import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Api from '../../../api';
import moment from 'moment';
import Loading from '../../../components/Loading';
import DataMappers from "../../../helpers/DataMappers";

const SectorNews = (props, context) => {
    const [dataRegDesc, setDataRegDesc] = useState({regulationNewsDesc: [], loading: true});
    const [dataRegAsc, setDataRegAsc] = useState({regulationNewsAsc: [], loading: true});
    const [dataBusDesc, setDataBusDesc] = useState({businessNewsDesc: [], loading: true});
    const [dataBusAsc, setDataBusAsc] = useState({businessNewsAsc: [], loading: true});
    const [dataMinDesc, setDataMinDesc] = useState({miningNewsDesc: [], loading: true});
    const [dataMinAsc, setDataMinAsc] = useState({miningNewsAsc: [], loading: true});
    const {
        rawData, // Raw sector metadata
        exchange, // Selected exchange rate
        businessMetadata // Business metadata
    } = props;

    useEffect(() => {
        // Fetch news for the sector
        if (businessMetadata && businessMetadata.length > 0) {
            fetchRegulation(businessMetadata);
            fetchBusiness(businessMetadata);
            fetchMining(businessMetadata);
        }


    }, [businessMetadata]);

    const fetchRegulation = () => {

        setDataRegDesc({...dataRegDesc, loading: true});

        const tags = businessMetadata.find(el => el.numeric_code === rawData.numeric_code).news_tag;

        const today = new Date();
        const lastWeek = new Date(today.getTime());
        lastWeek.setDate(today.getDate() - 7);


        Api.getPromise(`/News/NewsSearch?tags=OTHER_Regulation&order_by=title_sentiment,desc&min_publication_datetime=`+lastWeek.getTime(), {tags: tags})
            .then(res => {
                setDataRegDesc({regulationNewsDesc: (res && res.data) || [], loading: false});
            })
            .catch(e => setDataRegDesc({regulationNewsDesc: [], loading: false}));

        Api.getPromise(`/News/NewsSearch?tags=OTHER_Regulation&order_by=title_sentiment,asc&min_publication_datetime=`+lastWeek.getTime(), {tags: tags})
            .then(res => {
                setDataRegAsc({regulationNewsAsc: (res && res.data) || [], loading: false});
            })
            .catch(e => setDataRegAsc({regulationNewsAsc: [], loading: false}));

    }

    const fetchBusiness = () => {

        setDataBusDesc({...dataBusDesc, loading: true});
        const tags = businessMetadata.find(el => el.numeric_code === rawData.numeric_code).news_tag;

        const today = new Date();
        const lastWeek = new Date(today.getTime());
        lastWeek.setDate(today.getDate() - 7);

        Api.getPromise(`/News/NewsSearch?tags=OTHER_Business&order_by=title_sentiment,desc&min_publication_datetime=`+lastWeek.getTime(),{tags: tags} )
            .then(res => {
                setDataBusDesc({businessNewsDesc: (res && res.data) || [], loading: false});
            })
            .catch(e => setDataBusDesc({businessNewsDesc: [], loading: false}));

        Api.getPromise(`/News/NewsSearch?tags=OTHER_Business&order_by=title_sentiment,asc&min_publication_datetime=`+lastWeek.getTime(),{tags: tags} )
            .then(res => {
                setDataBusAsc({businessNewsAsc: (res && res.data) || [], loading: false});
            })
            .catch(e => setDataBusAsc({businessNewsAsc: [], loading: false}));

    }

    const fetchMining = () => {

        setDataMinDesc({...dataMinDesc, loading: true});
        const tags = businessMetadata.find(el => el.numeric_code === rawData.numeric_code).news_tag;


        const today = new Date();
        const lastWeek = new Date(today.getTime());
        lastWeek.setDate(today.getDate() - 7);

        Api.getPromise(`/News/NewsSearch?tags=OTHER_Mining&order_by=title_sentiment,desc&min_publication_datetime=`+lastWeek.getTime(),{tags: tags})
            .then(res => {
                setDataMinDesc({miningNewsDesc: (res && res.data) || [], loading: false});
            })
            .catch(e => setDataMinDesc({miningNewsDesc: [], loading: false}));

        Api.getPromise(`/News/NewsSearch?tags=OTHER_Mining&order_by=title_sentiment,asc&min_publication_datetime=`+lastWeek.getTime(), {tags: tags})
            .then(res => {
                setDataMinAsc({miningNewsAsc: (res && res.data) || [], loading: false});
            })
            .catch(e => setDataMinAsc({miningNewsAsc: [], loading: false}));

    }

    // Sentiment bubble generator
    const sentimentStyle = (value) => {
        return {
            borderRadius: "50%",
            width: "3em",
            height: "3em",
            fontSize: "12px",
            lineHeight: "3em",
            textAlign: "center",
            margin: "0.5vh 0 0.5vh 0",
            color: "white",
            fontWeight: "bold",
            background: DataMappers.mapSentimentColor(value)
        }
    };

    return (
        <section className="news">
            <div className="news-analysis-part">
                <div className="nap-flex">
                    <div style={{width: '33%'}} className="nap-flex-left">
                        <div className="gray-container"
                             style={{display: "flex", flexDirection: "column", height: "100%"}}>
                            <div className="table-head" style={{marginBottom: "2vh"}}>
                                <div className="table-left">
                                    <div className="table-header">Regulation</div>
                                    <br/>
                                </div>
                            </div>
                            <Loading loading={dataRegDesc.loading}>
                                <div>
                                    <p style={{color: 'rgba(255, 255, 255, 0.4)'}}>Top Positive News</p>
                                </div>
                                {
                                    dataRegDesc.regulationNewsDesc.map((row, i) => {
                                            const source = row.source.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
                                            const date = moment.utc(row.publication_datetime).local();
                                            return (i < 3 &&
                                                <div style={{display: 'flex'}}>
                                                    <div style={{
                                                        paddingLeft: '3%',
                                                        color: '#fff',
                                                        display: 'grid',
                                                        marginRight: '3%'
                                                    }}>
                                                        <div className="sector-summary-text">
                                                            <p style={{float: 'left'}}>
                                                                <span>{date.format('DD.MM.YYYY')}/</span> {date.format('HH:mm')}
                                                            </p>
                                                            <p style={{float: 'right', color: '#16adcf'}}><a target="_blank"
                                                                                                             className="blue-link"
                                                                                                             href={row.link}>
                                                                {source}
                                                            </a></p>
                                                        </div>

                                                        <div>
                                                            <p>{row.summary}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>
                                                        <div style={sentimentStyle(row.sentiment.title)}>
                                                            {(parseInt(row.sentiment.title * 100, 10))}%
                                                        </div>
                                                    </div>
                                                </div>

                                            );
                                        }
                                    )}

                                <div style={{marginTop: '5%'}}>
                                    <p style={{color: 'rgba(255, 255, 255, 0.4)'}}>Top Negative News</p>
                                </div>
                                {
                                    dataRegAsc.regulationNewsAsc.map((row, i) => {
                                            const source = row.source.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
                                            const date = moment.utc(row.publication_datetime).local();
                                            return (i < 3 &&
                                                <div style={{display: 'flex'}}>
                                                    <div style={{
                                                        paddingLeft: '3%',
                                                        color: '#fff',
                                                        display: 'grid',
                                                        marginRight: '3%'
                                                    }}>
                                                        <div className="sector-summary-text">
                                                            <p style={{float: 'left'}}>
                                                                <span>{date.format('DD.MM.YYYY')}/</span> {date.format('HH:mm')}
                                                            </p>
                                                            <p style={{float: 'right', color: '#16adcf'}}><a target="_blank"
                                                                                                             className="blue-link"
                                                                                                             href={row.link}>
                                                                {source}
                                                            </a></p>
                                                        </div>

                                                        <div>
                                                            <p>{row.summary}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>
                                                        <div style={sentimentStyle(row.sentiment.title)}>
                                                            {(parseInt(row.sentiment.title * 100, 10))}%
                                                        </div>
                                                    </div>
                                                </div>

                                            );
                                        }
                                    )}

                            </Loading>

                        </div>
                    </div>

                    <div style={{width: '33%'}} className="nap-flex-left">
                        <div className="gray-container"
                             style={{display: "flex", flexDirection: "column", height: "100%"}}>
                            <div className="table-head" style={{marginBottom: "2vh"}}>
                                <div className="table-left">
                                    <div className="table-header">Business and Trading</div>
                                    <br/>
                                </div>
                            </div>
                            <Loading loading={dataBusDesc.loading}>
                                <div>
                                    <p style={{color: 'rgba(255, 255, 255, 0.4)'}}>Top Positive News</p>
                                </div>
                                {
                                    dataBusDesc.businessNewsDesc.map((row, i) => {
                                            const source = row.source.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
                                            const date = moment.utc(row.publication_datetime).local();
                                            return (i < 3 &&
                                                <div style={{display: 'flex'}}>
                                                    <div style={{
                                                        paddingLeft: '3%',
                                                        color: '#fff',
                                                        display: 'grid',
                                                        marginRight: '3%'
                                                    }}>
                                                        <div className="sector-summary-text">
                                                            <p style={{float: 'left'}}>
                                                                <span>{date.format('DD.MM.YYYY')}/</span> {date.format('HH:mm')}
                                                            </p>
                                                            <p style={{float: 'right', color: '#16adcf'}}><a target="_blank"
                                                                                                             className="blue-link"
                                                                                                             href={row.link}>
                                                                {source}
                                                            </a></p>
                                                        </div>

                                                        <div>
                                                            <p>{row.summary}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>
                                                        <div style={sentimentStyle(row.sentiment.title)}>
                                                            {(parseInt(row.sentiment.title * 100, 10))}%
                                                        </div>
                                                    </div>
                                                </div>

                                            );
                                        }
                                    )}

                                <div style={{marginTop: '5%'}}>
                                    <p style={{color: 'rgba(255, 255, 255, 0.4)'}}>Top Negative News</p>
                                </div>
                                {
                                    dataBusAsc.businessNewsAsc.map((row, i) => {
                                            const source = row.source.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
                                            const date = moment.utc(row.publication_datetime).local();
                                            return (i < 3 &&
                                                <div style={{display: 'flex'}}>
                                                    <div style={{
                                                        paddingLeft: '3%',
                                                        color: '#fff',
                                                        display: 'grid',
                                                        marginRight: '3%'
                                                    }}>
                                                        <div className="sector-summary-text">
                                                            <p style={{float: 'left'}}>
                                                                <span>{date.format('DD.MM.YYYY')}/</span> {date.format('HH:mm')}
                                                            </p>
                                                            <p style={{float: 'right', color: '#16adcf'}}><a target="_blank"
                                                                                                             className="blue-link"
                                                                                                             href={row.link}>
                                                                {source}
                                                            </a></p>
                                                        </div>

                                                        <div>
                                                            <p>{row.summary}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>
                                                        <div style={sentimentStyle(row.sentiment.title)}>
                                                            {(parseInt(row.sentiment.title * 100, 10))}%
                                                        </div>
                                                    </div>
                                                </div>

                                            );
                                        }
                                    )}

                            </Loading>

                        </div>
                    </div>

                    <div style={{width: '33%'}} className="nap-flex-left">
                        <div className="gray-container"
                             style={{display: "flex", flexDirection: "column", height: "100%"}}>
                            <div className="table-head" style={{marginBottom: "2vh"}}>
                                <div className="table-left">
                                    <div className="table-header">Mining</div>
                                    <br/>
                                </div>
                            </div>
                            <Loading loading={dataMinDesc.loading}>

                                {
                                    dataMinDesc.miningNewsDesc.map((row, i) => {
                                            const source = row.source.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
                                            const date = moment.utc(row.publication_datetime).local();
                                            return (i < 3 &&
                                                <div style={{display: 'flex'}}>
                                                    <div style={{
                                                        paddingLeft: '3%',
                                                        color: '#fff',
                                                        display: 'grid',
                                                        marginRight: '3%'
                                                    }}>
                                                        <div className="sector-summary-text">
                                                            <p style={{float: 'left'}}>
                                                                <span>{date.format('DD.MM.YYYY')}/</span> {date.format('HH:mm')}
                                                            </p>
                                                            <p style={{float: 'right', color: '#16adcf'}}><a target="_blank"
                                                                                                             className="blue-link"
                                                                                                             href={row.link}>
                                                                {source}
                                                            </a></p>
                                                        </div>

                                                        <div>
                                                            <p>{row.summary}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>
                                                        <div style={sentimentStyle(row.sentiment.title)}>
                                                            {(parseInt(row.sentiment.title * 100, 10))}%
                                                        </div>
                                                    </div>
                                                </div>

                                            );
                                        }
                                    )}

                                <div style={{marginTop: '5%'}}>
                                    <p style={{color: 'rgba(255, 255, 255, 0.4)'}}>Top Negative News</p>
                                </div>
                                {
                                    dataMinAsc.miningNewsAsc.map((row, i) => {
                                            const source = row.source.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
                                            const date = moment.utc(row.publication_datetime).local();
                                            return (i < 3 &&
                                                <div style={{display: 'flex'}}>
                                                    <div style={{
                                                        paddingLeft: '3%',
                                                        color: '#fff',
                                                        display: 'grid',
                                                        marginRight: '3%'
                                                    }}>
                                                        <div className="sector-summary-text">
                                                            <p style={{float: 'left'}}>
                                                                <span>{date.format('DD.MM.YYYY')}/</span> {date.format('HH:mm')}
                                                            </p>
                                                            <p style={{float: 'right', color: '#16adcf'}}><a target="_blank"
                                                                                                             className="blue-link"
                                                                                                             href={row.link}>
                                                                {source}
                                                            </a></p>
                                                        </div>

                                                        <div>
                                                            <p>{row.summary}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>
                                                        <div style={sentimentStyle(row.sentiment.title)}>
                                                            {(parseInt(row.sentiment.title * 100, 10))}%
                                                        </div>
                                                    </div>
                                                </div>

                                            );
                                        }
                                    )}


                            </Loading>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = state => {
    return {
        meta: state.meta,
        businessMetadata: (state.meta && state.meta.lists && state.meta.lists.business_classification_schema)
    };
};

export default connect(mapStateToProps)(SectorNews);
