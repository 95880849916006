import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Utils from "../../helpers/Utils";
import Breadcrump from "../../components/Breadcrump/Breadcrump";
import PortfolioMenuContent from "./PortfolioMenuContent";
import PortfolioCreate from "./PortfolioCreate";
import MyPortfolio from "./MyPortfolios";
import PortfolioBindAccount from "./PortfolioBindAccount";
import Loading from "../../components/Loading";
import PortfolioDelete from "./PortfolioDelete";
import PortfolioApi from "../../api/PortfolioApi";
import Api from "../../api";
import AppHelpers from "../../helpers/AppHelpers";
import {withRouter} from "react-router-dom";

const isMobile = Utils.checkMobile();

//Menu Tabs
const menuItems = !isMobile ? [
    {
        name: 'BALANCE',
        path_matcher: "balance"
    },
    {
        name: 'TRANSACTIONS',
        path_matcher: "transactions"
    },
    // {
    //     name: 'SETTINGS',
    //     path_matcher: "settings"
    // }
] : [
    {
        name: 'BALANCE',
        path_matcher: "balance"
    },
    {
        name: 'TRANSACTIONS',
        path_matcher: "transactions"
    },
    // {
    //     name: 'SETTINGS',
    //     path_matcher: "settings"
    //
    // }
];

const Portfolio = (props, context) => {

    const {history} = props;

    // Bread crumps
    const [crumps, setCrumps] = useState([]);
    // Control Portfolio edit / add modal show
    const [showP, setShowP] = useState(false);
    // Control Portfolio delete modal show
    const [showD, setShowD] = useState(false);
    // Selected portfolio account
    const [myPortfolio, setMyPortfolio] = useState({name: "empty", id: ''});
    // All portfolio accounts
    const [portfolioAccounts, setPortfolioAccounts] = useState(null);
    // Selected edit portfolio account
    const [editPortfolio, setEditPortfolio] = useState({});
    // Portfolio account ids
    const [portfolioAccountIds, setPortfolioAccountIds] = useState("");
    // Selected portfolio account ids
    const [selectedPortfolioAccountIds, setSelectedPortfolioAccountIds] = useState("");
    // Selected currency
    const [ccy, setCcy] = useState({});
    // Portfolio accounts current balance
    const [currentAccounts, setCurrentAccounts] = useState({});
    // Portfolio total current balance
    const [totalBalance, setTotalBalance] = useState({total: 0, change: 0, perc: 0});
    // Portfolio account history balance
    const [historyAccounts, setHistoryAccounts] = useState({});
    // Total holdings for asset allocation
    const [totalHoldings, setTotalHoldings] = useState([]);
    // Portfolio Account Loading
    const [pLoading,setPLoading] = useState(true);
    // Portfolio Account Balance Loading
    const [pABLoading,setPABLoading] = useState(true);
    // Portfolio Account Balance History Loading
    const [pABHLoading,setPABHLoading] = useState(true);


    // Arrange crumps data
    const arrangeCrumps = () => {
        setCrumps([
            {
                name: 'Home',
                path: '/',
            },
            {
                name: 'Portfolios',
                path: '/portfolio',
            }
        ]);
    }

    // Menu changed; update link and path
    const menuChanged = (s) => {
        history.push({
            pathname: './' + s.path_matcher,
        });
    }

    // Select All
    const selectAll = () => {
        const accounts = portfolioAccounts.data.map(el => {
            el.selected = true
            return el;
        });
        setPortfolioAccounts({data: accounts});
    }

    // Remove All
    const removeAll = () => {
        const accounts = portfolioAccounts.data.map(el => {
            el.selected = false
            return el;
        });
        setPortfolioAccounts({data: accounts});
    }

    useEffect(() => {
        arrangeCrumps();
    }, []);

    // Arrange account ids from portfolio accounts
    useEffect(() => {
        if (portfolioAccounts) {
            if (portfolioAccounts.data.length > 0) {
                setPortfolioAccountIds(portfolioAccounts.data.map(el => {
                    return el.id;
                }).join(","));
                setSelectedPortfolioAccountIds(portfolioAccounts.data.filter(el => el.selected).map(el => {
                    return el.id;
                }).join(","));
            } else {
                setPortfolioAccountIds("");
                setSelectedPortfolioAccountIds("");
            }
        }
    }, [portfolioAccounts]);

    // Calculate user balance
    useEffect(() => {
        setPABLoading(true);
        setPLoading(true);
        setPABHLoading(true);
        if (portfolioAccountIds !== "" && selectedPortfolioAccountIds !== "") {
            // History balance
            const changesJob = PortfolioApi.getBalanceHistory({
                account_ids: selectedPortfolioAccountIds,
                ccy: ccy.ccy
            }).then(resp => {

                setHistoryAccounts(resp);
                setPABHLoading(false);
                // last day balance
                if (resp.history.length > 0) {
                    return resp.history[resp.history.length - 1].balance;
                } else {
                    return 0;
                }

            });

            // Current balance
            PortfolioApi.getCurrentBalance({
                account_ids: portfolioAccountIds,
                ccy: ccy.ccy
            }).then(resp => {
                // Set symbols from total holdings
                let symbols = [];
                if (resp.current_total_holdings) {
                    symbols = resp.current_total_holdings.map(el => {
                        return el[0]
                    });
                }

                const params = {
                    coin_symbols: symbols.join(","),
                    ccy: ccy.ccy,
                    page_size: symbols.length + 1
                };

                const assetParams={
                    assets: symbols.join(","),
                    ccy: ccy.ccy
                }

                Api.getAssetPrices(assetParams).then(res=>{
                    const coins = {};
                    // Re arrange coins
                    if(res.data){
                        res.data.forEach((el) => {
                            if (el[2] && el[2] !== 0) {
                                coins[el[0]] = el[2];
                            } else {
                                coins[el[0]] = 0;
                            }
                        });
                    }

                    // Calculate Total Value
                    let totalValue = 0;

                    const accounts = {}
                    if (resp.current_total_holdings) {
                        resp.current_total_holdings.forEach(el => {
                            if(el[2] && coins[el[0]]){
                                totalValue += el[2] * coins[el[0]];
                            }
                        });

                        // Calculate all accounts values
                        resp.current_account_holdings.forEach(el => {
                            accounts[el[0]] = el[2].map(coin => {
                                coin.push(coins[coin[0]] || 0);
                                return coin;
                            });
                        });

                        let totalH = [];
                        // Arrange total holdings
                        resp.current_total_holdings.sort((el1, el2) => el1[2] - el2[2]).forEach(el => {
                            if(coins[el[0]] !== 0 && el[2] !== 0){
                                const total = coins[el[0]] * el[2];
                                const percentage = (total * 100) / totalValue;
                                totalH.push({symbol: el[0], name: el[1], amount: el[2], perc: percentage, total: total});
                            }
                        });
                        totalH.sort((el1,el2)=>el2.total - el1.total);
                        setTotalHoldings(totalH);
                    } else {
                        setTotalHoldings([]);
                    }


                    changesJob.then(changes => {
                        // calculate diff percentages
                        const perc = (totalValue - changes) * 100 / totalValue;

                        setCurrentAccounts(accounts);
                        setTotalBalance({
                            ...totalBalance,
                            total: totalValue,
                            change: changes != 0 ? totalValue - changes : changes,
                            perc: changes != 0 ? perc : changes
                        });
                        setPABLoading(false);
                        setPLoading(false);
                    })
                })
            })
        } else {
            setHistoryAccounts({});
            setTotalHoldings([]);
            setTotalBalance({total: 0, change: 0, perc: 0});
            setPABLoading(false);
            setPLoading(false);
            setPABHLoading(false);
        }
    }, [portfolioAccountIds, ccy, selectedPortfolioAccountIds]);

    // Select unselect account
    const handleSelectAccount = (accountId) => {
        const accounts = portfolioAccounts.data.map(el => {
            if (el.id === accountId) {
                el.selected = !el.selected
            }
            return el;
        });
        setPortfolioAccounts({data: accounts});
    }

    // Re arrange accounts when portfolio change
    useEffect(() => {
        fetchData();
    }, [myPortfolio]);

    // Re arrange filtered accounts
    const fetchData = () => {
        if (myPortfolio.id) {
            PortfolioApi.getAccountsOfPortfolio(myPortfolio.id).then(respL => {
                if (respL.length > 0) {
                    const portfolioAccount = respL.map(resp => {
                        resp["selected"] = true;
                        return resp;
                    })
                    setPortfolioAccounts({data: portfolioAccount});
                } else {
                    setPortfolioAccounts({data: []});
                }
            })
        } else if (myPortfolio.name !== "empty") {
            setPortfolioAccounts({data: []})
        }
    }


    return (
        isMobile ?
            <div>

            </div> :
            <section className="dashboard padding-sm-container">
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Breadcrump links={crumps}/>
                </div>
                <div className="row" style={{marginTop:"10px"}}>
                    <div className="col-md-4 col-custom" style={{marginTop:"10px"}}>
                        <MyPortfolio showP={showP} setShowP={setShowP} myPortfolio={myPortfolio}
                                     setMyPortfolio={setMyPortfolio} setEditPortfolio={setEditPortfolio} showD={showD}
                                     setShowD={setShowD} setCcy={setCcy} ccy={ccy} total={totalBalance}
                                     historyAccounts={historyAccounts} portfolioAccounts={portfolioAccounts}
                                     setPortfolioAccountIds={setPortfolioAccountIds} pABLoading={pABLoading}/>
                    </div>
                    <div className="col-md-8 col-custom" style={{marginTop:"10px"}}>
                        <div className="gray-container" style={{height: "285px"}}>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <div style={{color: "white",fontSize:"20px"}}>
                                    Linked Accounts
                                </div>
                            </div>

                            <Loading loading={pLoading}>
                                <div style={{
                                    display: "flex",
                                    padding: "5px 0px",
                                    height: "150px",
                                    backgroundColor: "#1f2327",
                                    marginTop: "15px",
                                    marginBottom: "15px",
                                    borderRadius:"5px"
                                }}>


                                        {   portfolioAccounts &&
                                            portfolioAccounts.data.length > 0 ?
                                                <div style={{margin: "5px 5px", display: "flex", overflowX: "auto"}}
                                                     className="portfolioAccount">
                                                    {
                                                        portfolioAccounts.data.map((el, i) => {
                                                            // Calculate account total value
                                                            let total = 0;
                                                            if (currentAccounts[el.id]) {
                                                                currentAccounts[el.id].forEach(el => {
                                                                    total += el[2] * el[3];
                                                                })
                                                            }

                                                            return (
                                                                <div key={i} style={el.selected ? {
                                                                    padding: "5px",
                                                                    backgroundColor: "#292e33",
                                                                    borderRadius: "10px",
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    justifyContent: "center",
                                                                    color: "white",
                                                                    margin: "0px 5px",
                                                                    cursor: "pointer",
                                                                    border: "1px solid #16a5c5"
                                                                } : {
                                                                    padding: "5px",
                                                                    backgroundColor: "#292e33",
                                                                    borderRadius: "10px",
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    justifyContent: "center",
                                                                    color: "white",
                                                                    margin: "0px 5px",
                                                                    cursor: "pointer"
                                                                }} onClick={() => handleSelectAccount(el.id)}>
                                                                    <div style={{
                                                                        textAlign: "center",
                                                                        paddingTop: "5px",
                                                                        width: "100px",
                                                                        overflow: "hidden",
                                                                        textOverflow: "ellipsis"
                                                                    }}>
                                                                        {el.name}
                                                                    </div>
                                                                    <div style={{textAlign: "center", fontWeight: "bold"}}>
                                                                        {total == 0 ? "0" : AppHelpers.priceFormat(total)} {ccy.ccy}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                :
                                                <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                                                    <div style={{
                                                        color: '#fff',
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center"
                                                    }}>There is no connected account to your portfolio.
                                                    </div>
                                                </div>
                                        }
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-end"}}>
                                    <div style={{width: 100}} className="greenb-border-btn flRight">
                                        <a style={{cursor: "pointer"}} onClick={selectAll}>Select All</a>
                                    </div>
                                    <div style={{width: 100, marginLeft: "10px"}} className="greenb-border-btn flRight">
                                        <a style={{cursor: "pointer"}} onClick={removeAll}>Unselect All</a>
                                    </div>
                                </div>
                            </Loading>
                        </div>
                    </div>

                </div>
                <div className="tab-menu" style={{marginBottom:"15px"}}>
                    <ul>
                        {menuItems.map((s, i) => (
                            <li key={i} className={history.location.pathname.endsWith(s.path_matcher) ? 'active' : ''}>
                                <a onClick={() => menuChanged(s)}>{s.name}</a>
                            </li>
                        ))}
                    </ul>
                </div>
                <PortfolioMenuContent portfolioAccounts={portfolioAccounts} myPortfolio={myPortfolio}
                                      historyAccounts={historyAccounts} ccy={ccy} totalHoldings={totalHoldings} pABHLoading={pABHLoading}/>
                <PortfolioCreate
                    show={showP}
                    close={() => {
                        fetchData();
                        setShowP(false);
                    }}
                />
                <PortfolioDelete
                    editPortfolio={editPortfolio}
                    setEditPortfolio={setEditPortfolio}
                    getPortfolios={()=>{}}
                    show={showD}
                    close={() => {
                        setShowD(false);
                    }}
                />


            </section>
    );
};

const mapStateToProps = state => {

    return {
        meta: state.meta,
    };
};

export default connect(mapStateToProps)(withRouter(Portfolio));
