import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import Loading from "../Loading";
import ReactTooltip from "react-tooltip";
import SymbolRow from "../CoinMarketTab/SymbolRow";
import Paginate from "../Paginate";
import RealtimeUtils from "../../helpers/RealtimeUtils";
import AppHelpers from "../../helpers/AppHelpers";


const RealTimeTh = (props) => {
    // Props selected coin and ccy , and get exchanges from metadata
    const {newKey, index, mappedData, setMappedData, exchange, setChannel, base, quote, visible} = props;

    // Handles real time updates on row
    const [tickerData, setTickerData] = RealtimeUtils.useRealTimeRow(base+"-"+quote+"."+newKey, setChannel);

    useEffect(()=>{
        let data = {...mappedData};
        data[newKey] = tickerData;
        setMappedData(data);
    },[tickerData]);

    return (
        <th key={index} style={AppHelpers.timeDifferenceArbitrage(tickerData.lst_ts,visible) === "invisible"? {display:"none",borderLeft:"1px solid #33393f"}:{borderLeft:"1px solid #33393f"}}>
            <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",width:"150px"}}>
                <div style={{textAlign:"center"}}>
                    {exchange.name}
                </div>
                <div style={{textAlign:"center"}}>
                    {tickerData.price}
                </div>
                <div style={{textAlign:"center"}}>
                    {AppHelpers.timeDifferenceArbitrage(tickerData.lst_ts,visible)}
                </div>
            </div>
        </th>
    );
};


const mapStateToProps = state => {
    // Convert exchanges to map of symbol to exchange data
    const exchangeMap = {};
    state.meta.exchanges.forEach(el => exchangeMap[el.code] = el);
    return {
        meta: state.meta,
        exchanges: exchangeMap
    };
};

export default connect(mapStateToProps)(RealTimeTh);
