import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Api from '../../../api';
import moment from 'moment';
import Loading from '../../../components/Loading';
import SectorReportFilter from './SectorReportFilter';
import Select from "../../../components/Forms/Select";

// Query string for initializing URLSearchParams
const filter = "page_number=1&page_size=20&language_code=en";

// Page size selections
const pages = [
    {value: 10, label: '10'},
    {value: 20, label: '20'},
    {value: 50, label: '50'}
];

const SectorReports = (props, context) => {
    const {} = props;
    const [data, setData] = useState({items: {}, loading: true});
    const [show, setShow] = useState(false);
    const [dataReport, setDataReport] = useState({reports: [], loading: true});
    const [item, setItem] = useState({});
    const [filters, setFilters] = useState({});

    // Table related information variables
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);

    // Handles pager clicks
    const handlePageClick = d => {
        if (currentPage !== d.selected + 1) {
            setCurrentPage(d.selected + 1);
        }
    };

    useEffect(() => {
        fetchReports();
    },[]);


    useEffect(() => {

        // Fetch news on state updates
        fetchNews(filters);

        // $(document).ready(function () {
        //     $("#filter-area").click(function () {
        //         $("#element-should-first").toggle();
        //     });
        // });

        const mq = window.matchMedia("(min-width: 600px)");

        if (mq.matches) {

        } else {
            var content = document.getElementById('element-should-first');
            var parent = content.parentNode;
            parent.insertBefore(content, parent.firstChild);
        }


    }, [filters, currentPage, pageSize]);

    // Check if object is non empty
    const nonEmpty = (field) => field && Object.keys(field).length > 0;

    // Fetch news with the filter state
    const fetchNews = () => {
        // Map filters to Url Search Parameters
        const sector = (nonEmpty(filters.selectSector) && filters.selectSector.news_tag) || null;
        const coin = (nonEmpty(filters.selectCoin) && filters.selectCoin.symbol && 'COIN_' + filters.selectCoin.symbol) || null;
        const source = (nonEmpty(filters.selectSource) && filters.selectSource.id) || null;
        const assetType = (nonEmpty(filters.selectAsset) && filters.selectAsset.news_tag) || null;
        const startDate = (filters.startDate && moment.utc(filters.startDate).unix() * 1000) || null;
        const endDate = (filters.endDate && moment.utc(filters.endDate).unix() * 1000) || null;
        const exchange = (nonEmpty(filters.selectExchange) && filters.selectExchange.id && filters.selectExchange.tag_type + "_" + filters.selectExchange.tag);
        const category = (nonEmpty(filters.selectCategory) && filters.selectCategory.id && filters.selectCategory.tag_type + "_" + filters.selectCategory.tag);
        const nation = (nonEmpty(filters.selectNation) && filters.selectNation.id && filters.selectNation.tag_type + "_" + filters.selectNation.tag);
        const f = new URLSearchParams(filter); // UrlSearchParams used for AND ing tag filters
        let tags = [];
        if (filters.inputKeyword) f.set("keywords_search", filters.inputKeyword);
        if (startDate) f.set("min_publication_datetime", startDate);
        if (endDate) f.set("max_publication_datetime", endDate);
        if (source) f.set("news_sources", source);
        if (sector) tags = [...tags, sector];
        if (assetType) tags = [...tags, assetType];
        if (coin) tags = [...tags, coin.replace(" ", "_")];
        if (exchange) tags = [...tags, exchange.replace(" ", "_")];
        if (category) tags = [...tags, category.replace(" ", "_")];
        if (nation) tags = [...tags, nation.replace(" ", "_")];
        if (tags && tags.length > 0) tags.forEach(el => f.append("tags", el)); else f.delete("tags");

        // Set paging parameters
        f.set("page_number", currentPage + "");
        f.set("page_size", pageSize + "");

        setData({...data, loading: true});
        Api.getPromise('/News/NewsSearch', f).then(res => {
            // Extract paging parameters
            const pageSize = f.has("page_size") ? f.get("page_size") : 20;
            const pageCount = Math.ceil(res.total_count / pageSize) || 0;

            // Map data for visualisation
            res = res.data.map(el => {
                el.source = el.source.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
                return el;
            });

            // Update states
            setTotalPage(pageCount);
            setData({items: res, loading: false});
        });
    };

    const fetchReports = () => {

        setDataReport({...dataReport, loading: true});
        Api.getPromise(`/Reports/GetSectorReports`)
            .then(res => {
                setDataReport({reports: (res && res.data) || [], loading: false});
            })
            .catch(e => setDataReport({reports: [], loading: false}));

    }

    return (
        <section className="news">
            <div id="filter-area" style={{display: 'none'}}>
                <p>Filter <i class="down-arrow-news"></i></p>
            </div>
            <div className="news-analysis-part">
                <div className="nap-flex">
                    <div style={{width: '80%'}} className="nap-flex-left">
                        <div className="gray-container"
                             style={{display: "flex", flexDirection: "column", height: "100%"}}>
                            <div className="table-head" style={{marginBottom: "2vh"}}>
                                <div className="table-left">
                                    <div className="table-header">Weekly Sector Reports</div>
                                </div>
                                <div className="table-right">
                                    <div className="table-select-input">
                                        <div className="sm-select-input small-select">
                                            {data.items.length &&
                                            <Select selected={pageSize} options={pages} onChange={selected => {
                                                setPageSize(selected.value);
                                                setCurrentPage(1);
                                            }}/>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Loading loading={data.loading}>
                                <div className="table-body table-responsive" id="beauty-scroll7" tabIndex={1}
                                     style={{overflow: 'hidden', outline: 'none'}}>
                                    {data.items.length &&
                                    <table className="coins enumarable-table news-table-responsive linked-table">
                                        <thead>
                                        <tr>
                                            <th>Report No</th>
                                            <th>Report Name</th>
                                            <th>Publish Date</th>
                                            <th>PDF</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {dataReport.reports.map((row, i) => (
                                            <tr>

                                                <td style={{textAlign: 'left'}}>
                                                    <div className="text">{row.code}</div>
                                                </td>
                                                <td style={{textAlign: 'left'}}>
                                                    <div className="text">{row.description}</div>
                                                </td>
                                                <td style={{textAlign: 'left'}}>
                                                    <div className="text">
                                                        {moment(row.publication_date).format('DD.MM.YYYY - HH:mm')}
                                                    </div>
                                                </td>
                                                <td style={{textAlign: 'left'}}>
                                                    <div className="text"><img className="actionable-img" src="/img/pdf.svg"
                                                                               width={24} height={24} alt=""/></div>
                                                </td>
                                            </tr>
                                        ))}


                                        </tbody>
                                    </table>}
                                    {!data.items.length &&
                                    <p style={{color: "white"}}>No news found for the given filters...</p>}
                                </div>
                            </Loading>

                        </div>
                    </div>
                    <div style={{width: '20%'}} className="nap-flex-right" id="element-should-first">
                        <SectorReportFilter search={setFilters}/>
                    </div>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = state => {
    return {
        meta: state.meta,
    };
};

export default connect(mapStateToProps)(SectorReports);
