import axios from 'axios';
import Links from '../../constants/Links';
import {store} from '../../store';
import Api from "../index";

const MetaApi = {
  data(callback) {
    const state = store.getState();
    const list = Object.keys(state.meta.lists || {});
    if (!list.length) {
      axios({
        url: Links.BASE_URL + Links.META_DATA,
        method: 'GET',
      })
        .then(resp => {
          if (resp.status === 200) {
            callback(resp.data.data, false);
          } else {
            callback(false, true);
          }
        })

        .catch(err => {
          callback(false, true);
        });
    }
  },

  /**
   * Read news tags and execute the callback
   *
   * @param callback Callback function to execute
   */
  newsTags(callback) {
    const state = store.getState();
    const list = Object.keys(state.meta.newsTags || {});
    if (!list.length) {
      axios({
        url: Links.BASE_URL + Links.NEWS_TAGS,
        method: 'GET',
      })
          .then(resp => {
            if (resp.status === 200) {
              callback(resp.data.data, false);
            } else {
              callback(false, true);
            }
          })

          .catch(err => {
            callback(false, true);
          });
    }
  },

  getAllCoins(callback) {
    const state = store.getState();
    const list = state.meta.allCoins || [];
    if (!list.length) {
      axios({
        url: Links.BASE_URL + Links.GET_ALL_COINS,
        method: 'GET',
      })
        .then(resp => {
          callback(resp.data.data, false);
        })

        .catch(err => {
          callback(false, true);
        });
    }
  },

  getExchanges(callback) {
    Api.getExchanges().then(exc => {
      callback(exc, false);
    });
  },

  getConnectableExchanges(callback) {
    Api.getConnectableExchanges().then(exc => {
      callback(exc, false);
    });
  }

};

export default MetaApi;
