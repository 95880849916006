import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import ReactTooltip from "react-tooltip";
import RealTimeTh from "./RealTimeTh";
import RealtimeUtils from "../../helpers/RealtimeUtils";
import DataMappers from "../../helpers/DataMappers";
import chroma from 'chroma-js'
import AppHelpers from "../../helpers/AppHelpers";

const detectColorTd = (value)=>{
    const scaleGreen = chroma.scale(['#096316','#6bc231']).domain([0,0.1]);

    const scaleRed = chroma.scale(['#c1262b','#7b161f']).domain([-0.1,0]);

    if(value == 0.00){
        return "#171b29"
    }else if(value > 0){
        return scaleGreen(value).hex()
    }else{
        return scaleRed(value).hex()
    }
}


const ArbitrageGrid = (props) => {
    // Props selected coin and ccy , and get exchanges from metadata
    const {data, exchanges, selectedPair, type, visible} = props;

    const [mappedData,setMappedData] = useState({});

    const [realTimeMappedData,setRealTimeMappedData] = useState({});

    // Real time listeners
    const disabledColumns = ["market_cap", "high", "low"];
    const [tickerList, setChannel, initSocketConnection] = RealtimeUtils.useRealTimeTicker(disabledColumns,  DataMappers.spotTickerMapper);

    // Arrange spots so selected spot at top and exchange info is initiated
    const arrangeSpots = (inSpots, returnSpots) => {
        // Fill missing spot search values
        inSpots = inSpots.map(el => {
            // Add exchange name and ID
            const exc = exchanges[el.source_code];
            if (exc) {
                el.exchange_name = exc.name;
                el.exchange_id = exc.id;

            } else { // In case exchange not defined (Not likely when we get it from API)
                el.exchange_name = el.source_code;
            }
            return el;
        });

        // Return spots rather than updating (This one is used by updates to current spot list)
        if (returnSpots)
            return inSpots;

        // Initiate real time updates
        initSocketConnection(inSpots.map(el => el.cis_symbol));

    }

    useEffect(()=>{
        let newMappedData = {};
        // Puts selected spot at top if any
        arrangeSpots(data);
        if(data.length > 0){
            data.forEach(el=>{
                newMappedData[el.source_code] = el.ticker;
            })
            setMappedData(newMappedData);
            setRealTimeMappedData(newMappedData);
        }else{
            setMappedData({});
            setRealTimeMappedData({});
        }
    },[data]);

    return (
        <div style={{width:"600px"}}>
            <React.Fragment>
                <ReactTooltip html={true}/>
                <table className="arbitrage">
                    <thead>
                    <tr>
                        <th key={9999}>
                            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",width:"100px"}}>
                                <div style={{textAlign:"center"}}>
                                    {selectedPair.label}
                                </div>
                            </div>
                        </th>
                        {
                            Object.keys(mappedData).length > 0 && Object.keys(mappedData).map((key,index) => {
                                return(
                                    <RealTimeTh newKey={key} index={index}  setMappedData={setRealTimeMappedData} mappedData={realTimeMappedData} exchange={exchanges[key]} setChannel={setChannel} base={selectedPair.value.split("/")[0]} quote={selectedPair.value.split("/")[1]} visible={visible}/>
                                )
                            })
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {
                        Object.keys(realTimeMappedData).length > 0 ? Object.keys(realTimeMappedData).map((key,index) => {
                            return(
                                <tr key={index}  style={AppHelpers.timeDifferenceArbitrage(realTimeMappedData[key].lst_ts,visible) === "invisible"? {display:"none"}:{}}>
                                    <td key={9998}>
                                        <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
                                            <div style={{textAlign:"center"}}>
                                                {exchanges[key].name}
                                            </div>
                                            <div style={{textAlign:"center"}}>
                                                {realTimeMappedData[key].price}
                                            </div>
                                        </div>
                                    </td>
                                    {
                                        Object.keys(realTimeMappedData).length > 0 && Object.keys(realTimeMappedData).map((key1,index) => {
                                            const priceS = realTimeMappedData[key1].price ? realTimeMappedData[key1].price.replace(",","") : "0";
                                            const priceB = realTimeMappedData[key].price ? realTimeMappedData[key].price.replace(",",""): "0";

                                            let perc ;
                                            let priceDiff ;

                                            if(type === "BS"){
                                                perc = (((parseFloat(priceS)  - parseFloat(priceB)) / parseFloat(priceS))*100).toFixed(2);

                                                priceDiff = AppHelpers.priceFormat(parseFloat(priceS)  - parseFloat(priceB));
                                            }else{
                                                perc = (((parseFloat(priceB)  - parseFloat(priceS)) / parseFloat(priceB))*100).toFixed(2);

                                                priceDiff = AppHelpers.priceFormat(parseFloat(priceB)  - parseFloat(priceS));
                                            }

                                            return(
                                                <td key={index} style={AppHelpers.timeDifferenceArbitrage(realTimeMappedData[key1].lst_ts,visible) === "invisible"? {display:"none",color:"white",backgroundColor:detectColorTd(perc),borderLeft:"1px solid #33393f"}:{color:"white",backgroundColor:detectColorTd(perc),borderLeft:"1px solid #33393f"}}>
                                                    <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
                                                        <div style={{textAlign:"center"}}>
                                                            {priceDiff}
                                                        </div>
                                                        <div style={{textAlign:"center"}}>
                                                            {perc + "  %"}
                                                        </div>
                                                    </div>
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                                )
                        }):
                            <tr>
                                <td colSpan={12}
                                    style={{textAlign: "left", color: "fff", fontSize: "14px", padding: "7px 5px",fontWeight:"normal",cursor:"auto"}}>
                                    There is no data.
                                </td>
                            </tr>
                    }
                    </tbody>
                </table>
            </React.Fragment>
        </div>
    );
};


const mapStateToProps = state => {
    // Convert exchanges to map of symbol to exchange data
    const exchangeMap = {};
    state.meta.exchanges.forEach(el => exchangeMap[el.code] = el);
    return {
        meta: state.meta,
        exchanges: exchangeMap
    };
};

export default connect(mapStateToProps)(ArbitrageGrid);
