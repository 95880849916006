import axios from 'axios';
import Links from '../constants/Links';

let id = -1;
const Api = {
    updateToken(accessToken) {
        id !== -1 && axios.interceptors.request.eject(id);
        id = axios.interceptors.request.use( async(config) => {
            config.headers['authorization'] = `Bearer ${accessToken}`
            config.headers['cache-control'] = `no-cache`
            return config
        }, function (error) {
            return Promise.reject(error)
        })
    },

    getPromise(endpoint, params, baseUrl) {
        baseUrl = baseUrl || Links.BASE_URL;
        params = params || {};
        return new Promise((resolve, reject) => {
            axios
                .get(baseUrl + endpoint, {params: params})
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        });
    },

    postPromise(endpoint, body, baseUrl) {
        baseUrl = baseUrl || Links.BASE_URL;
        body = body|| {};
        return new Promise((resolve, reject) => {
            axios
                .post(baseUrl + endpoint, body)
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        });
    },

    getMPPromise(endpoint, params, baseUrl) {
        baseUrl = baseUrl || Links.BASE_URL;
        params = params || {};
        return new Promise((resolve, reject) => {
            axios
                .get(baseUrl + endpoint, {params: params, responseType: 'blob'})
                .then(response => resolve(response))
                .catch(error => reject(error));
        });
    },

    postMPPromise(endpoint, formData, baseUrl) {
        baseUrl = baseUrl || Links.BASE_URL;
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                data: formData,
                url: baseUrl + endpoint,
            }).then(response => resolve(response.data)).catch(error => reject(error));
        });
    },

    putPromise(endpoint, body, baseUrl) {
        baseUrl = baseUrl || Links.BASE_URL;
        body = body || {};
        return new Promise((resolve, reject) => {
            axios
                .put(baseUrl + endpoint, body)
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        });
    },

    deletePromise(endpoint, params, baseUrl) {
        baseUrl = baseUrl || Links.BASE_URL;
        params = params || {};
        return new Promise((resolve, reject) => {
            axios
                .delete(baseUrl + endpoint, {params: params})
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        });
    },

    /**
     * Read connectable exchanges
     */
    getExchanges() {
        return this.getPromise('/Exchange/ExchangeList').then(res => {
            return res.map(el => {
               // Map ccxt ID to id field
               el.id = el.ccxt_id;
               el.full_name = el.name + ' (' + el.code + ')';
               return el;
            });
        }).catch(err => {
            console.error(err);
            return [];
        })
    },

    /**
     * Read connectable exchanges
     */
    getConnectableExchanges() {
        return this.getPromise('/Exchange/ConnectableExchangeList').then(res => {
            return res.map(el => {
                // Map ccxt ID to id field
                el.id = el.ccxt_id;
                el.full_name = el.name + ' (' + el.code + ')';
                return el;
            });
        }).catch(err => {
            console.error(err);
            return [];
        })
    },

    /**
     * Read instrument info of given symbol
     *
     * @param cis_native_symbol CIS Native symbol
     */
    getInstrumentInfo(cis_native_symbol) {
        return this.getPromise('/Exchange/ProductInfo', {cis_native_symbol: cis_native_symbol}).then(res => {
            return res;
        }).catch(err => {
            console.error(err);
            return {};
        });
    },

    /**
     * Get asset price for portfolio
     *
     * @param include assets and ccy
     */
    getAssetPrices(param) {
        return this.getPromise('/Asset/GetAssetPrices', param).then(res => {
            return res;
        }).catch(err => {
            console.error(err);
            return {};
        });
    },

    /**
     * Performs the search request for news
     *
       @param params Extra query parameters
     * @param returnPageInfo Indicates if the paging will occur
     * @param searchPromise News search promise
     * @private
     */
    handleNewsSearch(params, returnPageInfo, searchPromise) {
        const defaultParams = {
            language_code: 'en',
            page_size: 20,
            page_number: 1
        };

        // Handle search promise
        return searchPromise('/News/NewsSearch', Object.assign(defaultParams, params)).then(res => {
            if (returnPageInfo) {
                const pageNumber = params.page_number ? params.page_number : 1;
                const pageSize = params.page_size ? params.page_size : 20;
                const pageCount = Math.ceil(res.total_count / pageSize) || 0;
                return {data: res.data, totalPage: pageCount, pageSize: pageSize, currentPage: pageNumber};
            } else {
                return res.data;
            }
        }).catch(e => {
            console.error(e);
            if (returnPageInfo) {
                return {data: [], totalPage: 0, pageSize: 0, currentPage: 1};
            }
            return [];
        })
    },

    /**
     * Search news with get request
     *
     * @param params Extra query parameters
     * @param returnPageInfo Indicates if the paging will occur
     * @returns
     */
    searchNews(params, returnPageInfo) {
        return this.handleNewsSearch(params, returnPageInfo, this.getPromise);
    },

    /**
     * Search news with post request
     *
     * @param params
     * @param returnPageInfo
     */
    searchNewsPost(params, returnPageInfo) {
        // Change order by to list format for POST request
        params.order_by = params.order_by && [params.order_by];
        return this.handleNewsSearch(params, returnPageInfo, this.postPromise);
    },

    /**
     * Call for searching through coins, params json at the body
     *
     * @param params Params to be used for filtering
     * @param returnPageInfo Indicates if the page info should be returned
     * @param searchPromise Coin search promise
     * @returns
     */
    handleCoinSearch(params, returnPageInfo, searchPromise) {
        return searchPromise('/Search/CoinSearch', params).then(res => {
            if (returnPageInfo) {
                const pageNumber = params.page_number ? params.page_number : 1;
                const pageSize = params.page_size ? params.page_size : 20;
                const pageCount = Math.ceil(res.total_count / pageSize) || 0;
                return {data: res.data, totalPage: pageCount, pageSize: pageSize, currentPage: pageNumber};
            } else {
                return res.data;
            }
        }).catch(e => {
            console.error(e);
            if (returnPageInfo) {
                return {data: [], totalPage: 0, pageSize: 0, currentPage: 1};
            }
            return [];
        });
    },

    /**
     * Search coins with get request
     *
     * @param params Params to be used for filtering
     * @param returnPageInfo Indicates if the page info should be returned (for paging)
     */
    searchCoins(params, returnPageInfo) {
        return this.handleCoinSearch(params, returnPageInfo, this.getPromise);
    },

    /**
     * Search coins with post request, params json at the body
     *
     * @param params Params to be used for filtering
     * @param returnPageInfo Indicates if the page info should be returned (for paging)
     */
    searchCoinsPost(params, returnPageInfo) {
        // Change order by to list format for POST request
        params.order_by = [params.order_by];
        return this.handleCoinSearch(params, returnPageInfo, this.postPromise);
    },

    /**
     * Reads summary for the given sector code
     *
     * @param code Sector code
     * @param ccy Exchange rate
     * @returns
     */
    readSectorSummary(code, ccy) {
        return this.getPromise("/sector/getsinglesectorsummary", {alphanumeric_code: code, ccy: ccy})
            .catch(e => {
                console.error(e);
                return null;
            });
    },

    /**
     * Spot search for exchange page
     *
     * @param params
     * @param pageSize Page size
     */
    spotSearch(params, pageSize= 20) {
        params.page_size = pageSize;
        return this.getPromise('/Search/SpotSearch', params)
            .then(res => {
                const pageNumber = params.page_number && 1;
                const pageCount = Math.ceil(res.total_count / pageSize) || 0;
                return {data: res.data || [], totalPage: pageCount, currentPage: pageNumber, ...res};
            }).catch(err => {
                console.error(err);
                return {data: [], totalPage: 0, currentPage: 1}
            });
    },

    /**
     * Market search for alert create
     *
     */
    marketSearch(){
        return this.getPromise('/Search/SpotSearch')
            .then(res => {
                return res.exchanges;
            }).catch(err => {
                console.error(err);
                return {data: [], totalPage: 0, currentPage: 1}
            });
    },

    /**
     * Coin pair search for alert create
     *
     * @param params
     */
    coinPairSearch(params){
        return this.getPromise('/Search/SpotSearch', params)
            .then(res => {
                return res;
            }).catch(err => {
                console.error(err);
                return {data: [], totalPage: 0, currentPage: 1}
            });
    },




    /**
     * Call for searching through indexes
     *
     * @param params Extra query parameters
     * @param returnPageInfo Indicates if the page info should be returned
     * @returns
     */
    searchIndex(params, returnPageInfo) {
        const defaultParams = {
            page_size: 20,
            page_number: 1,
            language_code: 'en'
        };

        return this.getPromise('/Search/IndexSearch', Object.assign(defaultParams, params))
            .then(res => {
                if (returnPageInfo) {
                    const pageNumber = params.page_number ? params.page_number : 1;
                    const pageSize = params.page_size ? params.page_size : 20;
                    const pageCount = Math.ceil(res.total_count / pageSize) || 0;
                    return {data: res.data || [], totalPage: pageCount, pageSize: pageSize, currentPage: pageNumber};
                } else {
                    return res.data;
                }
            })
            .catch(e => {
                console.error(e);
                if (returnPageInfo) {
                    return {data: [], totalPage: 0, pageSize: 0, currentPage: 1};
                }
                return [];
            });
    },

    /**
     * Reads full index data for given index code and exchange information
     *
     * @param indexCode Indicates which index to read
     * @param exchange Indicates which exchange will used for values
     */
    readIndexFull(indexCode, exchange) {
        // Prepare query map
        const query = {
            language_code: 'en',
            index_code: indexCode,
            ccy_code: exchange
        };

        // Fetch data
        return this.getPromise("/Index/GetIndexFull", query)
            .then(res => {
                return res && res.data;
            })
            .catch(e => {
                console.error(e);
                return null;
            })
    },

    /**
     * Reads index constituents for given index code and exchange information
     *
     * @param params Query parameters
     * @param returnPageInfo Indicates if the page info should be returned
     */
    getIndexConstituents(params, returnPageInfo) {
        // Default params
        const defaultParams = {
            language_code: 'en',
            page_size: 20,
            page_number: 1,
        };

        // Fetch data
        return this.getPromise("/Index/GetIndexConstituents", Object.assign(defaultParams, params)).then(res => {
            if (returnPageInfo) {
                const pageNumber = params.page_number ? params.page_number : 1;
                const pageSize = params.page_size ? params.page_size : 20;
                const pageCount = Math.ceil(res.total_count / pageSize) || 0;
                return {data: res.data, totalPage: pageCount, pageSize: pageSize, currentPage: pageNumber};
            } else {
                return res.data;
            }
        }).catch(e => {
            console.error(e);
            if (returnPageInfo) {
                return {data: [], totalPage: 0, pageSize: 0, currentPage: 1};
            }
            return [];
        });
    },

    /**
     * Performs quick search on coin, sectors and indexes
     *
     * @param searchString Search String
     * @param extraParams Increase page size if required check api docs
     */
    quickSearch(searchString, extraParams = {}) {
        // Default search params
        const defaultParams = {
            page_size: 5,
            page_number: 1
        };

        // Combine with extra params
        const params = Object.assign(defaultParams, extraParams);
        params.search_string = searchString;

        // Execute search
        return Api.getPromise("/Search/QuickSearch", params).then(res => {
            return res.data || [];
        }).catch(e => {
            console.error(e);
            return [];
        });
    },

    /**
     * Perform quick search with given string on coins. Returns 5 results
     *
     * @param searchString Search string
     * @param extraParams Increase page size if required check api docs
     */
    quickSearchCoin(searchString, extraParams = {}) {
        // Default search params
        const defaultParams = {
            page_size: 5,
            page_number: 1
        };

        // Combine with extra params
        const params = Object.assign(defaultParams, extraParams);
        params.search_string = searchString;

        // Execute search
        return Api.getPromise("/Search/QuickSearchCoin", params).then(res => {
            return res.data || [];
        }).catch(e => {
            console.error(e);
            return [];
        });
    },

    /**
     * Perform quick search with given string on coins. Returns 5 results
     *
     * @param searchString Search string
     * @param extraParams Increase page size if required check api docs
     */
    quickSearchIndex(searchString, extraParams = {}) {
        // Default search params
        const defaultParams = {
            page_size: 5,
            page_number: 1
        };

        // Combine with extra params
        const params = Object.assign(defaultParams, extraParams);
        params.search_string = searchString;

        // Execute search
        return Api.getPromise("/Search/QuickSearchIndex", params).then(res => {
            return res.data || [];
        }).catch(e => {
            console.error(e);
            return [];
        });
    },

    /**
     * Perform quick search with given string on coins. Returns 5 results
     *
     * @param searchString Search string
     * @param extraParams Increase page size if required check api docs
     */
    quickSearchSector(searchString, extraParams = {}) {
        // Default search params
        const defaultParams = {
            page_size: 5,
            page_number: 1
        };

        // Combine with extra params
        const params = Object.assign(defaultParams, extraParams);
        params.search_string = searchString;

        // Execute search
        return Api.getPromise("/Search/QuickSearchSector", params).then(res => {
            return res.data || [];
        }).catch(e => {
            console.error(e);
            return [];
        });
    },

    /**
     * Get timeseries price data with given params
     *
     * @param params Params for the search
     * @returns
     */
    getTsData(params) {
        let promise = null;
        if (typeof params === 'string') {
            promise = Api.getPromise("/timeseries/gettimeseries?" + params)
        } else {
            promise = Api.getPromise("/timeseries/gettimeseries", params)
        }

        // Execute search
        return promise.then(res => {
            return res || [];
        }).catch(e => {
            console.error(e);
            return [];
        });
    },

    /**
     * Get news sentiment timeseries data with given params
     *
     * @param params Params for the search
     * @returns
     */
    getNewsTsData(params) {
        let promise = null;
        if (typeof params === 'string') {
            promise = Api.getPromise("/News/NewsSentimentHistory" + '?' + params)
        } else {
            promise = Api.getPromise("/News/NewsSentimentHistory", params)
        }

        // Execute search
        return promise.then(res => {
            return res || [];
        }).catch(e => {
            console.error(e);
            return [];
        });
    },

    /**
     * Get sector pie chart datas
     *
     * @param params sectorname like "FIN" , ccy like "USD"
     *
     */
    getSectorPieDatas(params){
        let promise = null;

        const response = {};

        promise = Api.getPromise("/Sector/GetSingleSectorCoins",params);

        return promise.then(res => {
            response.asset_type_split = res.asset_type_split;
            response.coin_count = res.coin_count
            response.market_cap_split = res.market_cap_split;
            return response || [];
        }).catch(e => {
            console.error(e);
            return [];
        });
    },

    /**
     * Get sector coins datas
     *
     * @param params sectorname like "FIN" , ccy like "USD"
     */
    getSectorCoinDatas(params){
        let promise = null;
        let fields = {};
        let coinDatas = {};
        const response = {};

        promise = Api.getPromise("/Sector/GetSingleSectorCoins",params);

        return promise.then(res => {

            fields = res.coins.fields;
            coinDatas = res.coins.data;

            const mapped = coinDatas.map(coin=> {
                const ret = {}
                fields.forEach((f,i)=>{
                    ret[f]=coin[i];
                })

                return ret;
            })

            return mapped || [];
        }).catch(e => {
            console.error(e);
            return [];
        });
    },

    /**
     * Get coin dominance for home page
     *
     * @param include ccy
     */
    getCoinDominance(params){
        return this.getPromise('/Coin/GetCoinDominance', params)
            .then(res => {
                return res;
            }).catch(err => {
                console.error(err);
                return {}
            });
    }




};

export default Api;
