import React, { useEffect, useState } from 'react';
import ReadMoreReact from 'read-more-react';
import Utils from "../../helpers/Utils";
import createReport from 'docx-templates';
import Api from '../../api';
import SocialApi from '../../api/SocialApi/social';
import { toastr } from 'react-redux-toastr';
import AppHelpers from "../../helpers/AppHelpers";
import axios from "axios";


const isMobile = Utils.checkMobile();

const AssetSummary = props => {
    const { desc, row, assetTypeLists, business, takeScreenshot, setTakeScreenshot, screenshotUrl } = props;

    const [socialMediaLinks, setSocialMediaLinks] = useState([]);

    // indicates whether an error occurred during report data fetching
    const [error, setError] = useState(false);

    useEffect(() => {
        if (error) {
            toastr.error('', 'Error while creating document');
            setError(false);
        }
    }, [error])

    useEffect(() => {
        const socialMedias = { 'YouTube': 'youtube.com', 'Twitter': 'twitter.com', 'LinkedIn': 'linkedin.com', 'Reddit': 'reddit.com', 'Telegram': 't.me' };
        let links = {};
        for (let i = 0; i < row.additional_links.length; i++) {
            let link = row.additional_links[i];
            for (let el in socialMedias) {
                if (link.includes(socialMedias[el])) {
                    links[el] = link;
                    break;
                }
            }
        }
        links['Github'] = row.blockchain_source_code_url;
        setSocialMediaLinks(links);
    }, [row])

    useEffect(() => {
        // console.log(screenshotUrl);
        if (screenshotUrl)
            generateReport(screenshotUrl);
    }, [screenshotUrl])

    // Helper function to open links in new tab
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    }

    const formatLongText = (text) => {
        if (text.length < 150) {
            return text;
        }
        return text.substring(0, 120) + '...'
    }

    // Parse links
    const parseLinks = (coin) => {
        let links = [];
        coin.coin_info.additional_links.forEach(l => {
            const url = new URL(l);
            if (url.hostname === 'twitter.com' || url.hostname === 'reddit.com' || url.hostname === 'www.youtube.com') {
                links.push(l);
            }
        });
        links.push(coin.coin_info.blockchain_source_code_url);
        return links;
    }

    const generateReport = async (screenshotUrl) => {
        toastr.info('', 'Coin Reports are generated in .docx format. Please open in MS Word and enable editing to view charts.');
        const reportData = await getReportData();
        const redirectUrl = await axios.get("https://68buu1q8w0.execute-api.eu-central-1.amazonaws.com/prod/redirect?url=" + screenshotUrl).then(res => res.data).catch(err => "");
        reportData["imageurl"] = redirectUrl;

        const fileName = '/template.docx';
        const response = await fetch(fileName);
        const template = await response.arrayBuffer();

        // Download image and take to array buffer
        //  const image = await fetch("http://localhost:3001/dev/redirect?url=" + screenshotUrl).then(res => res.arrayBuffer());

        try {
            const report = await createReport({
                template,
                data: reportData,
                cmdDelimiter: ['+++', '+++'],
                // additionalJsContext: {
                //     imageEmbed: async () => {
                //         return { width: 6, height: 6, data: image, extension: '.png' };
                //     },
                // }
            });

            saveDataToFile(
                report,
                `report_${row.symbol}.docx`,
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            );
        }
        catch (errors) {
            if (Array.isArray(errors)) {
                console.log('Array error: ' + errors);
            }
            else {
                console.log(errors);
                throw errors;
            }
        }
    }

    const saveDataToFile = (data, fileName, mimeType) => {
        const blob = new Blob([data], { type: mimeType });
        const url = window.URL.createObjectURL(blob);
        downloadURL(url, fileName, mimeType);
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
        }, 1000);
    };

    const downloadURL = (data, fileName) => {
        const a = document.createElement('a');
        a.href = data;
        a.download = fileName;
        document.body.appendChild(a);
        a.style = 'display: none';
        a.target = '_blank';
        a.click();
        a.remove();
    };

    const getReportData = async () => {
        ;
        const params = {
            'coin_symbols': row.symbol,
            'ccy': 'USD'
        };
        let reportData = {};
        await Api.searchCoins(params)
            .then(res => reportData = {
                date: new Date().toLocaleDateString() + '',
                rank: row.rank ? row.rank : '-',
                name: res[0].name,
                symbol: res[0].symbol,
                summary: res[0].description ? res[0].description : '-',
                volumeFlag: res[0].volume_category || '-',
                hashAlgo: res[0].algorithm || '-',
                anonimity: res[0].transaction_anonimity || '-',
                consensusAlgo: res[0].consensus_model || '-',
                assetType: res[0].asset_type || '-',
                mktcapCategory: res[0].market_cap_category || '-',
                volatility: res[0].volatility_category || '-',
                sector: res[0].business_classification || '-',
                mktcap: AppHelpers.numberFormat(res[0].ticker.market_cap) || '-',
                price: AppHelpers.priceFormat(res[0].ticker.lst) || '-',
                dayLow: AppHelpers.priceFormat(res[0].ticker.l_day) || '-',
                dayHigh: AppHelpers.priceFormat(res[0].ticker.h_day) || '-',
                dayVol: AppHelpers.numberFormat(res[0].ticker.vto) || '-',
                chgpct: (res[0].ticker.chgpct * 100).toFixed(2) || '-',
                close: AppHelpers.priceFormat(res[0].performance.close_price) || '-',
                sevenD: (res[0].performance.chgpct_7d * 100).toFixed(2) || '-',
                thirtyD: (res[0].performance.chgpct_30d * 100).toFixed(2) || '-',
                threeM: (res[0].performance.chgpct_3m * 100).toFixed(2) || '-',
                sixM: (res[0].performance.chgpct_6m * 100).toFixed(2) || '-',
                ytd: (res[0].performance.chgpct_ytd * 100).toFixed(2) || '-',
                oneY: (res[0].performance.chgpct_1y * 100).toFixed(2) || '-',
                twoY: (res[0].performance.chgpct_2y * 100).toFixed(2) || '-',
                stableFlag: res[0].stablecoin ? 'Stable' : '-',
                defiFlag: res[0].defi ? 'Defi' : '-',
                mineableFlag: res[0].mineable ? 'Mineable' : '-',
            })
            .catch(err => setError(true));

        if (!error) {
            // positive news
            const pNews = await Api.searchNews({ tags: 'COIN_' + row.symbol, page_size: 5, min_sentiment: 0 }, false)
                .then(res => {
                    return res;
                })
                .catch(err => setError(true));

            for (let i = 0; i < pNews.length; i++) {
                try {
                    reportData[`pnews${i + 1}Date`] = new Date(pNews[i].publication_datetime).toLocaleDateString() + '';
                    reportData[`pnews${i + 1}Title`] = pNews[i].og_metadata.site_name;
                    reportData[`pnews${i + 1}Link`] = formatLongText(pNews[i].title);
                    reportData[`pnews${i + 1}Sentiment`] = (pNews[i].sentiment.title * 100).toFixed(2);
                    reportData[`pUrl${i + 1}`] = pNews[i].link;
                } catch (err) {
                    setError(true);
                    return;
                }
            }

            const nNews = await Api.searchNews({ tags: 'COIN_' + row.symbol, page_size: 5, max_sentiment: 0 }, false)
                .then(res => {
                    return res;
                })
                .catch(err => setError(true));

            for (let i = 0; i < nNews.length; i++) {
                try {
                    reportData[`nnews${i + 1}Date`] = new Date(nNews[i].publication_datetime).toLocaleDateString() + '';
                    reportData[`nnews${i + 1}Title`] = nNews[i].og_metadata.site_name;
                    reportData[`nnews${i + 1}Link`] = formatLongText(nNews[i].title);
                    reportData[`nnews${i + 1}Sentiment`] = (nNews[i].sentiment.title * 100).toFixed(2);
                    reportData[`nUrl${i + 1}`] = nNews[i].link;
                } catch (err) {
                    setError(true);
                    return;
                }
            }
        }

        // similar coins 
        if (!error) {
            let f = {
                page_size: 11,
                asset_type: '',
                coin_symbols: '',
                business_classification: '',
                ccy: 'USD',
                order_by: 'mkt_cap,desc',
                ts_cutoff: 0,
                page_number: 1,
            };

            let links;
            const similarData = await Api.getPromise(`/Coin/GetCoin?coin_symbol=${row.symbol}&language_code=en`).then(res => {
                const info = (res && res.data && res.data.coin_info) || {};
                let asset = assetTypeLists.filter(a => info.asset_type && info.asset_type.includes(a.asset_type_code));
                let bus = business.filter(b => b.alphanumeric_code === info.business_classification_code);
                if (asset[0] && asset[0].asset_type_id) f.asset_type = asset[0].asset_type_id;
                if (bus[0] && bus[0].business_classification_id) f.business_classification = bus[0].business_classification_id;
                f.ccy = 'USD';
                links = parseLinks(res.data);
                return Api.getPromise('/Search/CoinSearch', f)
                    .then(res => {
                        const data = (res && res.data.slice(0, 10)) || [];
                        return data;
                    })
                    .catch(e => setError(true));
            });

            for (let i = 0; i < 10; i++) {
                reportData[`c${i + 1}N`] = similarData[i].name;
                reportData[`c${i + 1}P`] = AppHelpers.priceFormat(similarData[i].ticker.lst);
                reportData[`c${i + 1}C`] = (similarData[i].ticker.chgpct * 100).toFixed(2);
            }

            // social media
            const medias = ['r', 't', 'y'];
            for (let s in medias) {
                const m = medias[s];
                reportData[`${m}Follower`] = '-';
                reportData[`${m}Last`] = '-';
            }

            reportData.gWatcher = '-';
            reportData.gStar = '-';
            reportData.gLast = '-';

            for (let idx in links) {
                if (links[idx].includes('youtube.com')) {
                    const yData = await SocialApi.getSocialInformation(links[idx]).then(res => {
                        return res[0];
                    });
                    reportData.yFollower = yData.followers ? AppHelpers.numberFormat(yData.followers) : '-';
                    reportData.yLast = yData.latest_time ? new Date(yData.latest_time * 1000).toLocaleDateString() + '' : '-';
                }
                else if (links[idx].includes('reddit.com')) {
                    const rData = await SocialApi.getSocialInformation(links[idx]).then(res => {
                        return res[0];
                    });
                    reportData.rFollower = rData.followers ? AppHelpers.numberFormat(rData.followers) : '-';
                    reportData.rLast = rData.latest_time ? new Date(rData.latest_time * 1000).toLocaleDateString() + '' : '-';
                }
                else if (links[idx].includes('twitter.com')) {
                    const tData = await SocialApi.getSocialInformation(links[idx]).then(res => {
                        return res[0];
                    });
                    reportData.tFollower = tData.followers ? AppHelpers.numberFormat(tData.followers) : '-';
                    reportData.tLast = tData.latest_time ? new Date(tData.latest_time * 1000).toLocaleDateString() + '' : '-';
                }
                else if (links[idx].includes('github.com')) {
                    const gData = await SocialApi.getSocialInformation(links[idx]).then(res => {
                        return res[0];
                    });
                    reportData.gWatcher = gData.followers ? AppHelpers.numberFormat(gData.followers) : '-';
                    reportData.gStar = gData.num_likes ? AppHelpers.priceFormat(gData.num_likes) : '-';
                    reportData.gLast = gData.latest_time ? new Date(gData.latest_time * 1000).toLocaleDateString() + '' : '-';
                }
            }
        }

        if (reportData.summary.length > 1250) {
            const shortSummary = reportData.summary.substring(0, 1250).trim();
            reportData.summary = shortSummary;
            reportData.summaryMore = '... read more';
            reportData.summaryUrl = `https://app.cistrader.com/coins/${row.symbol}/detail?ccy=USD`;
            reportData.summaryLink = true;
        }
        else {
            reportData.summaryLink = false;
        }

        return reportData;
    }

    return (
        <div className="gray-container summary">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="section-header">Coin Summary</div>
                <div className="section-header">
                    <div className="blue-border-btn flRight" style={{ width: "180px" }}><a style={{ cursor: "pointer" }} onClick={e => setTakeScreenshot(!takeScreenshot)}>Generate Report</a></div>
                </div>
            </div>

            <hr></hr>
            <div className='flex-coin-overview'>
                <div style={{display:"flex", justifyContent:"space-between"}}>
                    <div style={{ color: "white", fontFamily: "Roboto , sans-serif", fontSize: "18px", fontWeight: "bold" }}>Market Cap Category:  {row.market_cap_category}</div>
                    <div className="green-border-btn three-dot-menu three-dot-menu-coin-details" style={{width:"80px"}}>
                        <a>
                            <img height={15} className="actionable-img" src="/img/plus-normal.svg" alt="" />
                        </a>
                        <div className="table-dropdown-menu">
                            <ul>
                                <li>
                                    <div style={{color:"white",cursor:"pointer"}}
                                        onClick={() => {
                                            openInNewTab(row.website_url);
                                        }}
                                        className="open-modal-event">
                                        Website
                                    </div>
                                </li>
                                {Object.keys(socialMediaLinks).map((el) => {
                                    return <li>
                                        <div style={{color:"white", cursor:"pointer"}}
                                            onClick={() => {
                                                openInNewTab(socialMediaLinks[el]);
                                            }}
                                            className="open-modal-event">
                                            {el}
                                        </div>
                                    </li>
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {!isMobile ? <div id="body-scroll"
                style={{ textAlign: 'justify', height: 250, overflowY: 'scroll', margin: '1em', color: '#fff', paddingRight: 15 }}
                dangerouslySetInnerHTML={{ __html: desc }}
                className="summary-par" />
                :
                <ReadMoreReact text={desc}
                    min={500}
                    ideal={500}
                    max={1500}
                    style={{ color: 'white' }}
                    readMoreText="Read More..." />}
            <div className="clearfix" />
        </div>
    );
};

export default AssetSummary;
