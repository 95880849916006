import React, {useState, useEffect, useRef} from 'react';
import AppHelpers from '../../helpers/AppHelpers';
import Select from '../Forms/Select';
import CoinRow from '../CoinRow';
import CISNavigator from "../../helpers/CISNavigator";
import Utils from "../../helpers/Utils";
import {MultiSelect} from "primereact/multiselect";
import Loading from "../Loading";

const isMobile = Utils.checkMobile();

const GainersLosers = props => {
    const {title, exchange, currencyList, setExchange, selectedExchange, result, history, parentSubType, marketCap, setMarketCap, meta ,loadingC} = props;
    const [options,setOptions] = useState([]);

    // Default assignment for market cap mid large huge
    useEffect(()=>{

        //Get market options
        const newOptions = meta.lists.market_cap_catgeory_list.map(el=>{
            return({value:el.id,name:el.market_cap_category,check:false})
        })
        setOptions(newOptions);
    },[]);


    return (
        isMobile ?
            <>
                <div className="table-head">
                    <div className="table-left">
                        <div className="table-header">{title}</div>
                    </div>
                    {setExchange && (
                        <div className="table-right">
                            <div className={'table-select-input'}>
                                <div className={'sm-select-input small-select'}>
                                    <Select selected={exchange} options={currencyList}
                                            onChange={selected => setExchange(selected.value)}/>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="table-body home-table table-responsive">
                    <table>
                        <thead>
                        <tr>
                            <th>Coin</th>
                            <th>Price</th>
                            <th>Mkt. Cap</th>
                            <th>Vol</th>
                            <th>Volto</th>
                            <th>24h Change%</th>
                        </tr>
                        </thead>
                        <tbody>
                        {result.items.map((row, i) => (
                            <CoinRow
                                key={i}
                                parentSubType={parentSubType}
                                type={'homeTable'}
                                rowClick={() => {
                                    CISNavigator.navigateToCoinDetail(history, row, selectedExchange);
                                    document.body.scrollTop = 0;
                                    document.documentElement.scrollTop = 0;
                                }}
                                row={row}
                                selectedExchange={selectedExchange}
                            />
                        ))}
                        </tbody>
                    </table>
                </div>
            </> :
            <>
                <div className="table-head">
                    <div className="table-left">
                        <div className="filter-bar">
                            <div className="input-title" style={{marginTop:"7px"}}>Market Cap Category:</div>
                            <div className="filter-bar-input create-flex">
                                <div className="sm-select-input multiselect-regular">
                                    <MultiSelect maxSelectedLabels={1} placeholder="Select Market Cap" optionLabel="name" optionValue="value" value={marketCap} options={options} onChange={(e) => setMarketCap(e.value)} filter/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {setExchange && (
                        <div className="table-right">
                            <div className={'table-select-input'}>
                                <div className={'sm-select-input small-select'}>
                                    <Select selected={exchange} options={currencyList}
                                            onChange={selected => setExchange(selected.value)}/>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div>
                    {marketCap.length > 0 &&
                    <div style={{display: "flex",paddingLeft:"10px", flexWrap: "wrap"}}>
                        <div style={{fontSize: "14px", color: "#b2b2b2"}}>
                        </div>
                        {marketCap && marketCap.map(el => {
                            let html;
                            options.forEach((b,i) => {
                                if (b.value === el) {
                                    html = (
                                        <div key={i} style={{
                                            border: "1px solid #b2b2b2",
                                            borderRadius: "5px",
                                            paddingRight: "5px",
                                            marginRight: "5px",
                                            marginBottom: "4px"
                                        }}>
                                            <div style={{padding: "2px", fontSize: "14px", color: "#b2b2b2", display: "flex"}}>
                                                <p style={{margin: "0 0"}}>{b.name}</p><i style={{marginTop: "3px", cursor: "pointer"}}
                                                                                          onClick={() => {
                                                                                              let selected = [...marketCap];
                                                                                              selected.splice(marketCap.indexOf(el), 1);
                                                                                              setMarketCap(selected);
                                                                                          }
                                                                                          } className="fas fa-times"/>
                                            </div>
                                        </div>)
                                }
                            })
                            return html;
                        })
                        }
                    </div>
                    }
                </div>
                <Loading loading={loadingC}>
                    <div className="table-body home-table table-responsive" style={{height: 820}}>
                        <table>
                            <thead>
                            <tr>
                                <th colSpan={3}/>
                                <th colSpan={3}>LAST 24h</th>
                            </tr>
                            <tr>
                                <th>Coin</th>
                                <th>Price</th>
                                <th>Mkt. Cap</th>
                                <th>Vol</th>
                                <th style={{paddingLeft: "0px"}}>Volto</th>
                                <th>Change</th>
                            </tr>
                            </thead>
                            <tbody>
                            {result.items.map((row, i) => (
                                <CoinRow
                                    key={i}
                                    parentSubType={parentSubType}
                                    type={'homeTable'}
                                    rowClick={() => {
                                        CISNavigator.navigateToCoinDetail(history, row, selectedExchange);
                                        document.body.scrollTop = 0;
                                        document.documentElement.scrollTop = 0;
                                    }}
                                    row={row}
                                    selectedExchange={selectedExchange}
                                />
                            ))}
                            </tbody>
                        </table>
                    </div>
                </Loading>
            </>
    );
};

export default GainersLosers;
