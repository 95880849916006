import React from 'react';
import Select from '../Forms/Select';
import PropTypes from 'prop-types';
import CoinRow from '../CoinRow';
import CISNavigator from "../../helpers/CISNavigator";

const FullTable = (props, context) => {
  const {t} = context;
  const {title, exchange, currencyList, setExchange, selectedExchange, result, weight, history, parentSubType} = props;
  return (
    <>
      <div className="table-head">
        <div className="table-left">
          <div className="table-header">{title}</div>
        </div>
        {setExchange && (
          <div className="table-right">
            <div className={'table-select-input'}>
              <div className={'sm-select-input small-select'}>
                <Select selected={exchange} options={currencyList} onChange={selected => setExchange(selected.value)} />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="table-body table-responsive">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Price</th>
              <th>
                Mkt. Cap <i data-html={true} data-tip={t('captDesc')} className="fas fa-info-circle" />
              </th>
              <th>Circ. Supply</th>
              <th>Max. Supply</th>
              <th>
                24h Vol
                <i data-tip={t('totalVDesc')} className="fas fa-info-circle" />
              </th>
              <th>24h Change</th>
              {weight && <th>Weight</th>}
            </tr>
          </thead>
          <tbody>
            {result.items.map((row, i) => (
              <CoinRow
                key={i}
                parentSubType={parentSubType}
                type={'fullTable'}
                rowClick={() => {
                  CISNavigator.navigateToCoinDetail(history, row, selectedExchange)
                  document.body.scrollTop = 0;
                  document.documentElement.scrollTop = 0;
                }}
                row={row}
                selectedExchange={selectedExchange}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
FullTable.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default FullTable;
