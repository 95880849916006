import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
// import withRouter from "react-router-dom/es/withRouter";
import React, {useEffect} from "react";
import RealtimeUtils from "../../helpers/RealtimeUtils";
import ReactTooltip from "react-tooltip";
import CoinRow from "./CoinRow";
import CISNavigator from "../../helpers/CISNavigator";
import DataMappers from "../../helpers/DataMappers";

const CoinTable = (props, context) => {
    // To show tooltip values
    const {t} = context;
    const {
        coinData, // Coin data to show
        exchange, // Exchange information
        history, // Used to navigate pages
        sort, // Sort information
        setSort, // Indicates if the table has sorting, if not given sorting is disabled
        disabledColumns, // Disabled columns in table
        addWatchlist, // Called when add watchlist is clicked with the selected coin
        addAlert, // Called when add alert is clicked with the selected coin
        disableRealTime // Disable real time updates
    } = props;

    // Use realtime ticker updates
    const [tickerList, setChannel, initSocketConnection] = RealtimeUtils.useRealTimeTicker([], DataMappers.coinTickerMapper);

    useEffect(() => {
        // Every coin at least must contain ticker symbol
        coinData.filter(el => !el.ticker || !el.ticker.symbol)
            .forEach(el => {
                if (el.ticker) {
                    el.ticker.symbol = RealtimeUtils.generateTicker(el.symbol, exchange.value);
                } else {
                    el.ticker = { symbol: RealtimeUtils.generateTicker(el.symbol, exchange.value) };
                }
            });

        // Generate tickers
        const tickers = coinData.map(el => el.ticker.symbol);

        // Generate real time trackers
        initSocketConnection(tickers, disableRealTime);
    }, [coinData]);

    // Sort related variables
    const sortChange = index => sort && (sort.s === 'desc' ? {i: index, s: 'asc'} : {i: index, s: 'desc'});
    const sortArrow = index => sort && sort.i === index && <i style={{fontSize: 12}} className={`fa fa-angle-${sort.s === 'desc' ? 'up' : 'down'}`} />;

    // Check if column is enabled
    const isEnabled = col_id => !disabledColumns.includes(col_id);

    // Navigate to coin page
    const coinSelected = selectedRow => CISNavigator.navigateToCoinDetail(history, selectedRow, exchange)

    return (
        <div>
            <ReactTooltip html={true} />
            <table className="new-coins enumarable-table linked-table mobile-coins-sector" style={{"tableLayout" : "fixed"}}>
                <thead>
                <tr>
                    <th colSpan={isEnabled("symbol") ? 2 : 6} className={"header-left-aligned"}>Name</th>
                    <th colSpan={3}>Price</th>
                    {isEnabled("market_cap") && setSort && <th colSpan={3} onClick={() => setSort(sortChange('mkt_cap'))} style={{cursor: 'pointer'}}>
                        Mkt. Cap <i data-html={true} data-tip={t('captDesc')} className="fas fa-info-circle" /> {sortArrow('mkt_cap')}
                    </th>}
                    {isEnabled("market_cap") && !setSort && <th colSpan={3}>Mkt. Cap <i data-html={true} data-tip={t('captDesc')} className="fas fa-info-circle" /></th>}
                    {isEnabled("total_coins") && <th colSpan={3}>Circ. Supply</th>}
                    {isEnabled("aval_coins") && <th colSpan={3}>Max Supply</th>}
                    {isEnabled("vol24") && setSort && <th colSpan={3} onClick={() => setSort(sortChange('vto'))} style={{cursor: 'pointer'}}>
                        24h Vol <i data-tip={t('totalVDesc')} className="fas fa-info-circle" /> {sortArrow('vto')}
                    </th>}
                    {isEnabled("vol24") && !setSort && <th colSpan={3}>24h Vol <i data-tip={t('totalVDesc')} className="fas fa-info-circle" /> </th>}
                    {isEnabled("chngpct") && setSort && <th colSpan={3} onClick={() => setSort(sortChange('chgpct'))} style={{cursor: 'pointer'}}>
                        24h Change {sortArrow('chgpct')}
                    </th>}
                    {isEnabled("chngpct") && !setSort && <th colSpan={3}>24h Change</th>}
                    {isEnabled("weight") && <th colSpan={3}>Weight</th>}
                    {isEnabled("set_watchlist") && <th colSpan={1}>Actions</th>}
                </tr>
                </thead>
                <tbody>
                {tickerList.map((row, idx) => (coinData[idx] &&
                    <CoinRow key={row} coinRow={coinData[idx]} setChannel={setChannel} disabledColumns={disabledColumns} coinSelected={coinSelected} addWatchlist={addWatchlist} addAlert={addAlert}/>
                ))}
                </tbody>
            </table>
        </div>
    )
};


CoinTable.contextTypes = {
    t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    return {
        user: state.user,
    };
};


export default connect(mapStateToProps)(withRouter(CoinTable));
