import React, {forwardRef, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import {setLoading} from "../../actions/generalAction";
import Loading from "../../components/Loading";
import Select from "../../components/Forms/Select";
import Utils from "../../helpers/Utils";
import DatePicker from "react-datepicker";
import {toastr} from "react-redux-toastr";
import PortfolioApi from "../../api/PortfolioApi";
import moment from 'moment';
import UserApi from "../../api/UserApi";


const ImportTradeDocument = props => {
    const {close, show, portfolioAccounts, exchanges, allowedExchanges} = props;
    const [manualAccounts, setManualAccounts] = useState({data: [], loading: true})
    const [manualAccount, setManualAccount] = useState({});

    // Selected exchange
    const [exchange, setExchange] = useState({});

    // Allowed Exchange List
    const [allowedExchangeList, setAllowedExchangeList] = useState([]);

    // File state
    const [file, setFile] = useState(null);

    useEffect(() => {
        if (exchanges.length > 0) {
            const exchangeList = allowedExchanges.map(el => {
                let exc = {};
                exchanges.forEach(item => {
                    if (item.id === el) {
                        exc = item;
                    }
                })
                return exc;
            });
            setAllowedExchangeList(exchangeList);
            setExchange(exchangeList[0]);
        }
    }, [exchanges])


    useEffect(() => {
        if (portfolioAccounts && portfolioAccounts.data.length > 0 && show) {
            let manuals = portfolioAccounts.data.filter(el => el.type === "manual");
            if (manuals.length > 0) {
                setManualAccounts({data: manuals, loading: false});
                setManualAccount(manuals[0]);
            } else {
                toastr.error('', 'There is no manual account linked to portfolio.');
                close();
            }
        } else if (show) {
            toastr.error('', 'There is no account linked to portfolio.');
            close();
        }
    }, [portfolioAccounts, show]);


    // Multi select change function
    const onChange = (field, selected) => {
        if (field === "manual") {
            setManualAccount(selected);
        } else if (field === "exchange") {
            setExchange(selected);
        }
    };

    const onSave = () => {
        if (file) {
            PortfolioApi.importTradeDocument(manualAccount.id, exchange.id, file).then(resp => {
                toastr.success('', 'Successfully imported document!');
                setFile(null);
                close();
            }).catch(err => {
                console.error(err);
                toastr.error('Something went wrong while importing document!');
            });
        } else {
            toastr.error('You must pick a file!');
        }
    };

    // Pick file
    const selectFile = f => {
        let fr = new FileReader();
        if (f.length > 0) {
            fr.readAsDataURL(f[0]);
            setFile(f[0]);
        } else {
            setFile(null);
        }
    };

    return (
        <div className={`general-modal ${show ? 'active' : ''}`} id="set-news-alert">
            <div className="vAlignWr">
                <div className="vAlign">
                    <div className="white-modal-inner set-alert">
                        <div className="modal-blue-header">
                            {"Import Trade Document"}
                            <div onClick={close} className="close-modal" data-target="set-news-alert">
                                <img src="/img/close-white.svg" alt=""/>
                            </div>
                        </div>
                        <div style={{minWidth: '50vw'}} className="modal-white-body">
                            <div className="general-tab-area">
                                <div className="general-tab-menu">
                                    <ul>
                                        <li className="active">
                                            <a href="#" data-target="price-alert">
                                                {"Import Trade Document"}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div style={{color:"gray", padding:"15px", background: "#292e33", borderBottom:"1px solid rgb(25, 29, 33)",width:"800px",textAlign:"justify"}}>
                                    <i style={{marginRight:"5px"}} className="fas fa-info-circle"/>
                                    You can automatically import your trade history to your account by uploading documents. Using "Exchange" option, please specify which exchange you exported your trade history document. Currently only importing documents from supported exchanges is allowed.
                                </div>
                                <Loading loading={manualAccounts.loading}>
                                    <div className="general-tab-body-all">
                                        <div className="general-tab-body active" id="news-alert">
                                            <div className="set-news-alert">
                                                <div className="input-table">
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td style={{width: "30%"}}>
                                                                <div className="spa-text">Manual Accounts:</div>
                                                            </td>
                                                            <td>
                                                                <div className="sm-select-input">
                                                                    <Select
                                                                        selected={manualAccount.id || 0}
                                                                        optionValue={'id'}
                                                                        optionLabel={'name'}
                                                                        options={manualAccounts.data || []}
                                                                        onChange={selected => onChange("manual", selected)}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{width: "30%"}}>
                                                                <div className="spa-text">Document:</div>
                                                            </td>
                                                            <td>
                                                                <div className="sm-select-input">
                                                                    {/*fileTypes: [, "xlsb", "xlsm", , "xml"*/}
                                                                    <input type="file" id="profile-img"
                                                                           onChange={e => selectFile(e.target.files)}
                                                                           accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, text/xml"/>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{width: "30%",}}>

                                                            </td>
                                                            <td>
                                                                <div style={{color:"gray",fontSize:"12px"}}>
                                                                    <i style={{marginRight:"5px"}} className="fas fa-info-circle"/>
                                                                    Supported File Types: xlsx, xlsb, xlsm, xls, xml, csv, txt
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="spa-text">Exchange:</div>
                                                            </td>
                                                            <td>
                                                                <div className="sm-select-input">
                                                                    <Select
                                                                        selected={exchange.id || 0}
                                                                        optionValue={'id'}
                                                                        optionLabel={'name'}
                                                                        options={allowedExchangeList || []}
                                                                        onChange={selected => onChange("exchange", selected)}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    <div className="clearfix"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Loading>
                            </div>
                        </div>
                        <div className="modal-gray-footer">
                            <div className="form-buttons">
                                <div className="form-flex">
                                    <div className="form-flex-inner" style={{width: "120px"}}>
                                        <div className="light-gray-btn">
                                            <button onClick={close} className="close-modal"
                                                    data-target="set-news-alert">
                                                CANCEL
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-flex-inner" style={{width: "120px"}}>
                                        <div className="light-blue-btn">
                                            <button onClick={onSave}>
                                                {"IMPORT"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setFullLoading: data => dispatch(setLoading(data)),
    };
};

const mapStateToProps = state => {
    return {
        meta: state.meta,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportTradeDocument);
