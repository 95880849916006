import React, {forwardRef, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import {setLoading} from "../../actions/generalAction";
import Loading from "../../components/Loading";
import Select from "../../components/Forms/Select";
import Utils from "../../helpers/Utils";
import DatePicker from "react-datepicker";
import {toastr} from "react-redux-toastr";
import PortfolioApi from "../../api/PortfolioApi";
import moment from 'moment';

const types = [
    {id: "DEPOSIT", name: "Deposit"},
    {id: "WITHDRAWAL", name: "Withdrawal"}
]


const PortfolioAddTransfer = props => {
    const {close, show, portfolioAccounts, meta, selectedTransfer, setSelectedTransfer} = props;

    const ref = React.createRef();
    const StartDateInput = forwardRef(({value, onClick}, ref) => (
        <button className={'sm-select-input news'} onClick={onClick} ref={ref}>
            {value || "Start"}
        </button>
    ))

    const [manualAccounts, setManualAccounts] = useState({data: [], loading: true})
    const [manualAccount, setManualAccount] = useState({});
    const [type, setType] = useState({id: "DEPOSIT", name: "Deposit"});
    const [amount, setAmount] = useState(0);
    const [symbol, setSymbol] = useState(Utils.prepareCoinSelections(meta.allCoins)[0]);
    const [symbols, setSymbols] = useState(Utils.prepareCoinSelections(meta.allCoins))
    const [time, setTime] = useState('');
    const [transferId, setTransferId] = useState(0);

    useEffect(() => {
        if (portfolioAccounts && portfolioAccounts.data.length > 0 && show) {
            let manuals = portfolioAccounts.data.filter(el => el.type === "manual");
            if (manuals.length > 0) {
                setManualAccounts({data: manuals, loading: false});
                if (selectedTransfer.account_id) {
                    setTime(selectedTransfer.transfer_ts);
                    setTransferId(selectedTransfer.id);
                    setAmount(selectedTransfer.asset_amount);
                    setSymbol({symbol: selectedTransfer.source_native_asset_symbol});
                    setType({id: selectedTransfer.type});
                    setManualAccount({id: selectedTransfer.account_id});
                } else {
                    setManualAccount(manuals[0]);
                    setTime('');
                    setAmount(0);
                }
            } else {
                toastr.error('', 'There is no manual account linked to portfolio.');
                close();
            }
        } else if (show) {
            toastr.error('', 'There is no account linked to portfolio.');
            close();
        }
    }, [portfolioAccounts, show]);

    const onChange = (field, selected) => {
        if (field === "manual") {
            setManualAccount(selected);
        }
        if (field === "type") {
            setType(selected);
        }
        if (field === "amount") {
            setAmount(selected);
        }
        if (field === "symbol") {
            setSymbol(selected);
        }

    };

    const onSave = () => {
        if (!manualAccount.id) {
            toastr.error('', 'You must choose account.');
            return;
        } else if (!time) {
            toastr.error('', 'You must choose time.');
            return;
        } else if (amount == 0) {
            toastr.error('', 'You must enter amount.');
            return;
        }


        let f = {
            "transfer_ts": moment.utc(time).unix() * 1000,
            "type": type.id,
            "asset_symbol": symbol.symbol,
            "asset_amount": amount
        }


        if (selectedTransfer.account_id) {
            PortfolioApi.updateTransfer(manualAccount.id, transferId, f).then(resp => {
                toastr.success('', 'Successfully updated trade');
                setSelectedTransfer({});
                close();
            }).catch(err => {
                console.error(err);
                toastr.error('Something went wrong while updating trade');
            });
        } else {
            PortfolioApi.addTransfer(manualAccount.id, f).then(resp => {
                toastr.success('', 'Successfully add transfer');
                close();
            }).catch(err => {
                console.error(err);
                toastr.error('Something went wrong while adding transfer');
            });
        }


    };

    return (
        <div className={`general-modal ${show ? 'active' : ''}`} id="set-news-alert">
            <div className="vAlignWr">
                <div className="vAlign">
                    <div className="white-modal-inner set-alert">
                        <div className="modal-blue-header">
                            {selectedTransfer.account_id ? "Update Transfer" : "Add Transfer"}
                            <div onClick={close} className="close-modal" data-target="set-news-alert">
                                <img src="/img/close-white.svg" alt=""/>
                            </div>
                        </div>
                        <div style={{minWidth: '50vw'}} className="modal-white-body">
                            <div className="general-tab-area">
                                <div className="general-tab-menu">
                                    <ul>
                                        <li className="active">
                                            <a href="#" data-target="price-alert">
                                                {selectedTransfer.account_id ? "Update Transfer" : "Add Transfer"}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <Loading loading={manualAccounts.loading}>
                                    <div className="general-tab-body-all">
                                        <div className="general-tab-body active" id="news-alert">
                                            <div className="set-news-alert">
                                                <div className="input-table">
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className="spa-text">Manual Accounts</div>
                                                            </td>
                                                            <td>
                                                                <div className="sm-select-input">
                                                                    <Select
                                                                        selected={manualAccount.id || 0}
                                                                        optionValue={'id'}
                                                                        optionLabel={'name'}
                                                                        options={manualAccounts.data || []}
                                                                        onChange={selected => onChange("manual", selected)}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="spa-text">Time</div>
                                                            </td>
                                                            <td>
                                                                <div className={"sm-select-input"}>
                                                                    <DatePicker
                                                                        selected={time}
                                                                        onChange={date => {
                                                                            setTime(date);
                                                                        }}
                                                                        selectsStart
                                                                        customInput={<StartDateInput ref={ref}/>}
                                                                        timeInputLabel="Time:"
                                                                        dateFormat="MM/dd/yyyy h:mm aa"
                                                                        showTimeInput
                                                                        // isClearable={!!time}
                                                                        startDate={time}/>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="spa-text">Type</div>
                                                            </td>
                                                            <td>
                                                                <div className="sm-select-input">
                                                                    <Select
                                                                        selected={type.id || 0}
                                                                        optionValue={'id'}
                                                                        optionLabel={'name'}
                                                                        options={types || []}
                                                                        onChange={selected => onChange("type", selected)}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="spa-text">Symbol</div>
                                                            </td>
                                                            <td>
                                                                <div className="sm-select-input">
                                                                    <Select
                                                                        selected={symbol.symbol || 0}
                                                                        optionValue={'symbol'}
                                                                        optionLabel={'label'}
                                                                        options={symbols || []}
                                                                        onChange={selected => onChange("symbol", selected)}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="spa-text">Amount</div>
                                                            </td>
                                                            <td>
                                                                <div className="bottom-border-input">
                                                                    <input type="number" placeholder="Account Name"
                                                                           min={0} maxLength={50} value={amount}
                                                                           onChange={val => onChange('amount', val.target.valueAsNumber)}/>
                                                                </div>
                                                            </td>
                                                        </tr>


                                                        </tbody>
                                                    </table>
                                                    <div className="clearfix"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Loading>
                            </div>
                        </div>
                        <div className="modal-gray-footer">
                            <div className="form-buttons">
                                <div className="form-flex">
                                    <div className="form-flex-inner" style={{width: "120px"}}>
                                        <div className="light-gray-btn">
                                            <button onClick={close} className="close-modal"
                                                    data-target="set-news-alert">
                                                CANCEL
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-flex-inner"
                                         style={selectedTransfer.account_id ? {width: "150px"} : {width: "120px"}}>
                                        <div className="light-blue-btn">
                                            <button onClick={onSave}>
                                                {selectedTransfer.account_id ? "UPDATE TRANSFER" : "ADD TRANSFER"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setFullLoading: data => dispatch(setLoading(data)),
    };
};

const mapStateToProps = state => {
    return {
        meta: state.meta,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioAddTransfer);

