import io from 'socket.io-client';
import Links from "../constants/Links";

// Keep track of active symbols for token refreshes
const activeSymbols = new Set();
let socket;

const SocketHelpers = {

  /**
   * Set token to io connection, requires new io connection
   * @param token
   */
  updateToken(token) {
    // If socket defined update auth token
    if (socket) {
      socket.emit('m', {action: 'authenticate', data: token});
      if (socket.query) socket.query.token = token;
    } else {
      socket = io(Links.REAL_TIME_SERVER, {path: "/", transports: ['websocket'], query: {token: token}, rememberUpgrade: true});
    }

    socket && socket.once("connect", function () {
      this.subscribe(Array.from(activeSymbols));
    }.bind(this));

    socket && socket.on("reconnect", function () {
      this.subscribe(Array.from(activeSymbols));
    }.bind(this));
  },

  /**
   * Subscribe to given data array
   *
   * @param data E.g. List of symbols to subscribe
   */
  subscribe(data) {
    data.forEach(el => activeSymbols.add(el));
    socket && socket.emit('m', {action: 'subscribe', data: data});
  },

  /**
   * Handle messages directly with callback. This is required for removing specific callback. E.g. remove
   * index real time updates when you leave that page. Right now all the coin updating real time time data handlers
   * are piling up.
   *
   * @param data Where to subscribe
   * @param callback Callback to handle messages
   */
  coinsRaw(data, callback) {
    this.subscribe(data);
    socket && socket.on('m', callback);
  },

  /**
   * Close connections to specific listeners with given callback
   *
   * @param data Data to be unsubscribed from
   * @param callback Callback to handle messages
   */
  close(data, callback) {
    // Clean listeners
    data.forEach(el => activeSymbols.delete(el));
    socket && socket.emit('m', {action: 'unsubscribe', data: data });
    socket && socket.off('m', callback);
  },
};

export default SocketHelpers;
