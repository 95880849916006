import React, {useEffect, useState} from 'react';
import PortfolioApi from "../../api/PortfolioApi";
import Select from "../../components/Forms/Select";
import {setLoading} from "../../actions/generalAction";
import {connect} from "react-redux";
import AppHelpers from "../../helpers/AppHelpers";
import Loading from "../../components/Loading";


const MyPortfolio = (props) => {

    const {
        setShowP, // Set variable controlling Create or Edit portfolio modal show or not
        showP, // Show variable controlling Create or Edit portfolio modal show or not
        showD, // Show variable controlling Delete portfolio modal show or not
        setShowD, //  Set variable controlling Delete portfolio modal show or not
        myPortfolio, // Selected portfolio information
        setMyPortfolio, // Set selected portfolio information
        setEditPortfolio, // Set selected edit portfolio information
        setCcy, // Set selected currency
        ccy, // Selected currency
        meta, // Meta data
        total, // Total portfolio balance
        portfolioAccounts, // All bound portfolio accounts
        setPortfolioAccountIds, // Set portfolio account ids
        pABLoading // Portfolio Account Balance loading control

    } = props;

    // All portfolio accounts
    const [portfolios, setPortfolios] = useState({data: [], loading: true});
    // All currency
    const [ccys, setCcys] = useState([]);
    // All selected account for performance
    const [selectedAccounts, setSelectedAccounts] = useState([]);

    useEffect(()=>{
        if(portfolioAccounts && portfolioAccounts.data){
            setSelectedAccounts(portfolioAccounts.data);
        }
    },[portfolioAccounts])

    // Re arrange portfolio accounts when some portfolio edited or deleted
    useEffect(() => {
        if (!showP && !showD) {
            PortfolioApi.getPortfolios().then(resp => {
                if (resp.length > 0) {
                    setPortfolios({data: resp, loading: false});
                    setMyPortfolio(resp[0]);
                } else {
                    setPortfolios({data: [], loading: false});
                    setMyPortfolio({name: "-", id: '-'});
                }
            });
        }
    }, [showD,showP]);



    const onChange = (field, selected) => {
        if (field === "ccy") {
            setCcy(selected);
        }
    };

    useEffect(() => {
        const newCcys = getCcys();
        setCcys(newCcys);
        setCcy(newCcys[19]);
    }, []);


    // Get currency function from meta data
    const getCcys = () => {
        return meta.lists.key_currency_list.map((el) => {
            return ({ccy: el[0], name: el[0], type: el[2]});
        });
    }


    return (
        <div className="gray-container" style={{height: "285px",flexDirection:"column",display:"flex"}}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div style={{color: "white",fontSize:"20px",display:"flex"}}>
                    Portfolio
                    <div style={{color: "gray",fontSize:"14px",marginLeft:"5px",marginTop:"7px"}}>
                         BETA
                    </div>
                </div>

                <div style={{display: "flex"}}>
                    { !(myPortfolio.name === "empty") && !(pABLoading) &&
                    <div className="sm-select-input" style={{width: "80px", zIndex: "101", marginTop: "3px"}}>
                        <Select
                            selected={ccy.ccy || 0}
                            optionValue={'ccy'}
                            optionLabel={'name'}
                            options={ccys || []}
                            onChange={selected => onChange("ccy", selected)}
                        />
                    </div>

                    }
                    <div style={{width: 110, marginLeft: "10px"}} className="blue-border-btn flRight">
                        <a style={{cursor: "pointer"}} onClick={() => {
                            setShowP(true);
                        }}>Create New</a>
                    </div>
                </div>
            </div>

            <Loading loading={myPortfolio.name === "empty"}>
                {
                    myPortfolio.id &&  myPortfolio.id !== "-" ?
                        <React.Fragment>
                            <div className="border-bottom-input floating-label-wrap">
                                <div className="sm-select-input floating-label-field floating-label-field--s1"
                                     style={{paddingLeft: "0px", paddingTop: "15px", zIndex: 100}}>
                                    <Select
                                        selected={myPortfolio.id || null}
                                        optionValue={'id'}
                                        optionLabel={'name'}
                                        options={portfolios.data || []}
                                        onChange={selected => setMyPortfolio({name: selected.name, id: selected.id})}/>
                                </div>
                            </div>
                            <Loading loading={pABLoading}>
                                <div className="row" style={{paddingTop: "10px",paddingRight:"6px",paddingLeft:"2px"}}>

                                    <div style={{color: "white"}} className="col-md-6 col-sm-6">
                                        Current Value
                                        <div style={{display: "flex", justifyContent: "space-between", paddingTop: "15px"}}>
                                            <div style={{fontWeight: "bold", fontSize: "16px"}}>
                                                {total.total == 0 ? "0" : AppHelpers.priceFormat(total.total)}
                                            </div>
                                            <div>
                                                {ccy.ccy}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{color: "white"}} className="col-md-6 col-sm-6">
                                        Change Today
                                        <div style={total.change == 0 ? {
                                            display: "flex",
                                            justifyContent: "space-between",
                                            paddingTop: "15px",
                                        } : total.change > 0 ? {
                                                display: "flex",
                                                justifyContent: "space-between",
                                                paddingTop: "15px",
                                                color: "#7ac231"
                                            } :
                                            {
                                                display: "flex",
                                                justifyContent: "space-between",
                                                paddingTop: "15px",
                                                color: "red"
                                            }}>
                                            <div style={{fontWeight: "bold", fontSize: "16px"}}>
                                                {total.change == 0 ? "0" : total.change > 0 ? "+" + AppHelpers.priceFormat(total.change) : "" + AppHelpers.priceFormat(total.change)}
                                            </div>
                                            <div>
                                                {total.perc == 0 ? "0" : total.perc > 0 ? "" + total.perc.toFixed(2) + " %" : "" + total.perc.toFixed(2) + " %"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-end", height:"100%"}}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-end',
                                    }}>
                                        <div style={{width: 120}} className="blue-border-btn flRight">
                                            <a style={{cursor: "pointer"}} onClick={() => {
                                                setEditPortfolio(myPortfolio);
                                                setShowP(true);
                                            }}>Edit</a>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-end',
                                        marginLeft: "10px"
                                    }}>
                                        <div style={{width: 120}} className="red-border-btn flRight">
                                            <a style={{cursor: "pointer"}} onClick={() => {
                                                setEditPortfolio(myPortfolio);
                                                setShowD(true);
                                            }}>Delete</a>
                                        </div>
                                    </div>
                                </div>
                            </Loading>

                        </React.Fragment>
                        :
                        <div style={{display: "flex", justifyContent: "center", width: "100%", height: "150px"}}>
                            <div style={{
                                color: '#fff',
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center"
                            }}>There is no portfolio.
                            </div>
                        </div>
                }
            </Loading>

        </div>
    );
}
;


const mapDispatchToProps = dispatch =>
{
    return {
        setFullLoading: data => dispatch(setLoading(data)),
    };
}
;

const mapStateToProps = state =>
{
    return {
        meta: state.meta,
    };
}
;

export default connect(mapStateToProps, mapDispatchToProps)(MyPortfolio);
