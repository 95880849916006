import React, {useEffect, useState} from 'react';
import SocialApi from "../../api/SocialApi/social";
import AppHelpers from "../../helpers/AppHelpers";
import Loading from "../../components/Loading";

const CoinSocialMedia = props => {
    const {coin} = props;
    //get twitter link
    let twitterLink = "";
    //get youtube link
    let youtubeLink = "";
    //get reddit link
    let redditLink = "";

    // Social information
    const [twitter, setTwitter] = useState(null);
    const [github, setGithub] = useState(null);
    const [youtube, setYoutube] = useState(null);
    const [reddit, setReddit] = useState(null);
    //github loading
    const [loadingG, setLoadingG] = useState(true);
    //youtube loading
    const [loadingY, setLoadingY] = useState(true);
    //twitter loading
    const [loadingT, setLoadingT] = useState(true);
    //reddit loading
    const [loadingR, setLoadingR] = useState(true);

    //Parse links
    const parseLinks = () =>{
        coin.coin_info.additional_links.forEach(l => {
            const url = new URL(l);
            if(url.hostname === 'twitter.com'){
                twitterLink=l;
            }
            else if(url.hostname === 'reddit.com'){
                redditLink=l;
            }
            else if(url.hostname === 'www.youtube.com'){
                youtubeLink=l;
            }
        });
    }

    //Fetch Youtube link and fill elements
    const fetchYoutube = () =>{
        SocialApi.getSocialInformation(youtubeLink).then(resp =>{
            const _youtube = resp[0];
            if (_youtube && !_youtube.err) setYoutube(_youtube);
            setLoadingY(false);
        });
    }
    //Fetch Twitter link and fill elements
    const fetchTwitter = () =>{
        SocialApi.getSocialInformation(twitterLink).then(resp =>{
            const _twitter = resp[0];
            if (_twitter && !_twitter.err) setTwitter(_twitter);
            setLoadingT(false);
        });
    }
    //Fetch Reddit link and fill elements
    const fetchReddit = () =>{
        SocialApi.getSocialInformation(redditLink).then(resp =>{
            const _reddit = resp[0];

            if (_reddit && !_reddit.err) setReddit(_reddit);
            setLoadingR(false);
        });
    }
    //Fetch GitHub link and fill elements
    const fetchGitHub = () =>{
        SocialApi.getSocialInformation(coin.coin_info.blockchain_source_code_url).then(resp =>{
            const _github = resp[0];

            if (_github && !_github.err) setGithub(_github);
            setLoadingG(false);
        });
    }

    useEffect(() => {
        // Fetch social on prop change
        if (coin && coin.coin_info) {
            parseLinks();
            fetchYoutube();
            fetchReddit();
            fetchTwitter();
            fetchGitHub();
        }
    }, [coin]);

    // Returns twitter post design, special retweet design
    const getTwitterPost = (post, ac,l) => {
        const isRT = post[0] === "R" && post[1] === "T";

        const newPost = post.replaceAll("amp;","");

        return (
            <div className="bli-inner1">
                { l.is_retweet && <div style={{color: 'rgb(136, 153, 166)', fontSize: '12px', paddingLeft: "15px", paddingTop: "7px"}}>{ac} retweeted</div>}
                <div style={{display:"flex"}}>
                    <div className="twitterpost" style={{padding:"7px 0px", width:"65%"}}>
                        <div className={"social-media-post"}>
                            {isRT ? post.substring(post.indexOf(":") + 2, post.length) : newPost}
                        </div>
                    </div>
                    <div className="twitterpost" style={{padding:"20px 0px",color:"white", width:"35%"}}>
                        <div style={{display:"flex",justifyContent:"flex-end"}}>
                            <div style={{padding:"0px 15px",color:"#2196F3"}}>
                                <i className="fas fa-retweet fa-2x" style={{paddingRight:"5px",paddingLeft:"5px"}}></i>
                                <p style={{textAlign:"center",fontSize:"17px",fontWeight:"bold"}}>{l.retweet_count}</p>
                            </div>
                            <div style={{padding:"0px 15px",color:"#7ac231"}}>
                                <i className="far fa-heart fa-2x" style={{paddingRight:"5px",paddingLeft:"5px"}}></i>
                                <p style={{textAlign:"center",fontSize:"17px",fontWeight:"bold"}}>{l.fav_count}</p>
                            </div>
                            <div className="post_link" style={{padding:"0px 15px"}}>
                                <a href={l.link} target="_blank"><i className="fas fa-external-link-alt fa-2x" style={{paddingRight:"5px",paddingLeft:"5px"}}></i></a>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="subsection-linev2" style={{marginTop: "1vh"}}/>
            </div>
        )
    }

    // Get visual design for reddit posts
    const getRedditPosts = (post,r) => {
        post = post.replaceAll("amp;","");
        return (
            <div className="bli-inner1">
                <div style={{display:"flex",paddingTop:"25px"}}>
                    <div className="twitterpost" style={{padding:"7px 0px", width:"75%"}}>
                        <div className={"social-media-post"}>
                            {post}
                        </div>
                    </div>
                    <div className="twitterpost" style={{padding:"20px 0px",color:"white", width:"25%"}}>
                        <div style={{display:"flex",justifyContent:"flex-end"}}>
                            <div style={{padding:"0px 15px",color:"#7ac231"}}>
                                <i className="fas fa-arrow-up fa-2x" style={{paddingRight:"5px",paddingLeft:"5px"}}></i>
                                <p style={{textAlign:"center",fontSize:"17px",fontWeight:"bold"}}>{r.upvote}</p>
                            </div>
                            <div className="post_link" style={{padding:"0px 15px"}}>
                                <a href={r.link} target="_blank"><i className="fas fa-external-link-alt fa-2x" style={{paddingRight:"5px",paddingLeft:"5px"}}></i></a>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="subsection-linev2" style={{marginTop: "1vh"}}/>
            </div>
        );
    }
    // Get visual design for youtube posts
    const getYoutubePosts = (post,y) => {
        post = post.replaceAll("amp;","");
        return (
            <div className="bli-inner1">
                <div style={{display:"flex"}}>
                    <div className="twitterpost" style={{padding:"7px 0px", width:"55%"}}>
                        <div className={"social-media-post"}>
                            {post}
                        </div>
                    </div>
                    <div className="twitterpost" style={{padding:"20px 0px", width:"45%"}}>
                        <div style={{display:"flex",justifyContent:"flex-end"}}>
                            <div style={{padding:"0px 15px",color:"#2196F3"}}>
                                <i className="far fa-eye fa-2x" style={{paddingRight:"5px",paddingLeft:"5px"}}></i>
                                <p style={{textAlign:"center",fontSize:"17px",fontWeight:"bold"}}>{y.view_count}</p>
                            </div>
                            <div style={{padding:"0px 15px",color:"#7ac231"}}>
                                <i className="far fa-thumbs-up fa-2x" style={{paddingRight:"5px",paddingLeft:"5px"}}></i>
                                <p style={{textAlign:"center",fontSize:"17px",fontWeight:"bold"}}>{y.like_count}</p>
                            </div>
                            <div style={{padding:"0px 15px",color:"#c11e0f"}}>
                                <i className="far fa-thumbs-down fa-2x" style={{paddingRight:"5px",paddingLeft:"5px"}}></i>
                                <p style={{textAlign:"center",fontSize:"17px",fontWeight:"bold"}}>{y.dislike_count}</p>
                            </div>
                            <div className="post_link" style={{padding:"0px 15px"}}>
                                <a href={y.link} target="_blank"><i className="fas fa-external-link-alt fa-2x" style={{paddingRight:"5px",paddingLeft:"5px"}}></i></a>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="subsection-linev2" style={{marginTop: "1vh"}}/>
            </div>
        );
    }

    function rounded(number) {
        if (number > 1000) {
            return Math.round(number / 1000) + ' K';
        } else
            return number;
    }

    return (
        <div className="overview-inner">
            <div className="overview-row" style={{paddingTop:"10px"}}>
                <div className="col-md-6 coin-chart-table" style={{paddingRight: "10px", paddingLeft: "10px"}}>
                    <div className="chart-area" style={{height: "100%" ,marginTop:"0px"}}>
                        <div style={{paddingRight: "20px", paddingLeft: "20px", paddingTop: "10px"}}>
                            <div className="chart-areaforsocialmedia" style={{marginTop: "1vh",overflowY:"scroll"}}>
                                <div className="modal-blue-header-socailmedia" onClick={() => {twitter && window.open(twitter.link)}}>
                                    <div className="remove-image-class" style={{height: "50px"}}>
                                        <img className={"social-media-logo"} style={{marginTop: "0.5vh"}} src="/img/twitterlogo.png"/>
                                    </div>
                                    <div className={"social-media-name"}>{twitter && twitter.name}</div>
                                </div>
                                <div className="subsection-linev2"/>
                                {twitter && <div style={{height: "16%", color: "#ffffff", display: "flex", flexDirection: "row",padding:"0px 15px"}}>
                                    <div className="bli-inner1 social-media-stats">
                                        <div style={{display:"flex"}}>
                                            <div style={{padding:"7px 0px" }}>
                                                <i style={{paddingTop:"5px"}} className="fa fa-users fa-2x fa-iconsocial" aria-hidden="true"></i>
                                            </div>
                                            <div style={{paddingLeft:"10px"}}>
                                                Followers
                                                <div style={{fontWeight: "bold"}}>{rounded(twitter.followers)}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="horizontal-line" style={{height: "100%"}}/>
                                    <div className="bli-inner1 social-media-stats">
                                        <div style={{display:"flex",justifyContent:"center"}}>
                                            <div style={{padding:"7px 0px"}}>
                                                <i style={{paddingTop:"5px"}} className="fas fa-clock fa-2x fa-iconsocial"></i>
                                            </div>
                                            <div style={{paddingLeft:"10px"}}>
                                                Last Activity
                                                <div style={{fontWeight: "bold"}}>{AppHelpers.timeDifference(twitter.latest_time)}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="horizontal-line" style={{height: "100%"}}/>
                                    <div className="bli-inner1 social-media-stats" >
                                        <div style={{display:"flex",justifyContent:"flex-end"}}>
                                            <div style={{padding:"7px 0px"}}>
                                                <i style={{paddingTop:"5px"}} className="far fa-envelope fa-2x fa-iconsocial"></i>
                                            </div>
                                            <div style={{paddingLeft:"10px"}}>
                                                Posts
                                                <div style={{fontWeight: "bold"}}>{rounded(twitter.num_posts)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {twitter && <div className="subsection-linev2"/>}
                                <div className="summary social-media-body">
                                    {loadingT && <Loading loading={loadingT}/>}
                                    {!loadingT && twitter && twitter.latest_posts.length > 0 && twitter.latest_posts.map(l => getTwitterPost(l.post, twitter.name,l))}
                                    {!loadingT && twitter && twitter.latest_posts.length === 0 && <div className={"social-media-no-relevant"}>There are no any recent Posts</div>}
                                    {!loadingT && !twitter && <div className={"social-media-no-relevant"}>There is no relevant Account</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 coin-chart-table" style={{paddingRight: "10px", paddingLeft: "10px"}}>
                    <div className="chart-area" style={{height: "100%",marginTop:"0px"}}>
                        <div style={{paddingRight: "20px", paddingLeft: "20px", paddingTop: "10px"}}>
                            <div className="chart-areaforsocialmedia" style={{marginTop: "1vh",overflowY:"scroll"}} >
                                <div className="modal-blue-header-socailmedia" onClick={() => {reddit && window.open(reddit.link);}}>
                                    <div className="remove-image-class" style={{height: "50px"}}>
                                        <img className={"social-media-logo"} style={{marginTop: "1vh", height: "80%"}} src="/img/Reddit.png"/>
                                    </div>
                                    <div className={"social-media-name"} >{reddit && reddit.name}</div>
                                </div>
                                <div className="subsection-linev2"/>
                                {reddit && <div style={{height: "16%", color: "#ffffff", display: "flex", flexDirection: "row",padding:"0px 15px"}}>
                                    <div className="bli-inner1 social-media-stats">
                                        <div style={{display:"flex"}}>
                                            <div style={{padding:"7px 0px"}}>
                                                <i style={{paddingTop:"5px"}} className="fa fa-user fa-2x fa-iconsocial"></i>
                                            </div>
                                            <div style={{paddingLeft:"10px"}}>
                                                Person Online
                                                <div style={{fontWeight: "bold"}}> {rounded(reddit.num_online)} </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="horizontal-line" style={{height: "100%"}}/>
                                    <div className="bli-inner1 social-media-stats">
                                        <div style={{display:"flex",justifyContent:"center"}}>
                                            <div style={{padding:"7px 0px"}}>
                                                <i style={{paddingTop:"5px"}} className="fas fa-clock fa-2x fa-iconsocial"></i>
                                            </div>
                                            <div style={{paddingLeft:"10px"}}>
                                                Last Activity
                                                <div style={{fontWeight: "bold"}}> {AppHelpers.timeDifference(reddit.latest_time)} </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="horizontal-line" style={{height: "100%"}}/>
                                    <div className="bli-inner1 social-media-stats">
                                        <div style={{display:"flex",justifyContent:"flex-end"}}>
                                            <div style={{padding:"7px 0px"}}>
                                                <i style={{paddingTop:"5px"}} className="fa fa-users fa-2x fa-iconsocial" aria-hidden="true"></i>
                                            </div>
                                            <div style={{paddingLeft:"10px"}}>
                                                Followers
                                                <div style={{fontWeight: "bold"}}>  {rounded(reddit.followers)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {reddit && <div className="subsection-linev2" style={{marginTop: "0vh"}}/>}
                                <div className="summary social-media-body">
                                    {loadingR && <Loading loading={loadingR}/>}
                                    {!loadingR && reddit && reddit.latest_posts.length > 0 && reddit.latest_posts.map(l => getRedditPosts(l.post,l))}
                                    {!loadingR && reddit && reddit.latest_posts.length === 0 && <div className={"social-media-no-relevant"}>There are no any recent Posts</div>}
                                    {!loadingR && !reddit && <div className={"social-media-no-relevant"}>There is no relevant Account</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="overview-row" style={{paddingTop:"10px"}}>
                <div className="col-md-6 coin-chart-table" style={{paddingRight: "10px", paddingLeft: "10px"}}>
                    <div className="chart-area" style={{height: "100%",marginTop:"0px"}}>
                        <div style={{paddingRight: "20px", paddingLeft: "20px", paddingTop: "10px"}}>
                            <div className="chart-areaforsocialmedia" style={{marginTop: "1vh",overflowY:"scroll"}}>
                                <div className="modal-blue-header-socailmedia" onClick={() => {youtube && window.open(youtube.link);}}>
                                    <div className="remove-image-class" style={{height: "50px"}}>
                                        <img className={"social-media-logo"} src="/img/youtubelogo.png"/>
                                    </div>
                                    <div className={"social-media-name"}>{youtube && youtube.name}</div>
                                </div>
                                <div className="subsection-linev2" style={{marginTop: "0vh"}}/>
                                {youtube && <div style={{height: "16%", color: "#ffffff", display: "flex", flexDirection: "row",padding:"0px 15px"}}>
                                    <div className="bli-inner1 social-media-stats">
                                        <div style={{display:"flex"}}>
                                            <div style={{padding:"7px 0px"}}>
                                                <i style={{paddingTop:"5px"}} className="fa fa-users fa-2x fa-iconsocial" aria-hidden="true"></i>
                                            </div>
                                            <div style={{paddingLeft:"10px"}}>
                                                Followers
                                                <div style={{fontWeight: "bold"}}> {rounded(youtube.followers)} </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="horizontal-line" style={{height: "100%"}}/>
                                    <div className="bli-inner1 social-media-stats">
                                        <div style={{display:"flex",justifyContent:"center"}}>
                                            <div style={{padding:"7px 0px"}}>
                                                <i style={{paddingTop:"5px"}} className="fas fa-clock fa-2x fa-iconsocial"></i>
                                            </div>
                                            <div style={{paddingLeft:"10px"}}>
                                                Last Activity
                                                <div style={{fontWeight: "bold"}}>    {AppHelpers.timeDifference(youtube.latest_time)} </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="horizontal-line" style={{height: "100%"}}/>
                                    <div className="bli-inner1 social-media-stats">
                                        <div style={{display:"flex",justifyContent:"flex-end"}}>
                                            <div style={{padding:"7px 0px"}}>
                                                <i style={{paddingTop:"5px"}} className="fab fa-youtube fa-2x fa-iconsocial"></i>
                                            </div>
                                            <div style={{paddingLeft:"10px"}}>
                                                Videos
                                                <div style={{fontWeight: "bold"}}> {rounded(youtube.num_posts)} </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {youtube && <div className="subsection-linev2" style={{marginTop: "0vh"}}/>}
                                <div className="summary social-media-body">
                                    {loadingY && <Loading loading={loadingY}/>}
                                    {!loadingY && youtube && youtube.latest_posts.length > 0 && youtube.latest_posts.map(l => getYoutubePosts(l.post,l))}
                                    {!loadingY && youtube && youtube.latest_posts.length === 0 && <div className={"social-media-no-relevant"}>There are no any recent Posts</div>}
                                    {!loadingY && !youtube && <div className={"social-media-no-relevant"}>There is no relevant Account</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 coin-chart-table" style={{paddingRight: "10px", paddingLeft: "10px"}}>
                    <div className="chart-area" style={{height: "100%",marginTop:"0px"}} >
                        <div style={{paddingRight: "20px", paddingLeft: "20px", paddingTop: "10px"}}>
                            <div className="modal-blue-header-socailmedia" onClick={() => {github && window.open(github.link)}}>
                                <div className="remove-image-class" style={{height: "50px"}}>
                                    <img className={"social-media-logo"} style={{marginTop: "1vh"}} src="/img/githublogo.png"/>
                                </div>
                                <div className={"social-media-name"} style={{paddingTop: "3vh"}}>{github && github.name}</div>
                            </div>
                            <div className="subsection-linev2" style={{marginTop: "2vh"}}/>
                            {github && <div className="summary social-media-body" style={{height: '37vh',padding:"0px 15px"}}>
                                <div style={{color: "#ffffff", display: "flex", flexDirection: "row"}}>
                                    <div className="bli-inner1 social-media-stats gitub-big">
                                        <div style={{display:"flex",justifyContent:"center"}}>
                                            <div style={{padding:"7px 0px"}}>
                                                <i style={{paddingTop:"5px"}} className="far fa-eye fa-2x fa-iconsocial"></i>
                                            </div>
                                            <div style={{paddingLeft:"10px"}}>
                                                Watchers
                                                <div style={{fontWeight: "bold"}}> {rounded(github.followers)}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="horizontal-line" style={{height: "100%"}}/>
                                    <div className="bli-inner1 social-media-stats gitub-big">
                                        <div style={{display:"flex",justifyContent:"center"}}>
                                            <div style={{padding:"7px 0px"}}>
                                                <i style={{paddingTop:"5px"}} className="far fa-star fa-2x fa-iconsocial"></i>
                                            </div>
                                            <div style={{paddingLeft:"10px"}}>
                                                Stars
                                                <div style={{fontWeight: "bold"}}> {rounded(github.num_likes)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="subsection-linev2"/>
                                <div style={{ color: "#ffffff", display: "flex", flexDirection: "row"}}>
                                    <div className="bli-inner1 social-media-stats gitub-big">
                                        <div style={{display:"flex",justifyContent:"center"}}>
                                            <div style={{padding:"7px 0px"}}>
                                                <i style={{paddingTop:"5px"}} className="fas fa-exclamation-circle fa-2x fa-iconsocial"></i>
                                            </div>
                                            <div style={{paddingLeft:"10px"}}>
                                                Open Issues
                                                <div style={{fontWeight: "bold"}}>{rounded(github.open_issues_count)} </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="horizontal-line" style={{height: "100%"}}/>
                                    <div className="bli-inner1 social-media-stats gitub-big">
                                        <div style={{display:"flex",justifyContent:"center"}}>
                                            <div style={{padding:"7px 0px"}}>
                                                <i style={{paddingTop:"5px"}} className="fas fa-network-wired fa-2x fa-iconsocial"></i>
                                            </div>
                                            <div style={{paddingLeft:"10px"}}>
                                                Forks
                                                <div style={{fontWeight: "bold"}}> {rounded(github.num_forks)}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="horizontal-line" style={{height: "100%"}}/>
                                    <div className="bli-inner1 social-media-stats gitub-big">
                                        <div style={{display:"flex",justifyContent:"center"}}>
                                            <div style={{padding:"7px 0px"}}>
                                                <i style={{paddingTop:"5px"}} className="fas fa-clock fa-2x fa-iconsocial"></i>
                                            </div>
                                            <div style={{paddingLeft:"10px"}}>
                                                Last Update
                                                <div style={{fontWeight: "bold"}}> {AppHelpers.timeDifference(github.latest_time)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="subsection-linev2"/>
                            </div>}
                            {loadingG && <Loading loading={loadingG}/>}
                            {!loadingG && !github && <div className="summary" style={{height: '20vh'}}>
                                <div className={"social-media-no-relevant"}>There is no relevant Account</div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CoinSocialMedia;
