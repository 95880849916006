import {GENERAL_UPDATE, SET_LOADING} from '../actions/types';

const initialState = {
  fullLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GENERAL_UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        fullLoading: action.payload,
      };
    default:
      return state;
  }
}
