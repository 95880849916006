import {COIN_SEARCH, SUBS_NEEDED_LIST, LOADER, CHANGE_EXCHANGE, LISTEN_COINS, COIN_CLEAR, LISTEN_TOTAL_VOLUME} from '../actions/types';
import CalculateSocketData from '../helpers/CalculateSocketData';

let INITIAL_STATE = {
  loader: false,
  exchange: 'USD',
  result: [],
  subWatchList: null,
  subsNeededList: [],
  subsNeeded: {},
  totalVolumes: [],
  listenCoin: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COIN_CLEAR:
      return {
        ...state,
        result: [],
        subWatchList: null,
        subsNeededList: [],
        subsNeeded: {},
        totalVolumes: [],
        listenCoin: [],
      };
    case COIN_SEARCH:
      action.value.forEach(val => {
        val.ticker.vd = null;
      });
      return {
        ...state,
        result: action.value,
        subWatchList: action.subWatchList,
        loader: false,
      };

    case SUBS_NEEDED_LIST:
      return {
        ...state,
        subsNeededList: action.subsNeededList,
        subsNeeded: action.subsNeeded,
      };

    case LOADER:
      return {
        ...state,
        loader: true,
      };

    case CHANGE_EXCHANGE:
      return {
        ...state,
        exchange: action.value,
      };
    case LISTEN_TOTAL_VOLUME:
      if (Object.keys(action.value).length !== 0) {
        if (state.totalVolumes && state.totalVolumes.length === 0) {
          state.totalVolumes.push(action.value);
        } else {
          let findedCoin = state.totalVolumes.find(i => i.SYMBOL === action.value.SYMBOL);
          if (findedCoin !== undefined) {
            const indis = state.totalVolumes.indexOf(findedCoin);
            state.totalVolumes.splice(indis, 1, action.value);
          } else {
            state.totalVolumes.push(action.value);
          }
        }

        let realData = state.result.find(i => i.symbol === action.value.SYMBOL);
        if (realData && state.totalVolumes.find(i => i.SYMBOL === action.value.SYMBOL)) {
          realData.ticker.totalVol = Number(action.value.FULLVOLUME) * Number(realData.ticker.lst);
        }
      }

      return {
        ...state,
      };

    case LISTEN_COINS:
      if (Object.keys(action.value).length !== 0) {
        if (state.listenCoin.length === 0) {
          state.listenCoin.push(action.value);
        } else {
          let findedCoin = state.listenCoin.find(i => i.FROMSYMBOL === action.value.FROMSYMBOL && i.TOSYMBOL === action.value.TOSYMBOL);
          if (findedCoin !== undefined) {
            const indis = state.listenCoin.indexOf(findedCoin);
            state.listenCoin.splice(indis, 1, action.value);
          } else {
            state.listenCoin.push(action.value);
          }
        }

        let result = [];
        let realData = {};
        result = CalculateSocketData.conversationSelection(state, action);
        if (result) {
          realData = state.result.find(i => i.symbol === result.TOSYMBOL);
          if (realData) {
            if (result.PRICE && (result.PRICE !== realData.ticker.lst)) {
              const old = realData.ticker.lst;
              realData.ticker.oldLst = old;
              setTimeout(() => {
                realData.ticker.lst = result.PRICE;
              }, 50);
            }

            if (result.CHANGE !== undefined && result.CHANGE !== null) {
              realData.ticker.chgpct = result.CHANGE;
            }
          }
        }
      }

      return {
        ...state,
        listenCoin: state.listenCoin,
      };

    default:
      return {
        ...state,
      };
  }
};
