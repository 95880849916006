import React, {useState, useEffect} from 'react';
import AppHelpers from '../../helpers/AppHelpers';
import SocketHelpersNew from '../../helpers/SocketHelpersNew';
import {withRouter} from 'react-router-dom';
import StateBarRow from './StateBarRow';
import SmallTrRow from './SmallTrRow';
import FullTableRow from './FullTableRow';
import HomeTableRow from './HomeTableRow';
import WatchListRow from './WatchListRow';
import WatchSlideRow from './WatchSlideRow';
import IndicesRow from './IndicesRow';
import IndicesSmallRow from './IndicesSmallRow';
import IndicesStateBarRow from './IndicesStateBarRow';
import DataMappers from "../../helpers/DataMappers";
import Utils from "../../helpers/Utils"

const isMobile = Utils.checkMobile();

const CoinRow = props => {
  const {selectedExchange, row, setAdd, setShow, rowClick, type, history, parentSubType, defi, setShowCU, setUpdated, setType} = props;
  const exchange = row.exchange ? row.exchange : selectedExchange.value;
  const [rowT, setRowT] = useState({});
  const [rowClass, setRowClass] = useState('');
  const [parentTicker, setParentTicker] = useState({});
  const subType = (row.ticker && row.ticker.symbol) || `${row.symbol}-${exchange}.CISCALC`;
  const [stable,setStable] = useState(null);
  const [markCapCategory,setMarkCapCategory] = useState(null);
  const [minable,setMinable] = useState('');
  const [volumeCategory,setVolumeCategory] = useState('');
  const [volatilityCategory,setVolatilityCategory] = useState('');

  const perClassName = value => {
    const key = AppHelpers.isPositiveFloat(value);
    switch (key) {
      case 1:
        return 'table-green-text';
      case -1:
        return 'table-red-text';
      default:
        return 'table-gray-text';
    }
  };

  const handleOpenAlert = (value) =>{
      setUpdated({id:null,data:{
        symbol:rowT.symbol,
        ccy:selectedExchange.value,
        exchange:"CISCALC",
        value:value.replace(",","")
      }})
      setType("Add");
      setShowCU(true);
  }

  useEffect(()=>{
    if(row.ticker){
      setStable(row.stablecoin);
      setMinable(row.mineable);
      setMarkCapCategory(row.market_cap_category);
      setVolumeCategory(row.volume_category);
      setVolatilityCategory(row.volatility_category);
    }
  },[])

  useEffect(() => {
    if (subType) {
      setRowT(row);
    }
  }, [subType]);

  useEffect(() => {
    if (rowClass !== '')
      setTimeout(() => {
        setRowClass('');
      }, 1000);
  }, [rowClass]);

  const handleRealTime = (result) => {
    try {
      if (!result.startsWith("OK|")) {
        const res = JSON.parse(result);

        // Extract symbol
        const symbol = res.hasOwnProperty("p") ? res.s : res.symbol;
        // Extract ticker message
        let ticker = res
        if (res.hasOwnProperty("p")) { // Send only ticker updates with sequence number
          ticker = res.p;
          ticker.mt = res.mt;
          ticker.seqnum = res.seqnum;
        }

        if (parentSubType && symbol === parentSubType) {
          setParentTicker(ticker);
        }
        if (subType === symbol) {
          setRowT(oRow => {

            // Check if new type of message and check if sequence numbers are valid
            if (ticker.seqnum && ticker.mt === 'update' && oRow.ticker && oRow.ticker.seqnum + 1 !== ticker.seqnum) {
              SocketHelpersNew.subscribe([symbol + "~TICKER"]);
              return oRow;
            }

            let rowClassT = '';
            const oLst = parseFloat(AppHelpers.priceChngFormat((oRow.ticker && oRow.ticker.lst) || 1));
            const nLst = ticker.lst ? parseFloat(AppHelpers.priceChngFormat(ticker.lst)) : oLst;

            const currTicker = ticker.lst ? ticker : oRow.ticker;
            if (nLst > oLst) {
              rowClassT = 'price-back-green';
            } else if (nLst < oLst) {
              rowClassT = 'price-back-red';
            }
            if (rowClassT !== '') setRowClass(rowClassT);

            if (ticker.mt) { // Don't check should update on updates segnum needs to be updated
              // Write updates on old ticker data if we are in compare and update scenario
              return  {...oRow, ticker: Object.assign({}, oRow.ticker, ticker)};
            }

            return {...oRow, ticker: currTicker};
          });
        }
      }
    } catch(e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (subType) {
      SocketHelpersNew.coinsRaw([`${subType}~TICKER`], handleRealTime);
    }
    return async () => {
      let noUnmount = null;
      if (type !== 'stateBar') noUnmount = subType;

      await SocketHelpersNew.close([`${subType}~TICKER`], handleRealTime);
    };
  }, [subType]);

  let ticker;
  let supply = {};
  if (rowT.index_code) {
    ticker = DataMappers.baseTickerMapperRaw(rowT.ticker, true)
  } else {
    ticker = DataMappers.baseTickerMapperRaw(rowT.ticker, false);
    supply = DataMappers.coinSupplyMapper(rowT.supply);
  }


  if (ticker) {
    //** variables
    const chgpct = ticker.chngpct;
    const price = ticker.price;
    const pureMarket = ticker.market_cap;
    const parentMarket = (parentTicker && parentTicker.index_market_cap) || 0;
    const marketCap = ticker.market_cap;
    const totalCoin = ticker.v || 0;
    const avalCoin = supply.max_supply || 0;
    const circCoin = supply.circulating_supply || 0;
    const vol24 = ticker.vol24;
    const vol = ticker.v;
    const perClass = perClassName(ticker.ochngpct);
    const perTd = (
      <>
        {chgpct}
      </>
    );
    const symbol = rowT.symbol;
    const rowImage = rowT.image_url;
    const name = rowT.name;
    const low24 = ticker.low;
    const high24 = ticker.high;
    const whitepaperUrl = rowT.blockchain_whitepaper_url;
    const weight = rowT.weight_pct ? ((parentMarket ? (rowT.weight_cap_factor * (pureMarket / parentMarket)) : rowT.weight_pct) * 100).toFixed(2) + '%' : null;
    const all = {
      chgpct,
      price,
      marketCap,
      totalCoin,
      avalCoin,
      circCoin,
      vol24,
      perClass,
      perTd,
      symbol,
      rowImage,
      name,
      exchange,
      rowClass,
      high24,
      rowClick,
      low24,
      weight,
      setShow,
      whitepaperUrl,
      row: rowT,
      vol,
    };

    //** variables */
    if (type === 'indices') return <IndicesRow {...all} />;
    else if (type === 'indicesSmall') return <IndicesSmallRow {...all} />;
    else if (type === 'indicesStateBar') return <IndicesStateBarRow {...all} />;
    else if (type === 'stateBar') return <StateBarRow setAdd={setAdd} setShow={setShow} selectedExchange={selectedExchange} handleOpenAlert={handleOpenAlert} {...all} />;
    else if (type === 'smallTr') return <SmallTrRow {...all} />;
    else if (type === 'fullTable') return <FullTableRow {...all} />;
    else if (type === 'homeTable') return <HomeTableRow {...all} />;
    else if (type === 'watchList') return <WatchListRow {...all} />;
    else if (type === 'WatchSlideRow') return <WatchSlideRow {...all} />;
    else
      return (
          isMobile?
              // <tr onClick={rowClick}>
              <tr>
                <td>
                  {symbol}
                </td>
                <td className={rowClass}>{price}</td>
                <td>{marketCap}{/*circulating_supply sabit kalacak ve soketten gelen PRICE alanı ile çarpılacak*/}</td>
                <td>{circCoin}</td>
                <td>{avalCoin}</td>
                <td>{vol24}</td>
                <td className={perClass}>{perTd}</td>
                <td className="mobile-actions-icon" onClick={e => e.stopPropagation()}>
                  <div className="three-dot-menu">
                    <div className="dot-menu-icon">
                      <img className="actionable-img" src="/img/three-dot.svg" alt="" />
                    </div>
                    <div className="table-dropdown-menu">
                      <ul>
                        <li>
                          <a
                              onClick={e => {
                                e.stopPropagation();
                                setShow(true);
                                setAdd({from: rowT.symbol, to: selectedExchange.value});
                              }}
                              className=" open-modal-event"
                              data-target="set-watch-list">
                            <img className="actionable-img" src="/img/rounded-add-button.svg" alt="" />
                            Add Watchlist
                          </a>
                        </li>
                        <li>
                          <a
                              onClick={() =>
                                  history.push({
                                    pathname: '/trade',
                                    state: {from: rowT.symbol, to: selectedExchange.value}
                                  })
                              }
                              className="open-modal-event"
                              data-target="set-alert">
                            <img className="actionable-img" src="/img/euro.svg" alt="" />
                            Trade
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              :
              defi ?
                  <tr onClick={rowClick}>
                    <td>
                      {/*<div className="icon-img">*/}
                      {/*  <img src={rowImage} style={{width: 20}} alt="" />*/}
                      {/*</div>*/}
                      {name} ({symbol})
                    </td>
                    <td className={rowClass}>{price}</td>
                    <td>{marketCap}{/*circulating_supply sabit kalacak ve soketten gelen PRICE alanı ile çarpılacak*/}</td>
                    <td className={perClass}>{perTd}</td>
                    <td>{rowT.defi_info && rowT.defi_info.category ?  rowT.defi_info.category : "-"}</td>
                    <td>{rowT.defi_info && rowT.defi_info["chain"] ?  rowT.defi_info["chain"] : "-"}</td>
                    <td>{rowT.defi_info && rowT.defi_info.tvl_usd ?  AppHelpers.numberFormat(rowT.defi_info.tvl_usd) : "-"}</td>
                    <td className="mobile-actions-icon" style={{textAlign:"center"}} onClick={e => e.stopPropagation()}>
                      <div className="three-dot-menu">
                        <div className="dot-menu-icon">
                          <img className="actionable-img" src="/img/three-dot.svg" alt="" />
                        </div>
                        <div className="table-dropdown-menu">
                          <ul>
                            <li>
                              <a
                                  onClick={e => {
                                    e.stopPropagation();
                                    setShow(true);
                                    setAdd({from: rowT.symbol, to: selectedExchange.value});
                                  }}
                                  className=" open-modal-event"
                                  data-target="set-watch-list">
                                <img className="actionable-img" src="/img/rounded-add-button.svg" alt="" />
                                Add Watchlist
                              </a>
                            </li>
                            <li>
                              <a
                                  onClick={() =>
                                      history.push({
                                        pathname: '/trade',
                                        // state: {from: rowT.symbol, to: selectedExchange.value}
                                      })
                                  }
                                  className="open-modal-event"
                                  data-target="set-alert">
                                <img className="actionable-img" src="/img/euro.svg" alt="" />
                                Trade
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                  :
                  <tr onClick={rowClick}>
                    {/*<td>1</td>*/}
                    <td>
                      {/*<div className="icon-img">*/}
                      {/*  <img src={rowImage} style={{width: 20}} alt="" />*/}
                      {/*</div>*/}
                      {name} ({symbol})
                    </td>
                    <td className={rowClass}>{price}</td>
                    <td>{marketCap}{/*circulating_supply sabit kalacak ve soketten gelen PRICE alanı ile çarpılacak*/}</td>
                    <td>{circCoin}</td>
                    <td>{avalCoin}</td>
                    <td>{vol24}</td>
                    <td className={perClass}>{perTd}</td>
                    <td>{markCapCategory}</td>
                    <td>{volatilityCategory}</td>
                    <td>{volumeCategory}</td>
                    <td className="mobile-actions-icon" style={{textAlign:"center"}} onClick={e => e.stopPropagation()}>
                      <div className="three-dot-menu">
                        <div className="dot-menu-icon">
                          <img className="actionable-img" src="/img/three-dot.svg" alt="" />
                        </div>
                        <div className="table-dropdown-menu">
                          <ul>
                            <li>
                              <a
                                onClick={e => {
                                  e.stopPropagation();
                                  setShow(true);
                                  setAdd({from: rowT.symbol, to: selectedExchange.value});
                                }}
                                className=" open-modal-event"
                                data-target="set-watch-list">
                                <img className="actionable-img" src="/img/rounded-add-button.svg" alt="" />
                                Add Watchlist
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={() =>
                                  history.push({
                                    pathname: '/trade',
                                    // state: {from: rowT.symbol, to: selectedExchange.value}
                                  })
                                }
                                className="open-modal-event"
                                data-target="set-alert">
                                <img className="actionable-img" src="/img/euro.svg" alt="" />
                                Trade
                              </a>
                            </li>
                            <li>
                              <a
                                  onClick={()=>handleOpenAlert(price)}
                                  className="open-modal-event"
                                  data-target="set-alert">
                                <img className="actionable-img" src="/img/alerts.svg" alt="" />
                                Alert
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
      );
  } else {
    return null;
  }
};
export default withRouter(CoinRow);
