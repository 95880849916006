import React, {useEffect} from 'react';
import {Pie} from 'react-chartjs-2';


const backgroundColor5 = [
    '#1f5c94',
    '#68bbe3',
    '#1b4b9d',
    '#1d2e51',
    '#7267c2',
    '#3d3c49'];

const backgroundColor3 = [
    '#1f5c94',
    '#68bbe3',
    '#1b4b9d',
    '#1d2e51',
];

const PieChart = props => {
    const {resp,type, displayLegend} = props;
    let data = {};
    let legend = {};



    let respKeys = Object.keys(resp);
    const respValues = respKeys.map(el => resp[el]);
    const newMapDatas = respValues.map(el => el[0]);


    const newRespKeys = respKeys.map(el =>{
        if(el === "Unknown"){
            el = "Unassigned";
        }
        if(el === "Asset Token (A)"){
            el = "Security Token (S)"
        }
        if(el === "No Category"){
            el = "No Market Cap";
        }
        return el;
    });

    if(type === "Asset"){
        data = {
            labels: newRespKeys,
            datasets: [
                {
                    label: '# of Votes',
                    data: newMapDatas,
                    backgroundColor: backgroundColor3,
                    borderColor: backgroundColor3,
                    borderWidth: 1,
                },
            ]
        }
    }else if(type === "MarketCap"){
        data = {
            labels: newRespKeys,
            datasets: [
                {
                    label: '# of Votes',
                    data: newMapDatas,
                    backgroundColor: backgroundColor5,
                    borderColor: backgroundColor5,
                    borderWidth: 1,
                },
            ],
        }
    }

    legend={
        display: displayLegend,
        position: 'right',
        fullWidth: true,
        reverse: false,
        labels: {
            boxWidth: 20,
            fontColor: "white",
            fontSize:20
        }
    }



    useEffect(()=>{
    },[])

    return (
        <div>
            <Pie data={data} legend={legend}/>
        </div>

    );
};


export default PieChart;


