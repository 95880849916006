import React from "react";

const EmailVerification = props => {
    return (
        <div className={"gray-container"} style={{height: "100%"}}>
            <section style={{textAlign: 'center'}} className="contact-area padding-sm-container">
                <img src={"/img/logo.svg"} style={{marginBottom: "2em", height: "7em"}}/>
                <div className="general-header"><h2>Please Verify Your Email!</h2></div>
                <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', color: '#fff'}} className="contact-form-and-banners">
                    <h4>Your email needs verification to use applications of Crypto Index Series. Please follow the link we have just sent to your stored email address.</h4>
                    <h3>
                        <a style={{cursor: "pointer"}} target="_blank" rel="noopener noreferrer" href="https://cryptoindexseries.com/">Contact Us</a>
                    </h3>
                </div>
            </section>
        </div>

    );
};

export default EmailVerification;
