import React from 'react';

const Privacy = () => {
  return (
    <div style={{width: '90%', margin: 'auto'}}>
      <p align="JUSTIFY" style={{marginBottom: '0.11in'}}>
        <br />
        <br />
      </p>
      <p align="JUSTIFY" style={{marginTop: '0.21in', marginBottom: '0.21in', background: '#ffffff', lineHeight: '100%'}}>
        <a name="_GoBack" />
        <font color="#121212">
          <font face="Arial, serif">
            <font size={5} style={{fontSize: '20pt'}}>
              <b>Privacy and Cookie Policy- CryptoCompare</b>
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              We respect your privacy and take data security extremely seriously. We make sure we have appropriate security measures in place to
              prevent your personal data from being accidentally lost and from unauthorised use and access.
            </font>
          </font>
        </font>
      </p>
      <p style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              Please read this Privacy and Cookie Policy carefully as it explains our practices regarding your personal data and how we will treat it,
              and the basis on which any personal data will be processed by us.
              <br />
              <br />
              For the purpose of the applicable data protection legislation (meaning, prior to 25 May 2018 the Data Protection Act 1998 and from 25
              May 2018 the General Data Protection Regulation and any legislation which implements it) (the “Data Protection Legislation”), the data
              controller is Crypto Index Series Limited of company number 12009753.
              <br />
              <br />
              References in this Privacy and Cookies Policy and on our website to “we”, “our” or “us” are references to Crypto Coin Comparison
              Limited. References to “you” and “your” means each person who interacts with us, uses our website or the products and services we
              provide.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginTop: '0.21in', marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#121212">
          <font face="Arial, serif">
            <font size={5} style={{fontSize: '20pt'}}>
              <b>1. Information we collect and process about you</b>
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              1.1 We may collect and process the following personal data about you:
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              1.1.1 Information you give to us. You may give us information about you when you register on our website&nbsp;
            </font>
          </font>
        </font>
        <a href="http://www.cryptoindexseries.com">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              www.cryptoindexseries.com
            </font>
          </font>
        </a>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              &nbsp;or by communicating with us by phone, email or otherwise. This also includes information you provide when you subscribe to any of
              our services, participate in surveys and user forums, and when you give us any feedback or report a problem with our website. The
              information you give us may include your name, address, email address, phone number, date of birth, identity documents, username,
              password, job title and company information.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              1.1.2 Information we collect about you when you communicate with us by phone, email, post, or otherwise, and when you use our products
              and services. We collect information such as how and when you contacted us and responded to communications from us and about how and
              when you use our products and services.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              1.1.3&nbsp;Information we collect about you if you use our website or interact with us over the internet, including via social media.
              Each time you visit our website or interact with us we may automatically collect the following information: (a) technical information,
              including the Internet protocol (IP) address used to connect your device to the Internet, your login information, browser type and
              version, time zone setting, browser plug-in types and versions, operating system and platform; (b) information about your visit,
              including the full Uniform Resource Locators (URL); products you viewed or searched for; length of visits to certain pages, page
              interaction information (such as scrolling, clicks, and mouse-overs); (c) information you share publicly on our website, including the
              data, lists or any other information you chose to share with other users by using our web site.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              1.1.4&nbsp;Information we collect about you from publicly available sources. This may include information available from social media
              (depending on your settings and the applicable privacy policies) and information from resources such as Companies House.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              1.1.5&nbsp;Information we receive from other sources.&nbsp; We may receive further information about you if you use any of the services
              we provide.&nbsp; We work closely with third parties (including, for example, suppliers, service providers, identity verification
              providers) and may receive information about you from them. We may combine information we receive from these other sources with
              information you give to us and information we collect about you.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              1.1.6&nbsp;We may monitor or record telephone conversations or other communications between you and us and keep recordings or
              transcripts of them and we may keep a copy of any correspondence.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              1.2 We also collect, use and share aggregated data such as statistical or demographic data. Aggregated data may be derived from your
              personal data, but it is not considered personal data under the Data Protection Legislation as it does not directly or indirectly
              identify you. If at any time we do combine any aggregated data with your personal data so that it can identify you, we treat the
              combined data as personal data, which we will use and process in accordance with this Privacy and Cookies Policy.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginTop: '0.21in', marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#121212">
          <font face="Arial, serif">
            <font size={5} style={{fontSize: '20pt'}}>
              <b>2. Cookies</b>
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              2.1 We and our service providers collect information about your use of our website from cookies. A cookie is a small file which asks
              permission to be placed on your computer's hard drive. Once it is it helps analyse website traffic or lets us know when you visit a
              particular site. Cookies allow website applications to respond to you as an individual. The website application can tailor its
              operations to your needs, likes, and dislikes by gathering and remembering information about your preferences.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              2.2 We use traffic log cookies to identify which of our website pages are being used. This helps us analyse data about web page traffic
              and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then
              the data is removed from our system.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              2.3 Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do
              not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              2.4 You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser
              setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginTop: '0.21in', marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#121212">
          <font face="Arial, serif">
            <font size={5} style={{fontSize: '20pt'}}>
              <b>3. Purposes and legal basis for using your personal data</b>
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              3.1 Where you have requested that we provide a specific product or service to you, or you otherwise make use of those products or
              services, we will process your personal data in order to perform our contract with you and provide that product or service.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              3.2 We also use the personal data we hold about you to pursue our legitimate interests in providing and marketing our products and
              services to you, improving our website, services, and interactions with you and other users of our products and services in the
              following ways:
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              3.2.1 administer your account and relationship with us and to&nbsp;communicate with you by telephone, mail, email, text (SMS) message,
              instant messaging or other electronic means;
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              3.2.2 verify your identity and detect fraud and crime and comply with any legal or regulatory requirements;
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              3.2.3 provide you with information about the products and services that you request from us;
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              3.2.4 provide you with information about other products and services we offer that are similar to those that you have already purchased
              or enquired about;
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              3.2.5&nbsp;provide you with information about products or services we feel may interest you or be best for you;
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              3.2.6&nbsp;your data may be shared with product or service providers to validate if you are an existing customer (which may affect
              whether you can be accepted for one of their products) or for fraud prevention purposes. The product or service provider does not have
              permission to use these data for any other purpose including marketing.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              3.2.7 notify you about changes to our services;
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              3.2.8 ensure that content from our website is presented in the most effective manner for you and your device;
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              3.2.9 to administer our website and for internal operations, including troubleshooting, data analysis, testing, research, statistical
              and survey purposes;
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              3.2.10 to build up a picture of your interests so that you don’t miss information relevant to you when you visit our website;
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              3.2.11 to improve the service we offer you and to try and ensure that you get the best from our website;
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              3.2.12 to allow you to participate in interactive features of our website and services;
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              3.2.13 as part of our efforts to keep our website safe and secure;
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              3.2.14 to measure the effectiveness of advertising we serve to you and others, and to deliver relevant advertising to you;
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              3.2.15 to send direct marketing to you (see section 5, below);
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              3.2.16 to make suggestions and recommendations about products or services that we think may be of interest based on usage patterns both
              on our website and on other channels (such as email and social media);
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              3.2.17 for training and quality purposes;
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              3.2.18 to check any instructions you give to us and for the purposes of investigating any complaint you may make, or as evidence in any
              dispute or anticipated disputes between you and us.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              3.3 In some cases we may also use the personal data we hold about you to comply with our legal obligations, or enter into or perform a
              contract with you.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginTop: '0.21in', marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#121212">
          <font face="Arial, serif">
            <font size={5} style={{fontSize: '20pt'}}>
              <b>4. Who your data can be disclosed to</b>
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              4.1 We may need to disclose your data to others to ensure the effective provision to you of the products, services, and information you
              request. Your data may also be disclosed to the other entities as described below.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              4.2 We may share your personal information with any member of our group. This may include any subsidiaries, holding companies we may be
              related and their subsidiaries.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              4.3 We may share your information with selected third parties including:
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              4.3.1 Suppliers, service providers, business partners, and sub-contractors;
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              4.3.2&nbsp;Fraud prevention agencies, to prevent crime and trace those responsible;
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              4.3.3&nbsp;Identity verification providers, to comply with legal or regulatory requirements;
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              4.3.4&nbsp;Analytics and search engine providers that assist us in the improvement and optimisation of our website;
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              4.3.5&nbsp;IT and software providers who supply us with our IT infrastructure for the provision of our services and administering our
              business (including our internal and external communications) and who also help us manage our customer and contact databases, customer
              relationships and marketing.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              4.4 We may disclose your personal information to third parties if:
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              4.4.1 we sell or buy any business or assets, in which case we may disclose your personal data to the prospective seller or buyer of such
              business or assets;
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              4.4.2&nbsp;Crypto Index Series Limited or substantially all of its assets are acquired by a third party, in which case personal data
              held by it about its customers will be one of the transferred assets; and
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              4.4.3&nbsp;we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to
              enforce or apply any contract with you and other agreements; or to protect the rights, property, or safety of our business, our
              customers, or others.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              4.5 We review all our relationships with third parties carefully so that we can be sure as possible that their practices match our own
              commitments to you relating to privacy and security. We also comply with the Data Protection Legislation in our dealings with these
              third parties to ensure that your information is appropriately protected.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginTop: '0.21in', marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#121212">
          <font face="Arial, serif">
            <font size={5} style={{fontSize: '20pt'}}>
              <b>5. Direct marketing and how you can change your preference</b>
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              5.1 We may send direct marketing communications to you, including by email, telephone or SMS or mail. If we are required by law to have
              your consent to do this then we will only do so if we have that consent.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              5.2 Whenever you receive direct marketing from us you will be told how you can unsubscribe so that you no longer receive it. When we
              communicate with you via email you will also be given the opportunity to set or amend any preferences that you have indicated to us.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              5.3 You are also able at any time to withdraw that consent to receive marketing communications that you have given to us. You can do
              this by going to the following link and updating your user preferences:&nbsp;
            </font>
          </font>
        </font>
        <a href="https://www.cryptoindexseries.com/consent/">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              https://www.cryptoindexseries.com/consent/
            </font>
          </font>
        </a>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              .&nbsp;Sometimes we may need to contact you further to ask you for additional information so that we can comply with your request.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginTop: '0.21in', marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#121212">
          <font face="Arial, serif">
            <font size={5} style={{fontSize: '20pt'}}>
              <b>6. Where we store and transfer your data</b>
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              6.1 Where we store your information ourselves it is stored on our secure servers in the EU. However, where we share your information
              with third parties this may involve transferring it to a country outside the EU and the European Economic Area (EEA). This may include
              countries that do not have data protection laws which are as strong as those in the UK or EU.&nbsp; Where we do this we will take the
              steps required under the Data Protection Legislation to ensure that your information is appropriately protected. If you would like any
              further information about this then please contact us using the details in the Contact section below.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              6.2 Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect
              your personal data, we cannot guarantee the security of your data transmitted to our website; any transmission is at your own risk. Once
              we have received your information, we will use appropriate procedures and security features to try to prevent unauthorised access, loss
              or damage.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginTop: '0.21in', marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#121212">
          <font face="Arial, serif">
            <font size={5} style={{fontSize: '20pt'}}>
              <b>7. How long we keep your information for</b>
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              7.1 How long we keep your information will depend on the purpose for which we use it. We will only retain your information for as long
              as is necessary for the purposes set out in this Privacy and Cookies Policy and as is necessary to comply with our legal obligations. We
              do not keep more information than we need for the particular purpose.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#ff0000">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              7.2 Where we have provided you with a product or service we will keep an archived record of your personal data for a period of up to 6
              years after termination (unless a longer period is prescribed by law) for the purposes of responding to legal disputes and legal or
              regulatory enquiries or investigations only, but will not use this data for any other purpose.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              7.3&nbsp;If you ask us to stop sending direct marketing communications to you, we will keep the minimum amount of information necessary
              (such as your name and email address) to ensure that we are able to adhere to your request.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginTop: '0.21in', marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#121212">
          <font face="Arial, serif">
            <font size={5} style={{fontSize: '20pt'}}>
              <b>8. Third party websites</b>
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              8.1 Our website and other information we send to you (including any emails or direct marketing) may contain links to other websites. If
              you follow a link to any of these websites they may collect information from you. Please note that these websites have their own privacy
              policies and that we do not accept any responsibility or liability for these websites or their policies. Please check these policies
              before you submit any personal data to these websites.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginTop: '0.21in', marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#121212">
          <font face="Arial, serif">
            <font size={5} style={{fontSize: '20pt'}}>
              <b>9. Your rights</b>
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              9.1 Under the Data Protection Legislation, you have certain rights in respect of the personal data we hold about you. These may include
              rights to: request a copy of the personal data that we hold, request that we correct personal data if it is inaccurate, request that we
              erase or block your personal data, and to object to our processing of your personal data. These rights are limited in some situations.
              For example, if we have a legal requirement or a compelling legitimate ground we may continue to process your data even where you
              request its deletion.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              9.2 If you would like to exercise any of these rights, please contact us using the details in the Contact section below.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              9.3 You also have the right to make a complaint if you feel your personal data has been mishandled. We would encourage you to contact us
              in the first instance but you are also entitled to complain directly to the Information Commissioner’s Office (ICO) (if you are in the
              UK), or to your local data protection authority (if you are outside the UK).
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginTop: '0.21in', marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#121212">
          <font face="Arial, serif">
            <font size={5} style={{fontSize: '20pt'}}>
              <b>10. Changes to our privacy and cookie policy</b>
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              10.1 Any changes we may make to our Privacy and Cookie Policy in the future will be posted on this page and, where we consider it
              appropriate, notified to you by email. Please check back frequently to see any updates or changes to our Privacy and Cookie Policy.
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginTop: '0.21in', marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#121212">
          <font face="Arial, serif">
            <font size={5} style={{fontSize: '20pt'}}>
              <b>11. Contact</b>
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.1in', background: '#ffffff', lineHeight: '100%'}}>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              11.1 Questions, comments and requests regarding this Privacy and Cookie Policy should be addressed to&nbsp;
            </font>
          </font>
        </font>
        <a href="mailto:info@cryptocompare.com">
          <font color="#337ab7">
            <font face="Open Sans, serif">
              <font size={2} style={{fontSize: '10pt'}}>
                <u>info@cryptocompare.com</u>
              </font>
            </font>
          </font>
        </a>
        <font color="#333333">
          <font face="Open Sans, serif">
            <font size={2} style={{fontSize: '10pt'}}>
              .
            </font>
          </font>
        </font>
      </p>
      <p align="JUSTIFY" style={{marginBottom: '0.11in'}}>
        <br />
        <br />
      </p>
    </div>
  );
};

export default Privacy;
