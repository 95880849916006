import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Breadcrump from '../../components/Breadcrump';
import Filter from '../../components/PageCoins/Filter';
import Indices from '../../components/Indices';
import Breadcrumps from '../../constants/Breadcrumps';
import MobileNavBar from "../../components/MobileNavBar/MobileNavBar";
import Utils from "../../helpers/Utils";
import Watchlist from "../../components/PageDashboard/Watchlist/Watchlist";
import {useAuth0} from "../../helpers/Auth0";
import IndicesDeFi from "../../components/IndicesDeFi";
import DeFiFilter from "../../components/PageCoins/DeFiFilter";

const isMobile = Utils.checkMobile();

const Coins = (props, context) => {
    const {history} = props;
    const {user} = useAuth0();
    const [watchListShow, setWatchListShow] = useState(user.preferences.show_watchlist);
    const [filter, setFilter] = useState(null);
    const [loading, setLoading] = useState(true);
    const [filterValue, setFilterValue] = useState(false);
    const [show, setShow] = useState(false);
    const [param,setParam] = useState(null);
    const [defiFilter, setDeFiFilter] = useState({
        inputMktCapMin: "",
        inputMktCapMax: "",
        inputTvlMin: "",
        inputTvlMax: "",
        inputChgMin: "",
        inputChgMax: "",
        inputKeyword: "",
        search: false
    });

    // Check Coin List Menu
    const [menu, setMenu] = useState("ALL COINS");
    // Inline tabs
    const [tabs,setTabs] = useState("Overview");

    const searchT = filter => {
        setFilter(filter);
    };

    useEffect(() => {
        isMobile ? setFilterValue(false) : setFilterValue(true);
        if (history.location.search != "") {
            const params = new URLSearchParams(history.location.search.substring(1));
            let assetProperty = [];
            if(params.get("mineable") === "true"){
                assetProperty.push(2);
            }
            if(params.get("stablecoin") === "true"){
                assetProperty.push(1);
            }

            const queryparams = {
                inputMktCapMin: params.get("mkt_cap_min"),
                inputMktCapMax: params.get("mkt_cap_max"),
                inputVolumeMin: params.get("total_coin_volume_24h_min"),
                inputVolumeMax: params.get("total_coin_volume_24h_max"),
                inputChgMin: params.get("chgpct_24h_min") !== ""? params.get("chgpct_24h_min")*100 : "",
                inputChgMax: params.get("chgpct_24h_max") !== ""? params.get("chgpct_24h_max")*100 : "",
                inputKeyword: params.get("search_string"),
                selectAsset: {},
                selectCoin: {},
                selectSector: {},
                assetProperty: {},
                marketCapCategory: {},
                volumeCategory: {},
                volatilityCategory: {},
                selectedAsset:  params.get("asset_type") !== "" ? params.get("asset_type").split(",").map(Number)  : [],
                selectedBusiness: params.get("business_classification") !== "" ? params.get("business_classification").split(",").map(Number): [],
                selectedMarketCap: params.get("market_cap_category") !== "" ? params.get("market_cap_category").split(",").map(Number) : [],
                selectedVolatility: params.get("volatility_category") !== "" ? params.get("volatility_category").split(",").map(Number) : [],
                selectedVolume: params.get("volume_category") !== "" ? params.get("volume_category").split(",").map(Number) : [],
                selectedConsensusModal: params.get("consensus_model") !== "" ? params.get("consensus_model").split(",").map(Number) : [],
                selectedAssetProperty: assetProperty,
                mineable: params.get("mineable") === "" ? "" : params.get("mineable"),
                stablecoin: params.get("stablecoin") === "" ? "" : params.get("stablecoin"),
                ccy:  params.get("ccy"),
                page_number: params.get("page_number"),
                page_size : params.get("page_size"),
                order_by: params.get("order_by"),
                is_defi: params.get("is_defi") === "" ? "" : params.get("is_defi")
            };
            setParam(queryparams)
        }
    }, []);


    const changeFilter = () => {
        setShow(true);
    }

    return (
        isMobile ?
            <div>
                <MobileNavBar/>
                <div>
                    {watchListShow ? <Watchlist/> : <></>}
                </div>
                <div className="mobile-Div">
                    <div className="row row-d" style={{paddingTop: "10px"}}>
                        <div className="col-xs-10 col-filter-top-l">
                            <p style={{margin: "5px 0px"}}>Advanced Search</p>
                        </div>
                        <div className="col-xs-2 col-filter-top-r" onClick={changeFilter}>
                            <img style={{margin: "5px 0px"}} src="/img/search.svg" alt="" height="30px"/>
                        </div>
                    </div>
                    <Filter show={show} loadingP={loading} {...props} search={setFilter} cancel={() => setShow(false)} param={param}/>
                </div>

                <div className="mobile-Div">
                    <Indices {...props} filter={filter} setLoadingP={setLoading}/>
                </div>
                <div style={{height: "80px"}}>

                    <p></p>
                </div>
            </div>
            :
            <React.Fragment>
                <section className="coin-table padding-container">
                    <div className="watchlist-all-page">
                        {watchListShow ? <Watchlist/> : <></>}
                    </div>

                    <Breadcrump links={Breadcrumps.coins}/>

                    <div className="tab-menu">
                        <ul>
                            <li className={menu === "ALL COINS" ? 'active' : ''}>
                                <a onClick={() => setMenu("ALL COINS")}>ALL COINS</a>
                            </li>
                            <li className={menu === "DEFI COINS" ? 'active' : ''}>
                                <a onClick={() => {
                                    setMenu("DEFI COINS");
                                    setFilter({});
                                }}>DEFI COINS</a>
                            </li>

                        </ul>
                    </div>

                    <div id="filter-area-coins" onClick={changeFilter} style={{display: 'none'}}>
                        <p>Filter <i className="down-arrow-news"/></p>
                    </div>
                    {
                        menu === "DEFI COINS" ?

                            filterValue &&
                            <DeFiFilter loadingP={loading} {...props} filter={defiFilter} setFilter={setDeFiFilter}/>
                            :
                            <React.Fragment>
                                { filterValue && <Filter loadingP={loading} {...props} search={searchT} param={param}/>}
                            </React.Fragment>
                    }
                    {
                        !(menu === "DEFI COINS") &&
                        <div style={{paddingTop: "10px"}}>
                            <div className={"inline-tab-wrapper"}>
                                <ul className={"inline-tab"}>
                                    <li key={"overview"}><button className={tabs === 'Overview' ? "inline-tab-button-active" : "inline-tab-button"} onClick={e => {e.stopPropagation();  setTabs('Overview')}}>Overview</button></li>
                                    <li key={"analysis"}><button className={tabs === 'Analysis' ? "inline-tab-button-active" : "inline-tab-button"} onClick={e => {e.stopPropagation();  setTabs('Analysis')}}>Analysis</button></li>
                                </ul>
                            </div>
                        </div>
                    }

                    {
                        menu === "DEFI COINS" ?
                            <IndicesDeFi {...props} filter={filter} setLoadingP={setLoading}
                                         defi={menu === "DEFI COINS" ? true : false} dfilter={defiFilter}
                                         setDFilter={setDeFiFilter}/>
                            :
                            <Indices {...props} filter={filter} setLoadingP={setLoading} param={param} tabs={tabs}/>
                    }
                </section>
            </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(Coins);
