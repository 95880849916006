import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Utils from "../../../helpers/Utils";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";

const directions = [
    {value: "above", label: "Above"},
    {value: "below", label: "Below"}
]

const sentimentTypes = [
    {label: "Title", value: 'title'},
    {label: "Summary", value: 'summary'},
    {label: "Content", value: 'content'}
]

const customStyles = {
    menuList: (provided, state) => ({
        ...provided,
        maxHeight: "220px",
    }),
}


const CreateAndUpdateNewsConditionModal = props => {
    const {
        conditionsFields,
        setConditionsFields,
        index,
        meta,
        allConditions
    } = props;

    // All condition fields
    const [conditionFields, setConditionFields] = useState(allConditions);

    useEffect(()=>{
        setConditionFields(allConditions);
    },[allConditions])

    const onChange = (type, selectedOption) => {
        let newConditionsFields = [...conditionsFields];
        newConditionsFields[index][type] = selectedOption;
        setConditionsFields(newConditionsFields);
    };

    const handleChangeTargetValue = (text) => {
        let newConditionsFields = [...conditionsFields];
        newConditionsFields[index]["value"] = text;
        setConditionsFields(newConditionsFields);
    };


    const handleChange = (event) => {
        if (event.target.value === "sentiment") {
            let newConditionsFields = [...conditionsFields];
            newConditionsFields[index]["sentiment"] = !newConditionsFields[index]["sentiment"];
            setConditionsFields(newConditionsFields);
        }
    }

    return (
        <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
            <h4 style={{
                color: "white",
                fontSize: "16px",
                padding: "0px 12px"
            }}>{"Filters:"}</h4>
            <div style={{padding: "5px 0px", display: "flex", marginBottom: "10px"}}>
                <div style={{paddingTop: "10px", marginLeft: "12px"}}>
                    <div className="input-title">Coins:</div>
                    <div style={{padding: "0px", width: "170px"}}>
                        <Select
                            styles={customStyles}
                            value={conditionsFields[index].coin}
                            onChange={(e) => onChange("coin", e)}
                            options={conditionFields.coins}
                        />
                    </div>
                </div>
                <div style={{paddingTop: "10px", marginLeft: "5px"}}>
                    <div className="input-title">Sectors:</div>
                    <div style={{padding: "0px", width: "170px"}}>
                        <Select
                            styles={customStyles}
                            value={conditionsFields[index].sector}
                            onChange={(e) => onChange("sector", e)}
                            options={conditionFields.sectors}
                        />
                    </div>
                </div>
                <div style={{paddingTop: "10px", marginLeft: "5px"}}>
                    <div className="input-title">Asset Types:</div>
                    <div style={{padding: "0px", width: "170px"}}>
                        <Select
                            styles={customStyles}
                            value={conditionsFields[index].assetType}
                            onChange={(e) => onChange("assetType", e)}
                            options={conditionFields.assets}
                        />
                    </div>
                </div>
                <div style={{paddingTop: "10px", marginLeft: "5px"}}>
                    <div className="input-title">Sources:</div>
                    <div style={{padding: "0px", width: "170px"}}>
                        <Select
                            styles={customStyles}
                            value={conditionsFields[index].source}
                            onChange={(e) => onChange("source", e)}
                            options={conditionFields.sources}
                        />
                    </div>
                </div>
                <div style={{paddingTop: "10px", marginLeft: "5px"}}>
                    <div className="input-title">Exchanges:</div>
                    <div style={{padding: "0px", width: "170px"}}>
                        <Select
                            styles={customStyles}
                            value={conditionsFields[index].exchange}
                            onChange={(e) => onChange("exchange", e)}
                            options={conditionFields.exchanges}
                        />
                    </div>
                </div>
                <div style={{paddingTop: "10px", marginLeft: "5px"}}>
                    <div className="input-title">Categories:</div>
                    <div style={{padding: "0px", width: "170px"}}>
                        <Select
                            styles={customStyles}
                            value={conditionsFields[index].category}
                            onChange={(e) => onChange("category", e)}
                            options={conditionFields.categories}
                        />
                    </div>
                </div>
                <div style={{paddingTop: "10px", marginLeft: "5px"}}>
                    <div className="input-title">Nations:</div>
                    <div style={{padding: "0px", width: "170px"}}>
                        <Select
                            styles={customStyles}
                            value={conditionsFields[index].nation}
                            onChange={(e) => onChange("nation", e)}
                            options={conditionFields.nations}
                        />
                    </div>
                </div>
            </div>
            <div style={{display: "flex"}}>
                <h4 style={{
                    color: "white",
                    fontSize: "16px",
                    padding: "0px 12px",
                }}>{"Sentiment"}</h4>
                <div className="alertSentiment">
                    <FormGroup>
                        <FormControlLabel key={index}
                                          control={
                                              <Checkbox
                                                  key={index}
                                                  checked={conditionsFields[index].sentiment}
                                                  onChange={handleChange}
                                                  color="primary"
                                                  value="sentiment"
                                              />
                                          }
                        />
                    </FormGroup>
                </div>
            </div>

            {
                conditionsFields[index].sentiment &&
                <div style={{padding: "5px 0px", display: "flex"}}>
                    <div style={{paddingTop: "10px", marginLeft: "12px"}}>
                        <div className="input-title">Sentiment Types:</div>
                        <div style={{padding: "0px", width: "170px"}}>
                            <Select
                                styles={customStyles}
                                value={conditionsFields[index].targetField}
                                onChange={(e) => onChange("targetField", e)}
                                options={sentimentTypes}
                                placeholder="Symbol"
                            />
                        </div>
                    </div>
                    <div style={{paddingTop: "10px", marginLeft: "5px"}}>
                        <div className="input-title">Direction:</div>
                        <div style={{padding: "0px", width: "170px"}}>
                            <Select
                                styles={customStyles}
                                value={conditionsFields[index].direction}
                                onChange={(e) => onChange("direction", e)}
                                options={directions}
                            />
                        </div>
                    </div>
                    <div style={{paddingTop: "10px", marginLeft: "5px"}}>
                        <div className="input-title">Sentiment Target (%):</div>
                        <div style={{padding: "0px", width: "170px"}}>
                            <TextField
                                margin="normal"
                                variant="outlined"
                                type="number"
                                onChange={(e) => {
                                    let value = 0;
                                    if (e.target.value > 100) {
                                        value = 100;
                                    }else if(e.target.value < -100){
                                        value = -100;
                                    }else{
                                        value = e.target.value;
                                    }
                                    handleChangeTargetValue(value);
                                }}
                                value={conditionsFields[index].value}
                                fullWidth

                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {
                                                conditionsFields[index].direction.value === "above" &&
                                                <img height={20} width={20}
                                                     className="actionable-img"
                                                     src="/img/up-arrow.svg" alt=""/>
                                            }
                                            {
                                                conditionsFields[index].direction.value === "below" &&
                                                <img height={20} width={20}
                                                     className="actionable-img"
                                                     src="/img/down-arrow.svg" alt=""/>
                                            }
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                    </div>
                </div>
            }

        </div>
    );
};

const prepareInputProps = state => {

    return {
        exchanges: state.meta.exchanges,
    };
};

export default connect(prepareInputProps)(CreateAndUpdateNewsConditionModal);
