import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import {composeWithDevTools} from 'redux-devtools-extension';
import {persistReducer, persistStore} from 'redux-persist';
import {applyMiddleware, createStore} from 'redux';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import expireReducer from 'redux-persist-expire';
import meta from './reducers/metaReducer'
import coin from './reducers/coinReducer'
import watchlist from './reducers/watchListReducer'
import general from './reducers/generalReducer'
import rootReducer from "./reducers";

const persistedReducers = persistReducer(
  {
    key: 'cryptoIndexSeries:root',
    storage: storage,
    stateReconciler: hardSet,
    transforms: [
      expireReducer('general', {
        expireSeconds: 86400,
        expiredState: {...general},
        autoExpire: true,
      }),
      expireReducer('watchlist', {
        expireSeconds: 86400,
        expiredState: {...watchlist},
        autoExpire: true,
      }),
      expireReducer('coin', {
        expireSeconds: 86400,
        expiredState: {...coin},
        autoExpire: true,
      }),
      expireReducer('meta', {
        expireSeconds: 86400,
        expiredState: {...meta},
        autoExpire: true,
      }),
    ],
  },
  rootReducer,
);

export const store = createStore(persistedReducers, composeWithDevTools(applyMiddleware(thunk)));

export const persist = persistStore(store);
