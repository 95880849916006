import React, {useState} from 'react';
import {connect} from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {withRouter} from 'react-router-dom';

const NewsFilter = props => {
    const {
        meta, // Metadata props
        search, // Search string
        history // Used for navigation purposes
    } = props;

    const {
        lists, // Metadata lists (e.g. business_classification....)
        newsTags, // Additional news tags
        allCoins // Coin list
    } = meta || {};

    // Selected filters
    const [filter, setFilter] = useState({
        inputKeyword: '',
        index: '',
        startDate: '',
        endDate: '',
        selectAsset: {},
        selectCoin: {},
        selectCategory: {},
        selectExchange: {},
        selectNation: {},
        selectSector: {},
        selectSource: {},
    });

    const onChange = (type, val) => {
        // Copy filter for state update
        let newFilter = {...filter};
        newFilter[type] = val;

        // Update state
        setFilter(newFilter);
    };

    const StartDateInput = ({value, onClick}) => (
        <button className={'sm-select-input news'} onClick={onClick}>
            {value || "Start"}
        </button>
    );
    const EndDateInput = ({value, onClick}) => (
        <button className={'sm-select-input news'} onClick={onClick}>
            {value || "End"}
        </button>
    );

    const onEnter = (e) => {
        if (e.key === 'Enter') {
            search({...filter});
        }
    };

    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{padding: '1vh 0 1vh 0', height: "inherit"}} className="gray-container sentiment-analysis">
                <div className="section-header" style={{padding: "15px 25px 0 25px"}}>Filters</div>
                <div className="filter-bars" style={{display: "flex", flexDirection: "column", marginTop: "0"}}>
                    <div className="input-title news">Publication Start Date</div>
                    <div className={"sm-select-input"}>
                        <DatePicker
                            selected={filter.startDate}
                            onChange={date => {
                                setFilter({...filter, startDate: date})
                            }}
                            selectsStart
                            customInput={<StartDateInput/>}
                            timeInputLabel="Time:"
                            dateFormat="MM/dd/yyyy h:mm aa"
                            showTimeInput
                            isClearable={!!filter.startDate}
                            startDate={filter.startDate}
                            endDate={filter.endDate}/>
                    </div>
                    <div className="input-title news">Publication End Date</div>
                    <div className={"sm-select-input"}>
                        <DatePicker
                            selected={filter.endDate}
                            onChange={date => setFilter({...filter, endDate: date})}
                            selectsEnd
                            customInput={<EndDateInput/>}
                            timeInputLabel="Time:"
                            dateFormat="MM/dd/yyyy h:mm aa"
                            showTimeInput
                            isClearable={!!filter.endDate}
                            startDate={filter.endDate}
                            endDate={filter.endDate}
                            minDate={filter.startDate}/>
                    </div>


                    <div style={{paddingTop: "3vh", display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <div style={{width: 120, paddingRight: 10}} className="blue-border-btn flRight">
                            <a onClick={() => search({...filter})}>Search</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        meta: state.meta
    };
};

export default connect(mapStateToProps)(withRouter(NewsFilter));
