import * as Url from "url";


export function setReferral() {
    // Extract query params
    const params = new URL(window && window.location && window.location.href).searchParams;

    // Referral Id param
    const referralId = params.get("utm_source");

    // Store at local storage
    referralId && window && window.localStorage && window.localStorage.setItem("referral_id", referralId);
}


export function getReferral() {
    // Extract query params
    const params = new URL(window && window.location && window.location.href).searchParams;

    // Referral Id param
    const referralId = params.get("utm_source");
    if (referralId) return referralId;

    // Store at local storage
    return window && window.localStorage && window.localStorage.getItem("referral_id");
}
