import Links from "../../constants/Links";
import Api from "../index";
const INFO_PATH = "/Info"
const PRODUCT_PATH = "/Products"

const InfoApi = {
    getPromise(endpoint, params) {
        return Api.getPromise(endpoint, params, Links.ACCOUNT_API_URL)
    },
    postPromise(endpoint, body) {
        return Api.postPromise(endpoint, body, Links.ACCOUNT_API_URL)
    },
    postMPPromise(endpoint, body) {
        return Api.postMPPromise(endpoint, body, Links.ACCOUNT_API_URL)
    },
    putPromise(endpoint, body) {
        return Api.putPromise(endpoint, body, Links.ACCOUNT_API_URL)
    },
    deletePromise(endpoint, params) {
        return Api.deletePromise(endpoint, params, Links.ACCOUNT_API_URL)
    },

    /**
     * Get index
     */
    getIndex() {
        return this.getPromise(INFO_PATH + "/Index");
    },

    /**
     * Get Country List
     */
    getCountryList() {
        return this.getPromise(INFO_PATH + "/GetCountryList");
    },

    /**
     * Get Currency List
     */
    getCurrencyList() {
        return this.getPromise(INFO_PATH + "/GetCurrencyList");
    },

    /**
     * Get Products
     */
    getPlansAndPrices() {
        return this.getPromise(PRODUCT_PATH + "/GetPlansAndPrices");
    },


    /**
     * Get Products
     */
    getTrialPlansAndPrices(plans) {
        return this.getPromise(PRODUCT_PATH + "/GetPlansAndPrices?cis_product_group_code="+plans);
    },

}

export default InfoApi;

