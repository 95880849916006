import React from 'react';
import CISNavigator from "../../helpers/CISNavigator";
import {withRouter} from "react-router-dom";

const WatchListRow = props => {
  const {
    chgpct,
    price,
    marketCap,
    totalCoin,
    avalCoin,
    vol24,
    perClass,
    perTd,
    symbol,
    rowImage,
    name,
    exchange,
    rowClass,
    high24,
    low24,
    rowClick,
    setShow,
    row,
    history
  } = props;
  return (
    <tr  onClick={() => CISNavigator.navigateToCoinDetail(history, row, {value:row.ticker.quote_symbol, type:row.ticker.quote_symbol})} style={{cursor:"pointer"}}>
      <td
        style={{
          display: 'flex',
          flexDirection: 'row'
        }}>
        {symbol} / {exchange}
      </td>
      <td className={rowClass}>{price}</td>
      <td>{marketCap}</td> <td>{totalCoin}</td>
      <td>{vol24}</td>
      <td className={perClass}>{perTd}</td>
      <td className="edit-delete-btn">
        <button onClick={() => setShow(row.pairId)} className=" open-modal-event" data-target="delete-modal-wlitem">
          <img className="actionable-img" src="img/delete.svg" alt="" />
        </button>
      </td>
    </tr>
  );
};

export default withRouter(WatchListRow);
