import React, {useState, useEffect} from 'react';
import Swiper from 'react-id-swiper';
import {connect} from 'react-redux';
import CoinRow from '../../../components/CoinRow';
import Loading from '../../../components/Loading';
import Utils from '../../../helpers/Utils';
import UserApi from "../../../api/UserApi";



const isMobile = Utils.checkMobile();

const params = {
  slidesPerView: isMobile?3:8,
  freeMode: true,
  spaceBetween: 0,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  }
  // navigation: {
  //   nextEl: '.swiper-button-next',
  //   prevEl: '.swiper-button-prev',
  // },
};

const Watchlist = props => {
  const {meta} = props;
  const {allCoins} = meta || {};
  const allCoinsT = Utils.prepareCoinSelections(allCoins);
  const [result, setResult] = useState({items: [], loading: true});
  const [swiper, updateSwiper] = useState(null);
  const [swiperStatus, setSwiperStatus] = useState(false);
  // const screenOrientation = useScreenOrientation();



  const isEnd = swiper && swiper.isEnd;
  const isBeginning = swiper && swiper.isBeginning;
  useEffect(() => {
    setResult({items: [], loading: true});
    fetchData();
  }, []);

  useEffect(() => {

    if (swiper)
      setTimeout(() => {
        swiper.update();
      }, 1500);
  }, [swiper]);


  //Mobile screen rotation change slider items count @huzeyfe
  // useEffect(() => {
  //   if(isMobile && screenOrientation === "landscape-primary"){
  //     params.slidesPerView = 4;
  //   }else{
  //     params.slidesPerView = isMobile?2:8;
  //   }
  //   setResult({items: [], loading: true});
  //   fetchData();
  // }, [screenOrientation]);


  const progress = () => {
    setSwiperStatus(s => !s);
  };

  const fetchData = p => {
    // Fetch favorite watchlist
    UserApi.getFavorite().then(pairs => {
      const data = pairs.map(p => {
        let sc = allCoinsT.filter(c => c.symbol === p.from_sym);
        sc = sc[0] || {};
        return {...sc, symbol: p.from_sym, exchange: p.to_sym, pairId: p.pair_id};
      });
      setResult({items: data, loading: false});
    }).catch(ex => {
      console.error(ex)
      setResult({...result, items: [], loading: false});
    });

  };

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };
  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  return (
    <React.Fragment>
      <div className="top-carousel-slider">
        <Loading loading={result.loading}>
          <div style={{margin: 'auto', width: '92%'}}>
            <Swiper getSwiper={updateSwiper} {...{...params, on: {progress: progress}}}>
              {result.items.map((row, i) => (
                <div key={i} className="swiper-slide">
                  <CoinRow key={i} type={'WatchSlideRow'} rowClick={() => null} row={row} selectedExchange={{value: row.exchange}} />
                </div>
              ))}
            </Swiper>
            <div
              className={`swiper-button-next ${isEnd ? 'swiper-button-disabled' : ''}`}
              onClick={goNext}
              tabIndex="0"
              role="button"
              aria-label="Next slide"
              aria-disabled="false"/>
            <div
              className={`swiper-button-prev ${isBeginning ? 'swiper-button-disabled' : ''}`}
              onClick={goPrev}
              tabIndex="0"
              role="button"
              aria-label="Next slide"
              aria-disabled="false"/>
          </div>
        </Loading>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    meta: state.meta,
  };
};

export default connect(mapStateToProps)(Watchlist);
