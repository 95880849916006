import React, {useEffect} from "react";
import {withRouter} from "react-router-dom";
import RealtimeUtils from "../../helpers/RealtimeUtils";

const CoinRow = props => {
    const {
        coinRow, // Coin data to show
        coinSelected, // Called when coin selected with the selected coin
        addWatchlist, // Called when add watchlist is clicked with the selected coin
        setChannel, // Channel for updating ticker
        addAlert, // Called when add alert is clicked with the selected coin
        disabledColumns // Indicates disabled columns
    } = props;

    // Handles real time updates on row
    const [tickerData, setTickerData] = RealtimeUtils.useRealTimeRow(coinRow.ticker.symbol, setChannel,coinRow && coinRow.display_ticker);

    // useEffect(() => {
    //     // Initialize ticker data with coin row
    //     setTickerData(coinRow.display_ticker);
    // }, [coinRow]);


    // Check if column is enabled
    const isEnabled = col_id => !disabledColumns.includes(col_id);

    return (
        <tr onClick={e => coinSelected(coinRow)}>
            {isEnabled("symbol") && <td colSpan={2}>
                <div className="icon-img">
                    <img src={coinRow.image_url} style={{width: 20}} alt="" />
                </div>
                {coinRow.symbol}
            </td>}
            {isEnabled("name") && <td style={{textAlign: 'left'}} colSpan={6}>
                <div className="custom-icon-img">
                    <img width="15" src={coinRow.image_url} alt="" />
                </div>
                {coinRow.name}
            </td>}
            <td colSpan={3} className={tickerData.price_update_class}>{tickerData.price}</td>
            {isEnabled("market_cap") && <td colSpan={3} className={tickerData.market_cap_update_class}>{tickerData.market_cap}</td>}
            {isEnabled("total_coins") && <td colSpan={3}>{coinRow.display_supply.circulating_supply}</td>}
            {isEnabled("aval_coins") && <td colSpan={3}>{coinRow.display_supply.max_supply}</td>}
            {isEnabled("vol24") && <td colSpan={3} className={tickerData.vol24_update_class}>{tickerData.vol24}</td>}
            {isEnabled("chngpct") && <td colSpan={3} className={`${tickerData.chngpct_update_class} ${tickerData.chngpct_class}`}>{tickerData.chngpct}</td>}
            {isEnabled("weight") && <td colSpan={3}>{coinRow.display_weight}</td>}
            {isEnabled("set_watchlist") && <td colSpan={1} onClick={e => e.stopPropagation()}>
                <div className="three-dot-menu">
                    <div className="dot-menu-icon">
                        <img className="actionable-img" src="/img/three-dot.svg" alt="" />
                    </div>
                    <div className="table-dropdown-menu">
                        <ul>
                            <li>
                                <a
                                    onClick={e => {
                                        e.stopPropagation();
                                        addWatchlist(coinRow);
                                    }}
                                    className=" open-modal-event"
                                    data-target="set-watch-list">
                                    <img className="actionable-img" src="/img/rounded-add-button.svg" alt="" />
                                    Add Watchlist
                                </a>
                            </li>
                            <li>
                                <a
                                    onClick={() => addAlert(coinRow)}
                                    className=" open-modal-event"
                                    data-target="set-alert">
                                    <img className="actionable-img" src="/img/set-alarm.svg" alt="" />
                                    Set Alert
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </td>}
        </tr>
    );
};


export default withRouter(CoinRow);
