import React, {forwardRef, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import {setLoading} from "../../actions/generalAction";
import Loading from "../../components/Loading";
import Select from "../../components/Forms/Select";
import Utils from "../../helpers/Utils";
import DatePicker from "react-datepicker";
import {toastr} from "react-redux-toastr";
import PortfolioApi from "../../api/PortfolioApi";
import moment from 'moment';

const types = [
    {id: "buy", name: "Buy"},
    {id: "sell", name: "Sell"}
]


const PortfolioAddTrade = props => {
    const {close, show, portfolioAccounts, meta, selectedTrade, setSelectedTrade} = props;

    const ref = React.createRef();
    const StartDateInput = forwardRef(({value, onClick}, ref) => (
        <button className={'sm-select-input news'} onClick={onClick} ref={ref}>
            {value || "Start"}
        </button>
    ))

    const [manualAccounts, setManualAccounts] = useState({data: [], loading: true})
    const [manualAccount, setManualAccount] = useState({});
    const [type, setType] = useState({id: "buy", name: "Buy"});
    const [baseAmount, setBaseAmount] = useState(0);
    const [quoteAmount, setQuoteAmount] = useState(0);
    const [price, setPrice] = useState(0);
    const [symbol, setSymbol] = useState(Utils.prepareCoinSelections(meta.allCoins)[0]);
    const [symbols, setSymbols] = useState(Utils.prepareCoinSelections(meta.allCoins));
    const [ccys, setCcys] = useState([]);
    const [ccy, setCcy] = useState({});
    const [time, setTime] = useState('');
    const [fullLoading, setFullLoading] = useState(true);
    const [tradeId, setTradeId] = useState(0);


    useEffect(() => {

        if (portfolioAccounts && portfolioAccounts.data.length > 0 && show) {
            let manuals = portfolioAccounts.data.filter(el => el.type === "manual");
            if (manuals.length > 0) {
                const newCcys = getCcys();
                setCcys(newCcys);
                setManualAccounts({data: manuals, loading: false});
                if (selectedTrade.account_id) {
                    setManualAccount({
                        id: selectedTrade.account_id,
                        name: selectedTrade.account_name,
                        type: selectedTrade.name
                    });
                    setTime(selectedTrade.trade_ts);
                    setType({
                        id: selectedTrade.type,
                        name: selectedTrade.type.charAt(0).toUpperCase() + selectedTrade.type.slice(1, selectedTrade.type.length)
                    });
                    setCcy({ccy: selectedTrade.source_native_instrument_symbol.split("-")[1]});
                    setSymbol({symbol: selectedTrade.source_native_instrument_symbol.split("-")[0]});
                    setPrice(selectedTrade.price);
                    setBaseAmount(selectedTrade.base_asset_amount);
                    setQuoteAmount(selectedTrade.quote_asset_amount);
                    setTradeId(selectedTrade.id);
                } else {
                    setManualAccount(manuals[0]);
                    setCcy(newCcys[23]);
                    setPrice(0);
                    setQuoteAmount(0);
                    setBaseAmount(0);
                    setType({id: "buy", name: "Buy"});
                    setTime('');
                }


            } else {
                toastr.error('', 'There is no manual account linked to portfolio.');
                close();
            }
        } else if (show) {
            toastr.error('', 'There is no account linked to portfolio.');
            close();
        }
    }, [portfolioAccounts, show]);


    const getCcys = () => {
        return meta.lists.key_currency_list.map((el) => {
            return ({ccy: el[0], name: el[0] + "(" + el[1] + ")", type: el[2]});
        });
    }

    const onChange = (field, selected) => {
        if (field === "manual") {
            setManualAccount(selected);
        } else if (field === "type") {
            setType(selected);
        } else if (field === "baseAmount") {
            if (baseAmount > 0 && quoteAmount > 0 && price > 0) {
                setQuoteAmount(selected * price);
            } else if (quoteAmount > 0) {
                setPrice(quoteAmount / selected);
            } else if (price > 0) {
                setQuoteAmount(selected * price);
            }
            setBaseAmount(selected);
        } else if (field === "quoteAmount") {
            if (baseAmount > 0 && quoteAmount > 0 && price > 0) {
                setBaseAmount(selected / price);
            } else if (baseAmount > 0) {
                setPrice(selected / baseAmount);
            } else if (price > 0) {
                setBaseAmount(selected / price);
            }
            setQuoteAmount(selected);
        } else if (field === "symbol") {
            setSymbol(selected);
        } else if (field === "ccy") {
            setCcy(selected);
        } else if (field === "price") {
            if (baseAmount > 0 && quoteAmount > 0 && price > 0) {
                setQuoteAmount(selected * baseAmount);
            } else if (quoteAmount > 0) {
                setBaseAmount(quoteAmount / selected);
            } else if (price > 0) {
                setQuoteAmount(selected * baseAmount);
            }
            setPrice(selected);
        }

    };

    const onSave = () => {
        if (!manualAccount.id) {
            toastr.error('', 'You must choose account.');
            setFullLoading(false);
            return;
        } else if (!time) {
            toastr.error('', 'You must choose time.');
            return;
        } else if (baseAmount === 0) {
            toastr.error('', 'You must enter base amount.');
            return;
        } else if (quoteAmount === 0) {
            toastr.error('', 'You must enter quote amount.');
            return;
        } else if (price === 0) {
            toastr.error('', 'You must enter price.');
            return;
        }

        let f = {
            "trade_ts": moment.utc(time).unix() * 1000,
            "type": type.id,
            "symbol": symbol.symbol + "-" + ccy.ccy,
            "base_amount": baseAmount,
            "quote_amount": quoteAmount,
            "price": price
        }


        if (selectedTrade.account_id) {
            PortfolioApi.updateTrade(manualAccount.id, tradeId, f).then(resp => {
                toastr.success('', 'Successfully updated trade');
                setSelectedTrade({});
                close();
            }).catch(err => {
                console.error(err);
                toastr.error('Something went wrong while updating trade');
            });
        } else {
            PortfolioApi.addTrade(manualAccount.id, f).then(resp => {
                toastr.success('', 'Successfully add trade');
                close();
            }).catch(err => {
                console.error(err);
                toastr.error('Something went wrong while adding trade');
            });
        }

    };

    return (
        <div className={`general-modal ${show ? 'active' : ''}`} id="set-news-alert">
            <div className="vAlignWr">
                <div className="vAlign">
                    <div className="white-modal-inner set-alert">
                        <div className="modal-blue-header">
                            {selectedTrade.account_id ? "Update Trade" : "Add Trade"}
                            <div onClick={close} className="close-modal" data-target="set-news-alert">
                                <img src="/img/close-white.svg" alt=""/>
                            </div>
                        </div>
                        <div style={{minWidth: '50vw'}} className="modal-white-body">
                            <div className="general-tab-area">
                                <div className="general-tab-menu">
                                    <ul>
                                        <li className="active">
                                            <a href="#" data-target="price-alert">
                                                {selectedTrade.account_id ? "Update Trade" : "Add Trade"}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <Loading loading={manualAccounts.loading}>
                                    <div className="general-tab-body-all">
                                        <div className="general-tab-body active" id="news-alert">
                                            <div className="set-news-alert">
                                                <div className="input-table">
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td style={{width: "30%"}}>
                                                                <div className="spa-text">Manual Accounts</div>
                                                            </td>
                                                            <td>
                                                                <div className="sm-select-input">
                                                                    <Select
                                                                        selected={manualAccount.id || 0}
                                                                        optionValue={'id'}
                                                                        optionLabel={'name'}
                                                                        options={manualAccounts.data || []}
                                                                        onChange={selected => onChange("manual", selected)}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="spa-text">Time</div>
                                                            </td>
                                                            <td>
                                                                <div className={"sm-select-input"}>
                                                                    <DatePicker
                                                                        selected={time}
                                                                        onChange={date => {
                                                                            setTime(date);
                                                                        }}
                                                                        selectsStart
                                                                        customInput={<StartDateInput ref={ref}/>}
                                                                        timeInputLabel="Time:"
                                                                        dateFormat="MM/dd/yyyy h:mm aa"
                                                                        showTimeInput
                                                                        // isClearable={!!time}
                                                                        startDate={time}/>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="spa-text">Type</div>
                                                            </td>
                                                            <td>
                                                                <div className="sm-select-input">
                                                                    <Select
                                                                        selected={type.id || 0}
                                                                        optionValue={'id'}
                                                                        optionLabel={'name'}
                                                                        options={types || []}
                                                                        onChange={selected => onChange("type", selected)}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="spa-text">Symbol / Quote</div>
                                                            </td>
                                                            <td>
                                                                <div style={{display: "flex"}}>
                                                                    <div className="sm-select-input">
                                                                        <Select
                                                                            selected={symbol.symbol || 0}
                                                                            optionValue={'symbol'}
                                                                            optionLabel={'label'}
                                                                            options={symbols || []}
                                                                            onChange={selected => onChange("symbol", selected)}
                                                                        />
                                                                    </div>
                                                                    /
                                                                    <div className="sm-select-input">
                                                                        <Select
                                                                            selected={ccy.ccy || 0}
                                                                            optionValue={'ccy'}
                                                                            optionLabel={'name'}
                                                                            options={ccys || []}
                                                                            onChange={selected => onChange("ccy", selected)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="spa-text">Price ({ccy.ccy})</div>
                                                            </td>
                                                            <td>
                                                                <div className="bottom-border-input">
                                                                    <input type="number" placeholder="Price" min={0}
                                                                           maxLength={50} value={price}
                                                                           onChange={val => onChange('price', val.target.valueAsNumber)}/>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="spa-text">Amount ({symbol.symbol})</div>
                                                            </td>
                                                            <td>
                                                                <div className="bottom-border-input">
                                                                    <input type="number" placeholder="Amount" min={0}
                                                                           maxLength={50} value={baseAmount}
                                                                           onChange={val => onChange('baseAmount', val.target.valueAsNumber)}/>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="spa-text">Total ({ccy.ccy})</div>
                                                            </td>
                                                            <td>
                                                                <div className="bottom-border-input">
                                                                    <input type="number" placeholder="Total" min={0}
                                                                           maxLength={50} value={quoteAmount}
                                                                           onChange={val => onChange('quoteAmount', val.target.valueAsNumber)}/>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        </tbody>
                                                    </table>
                                                    <div className="clearfix"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Loading>
                            </div>
                        </div>
                        <div className="modal-gray-footer">
                            <div className="form-buttons">
                                <div className="form-flex">
                                    <div className="form-flex-inner" style={{width: "120px"}}>
                                        <div className="light-gray-btn">
                                            <button onClick={close} className="close-modal"
                                                    data-target="set-news-alert">
                                                CANCEL
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-flex-inner" style={{width: "120px"}}>
                                        <div className="light-blue-btn">
                                            <button onClick={onSave}>
                                                {selectedTrade.account_id ? "UPDATE TRADE" : "ADD TRADE"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setFullLoading: data => dispatch(setLoading(data)),
    };
};

const mapStateToProps = state => {
    return {
        meta: state.meta,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioAddTrade);
