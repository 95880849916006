import React, {useEffect, useState} from "react";
import Api from "../../../api";
import Loading from "../../../components/Loading";
import {connect} from "react-redux";
import GainersLosersTable from "../../../components/Sector/GainersLosersTable";
import Utils from "../../../helpers/Utils"

const isMobile = Utils.checkMobile();

const Gainers = props => {
    const {
        rawData, // Raw sector metadata
        exchange, // Selected exchange rate
        businessMetadata // Business metadata
    } = props;

    // News of the sector
    const [news, setNews] = useState({items: [], loading: true});
    // Main index code
    const [indexCode, setIndexCode] = useState(rawData && rawData.main_index_benchmark_code);

    // Executes when props are changed
    useEffect(() => {
        // Fetch news for the sector
        if (businessMetadata && businessMetadata.length > 0) {
            fetchNews(businessMetadata);
        }
        // Fetch index for the sector
        setIndexCode(rawData && rawData.main_index_benchmark_code);
    }, [rawData, businessMetadata]);


    // Fetches news for the sector
    const fetchNews = (businessMetadata) => {
        setNews({...news, loading: true});
        // Find which tag to search for the sector
        const tags = businessMetadata.find(el => el.numeric_code === rawData.numeric_code).news_tag;
        Api.searchNews({tags: tags})
            .then(res => setNews({items: res, loading: false}));
    };

    return (
        isMobile?
            <Loading loading={!rawData}>
                <div className="detail-indices-inner">
                    <div className="detail-indices-row">

                        {!indexCode && <div className="di-right-column" style={{"marginTop" : "10px"}}>
                            <div>
                                <GainersLosersTable exchange={exchange} sectorCode={rawData.id} isGainer={true}/>
                            </div>
                            <div>
                                <GainersLosersTable exchange={exchange} sectorCode={rawData.id}/>
                            </div>
                        </div>}
                    </div>
                    <div className="detail-indices-row" style={{display:'block'}}>

                        {indexCode && <div className="di-right-column" style={{"marginTop" : "20px"}}>
                            <div>
                                <GainersLosersTable exchange={exchange} sectorCode={rawData.id} isGainer={true}/>
                            </div>
                            <div>
                                <GainersLosersTable exchange={exchange} sectorCode={rawData.id} />
                            </div>
                        </div>}

                    </div>

                </div>
            </Loading>
            :
        <Loading loading={!rawData}>
            <div className="detail-indices-inner">
                <div className="detail-indices-row">

                    {!indexCode && <div className="di-right-column" style={{"marginTop" : "10px"}}>
                        <div className="col-md-6" style={{"paddingLeft" : "0px", "height": "100%"}}>
                            <GainersLosersTable exchange={exchange} sectorCode={rawData.id} isGainer={true}/>
                        </div>
                        <div className="col-md-6" style={{"paddingRight" : "0px", "height": "100%"}}>
                            <GainersLosersTable exchange={exchange} sectorCode={rawData.id}/>
                        </div>
                    </div>}
                </div>
                <div className="detail-indices-row" style={{display:'block'}}>

                    {indexCode && <div className="di-right-column" style={{"marginTop" : "20px"}}>
                        <div className="col-md-6" style={{"paddingLeft" : "0px", "height": "100%"}}>
                            <GainersLosersTable exchange={exchange} sectorCode={rawData.id} isGainer={true}/>
                        </div>
                        <div className="col-md-6" style={{"paddingRight" : "15px", "height": "100%", paddingLeft:'0px', marginTop:'10px'}}>
                            <GainersLosersTable exchange={exchange} sectorCode={rawData.id} />
                        </div>
                    </div>}

                </div>
                
            </div>
        </Loading>
    );
};

const mapStateToProps = state => {
    return {
        businessMetadata: (state.meta && state.meta.lists && state.meta.lists.business_classification_schema)
    };
};

export default connect(mapStateToProps)(Gainers);
