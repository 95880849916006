import React, {useEffect, useState} from 'react';
import Api from '../../../api';
import Loading from '../../../components/Loading';
import {connect} from 'react-redux';
import FitLatestNews from "../../../components/News/LatestNews/FitLatestNews";

const MobileNews = props => {
  const {
    indexCode, // Which index are we working on
    exchange // Exchange information
  } = props;

  // News related to given index
  const [news, setNews] = useState([]);
  // Index data to show
  const [indexData, setIndexData] = useState(null);
  // Indicates if there are any data fetching happens
  const [loading, setLoading] = useState(true);
  // Indicates if the news laoding
  const [newsLoading, setNewsLoading] = useState(true);

  // Fetch/Refresh index data on code or exchange changes
  useEffect(() => {
    if (indexCode && exchange) {
      setLoading(true);
      Api.readIndexFull(indexCode, exchange.value)
          .then(res => {
            setIndexData(res);
            setLoading(false)
          });
    }
  }, [indexCode]);

  // Fetch/Refresh news data on index data change
  useEffect(() => {
    // Generate news tags from constituent list
    if (indexData) {
      const tags = indexData.current_constituents.map(r => 'COIN_' + r).join(',');
      setNewsLoading(true);
      // Fetch index news from API
      Api.searchNewsPost({tags: [tags]})
          .then(res => {
            setNews(res);
            setNewsLoading(false);
          });
    }
  }, [indexData]);

  return (
    <Loading loading={loading}>
      <div style={{"width": "100%"}}>
        <div style={{marginBottom: "1vh", display: "flex", flexFlow: "row wrap"}}>
          <div style={{"padding": "0px", "display": "flex", "flexDirection": "column"}}>
              
              <Loading loading={newsLoading}>
                <FitLatestNews news={news} />
              </Loading>
          </div>

        </div>
      </div>
    </Loading>
  );
};

const mapStateToProps = state => {
  return {
    allCoins: (state.meta && state.meta.allCoins) || [],
  };
};

export default connect(mapStateToProps)(MobileNews);
