import React, {useEffect, useRef, useState} from "react";
import Api from "../../api";
import Loading from "../Loading";
import {withRouter} from "react-router-dom";
import CoinTable from "../CoinTable/CoinTable";
import DataMappers from "../../helpers/DataMappers";

/**
 * Table implementation for gainers/losers in Sector Overview and Index Overview
 *
 * @param props
 * @constructor
 */
const GainersLosersTable = props => {
    const {
        sectorCode, // Which sector coins will be shown (Not given in index page)
        constituents, // Which constituents will be used for generating table (Not given in sector page)
        exchange, // Which exchange is selected for the view
        isGainer, // Indicates if this is the gainer table
        extraParams, // Extra params to apply
    } = props;

    // Coins to show
    const [coins, setCoins] = useState([]);
    // Indicates if the coins are loading
    const [loading, setLoading] = useState(true);
    // Indicates if the only liquid coins are fetched
    const [liquidOnly, setLiquidOnly] = useState(false);

    // Liquid check
    const liquidCheck = useRef(false);

    useEffect(() => {
        // Compute one day
        const today = new Date();
        const yesterday = new Date(today.getTime());
        yesterday.setDate(today.getDate() - 1);

        // Prepare query parameters
        const params = {
            page_size: 5,
            order_by: isGainer ? "chgpct,desc" : "chgpct,asc",
            ccy: exchange.value,
            ts_cutoff: yesterday.getTime(),
            total_coin_volume_24h_min: liquidOnly ? 0.0001 : 0
        };

        // Apply extra params
        const ep = Object.assign(params, extraParams);

        if (sectorCode) { // Search within sector
            params["business_classification"] = sectorCode.toString();
        } else if (constituents) { // Search within constituents
            params["coin_symbols"] = constituents.join(",");
        }

        // Fetch coins from api
        setLoading(true);
        Api.searchCoinsPost(ep).then(res => {
            if (res!==null) {
                // Extra filtering for less than 5 coin lists
                const clean = res.filter(el => (el.ticker.chgpct < 0 && !isGainer) || (el.ticker.chgpct > 0 && isGainer));
                // Map ticker values for visualisation
                const mapped = clean.map(DataMappers.coinDataMapper);
                // Update states
                setCoins(mapped);
                setLoading(false);
            }

        });
    }, [isGainer, sectorCode, extraParams, constituents, exchange, liquidCheck.current]);


    const checkChanged = () => {
        // Change checkbox state
        liquidCheck.current = !liquidCheck.current;

        // Update state to trigger rendering
        setLiquidOnly(liquidCheck.current)
    };


    return (
        <section className={"coin-table"} style={{"height": "100%", "paddingBottom" : "0px"}}>
            <div className="gray-container" style={{"height": "100%"}}>
                <div className="table-head">
                    <div className="clearfix" />
                    <div className="table-left"><div className="section-header">{isGainer ? 'Gainers' : 'Losers'}</div></div>
                    <div className="table-right">
                        <div className="gl gray-border-checkbox increase">
                            <label className="gbc-label">
                                <div className="gbc-text">Liquid Only</div>
                                <input type="checkbox" checked={liquidCheck.current} name="incdec" onChange={checkChanged} />
                                <div className="gbc-checkmark" />
                            </label>
                        </div>
                    </div>
                </div>
                <div className="table-body table-responsive" style={{"marginTop" : "2.5em"}}>
                    <Loading loading={loading}>
                        <CoinTable disableRealTime={true} coinData={coins} exchange={exchange} disabledColumns={["weight", "set_watchlist", "symbol", "market_cap", "vol24", "vol", "high", "low", "total_coins", "aval_coins"]}/>
                    </Loading>
                </div>
            </div>
        </section>
    );
};

export default withRouter(GainersLosersTable);
