import React from 'react';
import CoinRow from '../../components/CoinRow';
import Select from '../Forms/Select';
import {connect} from 'react-redux';
import HomeTable from './HomeTable';
import FullTable from './FullTable';
import {withRouter} from 'react-router-dom';

import Utils from "../../helpers/Utils";
import CISNavigator from "../../helpers/CISNavigator";
import GainersLosers from "./GainersLosers";
import Loading from "../Loading";

const isMobile = Utils.checkMobile();

const SmallCoinList = props => {
    const {
        selectedExchange,
        title,
        columnClass,
        columnStyle,
        subClass,
        result,
        weight,
        meta,
        setExchange,
        exchange,
        homeTable,
        fullTable,
        gainerLoser,
        history,
        parentSubType,
        setMarketCap,
        marketCap,
        loadingC
    } = props;

    const currencyList =
        (meta.lists &&
            meta.lists.key_currency_list &&
            meta.lists.key_currency_list.map(c => ({
                value: c[0],
                label: c[0],
                type: c[2] === 'FIAT' ? 'USD' : 'BTC'
            }))) ||
        [];

    const table = () => (
        isMobile ?
            <div>
                <div
                    className="section-header"
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: '1em'
                    }}>
                    <div>{title}</div>
                    {setExchange && (
                        <div className={'table-select-input'}>
                            <div style={{fontSize: 13}} className={'sm-select-input small-select'}>
                                <Select selected={exchange} options={currencyList}
                                        onChange={selected => setExchange(selected.value)}/>
                            </div>
                        </div>
                    )}
                </div>
                <div className="table-body table-responsive" id="beauty-scroll7">
                    <table className="sectors">
                        <thead>
                        <tr>
                            <th style={{"paddingLeft": "6px"}}>Name</th>
                            <th style={{"textAlign": "center"}}>Price({selectedExchange.value})</th>
                            <th style={{"textAlign": "center"}}>CHG (24h)</th>
                            {weight && <th style={{"textAlign": "center"}}>Weight</th>}
                        </tr>
                        </thead>
                        <tbody>
                        {result.items.map((row, i) => (
                            <CoinRow
                                type={'smallTr'}
                                parentSubType={parentSubType}
                                rowClick={() => {
                                    CISNavigator.navigateToCoinDetail(history, row, selectedExchange);
                                    document.body.scrollTop = 0;
                                    document.documentElement.scrollTop = 0;
                                }}
                                row={row}
                                selectedExchange={selectedExchange}
                            />
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
            :
            <div>
                <div
                    className="section-header"
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: '1em'
                    }}>
                    <div>{title}</div>
                    {setExchange && (
                        <div className={'table-select-input'}>
                            <div style={{fontSize: 13}} className={'sm-select-input small-select'}>
                                <Select selected={exchange} options={currencyList}
                                        onChange={selected => setExchange(selected.value)}/>
                            </div>
                        </div>
                    )}
                </div>
                <div className="table-body table-responsive" id="beauty-scroll7" tabIndex={2}
                     style={{overflowY: 'scroll', height: 385, outline: 'none'}}>
                    <table className="sectors">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th style={{"textAlign": "left"}}>Price({selectedExchange.value})</th>
                            <th style={{"textAlign": "left"}}>CHG (24h)</th>
                            {weight && <th style={{"textAlign": "left"}}>Weight</th>}
                        </tr>
                        </thead>
                        <tbody>
                        {result.items.map((row, i) => (
                            <CoinRow
                                type={'smallTr'}
                                parentSubType={parentSubType}
                                rowClick={() => {
                                    CISNavigator.navigateToCoinDetail(history, row, selectedExchange);
                                    document.body.scrollTop = 0;
                                    document.documentElement.scrollTop = 0;
                                }}
                                row={row}
                                selectedExchange={selectedExchange}
                            />
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
    );

    return (
        <div className={columnClass} style={columnStyle}>
            <div className={subClass}>
                    {fullTable ? (
                        <FullTable {...{
                            title,
                            exchange,
                            currencyList,
                            setExchange,
                            selectedExchange,
                            result,
                            weight,
                            history,
                            parentSubType
                        }} />
                    ) : homeTable ? (
                        <HomeTable {...{
                            loadingC,
                            title,
                            exchange,
                            currencyList,
                            setExchange,
                            selectedExchange,
                            result,
                            history,
                            parentSubType
                        }} />
                    ) : gainerLoser ? (
                        <GainersLosers {...{
                            loadingC,
                            marketCap,
                            setMarketCap,
                            title,
                            exchange,
                            currencyList,
                            setExchange,
                            selectedExchange,
                            result,
                            history,
                            parentSubType,
                            meta
                        }} />
                    ): (
                        table()
                    )

                    }
            </div>
        </div>
    );
};

SmallCoinList.defaultProps = {
    columnClass: 'di-left-column',
    subClass: 'gray-container sectors-table',
    homeTable: false,
};

const mapStateToProps = state => {
    return {
        meta: state.meta,
    };
};

export default withRouter(connect(mapStateToProps)(SmallCoinList));
