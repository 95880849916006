import React from 'react';

const IndicesStateBarRow = props => {
  const {
    chgpct,
    price,
    marketCap,
    totalCoin,
    avalCoin,
    vol24,
    perClass,
    perTd,
    symbol,
    rowImage,
    name,
    exchange,
    rowClass,
    high24,
    low24,
    rowClick,
    row,
  } = props;

  return (
    <div className="state-bar">
      <div className="state-bar-inner">
        <div className="sb-text-img">
          <div className="sb-text">
            <div className="sb-lg-text">{row.index_code}</div>
            <div className="sb-sm-text">{row.index_name}</div>
          </div>
        </div>
        <div className="sb-numbers">
          <div className="sb-number">
            <div className="sb-number-head">Price({exchange})</div>
            <div className={`sb-number-inner ${rowClass}`}>{price}</div>
          </div>
          <div className="sb-number">
            <div className="sb-number-head">MKT CAP</div>
            <div className={`sb-number-inner`}>{marketCap}</div>
          </div>
          <div className="sb-number">
            <div className="sb-number-head">24h VOL</div>
            <div className="sb-number-inner">{vol24}</div>
          </div>
          <div className="sb-number">
            <div className="sb-number-head">24h LOW ({exchange})</div>
            <div className="sb-number-inner">{low24}</div>
          </div>
          <div className="sb-number">
            <div className="sb-number-head">24h HIGH ({exchange})</div>
            <div className="sb-number-inner">{high24}</div>
          </div>
          <div className="sb-number">
            <div className="sb-number-head">CHG (24h)</div>
            <div className={`sb-number-inner ${perClass}`}>{perTd}</div>
          </div>
          <div className="clearfix" />
        </div>
        <div className="clearfix" />
      </div>
    </div>
  );
};

export default IndicesStateBarRow;
