import React, {useEffect, useState} from "react";
import Select from "../../Forms/Select";
import {connect} from "react-redux";
import Utils from "../../../helpers/Utils";
import TextField from "@material-ui/core/TextField";
import Loading from "../../Loading";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import {MultiSelect} from "primereact/multiselect";

const isMobile = Utils.checkMobile();

const Filter = props => {
    const {search, meta, loadingP, cancel, show, history, param} = props;
    const {lists, allCoins} = meta || {};
    let assetTypeLists;
    let business;
    let consensusModels;
    const [dBusiness, setDBusiness] = useState([]);
    const [dAssetType, setDAssetType] = useState([]);
    if (!isMobile) {
        const newAssetTypeLists = (lists && lists.asset_type_list) || [];
        const newBusiness =
            (lists &&
                lists.business_classification_schema.sort((a, b) => {
                    var textA = a.business_classification.toUpperCase().trim();
                    var textB = b.business_classification.toUpperCase().trim();
                    return textA < textB ? -1 : textA > textB ? 1 : 0;
                })) ||
            [];
        business = newBusiness.map(el => {
            return {
                value: el.business_classification_id,
                name: el.business_classification,
                alphanumeric_code: el.alphanumeric_code
            }
        })
        // business.unshift({value: "",name:"ALL",alphanumeric_code: "",check: false});
        assetTypeLists = newAssetTypeLists.map(el => {
            return {value: el.asset_type_id, name: el.asset_type, asset_type_code: el.asset_type_code}
        })

        consensusModels = lists.consensus_model_list.map(el=>{
            return {value: el.consensus_model_id, name: el.consensus_model, consensus_model_code: el.consensus_model_code}
        })


    } else {
        assetTypeLists = (lists && lists.asset_type_list) || [];
        business =
            (lists &&
                lists.business_classification_schema.sort((a, b) => {
                    var textA = a.business_classification.toUpperCase().trim();
                    var textB = b.business_classification.toUpperCase().trim();
                    return textA < textB ? -1 : textA > textB ? 1 : 0;
                })) ||
            [];
    }


    const [reset, setReset] = useState(false);


    // Prepare coin list from metadata for dropdown list
    const allCoinsT = Utils.prepareCoinSelections(allCoins);

    const [filter, setFilter] = useState(
        {
            inputMktCapMin: "",
            inputMktCapMax: "",
            inputVolumeMin: "",
            inputVolumeMax: "",
            inputChgMin: "",
            inputChgMax: "",
            inputKeyword: "",
            selectAsset: {},
            selectCoin: {},
            selectSector: {},
            assetProperty: {},
            marketCapCategory: {},
            volumeCategory: {},
            volatilityCategory: {},
            selectedAsset: [],
            selectedBusiness: [],
            selectedMarketCap: [],
            selectedVolatility: [],
            selectedVolume: [],
            selectedAssetProperty: [],
            selectedConsensusModal : [],
            mineable: "",
            stablecoin: "",
            base:"default",
            is_defi: "",
        });


    const [assetProperty, setAssetProperty] = useState([
        {value: 1, name: "Stable", check: false},
        {value: 2, name: "Mineable", check: false},
        {value: 3, name: "DeFi", check: false}
    ])

    const [volatility, setVolatility] = useState([
        {value: 1, name: "Low"},
        {value: 2, name: "Normal"},
        {value: 3, name: "High"},
        {value: 4, name: "Very High"}
    ]);

    const [volume, setVolume] = useState([
        {value: 1, name: "Extremely Low"},
        {value: 2, name: "Very Low"},
        {value: 3, name: "Low"},
        {value: 4, name: "Medium-Low"},
        {value: 5, name: "Medium"},
        {value: 6, name: "Medium-High"},
        {value: 7, name: "High"},
        {value: 8, name: "Very High"},
        {value: 9, name: "Extremely High"}
    ]);

    const [marketCap, setMarketCap] = useState([
        {value: 5, name: "Micro", check: false},
        {value: 4, name: "Small", check: false},
        {value: 3, name: "Mid", check: false},
        {value: 2, name: "Large", check: false},
        {value: 1, name: "Huge", check: false}
    ]);

    //open advanced filter tab
    const [advancedFilter, setAdvancedFilter] = useState(false);

    const [coinSelections, setCoinSelections] = useState(allCoinsT);


    useEffect(() => {
        setDBusiness(business);
        setDAssetType(assetTypeLists);
    }, [history.location.search])

    useEffect(() => {
        if (param) {
            setFilter(param);
            searchT(param);
        }
    }, [param]);

    useEffect(()=>{
        if(history.location.search === ""){
            searchT(filter);
        }
    },[])

    // useEffect(() => {
    //     if (!filter.base) {
    //
    //     }
    // }, [filter.inputKeyword]);

    const searchT = (filter) => {

        if (!isMobile) {
            search({
                coin: "",
                sector: filter.selectedBusiness.join().toString() || "",
                asset: filter.selectedAsset.join().toString() || "",
                keyword: filter.inputKeyword,
                cap: {
                    min: filter.inputMktCapMin,
                    max: filter.inputMktCapMax
                },
                volume: {
                    min: filter.inputVolumeMin,
                    max: filter.inputVolumeMax
                },
                chg: {
                    min: filter.inputChgMin,
                    max: filter.inputChgMax
                },
                stablecoin: filter.stablecoin,
                mineable: filter.mineable,
                is_defi: filter.is_defi,
                market_cap_category: filter.selectedMarketCap.join().toString() || "",
                volume_category: filter.selectedVolume.join().toString() || "",
                volatility_category: filter.selectedVolatility.join().toString() || "",
                consensus_model: filter.selectedConsensusModal.join().toString() || ""
            });
        } else {
            search({
                coin: filter.selectCoin.value,
                sector: filter.selectSector.value,
                asset: filter.selectAsset.value,
                keyword: filter.inputKeyword,
                cap: {
                    min: filter.inputMktCapMin,
                    max: filter.inputMktCapMax
                },
                volume: {
                    min: filter.inputVolumeMin,
                    max: filter.inputVolumeMax
                },
                chg: {
                    min: filter.inputChgMin.toString(),
                    max: filter.inputChgMax
                }
            });
        }

    };

    const onChange = (type, val) => {
        if (type !== "selectSector" && type !== "selectAsset" && val === null) {
            val = {value: "", label: "ALL"};
        }
        let newFilter = {...filter};
        newFilter[type] = val;
        setFilter(newFilter);
        if(type === "inputKeyword"){
            searchT(newFilter);
        }
    };


    useEffect(() => {
        if (show) {
            document.querySelector("body").style.overflow = 'hidden';
        } else {
            document.querySelector("body").style.overflow = 'auto';
        }

    }, [show]);

    const arrangeMultiSelect = (type, val) => {
        let newFilter = {...filter};
        if(type === "selectedAssetProperty"){
            newFilter["mineable"] = val.includes(2) ? "true":"false";
            newFilter["stablecoin"] = val.includes(1) ? "true":"false";
            newFilter["is_defi"] = val.includes(3) ? "true":"false";
        }
        newFilter[type] = val;
        setFilter(newFilter);
    }


    return (
        isMobile ?
            <div className={`general-modal ${(show && 'active') || ''}`} id="delete-modal-wlitem">
                <div className="vAlignWr">
                    <div className="vAlign">
                        <div className="row row-d" style={{paddingTop: "10px", background: "black"}}>
                            <div className="col-xs-10 col-filter-top-l">
                                <p style={{margin: "5px 0px"}}>Filter</p>
                            </div>
                            <div className="col-xs-2 col-filter-top-r" onClick={cancel}>
                                <img src="/img/close-white.svg" alt="" style={{"padding": "5px"}}/>
                            </div>
                        </div>
                        <div className="filter-bars">
                            <div className="filter-bar-inner">
                                <div className="filter-bar">
                                    <div className="input-title">Select Coin:</div>
                                    <div className="filter-bar-input create-flex">
                                        <div className="sm-select-input">
                                            <Select
                                                addAll
                                                selected={filter.selectCoin.value || 0}
                                                optionValue={"symbol"}
                                                optionLabel={"value"}
                                                options={coinSelections || []}
                                                onChange={selected => onChange("selectCoin", selected)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-bar">
                                    <div className="input-title">Sector:</div>
                                    <div className="filter-bar-input create-flex">
                                        <div className="sm-select-input">
                                            <Select
                                                id="custom-select-5"
                                                addAll
                                                selected={filter.selectSector.value || 0}
                                                options={business || []}
                                                optionValue={"business_classification_id"}
                                                optionLabel={"business_classification"}
                                                onChange={selected => onChange("selectSector", selected)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-bar">
                                    <div className="input-title">Asset Type:</div>
                                    <div className="filter-bar-input create-flex">
                                        <div className="sm-select-input">
                                            <Select
                                                id="custom-select-6"
                                                addAll
                                                selected={filter.selectAsset.value || 0}
                                                options={assetTypeLists || []}
                                                optionValue={"asset_type_id"}
                                                optionLabel={"asset_type"}
                                                onChange={selected => onChange("selectAsset", selected)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-bar">
                                    <div className="input-title">Keywords:</div>
                                    <div className="filter-bar-input search-menu-all create-flex">
                                        <div className="gray-sm-input" style={{padding: "0px 5px"}}>
                                            <input
                                                type="text"
                                                value={filter.inputKeyword}
                                                onChange={val => onChange("inputKeyword", val.target.value)}
                                            />
                                        </div>
                                        <div className="search-menu">
                                            <ul id="search-list">
                                                <li data-value="BTC Coins 1">
                                                    <a href="#">BTC Coins 1</a>
                                                </li>
                                                <li data-value="BTC Coins 2">
                                                    <a href="#">BTC Coins 2</a>
                                                </li>
                                                <li data-value="BTC Coins 3">
                                                    <a href="#">BTC Coins 3</a>
                                                </li>
                                                <li data-value="BTC Coins 4">
                                                    <a href="#">BTC Coins 4</a>
                                                </li>
                                                <li data-value="BTC Coins 5">
                                                    <a href="#">BTC Coins 5</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-bar-inner mtop15">
                                <div className="filter-bar">
                                    <div className="filter-bar-input" style={{width: "unset"}}>
                                        <div className="input-title">Mkt. Cap:</div>
                                        <div className="filter-bar-input create-flex">
                                            <div className="flex-row" style={{padding: "0px 5px"}}>
                                                <div className="gray-sm-input">
                                                    <input
                                                        type="text"
                                                        placeholder="Min"
                                                        value={filter.inputMktCapMin}
                                                        onChange={val =>
                                                            onChange("inputMktCapMin", val.target.value)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex-row" style={{padding: "0px 5px"}}>
                                                <div className="gray-sm-input">
                                                    <input
                                                        type="text"
                                                        placeholder="Max"
                                                        value={filter.inputMktCapMax}
                                                        onChange={val =>
                                                            onChange("inputMktCapMax", val.target.value)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-bar">
                                    <div className="filter-bar-input" style={{width: "unset"}}>
                                        <div className="input-title">Volume</div>
                                        <div className="filter-bar-input create-flex">
                                            <div className="flex-row" style={{padding: "0px 5px"}}>
                                                <div className="gray-sm-input">
                                                    <input
                                                        type="text"
                                                        placeholder="Min"
                                                        value={filter.inputVolumeMin}
                                                        onChange={val =>
                                                            onChange("inputVolumeMin", val.target.value)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex-row" style={{padding: "0px 5px"}}>
                                                <div className="gray-sm-input">
                                                    <input
                                                        type="text"
                                                        placeholder="Max"
                                                        value={filter.inputVolumeMax}
                                                        onChange={val =>
                                                            onChange("inputVolumeMax", val.target.value)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-bar">
                                    <div className="filter-bar-input" style={{width: "unset"}}>
                                        <div className="input-title">24h % Chg:</div>
                                        <div className="filter-bar-input create-flex">
                                            <div className="flex-row" style={{padding: "0px 5px"}}>
                                                <div className="gray-sm-input">
                                                    <input
                                                        type="text"
                                                        placeholder="Min"
                                                        value={filter.inputChgMin}
                                                        onChange={val => onChange("inputChgMin", val.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex-row" style={{padding: "0px 5px"}}>
                                                <div className="gray-sm-input">
                                                    <input
                                                        type="text"
                                                        placeholder="Max"
                                                        value={filter.inputChgMax}
                                                        onChange={val => onChange("inputChgMax", val.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-bar">
                                    <div
                                        className="filter-bar-input"
                                        style={{marginLeft: "auto", width: "300px"}}
                                    >
                                        <div className="blue-border-btn flLeft flLeftB">
                                            <a
                                                className={loadingP ? "a-disabled" : ""}
                                                onClick={() => {
                                                    if (!loadingP) searchT();
                                                    cancel();
                                                }}
                                            >
                                                Search
                                            </a>
                                        </div>

                                        <div className="blue-border-btn flLeft flLeftB">
                                            <a
                                                className={loadingP ? "a-disabled" : ""}
                                                onClick={() => {
                                                    const f = {
                                                        inputMktCapMin: "",
                                                        inputMktCapMax: "",
                                                        inputVolumeMin: "",
                                                        inputVolumeMax: "",
                                                        inputChgMin: "",
                                                        inputChgMax: "",
                                                        inputKeyword: "",
                                                        selectAsset: {},
                                                        selectCoin: {},
                                                        selectSector: {}
                                                    };
                                                    setFilter(f);
                                                    search(f);
                                                    cancel();
                                                }}
                                            >
                                                Reset
                                            </a>
                                        </div>

                                        <div className="clearfix"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div className="filter-bars" style={{border: "1px solid black",marginTop:"0px"}}>
                <div className="filter-bar-inner"
                     style={{justifyContent: "space-between", paddingBottom: "10px", paddingTop: "10px"}}>
                    <div className="filter-bar">
                        <div className="filter-bar-input search-menu-all create-flex">
                            <div className="gray-sm-input gray-sm-inputS" style={{padding: "0px 5px", width: "250px"}}>
                                <TextField id="outlined-basic" label="Search" variant="outlined"
                                           value={filter.inputKeyword || ""}
                                           onChange={val =>{onChange("inputKeyword", val.target.value);
                                           }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="filter-bar" style={{padding: "0px"}}>
                        <div className="blue-border-btn" style={{width: "240px", marginTop: "5px"}}>
                            <a style={{fontSize: "14px"}}
                               className={loadingP ? "a-disabled" : ""}
                               onClick={() => {
                                   setAdvancedFilter(!advancedFilter);
                               }}
                            >
                                Advanced Filter
                            </a>
                        </div>
                    </div>

                </div>
                <Loading loading={reset}>
                    {advancedFilter &&
                    <div style={{paddingTop: "10px", borderTop: "2px solid black"}}>
                        <div className="filter-bar-inner mtop15">

                            <div className="filter-bar">
                                <div className="input-title">Sector:</div>
                                <div className="filter-bar-input create-flex" style={{width: "250px"}}>
                                    <div className="sm-select-input">
                                        <div className="card">
                                            <MultiSelect maxSelectedLabels={1} placeholder="Select Sector"
                                                         optionLabel="name" value={filter.selectedBusiness} options={dBusiness}
                                                         onChange={(e) => {
                                                             arrangeMultiSelect("selectedBusiness", e.value)
                                                         }} filter/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-bar">
                                <div className="input-title">Asset Type:</div>
                                <div className="filter-bar-input create-flex">
                                    <div className="sm-select-input multiselect-regular">
                                        <MultiSelect maxSelectedLabels={1} placeholder="Select Asset" optionLabel="name"
                                                     optionValue="value" value={filter.selectedAsset} options={dAssetType}
                                                     onChange={(e) =>
                                                         arrangeMultiSelect("selectedAsset", e.value)
                                                     } filter/>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-bar">
                                <div className="input-title">Market Cap Category:</div>
                                <div className="filter-bar-input create-flex">
                                    <div className="sm-select-input multiselect-regular">
                                        <MultiSelect maxSelectedLabels={1} placeholder="Select Market Cap"
                                                     optionLabel="name" optionValue="value" value={filter.selectedMarketCap}
                                                     options={marketCap} onChange={(e) =>  arrangeMultiSelect("selectedMarketCap", e.value)}
                                                     filter/>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-bar">
                                <div className="input-title">Volatility Category:</div>
                                <div className="filter-bar-input create-flex">
                                    <div className="sm-select-input multiselect-regular">
                                        <MultiSelect maxSelectedLabels={1} placeholder="Select Volatility"
                                                     optionLabel="name" optionValue="value" value={filter.selectedVolatility}
                                                     options={volatility}
                                                     onChange={(e) => arrangeMultiSelect("selectedVolatility", e.value)} filter/>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-bar">
                                <div className="input-title">Volume Category:</div>
                                <div className="filter-bar-input create-flex">
                                    <div className="sm-select-input multiselect-regular">
                                        <MultiSelect maxSelectedLabels={1} placeholder="Select Volume"
                                                     optionLabel="name" optionValue="value" value={filter.selectedVolume}
                                                     options={volume} onChange={(e) => arrangeMultiSelect("selectedVolume", e.value)}
                                                     filter/>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-bar" style={{width: "100%", padding: "0px"}}>
                                <div
                                    className="filter-bar-input"
                                    style={{display: "flex", float: "right", width: "240px"}}
                                >
                                    <div className="blue-border-btn flLeft flLeftB">
                                        <a style={{fontSize: "14px"}}
                                           className={loadingP ? "a-disabled" : ""}
                                           onClick={() => {
                                               if (!loadingP) searchT(filter);
                                           }}
                                        >
                                            Search
                                        </a>
                                    </div>

                                    <div className="blue-border-btn flLeft flLeftB">
                                        <a style={{fontSize: "14px"}}

                                           className={loadingP ? "a-disabled" : ""}
                                           onClick={() => {

                                               const f = {
                                                   inputMktCapMin: "",
                                                   inputMktCapMax: "",
                                                   inputVolumeMin: "",
                                                   inputVolumeMax: "",
                                                   inputChgMin: "",
                                                   inputChgMax: "",
                                                   inputKeyword: "",
                                                   selectAsset: {},
                                                   selectCoin: {},
                                                   selectSector: {},
                                                   assetProperty: {},
                                                   marketCapCategory: {},
                                                   volumeCategory: {},
                                                   volatilityCategory: {},
                                                   selectedAsset: [],
                                                   selectedBusiness: [],
                                                   selectedMarketCap: [],
                                                   selectedVolatility: [],
                                                   selectedVolume: [],
                                                   selectedAssetProperty: [],
                                                   selectedConsensusModal : [],
                                                   mineable: false,
                                                   stablecoin: false,
                                               };
                                               setFilter(f);
                                               search(f);
                                               setReset(true);
                                               setTimeout(function () {
                                                   setReset(false);
                                               }, 500);

                                           }}
                                        >
                                            Reset
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="filter-bar-inner mtop15">
                            <div className="filter-bar">
                                <div className="filter-bar-input" style={{width: "unset"}}>
                                    <div className="input-title">Mkt. Cap:</div>
                                    <div className="filter-bar-input create-flex" style={{width: "255px"}}>
                                        <div className="flex-row" style={{padding: "0px 5px"}}>
                                            <div className="gray-sm-input">
                                                <TextField id="outlined-basic" label="Min" variant="outlined"
                                                           value={filter.inputMktCapMin}
                                                           onChange={val => onChange("inputMktCapMin", val.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex-row" style={{padding: "0px 5px"}}>
                                            <div className="gray-sm-input">
                                                <TextField id="outlined-basic" label="Max" variant="outlined"
                                                           value={filter.inputMktCapMax}
                                                           onChange={val => onChange("inputMktCapMax", val.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-bar">
                                <div className="filter-bar-input" style={{width: "unset"}}>
                                    <div className="input-title">Volume</div>
                                    <div className="filter-bar-input create-flex">
                                        <div className="flex-row" style={{padding: "0px 5px"}}>
                                            <div className="gray-sm-input">
                                                <TextField id="outlined-basic" label="Min" variant="outlined"
                                                           value={filter.inputVolumeMin}
                                                           onChange={val => onChange("inputVolumeMin", val.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex-row" style={{padding: "0px 5px"}}>
                                            <div className="gray-sm-input">
                                                <TextField id="outlined-basic" label="Max" variant="outlined"
                                                           value={filter.inputVolumeMax}
                                                           onChange={val => onChange("inputVolumeMax", val.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-bar">
                                <div className="filter-bar-input" style={{width: "unset"}}>
                                    <div className="input-title">24h % Chg:</div>
                                    <div className="filter-bar-input create-flex">
                                        <div className="flex-row" style={{padding: "0px 5px"}}>
                                            <div className="gray-sm-input">
                                                <TextField id="outlined-basic" label="Min" variant="outlined"
                                                           value={filter.inputChgMin}
                                                           onChange={val => onChange("inputChgMin", val.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex-row" style={{padding: "0px 5px"}}>
                                            <div className="gray-sm-input">
                                                <TextField id="outlined-basic" label="Max" variant="outlined"
                                                           value={filter.inputChgMax}
                                                           onChange={val => onChange("inputChgMax", val.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-bar">
                                <div className="input-title">Asset Property:</div>
                                <div className="filter-bar-input create-flex">
                                    <div className="sm-select-input multiselect-regular" style={{paddingLeft: "0px"}}>
                                        <MultiSelect maxSelectedLabels={1} placeholder="Select Asset Property"
                                                     optionLabel="name" optionValue="value"
                                                     value={filter.selectedAssetProperty} options={assetProperty}
                                                     onChange={(e) => arrangeMultiSelect("selectedAssetProperty", e.value)} filter/>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-bar">
                                <div className="input-title">Consensus Algorithm:</div>
                                <div className="filter-bar-input create-flex">
                                    <div className="sm-select-input multiselect-regular" style={{paddingLeft: "0px"}}>
                                        <MultiSelect maxSelectedLabels={1} placeholder="Select Consensus Algorithm"
                                                     optionLabel="name" optionValue="value"
                                                     value={filter.selectedConsensusModal} options={consensusModels}
                                                     onChange={(e) => arrangeMultiSelect("selectedConsensusModal", e.value)} filter/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {filter.selectedBusiness.length > 0 &&
                        <div style={{display: "flex", paddingTop: "10px", flexWrap: "wrap"}}>
                            <div style={{fontSize: "14px", color: "#b2b2b2", paddingRight: "5px"}}>
                                Sector:
                            </div>
                            {filter.selectedBusiness && filter.selectedBusiness.map(el => {
                                let html;
                                dBusiness.forEach(b => {
                                    if (b.value === el) {
                                        html = (
                                            <div style={{
                                                border: "1px solid #b2b2b2",
                                                borderRadius: "5px",
                                                paddingRight: "5px",
                                                marginRight: "5px",
                                                marginBottom: "4px"
                                            }}>
                                                <div style={{
                                                    padding: "2px",
                                                    fontSize: "14px",
                                                    color: "#b2b2b2",
                                                    display: "flex"
                                                }}>
                                                    <p style={{margin: "0 0"}}>{b.name}</p><i
                                                    style={{marginTop: "3px", cursor: "pointer"}}
                                                    onClick={() => {
                                                        let selected = [...filter.selectedBusiness];
                                                        selected.splice(filter.selectedBusiness.indexOf(el), 1);
                                                        arrangeMultiSelect("selectedBusiness", selected);
                                                    }
                                                    } className="fas fa-times"/>
                                                </div>
                                            </div>)
                                    }
                                })
                                return html;
                            })
                            }
                        </div>
                        }
                        {filter.selectedAsset.length > 0 &&
                        <div style={{display: "flex", paddingTop: "10px", flexWrap: "wrap"}}>
                            <div style={{fontSize: "14px", color: "#b2b2b2", paddingRight: "5px"}}>
                                Asset Type:
                            </div>
                            {filter.selectedAsset && filter.selectedAsset.map(el => {
                                let html;
                                dAssetType.forEach(b => {
                                    if (b.value === el) {
                                        html = (
                                            <div style={{
                                                border: "1px solid #b2b2b2",
                                                borderRadius: "5px",
                                                paddingRight: "5px",
                                                marginRight: "5px",
                                                marginBottom: "4px"
                                            }}>
                                                <div style={{
                                                    padding: "2px",
                                                    fontSize: "14px",
                                                    color: "#b2b2b2",
                                                    display: "flex"
                                                }}>
                                                    <p style={{margin: "0 0"}}>{b.name}</p><i
                                                    style={{marginTop: "3px", cursor: "pointer"}}
                                                    onClick={() => {
                                                        let selected = [...filter.selectedAsset];
                                                        selected.splice(filter.selectedAsset.indexOf(el), 1);
                                                        arrangeMultiSelect("selectedAsset", selected);
                                                    }
                                                    } className="fas fa-times"/>
                                                </div>
                                            </div>)
                                    }
                                })
                                return html;
                            })
                            }
                        </div>
                        }
                        {filter.selectedMarketCap.length > 0 &&
                        <div style={{display: "flex", paddingTop: "10px", flexWrap: "wrap"}}>
                            <div style={{fontSize: "14px", color: "#b2b2b2", paddingRight: "5px"}}>
                                Market Cap Category:
                            </div>
                            {filter.selectedMarketCap && filter.selectedMarketCap.map(el => {
                                let html;
                                marketCap.forEach(b => {
                                    if (b.value === el) {
                                        html = (
                                            <div style={{
                                                border: "1px solid #b2b2b2",
                                                borderRadius: "5px",
                                                paddingRight: "5px",
                                                marginRight: "5px",
                                                marginBottom: "4px"
                                            }}>
                                                <div style={{
                                                    padding: "2px",
                                                    fontSize: "14px",
                                                    color: "#b2b2b2",
                                                    display: "flex"
                                                }}>
                                                    <p style={{margin: "0 0"}}>{b.name}</p><i
                                                    style={{marginTop: "3px", cursor: "pointer"}}
                                                    onClick={() => {
                                                        let selected = [...filter.selectedMarketCap];
                                                        selected.splice(filter.selectedMarketCap.indexOf(el), 1);
                                                        arrangeMultiSelect("selectedMarketCap", selected);
                                                    }
                                                    } className="fas fa-times"/>
                                                </div>
                                            </div>)
                                    }
                                })
                                return html;
                            })
                            }
                        </div>
                        }
                        {filter.selectedVolatility.length > 0 &&
                        <div style={{display: "flex", paddingTop: "10px", flexWrap: "wrap"}}>
                            <div style={{fontSize: "14px", color: "#b2b2b2", paddingRight: "5px"}}>
                                Volatility Category:
                            </div>
                            {filter.selectedVolatility && filter.selectedVolatility.map(el => {
                                let html;
                                volatility.forEach(b => {
                                    if (b.value === el) {
                                        html = (
                                            <div style={{
                                                border: "1px solid #b2b2b2",
                                                borderRadius: "5px",
                                                paddingRight: "5px",
                                                marginRight: "5px",
                                                marginBottom: "4px"
                                            }}>
                                                <div style={{
                                                    padding: "2px",
                                                    fontSize: "14px",
                                                    color: "#b2b2b2",
                                                    display: "flex"
                                                }}>
                                                    <p style={{margin: "0 0"}}>{b.name}</p><i
                                                    style={{marginTop: "3px", cursor: "pointer"}}
                                                    onClick={() => {
                                                        let selected = [...filter.selectedVolatility];
                                                        selected.splice(filter.selectedVolatility.indexOf(el), 1);
                                                        arrangeMultiSelect("selectedVolatility", selected);
                                                    }
                                                    } className="fas fa-times"/>
                                                </div>
                                            </div>)
                                    }
                                })
                                return html;
                            })
                            }
                        </div>
                        }
                        {filter.selectedVolume.length > 0 &&
                        <div style={{display: "flex", paddingTop: "10px", flexWrap: "wrap"}}>
                            <div style={{fontSize: "14px", color: "#b2b2b2", paddingRight: "5px"}}>
                                Volume Category:
                            </div>
                            {filter.selectedVolume.map(el => {
                                let html;
                                volume.forEach(b => {
                                    if (b.value === el) {
                                        html = (
                                            <div style={{
                                                border: "1px solid #b2b2b2",
                                                borderRadius: "5px",
                                                paddingRight: "5px",
                                                marginRight: "5px",
                                                marginBottom: "4px"
                                            }}>
                                                <div style={{
                                                    padding: "2px",
                                                    fontSize: "14px",
                                                    color: "#b2b2b2",
                                                    display: "flex"
                                                }}>
                                                    <p style={{margin: "0 0"}}>{b.name}</p><i
                                                    style={{marginTop: "3px", cursor: "pointer"}}
                                                    onClick={() => {
                                                        let selected = [...filter.selectedVolume];
                                                        selected.splice(filter.selectedVolume.indexOf(el), 1);
                                                        arrangeMultiSelect("selectedVolume", selected);
                                                    }
                                                    } className="fas fa-times"/>
                                                </div>
                                            </div>)
                                    }
                                })
                                return html;
                            })
                            }
                        </div>
                        }
                        {filter.selectedAssetProperty.length > 0 &&
                        <div style={{display: "flex", paddingTop: "10px", flexWrap: "wrap"}}>
                            <div style={{fontSize: "14px", color: "#b2b2b2", paddingRight: "5px"}}>
                                Asset Property:
                            </div>
                            {filter.selectedAssetProperty.map(el => {
                                let html;
                                assetProperty.forEach(b => {
                                    if (b.value === el) {
                                        html = (
                                            <div style={{
                                                border: "1px solid #b2b2b2",
                                                borderRadius: "5px",
                                                paddingRight: "5px",
                                                marginRight: "5px",
                                                marginBottom: "4px"
                                            }}>
                                                <div style={{
                                                    padding: "2px",
                                                    fontSize: "14px",
                                                    color: "#b2b2b2",
                                                    display: "flex"
                                                }}>
                                                    <p style={{margin: "0 0"}}>{b.name}</p><i
                                                    style={{marginTop: "3px", cursor: "pointer"}} onClick={() => {
                                                    let selected = [...filter.selectedAssetProperty];
                                                    selected.splice(filter.selectedAssetProperty.indexOf(el), 1);
                                                    arrangeMultiSelect("selectedAssetProperty", selected);
                                                }
                                                } className="fas fa-times"/>
                                                </div>
                                            </div>)
                                    }
                                })
                                return html;
                            })
                            }
                        </div>
                        }
                        {filter.selectedConsensusModal && filter.selectedConsensusModal.length > 0 &&
                        <div style={{display: "flex", paddingTop: "10px", flexWrap: "wrap"}}>
                            <div style={{fontSize: "14px", color: "#b2b2b2", paddingRight: "5px"}}>
                                Consensus Algorithm:
                            </div>
                            {filter.selectedConsensusModal.map(el => {
                                let html;
                                consensusModels.forEach(b => {
                                    if (b.value === el) {
                                        html = (
                                            <div style={{
                                                border: "1px solid #b2b2b2",
                                                borderRadius: "5px",
                                                paddingRight: "5px",
                                                marginRight: "5px",
                                                marginBottom: "4px"
                                            }}>
                                                <div style={{
                                                    padding: "2px",
                                                    fontSize: "14px",
                                                    color: "#b2b2b2",
                                                    display: "flex"
                                                }}>
                                                    <p style={{margin: "0 0"}}>{b.name}</p><i
                                                    style={{marginTop: "3px", cursor: "pointer"}} onClick={() => {
                                                    let selected = [...filter.selectedConsensusModal];
                                                    selected.splice(filter.selectedConsensusModal.indexOf(el), 1);
                                                    arrangeMultiSelect("selectedConsensusModal", selected);
                                                }
                                                } className="fas fa-times"/>
                                                </div>
                                            </div>)
                                    }
                                })
                                return html;
                            })
                            }
                        }
                        </div>
                        }
                    </div>

                    }
                </Loading>

            </div>

    );
}
;
const mapStateToProps = state =>
{
    return {
        meta: state.meta
    };
}
;
export default connect(mapStateToProps)(Filter);
