import React from 'react';
import PropTypes from 'prop-types'; 
import ReactPaginate from 'react-paginate';
import './custom.css'
import Utils from "../../helpers/Utils";

const isMobile = Utils.checkMobile();

const Paginate = ({ pageCount, onPageChange, initialPage, active }) => {

  return (
    <ReactPaginate
      forcePage={active}
      pageCount={pageCount}
      initialPage={initialPage || active}
      pageRangeDisplayed={isMobile?2:4}
      marginPagesDisplayed={isMobile?3:4}
      onPageChange={onPageChange}
      previousLabel={<i className="fa fa-angle-left"></i>}
      nextLabel={<i className="fa fa-angle-right"></i>}
      breakLabel={'...'}
      containerClassName={"pagination"}
      activeClassName={"active"}
    />
  );
}

Paginate.defaultProps = {
  pageRangeDisplayed: 20,
  initialPage: 0,
  active: 0
}

Paginate.propTypes = {
  pageCount: PropTypes.number,
  active: PropTypes.number,
  pageRangeDisplayed: PropTypes.number,
  onPageChange: PropTypes.func
};

Paginate.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Paginate;