import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Breadcrump from '../../components/Breadcrump';
import {withRouter} from 'react-router-dom';
import UserApi from "../../api/UserApi";
import ExchangeConfirmation from "./ExchangeConfirmation";
import Loading from "../../components/Loading";
import ExchangeCreate from "./ExchangeCreate";
import {checkQuotaAccess, useAuth0} from "../../helpers/Auth0";
import {toastr} from "react-redux-toastr";
import PortfolioApi from "../../api/PortfolioApi";
import arrowUp from "../Exchanges/img/arrow-down.svg";
import downArrow from "../Exchanges/img/arrow-right.svg";




const Exchanges = props => {
    const {location, history, exchanges} = props;
    const [showP, setShowP] = useState(false);
    const [showC, setShowC] = useState(false);
    const [data, setData] = useState({items: [], loading: true, selected: {}});
    const {user} = useAuth0();
    const [row, setRow] = useState({
        waek:{isOpen:false},
        wiaa:{isOpen:false},
        iis:{isOpen:false}
    })

    // Extract navigated exchange value
    const locState = location && location.state && location.state.exchange ? location.state : null;

    //Portfolios
    const [portfolios, setPortfolios] = useState({data: [], loading: true});

    const getPortfolios = (type) => {
        PortfolioApi.getPortfolios().then(resp => {
            if (resp.length > 0) {
                setPortfolios({data: resp, loading: false});
            } else {
                setPortfolios({data: [], loading: false});
            }
        });
    }


    useEffect(() => {
        getPortfolios("create");
    }, [])

    useEffect(() => {
        if (locState && !data.loading) {
            setShowP(true);
        }
    }, [locState, data.loading]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = (text) => {
        setData(d => ({...d, loading: true}));
        PortfolioApi.getAccounts().then(resp=>{
            // Fill exchange names
            const mapped = resp.map(el => {
                if(el.key_details){
                    el.key_details.exchange_display_name = exchanges[el.key_details.exchange_name].name;
                }
                return el;
            });
            setData({items: mapped, loading: false });
        }).catch(e=>{
            if(text === "new"){
                history.go(0);
            }
        })
    };

    const onUpdate = item => {
        setData(d => ({...d, selected: item}));
        setShowP(true);
    };

    const onDelete = item => {
        setData(d => ({...d, selected: item}));
        setShowC(true);
    };

    const handleChangeRow = (val) => {
        let newRow = {...row}
        newRow[val].isOpen = !newRow[val].isOpen;
        setRow(newRow);
    }

    return (
        <React.Fragment>
            <section className="dashboard padding-sm-container">
                <Breadcrump />
                <div className="flex-row">
                    <div className="flex-width-left" style={{width: "100%"}}>
                        <div className="gray-container alerts-price-alert">
                            <div className="section-header">
                                <div className={"row"}>
                                    <div className="col-md-9">
                                        My Accounts
                                    </div>
                                    <div className="col-md-3">
                                        Help
                                    </div>
                                </div>
                            </div>
                            <div className="pa-inner">
                                <div id="beauty-scroll-table">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <div style={{marginTop:"10px",color:"#fff"}}>
                                                Add / Connect exchange accounts to your cistrader account here.
                                            </div>
                                            <div style={{marginTop:"10px",color:"#fff"}}>
                                                Create offline / manual accounts and upload your transactions & transfer manually  / via csv.
                                            </div>
                                            <div style={{width: 110,marginTop:"20px",marginBottom:"10px"}} className="blue-border-btn flLeft">
                                                <a style={{cursor: "pointer",padding:"10px 0px"}} onClick={() => {
                                                    const quota = checkQuotaAccess(user, 'exchange_connections', data.items.length);
                                                    setData(d => ({...d, selected: null}));
                                                    if (quota) {
                                                        setShowP(true);
                                                    } else {
                                                        toastr.error("", `You cannot create new watchlist. Upgrade your plan (Remaining Quota: ${quota}).`)
                                                    }
                                                }}>ADD ACCOUNT</a>
                                            </div>
                                            <Loading loading={data.loading}>
                                                <table style={{marginTop:"10px",marginBottom:"10px"}}>
                                                    <thead>
                                                    <tr>
                                                        <th style={{color: '#7d7d7d',padding:"10px"}}>Account Name</th>
                                                        <th style={{color: '#7d7d7d',padding:"10px"}}>Type</th>
                                                        <th style={{color: '#7d7d7d',padding:"10px"}}>Exchange</th>
                                                        <th style={{color: '#7d7d7d',padding:"10px",textAlign:"right"}}>Actions</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {data.items.map((d, i) => (
                                                        <tr key={i}>
                                                            <td style={{color: '#fff',padding:"10px"}}>{d.name}</td>
                                                            <td style={{color: '#fff',padding:"10px"}}>{d.type.toUpperCase()}</td>
                                                            {d.key_details ? <td style={{color: '#fff',padding:"10px"}}>{d.key_details.exchange_display_name}</td> : <td style={{color: '#fff',padding:"10px"}}>-</td>}
                                                            <td className="edit-delete-btn" style={{width: "120px", cursor: "pointer",padding:"10px"}}>
                                                                <button onClick={() => onUpdate(d)} className=" open-modal-event" style={{marginRight: "15px"}}>
                                                                    <img className="actionable-img" src="/img/edit.svg" alt="" />
                                                                </button>
                                                                <button onClick={() => onDelete(d)} className=" open-modal-event">
                                                                    <img className="actionable-img" src="/img/delete.svg" alt="" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </Loading>
                                        </div>
                                        <div className="col-md-3">
                                            <div style={{marginTop:"10px", color:"white"}}>
                                                <div style={{fontSize:"16px",fontWeight:"bold", display:"flex", justifyContent:"space-between", cursor:"pointer"}} data-toggle="collapse"  aria-controls="collapseExample" data-target="#collapseExample" aria-expanded="false" onClick={()=>{handleChangeRow("wiaa")}}>
                                                    <div>
                                                        What is an account?
                                                    </div>
                                                    <div>
                                                        <img width={15} height={15} className={"actionable-img"} src={row.wiaa.is_open ? downArrow: arrowUp}/>
                                                    </div>
                                                </div>
                                                <div className="collapse" id="collapseExample">
                                                    <div style={{fontSize:"14px",fontWeight:"bold",marginTop:"10px"}}>
                                                        Exchange Accounts:
                                                    </div>
                                                    <div style={{textAlign:"justify",marginTop:"5px"}}>
                                                        You can create an exchange account or a manual / offline account. Exchange accounts need to be linked to an account of a supported exchange e.g. Binance, Coinbase Pro etc… Once connected you will be able to manage and execute orders for that account from within the CIS Trader platform. We’ll also collect trades and transaction data for your account (where available) to provide you with portfolio data & analytics.
                                                    </div>
                                                    <div style={{fontSize:"14px",fontWeight:"bold",marginTop:"10px"}}>
                                                        Manual / Offline accounts:
                                                    </div>
                                                    <div style={{textAlign:"justify",marginTop:"5px"}}>
                                                        You can also create a manual / offline account – these cannot be used for any order management / execution but allow you to upload trade / transaction data to be used in our portfolio analytics service. This is particularly useful if you have funds held in a wallet and would like to factor those into your portfolio calculations. It can also be useful as a simulation / test environment as well.
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{marginTop:"20px", color:"white"}}>
                                                <div style={{fontSize:"16px",fontWeight:"bold", display:"flex", justifyContent:"space-between", cursor:"pointer"}} data-toggle="collapse"  aria-controls="collapseExample1" data-target="#collapseExample1" aria-expanded="false" onClick={()=>{handleChangeRow("iis")}}>
                                                    <div>
                                                        Is it secure?
                                                    </div>
                                                    <div>
                                                        <img width={15} height={15} className={"actionable-img"} src={row.iis.is_open ? downArrow: arrowUp}/>
                                                    </div>
                                                </div>
                                                <div className="collapse" id="collapseExample1">
                                                    <div style={{textAlign:"justify",marginTop:"5px"}}>
                                                        <b style={{marginRight:"5px"}}>Absolutely!</b>We operate with up-to-date and strict security protocols. When you connect an exchange account we encrypt your account keys and then store them in Microsoft’s Azure Key Vault. Also all of our servers & infrastructure sit in private networks in the cloud and therefore cannot be accessed by anything or anyone outside of that private network.
                                                    </div>
                                                    <div style={{textAlign:"justify",marginTop:"10px"}}>
                                                        For added peace of mind we also recommend that, where possible, you disable withdrawals for the accounts API Keys you use with us – (we don’t require any withdrawal capabilities for our platform)
                                                    </div>
                                                    <div style={{textAlign:"justify",marginTop:"10px"}}>
                                                        Additionally – our platform requires 2-Factor Authentication for any functionality related to your exchange accounts.
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{marginTop:"20px", color:"white"}}>
                                                <div style={{fontSize:"16px",fontWeight:"bold", display:"flex", justifyContent:"space-between", cursor:"pointer"}} data-toggle="collapse"  aria-controls="collapseExample2" data-target="#collapseExample2" aria-expanded="false" onClick={()=>{handleChangeRow("waek")}}>
                                                    <div>
                                                        What are exchange Keys?
                                                    </div>
                                                    <div>
                                                        <img width={15} height={15} className={"actionable-img"} src={row.waek.is_open ? downArrow: arrowUp}/>
                                                    </div>
                                                </div>
                                                <div className="collapse" id="collapseExample2">
                                                    <div style={{textAlign:"justify",marginTop:"5px"}}>
                                                        Each exchange works slightly differently but generally you can generate an API Key & Secret for any exchange account.
                                                    </div>
                                                    <div style={{textAlign:"justify",marginTop:"10px"}}>
                                                        Your API Key & Secret can then be used to make requests to the exchange for your account programmatically.
                                                    </div>
                                                    <div style={{textAlign:"justify",marginTop:"10px"}}>
                                                        Our platform allows you to “connect” to your exchange accounts using your API Key & Secret. Once connected you can then interact with your exchange account through our platform.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ExchangeCreate
                fetchDataAccount={()=>{}}
                fetchKeys={fetchData}
                history={history}
                portfolios={portfolios}
                isNew={data.selected}
                locState={locState}
                show={showP}
                close={() => {
                    if (locState) history.replace('/exchanges');
                    setData(d => ({...d, selected: {}}));
                    setShowP(false);
                }}
            />
            <ExchangeConfirmation
                fetchDataAccount={()=>{}}
                fetchKeys={fetchData}
                item={data.selected}
                show={showC}
                close={() => {
                    setData(d => ({...d, selected: {}}));
                    setShowC(false);
                }}
            />
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    // Convert exchanges to map of id to exchange data
    const exchangeMap = {};
    state.meta.connectable_exchanges.filter(el => el.is_connectable).forEach(el => exchangeMap[el.id] = el);

    return {
        exchanges: exchangeMap
    }
};

export default connect(mapStateToProps)(withRouter(Exchanges));
