import React from 'react';

const WatchSlideRow = props => {
  const {
    chgpct,
    price,
    marketCap,
    totalCoin,
    avalCoin,
    vol24,
    perClass,
    perTd,
    symbol,
    rowImage,
    name,
    exchange,
    rowClass,
    high24,
    low24,
    rowClick,
    setShow,
    row,
  } = props;
  return (
    <span>
      <div className="tpc-list-inner">
        <div>
          <div className="vAlign">
            <div className="tpc-list-num-text">
              <div className="tpc-list-head">
                {symbol} / {exchange}
              </div>
              <div className={'tpc-list-num ' + rowClass}>{price}</div>
              <div className={'tpc-list-state ' + perClass}>{perTd}</div>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};

export default WatchSlideRow;
