import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import DatePicker from 'react-datepicker';
import Select from '../../components/Forms/Select';
import 'react-datepicker/dist/react-datepicker.css';
import Utils from "../../helpers/Utils";
import Api from "../../api";

const isMobile = Utils.checkMobile();

const concat = (x,y) =>
    x.concat(y)

const flatMap = (f,xs) =>
    xs.map(f).reduce(concat, [])

const NewsFilter = props => {
    const {
        meta, // Metadata props
        search, // Search string
        show,   //show modal
        cancel,  //cancel modal
    } = props;

    const {
        lists, // Metadata lists (e.g. business_classification....)
        newsTags, // Additional news tags
        allCoins // Coin list
    } = meta || {};

    const [sources,setSources] = useState([]);

    // Asset type option
    const assetTypes = (lists && lists.asset_type_list) || [];
    // Sector options
    const business = (lists && lists.business_classification_schema) || [];
    // Exchange options
    const exchanges = (newsTags && newsTags.exchanges) || [];
    // Nation options
    const nations = (newsTags && flatMap(el => el.children, newsTags.geographical)) || [];
    // Other categories
    const categories = (newsTags && newsTags.other) || [];
    // Prepare coin list from metadata for dropdown list
    const allCoinsT = Utils.prepareCoinSelections(allCoins);

    useEffect(()=>{
        Api.getPromise('/Metadata/GetNewsSources').then(resp=>{
            setSources(resp);
        })
    },[])

    // Selected filters
    const [filter, setFilter] = useState({
        inputKeyword: '',
        index: '',
        startDate: '',
        endDate: '',
        selectAsset: {},
        selectCoin: {},
        selectCategory: {},
        selectExchange: {},
        selectNation: {},
        selectSector: {},
        selectSource: {},
        minSentiment: null,
        maxSentiment: null
    });

    const onChange = (type, val) => {
        // Copy filter for state update
        let newFilter = {...filter};
        newFilter[type] = val;

        // Update state
        setFilter(newFilter);
    };

    const StartDateInput = ({value, onClick}) => (
        <button className={'sm-select-input news'} onClick={onClick}>
            {value || "Start"}
        </button>
    );
    const EndDateInput = ({value, onClick}) => (
        <button className={'sm-select-input news'} onClick={onClick}>
            {value || "End"}
        </button>
    );

    const onEnter = (e) => {
        if (e.key === 'Enter') {
            search({...filter});
        }
    };
    useEffect(()=>{
        if(show){
            document.querySelector("body").style.overflow = 'hidden';
        }else{
            document.querySelector("body").style.overflow = 'auto';
        }

    },[show])

    return (
        isMobile?
            <div className={`general-modal ${(show && 'active') || ''}`} id="delete-modal-wlitem"  >
                <div className="vAlignWr" >
                    <div className="vAlign" >
                        <div className="row row-d" style={{paddingTop:"10px",background:"black"}}>
                            <div className="col-xs-10 col-filter-top-l">
                                <p style={{margin:"5px 0px"}}>Filter</p>
                            </div>
                            <div className="col-xs-2 col-filter-top-r" onClick={cancel}>
                                <img src="/img/close-white.svg" alt="" style={{"padding": "5px"}}/>
                            </div>
                        </div>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <div style={{padding: '1vh 0 1vh 0', height: "inherit"}} className="gray-container gc-news sentiment-analysis">
                                <div className="section-header" style={{padding: "15px 25px 0 25px"}}>Text Search</div>
                                <div className="filter-bars" style={{display: "flex", flexDirection: "column", marginTop: "0", paddingBottom: 0}}>
                                    <div className="gray-sm-input" >
                                        <input type="text" onKeyDown={onEnter} style={{padding: '5px 10px'}} value={filter.inputKeyword} onChange={val => onChange('inputKeyword', val.target.value)} />
                                    </div>
                                </div>
                                <div style={{paddingTop: "3vh", display: 'flex', paddingBottom: "15px", flexDirection: 'row', justifyContent: 'center'}}>
                                    <div style={{width: 120}} className="blue-border-btn flRight">
                                        <a onClick={() => {search({...filter}); cancel();}}>Search</a>
                                    </div>
                                </div>
                            </div>
                            <div style={{padding: '1vh 0 1vh 0', height: "inherit"}} className="gray-container gc-news sentiment-analysis">
                                <div className="section-header" style={{padding: "15px 25px 0 25px"}}>Filters</div>
                                <div className="filter-bars" style={{display: "flex", flexDirection: "column", marginTop: "0"}}>
                                    <div className="input-title news">Select Coin</div>
                                    <div className="sm-select-input">
                                        <Select
                                            addAll
                                            optionValue={'symbol'}
                                            optionLabel={'value'}
                                            selected={filter.selectCoin.value || 0}
                                            options={allCoinsT || []}
                                            onChange={selected => onChange('selectCoin', selected)}
                                        />
                                    </div>
                                    <div className="input-title news">Sector</div>
                                    <div className="sm-select-input">
                                        <Select
                                            id="custom-select-5"
                                            addAll
                                            selected={filter.selectSector.value || 0}
                                            options={business || []}
                                            optionValue={'business_classification_id'}
                                            optionLabel={'business_classification'}
                                            onChange={selected => onChange('selectSector', selected)}
                                        />
                                    </div>
                                    <div className="input-title news">Asset Type</div>
                                    <div className="sm-select-input">
                                        <Select
                                            id="custom-select-6"
                                            addAll
                                            selected={filter.selectAsset.value || 0}
                                            options={assetTypes || []}
                                            optionValue={'asset_type_id'}
                                            optionLabel={'asset_type'}
                                            onChange={selected => onChange('selectAsset', selected)}
                                        />
                                    </div>
                                    <div className="input-title news">Source</div>
                                    <div className="sm-select-input">
                                        <Select
                                            id="custom-select-6"
                                            addAll
                                            selected={filter.selectSource.value || 0}
                                            options={sources || []}
                                            optionValue={'id'}
                                            optionLabel={'name'}
                                            onChange={selected => onChange('selectSource', selected)}
                                        />
                                    </div>
                                    <div className="input-title news">Exchange</div>
                                    <div className="sm-select-input">
                                        <Select
                                            id="custom-select-6"
                                            addAll
                                            selected={filter.selectExchange.value || 0}
                                            options={exchanges || []}
                                            optionValue={'id'}
                                            optionLabel={'tag'}
                                            onChange={selected => onChange('selectExchange', selected)}
                                        />
                                    </div>
                                    <div className="input-title news">Category</div>
                                    <div className="sm-select-input">
                                        <Select
                                            id="custom-select-6"
                                            addAll
                                            selected={filter.selectCategory.value || 0}
                                            options={categories || []}
                                            optionValue={'id'}
                                            optionLabel={'tag'}
                                            onChange={selected => onChange('selectCategory', selected)}
                                        />
                                    </div>
                                    <div className="input-title news">Nation</div>
                                    <div className="sm-select-input">
                                        <Select
                                            id="custom-select-6"
                                            addAll
                                            selected={filter.selectNation.value || 0}
                                            options={nations || []}
                                            optionValue={'id'}
                                            optionLabel={'tag'}
                                            onChange={selected => onChange('selectNation', selected)}
                                        />
                                    </div>
                                    <div className="input-title news">Publication Start Date</div>
                                    <div className={"sm-select-input"}>
                                        <DatePicker
                                            selected={filter.startDate}
                                            onChange={date => {
                                                setFilter({...filter, startDate: date})
                                            }}
                                            selectsStart
                                            customInput={<StartDateInput/>}
                                            timeInputLabel="Time:"
                                            dateFormat="MM/dd/yyyy h:mm aa"
                                            showTimeInput
                                            isClearable={!!filter.startDate}
                                            startDate={filter.startDate}
                                            endDate={filter.endDate}/>
                                    </div>
                                    <div className="input-title news">Publication End Date</div>
                                    <div className={"sm-select-input"}>
                                        <DatePicker
                                            selected={filter.endDate}
                                            onChange={date => setFilter({...filter, endDate: date})}
                                            selectsEnd
                                            customInput={<EndDateInput/>}
                                            timeInputLabel="Time:"
                                            dateFormat="MM/dd/yyyy h:mm aa"
                                            showTimeInput
                                            isClearable={!!filter.endDate}
                                            startDate={filter.endDate}
                                            endDate={filter.endDate}
                                            minDate={filter.startDate}/>
                                    </div>
                                    <div className="input-title news">Minimum Sentiment Score (Min -100)</div>
                                    <div className={"sm-select-input set-price-alert"}>
                                        <div className="bottom-border-input">
                                            <input style={{fontSize: "13px"}}
                                                   id={"min_sentiment"}
                                                   type={"number"}
                                                   min={-100}
                                                   max={100}
                                                   step={1}
                                                // value={filter.minSentiment}
                                                   onChange={el => onChange("minSentiment", el.target.valueAsNumber)}/>
                                        </div>
                                    </div>
                                    <div className="input-title news">Maximum Sentiment Score (Max 100)</div>
                                    <div className={"sm-select-input set-price-alert"}>
                                        <div className="bottom-border-input">
                                            <input style={{fontSize: "13px"}}
                                                   id={"max_sentiment"}
                                                   type={"number"}
                                                   min={-100}
                                                   max={100}
                                                   step={1}
                                                // value={filter.maxSentiment}
                                                   onChange={el => onChange("maxSentiment", el.target.valueAsNumber)}/>
                                        </div>
                                    </div>
                                    <div style={{paddingTop: "3vh", display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                        <div style={{width: 120, paddingRight: 10}} className="blue-border-btn flRight">
                                            <a onClick={() => {search({...filter}); cancel();}}>Search</a>
                                        </div>
                                        {/*<div style={{width: 120}} className="green-border-btn flRight">*/}
                                        {/*    <a*/}
                                        {/*        onClick={() =>*/}
                                        {/*            history.push({*/}
                                        {/*                pathname: '/alerts',*/}
                                        {/*                state: {filter: filter, news: true},*/}
                                        {/*            })*/}
                                        {/*        }>*/}
                                        {/*        Set News Alert*/}
                                        {/*    </a>*/}
                                        {/*</div>*/}
                                        <div className="blue-border-btn flLeft" style={{width: 120, paddingRight: 10}}>
                                            <a onClick={() => {
                                                document.getElementById("min_sentiment").value = null;
                                                document.getElementById("max_sentiment").value = null;
                                                const f = {
                                                    selectCategory: {},
                                                    selectNation: {},
                                                    selectExchange: {},
                                                    selectSource: {},
                                                    selectAsset: {},
                                                    selectCoin: {},
                                                    selectSector: {}
                                                };
                                                setFilter(f);
                                                search(f);
                                                cancel();
                                            }}>
                                                Reset
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div style={{display: "flex", flexDirection: "column"}}>
                <div style={{padding: '1vh 0 1vh 0', height: "inherit", marginBottom: "2vh"}} className="gray-container sentiment-analysis">
                    <div className="section-header" style={{padding: "15px 25px 0 25px"}}>Text Search</div>
                    <div className="filter-bars" style={{display: "flex", flexDirection: "column", marginTop: "0", paddingBottom: 0}}>
                        <div className="gray-sm-input" >
                            <input type="text" onKeyDown={onEnter} style={{padding: '5px 10px'}} value={filter.inputKeyword} onChange={val => onChange('inputKeyword', val.target.value)} />
                        </div>
                    </div>
                    <div style={{paddingTop: "3vh", display: 'flex', paddingBottom: "15px", flexDirection: 'row', justifyContent: 'center'}}>
                        <div style={{width: 120}} className="blue-border-btn flRight">
                            <a onClick={() => search({...filter})}>Search</a>
                        </div>
                    </div>
                </div>
                <div style={{padding: '1vh 0 1vh 0', height: "inherit"}} className="gray-container sentiment-analysis">
                    <div className="section-header" style={{padding: "15px 25px 0 25px"}}>Filters</div>
                    <div className="filter-bars" style={{display: "flex", flexDirection: "column", marginTop: "0"}}>
                        <div className="input-title news">Select Coin</div>
                        <div className="sm-select-input">
                            <Select
                                addAll
                                optionValue={'symbol'}
                                optionLabel={'value'}
                                selected={filter.selectCoin.value || 0}
                                options={allCoinsT || []}
                                onChange={selected => onChange('selectCoin', selected)}
                            />
                        </div>
                        <div className="input-title news">Sector</div>
                        <div className="sm-select-input">
                            <Select
                                id="custom-select-5"
                                addAll
                                selected={filter.selectSector.value || 0}
                                options={business || []}
                                optionValue={'business_classification_id'}
                                optionLabel={'business_classification'}
                                onChange={selected => onChange('selectSector', selected)}
                            />
                        </div>
                        <div className="input-title news">Asset Type</div>
                        <div className="sm-select-input">
                            <Select
                                id="custom-select-6"
                                addAll
                                selected={filter.selectAsset.value || 0}
                                options={assetTypes || []}
                                optionValue={'asset_type_id'}
                                optionLabel={'asset_type'}
                                onChange={selected => onChange('selectAsset', selected)}
                            />
                        </div>
                        <div className="input-title news">Source</div>
                        <div className="sm-select-input">
                            <Select
                                id="custom-select-6"
                                addAll
                                selected={filter.selectSource.value || 0}
                                options={sources || []}
                                optionValue={'id'}
                                optionLabel={'name'}
                                onChange={selected => onChange('selectSource', selected)}
                            />
                        </div>
                        <div className="input-title news">Exchange</div>
                        <div className="sm-select-input">
                            <Select
                                id="custom-select-6"
                                addAll
                                selected={filter.selectExchange.value || 0}
                                options={exchanges || []}
                                optionValue={'id'}
                                optionLabel={'tag'}
                                onChange={selected => onChange('selectExchange', selected)}
                            />
                        </div>
                        <div className="input-title news">Category</div>
                        <div className="sm-select-input">
                            <Select
                                id="custom-select-6"
                                addAll
                                selected={filter.selectCategory.value || 0}
                                options={categories || []}
                                optionValue={'id'}
                                optionLabel={'tag'}
                                onChange={selected => onChange('selectCategory', selected)}
                            />
                        </div>
                        <div className="input-title news">Nation</div>
                        <div className="sm-select-input">
                            <Select
                                id="custom-select-6"
                                addAll
                                selected={filter.selectNation.value || 0}
                                options={nations || []}
                                optionValue={'id'}
                                optionLabel={'tag'}
                                onChange={selected => onChange('selectNation', selected)}
                            />
                        </div>
                        <div className="input-title news">Publication Start Date</div>
                        <div className={"sm-select-input"}>
                            <DatePicker
                                selected={filter.startDate}
                                onChange={date => {
                                    setFilter({...filter, startDate: date})
                                }}
                                selectsStart
                                customInput={<StartDateInput/>}
                                timeInputLabel="Time:"
                                dateFormat="MM/dd/yyyy h:mm aa"
                                showTimeInput
                                isClearable={!!filter.startDate}
                                startDate={filter.startDate}
                                endDate={filter.endDate}/>
                        </div>
                        <div className="input-title news">Publication End Date</div>
                        <div className={"sm-select-input"}>
                            <DatePicker
                                selected={filter.endDate}
                                onChange={date => setFilter({...filter, endDate: date})}
                                selectsEnd
                                customInput={<EndDateInput/>}
                                timeInputLabel="Time:"
                                dateFormat="MM/dd/yyyy h:mm aa"
                                showTimeInput
                                isClearable={!!filter.endDate}
                                startDate={filter.endDate}
                                endDate={filter.endDate}
                                minDate={filter.startDate}/>
                        </div>
                        <div className="input-title news">Minimum Sentiment Score (Min -100)</div>
                        <div className={"sm-select-input set-price-alert"}>
                            <div className="bottom-border-input">
                                <input style={{fontSize: "13px"}}
                                       id={"min_sentiment"}
                                       type={"number"}
                                       min={-100}
                                       max={100}
                                       step={1}
                                       // value={filter.minSentiment}
                                       onChange={el => onChange("minSentiment", el.target.valueAsNumber)}/>
                            </div>
                        </div>
                        <div className="input-title news">Maximum Sentiment Score (Max 100)</div>
                        <div className={"sm-select-input set-price-alert"}>
                            <div className="bottom-border-input">
                                <input style={{fontSize: "13px"}}
                                       id={"max_sentiment"}
                                       type={"number"}
                                       min={-100}
                                       max={100}
                                       step={1}
                                       // value={filter.maxSentiment}
                                       onChange={el => onChange("maxSentiment", el.target.valueAsNumber)}/>
                            </div>
                        </div>
                        <div style={{paddingTop: "3vh", display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                            <div style={{width: 120, paddingRight: 10}} className="blue-border-btn flRight">
                                <a onClick={() => search({...filter})}>Search</a>
                            </div>
                            {/*<div style={{width: 120}} className="green-border-btn flRight">*/}
                            {/*    <a*/}
                            {/*        onClick={() =>*/}
                            {/*            history.push({*/}
                            {/*                pathname: '/alerts',*/}
                            {/*                state: {filter: filter, news: true},*/}
                            {/*            })*/}
                            {/*        }>*/}
                            {/*        Set News Alert*/}
                            {/*    </a>*/}
                            {/*</div>*/}
                            <div className="blue-border-btn flLeft" style={{width: 120, paddingRight: 10}}>
                                <a onClick={() => {
                                        document.getElementById("min_sentiment").value = null;
                                        document.getElementById("max_sentiment").value = null;
                                        const f = {
                                            selectCategory: {},
                                            selectNation: {},
                                            selectExchange: {},
                                            selectSource: {},
                                            selectAsset: {},
                                            selectCoin: {},
                                            selectSector: {}
                                        };
                                        setFilter(f);
                                        search(f);
                                }}>
                                        Reset
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
};

const mapStateToProps = state => {
    return {
        meta: state.meta
    };
};

export default connect(mapStateToProps)(NewsFilter);
