import React, {useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import TradingView from "../../tradingview";
import Utils from "../../helpers/Utils";
import AllMarkets from "../../components/CoinMarketTab/AllMarkets";
import VolumeAnalysis from "../../components/CoinMarketTab/VolumeAnalysis";
import MarketsTable from "../../components/CoinMarketTab/MarketTable";
import ChartViewMarket from "../../components/CoinMarketTab/ChartViewMarket";
import ArbitrageGridMarket from "../../components/CoinMarketTab/ArbitrageGridMarket";

const isMobile = Utils.checkMobile();



const CoinMarkets = (props) => {
    const {
        coinRow,
        exchanges, // Exchange information metadata
        ccy
    } = props;

    // Selected ticker for trading view
    const [selectedTicker, setSelectedTicker] = useState(null);

    // Menu
    const [menu,setMenu] = useState("All Markets");
    return (
        isMobile?
            <div>
                <div>
                    <div className="market-div-mobile">
                        <MarketsTable coinRow={coinRow} exchanges={exchanges} is_exchange={false} selectedTicker={selectedTicker} setSelectedTicker={setSelectedTicker}/>
                    </div>
                    <div className="market-div-mobile">
                        <MarketsTable coinRow={coinRow} exchanges={exchanges} is_exchange={true} selectedTicker={selectedTicker} setSelectedTicker={setSelectedTicker}/>
                    </div>
                </div>
                <div>
                    <div className={"gray-container"} style={{marginTop:"0px"}}>
                        {selectedTicker ? <TradingView ticker={selectedTicker.cis_native_symbol} showVolume={true} latest_price={selectedTicker.org_ticker && selectedTicker.org_ticker.lst}/> :
                            <div style={{color: "white", paddingTop: "2em", paddingBottom: "2em", margin: "auto", fontSize: "20px"}}>Select a pair for price graph.</div>}
                    </div>
                </div>
            </div>

            :

            // <div className={"col-lg-12"} style={{padding: "0px", height: "80vh"}}>
            //     <div className={"col-lg-6"} style={{padding: "0px 5px 0px 0px", height: "100%"}}>
            //         <div style={{height: "40vh", paddingBottom: "5px"}}>
            //             <MarketsTable coinRow={coinRow} exchanges={exchanges} is_exchange={false} selectedTicker={selectedTicker} setSelectedTicker={setSelectedTicker}/>
            //         </div>
            //         <div style={{height: "40vh", paddingTop: "5px"}}>
            //             <MarketsTable coinRow={coinRow} exchanges={exchanges} is_exchange={true} selectedTicker={selectedTicker} setSelectedTicker={setSelectedTicker}/>
            //         </div>
            //     </div>
            //     <div className={"col-lg-6"} style={{padding: "0px 0px 0px 5px", marginTop: "10px", height: "100%"}}>
            //         <div className={"gray-container"} style={{height: "100%", padding: "0px", display: "flex"}}>

            //         </div>
            //     </div>
            // </div>

            <React.Fragment>
                <section className="coin-table">
                    <div style={{paddingTop: "10px"}}>
                        <div className={"inline-tab-wrapper"}>
                            <ul className={"inline-tab"}>
                                <li><button className={menu === 'All Markets' ? "inline-tab-button-active" : "inline-tab-button"} onClick={e => {e.stopPropagation();  setMenu('All Markets')}}>All Markets</button></li>
                                {/*<li><button className={menu === 'By Pair' ? "inline-tab-button-active" : "inline-tab-button"} onClick={e => {e.stopPropagation();  setMenu('By Pair')}}>By Pair</button></li>*/}
                                {/*<li><button className={menu === 'By Exchange' ? "inline-tab-button-active" : "inline-tab-button"} onClick={e => {e.stopPropagation();  setMenu('By Exchange')}}>By Exchange</button></li>*/}
                                <li><button className={menu === 'Volume Analysis' ? "inline-tab-button-active" : "inline-tab-button"} onClick={e => {e.stopPropagation();  setMenu('Volume Analysis')}}>Volume Analysis</button></li>
                                <li><button className={menu === 'Chart View' ? "inline-tab-button-active" : "inline-tab-button"} onClick={e => {e.stopPropagation();  setMenu('Chart View')}}>Chart View</button></li>
                                <li><button className={menu === 'Arbitrage Grid' ? "inline-tab-button-active" : "inline-tab-button"} onClick={e => {e.stopPropagation();  setMenu('Arbitrage Grid')}}>Arbitrage Grid</button></li>
                            </ul>
                        </div>
                    </div>
                    {
                        menu === "All Markets" &&
                        <AllMarkets coinBaseAsset={coinRow.symbol} ccy={ccy} type={"all"}/>
                    }
                    {/*{*/}
                    {/*    menu === "By Pair" &&*/}
                    {/*    <AllMarkets coinRow={coinRow} ccy={ccy} type={"byPair"}/>*/}
                    {/*}*/}
                    {/*{*/}
                    {/*    menu === "By Exchange" &&*/}
                    {/*    <AllMarkets coinRow={coinRow} ccy={ccy} type={"byExchange"}/>*/}
                    {/*}*/}
                    {
                        menu === "Volume Analysis" &&
                        <VolumeAnalysis coinRow={coinRow} ccy={ccy}/>
                    }
                    {
                        menu === "Chart View" &&
                        <ChartViewMarket selectedTicker={selectedTicker} coinRow={coinRow} ccy={ccy}/>
                    }
                    {
                        menu === "Arbitrage Grid" &&
                        <ArbitrageGridMarket coinSymbol={coinRow.symbol}/>
                    }
                </section>
            </React.Fragment>

    );
}

// Prepares input props from state and local storage
const prepareInputProps = state => {

    // Convert exchanges to map of symbol to exchange data
    const exchangeMap = {};
    state.meta.exchanges.forEach(el => exchangeMap[el.code] = el);

    return {
        exchanges: exchangeMap
    };
};
export default connect(prepareInputProps)(withRouter(CoinMarkets));
