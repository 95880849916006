import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import Utils from "../../../helpers/Utils";

const isMobile = Utils.checkMobile();


class LeftMenu extends Component {
  render() {
    const {location} = this.props;
    const path = location.pathname;
    return (
      <section className="left-menu">
        <div className="lm-inner">
          <div className="logo">
            <img src="/img/logo.svg" alt="" />
          </div>
          <div className="lm-menu-items">
            <ul>
              <li className={`lm-menu-item ${path === '/' ? 'active' : ''}`}>
                <NavLink to="/" data-hover="HOMEPAGE">
                  <div className="menu-icon">
                    <img className="actionable-img" src={isMobile ? '/img/home-m.svg':'/img/home.svg'} alt="" />
                  </div>
                </NavLink>
              </li>
              <li className={`lm-menu-item ${path.includes('/coins') ? 'active' : ''}`}>
                <NavLink to="/coins" data-hover="COINS">
                  <div className="menu-icon">
                    <img className="actionable-img" src={isMobile ? '/img/coins2-m.svg':'/img/coins2.svg'} alt="" />
                  </div>
                </NavLink>
              </li>
              <li className={`lm-menu-item ${path.includes('/indices') ? 'active' : ''}`}>
                <NavLink to="/indices" data-hover="INDICES">
                  <div className="menu-icon">
                    <img className="actionable-img" src={isMobile ? '/img/indices-m.svg':'/img/indices.svg'} alt="" />
                  </div>
                </NavLink>
              </li>
              <li className={`lm-menu-item ${path.includes('/sectors') ? 'active' : ''}`}>
                <NavLink to="/sectors" data-hover="SECTORS">
                  <div className="menu-icon">
                    <img className="actionable-img" src={isMobile ? '/img/sectors-m.svg':'/img/sectors.svg'} alt="" />
                  </div>
                </NavLink>
              </li>
              <li className={`lm-menu-item ${path.includes('/news') ? 'active' : ''}`}>
                <NavLink to="/news" data-hover="NEWS">
                  <div className="menu-icon">
                    <img className="actionable-img" src={isMobile ? '/img/news-m.svg':'/img/news.svg'} alt="" />
                  </div>
                </NavLink>
              </li>
              <li className={`lm-menu-item ${path.includes('/trade') ? 'active' : ''}`}>
                <NavLink to="/trade" data-hover="TRADE">
                  <div className="menu-icon">
                    <img className="actionable-img" src={isMobile ? '/img/euro.svg':'/img/euro.svg'} alt="" />
                  </div>
                </NavLink>
              </li>
              <li className={`lm-menu-item ${path.includes('/alerts') ? 'active' : ''}`}>
                <NavLink to="/alerts" data-hover="ALERTS">
                  <div className="menu-icon">
                    <img className="actionable-img" src={isMobile ? '/img/alerts-m.svg':'/img/alerts.svg'} alt="" />
                  </div>
                </NavLink>
              </li>
              <li className={`lm-menu-item ${path.includes('/portfolio') ? 'active' : ''}`}>
                <NavLink to="/portfolio/balance" data-hover="PORTFOLIO">
                  <div className="menu-icon">
                    <img className="actionable-img" src={isMobile ? '/img/portfolio-m.svg':'/img/portfolio.svg'} alt="" />
                  </div>
                </NavLink>
              </li>
              {/*{!isMobile &&*/}
              {/*<li className={`lm-menu-item ${path.includes('/trade') ? 'active' : ''}`}>*/}
              {/*  <NavLink to="/trade" data-hover="TRADE">*/}
              {/*    <div className="menu-icon">*/}
              {/*      <Image className="actionable-img" src={'euro.svg'} alt="" />*/}
              {/*    </div>*/}
              {/*  </NavLink>*/}
              {/*</li>}*/}


              {/* <li className="lm-menu-item">
                                <a data-hover="INDICES">
                                    <div className="menu-icon"><img className="actionable-img" src="img/indices.svg" alt="" /></div>
                                </a>
                            </li>

                            <li className="lm-menu-item">
                                <a href="sectors.html" data-hover="SECTORS">
                                    <div className="menu-icon"><img className="actionable-img" src="img/sectors.svg" alt="" /></div>
                                </a>
                            </li>
                             */}

              {/* <li class="lm-menu-item">
                        <a href="profile.html" data-hover="PROFILE">
                            <div class="menu-icon"><img class="actionable-img" src="img/profile.svg" alt=""></div>
                        </a>
                    </li> */}

              {/* <li className="lm-menu-item">
                                <a href="alerts.html" data-hover="ALERTS">
                                    <div className="menu-icon"><img className="actionable-img" src="img/alerts.svg" alt="" /></div>
                                </a>
                            </li> BUUUU */}

              {/* <li class="lm-menu-item">
                        <a href="watch-list.html" data-hover="WATCHLISTS">
                            <div class="menu-icon"><img class="actionable-img" src="img/watchlist.svg" alt=""></div>
                        </a>
                    </li> */}
              {/* <li class="lm-menu-item">
                            <a href="#"><div class="menu-icon"><img class="actionable-img" src="img/portfolio.svg" alt=""></div></a>
                        </li> */}
            </ul>
          </div>
          {/* <div className="messages active">
            <div className="messages-inner active">
              <a className="messages-link" href="#" data-notf-number={11}>
                <Image src="message.svg" alt="" />
              </a>
              <div className="messages-menu-dropdown">
                <div className="mmd-inner">
                  <div className="mmd-header">
                    Messages{' '}
                    <a className="flRight" href="#">
                      See All
                    </a>
                  </div>
                  <ul className="active-scroll" id="beauty-scrollmess">
                    <li>
                      <a href="#">
                        <div className="notf-inner">
                          <div className="notf-text">
                            That's your <strong>notifications</strong>
                          </div>
                        </div>
                        <div className="notf-date">01.01.2019 09:00PM</div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    );
  }
}

export default withRouter(LeftMenu);
