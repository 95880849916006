import React from 'react';

import IndexOverview from './parts/IndexOverview';
import IndexConstituents from './parts/IndexConstitutents';
import MobileOverview from './parts/MobileOverview';
import MobileGainsLosers from './parts/MobileGainsLosers';
import MobileNews from './parts/MobileNews';
import {getAccessibleComponent, useAuth0} from "../../helpers/Auth0";
import {Route, Switch} from "react-router-dom";


const IndexMenuContent = props => {
  const {
    selectedExchange, // Indicates the selected exchange value
    indexCode, // Which we are currently in
  } = props;
  const {user} = useAuth0();

  return (
      <Switch>
        <Route path={"/indices/:coinSymbol/detail"} render={() => {
          const c1 = <IndexOverview exchange={selectedExchange} indexCode={indexCode}/>;
          return getAccessibleComponent(user, null, "index_summary", c1);
        }}/>
        <Route path={"/indices/:coinSymbol/index-constituents"} render={() => {
          const c2 = <IndexConstituents exchange={selectedExchange} indexCode={indexCode}/>;
          return getAccessibleComponent(user, null, "index_constituents", c2);
        }}/>
        <Route path={"/indices/:coinSymbol/m-detail"} render={() => {
          const c3 = <MobileOverview exchange={selectedExchange} indexCode={indexCode}/>;
          return getAccessibleComponent(user, null, "index_summary", c3);
        }}/>
        <Route path={"/indices/:coinSymbol/index-gainers-losers"} render={() => {
          const c4 = <MobileGainsLosers exchange={selectedExchange} indexCode={indexCode}/>;
          return getAccessibleComponent(user, null, "index_summary", c4);
        }}/>
        <Route path={"/indices/:coinSymbol/index-news"} render={() => {
          const c5 = <MobileNews exchange={selectedExchange} indexCode={indexCode}/>;
          return getAccessibleComponent(user, null, "index_summary", c5);
        }}/>
      </Switch>
  );
};

export default IndexMenuContent;
