import React, {useEffect, useState} from 'react';
import Loading from "../Loading";
import ReactTooltip from "react-tooltip";
import Paginate from "../Paginate";
import AppHelpers from "../../helpers/AppHelpers";
import Select from "../Forms/Select";
import CISNavigator from "../../helpers/CISNavigator";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";



const SectorCoinTable = props => {

    const {datas,history} = props;
    const [pageInfo, setPageInfo] = useState({page: 1, totalPage: datas.length / 50});
    const [sort,setSort] = useState({i:"avg_market_cap_30d",s:"desc"});
    const [coins,setCoins]= useState({items:[],loading:true});
    const [loading,setLoading] = useState(true);
    const [currentPage,setCurrentPage] = useState(0);
    const [pageSize,setPageSize] = useState(50);

    const pages = [
        {value: 10, label: '10'},
        {value: 20, label: '20'},
        {value: 50, label: '50'},
    ];


    const perClassName = value => {
        const key = AppHelpers.isPositiveFloat(value);
        switch (key) {
            case 1:
                return 'table-green-text';
            case -1:
                return 'table-red-text';
            default:
                return 'table-gray-text';
        }
    };

    const perSvg = value => {
        const key = AppHelpers.isPositiveFloat(value);
        switch (key) {
            case 1:
                return '/img/up.svg';
            case -1:
                return '/img/down.svg';
            default:
                return null;
        }
    };

    const calculateChg = (value) =>{
        return  <td className={perClassName((value*100).toFixed(2))}>{perSvg((value*100).toFixed(2)) && <img height="15px" width="15px" className="actionable-img" src={perSvg((value*100).toFixed(2))} alt="" />}{" "}{(value*100).toFixed(2)}</td>
    }

    const coinSort = () =>{
        setCoins({items:[],loading:true})
        datas.sort(function(a,b){

            if(sort.s === 'asc'){
                return a[sort.i] - b[sort.i];
            }else{
                return b[sort.i] - a[sort.i];
            }

        });
        setCoins({items:datas,loading:false})
    }


    const pageChange = event =>{
        setCurrentPage(event.selected)
    }
    useEffect(()=>{
        setCurrentPage(0);
        setPageInfo({page: 1, totalPage: datas.length / pageSize})
        coinSort();

    },[datas,pageSize])

    useEffect(()=>{
        coinSort();
    },[sort.i,sort.s,coins.items]);

    const sortChange = index => (sort.s === 'desc' ? {i: index, s: 'asc'} : {i: index, s: 'desc'});

    const sortArrow = index => sort.i === index && <i style={{fontSize: 12,paddingLeft:"5px"}} className={`fa fa-angle-${sort.s === 'desc' ? 'up' : 'down'}`} />;

    return(
        <div className="table-body table-responsive" id="beauty-scroll7">

                <ReactTooltip html={true} />
                <div className="table-right" style={{paddingBottom:"30px"}}>
                    <div className="table-select-input">
                        <div className="sm-select-input small-select" style={{padding:"0px" ,width:"50px",float:"right"}}>
                            <Select selected={pageSize} options={pages} onChange={selected => setPageSize(selected.value)} />
                        </div>
                    </div>
                </div>
                <table className="sector-coins enumarable-table coins-menu-table linked-table">
                    <thead>
                    <tr>
                        <th colSpan={3} style={{border:"none"}}></th>
                        <th colSpan={8} style={{textAlign:"center" ,fontWeight:"bold"}}>Change %</th>
                        <th colSpan={4} style={{textAlign:"center",fontWeight:"bold"}}>30 day Avg / calcs</th>
                    </tr>
                    <tr>
                        <th>Coin</th>
                        <th>Mkt Cap Category</th>
                        <th onClick={()=> setSort(sortChange('close_price'))} style={{cursor:"pointer"}}>Close{sortArrow('close_price')}</th>
                        <th onClick={()=> setSort(sortChange('chgpct_7d'))} style={{cursor:"pointer"}}>7D{sortArrow('chgpct_7d')}</th>
                        <th onClick={()=> setSort(sortChange('chgpct_30d'))} style={{cursor:"pointer"}}>30D{sortArrow('chgpct_30d')}</th>
                        <th onClick={()=> setSort(sortChange('chgpct_3m'))} style={{cursor:"pointer"}}>3M{sortArrow('chgpct_3m')}</th>
                        <th onClick={()=> setSort(sortChange('chgpct_6m'))} style={{cursor:"pointer"}}>6M{sortArrow('chgpct_6m')}</th>
                        <th onClick={()=> setSort(sortChange('chgpct_ytd'))} style={{cursor:"pointer"}}>YTD{sortArrow('chgpct_ytd')}</th>
                        <th onClick={()=> setSort(sortChange('chgpct_1y'))} style={{cursor:"pointer"}}>1Y{sortArrow('chgpct_1y')}</th>
                        <th onClick={()=> setSort(sortChange('chgpct_2y'))} style={{cursor:"pointer"}}>2Y{sortArrow('chgpct_2y')}</th>
                        <th onClick={()=> setSort(sortChange('chgpct_3y'))} style={{cursor:"pointer"}}>3Y{sortArrow('chgpct_3y')}</th>
                        <th onClick={()=> setSort(sortChange('avg_market_cap_30d'))} style={{cursor:"pointer"}}>Avg Mkt Cap{sortArrow('avg_market_cap_30d')}</th>
                        <th onClick={()=> setSort(sortChange('avg_volume_to_30d'))} style={{cursor:"pointer"}}>Avg Vol To{sortArrow('avg_volume_to_30d')}</th>
                        <th onClick={()=> setSort(sortChange('volatility_30d'))} style={{cursor:"pointer"}}>Volatility{sortArrow('volatility_30d')}</th>
                        <th onClick={()=> setSort(sortChange('rsi_30d'))} style={{cursor:"pointer"}}>RSI{sortArrow('rsi_30d')}</th>
                    </tr>
                    </thead>
                    <Loading loading={coins.loading} isSector={true}>
                    <tbody>
                    {
                        coins.items.slice(currentPage*pageSize).slice(0,pageSize).map((coin,i)=>(
                                <tr key={i} onClick={() => CISNavigator.navigateToCoinDetail(history, coin)}>
                                    <td>{coin.symbol?coin.symbol:"-"}</td>
                                    <td>{coin.market_cap_category?coin.market_cap_category:"-"} </td>
                                    <td>{coin.close_price?AppHelpers.priceFormat(coin.close_price):"-"}</td>
                                    {coin.chgpct_7d?calculateChg(coin.chgpct_7d):<td>-</td>}
                                    {coin.chgpct_30d?calculateChg(coin.chgpct_30d):<td>-</td>}
                                    {coin.chgpct_3m?calculateChg(coin.chgpct_3m):<td>-</td>}
                                    {coin.chgpct_6m?calculateChg(coin.chgpct_6m):<td>-</td>}
                                    {coin.chgpct_ytd?calculateChg(coin.chgpct_ytd):<td>-</td>}
                                    {coin.chgpct_1y?calculateChg(coin.chgpct_1y):<td>-</td>}
                                    {coin.chgpct_2y?calculateChg(coin.chgpct_2y):<td>-</td>}
                                    {coin.chgpct_3y?calculateChg(coin.chgpct_3y):<td>-</td>}
                                    <td>{coin.avg_market_cap_30d?AppHelpers.numberFormat(coin.avg_market_cap_30d):"-"}</td>
                                    <td>{coin.avg_volume_to_30d?AppHelpers.numberFormat(coin.avg_volume_to_30d):"-"}</td>
                                    <td>{coin.volatility_30d?coin.volatility_30d.toFixed(2):"-"}</td>
                                    <td>{coin.rsi_30d?coin.rsi_30d.toFixed(2):"-"}</td>
                                </tr>
                        ))
                    }
                    </tbody>
                    </Loading>

                </table>
            {
                pageInfo.totalPage > 1 ?
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Paginate pageCount={pageInfo.totalPage} onPageChange={pageChange} active={pageInfo.page - 1} />
                    </div>
                    :
                    <div></div>
            }

        </div>
    );


};

const mapStateToProps = state => {
    return {
        meta: state.meta,
    };
};

export default connect(mapStateToProps)(withRouter(SectorCoinTable));

