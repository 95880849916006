import React, {useEffect, useRef, useState} from 'react';
import Api from "../../api";
import AppHelpers from "../../helpers/AppHelpers";
import {useAuth0} from "../../helpers/Auth0";
import CISNavigator from "../../helpers/CISNavigator";
import withRouter from "react-router-dom/es/withRouter";

const TopDominanceArea = props => {
    const {user} = useAuth0();
    const [totalMarketCap, setTotalMarketCap] = useState(0);
    const [dominances, setDominances] = useState([]);
    const {history} = props;



    useEffect(() => {
        const param = {ccy: user.preferences.currency};
        Api.getCoinDominance(param).then(resp => {
            setTotalMarketCap(resp.total_market_cap);
            if (resp.dominance) {

                const mapped = resp.dominance.map(el => {
                    const dominance = {}
                    resp.fields.forEach((dom, i) => {
                        dominance[dom] = el [i];
                    })
                    return dominance;
                })
                setDominances(mapped);
            } else {
                setDominances([]);
            }
        });
    }, [])


    return (
        <React.Fragment>
            <div className="gray-container" style={{marginBottom:"15px"}}>
                <div className="sb-numbers" style={{display:"flex"}}>
                    <div className="sb-number" style={{display:"flex",flexWrap:"wrap"}}>
                        <div className="sb-number-head">Total Market Cap:</div>
                        <div className={`sb-number-inner`} style={{marginLeft:"5px"}}>{AppHelpers.numberFormat(totalMarketCap)}</div>
                    </div>
                    <div className="sb-number" style={{marginLeft:"15px",display:"flex",flexWrap:"wrap"}}>
                        <div className="sb-number-head">Dominance:</div>
                        <div className={`sb-number-inner`} style={{marginLeft:"5px",display:"flex",flexWrap:"wrap"}}>
                            {
                                dominances.map((el,i)=>{
                                    return (
                                        <div key={i} style={i === 0 ? {display:"flex",marginLeft:"1px"}:{display:"flex",marginLeft:"10px"}}>
                                            <div style={{cursor:"pointer"}} onClick={() => CISNavigator.navigateToCoinDetail(history, el)}>
                                                {el.symbol}
                                            </div>
                                            {i === dominances.length-1 ?
                                                <div style={{color:"#337ab7",marginLeft:"5px",}}>
                                                    {(el.dominance * 100).toFixed(2) + " % "}
                                                </div>
                                                :
                                                <React.Fragment>
                                                    <div style={{color:"#337ab7",marginLeft:"5px",}}>
                                                        {(el.dominance * 100).toFixed(2) + " % "}
                                                    </div>
                                                    <div style={{marginLeft:"10px"}}>
                                                        |
                                                    </div>
                                                </React.Fragment>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="clearfix" />
                </div>

            </div>
        </React.Fragment>
    );
};

export default withRouter(TopDominanceArea);
