import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import ReactTooltip from "react-tooltip";
import TemplateConditionModal from "./TemplateConditionModal";
import {toastr} from "react-redux-toastr";
import closeImg from "../SetTemplateModal/close-white.svg"
import UserApi from "../../../api/UserApi";
import IndicatorsApi from "../../../api/IndicatorsApi";

const defaultAlert = () => {
    return (
        {
            indicator_requests: [{
                indicator_code: "SMA",
                indicator_ts: null,
                parameters: {period: 10}
            }],
        }
    )
}

const SetTemplateModal = props => {
    const {
        show,
        close,
        indicatorList,
        indicators,
        templates,
        setTemplates,
        type,
        selectedTemplate,
        setSelectedTemplate
    } = props;


    const [template, setTemplate] = useState({name: "", data: {}});

    // Template Conditions
    const [conditions, setConditions] = useState([...defaultAlert().indicator_requests]);

    useEffect(()=>{
        if(type === "Update"){
            setTemplate({name: selectedTemplate.label, data: {}});
            setConditions(selectedTemplate.value.indicator_requests);
        }else{
            setTemplate({name: "", data: {}});
            setConditions([...defaultAlert().indicator_requests]);
        }
    },[type])

    // Control conditions field
    const controlConditions = () => {
        let control = {flag: true};
        conditions.forEach(el => {
            if (!el.indicator_code || !el.parameters) {
                control = {flag: false};
            } else {
                Object.keys(el.parameters).forEach(key => {
                    if (el.parameters[key] === "") {
                        control = {flag: false};
                    }
                })

            }
        })
        return control;
    }

    const handleChangeName = text => {
        let newTemplate = {...template};
        newTemplate["name"] = text.target.value;
        setTemplate(newTemplate);
    };


    const handleAddCondition = () => {
        let newConditions = [...conditions];
        newConditions.push({...defaultAlert().indicator_requests[0]});
        setConditions(newConditions);
    }

    const handleDeleteCondition = (index) => {
        let newConditions = [...conditions];
        newConditions.splice(index, 1);
        setConditions(newConditions);
    }

    const handleCreate = () => {
        let newTemplate = {...template};
        const control = controlConditions();
        if (newTemplate.name === "") {
            toastr.error('', 'You must enter alert name.');
            return;

        } else {
            if (control.flag) {
                let param = {
                    name: newTemplate.name,
                    indicator_requests: conditions,
                }

                UserApi.createIndicatorTemplate(param).then(resp=>{
                    console.log(templates,"temp");
                    let newTemplates = [...templates];
                    newTemplates.push({label:newTemplate.name,value:resp});
                    setSelectedTemplate({label:newTemplate.name,value:resp});
                    setTemplates(newTemplates);
                    toastr.success('', 'Successfully created alert.');
                    close();
                }).catch(err =>
                    console.log("Something went wrong while creating template.")
                );


            } else {
                toastr.error('', 'You must fill all fields for conditions.');
            }
        }
    }

    const handleUpdate = () => {
        let newTemplate = {...template};
        const control = controlConditions();
        if (newTemplate.name === "") {
            toastr.error('', 'You must enter alert name.');
            return;

        } else {
            if (control.flag) {
                let param = {
                    name: newTemplate.name,
                    indicator_requests: conditions,
                }

                UserApi.updateIndicatorTemplate(selectedTemplate.value.id, param).then(responseTemp=>{
                    IndicatorsApi.getIndicatorsTemplates().then(resp=>{

                        let newTemplates = [];

                        newTemplates.push({label:"Default", value:resp.DEFAULT});

                        UserApi.getUserIndicatorTemplate().then(response=>{
                            response.forEach(el=>{
                                newTemplates.push({label:el.name, value:el});
                            })

                            setTemplates(newTemplates);

                            newTemplates.forEach(el=>{
                                if(el.value.id === selectedTemplate.value.id){
                                    setSelectedTemplate(el);
                                    return;
                                }
                            })
                            toastr.success('', 'Successfully updated alert.');
                            close();
                        })
                    })

                }).catch(err =>
                    console.log("Something went wrong while updating template.")
                );


            } else {
                toastr.error('', 'You must fill all fields for conditions.');
            }
        }
    }

    return (
        <div className={`general-modal ${show ? 'active' : ''}`} id="set-price-alert">
            <div className="vAlignWr">
                <div className="vAlign">
                    <div className="white-modal-inner set-alert">
                        <div className="modal-blue-header" style={{height: "60px"}}>
                            <div onClick={close} className="close-modal" data-target="set-price-alert">
                                <img src={closeImg} alt=""/>
                            </div>
                        </div>
                        <div className="modal-white-body">
                            <div className="gray-container alerts-price-alert"
                                 style={{
                                     display: "flex",
                                     flexDirection: "column",
                                     justifyContent: "space-between",
                                     marginTop: "0px",
                                     width: "1200px"
                                 }}>
                                <div className="row" style={{padding: "5px 0px"}}>
                                    <div className="col-lg-12" style={{padding: "0px 15px"}}>
                                        <h4 style={{
                                            color: "white",
                                            textAlign: "center",
                                            fontSize: "20px"
                                        }}>{type} Template</h4>
                                        <hr style={{borderTop: "1px solid #3c4145"}}/>
                                    </div>
                                    <div className="col-lg-12" style={{padding: "0px 15px"}}>
                                        <div style={{display: "flex", padding: "0px 10px"}}>
                                            <div className="filter-bar-input1 " style={{padding: "0px"}}>
                                                <TextField
                                                    size={"small"}
                                                    label="Template Name"
                                                    margin="normal"
                                                    variant="outlined"
                                                    onChange={handleChangeName}
                                                    value={template.name}
                                                    fullWidth
                                                />
                                            </div>
                                        </div>
                                        <hr style={{borderTop: "1px solid #3c4145"}}></hr>
                                    </div>
                                    <div className="col-lg-12"
                                         style={{padding: "0px 15px", display: "flex"}}>
                                        <h4 style={{
                                            color: "white",
                                            fontSize: "18px",
                                            padding: "0px 10px"
                                        }}>{"Indicators:"}</h4>

                                    </div>
                                    <div className="col-lg-12" style={{padding: "0px 15px", marginTop: "10px"}}>
                                        <hr style={{borderTop: "1px solid #3c4145", marginTop: "0px"}}></hr>
                                        {conditions.length > 0 && conditions.map((el, index) => {
                                            return (
                                                <TemplateConditionModal key={index} conditionsFields={conditions}
                                                                        setConditionsFields={setConditions}
                                                                        index={index}
                                                                        indicatorList={indicatorList}
                                                                        indicators={indicators}
                                                                        handleDeleteCondition={handleDeleteCondition}
                                                />
                                            )
                                        })}
                                    </div>
                                    <div className="col-lg-12"
                                         style={{padding: "0px 18px", marginTop: "10px", display: "flex"}}>
                                        <ReactTooltip html={true}/>
                                        <a style={{marginTop: "7px", marginLeft: "5px", cursor: "pointer"}}><img
                                            className="actionable-img"
                                            alt=""/>
                                            <i onClick={handleAddCondition} className="fas fa-plus-circle" style={{
                                                fontSize: "1.5em",
                                                color: "#7ac231"
                                            }} data-tip={'Add Condition'}/>
                                        </a>
                                    </div>

                                </div>
                                <div style={{justifyContent: "flex-end",marginTop:"100px"}}>
                                    <div className="pa-inner" style={{display:"flex",justifyContent:"space-between"}}>
                                        <div style={{display:"flex",justifyContent:"space-between",marginLeft: "10px"}}>

                                        </div>

                                        <div style={{display: "flex", justifyContent: "flex-end"}}>
                                            <div className="green-border-btn">
                                                <a onClick={close} style={{cursor: "pointer"}}>
                                                    Close
                                                </a>
                                            </div>
                                            {
                                                type === "Create" ?
                                                    <div style={{marginLeft: "5px"}} className="green-border-btn">
                                                        <a style={{cursor: "pointer"}} onClick={handleCreate}>
                                                            Create Template
                                                        </a>
                                                    </div>
                                                    :
                                                    <div style={{marginLeft: "5px"}} className="green-border-btn">
                                                        <a style={{cursor: "pointer"}} onClick={handleUpdate}>
                                                            Update Template
                                                        </a>
                                                    </div>
                                            }


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};


const prepareInputProps = state => {

    return {
        exchanges: state.meta.exchanges
    };
};

export default  connect(prepareInputProps)(SetTemplateModal);
