import React, {useEffect, useRef, useState} from "react";
import ExchangeApi from "../../api/ExchangeApi";
import {toastr} from 'react-redux-toastr';
import Loading from "../../components/Loading";
import moment from "moment";
import AppHelpers from "../../helpers/AppHelpers";
import Confirm from "../../components/Confirm/Confirm";

const OpenOrder = props => {
    const {
        userAccount, // Which account we show for open orders
        activatePeriodicChecks, // Indicates if periodic check is required (No need within balance since its already checked every minute)
        selectedBalance, // Selected spot details
        symbolMappings, // Symbol mappings of selected spot
        setOrderChanged // Triggered when an order changed
    } = props;

    // State variables
    const [loading, setLoading] = useState(!symbolMappings.loaded);
    const [orders, setOrders] = useState([]);
    const [deleteItem, setDeleteItem] = useState(false);

    // Periodic checks for open orders
    const realTimeRef = useRef(null);

    // Fetches open orders
    const fetchOrders = () => {
        // Fetch orders if we have symbol mappings
        if (!selectedBalance || (selectedBalance && !symbolMappings[userAccount.exchange])) {
            setLoading(!symbolMappings.loaded);
            return;
        }
        const symbol = symbolMappings[userAccount.exchange];

        setLoading(true);
        ExchangeApi.getOpenOrders(userAccount.key_id, symbol).then(res => {
            // Format orders
            const formatted = res.map(el => {
                el['record_date'] = moment(el.timestamp).format("DD.MM.YYYY HH:mm:ss");
                el['total'] = AppHelpers.priceFormat(el.amount * el.price);
                el['side_f'] = el.side.charAt(0).toUpperCase() + el.side.slice(1);
                return el;
            })

            setOrders(formatted);
            setLoading(false);
        }).catch( err => {
           console.error(err);
           setLoading(false);
        });
    }

    // Cancel order request
    const cancelOrder = (order) => {
        setLoading(true);
        ExchangeApi.cancelOrder(userAccount.key_id, order.id, order.symbol).then(res => {
            toastr.success('', "Order successfully canceled...");
            // Refresh orders by triggering order changed directive
            setOrderChanged("Cancel-" + new Date().getMilliseconds().toString());
            setLoading(false);
        }).catch(err => {
            toastr.error('', "Something went wrong...");
            console.error(err);
            setLoading(false);
        })
    }

    // Initialize periodic checks for balance
    const startPeriodicCheck = () => {
        // Clear timeout if already running
        if (realTimeRef.current) {
            clearInterval(realTimeRef.current);
        }

        // Create new periodic job
        realTimeRef.current = setInterval(() => {
            fetchOrders();
        }, 60 * 1000);
    }

    useEffect(() => {
        // Fetch open orders
        fetchOrders();

        // Start periodic check for balances
        if (activatePeriodicChecks) startPeriodicCheck();
    }, [selectedBalance, userAccount, symbolMappings]);

    useEffect(() => {
        if (activatePeriodicChecks) startPeriodicCheck();
    }, [activatePeriodicChecks]);

    // Clean async jobs
    useEffect(() => {
        return () => {
            if (realTimeRef.current) {
                clearInterval(realTimeRef.current);
            }
        }
    }, []);

    return (
         <Loading loading={loading}>
             {orders.length > 0 ?
                 <div className="table-body table-responsive" id="beauty-scroll7" tabIndex={1} style={{overflow: 'hidden', outline: 'none', paddingLeft: "1px", paddingRight: "1px", marginTop: "0px"}}>
                     <table className="coins enumarable-table news-table-responsive linked-table">
                         <thead>
                             <tr>
                                <th style={{padding: "7px"}}>Time</th>
                                <th style={{padding: "7px"}}>Side</th>
                                <th style={{padding: "7px"}}>Price ({selectedBalance.quoted_asset})</th>
                                <th style={{padding: "7px"}}>Amount ({selectedBalance.base_asset})</th>
                                <th style={{padding: "7px"}}>Filled</th>
                                <th style={{padding: "7px"}}>Total ({selectedBalance.quoted_asset})</th>
                                <th/>
                             </tr>
                         </thead>
                         <tbody>
                             {orders.map(el =>
                                 <tr key={el.id}>
                                     <td style={{textAlign: 'left'}}>
                                         <div className="text">{el.record_date}</div>
                                     </td>
                                     <td style={{textAlign: 'left'}} className={el.side_f === 'Buy' ? 'table-green-text' : 'table-red-text'}>
                                         <div className="text">{el.side_f}</div>
                                     </td>
                                     <td style={{textAlign: 'left'}}>
                                         <div className="text">{el.price}</div>
                                     </td>
                                     <td style={{textAlign: 'left'}}>
                                         <div className="text">{el.amount}</div>
                                     </td>
                                     <td style={{textAlign: 'left'}}>
                                         <div className="text">{el.filled}</div>
                                     </td>
                                     <td style={{textAlign: 'left'}}>
                                         <div className="text">{el.total}</div>
                                     </td>
                                     <td>
                                         <button onClick={() => setDeleteItem(el)} style={{background: "transparent", border: "0px"}} className="open-modal-event">
                                             <img className="actionable-img" src="/img/delete.svg" alt="" />
                                         </button>
                                     </td>
                                 </tr>
                             )}
                         </tbody>
                     </table>
                     <Confirm message={'Are you sure to cancel your order?'} show={deleteItem} ok={() => {cancelOrder(deleteItem); setDeleteItem(false)}} cancel={() => setDeleteItem(false)} />
                 </div> :
                 ( symbolMappings[userAccount.exchange] ?
                     <p style={{margin: "1em", color: "white"}}>No open orders for {selectedBalance.base_asset} / {selectedBalance.quoted_asset} on account { '"' + userAccount.key_name + '"'}</p>:
                     <p style={{margin: "1em", color: "white"}}>Instrument {selectedBalance.base_asset} / {selectedBalance.quoted_asset} is not available on exchage { '"' + selectedBalance.exchange_name + '"'}</p>
                 )
             }
         </Loading>
    );
}

export default (OpenOrder);
