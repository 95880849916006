import {connect} from "react-redux";
import React from "react";
import Balance from "./Balance";
import withRouter from "react-router-dom/es/withRouter";
import {useAuth0} from "../../helpers/Auth0";

const AccountSummary = props => {
    const {
        history, // Used for navigation
        selectedSpot, // Selected spot for the view
        userAccounts, // List of connected user accounts
        symbolMappings, // Symbol mappings of selected spot
        orderChanged, // Order changed fetch selected account balance again
        setOrderChanged, // Trigger order fetching
        exchanges, // Exchange metadata
        selectedAccount, // Selected exchange account,
        setSelectedBalance // Used for setting balance information on selected account
    } = props;

    const {configured2fa, sessionHas2FA, stepUpAuthorize} = useAuth0();

    // Return what should we write If there is no accounts/credentials
    const getInformationP = () => {
        if (sessionHas2FA) {
            return<p style={{marginTop: "10px", color: "white"}}>No accounts to show. <a style={{color: "white", cursor: "pointer", textDecoration: "underline"}} onClick={() =>
                history.push({
                    pathname: '/exchanges',
                    state: {exchange: selectedSpot.exchange_id}
                })
            }>Connect Now</a></p>
        } else if (configured2fa) {
            return <p style={{marginTop: "10px", color: "white"}}>You need to authenticate your current session with 2FA to access this functionality. <a style={{color: "white", cursor: "pointer", textDecoration: "underline"}} onClick={() =>
                stepUpAuthorize()}>Login with 2FA</a></p>
        } else {
            return <p style={{marginTop: "10px", color: "white"}}>You need to authenticate your current session with second factor. But it seems you did not configure a second factor for your account. To configure a second factor for your account please click <a style={{color: "white", cursor: "pointer", textDecoration: "underline"}} onClick={() =>
                stepUpAuthorize()}>Configure 2FA</a> to continue...</p>
        }
    }

    return (
        <div className="gray-container" style={{display: "flex", flexDirection: "column", marginTop: "10px", height: "100%"}}>
            <div className="section-header">Account Summary</div>
            {userAccounts.length === 0 && getInformationP()}
            {userAccounts.length > 0 &&
                <div className="table-body table-responsive" id="beauty-scroll7" tabIndex={1} style={{overflow: 'hidden', outline: 'none', paddingLeft: "1px", paddingRight: "1px"}}>
                    <table className="coins enumarable-table news-table-responsive linked-table">
                        <thead>
                            <tr>
                                <th colSpan={2}/>
                                <th colSpan={3} style={{width: "calc(100%/3)", border: "thin solid #33393f", padding: "7px", textAlign: "center"}}>{selectedSpot.base_asset}</th>
                                <th colSpan={3} style={{width: "calc(100%/3)", border: "thin solid #33393f", padding: "7px", textAlign: "center"}}>{selectedSpot.quoted_asset}</th>
                                <th/>
                            </tr>
                            <tr>
                                <th style={{padding: "7px"}}>Account</th>
                                <th style={{padding: "7px"}}>Exchange</th>
                                <th style={{width: "calc(100%/9)", padding: "7px", textAlign: "center", borderLeft: "thin solid #33393f"}}>Total</th>
                                <th style={{width: "calc(100%/9)", padding: "7px", textAlign: "center"}}>Locked</th>
                                <th style={{width: "calc(100%/9)", padding: "7px", textAlign: "center"}}>Free</th>
                                <th style={{width: "calc(100%/9)", padding: "7px", textAlign: "center", borderLeft: "thin solid #33393f"}}>Total</th>
                                <th style={{width: "calc(100%/9)", padding: "7px", textAlign: "center"}}>Locked</th>
                                <th style={{width: "calc(100%/9)", padding: "7px", textAlign: "center"}}>Free</th>
                                <th style={{padding: "7px", textAlign: "end", borderLeft: "thin solid #33393f"}}>Last Updated</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userAccounts.map(el =>
                                <Balance key={el.key_id} userAccount={el} selectedAccount={selectedAccount} selectedSpot={selectedSpot} orderChanged={orderChanged}
                                         setOrderChanged={setOrderChanged} setSelectedBalance={setSelectedBalance} exchanges={exchanges} symbolMappings={symbolMappings} />
                            )}
                        </tbody>
                    </table>
                </div>}
        </div>
    )
}

const prepareProps = state => {
    // Convert exchanges to map of symbol to exchange data
    const exchangeMap = {};
    state.meta.connectable_exchanges.forEach(el => exchangeMap[el.id] = el);

    return {
        exchanges: exchangeMap
    };
}

export default connect(prepareProps)(withRouter(AccountSummary));
