import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {connect} from 'react-redux';
import Breadcrump from '../../components/Breadcrump';
import {withRouter} from 'react-router-dom';
import {useAuth0, checkQuotaAccess} from "../../helpers/Auth0";
import Breadcrumps from "../../constants/Breadcrumps";
import Watchlist from "../../components/PageDashboard/Watchlist/Watchlist";
import CryptoAlert from "./Parts/CryptoAlert";
import AlertApi from "../../api/AlertApi";
import NewsAlert from "./Parts/NewsAlert";


const Alerts = props => {
    const {user} = useAuth0();
    const [watchListShow, setWatchListShow] = useState(user.preferences.show_watchlist);
    const [data, setData] = useState({items: [], loading: true});
    const [dataNews,setDataNews] = useState({items: [], loading: true});
    const {exchanges} = props;
    const location = useLocation();


    useEffect(() => {
        fetchData();
        fetchDataNews();
    }, []);

    const fetchData = () => {
        setData(d => ({...d, loading: true}));
        AlertApi.getCryptoAlert().then(resp => {
            resp= resp.map(el=>{
                el["show"] = false;
                return el;
            });
            setData({items: resp, loading: false});
        })
    };

    const fetchDataNews = () => {
        setDataNews(d => ({...d, loading: true}));
        AlertApi.getNewsAlert().then(resp => {
            resp= resp.map(el=>{
                el["show"] = false;
                return el;
            });
            setDataNews({items: resp, loading: false});
        })
    };

    const getExchangeName = (code) => {
        let marketLabel = "";
        if(code === "CISCALC"){
            marketLabel = "Crypto Index Series"
        }else{
            exchanges.forEach(exel => {
                if (code === exel.code) {
                    marketLabel = exel.name;
                    return;
                }
            });
        }

        return marketLabel;
    }


    return (
        <React.Fragment>
            <section className="dashboard padding-sm-container">
                <div className="watchlist-all-page">
                    {watchListShow ? <Watchlist/> : <></>}
                </div>
                <Breadcrump links={Breadcrumps.alerts}/>
                <div className="flex-row">
                    <div className="flex-width-left" style={{width: "50%"}}>
                        <CryptoAlert
                            loading={data.loading}
                            data={data}
                            setData={setData}
                            fetchData={fetchData}
                            getExchangeName={getExchangeName}
                        />
                    </div>
                    <div className="flex-width-right" style={{width: "50%"}}>
                        <NewsAlert
                            loading={dataNews.loading}
                            data={dataNews}
                            setData={setDataNews}
                            fetchData={fetchDataNews}
                            getExchangeName={getExchangeName}
                        />
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};


const prepareInputProps = state => {
    return {
        exchanges: state.meta.exchanges
    };
};

export default connect(prepareInputProps)(withRouter(Alerts));
