import React from 'react';
import {Link} from 'react-router-dom';
import withRouter from "react-router-dom/es/withRouter";


const Breadcrump = props => {
    const {history, links} = props;

    const renderLinks = () => {
        return links && links.map((row, i) => {
            return (
                <li key={i}>
                    <Link to={row.path}>{row.name}</Link>
                </li>
            );
        })
    }

    return (
        <div className="page-bar">
            <ul>
                <li className="back-btn">
                    <a onClick={() => history.goBack()} style={{cursor: "pointer"}}>
                        <img src="/img/left-arrow.svg" alt=""/>
                    </a>
                </li>
                {renderLinks()}
            </ul>
        </div>
    );
}

export default withRouter(Breadcrump);
