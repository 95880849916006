import React, {forwardRef, useEffect, useState} from 'react';
import Paginate from "../../components/Paginate";
import Loading from "../../components/Loading";
import Select from "../../components/Forms/Select";
import PortfolioApi from "../../api/PortfolioApi";
import moment from 'moment';
import AppHelpers from "../../helpers/AppHelpers";
import {MultiSelect} from "primereact/multiselect";
import DatePicker from "react-datepicker";


const pages = [
    {value: 10, label: '10'},
    {value: 20, label: '20'},
    {value: 50, label: '50'},
];

const defaultFilterParam = {
    portfolio_id: "",
    page_size: 10,
    page_number: 1,
    order_by_field: "transfer_ts",
    order_by_direction: "desc",
    start: "",
    end: "",
    assets: ""

};

const TransferHistoryTable = (props) => {
    const {
        myPortfolio,
        portfolioAccounts,
        setShowA,
        showA,
        setSelectedTransfer,
        setShowAD,
        showAD,
        setShowP,
        setShowITS, // Set show import transfer modal
        setShowETS // Set show export transfer modal
    } = props;
    const [pageInfo, setPageInfo] = useState({page: 1, totalPage: 0});
    const [pageSize, setPageSize] = useState(10);
    const [filter, setFilter] = useState(defaultFilterParam);
    const [transferHistory, setTransferHistory] = useState({data: [], loading: true});
    const [sort, setSort] = useState({f: 'transfer_ts', d: 'desc'});
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const [dAccounts, setDAccounts] = useState([]);
    const [manualAccounts, setManualAccounts] = useState({});
    // Filter Show
    const [filterShow,setFilterShow] = useState(false);

    useEffect(() => {
        if (!showA && !showAD) {
            let f = filter;
            let manualMapped = {};
            f.portfolio_id = myPortfolio.id;
            f.page_size = pageSize;
            f.account_ids = selectedAccounts.join().toString() || "";
            f.start = f.start !== "" ? moment.utc(f.start).unix() * 1000 : "";
            f.end = f.end !== "" ? moment.utc(f.end).unix() * 1000 : "";

            fetchData(f);
            setFilter(f);

            if (portfolioAccounts && portfolioAccounts.data.length > 0) {
                let mappedAccounts = portfolioAccounts.data.map(el => {
                    if (el.type === "manual" && !manualMapped[el.id]) {
                        manualMapped[el.id] = el;
                    }
                    return ({value: el.id, name: el.name});
                });
                setManualAccounts(manualMapped);
                setDAccounts(mappedAccounts);
            }
        }
    }, [myPortfolio, pageSize, sort.f, sort.d, portfolioAccounts, selectedAccounts, showA, showAD, filter]);


    const handlePageClick = d => {
        let f = filter;
        if (pageInfo.page !== d.selected + 1) {
            f.page_number = d.selected + 1;
            fetchData(f, d.selected + 1);
        }
    };
    const fetchData = (f, pageNumber) => {
        f.order_by_direction = sort.d;
        f.order_by_field = sort.f;
        PortfolioApi.getTransferHistory(f).then(resp => {
            setPageInfo({
                ...pageInfo,
                page: pageNumber || pageInfo.page,
                totalPage: Math.round((resp.total_count / pageSize) + 0.5)
            });
            setTransferHistory({data: resp.mapped, loading: false})
        })
    };

    const onUpdate = item => {
        setSelectedTransfer(item);
        setShowA(true);
    };

    const onDelete = item => {
        setSelectedTransfer(item);
        setShowAD(true);
    };

    const sortChange = index => (sort.d === 'desc' ? {f: index, d: 'asc'} : {f: index, d: 'desc'});

    const sortArrow = index => sort.f === index &&
        <i style={{fontSize: 12}} className={`fa fa-angle-${sort.d === 'desc' ? 'up' : 'down'}`}/>;

    const ref = React.createRef();
    const ref1 = React.createRef();
    const StartDateInput = forwardRef(({value, onClick}, ref) => (
        <button className={'sm-select-input news'} style={{width: "200px",height: "38px",textAlign: "center",background: "#141619"}} onClick={onClick} ref={ref}>
            {value || "Start"}
        </button>
    ))
    const EndDateInput = forwardRef(({value, onClick}, ref) => (
        <button className={'sm-select-input news'} style={{width: "200px",height: "38px",textAlign: "center",background: "#141619"}}  onClick={onClick} ref={ref}>
            {value || "End"}
        </button>
    ))

    return (
        <div>
            <div className="table-head">
                <div className="table-left">

                </div>
                <div className="table-right">
                    <div style={{marginTop: "5px"}}>
                        <div style={{width: 110, marginLeft: "10px"}} className="blue-border-btn flRight">
                            <a style={{cursor: "pointer"}} onClick={() => {
                                setShowETS(true);
                            }}>Export Transfer</a>
                        </div>
                        <div style={{width: 110, marginLeft: "10px"}} className="blue-border-btn flRight">
                            <a style={{cursor: "pointer"}} onClick={() => {
                                setShowITS(true);
                            }}>Import Transfer</a>
                        </div>
                        <div style={{width: 110, marginLeft: "10px"}} className="blue-border-btn flRight">
                            <a style={{cursor: "pointer"}} onClick={() => {
                                setShowA(true);
                            }}>Add Transfer</a>
                        </div>
                        <div style={{width: 110, marginLeft: "10px"}} className="blue-border-btn flRight">
                            <a style={{cursor: "pointer"}} onClick={() => {
                                setFilterShow(!filterShow);
                            }}>Filter</a>
                        </div>
                        {
                            filterShow &&
                            <div style={{width: 110}} className="blue-border-btn flRight">
                                <a style={{cursor: "pointer"}} onClick={() => {
                                    setFilter(defaultFilterParam);
                                }}>Reset Filter</a>
                            </div>
                        }
                    </div>
                    <div className="table-select-input" style={{marginLeft: "10px", marginTop: "5px"}}>
                        <div className="sm-select-input small-select">
                            <Select selected={pageSize} options={pages}
                                    onChange={selected => setPageSize(selected.value)}/>
                        </div>
                    </div>
                </div>
                <div className="clearfix"/>
            </div>
            {
                filterShow &&
                <div style={{marginTop:"10px",border: "1px solid black",padding:"10px",marginRight:"-25px",marginLeft:"-25px"}}>
                    <div className="filter-bar" style={{display: "flex",flexWrap:"wrap"}}>
                        <div  style={{display:"flex",marginTop:"10px"}}>
                            <div className="input-title" style={{paddingTop: "10px"}}>Accounts:</div>
                            <div className="filter-bar-input create-flex" style={{marginLeft: "10px"}}>
                                <div className="sm-select-input multiselect-regular">
                                    <div className="card">
                                        <MultiSelect maxSelectedLabels={1} placeholder="Select Accounts" optionLabel="name"
                                                     value={selectedAccounts} options={dAccounts}
                                                     onChange={(e) => setSelectedAccounts(e.value)} filter/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div  style={{display:"flex",marginTop:"10px"}}>
                            <div className="input-title" style={{paddingTop: "10px",marginLeft:"10px"}}>Start Date:</div>
                            <div className={"sm-select-input"} style={{width:"auto",marginLeft:"4px"}}>
                                <DatePicker
                                    selected={filter.start}
                                    onChange={date => {
                                        let newFilter = {...filter};
                                        newFilter.start = date;
                                        setFilter(newFilter);
                                    }}
                                    selectsStart
                                    customInput={<StartDateInput ref={ref}/>}
                                    timeInputLabel="Time:"
                                    dateFormat="MM/dd/yyyy"
                                    showTimeInput
                                    startDate={filter.start}
                                    endDate={filter.end}
                                />
                            </div>
                        </div>
                        <div style={{display:"flex",marginTop:"10px"}}>
                            <div className="input-title" style={{paddingTop: "10px",marginLeft:"10px"}}>End Date:</div>
                            <div className={"sm-select-input"} style={{width:"auto",marginLeft:"4px"}}>
                                <DatePicker
                                    selected={filter.end}
                                    onChange={date => {
                                        let newFilter = {...filter};
                                        newFilter.end = date;
                                        setFilter(newFilter);
                                    }}
                                    selectsEnd
                                    customInput={<EndDateInput ref={ref1}/>}
                                    timeInputLabel="Time:"
                                    dateFormat="MM/dd/yyyy"
                                    showTimeInput
                                    startDate={filter.end}
                                    endDate={filter.end}
                                    minDate={filter.start}
                                />
                            </div>
                        </div>
                        <div style={{display:"flex",marginTop:"10px"}}>
                            <div className="input-title" style={{paddingTop: "10px",marginLeft:"10px"}}>Asset:</div>
                            <div className="set-news-alert" style={{marginLeft:"10px"}}>
                                <div className="bottom-border-input bottom-border-inputNew">
                                    <input type="text" placeholder="Base" style={{height: "38px", width: "200px",borderRadius:"5px",background:"rgb(20 22 25)"}}
                                           value={filter.assets}
                                           onChange={el => {
                                               let newFilter = {...filter};
                                               let text = el.target.value;
                                               newFilter.assets = el.target.value.toUpperCase();
                                               setFilter(newFilter);
                                           }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {selectedAccounts.length > 0 &&
                        <div style={{display: "flex", paddingTop: "10px", flexWrap: "wrap"}}>
                            <div style={{fontSize: "14px", color: "#b2b2b2", paddingRight: "5px",marginRight:"12px"}}>
                                Accounts:
                            </div>
                            {selectedAccounts && selectedAccounts.map((el, i) => {
                                let html;
                                dAccounts.forEach(b => {
                                    if (b.value === el) {
                                        html = (
                                            <div key={i} style={{
                                                border: "1px solid #b2b2b2",
                                                borderRadius: "5px",
                                                paddingRight: "5px",
                                                marginRight: "5px",
                                                marginBottom: "4px"
                                            }}>
                                                <div style={{
                                                    padding: "2px",
                                                    fontSize: "14px",
                                                    color: "#b2b2b2",
                                                    display: "flex"
                                                }}>
                                                    <p style={{margin: "0 0"}}>{b.name}</p><i
                                                    style={{marginTop: "3px", cursor: "pointer"}}
                                                    onClick={() => {
                                                        let selected = [...selectedAccounts];
                                                        selected.splice(selectedAccounts.indexOf(el), 1);
                                                        setSelectedAccounts(selected);
                                                    }
                                                    } className="fas fa-times"/>
                                                </div>
                                            </div>)
                                    }
                                })
                                return html;
                            })
                            }
                        </div>
                        }
                    </div>
                </div>
            }
            <div className="table-body table-responsive" id="beauty-scroll7" style={{marginTop: "15px"}}>
                <Loading loading={transferHistory.loading}>
                    <table className="coins enumarable-table coins-menu-table linked-table">
                        <thead>
                        <tr>
                            <th onClick={() => setSort(sortChange('transfer_ts'))}>Time {sortArrow('transfer_ts')}</th>
                            <th onClick={() => setSort(sortChange('account_name'))}>Account
                                Name {sortArrow('account_name')}</th>
                            <th>Type</th>
                            <th>CIS Asset Symbol</th>
                            <th onClick={() => setSort(sortChange('asset_amount'))}>Asset
                                Amount {sortArrow('asset_amount')}</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {transferHistory.data && transferHistory.data.length > 0 ? transferHistory.data.map((el, i) => {

                                let newAmount = "-";
                                let newType = "-";
                                if (el.type) {
                                    newType = el.type[0] + el.type.toLowerCase().slice(1);
                                }
                                if (el.asset_amount) {
                                    newAmount = AppHelpers.priceFormat(el.asset_amount) + " " + (el.cis_asset_symbol || "");
                                }

                                return (
                                    <tr key={i}>
                                        <td>{moment(el.transfer_ts).format('DD.MM.YYYY HH:mm:ss') || "-"}</td>
                                        <td>{el.account_name || "-"}</td>
                                        <td style={el.type === "DEPOSIT" ? {color: "#7ac231"} : {color: "#c11e0f"}}>{newType}</td>
                                        <td>{el.cis_asset_symbol || el.source_native_asset_symbol}</td>
                                        <td>{newAmount}</td>
                                        {
                                            manualAccounts[el.account_id] ?
                                                <td className="edit-delete-btn"
                                                    style={{width: "120px", cursor: "pointer", padding: "10px"}}>
                                                    <button onClick={() => onUpdate(el)} className=" open-modal-event"
                                                            style={{marginRight: "15px"}}>
                                                        <img className="actionable-img" src="/img/edit.svg" alt=""/>
                                                    </button>
                                                    <button onClick={() => onDelete(el)} className=" open-modal-event">
                                                        <img className="actionable-img" src="/img/delete.svg" alt=""/>
                                                    </button>
                                                </td>
                                                :
                                                <td>

                                                </td>
                                        }
                                    </tr>
                                );
                            }) :
                            <tr>
                                <td>There is no connected account or transfer in your portfolio.</td>
                            </tr>}
                        </tbody>
                        {/*<IndicesTable data={result.items} setWatchListModal={setWatchListModal} selectedExchange={selectedExchange} />*/}
                    </table>
                </Loading>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Paginate pageCount={pageInfo.totalPage} onPageChange={handlePageClick} active={pageInfo.page - 1}/>
                </div>
            </div>
        </div>
    );
};


export default TransferHistoryTable;





