import React, {Component, useEffect, useState} from 'react';
import {connect} from 'react-redux';



export class ChatComponent extends Component {

    componentDidMount() {
        const script = document.createElement('script');
        script.src = 'https://js.hs-scripts.com/7183506.js';
        document.body.appendChild(script);
    }

    render() {
        return (
            <div id={"chat"}>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        state,
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ChatComponent);
