import React from "react";
import SectorOverview from "./SectorOverview";
import SectorNews from "./SectorNews";
import SectorAnalysis from "./SectorAnalysis";
import SectorCoins from "./SectorCoins";
import SectorReports from "./SectorReports";
import OverviewMobile from "./OverviewMobile";
import News from "./News";
import Gainers from "./Gainers";
import {getAccessibleComponent, useAuth0} from "../../../helpers/Auth0";
import {Route, Switch} from "react-router-dom";

const SectorMenuContent = props => {
    const {
        rawData, // Raw data of the sector
        selectedExchange // Selected exchange rate
    } = props;
    const {user} = useAuth0();

    return (
        <Switch>
            <Route path={"/sectors/:secSymbol/detail"} render={() => {
                const c1 = <SectorOverview rawData={rawData} exchange={selectedExchange}/>;
                return getAccessibleComponent(user, null, "sector_overview", c1);
            }}/>
            <Route path={"/sectors/:secSymbol/sector-coins"} render={() => {
                const c2 = <SectorCoins rawData={rawData} exchange={selectedExchange}/>;
                return getAccessibleComponent(user, null, "sector_overview", c2);
            }}/>
            <Route path={"/sectors/:secSymbol/sector-news"} render={() => {
                const c3 = <SectorNews rawData={rawData} exchange={selectedExchange}/>;
                return getAccessibleComponent(user, null, "sector_news", c3);
            }}/>
            <Route path={"/sectors/:secSymbol/sector-analysis"} render={() => {
                const c4 = <SectorAnalysis rawData={rawData} exchange={selectedExchange}/>;
                return getAccessibleComponent(user, null, "sector_overview", c4);
            }}/>
            <Route path={"/sectors/:secSymbol/sector-reports"} render={() => {
                const c5 = <SectorReports rawData={rawData} exchange={selectedExchange}/>;
                return getAccessibleComponent(user, null, "sector_reports", c5);
            }}/>
            <Route path={"/sectors/:secSymbol/m-detail"} render={() => {
                const c6 = <OverviewMobile rawData={rawData} exchange={selectedExchange}/>;
                return getAccessibleComponent(user, null, "sector_overview", c6);
            }}/>
            <Route path={"/sectors/:secSymbol/m-sector-news"} render={() => {
                const c7 = <News rawData={rawData} exchange={selectedExchange}/>;
                return getAccessibleComponent(user, null, "sector_news", c7);
            }}/>
            <Route path={"/sectors/:secSymbol/gainers-losers"} render={() => {
                const c8 = <Gainers rawData={rawData} exchange={selectedExchange}/>;
                return getAccessibleComponent(user, null, "sector_overview", c8);
            }}/>
        </Switch>
    );
};

export default SectorMenuContent;
