import React from 'react';
import {Provider} from 'react-redux';
import I18n from 'redux-i18n';
import {PersistGate} from 'redux-persist/lib/integration/react';
import ReduxToastr from 'react-redux-toastr';
import Launcher from './components/Launcher';
import {persist, store} from './store';
import AppRoutes from './pages/AppRoutes';
import {BrowserRouter} from 'react-router-dom';
import history from "./helpers/HistoryAccess"

const App = () => {
  return (
    <Provider store={store}>
      <I18n translations={{}} useReducer initialLang="en">
        <PersistGate loading={<Launcher />} persistor={persist}>
          <BrowserRouter history={history}>
            {/* <ThemeProvider
              theme={createMuiTheme({"
                palette: {
                  type: 'light',
                },
              })}> */}
              <AppRoutes />
            {/* </ThemeProvider> */}
            <ReduxToastr
              timeOut={3000}
              newestOnTop={false}
              preventDuplicates
              position="top-right"
              transitionIn="fadeIn"
              transitionOut="fadeOut"
              progressBar
              closeOnToastrClick
            />
          </BrowserRouter>
        </PersistGate>
      </I18n>
    </Provider>
  );
};

export default App;
