import Links from "../../constants/Links";
import Api from "../index";


// Path definitions
const ALERT_CRYPTO = "/alert/crypto";
const ALERT_NEWS = "/alert/news"


const AlertApi = {
    getPromise(endpoint, params) {
        return Api.getPromise(endpoint, params, Links.BASE_USER_URL)
    },
    getMPromise(endpoint, params) {
        return Api.getMPPromise(endpoint, params, Links.BASE_USER_URL)
    },
    postPromise(endpoint, body) {
        return Api.postPromise(endpoint, body, Links.BASE_USER_URL)
    },
    postMPPromise(endpoint, body) {
        return Api.postMPPromise(endpoint, body, Links.BASE_USER_URL)
    },
    putPromise(endpoint, body) {
        return Api.putPromise(endpoint, body, Links.BASE_USER_URL)
    },
    deletePromise(endpoint, params) {
        return Api.deletePromise(endpoint, params, Links.BASE_USER_URL)
    },
    /**
     * Read crypto alert.
     */
    getCryptoAlert() {
        return this.getPromise(ALERT_CRYPTO);
    },
    /**
     * Read news alert.
     */
    getNewsAlert() {
        return this.getPromise(ALERT_NEWS);
    },
    /**
     * Create crypto alert.
     * @param include alert info
     */
    createCryptoAlert(param) {
        return this.postPromise(ALERT_CRYPTO, param);
    },
    /**
     * Create crypto alert.
     * @param include alert info
     */
    createNewsAlert(param) {
        return this.postPromise(ALERT_NEWS, param);
    },
    /**
     * Update crypto alert.
     * @param include alert info
     */
    updateCryptoAlert(id,param) {
        return this.putPromise(ALERT_CRYPTO+"/"+id, param);
    },
    /**
     * Update crypto alert.
     * @param include alert info
     */
    updateNewsAlert(id,param) {
        return this.putPromise(ALERT_NEWS+"/"+id, param);
    },
    /**
     * Delete crypto alert.
     * @id alert id
     */
    deleteCryptoAlert(id) {
        return this.deletePromise(ALERT_CRYPTO+"/"+id);
    },
    /**
     * Delete crypto alert.
     * @id alert id
     */
    deleteNewsAlert(id) {
        return this.deletePromise(ALERT_NEWS+"/"+id);
    },


}

export default AlertApi;

