import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import {toastr} from "react-redux-toastr";
import PortfolioApi from "../../api/PortfolioApi";
import Loading from "../../components/Loading";
import ExchangeConfirmation from "../Exchanges/ExchangeConfirmation";
import ExchangeCreate from "../Exchanges/ExchangeCreate";
import {checkQuotaAccess, useAuth0} from "../../helpers/Auth0";
import ReactTooltip from "react-tooltip";
import {TextField} from "@material-ui/core";
import {withRouter} from "react-router-dom";


const AccountManagement = props => {
    const {
        exchanges,
        location,
        history,
        portfolios,
        fetchDataAccount,
        getPortfolios
    } = props;

    const {user} = useAuth0();

    // Create Edit Modal
    const [showP, setShowP] = useState(false);

    // Data for accounts
    const [data, setData] = useState({items: [], loading: true, selected: {}});

    // Delete modal
    const [showC, setShowC] = useState(false);

    // Extract navigated exchange value
    const locState = location && location.state && location.state.exchange ? location.state : null;

    useEffect(() => {
        if (locState && !data.loading) {
            setShowP(true);
        }
    }, [locState, data.loading]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = (text) => {
        setData(d => ({...d, loading: true}));
        PortfolioApi.getAccounts().then(resp=>{
            // Fill exchange names
            const mapped = resp.map(el => {
                if(el.key_details){
                    el.key_details.exchange_display_name = exchanges[el.key_details.exchange_name].name;
                }
                el.show = false;
                return el;
            });
            setData({items: mapped, loading: false });
        }).catch(e=>{
            if(text === "new"){
                history.go(0);
            }
        })
    };

    const onUpdate = item => {
        setData(d => ({...d, selected: item}));
        setShowP(true);
    };

    const onDelete = item => {
        setData(d => ({...d, selected: item}));
        setShowC(true);
    };

    const handleEditName = (item) =>{
        let newData = {...data};
        newData.items = newData.items.map(el=>{
            if(!el.show && el.id === item.id){
                el.show = !el.show;
            }
            return el;
        })
        setData(newData);
    }

    const handleRemoveEditName = (item) =>{
        let newData = {...data};
        newData.items = newData.items.map(el=>{
            if(el.show && el.id === item.id){
                el.show = !el.show;
            }
            return el;
        })
        setData(newData);
    }

    const handleSave = (event,item) =>{
        let param = {
            name:event.target.value
        }
        PortfolioApi.updateAccount(param,item.id).then(resp=>{
            toastr.success('', 'Successfully updated account name');
            let newData = {...data};
            newData.items = newData.items.map(el=>{
                if(el.show && el.id === item.id){
                    el.show = !el.show;
                    el.name = param.name;
                }
                return el;
            })
            setData(newData);
            setShowP(false);
            getPortfolios();
        }).catch(err => {
            console.error(err)
            toastr.error('Something went wrong while updating account name');
        });
    }



    return (
        <div className="general-tab-body-all">
            <div className="general-tab-body active" id="news-alert" style={{borderBottomLeftRadius: "5px",borderBottomRightRadius: "5px"}}>
                <div className="set-news-alert">
                    <div style={{color:"gray",padding:"15px",maxWidth:"1260px"}}>
                        <i style={{marginRight:"5px"}} className="fas fa-info-circle"/>
                        You can create an exchange account using your exchange API key / secret or you can create manual accounts and upload relevant activity data to it. You can then map any of your accounts to a any of your portfolios.
                    </div>
                    <hr style={{borderTop:"1px solid #191d21", marginTop:"0px",marginBottom:"0px"}}/>
                    <div className={"inline-tab-wrapper"} style={{marginTop:"15px",color:"white",padding:"0px 20px",fontSize:"16px",display:"flex",justifyContent:"space-between",flexDirection:"row"}}>
                        <div>
                            My Accounts
                        </div>
                        <div style={{cursor:"pointer",paddingRight: "25px"}} onClick={() => {
                            const quota = checkQuotaAccess(user, 'exchange_connections', data.items.length);
                            setData(d => ({...d, selected: null}));
                            if (quota) {
                                setShowP(true);
                            } else {
                                toastr.error("", `You cannot create new watchlist. Upgrade your plan (Remaining Quota: ${quota}).`)
                            }
                        }}>
                            <i className="fas fa-plus-circle" style={{
                                fontSize: "1.5em",
                                color: "#7ac231",
                                marginTop:"1px",
                            }}/>
                        </div>
                    </div>
                    <div style={{padding:"10px",height:"308px",marginTop:"0px",overflow:"auto"}} className="alerts-price-alert">
                        <div className="pa-inner">
                            <div id="beauty-scroll-table">
                                <Loading loading={data.loading}>
                                    <table style={{width:"100%"}}>
                                        <thead>
                                        <tr>
                                            <th style={{color: '#fff',padding:"10px",fontSize:"14px"}}>Account Name</th>
                                            <th style={{color: '#fff',padding:"10px",fontSize:"14px"}}>Type</th>
                                            <th style={{color: '#fff',padding:"10px",fontSize:"14px"}}>Exchange</th>
                                            <th style={{color: '#fff',padding:"10px",fontSize:"14px",textAlign:"center"}}>Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data.items.map((d, i) => (
                                                <tr key={i}>
                                                    {
                                                        d.show ?
                                                            <td className="account" style={{color: '#fff',padding:"10px",fontSize:"14px",display:"flex"}}>
                                                                <ReactTooltip html={true} />
                                                                <TextField onKeyPress={(e)=>{
                                                                    if(e.key === 'Enter'){
                                                                        handleSave(e,d);
                                                                    }
                                                                }
                                                                } id="standard-required" defaultValue={d.name} />
                                                                <div style={{marginLeft: "10px",marginTop:"5px"}} data-html={true} data-tip={"Cancel"}>
                                                                    <a style={{cursor: "pointer"}} onClick={()=>{
                                                                        handleRemoveEditName(d);
                                                                    }}><img
                                                                        className="actionable-img"
                                                                        alt=""/><i  className="far fa-window-close"
                                                                                    style={{
                                                                                        fontSize: "1.5em",
                                                                                        color: "#c11e0f"
                                                                                    }}/></a>
                                                                </div>
                                                            </td>
                                                            :
                                                            <td style={{color: '#fff',padding:"10px",fontSize:"14px"}}>
                                                                {d.name}
                                                            </td>

                                                    }
                                                    <td style={{color: '#fff',padding:"10px",fontSize:"14px"}}>{d.type.toUpperCase()}</td>
                                                    {d.key_details ? <td style={{color: '#fff',padding:"10px",fontSize:"14px"}}>{d.key_details.exchange_display_name}</td> : <td style={{color: '#fff',padding:"10px"}}>-</td>}
                                                    <td className="edit-delete-btn" style={{width: "120px", cursor: "pointer",padding:"10px",textAlign:"center",fontSize:"14px"}}>
                                                        <button onClick={() => handleEditName(d)} className=" open-modal-event" style={{marginRight: "15px"}}>
                                                            <img className="actionable-img" src="/img/edit.svg" alt="" />
                                                        </button>
                                                        <button onClick={() => onDelete(d)} className=" open-modal-event">
                                                            <img className="actionable-img" src="/img/delete.svg" alt="" />
                                                        </button>
                                                    </td>
                                                </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </Loading>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ExchangeConfirmation
                fetchDataAccount={fetchDataAccount}
                fetchKeys={fetchData}
                item={data.selected}
                show={showC}
                close={() => {
                    setData(d => ({...d, selected: {}}));
                    setShowC(false);
                }}
            />
            <ExchangeCreate
                fetchDataAccount={fetchDataAccount}
                fetchKeys={fetchData}
                portfolios={portfolios}
                history={history}
                isNew={data.selected}
                locState={locState}
                show={showP}
                close={() => {
                    if (locState) history.replace('/exchanges');
                    setData(d => ({...d, selected: {}}));
                    setShowP(false);
                }}
            />
        </div>
    );
};

const mapStateToProps = state => {
    // Convert exchanges to map of id to exchange data
    const exchangeMap = {};
    state.meta.connectable_exchanges.filter(el => el.is_connectable).forEach(el => exchangeMap[el.id] = el);

    return {
        exchanges: exchangeMap
    }
};


export default connect(mapStateToProps)(withRouter(AccountManagement));

