import React, {useState} from 'react';
import moment from 'moment';
import SummaryModal from "../../../pages/News/SummaryModal";

const FitLatestNews = (props) => {
    const {news} = props;
    const [show, setShow] = useState(false);
    const [item, setItem] = useState({});

    return (
        <div className="gray-container latest-news" style={{height: "100%", display: "flex", flexDirection: "column"}}>
            <div className="section-header">Latest News</div>
            <div className="news-list news-list-mobile" id="beauty-scroll3" style={{flex: "1 1 1px", minHeight: "50vh", maxHeight: "inherit", marginTop:"5px"}}>
                <ul style={{margin: '1em'}}>
                    {news && news.length &&
                    news.map((d, i) => {
                        const source = d.source.split("_").map(word =>  word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
                        const date = moment.utc(d.publication_datetime).local();
                        return (
                            <li key={i} onClick={() => {setShow(true); setItem(d);}}>
                                <div className="ln-date">
                                    <span>{date.format('DD.MM.YYYY')}/</span> {date.format('HH:mm')}
                                </div>
                                <div className="ln-text">{d.title}</div>
                                <div className="ln-link">
                                    {' '}
                                    <a target="_blank" className="blue-link" href={d.link}>
                                        {source}
                                    </a>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <SummaryModal show={show} item={item} cancel={() => setShow(false)}/>
        </div>
    );
};

export default FitLatestNews;
