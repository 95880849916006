import React, {useEffect, useRef, useState} from "react";

import {withRouter} from "react-router-dom";
import {Bar} from "react-chartjs-2";

const HorizontalBar = props => {

    const {title,datas,values,color, hoverColor, max} = props;

    const [backgroundColor,setBackgroundColor] = useState([]);

    useEffect(()=>{
        if(color && datas.length > 0){
            let colorNew = [];
            datas.forEach(el=>{
                colorNew.push(color);
            })
            setBackgroundColor(colorNew);
        }
    },[color,datas])

    const data = {
        labels: datas,
        datasets: [
            {
                label: 'Number Of News',
                data: values,
                backgroundColor: backgroundColor,
                borderColor: backgroundColor,
                hoverBackgroundColor:hoverColor,
                borderWidth: 1,

            },
        ],
    };

    const options = {
        legend: {
            display: false,
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        fontColor:"white",
                        max:max,
                        precision: 0
                        // stepSize:1,
                    },
                },
            ],
            xAxes: [
                {
                    ticks: {
                        fontColor:"white"
                    },
                },
            ],
        },
        title: {
            display:true,
            text: title.text,
            fontSize: 14,
            fontColor:"white"
        }

    };

    return (
        <Bar data={data} options={options} />
    );
};

export default withRouter(HorizontalBar);
