import React, {useEffect, useState} from 'react';
import {setLoading} from "../../actions/generalAction";
import {connect} from "react-redux";


const ExceptionsTable = (props) => {
    const {
        meta, // Meta Data
        historyAccounts, // Portfolio balance history
    } = props;

    // Connectable Exchanges
    const [exchanges,setExchanges] = useState({});

    // Get connectable exchanges from meta data
    useEffect(()=>{
        const mapExc = {};
        meta.connectable_exchanges.forEach(el=>{
            mapExc[el.code] = el.name;
        });
        mapExc["CIS"] = "Crypto Index Series";
        setExchanges(mapExc);
    },[]);


    return (
        <React.Fragment>
            <div className={"row"}>
                <div className="col-md-6 col-custom">
                    <div style={{fontSize: "20px",textAlign:"left"}}>Asset Exceptions</div>
                    <div className="table-body table-responsive" id="beauty-scroll7"
                         style={{height: "233px"}}>
                        <table className="coins enumarable-table coins-menu-table linked-table"
                               style={{marginTop: "10px"}}>
                            <thead>
                            <tr>
                                <th style={{width:"70%"}}>Name</th>
                                <th style={{width:"30%"}}>Asset</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                historyAccounts.asset_exceptions && historyAccounts.asset_exceptions.length > 0 ?
                                    historyAccounts.asset_exceptions.map((el, i) => {
                                        return (
                                            <tr style={{cursor: "auto"}} key={i}>
                                                <td>{exchanges[el[0]]} ({el[0]})</td>
                                                <td>{el[1]}</td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan={4}>
                                            There is no asset exception!
                                        </td>
                                    </tr>
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-md-6 col-custom">
                    <div style={{fontSize: "20px",textAlign:"left"}}>Instrument Exceptions</div>
                    <div className="table-body table-responsive" id="beauty-scroll7"
                         style={{height: "233px",marginTop: "10px"}}>
                        <table className="coins enumarable-table coins-menu-table linked-table">
                            <thead>
                            <tr>
                                <th style={{width:"70%"}}>Name</th>
                                <th style={{width:"30%"}}>Instrument</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                historyAccounts.instrument_exceptions && historyAccounts.instrument_exceptions.length > 0 ?
                                    historyAccounts.instrument_exceptions.map((el, i) => {
                                        return (
                                            <tr style={{cursor: "auto"}} key={i}>
                                                <td>{exchanges[el[0]]} ({el[0]})</td>
                                                <td>{el[1]}</td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan={4}>
                                            There is no asset exception!
                                        </td>
                                    </tr>
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setFullLoading: data => dispatch(setLoading(data)),
    };
};

const mapStateToProps = state => {
    return {
        meta: state.meta,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExceptionsTable);





