export const AUTH = "AUTH"
export const REGISTER = "REGISTER"



export const EXCEPTION = "EXCEPTION"


export const META_DATA = "META_DATA"
export const NEWS_TAGS = "NEWS_TAGS"
export const CLEAR_META_DATA = "CLEAR_META_DATA"
export const GET_ALL_COINS = "GET_ALL_COINS"
export const GET_EXCHANGES = "EXCHANGES"
export const GET_CONNECTABLE_EXCHANGES = "CONNECTABLE_EXCHANGES"

export const COIN_SEARCH = "COIN_SEARCH"

export const SUBSNEEDED = "SUBSNEEDED";

export const SUBS_NEEDED_LIST = "SUBS_NEEDED_LIST"


export const LOADER = "LOADER"


export const CHANGE_EXCHANGE = "CHANGE_EXCHANGE"


export const LISTEN_COINS = "LISTEN_COINS"
export const LISTEN_TOTAL_VOLUME = "LISTEN_TOTAL_VOLUME"


export const COIN_CLEAR = "COIN_CLEAR"


export const WATCHLISTS = "WATCHLISTS"
export const WATCHPAIRS = "WATCHPAIRS"
export const WATCHSELECTED = "WATCHSELECTED"

export const GENERAL_UPDATE = "GENERAL_UPDATE"
export const SET_LOADING = "SET_LOADING"
