import React, {forwardRef, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import {setLoading} from "../../actions/generalAction";
import Loading from "../../components/Loading";
import Select from "../../components/Forms/Select";
import {toastr} from "react-redux-toastr";
import PortfolioApi from "../../api/PortfolioApi";


// Selectable file types "xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt"
const types = [
    {id: "xlsx", name: "xlsx"},
    {id: "xlsb", name: "xlsb"},
    {id: "xlsm", name: "xlsm"},
    {id: "xls", name: "xls"},
    {id: "xml", name: "xml"},
    {id: "csv", name: "csv"},
    {id: "txt", name: "txt"}
]

const ExportTradeDocument = props => {
    const {close, show, portfolioAccounts} = props;
    const [manualAccounts, setManualAccounts] = useState({data: [], loading: true})
    const [manualAccount, setManualAccount] = useState({});

    // Selected file type
    const [fileType, setFileType] = useState( {id: "xlsx", name: "xlsx"});

    useEffect(() => {
        if (portfolioAccounts && portfolioAccounts.data.length > 0 && show) {
            setManualAccounts({data: portfolioAccounts.data, loading: false});
            setManualAccount(portfolioAccounts.data[0]);
        } else if (show) {
            toastr.error('', 'There is no account linked to portfolio.');
            close();
        }
    }, [portfolioAccounts, show]);


    // Multi select change function
    const onChange = (field, selected) => {
        if (field === "manual") {
            setManualAccount(selected);
        } else if (field === "fileType") {
            setFileType(selected);
        }
    };

    const onSave = () => {
        PortfolioApi.exportTradeDocument(manualAccount.id, fileType.id).then(resp => {
            toastr.success('','Your request is being processed!');
            close();
        }).catch(err => {
            console.error(err);
            toastr.error('Something went wrong while exporting document!');
        });
    };


    return (
        <div className={`general-modal ${show ? 'active' : ''}`} id="set-news-alert">
            <div className="vAlignWr">
                <div className="vAlign">
                    <div className="white-modal-inner set-alert">
                        <div className="modal-blue-header">
                            {"Export Trade Document"}
                            <div onClick={close} className="close-modal" data-target="set-news-alert">
                                <img src="/img/close-white.svg" alt=""/>
                            </div>
                        </div>
                        <div style={{minWidth: '50vw'}} className="modal-white-body">
                            <div className="general-tab-area">
                                <div className="general-tab-menu">
                                    <ul>
                                        <li className="active">
                                            <a href="#" data-target="price-alert">
                                                {"Export Trade Document"}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <Loading loading={manualAccounts.loading}>
                                    <div className="general-tab-body-all">
                                        <div className="general-tab-body active" id="news-alert">
                                            <div className="set-news-alert">
                                                <div className="input-table">
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td style={{width: "30%"}}>
                                                                <div className="spa-text">Accounts:</div>
                                                            </td>
                                                            <td>
                                                                <div className="sm-select-input">
                                                                    <Select
                                                                        selected={manualAccount.id || 0}
                                                                        optionValue={'id'}
                                                                        optionLabel={'name'}
                                                                        options={manualAccounts.data || []}
                                                                        onChange={selected => onChange("manual", selected)}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="spa-text">File Type:</div>
                                                            </td>
                                                            <td>
                                                                <div className="sm-select-input">
                                                                    <Select
                                                                        selected={fileType.id || 0}
                                                                        optionValue={'id'}
                                                                        optionLabel={'name'}
                                                                        options={types || []}
                                                                        onChange={selected => onChange("fileType", selected)}
                                                                        maxHeight={100}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    <div className="clearfix"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Loading>
                            </div>
                        </div>
                        <div className="modal-gray-footer">
                            <div className="form-buttons">
                                <div className="form-flex">
                                    <div className="form-flex-inner" style={{width: "120px"}}>
                                        <div className="light-gray-btn">
                                            <button onClick={close} className="close-modal"
                                                    data-target="set-news-alert">
                                                CANCEL
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-flex-inner" style={{width: "120px"}}>
                                        <div className="light-blue-btn">
                                            <button onClick={onSave}>
                                                {"EXPORT"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setFullLoading: data => dispatch(setLoading(data)),
    };
};

const mapStateToProps = state => {
    return {
        meta: state.meta,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportTradeDocument);
