import {SET_LOADING, GENERAL_UPDATE} from './types';

export const setLoading = data => ({
  type: SET_LOADING,
  payload: data,
});

export const setGeneral = data => {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: GENERAL_UPDATE,
        payload: data,
      });
      resolve();
    });
};
