import React, {useState, useEffect, useRef} from 'react';
import Loading from '../Loading';
import Select from '../Forms/Select';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import Api from '../../api';
import {connect} from 'react-redux';
import IndicesTable from './IndicesTable';
import Paginate from '../Paginate';
import {useAuth0} from "../../helpers/Auth0";
import Utils from "../../helpers/Utils";
import AppHelpers from "../../helpers/AppHelpers";
import CISNavigator from "../../helpers/CISNavigator";
import CryptoAlertModal from "../Alerts/CryptoAlertModal/CryptoAlertModal";

const isMobile = Utils.checkMobile();

const pages = [
    {value: 10, label: '10'},
    {value: 20, label: '20'},
    {value: 50, label: '50'},
    {value: 100, label: '100'},
    {value: 300, label: '300'},
];

const Indices = (props, context) => {
    const {t} = context;
    const subListRef = useRef({});
    const {user} = useAuth0()
    const {filter, setWatchListModal, containerType, meta, setLoadingP, history, param, tabs, exchanges} = props;
    const [exchange, setExchange] = useState(user.preferences.currency);
    const [result, setResult] = useState({items: [], loading: true});
    const [pageInfo, setPageInfo] = useState({page: 1, totalPage: 0});
    const [pageSize, setPageSize] = useState(10);
    const [selectedExchange, setSelectedExchange] = useState({});
    const [sort, setSort] = useState({i: 'mkt_cap', s: 'desc'});


    const filterParam = filterT => ({
        page_size: pageSize || 10,
        asset_type: filterT.asset || '',
        coin_symbols: filterT.coin || '',
        business_classification: filterT.sector || '',
        ccy: filterT.ccy,
        search_string: filterT.keyword || '',
        mkt_cap_min: (filterT.cap && filterT.cap.min) || '',
        mkt_cap_max: (filterT.cap && filterT.cap.max) || '',
        total_coin_volume_24h_min: (filterT.volume && filterT.volume.min) || '',
        total_coin_volume_24h_max: (filterT.volume && filterT.volume.max) || '',
        chgpct_24h_min: filterT.chg && filterT.chg.min === "0" ? 0 : (filterT.chg && filterT.chg.min / 100) || '',
        chgpct_24h_max: filterT.chg && filterT.chg.max === "0" ? 0 : (filterT.chg && filterT.chg.max / 100) || '',
        order_by: `${sort.i},${sort.s}`,
        ts_cutoff: 0,
        page_number: filterT.page || 1,
        mineable: filterT.mineable,
        stablecoin: filterT.stablecoin,
        market_cap_category: filterT.market_cap_category,
        volume_category: filterT.volume_category,
        volatility_category: filterT.volatility_category,
        consensus_model: filterT.consensus_model,
        is_defi : filterT.is_defi
    });

    // Alert related
    const [newType, setNewType] = useState({label: "Create"});

    // Show add alert modal
    const [showCU, setShowCU] = useState(false);

    // Type
    const [type, setType] = useState("");

    // update item infos
    const [updated, setUpdated] = useState({id: null, data: null});

    const getExchangeName = (code) => {
        let marketLabel = "";
        if(code = "CISCALC"){
            marketLabel = "Crypto Index Series"
        }else{
            exchanges.forEach(exel => {
                if (code === exel.code) {
                    marketLabel = exel.name;
                    return;
                }
            });
        }

        return marketLabel;
    }





    const filterURL = (filterT) => {
        const params = new URLSearchParams(filterParam(filterT));
        // Change ccy param at url
        history.replace({
            pathname: history.location.pathname,
            search: "?"+params.toString(),
        });
    }

    const currencyList =
        (meta.lists &&
            meta.lists.key_currency_list &&
            meta.lists.key_currency_list.map(c => ({
                value: c[0],
                label: c[0],
                type: c[2] === 'FIAT' ? 'USD' : 'BTC'
            }))) ||
        [];

    useEffect(() => {
        let selectedExchange = currencyList.filter(e => e.value === exchange);
        selectedExchange = (selectedExchange.length && selectedExchange[0]) || {};
        setSelectedExchange(selectedExchange);
    }, [exchange]);

    useEffect(() => {
        if(param){
            setExchange(param.ccy);
            const newSort = param.order_by.split(",");
            setSort({i:newSort[0],s:newSort[1]});;
        }
    }, [param]);


    useEffect(() => {
        if(filter){
            filter.ccy = exchange;

            fetchData(filter);
        }
    }, [exchange, filter, sort.i, sort.s, pageSize]);

    useEffect(() => {
        setLoadingP(result.loading);
    }, [result.loading]);

    const fetchData = f => {
        // if (Object.keys(subList).length) conOrCloseSocket(subList, 'close');
        setResult({items: [], loading: true});
        setPageInfo({page: 1, totalPage: 0});

        setTimeout(() => {
            Api.getPromise('/Search/CoinSearch', filterParam(f))
                .then(res => {
                    const c = pageSize || 10;
                    const data = (res && res.data) || [];
                    setResult({items: data, loading: false});
                    setPageInfo({page: f.page || 1, totalPage: Math.ceil(res.total_count / c) || 0});
                    filterURL(f);
                })
                .catch(e => setResult({items: [], loading: false}));
        }, 500);
    };

    const handlePageClick = d => {
        let f = filter;
        if (pageInfo.page !== d.selected + 1) {
            f.page = d.selected + 1;
            fetchData(f);
        }
    };

    const perClassName = value => {
        const key = AppHelpers.isPositiveFloat(value);
        switch (key) {
            case 1:
                return 'table-green-text';
            case -1:
                return 'table-red-text';
            default:
                return 'table-gray-text';
        }
    };

    const perSvg = value => {
        const key = AppHelpers.isPositiveFloat(value);
        switch (key) {
            case 1:
                return '/img/up.svg';
            case -1:
                return '/img/down.svg';
            default:
                return null;
        }
    };

    const calculateChg = (value) =>{
        return  <td className={perClassName((value*100).toFixed(2))}>{perSvg((value*100).toFixed(2)) && <img height="15px" width="15px" className="actionable-img" src={perSvg((value*100).toFixed(2))} alt="" />}{" "}{(value*100).toFixed(2)}</td>
    }

    const sortChange = index => (sort.s === 'desc' ? {i: index, s: 'asc'} : {i: index, s: 'desc'});

    const sortArrow = index => sort.i === index &&
        <i style={{fontSize: 12}} className={`fa fa-angle-${sort.s === 'desc' ? 'up' : 'down'}`}/>;

    let selectedSize = pages.filter(p => p.value === pageSize);
    selectedSize = (selectedSize.length && selectedSize[0]) || {};


    return (
        isMobile ?
            <div className={`gray-container ${containerType}`}>
                <div className="table-head">
                    <div className="table-left">
                        <div className="table-header">OVERVIEW</div>
                    </div>
                    <div className="table-right">
                        <div className="table-select-input">
                            <div className="sm-select-input small-select">
                                <Select selected={exchange} options={currencyList}
                                        onChange={selected => setExchange(selected.value)}/>
                            </div>
                        </div>

                        <div className="table-select-input">
                            <div className="sm-select-input small-select">
                                <Select selected={pageSize} options={pages}
                                        onChange={selected => setPageSize(selected.value)}/>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"/>
                </div>
                <div className="table-body table-responsive" id="beauty-scroll7">
                    <Loading loading={result.loading}>
                        <ReactTooltip html={true}/>
                        <table className="coins enumarable-table coins-menu-table linked-table">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Price</th>
                                <th onClick={() => setSort(sortChange('mkt_cap'))} style={{cursor: 'pointer'}}>
                                    Mkt. Cap {isMobile ? null : <i data-html={true} data-tip={t('captDesc')}
                                                                   className="fas fa-info-circle"/>} {isMobile ? null : sortArrow('mkt_cap')}
                                </th>
                                <th>Circ. Supply</th>
                                <th>Max. Supply</th>
                                <th onClick={() => setSort(sortChange('vto'))} style={{cursor: 'pointer'}}>
                                    24h Vol
                                    <i data-tip={t('totalVDesc')} className="fas fa-info-circle"/> {sortArrow('vto')}
                                </th>
                                <th onClick={() => setSort(sortChange('chgpct'))} style={{cursor: 'pointer'}}>
                                    24h Change% {isMobile ? null : sortArrow('chgpct')}
                                </th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <IndicesTable data={result.items} setWatchListModal={setWatchListModal}
                                          selectedExchange={selectedExchange}/>
                        </table>
                    </Loading>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Paginate pageCount={pageInfo.totalPage} onPageChange={handlePageClick}
                                  active={pageInfo.page - 1}/>
                    </div>
                </div>
            </div>
            :
            <div className={`gray-container ${containerType}`} style={{borderTopLeftRadius:"0px",borderTopRightRadius:"0px"}}>
                <div className="table-head">
                    <div className="table-left">
                        {/*<div className="table-header">OVERVIEW</div>*/}
                        {/* <div className="table-search">
                    <div className="sm-search-input">
                        <input type="text" placeholder="Search Indices">
                        <button type="submit"><img className="actionable-img" src="img/search.svg" alt=""></button>
                    </div>
                </div> */}
                    </div>
                    <div className="table-right">
                        {
                            tabs === "Overview" &&
                            <div className="table-select-input">
                                <div className="sm-select-input small-select">
                                    <Select selected={exchange} options={currencyList}
                                            onChange={selected => setExchange(selected.value)}/>
                                </div>
                            </div>

                        }
                        <div className="table-select-input">
                            <div className="sm-select-input small-select">
                                <Select selected={pageSize} options={pages}
                                        onChange={selected => setPageSize(selected.value)}/>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"/>
                </div>

                <div className="table-body table-responsive" id="beauty-scroll7">
                    <Loading loading={result.loading}>
                        {
                            tabs === "Overview" ?
                                <React.Fragment>
                                    <ReactTooltip html={true}/>
                                    <table className="coins enumarable-table coins-menu-table linked-table">
                                        <thead>
                                        <tr>
                                            {/*<th>Rank</th>*/}
                                            <th>Name</th>
                                            <th>Price</th>
                                            <th onClick={() => setSort(sortChange('mkt_cap'))} style={{cursor: 'pointer'}}>
                                                Mkt. Cap {isMobile ? null : <i data-html={true} data-tip={t('captDesc')}
                                                                               className="fas fa-info-circle"/>} {isMobile ? null : sortArrow('mkt_cap')}
                                            </th>
                                            <th>Circ. Supply</th>
                                            <th>Max. Supply</th>
                                            <th onClick={() => setSort(sortChange('vto'))} style={{cursor: 'pointer'}}>
                                                24h Vol
                                                <i data-tip={t('totalVDesc')}
                                                   className="fas fa-info-circle"/> {sortArrow('vto')}
                                            </th>
                                            <th onClick={() => setSort(sortChange('chgpct'))} style={{cursor: 'pointer'}}>
                                                24h Change% {isMobile ? null : sortArrow('chgpct')}
                                            </th>
                                            <th>Market Cap Category</th>
                                            <th>Volatility Category</th>
                                            <th>Volume Category</th>
                                            <th>Actions</th>
                                        </tr>
                                        </thead>
                                        <IndicesTable data={result.items} setWatchListModal={setWatchListModal}
                                                      selectedExchange={selectedExchange} setShowCU={setShowCU} setUpdated={setUpdated} setType={setType}/>
                                    </table>
                                </React.Fragment>
                                :
                                <table className="sector-coins enumarable-table coins-menu-table linked-table">
                                    <thead>
                                    <tr>
                                        <th colSpan={3} style={{border:"none"}}></th>
                                        <th colSpan={8} style={{textAlign:"center" ,fontWeight:"bold"}}>Change %</th>
                                        <th colSpan={4} style={{textAlign:"center",fontWeight:"bold"}}>30 day Avg / calcs</th>
                                    </tr>
                                    <tr>
                                        <th>Coin</th>
                                        <th>Mkt Cap Category</th>
                                        <th style={{cursor:"pointer"}}>Close</th>
                                        <th style={{cursor:"pointer"}}>7D</th>
                                        <th style={{cursor:"pointer"}}>30D</th>
                                        <th style={{cursor:"pointer"}}>3M</th>
                                        <th style={{cursor:"pointer"}}>6M</th>
                                        <th style={{cursor:"pointer"}}>YTD</th>
                                        <th style={{cursor:"pointer"}}>1Y</th>
                                        <th style={{cursor:"pointer"}}>2Y</th>
                                        <th style={{cursor:"pointer"}}>3Y</th>
                                        <th style={{cursor:"pointer"}}>Avg Mkt Cap</th>
                                        <th style={{cursor:"pointer"}}>Avg Vol To</th>
                                        <th style={{cursor:"pointer"}}>Volatility</th>
                                        <th style={{cursor:"pointer"}}>RSI</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        result.items.map((coin,i)=>(
                                            <tr key={i} onClick={() => CISNavigator.navigateToCoinDetail(history, coin)}>
                                                <td>{coin.ticker.asset_symbol}</td>
                                                <td>{coin.market_cap_category?coin.market_cap_category:"-"}</td>
                                                <td>{coin.performance.close_price?AppHelpers.priceFormat(coin.performance.close_price):"-"}</td>
                                                {coin.performance.chgpct_7d?calculateChg(coin.performance.chgpct_7d):<td>-</td>}
                                                {coin.performance.chgpct_30d?calculateChg(coin.performance.chgpct_30d):<td>-</td>}
                                                {coin.performance.chgpct_3m?calculateChg(coin.performance.chgpct_3m):<td>-</td>}
                                                {coin.performance.chgpct_6m?calculateChg(coin.performance.chgpct_6m):<td>-</td>}
                                                {coin.performance.chgpct_ytd?calculateChg(coin.performance.chgpct_ytd):<td>-</td>}
                                                {coin.performance.chgpct_1y?calculateChg(coin.performance.chgpct_1y):<td>-</td>}
                                                {coin.performance.chgpct_2y?calculateChg(coin.performance.chgpct_2y):<td>-</td>}
                                                {coin.performance.chgpct_3y?calculateChg(coin.performance.chgpct_3y):<td>-</td>}
                                                <td>{coin.technical.avg_market_cap_30d?AppHelpers.numberFormat(coin.technical.avg_market_cap_30d):"-"}</td>
                                                <td>{coin.technical.avg_volume_to_30d?AppHelpers.numberFormat(coin.technical.avg_volume_to_30d):"-"}</td>
                                                <td>{coin.technical.volatility_30d?coin.technical.volatility_30d.toFixed(2):"-"}</td>
                                                <td>{coin.technical.rsi_30d?coin.technical.rsi_30d.toFixed(2):"-"}</td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>

                                </table>
                        }
                    </Loading>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Paginate pageCount={pageInfo.totalPage} onPageChange={handlePageClick}
                                  active={pageInfo.page - 1}/>
                    </div>
                </div>
                <CryptoAlertModal
                    newType={newType}
                    getExchangeName={getExchangeName}
                    show={showCU}
                    type={type}
                    setType={setType}
                    updated={updated}
                    close={() => {
                        setUpdated({id: null, data: null});
                        setType("");
                        setShowCU(false);
                    }}
                />
            </div>
    );
};
Indices.contextTypes = {
    t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    return {
        meta: state.meta,
        exchanges: state.meta.exchanges
    };
};

export default connect(mapStateToProps)(Indices);
