import React, {useState, useEffect} from 'react';
import Loading from '../../../../components/Loading';
import {toastr} from 'react-redux-toastr';
import Utils from "../../../../helpers/Utils";
import {connect} from "react-redux";
import {checkQuotaAccess, useAuth0} from "../../../../helpers/Auth0";
import ReactTooltip from "react-tooltip";
import ConfirmationAlert from "../../../../components/Alerts/ConfirmationAlert";
import CryptoAlertModal from "../../../../components/Alerts/CryptoAlertModal/CryptoAlertModal";
import NewsAlertModal from "../../../../components/Alerts/NewsAlertModal/NewsAlertModal";
import Api from "../../../../api";

const defaultConditionFields = {
    coins: [{value: "ALL", label: "ALL"}],
    sectors: [{value: "ALL", label: "ALL"}],
    assets: [{value: "ALL", label: "ALL"}],
    sources: [{value: "ALL", label: "ALL"}],
    exchanges: [{value: "ALL", label: "ALL"}],
    categories: [{value: "ALL", label: "ALL"}],
    nations: [{value: "ALL", label: "ALL"}],
}

const concat = (x, y) =>
    x.concat(y)

const flatMap = (f, xs) =>
    xs.map(f).reduce(concat, [])

const NewsAlert = props => {
    const {loading, data, setData, getExchangeName, fetchData, meta} = props;

    // remove item infos
    const [removed, setRemoved] = useState({type: "", id: 0, index1: 0, index2: 0});
    // update item infos
    const [updated, setUpdated] = useState({id: null, data: null});
    // Show delete modal
    const [showDelete, setShowDelete] = useState(false);
    // Show edit modal
    const [showCU, setShowCU] = useState(false);
    // Type
    const [type, setType] = useState("Create");
    // New Type for title
    const [newType, setNewType] = useState({label: "Create"});
    // All condition fields
    const [conditionFields, setConditionFields] = useState(defaultConditionFields);

    const {
        lists, // Metadata lists (e.g. business_classification....)
        newsTags, // Additional news tags
        allCoins // Coin list
    } = meta || {};


    useEffect(() => {
        getFields();
    }, []);

    // Get all fields from metadata
    const getFields = () => {
        let newConditionFields = {...conditionFields};
        const newCoins = Utils.prepareCoinSelections(allCoins).map(el => {
            return ({value: el.symbol, label: el.symbol, tag: "COIN_" + el.symbol});
        })
        const newSectors = lists.business_classification_schema.map(el => {
            return ({value: el.business_classification, label: el.business_classification, tag: el.news_tag})
        });
        const newAssetTypes = lists.asset_type_list.map(el => {
            return ({value: el.asset_type, label: el.asset_type, tag: el.news_tag})
        })
        const newExchanges = newsTags.exchanges.map(el => {
            return ({value: el.tag, label: el.tag, tag: el.tag_type + "_" + el.tag})
        })
        const newCategories = newsTags.other.map(el => {
            return ({value: el.tag, label: el.tag, tag: el.tag_type + "_" + el.tag})
        })
        const newNations = flatMap(el => el.children, newsTags.geographical).map(el => {
            return ({value: el.tag, label: el.tag, tag: el.tag_type + "_" + el.tag})
        })

        Api.getPromise('/Metadata/GetNewsSources').then(resp=>{
            newConditionFields.coins = newConditionFields.coins.concat(newCoins);
            newConditionFields.sectors = newConditionFields.sectors.concat(newSectors);
            newConditionFields.assets = newConditionFields.assets.concat(newAssetTypes);
            newConditionFields.exchanges = newConditionFields.exchanges.concat(newExchanges);
            newConditionFields.categories = newConditionFields.categories.concat(newCategories);
            newConditionFields.nations = newConditionFields.nations.concat(newNations);

            const newSources = resp.map(el=>{
                return ({value:el.id,label:el.name});
            })

            newConditionFields.sources = newConditionFields.sources.concat(newSources);
            setConditionFields(newConditionFields)
        })

    }

    const handleOpenConditions = (index) => {
        let newData = {...data};
        newData.items[index].show = !newData.items[index].show;
        setData({items: newData.items, loading: false});
    }

    const handleDeleteAlert = (id) => {
        setRemoved({type: "news", id: id, title: "news alert"});
        setShowDelete(true);
    }

    const handleEditAlert = (id, item) => {
        setUpdated({id: id, data: item});
        setType("Update");
        setNewType({label: "Update"})
        setShowCU(true);
    }

    const handleCreateAlert = () => {
        setType("Create");
        setNewType({label: "Create"})
        setShowCU(true);
    }


    return (
        <div className="gray-container alerts-price-alert">
            <div className="section-header" style={{display: "flex", justifyContent: "space-between"}}>
                <div>
                    News Alerts
                </div>
                <div>
                    <div style={{width: 180, marginLeft: "10px"}} className="blue-border-btn flRight">
                        <a style={{cursor: "pointer"}} onClick={handleCreateAlert}>Create News Alert</a>
                    </div>
                </div>
            </div>
            <div className="pa-inner">
                <div className="scroll-table" id="beauty-scroll-table">
                    <Loading loading={loading}>
                        <ReactTooltip html={true}/>
                        <table>
                            <thead>
                            <tr>
                                <th style={{width: "57px", padding: "7px 5px"}}/>
                                <th style={{color: '#7d7d7d', padding: "7px 5px"}}>Name</th>
                                <th style={{color: '#7d7d7d', padding: "7px 5px"}}>Condition Count</th>
                                <th style={{color: '#7d7d7d', padding: "7px 5px"}}>Created Date</th>
                                <th style={{color: '#7d7d7d', padding: "7px 5px"}}>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.items.length > 0 ? data.items.map((d, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <tr key={i}>
                                                <td style={{textAlign: "center", width: "57px", padding: "7px 5px"}}
                                                    onClick={() => handleOpenConditions(i)}><img style={{margin: "1em"}}
                                                                                                 className={"actionable-img"}
                                                                                                 src={d.show ? "/img/arrow-down.svg" : "/img/arrow-right.svg"}/>
                                                </td>

                                                <td style={{color: "fff", padding: "7px 5px"}}>{d.alert_name}</td>
                                                <td style={{color: "fff", padding: "7px 5px"}}>{d.alert_data.length}</td>
                                                <td style={{color: "fff", fontSize: "14px", padding: "7px 5px"}}>
                                                    {d.created_on.split("T")[0]}
                                                </td>
                                                <td className="edit-delete-btn"
                                                    style={{textAlign: "left", padding: "7px 5px"}}>
                                                    <button onClick={() => handleEditAlert(d.alert_id, d)}
                                                            className=" open-modal-event" style={{marginRight: "15px"}}
                                                            data-html={true} data-tip={'Edit Alert'}>
                                                        <img className="actionable-img" src="/img/edit.svg" alt=""/>
                                                    </button>
                                                    <button onClick={() => handleDeleteAlert(d.alert_id)}
                                                            className=" open-modal-event" data-html={true}
                                                            data-tip={'Delete Alert'}>
                                                        <img className="actionable-img" src="/img/delete.svg" alt=""/>
                                                    </button>
                                                </td>
                                            </tr>
                                            {
                                                d.show &&
                                                <tr style={{border: "none"}} key={d.alert_id}>
                                                    <td colSpan={5} style={{padding: "0px"}}>
                                                        <ReactTooltip html={true}/>
                                                        <table>
                                                            <thead>
                                                            <tr style={{fontSize: "14px", background: "#1f2327"}}>
                                                                <th style={{color: '#7d7d7d', padding: "7px 5px"}}>Filter Count</th>
                                                                <th style={{
                                                                    color: '#7d7d7d',
                                                                    padding: "7px 5px"
                                                                }}>Source
                                                                </th>
                                                                <th style={{
                                                                    color: '#7d7d7d',
                                                                    padding: "7px 5px"
                                                                }}>Sentiment Type
                                                                </th>
                                                                <th style={{
                                                                    color: '#7d7d7d',
                                                                    padding: "7px 5px"
                                                                }}>Direction
                                                                </th>
                                                                <th style={{
                                                                    color: '#7d7d7d',
                                                                    padding: "7px 5px"
                                                                }}>Target(%)
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                d.alert_data.map((el, index) => {
                                                                    let sourceName = "";

                                                                    conditionFields.sources.forEach(el1=>{
                                                                        if(el1.value === el.source){
                                                                            sourceName = el1.label;
                                                                        }
                                                                    })

                                                                    return (
                                                                        <tr style={{background: "#1f2327", fontSize: "14px"}}
                                                                            key={index}>
                                                                            <td style={{
                                                                                color: "fff",
                                                                                padding: "7px 5px",
                                                                                textAlign: "left"
                                                                            }}><p style={{
                                                                                margin: "10px 0 0",
                                                                                fontSize: "14px"
                                                                            }}>{el.tag ? el.tag.length : "0"}</p></td>
                                                                            <td style={{
                                                                                color: "fff",
                                                                                padding: "7px 5px",
                                                                                textAlign: "left"
                                                                            }}><p style={{
                                                                                margin: "10px 0 0",
                                                                                fontSize: "14px"
                                                                            }}>{el.source ? sourceName : "-"}</p></td>
                                                                            <td style={{
                                                                                color: "fff",
                                                                                padding: "7px 5px",
                                                                                textAlign: "left"
                                                                            }}><p style={{
                                                                                margin: "10px 0 0",
                                                                                fontSize: "14px"
                                                                            }}>{el.sentiment_target_field ? el.sentiment_target_field.charAt(0).toUpperCase() + el.sentiment_target_field.slice(1):"-"}</p>
                                                                            </td>
                                                                            <td className={el.sentiment_direction === "above" ? "alert-value-above" : "alert-value-below"}
                                                                                style={{
                                                                                    color: "fff",
                                                                                    padding: "7px 5px",
                                                                                    textAlign: "left"
                                                                                }}><p style={{
                                                                                margin: "10px 0 0",
                                                                                fontSize: "14px"
                                                                            }}>{el.sentiment_direction ? el.sentiment_direction.charAt(0).toUpperCase() + el.sentiment_direction.slice(1): "-"}</p>
                                                                            </td>
                                                                            <td className={el.sentiment_target ? (el.sentiment_target > 0 ? "alert-value-above" : "alert-value-below"):("")}
                                                                                style={{
                                                                                    color: "fff",
                                                                                    padding: "7px 5px",
                                                                                    textAlign: "left"
                                                                                }}><p style={{
                                                                                margin: "10px 0 0",
                                                                                fontSize: "14px"
                                                                            }}>{el.sentiment_target ? el.sentiment_target*100 + "%" : "-"}</p></td>
                                                                        </tr>
                                                                    )
                                                                } )
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            }
                                        </React.Fragment>
                                    )
                                }
                                ) :
                                <tr>
                                    <td colSpan={6}
                                        style={{textAlign: "left", color: "fff", fontSize: "14px", padding: "7px 5px"}}>
                                        There is no alert in your account!
                                    </td>
                                </tr>
                            }
                            </tbody>
                        </table>
                    </Loading>
                </div>
                <div className="clearfix"/>
            </div>
            <ConfirmationAlert
                fetchAlerts={fetchData}
                item={removed}
                data={data}
                show={showDelete}
                close={() => {
                    setRemoved({type: "", id: 0, index1: 0, index2: 0})
                    setShowDelete(false);
                }}
            />
            <NewsAlertModal
                fetchData={fetchData}
                newType={newType}
                getExchangeName={getExchangeName}
                show={showCU}
                type={type}
                allConditions={conditionFields}
                setType={setType}
                updated={updated}
                close={() => {
                    setUpdated({id: null, data: null});
                    setType("");
                    setShowCU(false);
                }}
            />
        </div>

    );
};

const mapStateToProps = state => {
    return {
        meta: state.meta,
    };
};

export default connect(mapStateToProps)(NewsAlert);
