import {WATCHLISTS, WATCHPAIRS, WATCHSELECTED} from '../actions/types';

let INITIAL_STATE = {
  list: [],
  pairs: [],
  selected: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WATCHLISTS:
      return {
        ...state,
        list: action.value,
      };
    case WATCHPAIRS:
      return {
        ...state,
        pairs: action.value,
      };
    case WATCHSELECTED:
      return {
        ...state,
        selected: action.value,
      };
    default:
      return {
        ...state,
      };
  }
};
