import React, {useEffect, useState} from 'react';
import Utils from "../../helpers/Utils";

const UpgradeYourPlan = props => {
    const [url,setUrl]= useState(Utils.isMarketMonitor());

  return (
    <section style={{textAlign: 'center', minHeight: "80vh", marginTop: "10px", backgroundImage: "url('/img/blur_upgrade_your_plan.png')"}} className="contact-area padding-sm-container">
        <div className="general-header"><h2>Upgrade Your Plan</h2></div>
      <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', color: '#fff'}} className="contact-form-and-banners">
        <h4>Access to many features, analysis and increased quotas for alerts, watchlists and trading.</h4>
        <h3>
            <a style={{cursor: "pointer"}} target="_blank" rel="noopener noreferrer" href={"https://account.cryptoindexseries.com/plans-prices/"+Utils.checkRedirectUrlPlanAndPrices(url.title)}>Upgrade Your Plan</a>
        </h3>
      </div>
    </section>
  );
};

export default UpgradeYourPlan;
