import React, {useEffect, useState} from "react";
import Api from "../../../api";
import Loading from "../../../components/Loading";
import {connect} from "react-redux";
import Paginate from "../../../components/Paginate";
import StateBarIndex from "../../../components/Indices/StateBarIndex";
import Select from "../../../components/Forms/Select";
import DataMappers from "../../../helpers/DataMappers";
import CoinTable from "../../../components/CoinTable/CoinTable";

// Page size selections
const pages = [
    {value: 10, label: '10'},
    {value: 20, label: '20'},
    {value: 50, label: '50'}
];

const SectorCoins = props => {
    const {
        rawData, // Raw sector information
        exchange, // Exchange information
    } = props;

    // Indicates if any fetching process is running
    const [loading, setLoading] = useState(true);
    // Index data of the sector
    const [indexCode, setIndexCode] = useState(rawData && rawData.main_index_benchmark_code);
    // List of coins for the sector
    const [coinData, setCoinData] = useState([]);

    // Table related information variables
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [sort, setSort] = useState({i: 'mkt_cap', s: 'desc'});

    // Refresh index information
    useEffect(() => {
        // Fetch main index if defined
        if (rawData && rawData.main_index_benchmark_code) {
            setIndexCode(rawData.main_index_benchmark_code)
        }
    }, [rawData]);

    // Refresh coin information
    useEffect(() => {
        // Fetch coins if raw data is defined
        if (rawData && rawData.id) {
            fetchCoins(rawData.id)
        }
    }, [rawData, pageSize, sort, currentPage]);

    // Fetches list of coins for the sector
    const fetchCoins = (sectorId) => {
        setLoading(true);
        Api.searchCoins({order_by: `${sort.i},${sort.s}`, business_classification: sectorId, ccy: exchange.value, page_size: pageSize, page_number: currentPage}, true)
            .then(res => {
                // Map ticker values for visualisation
                const mapped = res.data.map(DataMappers.coinDataMapper);

                // Set state variables
                setCoinData(mapped);
                setLoading(false);
                setTotalPage(res.totalPage);
            });
    };

    // Handles pager clicks
    const handlePageClick = d => {
        if (currentPage !== d.selected + 1) {
            setCurrentPage(d.selected + 1);
        }
    };

    return (
        <section className="coin-table coin-table-mobile" style={{"marginTop": "10px"}}>
            <div className="gray-container hide-content" style={{"marginBottom" : "20px"}}>
                <div className="section-header" style={{"marginBottom" : "1.5em"}}>Main Benchmark Index</div>
                {indexCode ? <StateBarIndex clickable={true} indexCode={indexCode} selectedExchange={exchange}/> :
                <div className={"summary-par"} style={{"color" : "white", "paddingTop" : "2em", "paddingBottom" : "2em"}}>
                    There is no index created for this sector yet...
                </div> }
            </div>
            <div className="gray-container sector-summary-mobile" style={{display: "flex", flexDirection: "column"}}>
                <div className="table-head">
                    <div className="clearfix" />
                    <div className="table-left"><div className="section-header">Sector Coins</div></div>
                    <div className="table-right">
                        <div className="table-select-input">
                            <div className="sm-select-input small-select">
                                <Select selected={pageSize} options={pages} onChange={selected => setPageSize(selected.value)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-body table-responsive" id="beauty-scroll7">
                    <Loading loading={loading}>
                        <CoinTable coinData={coinData} exchange={exchange} sort={sort} setSort={setSort} disabledColumns={["weight", "name"]}/>
                    </Loading>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: totalPage > 1 ? '0' : '2em'}}>
                        {totalPage > 1 && <Paginate pageCount={totalPage} onPageChange={handlePageClick} active={currentPage - 1} />}
                    </div>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = state => {
    return {
        user: state.user,
    };
};


export default connect(mapStateToProps)(SectorCoins);
