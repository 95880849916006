import React, {useState, useEffect, useRef} from 'react';
import Loading from '../Loading';
import Select from '../Forms/Select';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import Api from '../../api';
import {connect} from 'react-redux';
import Paginate from '../Paginate';
import {useAuth0} from "../../helpers/Auth0";
import Utils from "../../helpers/Utils";
import IndicesTable from "../Indices/IndicesTable";

const isMobile = Utils.checkMobile();

const pages = [
    {value: 10, label: '10'},
    {value: 20, label: '20'},
    {value: 50, label: '50'},
];

const IndicesDeFi = (props, context) => {
    const {t} = context;
    const subListRef = useRef({});
    const {user} = useAuth0()
    const {filter, setWatchListModal, containerType, meta, setLoadingP, defi, setDFilter, dfilter} = props;
    const [exchange, setExchange] = useState(user.preferences.currency);
    const [result, setResult] = useState({items: [], loading: true});
    const [resultTotal, setResultTotal] = useState({items: []});
    const [pageInfo, setPageInfo] = useState({page: 1, totalPage: 0});
    const [pageSize, setPageSize] = useState(10);
    const [selectedExchange, setSelectedExchange] = useState({});
    const [sort, setSort] = useState({i: 'mkt_cap', s: 'desc'});


    const filterParam = filterT => ({
        page_size: 100,
        asset_type: filterT.asset || '',
        coin_symbols: filterT.coin || '',
        business_classification: filterT.sector || '',
        ccy: filterT.ccy,
        search_string: filterT.keyword || '',
        mkt_cap_min: (filterT.cap && filterT.cap.min) || '',
        mkt_cap_max: (filterT.cap && filterT.cap.max) || '',
        total_coin_volume_24h_min: (filterT.volume && filterT.volume.min) || '',
        total_coin_volume_24h_max: (filterT.volume && filterT.volume.max) || '',
        chgpct_24h_min: filterT.chg && filterT.chg.min === "0" ? 0 : (filterT.chg && filterT.chg.min / 100) || '',
        chgpct_24h_max: filterT.chg && filterT.chg.max === "0" ? 0 : (filterT.chg && filterT.chg.max / 100) || '',
        ts_cutoff: 0,
        page_number: filterT.page || 1,
        mineable: filterT.mineable,
        stablecoin: filterT.stablecoin,
        market_cap_category: filterT.market_cap_category,
        volume_category: filterT.volume_category,
        volatility_category: filterT.volatility_category,
        is_defi: defi
    });

    const currencyList =
        (meta.lists &&
            meta.lists.key_currency_list &&
            meta.lists.key_currency_list.map(c => ({
                value: c[0],
                label: c[0],
                type: c[2] === 'FIAT' ? 'USD' : 'BTC'
            }))) ||
        [];

    useEffect(() => {
        let selectedExchange = currencyList.filter(e => e.value === exchange);
        selectedExchange = (selectedExchange.length && selectedExchange[0]) || {};
        setSelectedExchange(selectedExchange);
    }, [exchange]);

    useEffect(() => {
        const newArray = sortDeFi(resultTotal.items);
        setResult({items: newArray.slice((pageInfo.page - 1) * pageSize, (pageInfo.page * pageSize)), loading: false});
    }, [sort.i, sort.s]);

    useEffect(() => {
        filter.ccy = exchange;
        fetchData(filter);
    }, [pageSize, exchange, dfilter.search]);

    useEffect(() => {
        setLoadingP(result.loading);
    }, [result.loading]);

    const fetchData = f => {
        setResult({items: [], loading: true});
        setPageInfo({page: 1, totalPage: 0});
        setTimeout(() => {
            Api.getPromise('/Search/CoinSearch', filterParam(f))
                .then(res => {
                    const c = pageSize || 10;
                    let data = (res && res.data) || [];
                    if (dfilter.inputTvlMax !== "") {
                        data = data.filter(el =>
                            el.defi_info.tvl_usd < parseInt(dfilter.inputTvlMax)
                        );
                    }
                    if (dfilter.inputTvlMin !== "") {
                        data = data.filter(el =>{
                                return el.defi_info.tvl_usd > parseInt(dfilter.inputTvlMin);
                        }
                        );
                    }
                    setResultTotal({items: data});
                    setResult({
                        items: sortDeFi(data.slice(0, pageSize)),
                        loading: false
                    });
                    setPageInfo({page: f.page || 1, totalPage: Math.ceil(data.length / c) || 0});
                })
                .catch(e => setResult({items: [], loading: false}));
        }, 500);
    };

    const handlePageClick = d => {
        let f = {...filter};
        if (pageInfo.page !== d.selected + 1) {
            f.page = d.selected + 1;
            setPageInfo({...pageInfo, page: f.page});
            const newArray = resultTotal.items.slice((f.page - 1) * pageSize, (f.page * pageSize));
            setResult({
                items: sortDeFi(newArray),
                loading: false
            });
        }
    };

    const sortDeFi = (arr) => {
        if (sort.i === "mkt_cap") {
            if (sort.s === 'desc') {
                return arr.sort((el1, el2) => el2.ticker.market_cap - el1.ticker.market_cap);
            } else {
                return arr.sort((el1, el2) => el1.ticker.market_cap - el2.ticker.market_cap);
            }
        } else if (sort.i === "chgpct") {
            if (sort.s === 'desc') {
                return arr.sort((el1, el2) => el2.ticker.chgpct - el1.ticker.chgpct);
            } else {
                return arr.sort((el1, el2) => el1.ticker.chgpct - el2.ticker.chgpct);
            }
        } else if (sort.i === "defi_tvl") {
            if (sort.s === 'desc') {
                return arr.sort((el1, el2) => el2.defi_info.tvl_usd - el1.defi_info.tvl_usd);
            } else {
                return arr.sort((el1, el2) => el1.defi_info.tvl_usd - el2.defi_info.tvl_usd);
            }
        }
    }

    const sortChange = index => (sort.s === 'desc' ? {i: index, s: 'asc'} : {i: index, s: 'desc'});

    const sortArrow = index => sort.i === index &&
        <i style={{fontSize: 12}} className={`fa fa-angle-${sort.s === 'desc' ? 'up' : 'down'}`}/>;

    let selectedSize = pages.filter(p => p.value === pageSize);
    selectedSize = (selectedSize.length && selectedSize[0]) || {};

    return (
        isMobile ?
            <div className={`gray-container ${containerType}`}>
                <div className="table-head">
                    <div className="table-left">
                        <div className="table-header">OVERVIEW</div>
                    </div>
                    <div className="table-right">
                        <div className="table-select-input">
                            <div className="sm-select-input small-select">
                                <Select selected={exchange} options={currencyList}
                                        onChange={selected => setExchange(selected.value)}/>
                            </div>
                        </div>

                        <div className="table-select-input">
                            <div className="sm-select-input small-select">
                                <Select selected={pageSize} options={pages}
                                        onChange={selected => setPageSize(selected.value)}/>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"/>
                </div>
                <div className="table-body table-responsive" id="beauty-scroll7">
                    <Loading loading={result.loading}>
                        <ReactTooltip html={true}/>
                        <table className="coins enumarable-table coins-menu-table linked-table">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Price</th>
                                <th onClick={() => setSort(sortChange('mkt_cap'))} style={{cursor: 'pointer'}}>
                                    Mkt. Cap {isMobile ? null : <i data-html={true} data-tip={t('captDesc')}
                                                                   className="fas fa-info-circle"/>} {isMobile ? null : sortArrow('mkt_cap')}
                                </th>
                                <th>Circ. Supply</th>
                                <th>Max. Supply</th>
                                <th onClick={() => setSort(sortChange('vto'))} style={{cursor: 'pointer'}}>
                                    24h Vol
                                    <i data-tip={t('totalVDesc')} className="fas fa-info-circle"/> {sortArrow('vto')}
                                </th>
                                <th onClick={() => setSort(sortChange('chgpct'))} style={{cursor: 'pointer'}}>
                                    24h Change% {isMobile ? null : sortArrow('chgpct')}
                                </th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <IndicesTable data={result.items} setWatchListModal={setWatchListModal}
                                          selectedExchange={selectedExchange}/>
                        </table>
                    </Loading>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Paginate pageCount={pageInfo.totalPage} onPageChange={handlePageClick}
                                  active={pageInfo.page - 1}/>
                    </div>
                </div>
            </div>
            :
            <div className={`gray-container ${containerType}`}>
                <div className="table-head">
                    <div className="table-left">
                        <div className="table-header">OVERVIEW</div>
                    </div>
                    <div className="table-right">
                        <div className="table-select-input">
                            <div className="sm-select-input small-select">
                                <Select selected={exchange} options={currencyList}
                                        onChange={selected => setExchange(selected.value)}/>
                            </div>
                        </div>

                        <div className="table-select-input">
                            <div className="sm-select-input small-select">
                                <Select selected={pageSize} options={pages}
                                        onChange={selected => setPageSize(selected.value)}/>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"/>
                </div>
                <div className="table-body table-responsive" id="beauty-scroll7">
                    <Loading loading={result.loading}>
                        <ReactTooltip html={true}/>
                        <table className="coins enumarable-table coins-menu-table linked-table">
                            <thead>
                            {defi ?
                                <tr>
                                    <th>Name</th>
                                    <th>Price</th>
                                    <th onClick={() => setSort(sortChange('mkt_cap'))} style={{cursor: 'pointer'}}>
                                        Mkt. Cap {isMobile ? null : <i data-html={true} data-tip={t('captDesc')}
                                                                       className="fas fa-info-circle"/>} {isMobile ? null : sortArrow('mkt_cap')}
                                    </th>
                                    <th onClick={() => setSort(sortChange('chgpct'))} style={{cursor: 'pointer'}}>
                                        24h Change% {isMobile ? null : sortArrow('chgpct')}
                                    </th>
                                    <th>DeFi Category</th>
                                    <th>DeFi Chain</th>
                                    <th onClick={() => setSort(sortChange('defi_tvl'))} style={{cursor: 'pointer'}}>
                                        DeFi TVL {isMobile ? null : sortArrow('defi_tvl')}
                                    </th>
                                    <th style={{textAlign: "center"}}>Actions</th>
                                </tr>
                                :
                                <tr>
                                    <th>Name</th>
                                    <th>Price</th>
                                    <th onClick={() => setSort(sortChange('mkt_cap'))} style={{cursor: 'pointer'}}>
                                        Mkt. Cap {isMobile ? null : <i data-html={true} data-tip={t('captDesc')}
                                                                       className="fas fa-info-circle"/>} {isMobile ? null : sortArrow('mkt_cap')}
                                    </th>
                                    <th>Circ. Supply</th>
                                    <th>Max. Supply</th>
                                    <th onClick={() => setSort(sortChange('vto'))} style={{cursor: 'pointer'}}>
                                        24h Vol
                                        <i data-tip={t('totalVDesc')}
                                           className="fas fa-info-circle"/> {sortArrow('vto')}
                                    </th>
                                    <th onClick={() => setSort(sortChange('chgpct'))} style={{cursor: 'pointer'}}>
                                        24h Change% {isMobile ? null : sortArrow('chgpct')}
                                    </th>
                                    <th>Market Cap Category</th>
                                    <th>Volatility Category</th>
                                    <th>Volume Category</th>
                                    <th>Actions</th>
                                </tr>
                            }

                            </thead>
                            <IndicesTable data={result.items} setWatchListModal={setWatchListModal}
                                          selectedExchange={selectedExchange} defi={defi}/>
                        </table>
                    </Loading>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Paginate pageCount={pageInfo.totalPage} onPageChange={handlePageClick}
                                  active={pageInfo.page - 1}/>
                    </div>
                </div>
            </div>
    );
};
IndicesDeFi.contextTypes = {
    t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    return {
        meta: state.meta,
    };
};

export default connect(mapStateToProps)(IndicesDeFi);
