import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import CreateAndUpdateNewsConditionModal from "../CreateNewsAlert/CreateAndUpdateNewsConditionModal";
import {toastr} from "react-redux-toastr";
import AlertApi from "../../../api/AlertApi";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ReactTooltip from "react-tooltip";
import Select from "react-select";

const defaultAlert = () => {
    return (
        {
            name: "",
            conditions: [{
                coin: {value: "ALL", label: "ALL"},
                sector: {value: "ALL", label: "ALL"},
                assetType: {value: "ALL", label: "ALL"},
                exchange: {value: "ALL", label: "ALL"},
                category: {value: "ALL", label: "ALL"},
                nation: {value: "ALL", label: "ALL"},
                source: {value: "ALL", label: "ALL"},
                direction: {value: "above", label: "Above"},
                targetField: {label: "Title", value: 'title'},
                value: 0,
                sentiment: false
            }],
            id: "",
            webpn:false,
            email:false,
            is_perpetual:false,

        }
    )
}

const Perpetual = [
    {value:false,label:"One-Off"},
    {value:true,label:"Perpetual"}
]


const CryptoAlertModal = props => {
    const {
        show,
        close,
        type,
        newType,
        updated, // updated data
        getExchangeName, // provide exchange label name
        fetchData,
        allConditions // all conditions
    } = props;

    // Alert
    const [alert, setAlert] = useState(defaultAlert());
    // Alert Conditions
    const [conditions, setConditions] = useState(defaultAlert().conditions);
    // Loading Conditions
    const [loading, setLoading] = useState(true);
    // Const new type
    const [typeNew, setTypeNew] = useState("");
    // Perpetual
    const [perpetual,setPerpetual] = useState( {value:false,label:"One-Off"})

    const reset = () => {
        setConditions(defaultAlert().conditions)
    }

    const getValueFromList = (tag, key) => {
        let returnValue = "";
        allConditions[key].forEach(el=>{
            if(el.tag === tag){
                returnValue = el.value;
                return;
            }
        })
        return returnValue;
    }

    // Fill updated datas into alert conditions
    const getUpdateTags = (tags) => {
        let conditions = defaultAlert().conditions;
        tags.forEach(el => {
            switch (el.split("_")[0]) {
                case "COIN":
                    conditions[0].coin = {value: el.split("_")[1], label: el.split("_")[1], tag: el}
                    break;
                case "SECTOR":
                    let sectorValue = getValueFromList(el, "sectors")
                    conditions[0].sector = {value: sectorValue, label: sectorValue, tag: el}
                    break;
                case "ASSET":
                    let assetValue = getValueFromList(el, "assets")
                    conditions[0].assetType = {value: assetValue, label: assetValue, tag: el}
                    break;
                case "EXCHANGE":
                    let exchangeValue = getValueFromList(el, "exchanges")
                    conditions[0].exchange = {value: exchangeValue, label: exchangeValue, tag: el}
                    break;
                case "OTHER":
                    let otherValue = getValueFromList(el, "categories")
                    conditions[0].category = {value: otherValue, label: otherValue, tag: el}
                    break;
                case "LOCATION":
                    let locationValue = getValueFromList(el, "nations")
                    conditions[0].nation = {value: locationValue, label: locationValue, tag: el}
                    break;
                default:
                    break;
            }
        })
        return conditions;
    }


    useEffect(() => {
        if (type === "Update" && updated.id) {
            let alert = defaultAlert();
            alert.id = updated.id;
            alert.name = updated.data.alert_name;
            if (updated.data.alert_data[0].tag) {
                alert.conditions = getUpdateTags(updated.data.alert_data[0].tag);
            }
            if (updated.data.alert_data[0].source){
                allConditions.sources.forEach(el=>{
                    if(el.value === updated.data.alert_data[0].source){
                        alert.conditions[0].source = el;
                    }
                })
            }
            if (updated.data.alert_data[0].sentiment_target) {
                alert.conditions[0].value = (updated.data.alert_data[0].sentiment_target * 100).toFixed(2);
                alert.conditions[0].sentiment = true;
                alert.conditions[0].direction = {
                    value: updated.data.alert_data[0].sentiment_direction,
                    label: updated.data.alert_data[0].sentiment_direction.charAt(0).toUpperCase() + updated.data.alert_data[0].sentiment_direction.slice(1,updated.data.alert_data[0].sentiment_direction.length)
                };
                alert.conditions[0].targetField = {
                    value: updated.data.alert_data[0].sentiment_target_field,
                    label: updated.data.alert_data[0].sentiment_target_field.charAt(0).toUpperCase() + updated.data.alert_data[0].sentiment_target_field.slice(1,updated.data.alert_data[0].sentiment_target_field.length)
                };
            }
            updated.data.notification_device.forEach(el=>{
                if(el === "email"){
                    alert.email = true;
                }else if( el === "webpn"){
                    alert.webpn = true;
                }
            })
            Perpetual.forEach(el=>{
                if(el.value === updated.data.is_perpetual){
                    setPerpetual(el);
                }
            })
            setAlert(alert);
            setConditions(alert.conditions);
        } else if (!updated.id && type !== "Update") {
            setAlert(defaultAlert);
            setConditions(defaultAlert().conditions);
            setTypeNew(type);
        }
    }, [type])


    const handleChangeName = text => {
        let newAlert = {...alert};
        newAlert["name"] = text.target.value;
        setAlert(newAlert);
    };

    const handleCreate = () => {
        let newAlert = {...alert};
        newAlert.conditions = conditions;
        const control = controlConditions();
        if (newAlert.name === "") {
            toastr.error('', 'You must enter alert name.');
            return;
        }else if(!newAlert.webpn && !newAlert.email ){
            toastr.error('', 'You must select at least one notification option.');
            return;
        } else {
            if (control.flag) {
                const param = {
                    "alert_name": newAlert.name,
                    "alert_data": control.conditions,
                    "notification_device": control.notification_device,
                    "is_perpetual":perpetual.value
                }
                AlertApi.createNewsAlert(param).then(resp => {
                    fetchData();
                    toastr.success('', 'Successfully created alert.');
                    reset();
                    close();
                })
            } else {
                toastr.error('', 'You must enter target if you select sentiment!');
            }
        }
    }

    const handleUpdate = () => {
        let newAlert = {...alert};
        newAlert.conditions = conditions;
        const control = controlConditions();
        if (newAlert.name === "") {
            toastr.error('', 'You must enter alert name.');
            return;
        } else if(!newAlert.webpn && !newAlert.email ){
            toastr.error('', 'You must select at least one notification option.');
            return;
        } else {
            if (control.flag) {
                const param = {
                    "alert_name": newAlert.name,
                    "alert_data": control.conditions,
                    "notification_device": control.notification_device,
                    "is_perpetual":perpetual.value
                }
                AlertApi.updateNewsAlert(updated.id,param).then(resp => {
                    fetchData();
                    toastr.success('', 'Successfully updated alert.');
                    reset();
                    close();
                })
            } else {
                toastr.error('', 'You must enter target if you select sentiment!');
            }
        }
    }

    // Get Tags from conditions
    const getTags = (conditions) => {
        const tags = [];
        Object.keys(conditions[0]).forEach(el => {
            if (conditions[0][el].tag) {
                tags.push(conditions[0][el].tag)
            }
        })
        return tags.length > 0 ? tags : null;
    }

    // Convert sentiment target % to float
    const convSentimentValue = (value) => {
        return value / 100;
    }

    // Control conditions field
    const controlConditions = () => {
        let control = {flag: true};
        let newConditions = [];
        conditions.forEach(el => {
            if (el.sentiment) {
                if (el.value === 0) {
                    control = {flag: false};
                } else {
                    let newCondition = {
                        "tag": getTags(conditions),
                        "source": el.source.value ? el.source.value : null,
                        "sentiment_target_field": el.targetField.value,
                        "sentiment_target": convSentimentValue(parseFloat(el.value)),
                        "sentiment_direction": el.direction.value
                    }
                    newConditions.push(newCondition);
                }
            } else {
                let newCondition = {
                    "tag": getTags(conditions),
                    "source": el.source.value ? el.source.value : null,
                    "sentiment_target_field": null,
                    "sentiment_target": null,
                    "sentiment_direction": null
                }
                newConditions.push(newCondition);
            }
        })
        control.notification_device = [];
        if(alert.email){
            control.notification_device.push("email");
        }
        if(alert.webpn){
            control.notification_device.push("webpn");
        }
        control.conditions = newConditions;
        return control;
    }

    const handleNotification = (type) =>{
        let newAlert = {...alert}
        newAlert[type] = !newAlert[type];
        setAlert(newAlert);
    }

    return (
        <div className={`general-modal ${show ? 'active' : ''}`} id="set-price-alert">
            <div className="vAlignWr">
                <div className="vAlign">
                    <div className="white-modal-inner set-alert">
                        <div className="modal-blue-header" style={{height:"60px"}}>
                            <div onClick={close} className="close-modal" data-target="set-price-alert">
                                <img src="img/close-white.svg" alt=""/>
                            </div>
                        </div>
                        <div className="modal-white-body" style={{width: "1330px"}}>
                            <div className="gray-container alerts-price-alert"
                                 style={{display: "flex", flexDirection: "column", justifyContent: "space-between",marginTop:"0px"}}>
                                <div className="row" style={{padding: "5px 0px"}}>
                                    <div className="col-lg-12" style={{padding: "0px 15px"}}>
                                        <h4 style={{
                                            color: "white",
                                            textAlign: "center",
                                            fontSize: "20px"
                                        }}>{newType.label + " News Alert"}</h4>
                                        <hr style={{borderTop: "1px solid #3c4145"}}></hr>
                                    </div>
                                    <div className="col-lg-12" style={{padding: "0px 15px"}}>
                                        <div style={{display:"flex", padding: "0px 10px"}}>
                                            <div className="filter-bar-input1 " style={{padding: "0px"}}>
                                                <TextField
                                                    size={"small"}
                                                    label="Alert Name"
                                                    margin="normal"
                                                    variant="outlined"
                                                    onChange={handleChangeName}
                                                    value={alert.name}
                                                    fullWidth
                                                />
                                            </div>
                                        </div>
                                        <hr style={{borderTop: "1px solid #3c4145"}}></hr>
                                    </div>
                                    <div className="col-lg-12"
                                         style={{padding: "0px 15px", marginTop: "10px", display: "flex"}}>
                                        <ReactTooltip html={true}/>
                                        <a style={{marginTop: "7px", marginLeft: "5px", cursor: "pointer"}}><img
                                            className="actionable-img"
                                            alt=""/>
                                            {
                                                conditions.length < 3 &&
                                                <i onClick={reset} className="fas fa-redo-alt" style={{
                                                    fontSize: "1.5em",
                                                    color: "#16adcf"
                                                }} data-tip={'Reset Condition'}/>
                                            }
                                        </a>
                                        <h4 style={conditions.length >= 3 ?
                                            {
                                                color: "white",
                                                fontSize: "16px",
                                                padding: "0px 76px"
                                            }
                                            : {
                                                color: "white",
                                                fontSize: "16px",
                                                padding: "0px 12px"
                                            }}>{"Conditions:"}</h4>

                                    </div>
                                    <div className="col-lg-12" style={{padding: "0px 15px", marginTop: "10px"}}>
                                        <hr style={{borderTop: "1px solid #3c4145", marginTop: "0px"}}></hr>
                                        {conditions.length > 0 && conditions.map((el, index) => {
                                            return (
                                                <div key={index}>
                                                    <CreateAndUpdateNewsConditionModal
                                                        conditionsFields={conditions}
                                                        setConditionsFields={setConditions}
                                                        index={index} type={typeNew}
                                                        setType={setTypeNew}
                                                        allConditions={allConditions}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="col-lg-12" style={{padding: "0px 25px", marginTop: "10px"}}>
                                        <div className="input-title">Frequency:</div>
                                        <div style={{padding: "0px", width: "170px"}}>
                                            <Select
                                                value={perpetual}
                                                onChange={(e) => setPerpetual(e)}
                                                options={Perpetual}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div style={{justifyContent: "flex-end"}}>
                                    <div className="pa-inner"  style={{display:"flex",justifyContent:"space-between"}}>
                                        <div style={{display:"flex",justifyContent:"space-between",marginLeft: "10px"}}>
                                            <div style={{color:"white",fontSize:"16px",marginTop: "25px"}}>
                                                Notifications:
                                            </div>
                                            <div style={{display:"flex",marginLeft:"15px"}}>
                                                <div className="spa-text" style={{textAlign:"left", marginTop: "25px",marginRight:"0px",color:"white"}}>Web</div>
                                                <div className="alertSentiment" style={{marginLeft: "10px", marginTop: "15px"}}>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={alert.webpn}
                                                                    onChange={()=>handleNotification("webpn")}
                                                                    color="primary"
                                                                    value="sentiment"
                                                                />
                                                            }
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div style={{display:"flex"}}>
                                                <div className="spa-text" style={{textAlign:"left", marginTop: "25px",marginRight:"0px",color:"white"}}>Email</div>
                                                <div className="alertSentiment" style={{marginLeft: "10px", marginTop: "15px"}}>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={alert.email}
                                                                    onChange={()=>handleNotification("email")}
                                                                    color="primary"
                                                                    value="sentiment"
                                                                />
                                                            }
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </div>
                                        {newType.label === "Create" ?
                                            <div style={{display: "flex", justifyContent: "flex-end"}}>
                                                <div className="green-border-btn">
                                                    <a onClick={close} style={{cursor: "pointer"}}>
                                                        Close
                                                    </a>
                                                </div>
                                                <div style={{marginLeft: "5px"}} className="green-border-btn">
                                                    <a style={{cursor: "pointer"}} onClick={handleCreate}>
                                                        Create Alert
                                                    </a>
                                                </div>
                                            </div>
                                            :
                                            <div style={{display: "flex", justifyContent: "flex-end"}}>
                                                <div className="green-border-btn">
                                                    <a onClick={() => {
                                                        close();
                                                    }} style={{cursor: "pointer"}}>
                                                        Close
                                                    </a>
                                                </div>
                                                <div style={{marginLeft: "5px"}} className="green-border-btn">
                                                    <a onClick={handleUpdate} style={{cursor: "pointer"}}>
                                                        Update Alert
                                                    </a>
                                                </div>
                                            </div>

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};


const prepareInputProps = state => {

    return {
        exchanges: state.meta.exchanges
    };
};

export default connect(prepareInputProps)(CryptoAlertModal);
