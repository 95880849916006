import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {toastr} from 'react-redux-toastr';
import Language from '../../constants/Language';
import {setLoading} from '../../actions/generalAction';
import UserApi from "../../api/UserApi";
import {useAuth0} from "../../helpers/Auth0";
import Select from "../../components/Forms/Select";
import Utils from "../../helpers/Utils"
import MobileNavBar from "../../components/MobileNavBar/MobileNavBar";
import ToggleButton from 'react-toggle-button'
import {Link} from "react-router-dom";
import DeleteProfileModal from "../../components/Modals/UserModal/DeleteProfileModal";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const isMobile = Utils.checkMobile();

const lineTypes = [
  {name: "None", value: null},
  {name: "Bars", value: 0},
  {name: "Candles", value: 1},
  {name: "Line", value: 2},
  {name: "Area", value: 3},
  {name: "Heiken Ashi", value: 8},
  {name: "Hollow Candles", value: 9},
  {name: "Baseline", value: 10}
]

const sentimentTypes = [
  {name:"Title Sentiment", value:'title_sentiment'},
  {name:"Summary Sentiment", value:'summary_sentiment'},
  {name:"Content Sentiment", value:'content_sentiment'}
]

const Profile = (props, context) => {
  const [show, setShow] = useState(false);
  const {setFullLoading, meta} = props;
  const {lists} = meta || {};
  const currencyList =
      (lists && lists.key_currency_list && lists.key_currency_list.map(c => ({value: c[0], label: c[0], type: c[2] === 'FIAT' ? 'USD' : 'BTC'}))) || [];
  const {setUser} = useAuth0();
  const [form, setForm] = useState({});
  const [formSocial, setFormSocial] = useState({});
  const [formPref, setFormPref] = useState({});
  const [file, setFile] = useState(null);
  const messages = Language.en.messages.INVALID_INPUT_ERRORS;

  useEffect(() => {
    setLoading(true);
    fetchUser();
  }, []);

  const fetchUser = () => {
    UserApi.getProfile().then(res => {
      setForm(res);
      setFormSocial(res.social);
      setFormPref(res.preferences);
      setLoading(false);
    });
  };

  const setLoading = status => {
    setFullLoading(status);
  };

  const onChangeForm = (type, val) => {
    let newForm = {...form};
    newForm[type] = val;
    setForm(newForm);
  };

  const onChangeFormSocial = (type, val) => {
    let newForm = {...formSocial};
    newForm[type] = val;
    setFormSocial(newForm);
  };

  const onChangePrefs = (type, val) => {
    let newForm = {...formPref};
    newForm[type] = val;
    setFormPref(newForm);
  }

  const onSave = () => {
    setLoading(true);
    form.social = formSocial;
    form.preferences = formPref

    UserApi.updateProfile(form).then(res => {
      setLoading(false);
      toastr.success('', Language.en.messages.SAVE_SUCCESS);
      setForm(res);
      setFormSocial(res.social);
      setFormPref(res.preferences);
      setUser(res);
    }).catch(err => {
      setLoading(false);
      toastr.warning('', messages.username_uniq);
    });
  };

  const selectImage = f => {
    setLoading(true);
    var fr = new FileReader();
    fr.onload = function() {
      document.getElementById('userAvatarImg').src = fr.result;
    };
    fr.readAsDataURL(f[0]);
    setFile(f[0]);
    
    if (f[0].type !== "image/jpeg" && f[0].type !== "image/jpg" && f[0].type !== "image/png") {
      toastr.error('', 'Images can only be jpeg, jpg or png types...')
      document.getElementById('userAvatarImg').src = form.picture
      setLoading(false);
    } else {
      UserApi.uploadProfilePic(f[0]).then(res => {
        setForm(res);
        setFormSocial(res.social);
        setFormPref(res.preferences);
        setUser({...res});
        setLoading(false);
        toastr.success('', 'Image successfully uploaded...')
      }).catch(err => {
        toastr.error('', 'Something went wrong while uploading the image...')
      })
    }
  };


  return (

      isMobile?
          <div>
            <MobileNavBar/>
            <div className="mobile-Div mobile-Div-Overflowx">
              <section className="profile padding-container">
                <div className="profile-head">
                  <div className="profile-info">
                    <div className="user-img" id="user-img">
                      <img id={'userAvatarImg'} src={form.picture || '/img/user-default.svg'} alt="" />
                      <div className="edit-img-btn">
                        <label htmlFor="profile-img">
                          <span className="upload-btn-text">
                            <img className="actionable-img" src="/img/edit.svg" alt="" />
                          </span>
                          <input type="file" id="profile-img" onChange={e => selectImage(e.target.files)} accept="image/png,image/jpeg,image/jpg" />
                        </label>
                      </div>
                    </div>
                    <div className="user-name">{form.username}</div>
                  </div>
                </div>
                <div className="profile-info-detail">
                  <div className="profile-settings">
                    <div className="ps-head">Profile Settings</div>
                    <div className="ps-input-area">
                      <form autoComplete={'off'}>
                        <div className="border-bottom-input profile text-input floating-label-wrap">
                          <input
                              type="text"
                              className="floating-label-field floating-label-field--s1"
                              placeholder=" "
                              value={form.given_name}
                              onChange={val => onChangeForm('given_name', val.target.value)}
                          />
                          <label className="floating-label">Name</label>
                        </div>
                        <div className="border-bottom-input profile text-input floating-label-wrap">
                          <input
                              type="text"
                              className="floating-label-field floating-label-field--s1"
                              placeholder=" "
                              value={form.family_name}
                              onChange={val => onChangeForm('family_name', val.target.value)}
                          />
                          <label className="floating-label">Surname</label>
                        </div>
                        <div className="border-bottom-input profile text-input floating-label-wrap">
                          <input
                              type="text"
                              className="floating-label-field floating-label-field--s1"
                              placeholder=" "
                              value={form.username}
                              onChange={val => onChangeForm('username', val.target.value)}
                          />
                          <label className="floating-label">Username</label>
                        </div>
                        <div className="border-bottom-input profile text-input floating-label-wrap">
                          <input type="text" placeholder="Email"  className="floating-label-field floating-label-field--s1" disabled value={form.email} />
                          <label className="floating-label">Email</label>
                        </div>
                        <div className="border-bottom-input profile text-input floating-label-wrap">
                          <textarea
                              cols={30}
                              rows={5}
                              className="floating-label-field floating-label-field--s1"
                              placeholder=" "
                              value={form.details}
                              onChange={val => onChangeForm('details', val.target.value)}
                          />
                          <label className="floating-label">Personal detail information …</label>
                        </div>
                      </form>
                    </div>
                    <div className="white-btn" style={{marginTop: "50px",width:"100%"}}>
                      <button onClick={onSave} className="skew-anim-black">
                        SAVE
                      </button>
                    </div>
                  </div>
                  <div className="notification-settings" style={{display: "flex", flexDirection: "column"}}>
                    <div className="section-header" style={{marginBottom: "20px", fontSize: "20px"}}>Social Network Settings</div>
                    <div className="ns-input-area">
                      <form autoComplete={'off'}>
                        <div className="border-bottom-input profile floating-label-wrap">
                          <div className="ns-img-area">
                            <img className="actionable-img" src="/img/logo-g-twitter.svg" alt="" />
                          </div>
                          <input
                              type="text"
                              className="floating-label-field floating-label-field--s1"
                              placeholder=" "
                              value={formSocial.twitter}
                              onChange={val => onChangeFormSocial('twitter', val.target.value)}
                          />
                          <label style={{marginLeft: 45}} className="floating-label">
                            Twitter profile link
                          </label>
                        </div>
                        <div className="border-bottom-input profile floating-label-wrap">
                          <div className="ns-img-area">
                            <img className="actionable-img" src="/img/logo-g-linkedin.svg" alt="" />
                          </div>
                          <input
                              type="text"
                              className="floating-label-field floating-label-field--s1"
                              placeholder=" "
                              value={formSocial.linkedin}
                              onChange={val => onChangeFormSocial('linkedin', val.target.value)}
                          />
                          <label style={{marginLeft: 45}} className="floating-label">
                            Linkedin profile link
                          </label>
                        </div>
                        <div className="border-bottom-input profile floating-label-wrap">
                          <div className="ns-img-area">
                            <img className="actionable-img" src="/img/logo-g-instagram.svg" alt="" />
                          </div>
                          <input
                              type="text"
                              className="floating-label-field floating-label-field--s1"
                              placeholder=" "
                              value={formSocial.instagram}
                              onChange={val => onChangeFormSocial('instagram', val.target.value)}
                          />
                          <label style={{marginLeft: 45}} className="floating-label">
                            Instagram profile link
                          </label>
                        </div>
                        <div className="border-bottom-input profile floating-label-wrap">
                          <div className="ns-img-area">
                            <img className="actionable-img" src="/img/logo-g-youtube.svg" alt="" />
                          </div>
                          <input
                              type="text"
                              className="floating-label-field floating-label-field--s1"
                              placeholder=" "
                              value={formSocial.youtube}
                              onChange={val => onChangeFormSocial('youtube', val.target.value)}
                          />
                          <label style={{marginLeft: 45}} className="floating-label">
                            Youtube profile link
                          </label>
                        </div>
                        <div className="border-bottom-input profile floating-label-wrap">
                          <div className="ns-img-area">
                            <img className="actionable-img" src="/img/logo-g-telegram.svg" alt="" />
                          </div>
                          <input
                              type="text"
                              className="floating-label-field floating-label-field--s1"
                              placeholder=" "
                              value={formSocial.telegram}
                              onChange={val => onChangeFormSocial('telegram', val.target.value)}
                          />
                          <label style={{marginLeft: 45}} className="floating-label">
                            Telegram profile link
                          </label>
                        </div>
                        <div className="border-bottom-input profile floating-label-wrap">
                          <div className="ns-img-area">
                            <img className="actionable-img" src="/img/logo-g-medium.svg" alt="" />
                          </div>
                          <input
                              type="text"
                              className="floating-label-field floating-label-field--s1"
                              placeholder=" "
                              value={formSocial.medium}
                              onChange={val => onChangeFormSocial('medium', val.target.value)}
                          />
                          <label style={{marginLeft: 45}} className="floating-label">
                            Medium profile link
                          </label>
                        </div>
                      </form>
                      <div className="white-btn" style={{marginTop: "50px"}}>
                        <button onClick={onSave} className="skew-anim-black">
                          SAVE
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="profile-settings">
                    <div className="ps-head">Application Preferences</div>
                    <div className="ps-input-area">
                      <form autoComplete={'off'}>
                        <div className="border-bottom-input floating-label-wrap">
                          <div className="sm-select-input floating-label-field floating-label-field--s1" style={{paddingLeft: "0px", paddingTop: "15px",zIndex: 10000}}>
                            <Select
                                selected={formPref.chart_type || null}
                                optionValue={'value'}
                                optionLabel={'name'}
                                options={lineTypes || []}
                                onChange={selected => onChangePrefs('chart_type', selected.value)}/>
                          </div>
                          <label className="floating-label">
                            Default Chart Type
                          </label>
                        </div>
                        <div className="border-bottom-input floating-label-wrap">
                          <div className="sm-select-input floating-label-field floating-label-field--s1" style={{paddingLeft: "0px", paddingTop: "15px", zIndex: 9999}}>
                            <Select
                                selected={formPref.currency || "USD"}
                                optionValue={'value'}
                                optionLabel={'label'}
                                options={currencyList || []}
                                onChange={selected => onChangePrefs('currency', selected.value)}/>
                          </div>
                          <label className="floating-label">
                            Default Currency
                          </label>
                        </div>
                        <div className="border-bottom-input floating-label-wrap">
                          <div className="sm-select-input floating-label-field floating-label-field--s1" style={{paddingLeft: "0px", paddingTop: "15px", zIndex: 1}}>
                            <Select
                                selected={formPref.sentiment_field || null}
                                optionValue={'value'}
                                optionLabel={'name'}
                                options={sentimentTypes || []}
                                onChange={selected => onChangePrefs('sentiment_field', selected.value)}/>
                          </div>
                          <label className="floating-label">
                            Default News Sentiment Analysis Type
                          </label>
                        </div>
                        <div className="border-bottom-input floating-label-wrap">
                          <div className="sm-select-input floating-label-field floating-label-field--s1" style={{paddingLeft: "0px", paddingTop: "15px", zIndex: 0}}>
                            <ToggleButton
                                value={formPref.show_watchlist}
                                onToggle={(value) => {
                                  onChangePrefs('show_watchlist', !value);
                                }} />
                          </div>
                          <label className="floating-label">
                            Watchlist Slider
                          </label>
                        </div>
                        <div style={{display:"flex"}}>
                          <div className="alertSentiment" style={{marginLeft: "10px", marginTop: "25px"}}>
                            <FormGroup>
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                        checked={formPref.newsletter_check ||false}
                                        onChange={()=> onChangePrefs('newsletter_check', !formPref.newsletter_check)}
                                        color="primary"
                                    />
                                  }
                              />
                            </FormGroup>
                          </div>
                          <div className="spa-text" style={{textAlign:"left", marginTop: "25px",marginRight:"0px",color:"white"}}>I would like to receive newsletters and promotional material from CryptoIndexSeries</div>
                        </div>

                        {/*<div className="border-bottom-input floating-label-wrap">*/}
                        {/*  <Link to="/exchanges">Change Exchange Connections</Link>*/}
                        {/*</div>*/}
                      </form>
                      <div className="application-set-button">
                        <div className="border-bottom-input floating-label-wrap delete-div" style={{color:"#85858a"}}>
                          To Request Account Deletion,<br></br><a onClick={() => {{setShow(true)}}}>Click here</a>
                        </div>
                        <div className="white-btn" >
                          <button onClick={onSave}>
                            SAVE
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <DeleteProfileModal show={show}  cancel={() => setShow(false)}/>
              </section>
            </div>
            <div style={{height:"80px"}}>

              <p></p>
            </div>
          </div>
          :
          <section className="profile padding-container">
            <div className="profile-head">
              <div className="profile-info">
                <div className="user-img" id="user-img">
                  <img id={'userAvatarImg'} src={form.picture || '/img/user-default.svg'} alt="" />
                  <div className="edit-img-btn">
                    <label htmlFor="profile-img">
                      <span className="upload-btn-text">
                        <img className="actionable-img" src="/img/edit.svg" alt="" />
                      </span>
                      <input type="file" id="profile-img" onChange={e => selectImage(e.target.files)} accept="image/png,image/jpeg,image/jpg" />
                    </label>
                  </div>
                </div>
                <div className="user-name">{form.username}</div>
              </div>
            </div>
            <div className="profile-info-detail">
              <div className="profile-settings" style={{display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                <div>
                  <div className="ps-head">Profile Settings</div>
                  <div className="ps-input-area">
                    <form autoComplete={'off'}>
                      <div className="border-bottom-input profile text-input floating-label-wrap">
                        <input
                            type="text"
                            className="floating-label-field floating-label-field--s1"
                            placeholder=" "
                            value={form.given_name}
                            onChange={val => onChangeForm('given_name', val.target.value)}
                        />
                        <label className="floating-label">Name</label>
                      </div>
                      <div className="border-bottom-input profile text-input floating-label-wrap">
                        <input
                            type="text"
                            className="floating-label-field floating-label-field--s1"
                            placeholder=" "
                            value={form.family_name}
                            onChange={val => onChangeForm('family_name', val.target.value)}
                        />
                        <label className="floating-label">Surname</label>
                      </div>
                      <div className="border-bottom-input profile text-input floating-label-wrap">
                        <input
                            type="text"
                            className="floating-label-field floating-label-field--s1"
                            placeholder=" "
                            value={form.username}
                            onChange={val => onChangeForm('username', val.target.value)}
                        />
                        <label className="floating-label">Username</label>
                      </div>
                      <div className="border-bottom-input profile text-input floating-label-wrap">
                        <input type="text" placeholder="Email"  className="floating-label-field floating-label-field--s1" disabled value={form.email} />
                        <label className="floating-label">Email</label>
                      </div>
                      <div className="border-bottom-input profile text-input floating-label-wrap">
                      <textarea
                          cols={30}
                          rows={5}
                          className="floating-label-field floating-label-field--s1"
                          placeholder=" "
                          value={form.details}
                          onChange={val => onChangeForm('details', val.target.value)}
                      />
                        <label className="floating-label">Personal detail information …</label>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="white-btn" style={{marginTop: "90px", position: "inherit", bottom: 0, right: 0, marginLeft: "auto", marginBottom: 0}}>
                  <div style={{width: 100}} className="greenb-border-btn flRight">
                    <a style={{cursor: "pointer"}} onClick={onSave}>SAVE</a>
                  </div>
                </div>
              </div>
              <div className="notification-settings" style={{display: "flex", flexDirection: "column", justifyContent:"space-between"}}>
                <div>
                  <div className="section-header" style={{marginBottom: "20px", fontSize: "20px"}}>Social Network Settings</div>
                  <div className="ns-input-area">
                    <form autoComplete={'off'}>
                      <div className="border-bottom-input profile floating-label-wrap">
                        <div className="ns-img-area">
                          <img className="actionable-img" src="/img/logo-g-twitter.svg" alt="" />
                        </div>
                        <input
                            type="text"
                            className="floating-label-field floating-label-field--s1"
                            placeholder=" "
                            value={formSocial.twitter}
                            onChange={val => onChangeFormSocial('twitter', val.target.value)}
                        />
                        <label style={{marginLeft: 45}} className="floating-label">
                          Twitter profile link
                        </label>
                      </div>
                      <div className="border-bottom-input profile floating-label-wrap">
                        <div className="ns-img-area">
                          <img className="actionable-img" src="/img/logo-g-linkedin.svg" alt="" />
                        </div>
                        <input
                            type="text"
                            className="floating-label-field floating-label-field--s1"
                            placeholder=" "
                            value={formSocial.linkedin}
                            onChange={val => onChangeFormSocial('linkedin', val.target.value)}
                        />
                        <label style={{marginLeft: 45}} className="floating-label">
                          Linkedin profile link
                        </label>
                      </div>
                      <div className="border-bottom-input profile floating-label-wrap">
                        <div className="ns-img-area">
                          <img className="actionable-img" src="/img/logo-g-instagram.svg" alt="" />
                        </div>
                        <input
                            type="text"
                            className="floating-label-field floating-label-field--s1"
                            placeholder=" "
                            value={formSocial.instagram}
                            onChange={val => onChangeFormSocial('instagram', val.target.value)}
                        />
                        <label style={{marginLeft: 45}} className="floating-label">
                          Instagram profile link
                        </label>
                      </div>
                      <div className="border-bottom-input profile floating-label-wrap">
                        <div className="ns-img-area">
                          <img className="actionable-img" src="/img/logo-g-youtube.svg" alt="" />
                        </div>
                        <input
                            type="text"
                            className="floating-label-field floating-label-field--s1"
                            placeholder=" "
                            value={formSocial.youtube}
                            onChange={val => onChangeFormSocial('youtube', val.target.value)}
                        />
                        <label style={{marginLeft: 45}} className="floating-label">
                          Youtube profile link
                        </label>
                      </div>
                      <div className="border-bottom-input profile floating-label-wrap">
                        <div className="ns-img-area">
                          <img className="actionable-img" src="/img/logo-g-telegram.svg" alt="" />
                        </div>
                        <input
                            type="text"
                            className="floating-label-field floating-label-field--s1"
                            placeholder=" "
                            value={formSocial.telegram}
                            onChange={val => onChangeFormSocial('telegram', val.target.value)}
                        />
                        <label style={{marginLeft: 45}} className="floating-label">
                          Telegram profile link
                        </label>
                      </div>
                      <div className="border-bottom-input profile floating-label-wrap">
                        <div className="ns-img-area">
                          <img className="actionable-img" src="/img/logo-g-medium.svg" alt="" />
                        </div>
                        <input
                            type="text"
                            className="floating-label-field floating-label-field--s1"
                            placeholder=" "
                            value={formSocial.medium}
                            onChange={val => onChangeFormSocial('medium', val.target.value)}
                        />
                        <label style={{marginLeft: 45}} className="floating-label">
                          Medium profile link
                        </label>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="white-btn" style={{marginTop: "75px", position: "inherit", bottom: 0, right: 0, marginLeft: "auto", marginBottom: 0}}>
                  <div style={{width: 100}} className="greenb-border-btn flRight">
                    <a style={{cursor: "pointer"}} onClick={onSave}>SAVE</a>
                  </div>
                </div>
              </div>
              <div className="profile-settings">
                <div className="ps-head">Application Preferences</div>
                <div className="ps-input-area">
                  <form autoComplete={'off'}>
                    <div className="border-bottom-input floating-label-wrap">
                      <div className="sm-select-input floating-label-field floating-label-field--s1" style={{paddingLeft: "0px", paddingTop: "15px", zIndex: 10000}}>
                        <Select
                            selected={formPref.chart_type || null}
                            optionValue={'value'}
                            optionLabel={'name'}
                            options={lineTypes || []}
                            onChange={selected => onChangePrefs('chart_type', selected.value)}/>
                      </div>
                      <label className="floating-label">
                        Default Chart Type
                      </label>
                    </div>
                    <div className="border-bottom-input floating-label-wrap">
                      <div className="sm-select-input floating-label-field floating-label-field--s1" style={{paddingLeft: "0px", paddingTop: "15px", zIndex: 9999}}>
                        <Select
                            selected={formPref.currency || "USD"}
                            optionValue={'value'}
                            optionLabel={'label'}
                            options={currencyList || []}
                            onChange={selected => onChangePrefs('currency', selected.value)}/>
                      </div>
                      <label className="floating-label">
                        Default Currency
                      </label>
                    </div>
                    <div className="border-bottom-input floating-label-wrap">
                      <div className="sm-select-input floating-label-field floating-label-field--s1" style={{paddingLeft: "0px", paddingTop: "15px", zIndex: 1}}>
                        <Select
                            selected={formPref.sentiment_field || null}
                            optionValue={'value'}
                            optionLabel={'name'}
                            options={sentimentTypes || []}
                            onChange={selected => onChangePrefs('sentiment_field', selected.value)}/>
                      </div>
                      <label className="floating-label">
                        Default News Sentiment Analysis Type
                      </label>
                    </div>
                    <div className="border-bottom-input floating-label-wrap">
                      <div className="sm-select-input floating-label-field floating-label-field--s1" style={{paddingLeft: "0px", paddingTop: "15px", zIndex: 0}}>
                        <ToggleButton
                            value={formPref.show_watchlist}
                            onToggle={(value) => {
                              onChangePrefs('show_watchlist', !value);
                            }} />
                      </div>
                      <label style={{zIndex:"0"}} className="floating-label">
                        Watchlist Slider
                      </label>
                    </div>
                    <div style={{display:"flex"}}>
                      <div className="alertSentiment" style={{marginLeft: "10px", marginTop: "30px"}}>
                        <FormGroup>
                          <FormControlLabel
                              control={
                                <Checkbox
                                    checked={formPref.newsletter_check ||false}
                                    onChange={()=> onChangePrefs('newsletter_check', !formPref.newsletter_check)}
                                    color="primary"
                                    value="sentiment"
                                />
                              }
                          />
                        </FormGroup>
                      </div>
                      <div className="spa-text" style={{textAlign:"left", marginTop: "25px",marginRight:"0px",color:"rgb(133, 133, 138)"}}>I would like to receive newsletters and promotional material from CryptoIndexSeries</div>
                    </div>
                    <div className="border-bottom-input floating-label-wrap" style={{color:"#85858a"}}>
                      To Edit/Delete Exchange Connections, <Link to="/exchanges">Click here</Link>
                    </div>
                  </form>
                  <div className="application-set-button">
                    <div className="border-bottom-input floating-label-wrap delete-div" style={{color:"#85858a",marginTop:"5px"}}>
                      To Request Account Deletion, <a onClick={() => {{setShow(true)}}}>Click here</a>
                    </div>
                    <div className="white-btn" >
                      <div style={{width: 100}} className="greenb-border-btn flRight">
                        <a style={{cursor: "pointer"}} onClick={onSave}>SAVE</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DeleteProfileModal show={show}  cancel={() => setShow(false)}/>
          </section>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    setFullLoading: data => dispatch(setLoading(data)),
  };
};
const mapStateToProps = state => {
  return {
    meta: state.meta
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
