import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import {setLoading} from "../../actions/generalAction";
import {toastr} from "react-redux-toastr";
import PortfolioApi from "../../api/PortfolioApi";
import Loading from "../../components/Loading";
import {set} from "react-ga";
import PortfolioDelete from "./PortfolioDelete";
import {TextField} from "@material-ui/core";
import ReactTooltip from "react-tooltip";


const PortfolioManagement = props => {
    const {
        portfolioName, // Portfolio Name
        setPortfolioName, // Set Portfolio Name
        onSave, // Add porfolio name
        portfolios, // All portfolios
        myPortfolio, // Selected portfolio
        setMyPortfolio, // Set selected portfolio
        myPortfolioAccounts, // Selected portfolio binded accounts
        accounts, // All accounts
        moveAccount, // Bind account to portfolio
        moveBackAccount, // Remove account from portfolio
        getPortfolios,
        setPortfolios
    } = props;

    // Edit Portfolio
    const [editPortfolio,setEditPortfolio] = useState({});

    // Delete portfolio modal
    const [showD,setShowD] = useState(false);

    const handleEditName = (item) =>{
        let newPortfolio = {...portfolios};
        newPortfolio.data = newPortfolio.data.map(el=>{
            if(!el.show && el.id === item.id){
                el.show = !el.show;
            }
            return el;
        })
        setPortfolios(newPortfolio);
    }

    const handleRemoveEditName = (item) =>{
        let newPortfolio = {...portfolios};
        newPortfolio.data = newPortfolio.data.map(el=>{
            if(el.show && el.id === item.id){
                el.show = !el.show;
            }
            return el;
        })
        setPortfolios(newPortfolio);
    }

    const handleSave = (event,item) =>{
        let param = {
            name:event.target.value
        }
        PortfolioApi.editPortfolio(item.id,param).then(resp=>{
            toastr.success('', '\n' + 'Portfolio name successfully updated');
            let newPortfolio = {...portfolios};
            newPortfolio.data = newPortfolio.data.map(el=>{
                if(el.show && el.id === item.id){
                    el.show = !el.show;
                    el.name = param.name;
                }
                return el;
            })
            setPortfolios(newPortfolio);
            setShowD(false);
        })
    }


    return (
        <div className="general-tab-body-all">
            <div className="general-tab-body active" id="news-alert" style={{borderBottomLeftRadius: "5px",borderBottomRightRadius: "5px"}}>
                <div className="set-news-alert">
                    <div style={{color:"gray",padding:"15px"}}>
                        <i style={{marginRight:"5px"}} className="fas fa-info-circle"/>
                        Portfolios act as containers or groupings for accounts - you can map any of your accounts to any of your portfolios.
                        For example - you could map 3 different exchange accounts to a single portfolio
                    </div>
                    <hr style={{borderTop:"1px solid #191d21", marginTop:"0px",marginBottom:"0px"}}/>
                    <div className="row" style={{color:"white",marginRight:"0px",marginLeft:"0px"}}>
                        <div className="col-lg-4 col-sm-4" style={{height:"100%",backgroundColor:"rgb(31 35 40)",borderBottomLeftRadius: "5px",padding:"0px"}}>
                            <React.Fragment>
                                <div style={{padding:"0px 15px"}}>
                                    <h4 style={{fontSize:"16px",marginLeft:"2px"}}>My Portfolios</h4>
                                    <div style={{display:"flex"}}>
                                        <div className="bottom-border-input">
                                            <input type="text" placeholder="New Portfolio"
                                                   maxLength={50} value={portfolioName}
                                                   onChange={val => setPortfolioName(val.target.value)} onKeyPress={(e)=>{
                                                if(e.key === 'Enter'){
                                                    onSave();
                                                    setPortfolioName("");
                                                }
                                            }
                                            }/>
                                        </div>
                                        <div style={{cursor:"pointer"}} onClick={()=>{
                                            onSave();
                                            setPortfolioName("");
                                        }}>
                                            <i className="fas fa-plus-circle" style={{
                                                fontSize: "1.5em",
                                                color: "#7ac231",
                                                marginTop:"1px",
                                            }}/>
                                        </div>
                                    </div>
                                </div>
                                <hr style={{borderTop:"1px solid #191d21", marginTop:"10px",marginBottom:"0px"}}/>
                                <div style={{display:"flex",flexDirection:"column",height:"300px",overflowY: "auto"}}>
                                    <Loading loading={portfolios.loading}>
                                        {
                                            portfolios.data.length > 0 ?
                                                portfolios.data.map((el,index)=>{
                                                    return(
                                                        <div key={index}>
                                                            <div style={{display:"flex"}}>
                                                                <ReactTooltip html={true} />
                                                                {
                                                                    el.show ?

                                                                        <div style={{width:"100%",display:"flex"}} onClick={()=>setMyPortfolio(el)} className={myPortfolio.id === el.id ? "portfolios-div-active":"portfolios-div"}>
                                                                            <ReactTooltip html={true} />
                                                                            <TextField onKeyPress={(e)=>{
                                                                                if(e.key === 'Enter'){
                                                                                    handleSave(e,el);
                                                                                }
                                                                            }
                                                                            } id="standard-required" defaultValue={el.name} />
                                                                            <div style={{marginLeft: "10px",marginTop:"5px"}} data-html={true} data-tip={"Cancel"}>
                                                                                <a style={{cursor: "pointer"}} onClick={()=>{
                                                                                    handleRemoveEditName(el);
                                                                                }}><img
                                                                                    className="actionable-img"
                                                                                    alt=""/><i  className="far fa-window-close"
                                                                                               style={{
                                                                                                   fontSize: "1.5em",
                                                                                                   color: "#c11e0f"
                                                                                               }}/></a>
                                                                            </div>
                                                                        </div>

                                                                        :
                                                                        <div style={{width:"100%"}} onClick={()=>setMyPortfolio(el)} className={myPortfolio.id === el.id ? "portfolios-div-active":"portfolios-div"}>
                                                                            {el.name}
                                                                        </div>
                                                                }
                                                                <div style={{padding:"10px",borderRight:"1px solid #191d21"}}>
                                                                    <a style={{cursor: "pointer"}} onClick={()=>{
                                                                        handleEditName(el);
                                                                    }}><img data-html={true} data-tip={"Rename"} className="actionable-img" src="/img/edit.svg" alt="" />
                                                                        </a>
                                                                </div>
                                                                <div style={{padding:"10px",borderRight:"1px solid #191d21"}}>
                                                                    <a style={{cursor: "pointer"}} onClick={()=>{
                                                                        setEditPortfolio(el);
                                                                        setShowD(true);
                                                                    }}> <img data-html={true} data-tip={"Delete"} className="actionable-img" src="/img/delete.svg" alt=""/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <hr style={{borderTop:"1px solid #191d21", marginTop:"0px",marginBottom:"0px"}}/>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div style={{padding:"10px 20px"}}>
                                                    There is no created portfolio.
                                                </div>
                                        }
                                    </Loading>
                                </div>
                            </React.Fragment>
                        </div>
                        <div className="col-lg-8 col-sm-8" style={{height:"100%",borderBottomRightRadius: "5px",padding:"0px"}}>
                            <React.Fragment>
                                <div className="row" style={{marginLeft:"0px",marginRight:"0px"}}>
                                    <div  className="col-md-6 col-sm-6" style={{padding:"0px 5px"}}>
                                        <h4 style={{color: '#fff',paddingLeft:"11px",fontSize:"16px"}}>Linked Accounts</h4>
                                        <div style={{height:"300px",overflow:"auto",borderRight:"1px solid rgb(25 29 33)"}}>
                                            <table style={{marginTop:"10px",marginBottom:"10px"}}>
                                                <thead>
                                                <tr>
                                                    <th style={{color: '#fff',padding:"10px",width:"40%"}}>Name</th>
                                                    <th style={{color: '#fff',padding:"10px",width:"25%"}}>Type</th>
                                                    <th style={{color: '#fff',padding:"10px",width:"25%"}}>Exchange</th>
                                                    <th style={{color: '#fff',padding:"10px",width:"10%"}}>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {myPortfolioAccounts && myPortfolioAccounts.data.length > 0 ? myPortfolioAccounts.data.map((d, i) =>
                                                    {
                                                        let new_exchange_name = "-";
                                                        if(d && d.key_details){
                                                            new_exchange_name = d.key_details.exchange_name.charAt(0).toUpperCase() + d.key_details.exchange_name.slice(1,d.key_details.exchange_name.length);
                                                        }
                                                        return(
                                                            <tr key={i}>
                                                                <td style={{color: '#fff',padding:"10px"}}>{d.name}</td>
                                                                <td style={{color: '#fff',padding:"10px"}}>{d.type.toUpperCase()}</td>
                                                                <td style={{color: '#fff',padding:"10px"}}>{new_exchange_name}</td>
                                                                <td className="edit-delete-btn" style={{cursor: "pointer",padding:"10px 12px"}}>
                                                                    <button className=" open-modal-event" onClick={()=>moveBackAccount(myPortfolio.id,d.id,i)}>
                                                                        <i style={{color:"red"}} title={"Remove account from portfolio"} className="fas fa-2x fa-arrow-right"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }

                                                    ):
                                                    <tr>
                                                        <td colSpan={4} style={{color: '#fff',padding:"10px"}}>There is no linked account for this portfolio.</td>
                                                    </tr>
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6" style={{padding:"0px 5px"}}>
                                        <h4 style={{color: '#fff',paddingLeft:"11px",fontSize:"16px"}}>Other Accounts</h4>
                                        <div style={{height:"300px",overflow:"auto"}}>
                                            <table style={{marginTop:"10px",marginBottom:"10px"}}>
                                                <thead>
                                                <tr>
                                                    <th style={{color: '#fff',padding:"10px",width:"40%"}}>Name</th>
                                                    <th style={{color: '#fff',padding:"10px",width:"25%"}}>Type</th>
                                                    <th style={{color: '#fff',padding:"10px",width:"25%"}}>Exchange</th>
                                                    <th style={{color: '#fff',padding:"10px",width:"10%"}}>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {accounts.data.length > 0 ? accounts.data.map((d, i) => {
                                                        let new_exchange_name = "-";
                                                        if(d && d.key_details){
                                                            new_exchange_name = d.key_details.exchange_name.charAt(0).toUpperCase() + d.key_details.exchange_name.slice(1,d.key_details.exchange_name.length);
                                                        }
                                                        return (
                                                            <tr key={i}>
                                                                <td style={{color: '#fff',padding:"10px"}}>{d.name}</td>
                                                                <td style={{color: '#fff',padding:"10px"}}>{d.type.toUpperCase()}</td>
                                                                <td style={{color: '#fff',padding:"10px"}}>{new_exchange_name}</td>
                                                                <td className="edit-delete-btn" style={{cursor: "pointer",padding:"10px 12px"}} onClick={()=>moveAccount(myPortfolio.id,d.id,i)}>
                                                                    <button className=" open-modal-event">
                                                                        <i style={{color:"green"}} title={"Add account to portfolio"} className="fas fa-2x fa-arrow-left"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );

                                                    }
                                                    ):
                                                    <tr>
                                                        <td colSpan={4} style={{color: '#fff',padding:"10px"}}>There is no account to be linked</td>
                                                    </tr>
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>
                    </div>
                </div>
            </div>
            <PortfolioDelete
                editPortfolio={editPortfolio}
                show={showD}
                getPortfolios={getPortfolios}
                close={()=>{
                    setEditPortfolio({});
                    setShowD(false);
                    setPortfolioName("");
                }}

            />
        </div>
    );
};


export default (PortfolioManagement);

