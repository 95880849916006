import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import React, {useEffect} from "react";
import RealtimeUtils from "../../helpers/RealtimeUtils";
import ReactTooltip from "react-tooltip";
import CISNavigator from "../../helpers/CISNavigator";
import IndexRow from "./IndexRow";
import DataMappers from "../../helpers/DataMappers";


const IndexTable = (props, context) => {
    // To show tooltip values
    const {t} = context;
    const {
        indexData, // Index data to show
        exchange, // Exchange information
        history, // Used to navigate pages
        sort, // Sort information
        setSort, // Indicates if the table has sorting, if not given sorting is disabled
        disabledColumns, // Disabled columns in table
        addWatchlist, // Called when add watchlist is clicked with the selected index (Disabled Feature)
        addAlert // Called when add alert is clicked with the selected index (Disabled Feature)
    } = props;

    // Use realtime ticker updates
    const [tickerList, setChannel, initSocketConnection] = RealtimeUtils.useRealTimeTicker([], DataMappers.indexTickerMapper);

    useEffect(() => {
        // Every coin at least must contain ticker symbol
        indexData.filter(el => !el.ticker || !el.ticker.symbol)
            .forEach(el => {
                if (el.ticker) {
                    el.ticker.symbol = RealtimeUtils.generateTicker(el.symbol, exchange.value, true);
                } else {
                    el.ticker = { symbol: RealtimeUtils.generateTicker(el.symbol, exchange.value, true) };
                }
            });

        // Generate tickers
        const tickers = indexData.map(el => el.ticker.symbol);

        // Generate real time trackers
        initSocketConnection(tickers);
    }, [indexData]);

    // Sort related variables
    const sortChange = index => (sort.s === 'desc' ? {i: index, s: 'asc'} : {i: index, s: 'desc'});
    const sortArrow = index => sort.i === index && <i style={{fontSize: 12}} className={`fa fa-angle-${sort.s === 'desc' ? 'up' : 'down'}`} />;

    // Check if column is enabled
    const isEnabled = col_id => !disabledColumns.includes(col_id);

    // Navigate to index page
    const indexSelected = selectedRow => CISNavigator.navigateToIndexDetail(history, selectedRow, exchange);

    return (
        <div>
            <ReactTooltip html={true} />
            <table className="new-coins enumarable-table linked-table" style={{"tableLayout" : "fixed"}}>
                <thead>
                <tr>
                    <th colSpan={2} className={"header-left-aligned"}>Code</th>
                    <th colSpan={4} className={"header-left-aligned"}>Name</th>
                    <th colSpan={2}>Value</th>
                    {isEnabled("high") && <th colSpan={2}>24h High</th>}
                    {isEnabled("low") && <th colSpan={2}>24h Low</th>}
                    {isEnabled("market_cap") && <th colSpan={2}>Mkt. Cap.</th>}
                    {isEnabled("chgpct") && <th colSpan={2}>Chg (24h)</th>}
                    {isEnabled("set_watchlist") && <th colSpan={1}/>}
                </tr>
                </thead>
                <tbody>
                {tickerList.map((row, idx) => (indexData[idx] &&
                    <IndexRow key={row} indexRow={indexData[idx]} setChannel={setChannel} disabledColumns={disabledColumns} indexSelected={indexSelected} addWatchlist={addWatchlist} addAlert={addAlert}/>
                ))}
                </tbody>
            </table>
        </div>
    )
};


IndexTable.contextTypes = {
    t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    return {
        user: state.user,
    };
};


export default connect(mapStateToProps)(withRouter(IndexTable));
