import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Breadcrump from '../../components/Breadcrump';
import Breadcrumps from '../../constants/Breadcrumps';
import Loading from '../../components/Loading';
import Api from '../../api';
import ReactTooltip from 'react-tooltip';
import Select from '../../components/Forms/Select';
import {useAuth0} from "../../helpers/Auth0";
import MobileNavBar from "../../components/MobileNavBar/MobileNavBar";
import Utils from "../../helpers/Utils";
import Watchlist from "../../components/PageDashboard/Watchlist/Watchlist";
import CISNavigator from "../../helpers/CISNavigator";

const isMobile = Utils.checkMobile();

const Sectors = (props, context) => {
  const {history, meta} = props;
  const {user} = useAuth0();
  //User WatchListShow preference
  const [watchListShow,setWatchListShow] = useState(user.preferences.show_watchlist);
  const [data, setData] = useState({items: [], loading: true});
  const [exchange, setExchange] = useState(user.preferences.currency);
  const [sort, setSort] = useState({i: 4, s: 'ASC'});
  const currencyList =
    (meta.lists &&
      meta.lists.key_currency_list &&
      meta.lists.key_currency_list.map(c => ({value: c[0], label: c[0], type: c[2] === 'FIAT' ? 'USD' : 'BTC'}))) ||
    [];


  useEffect(() => {
    fetchData(exchange);
  }, [exchange, sort.i, sort.s]);

  const fetchData = ex => {
    ex = ex || exchange;
    setData({items: [], loading: true});
    Api.getPromise(`/sector/getsectorsummary?ccy=${ex}`)
      .then(r => {
        const d = (r.display_data && r.display_data.data.map((r2, i) => [...r2, r.raw_data.data[i]])) || [];
        setData({items: d, loading: false});
      })
      .catch(e => setData({items: [], loading: false}));
  };

  const sortChange = index => (sort.s === 'DESC' ? {i: index, s: 'ASC'} : {i: index, s: 'DESC'});

  const sortArrow = index => sort.i === index && <i style={{fontSize: 12}} className={`fa fa-angle-${sort.s === 'DESC' ? 'up' : 'down'}`} />;

  return (
    isMobile ?
        <div>
          <MobileNavBar/>
          <div>
            {watchListShow? <Watchlist />:<></>}
          </div>
          <div className="mobile-Div">
            <div className="gray-container coins-container">
              <div className="table-head">
                <div className="table-left">
                  <div className="table-header">ALL SECTORS</div>
                </div>
                <div className="clearfix" />
              </div>
              <div className="table-body sector-table table-responsive">
                <Loading loading={data.loading}>
                  <ReactTooltip />
                  <table className="coins enumarable-table linked-table">
                    <thead>
                    <tr>
                      <th onClick={() => setSort(sortChange(0))} style={{cursor: 'pointer'}}>
                        Sector Code {sortArrow(0)}
                      </th>
                      <th onClick={() => setSort(sortChange(1))} style={{cursor: 'pointer'}}>
                        Sector Name {sortArrow(1)}
                      </th>
                      <th onClick={() => setSort(sortChange(3))} style={{cursor: 'pointer'}}>
                        # Coins <i data-tip={'Number of Coins in that sector'} className="fas fa-info-circle" /> {sortArrow(3)}
                      </th>
                      <th onClick={() => setSort(sortChange(4))} style={{cursor: 'pointer'}}>
                        Market Cap <i data-tip={'Sum of Market Cap values of the coins'} className="fas fa-info-circle" /> {sortArrow(4)}
                      </th>
                      <th onClick={() => setSort(sortChange(5))} style={{cursor: 'pointer'}}>
                        Volume (24h)
                        <i data-tip={'Sum of volume of the relevant coins'} className="fas fa-info-circle" /> {sortArrow(5)}
                      </th>
                      <th onClick={() => setSort(sortChange(6))} style={{cursor: 'pointer'}}>
                        Change Percentage (24h) {sortArrow(6)}
                      </th>
                      <th onClick={() => setSort(sortChange(7))} style={{cursor: 'pointer'}}>
                        Mkt. Cap Dominance {sortArrow(7)}
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.items
                        .sort((a, b) => (sort.s === 'DESC' ? (a[11][sort.i] > b[11][sort.i] ? 1 : -1) : a[11][sort.i] < b[11][sort.i] ? 1 : -1))
                        .filter(d => d[3] > 0)
                        .map((d, i) => (
                            <tr
                                // onClick={() => {
                                //   CISNavigator.navigateToSectorDetail(history, d, exchange);
                                // }}
                                key={i}
                                data-href="detail-sectors.html">
                              <td>{d[0]}</td>
                              <td style={{textAlign: 'left'}}>{d[1]}</td>
                              <td>{d[3]}</td>
                              <td>{d[4]}</td>
                              <td>{d[5]}</td>
                              <td style={{display: 'flex', flexDirection: 'row'}}>
                                {d[7] == 'UP' && <img style={{width: 15, height: 15}} src="/img/up.svg"/>}
                                {d[7] == 'DOWN' && <img style={{width: 15, height: 15}} src="/img/down.svg"/>}
                                <span className={d[7] == 'UP' ? 'table-green-text':'table-red-text'} style={{marginLeft:"5px"}}>{d[6]}</span>
                              </td>
                              <td>{d[8]}</td>
                            </tr>
                        ))}
                    </tbody>
                  </table>
                </Loading>
              </div>
            </div>
          </div>
          <div style={{height:"80px"}}>

            <p></p>
          </div>
        </div>


        :
    <React.Fragment>
      <section className="coin-table padding-container">
        <div className="watchlist-all-page">
          {watchListShow? <Watchlist />:<></>}
        </div>
        <Breadcrump links={Breadcrumps.sectors} />

        <div className="gray-container coins-container">
          <div className="table-head">
            <div className="table-left">
              <div className="table-header">ALL SECTORS</div>
            </div>
            <div className="table-right">
              <div className="table-select-input">
                <div className="sm-select-input small-select">
                  <Select selected={exchange} options={currencyList} onChange={selected => setExchange(selected.value)} />
                </div>
              </div>
            </div>
            <div className="clearfix" />
          </div>
          <div className="table-body sector-table table-responsive">
            <Loading loading={data.loading}>
              <ReactTooltip />
              <table className="coins enumarable-table linked-table">
                <thead>
                  <tr>
                    <th onClick={() => setSort(sortChange(0))} style={{cursor: 'pointer'}}>
                      Sector Code {sortArrow(0)}
                    </th>
                    <th onClick={() => setSort(sortChange(1))} style={{cursor: 'pointer'}}>
                      Sector Name {sortArrow(1)}
                    </th>
                    <th onClick={() => setSort(sortChange(3))} style={{cursor: 'pointer'}}>
                      # Coins <i data-tip={'Number of Coins in that sector'} className="fas fa-info-circle" /> {sortArrow(3)}
                    </th>
                    <th onClick={() => setSort(sortChange(4))} style={{cursor: 'pointer'}}>
                      Market Cap <i data-tip={'Sum of Market Cap values of the coins'} className="fas fa-info-circle" /> {sortArrow(4)}
                    </th>
                    <th onClick={() => setSort(sortChange(5))} style={{cursor: 'pointer'}}>
                      Volume (24h)
                      <i data-tip={'Sum of volume of the relevant coins'} className="fas fa-info-circle" /> {sortArrow(5)}
                    </th>
                    <th onClick={() => setSort(sortChange(6))} style={{cursor: 'pointer'}}>
                      Change Percentage (24h) {sortArrow(6)}
                    </th>
                    <th onClick={() => setSort(sortChange(7))} style={{cursor: 'pointer'}}>
                      Mkt. Cap Dominance {sortArrow(7)}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.items
                    .sort((a, b) => (sort.s === 'DESC' ? (a[11][sort.i] > b[11][sort.i] ? 1 : -1) : a[11][sort.i] < b[11][sort.i] ? 1 : -1))
                    .filter(d => d[3] > 0)
                    .map((d, i) => (
                      <tr
                        onClick={() => {
                          CISNavigator.navigateToSectorDetail(history, d, exchange);
                        }}
                        key={i}
                        data-href="detail-sectors.html">
                        <td>{d[0]}</td>
                        <td style={{textAlign: 'left'}}>{d[1]}</td>
                        <td>{d[3]}</td>
                        <td>{d[4]}</td>
                        <td>{d[5]}</td>
                        <td style={{display: 'flex', flexDirection: 'row'}}>
                          {d[7] == 'UP' && <img style={{width: 15, height: 15}} src="/img/up.svg"/>}
                          {d[7] == 'DOWN' && <img style={{width: 15, height: 15}} src="/img/down.svg"/>}
                          <span className={d[7] == 'UP' ? 'table-green-text':'table-red-text'} style={{marginLeft:"5px"}}>{d[6]}</span>
                        </td>
                        <td>{d[8]}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Loading>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    meta: state.meta,
  };
};

export default connect(mapStateToProps)(Sectors);
