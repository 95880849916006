import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Utils from "../../../helpers/Utils";
import TextField from "@material-ui/core/TextField";
import Loading from "../../Loading";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';

const isMobile = Utils.checkMobile();

const DeFiFilter = props => {
    const {loadingP, show, setFilter, filter} = props;

    const [reset, setReset] = useState(false);

    const onChange = (type, val) => {
        let newFilter = {...filter};
        newFilter[type] = val;
        setFilter(newFilter);
    };

    useEffect(() => {
        if (show) {
            document.querySelector("body").style.overflow = 'hidden';
        } else {
            document.querySelector("body").style.overflow = 'auto';
        }
    }, [show]);


    return (
        isMobile ?
            <div>
            </div>
            :
            <div className="filter-bars" style={{border: "2px solid black"}}>
                <div className="filter-bar-inner" style={{justifyContent: "space-between", paddingBottom: "10px"}}>
                    <div className="filter-bar">
                        <div className="filter-bar-input" style={{width: "unset"}}>
                            <div className="input-title">TVL:</div>
                            <div className="filter-bar-input create-flex">
                                <div className="flex-row" style={{padding: "0px 5px"}}>
                                    <div className="gray-sm-input">
                                        <TextField id="outlined-basic" label="Min" variant="outlined"
                                                   value={filter.inputTvlMin}
                                                   onChange={val => onChange("inputTvlMin", val.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="flex-row" style={{padding: "0px 5px"}}>
                                    <div className="gray-sm-input">
                                        <TextField id="outlined-basic" label="Max" variant="outlined"
                                                   value={filter.inputTvlMax}
                                                   onChange={val => onChange("inputTvlMax", val.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="filter-bar" style={{width: "100%", padding: "0px"}}>
                        <div
                            className="filter-bar-input"
                            style={{display: "flex", float: "right", width: "240px"}}
                        >
                            <div className="blue-border-btn flLeft flLeftB">
                                <a style={{fontSize: "14px"}}
                                   className={loadingP ? "a-disabled" : ""}
                                   onClick={() => {
                                       if (!loadingP) setFilter({...filter, search: !filter.search});
                                   }}
                                >
                                    Search
                                </a>
                            </div>

                            <div className="blue-border-btn flLeft flLeftB">
                                <a style={{fontSize: "14px"}}

                                   className={loadingP ? "a-disabled" : ""}
                                   onClick={() => {

                                       setFilter({
                                           inputMktCapMin: "",
                                           inputMktCapMax: "",
                                           inputTvlMin: "",
                                           inputTvlMax: "",
                                           inputChgMin: "",
                                           inputChgMax: "",
                                           inputKeyword: "",
                                           search: !filter.search})
                                       setReset(true);
                                       setTimeout(function () {
                                           setReset(false);
                                       }, 500);

                                   }}
                                >
                                    Reset
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

    );
};
const mapStateToProps = state => {
    return {
        meta: state.meta
    };
};
export default connect(mapStateToProps)(DeFiFilter);
