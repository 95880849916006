import React, {useState, useEffect} from 'react';
import Loading from '../../../../components/Loading';
import {toastr} from 'react-redux-toastr';
import Utils from "../../../../helpers/Utils";
import {connect} from "react-redux";
import {checkQuotaAccess, useAuth0} from "../../../../helpers/Auth0";
import ReactTooltip from "react-tooltip";
import ConfirmationAlert from "../../../../components/Alerts/ConfirmationAlert";
import CryptoAlertModal from "../../../../components/Alerts/CryptoAlertModal/CryptoAlertModal";

const CryptoAlert = props => {
    const {loading, data, setData, exchanges, getExchangeName, fetchData} = props;

    // remove item infos
    const [removed, setRemoved] = useState({type: "", id: 0, title: ""});
    // update item infos
    const [updated, setUpdated] = useState({id: null, data: null});
    // Show delete modal
    const [showDelete, setShowDelete] = useState(false);
    // Show edit modal
    const [showCU, setShowCU] = useState(false);
    // Type
    const [type, setType] = useState("");
    // New Type for title
    const [newType, setNewType] = useState({label: "Create"});


    const handleOpenConditions = (index) => {
        let newData = {...data};
        newData.items[index].show = !newData.items[index].show;
        setData({items: newData.items, loading: false});
    }

    const handleDeleteAlert = (id) => {
        setRemoved({type:"alert",id:id,title:"market data alert"});
        setShowDelete(true);
    }

    const handleEditAlert = (id, item) => {
        setUpdated({id: id, data: item});
        setType("Update");
        setNewType({label: "Update"})
        setShowCU(true);
    }

    const handleCreateAlert = () => {
        setType("Create");
        setNewType({label: "Create"})
        setShowCU(true);
    }


    return (
        <div className="gray-container alerts-price-alert">
            <div className="section-header" style={{display: "flex", justifyContent: "space-between"}}>
                <div>
                    Market Data Alerts
                </div>
                <div>
                    <div style={{width: 180, marginLeft: "10px"}} className="blue-border-btn flRight">
                        <a style={{cursor: "pointer"}} onClick={handleCreateAlert}>Create Market Alert</a>
                    </div>
                </div>
            </div>
            <div className="pa-inner">
                <div className="scroll-table" id="beauty-scroll-table">
                    <Loading loading={loading}>
                        <ReactTooltip html={true}/>
                        <table>
                            <thead>
                            <tr>
                                <th style={{width: "57px", padding: "7px 5px"}}/>
                                <th style={{color: '#7d7d7d', padding: "7px 5px"}}>Name</th>
                                <th style={{color: '#7d7d7d', padding: "7px 5px"}}>Condition Count</th>
                                <th style={{color: '#7d7d7d', padding: "7px 5px"}}>Created Date</th>
                                <th style={{color: '#7d7d7d', padding: "7px 5px"}}>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.items.length > 0 ? data.items.map((d, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <tr key={i}>
                                                <td style={{textAlign: "center", width: "57px", padding: "7px 5px"}}
                                                    onClick={() => handleOpenConditions(i)}><img style={{margin: "1em"}}
                                                                                                 className={"actionable-img"}
                                                                                                 src={d.show ? "/img/arrow-down.svg" : "/img/arrow-right.svg"}/>
                                                </td>
                                                <td style={{color: "fff", padding: "7px 5px"}}>{d.alert_name}</td>
                                                <td style={{color: "fff", padding: "7px 5px"}}>{d.alert_data.length}</td>
                                                <td style={{color: "fff", fontSize: "14px", padding: "7px 5px"}}>
                                                    {d.created_on.split("T")[0]}
                                                </td>
                                                <td className="edit-delete-btn"
                                                    style={{textAlign: "left", padding: "7px 5px"}}>
                                                    <button onClick={() => handleEditAlert(d.alert_id, d)}
                                                            className=" open-modal-event" style={{marginRight: "15px"}}
                                                            data-html={true} data-tip={'Edit Alert'}>
                                                        <img className="actionable-img" src="/img/edit.svg" alt=""/>
                                                    </button>
                                                    <button onClick={() => handleDeleteAlert(d.alert_id)}
                                                            className=" open-modal-event" data-html={true}
                                                            data-tip={'Delete Alert'}>
                                                        <img className="actionable-img" src="/img/delete.svg" alt=""/>
                                                    </button>
                                                </td>
                                            </tr>
                                            {
                                                d.show &&
                                                <tr style={{border: "none"}} key={d.alert_id}>
                                                    <td colSpan={5} style={{padding: "0px"}}>
                                                        <ReactTooltip html={true}/>
                                                        <table>
                                                            <thead>
                                                            <tr style={{fontSize: "14px", background: "#1f2327"}}>
                                                                <th style={{color: '#7d7d7d', padding: "7px 5px"}}>Pair</th>
                                                                <th style={{
                                                                    color: '#7d7d7d',
                                                                    padding: "7px 5px"
                                                                }}>Market
                                                                </th>
                                                                <th style={{
                                                                    color: '#7d7d7d',
                                                                    padding: "7px 5px"
                                                                }}>Field
                                                                </th>
                                                                <th style={{
                                                                    color: '#7d7d7d',
                                                                    padding: "7px 5px"
                                                                }}>Direction
                                                                </th>
                                                                <th style={{
                                                                    color: '#7d7d7d',
                                                                    padding: "7px 5px"
                                                                }}>Target
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                d.alert_data.map((el, index) => (
                                                                    <tr style={{background: "#1f2327", fontSize: "14px"}}
                                                                        key={index}>
                                                                        <td style={{
                                                                            color: "fff",
                                                                            padding: "7px 5px",
                                                                            textAlign: "left"
                                                                        }}><p style={{
                                                                            margin: "10px 0 0",
                                                                            fontSize: "14px"
                                                                        }}>{el.pair.split(".")[0]}</p></td>
                                                                        <td style={{
                                                                            color: "fff",
                                                                            padding: "7px 5px",
                                                                            textAlign: "left"
                                                                        }}><p style={{
                                                                            margin: "10px 0 0",
                                                                            fontSize: "14px"
                                                                        }}>{getExchangeName(el.pair.split(".")[1])}</p></td>

                                                                        <td style={{
                                                                            color: "fff",
                                                                            padding: "7px 5px",
                                                                            textAlign: "left"
                                                                        }}><p style={{
                                                                            margin: "10px 0 0",
                                                                            fontSize: "14px"
                                                                        }}>{el.target_field === "price" ? el.target_field.charAt(0).toUpperCase() + el.target_field.slice(1) : el.target_field.split("_")[1].charAt(0).toUpperCase() + el.target_field.split("_")[1].slice(1)}</p>
                                                                        </td>
                                                                        <td className={el.direction === "above" ? "alert-value-above" : "alert-value-below"}
                                                                            style={{
                                                                                color: "fff",
                                                                                padding: "7px 5px",
                                                                                textAlign: "left"
                                                                            }}><p style={{
                                                                            margin: "10px 0 0",
                                                                            fontSize: "14px"
                                                                        }}>{el.direction.charAt(0).toUpperCase() + el.direction.slice(1)}</p>
                                                                        </td>
                                                                        <td className={el.direction === "above" ? "alert-value-above" : "alert-value-below"}
                                                                            style={{
                                                                                color: "fff",
                                                                                padding: "7px 5px",
                                                                                textAlign: "left"
                                                                            }}><p style={{
                                                                            margin: "10px 0 0",
                                                                            fontSize: "14px"
                                                                        }}>{el.target_value}</p></td>
                                                                    </tr>
                                                                ))
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            }
                                        </React.Fragment>
                                    )
                                }
                                ) :
                                <tr>
                                    <td colSpan={6}
                                        style={{textAlign: "left", color: "fff", fontSize: "14px", padding: "7px 5px"}}>
                                        There is no alert in your account!
                                    </td>
                                </tr>
                            }
                            </tbody>
                        </table>
                    </Loading>
                </div>
                <div className="clearfix"/>
            </div>
            <ConfirmationAlert
                fetchAlerts={fetchData}
                item={removed}
                show={showDelete}
                close={() => {
                    setRemoved({type: "", title: "", id: 0})
                    setShowDelete(false);
                }}
            />
            <CryptoAlertModal
                fetchData={fetchData}
                newType={newType}
                getExchangeName={getExchangeName}
                show={showCU}
                type={type}
                setType={setType}
                updated={updated}
                close={() => {
                    setUpdated({id: null, data: null});
                    setType("");
                    setShowCU(false);
                }}
            />
        </div>

    );
};

const mapStateToProps = state => {
    return {
        meta: state.meta,
    };
};

export default connect(mapStateToProps)(CryptoAlert);
