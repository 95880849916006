import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Breadcrump from '../../components/Breadcrump';
import Api from '../../api';
import StateBarSectorRow from "../../components/Sector/StateBarSectorRow";
import SectorMenuContent from "./tabs/SectorMenuContent";
import Select from "../../components/Forms/Select";
import ReactGA from "react-ga";
import {useAuth0} from "../../helpers/Auth0";
import Utils from "../../helpers/Utils";
import MobileNavBar from "../../components/MobileNavBar/MobileNavBar";


const isMobile = Utils.checkMobile();

const menuItems = !isMobile ? [
    {
        name: 'OVERVIEW',
        path_matcher: 'detail'
    },
    {
        name: 'COINS',
        path_matcher: 'sector-coins'
    },
    {
        name: 'ANALYSIS',
        path_matcher: 'sector-analysis'
    },
    // {
    //     name: 'REPORTS',
    //     path_matcher: 'sector-reports'
    // }
] : [
    {
        name: 'OVERVIEW',
        path_matcher: 'm-detail'
    },
    {
        name: 'GAINERS & LOSERS',
        path_matcher: 'gainers-losers'
    },
    {
        name: 'COINS',
        path_matcher: 'sector-coins'
    },
];

const SectorDetail = props => {
    const {
        history, // Routing information
        currencyList, // Global Currency Lists
        sectorList, // Global Sector lists
    } = props;
    const {user} = useAuth0();

    // Get exchange and sector code from path params
    const secCode = props.match.params && props.match.params.secSymbol;
    const row = (secCode && sectorList.filter(b => b.alphanumeric_code === secCode)[0]) || {};
    const menuSelection = (props.match.params && props.match.params.additional) || "detail";
    const matchedMenu = menuItems.find(el => el.path_matcher === menuSelection);

    // Get additional params (ccy)
    const params = new URLSearchParams(props.location.search);
    const exchangeCode = params.get("ccy");
    const matchedExchange = currencyList.find(el => el.value === exchangeCode) || // Get selected exchange
        (user.preferences.currency && {value: user.preferences.currency}) || // Or user preferred exchange
        currencyList[0] // First currency;

    // Indicates the sector data (raw_data for raw values, display_data for display information)
    const [sectorData, setSectorData] = useState({raw_data: null, display_data: null});
    // Exchange information
    const [selectedExchange, setSelectedExchange] = useState(matchedExchange);
    // Bread crump data
    const [crumps, setCrumps] = useState([]);

    // Arrange crumps data
    const arrangeCrumps = sectorCode => {
        setCrumps([
            {
                name: 'Home',
                path: '/',
            },
            {
                name: 'Sectors',
                path: '/sectors',
            },
            {
                name: sectorCode,
                path: `/sectors/${sectorCode}/detail`,
            },
        ]);
    }

    // Refresh data on exchange change
    useEffect(() => {
        if (!row) {
            history.replace('/sectors');
        } else if (!matchedMenu) {
            history.push(`./${menuItems[0].path_matcher}`);
        } else {
            // Arrange bread crump
            arrangeCrumps(secCode);

            // Change ccy param at url (on Exchange change)
            history.replace({
                pathname: history.location.pathname,
                search: `?ccy=${selectedExchange.value}`
            });

            // Fetches sector data from api
            Api.readSectorSummary(secCode, selectedExchange.value).then(res => {
                setSectorData(res)
            });
        }
    }, [matchedMenu, secCode, selectedExchange]);

    useEffect(() => {
        try {
            // Send changes to analytics on page change
            ReactGA.pageview(window.location.pathname);
        } catch (e) {
            console.error(e);
        }
    }, [secCode, window.location.pathname]);

    // Menu changed; update link and path
    const menuChanged = (s) => {
        history.push({
            pathname: './' + s.path_matcher,
            search: history.location.search
        });
    }

    return (
        isMobile ?
            <div>
                <MobileNavBar/>
                <div className="mobile-Div">
                    <div className="row coin_detail_row">
                        <div className="col-sm-7 col-xs-8 coin_detail_text">
                            <div id="filter-area-coins" style={{display: 'none'}}>
                                <p>Sector Details:</p>
                            </div>
                        </div>
                        <div className="col-sm-5 col-xs-4 coin_detail_select">
                            <div className={'table-select-input'}>
                                <div style={{fontSize: 13}}
                                     className={'sm-select-input small-select coin-detail-mobile'}>
                                    <Select selected={selectedExchange.value} options={currencyList}
                                            onChange={selected => setSelectedExchange(selected)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="state-bar">
                        {sectorData.raw_data && <StateBarSectorRow rowClick={() => null} rawData={sectorData.raw_data}
                                                                   displayData={sectorData.display_data}
                                                                   selectedExchange={selectedExchange}/>}
                    </div>
                </div>
                <div className="mobile-Div">
                    <div className="tab-menu">
                        <ul>
                            {menuItems.map((s, i) => (
                                <li key={i} className={history.location.pathname.endsWith(s.path_matcher) ? 'active' : ''}>
                                    <a onClick={() => menuChanged(s)}>{s.name}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {sectorData.raw_data && <SectorMenuContent
                        rawData={sectorData.raw_data}
                        selectedExchange={selectedExchange}
                    />}
                </div>
                <div style={{height: "80px"}}>

                    <p></p>
                </div>
            </div> :
            <section className="dashboard padding-sm-container">
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Breadcrump links={crumps}/>
                    <div className={'table-select-input'}>
                        <div style={{fontSize: 13}} className={'sm-select-input small-select'}>
                            <Select selected={selectedExchange.value} options={currencyList}
                                    onChange={selected => setSelectedExchange(selected)}/>
                        </div>
                    </div>
                </div>
                <div className="state-bar">
                    {sectorData.raw_data && <StateBarSectorRow rowClick={() => null} rawData={sectorData.raw_data}
                                                               displayData={sectorData.display_data}
                                                               selectedExchange={selectedExchange}/>}
                </div>
                <div className="tab-menu">
                    <ul>
                        {menuItems.map((s, i) => (
                            <li key={i} className={history.location.pathname.endsWith(s.path_matcher) ? 'active' : ''}>
                                <a onClick={() => menuChanged(s)}>{s.name}</a>
                            </li>
                        ))}
                    </ul>
                </div>
                {sectorData.raw_data && <SectorMenuContent
                    rawData={sectorData.raw_data}
                    selectedExchange={selectedExchange}
                />}
            </section>
    );
};

const mapStateToProps = state => {
    let business = [];
    let currencyList = [];

    if (state && state.meta && state.meta.lists) {
        // Extract business classification list
        business = state.meta.lists.business_classification_schema || [];

        // Extract currency list
        currencyList = (
            state.meta.lists.key_currency_list &&
            state.meta.lists.key_currency_list.map(c => ({value: c[0], label: c[0], type: c[2] === 'FIAT' ? 'USD' : 'BTC'}))
        ) || [];
    }

    return {
        sectorList: business,
        currencyList: currencyList
    };
};

export default connect(mapStateToProps)(SectorDetail);
