const Links = {
    REAL_TIME_SERVER: "wss://socket.dev.cryptoindexseries.com",
    // REAL_TIME_SERVER: "ws://localhost:3001",
    // REAL_TIME_SERVER: "wss://socket-awsdev.cryptoindexseries.com",
    // BASE_TRADE_URL: "https://api-user.cistrader.com/trade",
    BASE_TRADE_URL: "https://api-trade.cistrader.com",
    // BASE_TRADE_URL: "http://localhost:3001",
    // BASE_TRADE_URL: "http://cistradeapi-production.eba-de5unseg.eu-central-1.elasticbeanstalk.com",
    BASE_USER_URL: "https://api-user.cistrader.com/cis-user",
    BASE_PORTFOLIO_URL: "https://api-portfolio.cistrader.com/cis-portfolio",
    // BASE_PORTFOLIO_URL: "https://g7i2e522m0.execute-api.eu-central-1.amazonaws.com/prod",
    // BASE_PORTFOLIO_URL: "http://localhost:8000/cis-portfolio",
    // BASE_USER_URL: "http://localhost:8000/cis-user",
    // BASE_USER_URL: "http://cisuserapi-production.eba-smcc2mvp.eu-central-1.elasticbeanstalk.com/cis-user",
    //BASE_URL: "https://ciswebapi2.cryptoindexseries.com/api",
    BASE_URL: "https://api.cistrader.com/api",
    BASE_CRYPTO_COMPARE_URL: "https://min-api.cryptocompare.com/data",
    BASE_SOCIAL_URL: "https://social.cryptoindexseries.com/",
    // BASE_NOTIFICATION_URL: "http://localhost:8000/cis-notifier/user",
    BASE_NOTIFICATION_URL: "https://notification.cryptoindexseries.com/cis-notifier/user",
    // BASE_SOCIAL_URL: "http://173.212.249.191/social/",
    // BASE_SOCIAL_URL: "http://localhost:5000",
    APIAUTH: "https://apiauth.cryptoindexseries.com/api",
    META_DATA: "/Metadata/GetMetaData?language_code=en",
    NEWS_TAGS: "/Metadata/GetNewsTagsMetaData?language_code=en",
    COIN_SEARCH: "/Search/CoinSearch?",
    SUBWATCHLIST: "/subsWatchlist?",
    GET_ALL_COINS: "/Coin/GetAllCoins",
    ACCOUNT_API_URL: "https://84vj9mam3e.execute-api.eu-central-1.amazonaws.com/prod"
}
//
export default Links;
