/**
 * Contains navigator functions for coin, index, alert
 */
const CISNavigator = {

    /**
     * Navigate to coin detail page with the data
     *
     * @param history History to use in navigation
     * @param coinData Coin to navigate
     * @param exchange Exchange information to use
     */
    navigateToCoinDetail(history, coinData, exchange = {value: 'USD', type: 'USD'}) {
        const ccy = exchange && exchange.value ? `ccy=${exchange.value}` : '';
        history.push(`/coins/${coinData.symbol}/detail?${ccy}`);
    },

    /**
     * Navigate to sector detail page with the data
     *
     * @param history History to use in navigation
     * @param sectorData Sector to navigate
     * @param exchange Exchange information to use
     */
    navigateToSectorDetail(history, sectorData, exchange = {value: 'USD', type: 'USD'}) {
        const ccy = exchange && exchange.value ? `ccy=${exchange.value}` : '';
        history.push(`/sectors/${sectorData.value || sectorData[0]}/detail?${ccy}`);
    },

    /**
     * Navigate to coin detail page with the data
     *
     * @param history History to use in navigation
     * @param indexData Index to navigate
     * @param exchange Exchange information to use
     */
    navigateToIndexDetail(history, indexData, exchange = {value: 'USD', type: 'USD'}) {
        const ccy = exchange && exchange.value ? `ccy=${exchange.value}` : '';
        history.push(`/indices/${indexData.index_code || indexData.code}/detail?${ccy}`);
    },
};


export default CISNavigator;
