import React from 'react';
import CISNavigator from "../../helpers/CISNavigator";
import {withRouter} from "react-router-dom";
import Utils from "../../helpers/Utils";

const isMobile = Utils.checkMobile();

const Taxonomy = props => {

    const {history, data} = props;
    const {
        business_classification_code,
        business_classification,
        consensus_model,
        algorithm,
        transaction_anonimity,
        parent_blockchain_symbol,
        asset_type,
        mineable,
        stablecoin,
        defi,
    } = data.coin_info;


    const checkBusinessPerspective = () => business_classification;

    const checkTechnicalPerspective = () => consensus_model.length > 0 || algorithm.length > 0 || transaction_anonimity != null || parent_blockchain_symbol != null;

    const checkFinancialPerspective = () => asset_type != null || stablecoin != null;

    const checkEconomyPerspective = () => mineable != null;


    return (
        isMobile ?
            <div className="gray-container basic-lists">
                <div className="section-header">Taxonomy</div>
                <div className="basic-lists-inner">
                    <ul id="beauty-scroll">
                        <li style={{color: '#ffffff'}}>

                            <br></br>

                            <div className="subsection-header">Business Perspective</div>

                            <div className="bli-inner">
                                <div className="bli-icon">Sector</div>
                                <div className="bli-number" style={{textAlign: 'right', paddingLeft: '15px'}}
                                     onClick={() => {
                                         CISNavigator.navigateToSectorDetail(history, {value: business_classification_code});
                                     }}>
                                    {business_classification}
                                </div>
                            </div>

                            <div className="subsection-line"></div>
                            {(consensus_model.length > 0 || algorithm.length > 0 || transaction_anonimity != null) &&
                            <br></br>}
                            <div className="subsection-header">Technical Perspective</div>
                            {consensus_model.length > 0 &&
                            <div className="bli-inner">
                                <div className="bli-icon">Consensus Algorithm</div>
                                <div className="bli-number" style={{textAlign: 'right', paddingLeft: '15px'}}>
                                    {consensus_model}
                                </div>
                            </div>
                            }

                            {algorithm.length > 0 &&
                            <div className="bli-inner">
                                <div className="bli-icon">Hash Algorithm</div>
                                <div className="bli-number" style={{textAlign: 'right', paddingLeft: '15px'}}>
                                    {algorithm}
                                </div>
                            </div>
                            }

                            {transaction_anonimity != null &&
                            <div className="bli-inner">
                                <div className="bli-icon">Anonymity</div>
                                <div className="bli-number" style={{textAlign: 'right', paddingLeft: '15px'}}>
                                    {transaction_anonimity}
                                </div>
                            </div>
                            }

                            {parent_blockchain_symbol != null &&
                            <div className="bli-inner">
                                <div className="bli-icon">Parent Blockchain</div>
                                <div className="bli-number" style={{textAlign: 'right', paddingLeft: '15px'}}>
                                    {parent_blockchain_symbol}
                                </div>
                            </div>
                            }
                            <div className="subsection-line"></div>
                            <br></br>
                            {asset_type != null &&
                            <div className="subsection-header">Financial Perspective</div>}
                            {asset_type != null &&
                            <div className="bli-inner">
                                <div className="bli-icon">Asset Type</div>
                                <div className="bli-number" style={{textAlign: 'right', paddingLeft: '15px'}}>
                                    {asset_type}
                                </div>
                            </div>
                            }

                            {defi != null && <div className="bli-inner">
                                <div className="bli-icon">DeFi Flag</div>
                                <div className="bli-number" style={{textAlign: 'right', paddingLeft: '15px'}}>
                                    {defi ? "DeFi" : "- "}
                                </div>
                            </div>}


                            {data.defi_info != null &&
                            <React.Fragment>
                                <div className="bli-inner">
                                    <div className="bli-icon">DeFi Category</div>
                                    <div className="bli-number" style={{textAlign: 'right', paddingLeft: '15px'}}>
                                        {data.defi_info.category ? data.defi_info.category : "- "}
                                    </div>
                                </div>
                                <div className="bli-inner">
                                    <div className="bli-icon">DeFi Blockchain</div>
                                    <div className="bli-number" style={{textAlign: 'right', paddingLeft: '15px'}}>
                                        {data.defi_info['chain'] ? data.defi_info['chain'] : "- "}
                                    </div>
                                </div>
                            </React.Fragment>
                            }
                            <div className="subsection-line"></div>

                        </li>
                    </ul>
                </div>
            </div>
            :
            <div className="gray-container basic-lists">
                <div className="section-header">Taxonomy</div>
                <div className="basic-lists-inner">
                    <ul style={{height: '400px', overflowY: 'scroll', paddingRight: "10px"}} tabIndex={1}>
                        <li style={{color: '#ffffff'}}>
                            <br/>
                            <div className="subsection-header">Business Perspective</div>
                            <div className="bli-inner" style={{cursor: "pointer"}}>
                                <div className="bli-icon">Sector</div>
                                <div className="bli-number" style={{textAlign: 'right', paddingLeft: '15px'}}
                                     onClick={() => {
                                         CISNavigator.navigateToSectorDetail(history, {value: business_classification_code});
                                     }}>
                                    {business_classification}
                                </div>
                            </div>
                            {checkBusinessPerspective() && <div className="subsection-line"/>}
                            {checkTechnicalPerspective() && <br/>}
                            {checkTechnicalPerspective() &&
                            <div className="subsection-header">Technical Perspective</div>}
                            {consensus_model.length > 0 && <div className="bli-inner">
                                <div className="bli-icon">Consensus Algorithm</div>
                                <div className="bli-number" style={{textAlign: 'right', paddingLeft: '15px'}}>
                                    {consensus_model}
                                </div>
                            </div>}
                            {algorithm.length > 0 && <div className="bli-inner">
                                <div className="bli-icon">Hash Algorithm</div>
                                <div className="bli-number" style={{textAlign: 'right', paddingLeft: '15px'}}>
                                    {algorithm}
                                </div>
                            </div>}
                            {transaction_anonimity != null && <div className="bli-inner">
                                <div className="bli-icon">Anonymity</div>
                                <div className="bli-number" style={{textAlign: 'right', paddingLeft: '15px'}}>
                                    {transaction_anonimity}
                                </div>
                            </div>}
                            {parent_blockchain_symbol != null && <div className="bli-inner">
                                <div className="bli-icon">Parent Blockchain</div>
                                <div className="bli-number" style={{textAlign: 'right', paddingLeft: '15px'}}>
                                    {parent_blockchain_symbol}
                                </div>
                            </div>}
                            {checkTechnicalPerspective() && <div className="subsection-line"/>}
                            {checkFinancialPerspective() && <br/>}
                            {checkFinancialPerspective() &&
                            <div className="subsection-header">Financial Perspective</div>}
                            {asset_type != null && <div className="bli-inner">
                                <div className="bli-icon">Asset Type</div>
                                <div className="bli-number" style={{textAlign: 'right', paddingLeft: '15px'}}>
                                    {asset_type}
                                </div>
                            </div>}
                            {stablecoin != null && <div className="bli-inner">
                                <div className="bli-icon">Stable Coin Flag</div>
                                <div className="bli-number" style={{textAlign: 'right', paddingLeft: '15px'}}>
                                    {stablecoin ? "Stable" : "- "}
                                </div>
                            </div>}

                            {defi != null && <div className="bli-inner">
                                <div className="bli-icon">DeFi Flag</div>
                                <div className="bli-number" style={{textAlign: 'right', paddingLeft: '15px'}}>
                                    {defi ? "DeFi" : "- "}
                                </div>
                            </div>}

                            {data.defi_info != null &&
                            <React.Fragment>
                                <div className="bli-inner">
                                    <div className="bli-icon">DeFi Category</div>
                                    <div className="bli-number" style={{textAlign: 'right', paddingLeft: '15px'}}>
                                        {data.defi_info.category ? data.defi_info.category : "- "}
                                    </div>
                                </div>
                                <div className="bli-inner">
                                    <div className="bli-icon">DeFi Blockchain</div>
                                    <div className="bli-number" style={{textAlign: 'right', paddingLeft: '15px'}}>
                                        {data.defi_info['chain'] ? data.defi_info['chain'] : "- "}
                                    </div>
                                </div>
                            </React.Fragment>
                            }

                            {checkFinancialPerspective() && <div className="subsection-line"/>}
                            {checkEconomyPerspective() && <br/>}
                            {checkEconomyPerspective() &&
                            <div className="subsection-header">Token Economy Perspective</div>}
                            {mineable != null && <div className="bli-inner">
                                <div className="bli-icon">Mineable Token Flag</div>
                                <div className="bli-number" style={{textAlign: 'right', paddingLeft: '15px'}}>
                                    {mineable ? "Mineable" : "- "}
                                </div>
                            </div>}

                            {checkEconomyPerspective() && <div className="subsection-line"/>}
                        </li>
                    </ul>
                </div>
            </div>
    );
};

export default withRouter(Taxonomy);
