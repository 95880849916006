import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import TradingView from "../../tradingview";
import Api from "../../api";
import DataMappers from "../../helpers/DataMappers";
import Select from "react-select";
import Loading from "../Loading";
import ArbitrageGrid from "../ArbitrageGrid/ArbitrageGrid";
import RealtimeUtils from "../../helpers/RealtimeUtils";
import ToggleButton from "react-toggle-button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";


const ArbitrageGridMarket = (props) => {
    // Props selected coin and ccy , and get exchanges from metadata
    const {exchanges, coinSymbol} = props;

    // All pairs related to selected market
    const [pairOptions, setPairOptions] = useState([]);
    // Selected Pair buy sell
    const [selectedPairBS, setSelectedPairBS] = useState("");
    // Selected Pair sell buy
    const [selectedPairSB, setSelectedPairSB] = useState("");
    // Buy Sell Data
    const [buySellData,setBuySellData] = useState({data:[],loading:true});
    // Sell Buy Data
    const [sellBuyData,setSellBuyData] = useState({data:[],loading:true});
    // Loading Control
    const [loading,setLoading] = useState(true);
    // Set arbitrage type
    const [arbitrage,setArbitrage] = useState(false);
    // BS only latest checkbox
    const [onlyBS,setOnlyBS] = useState(true);
    // SB only latest checkbox
    const [onlySB,setOnlySB] = useState(true);


    useEffect(()=>{
        // Generate search params
        setSelectedPairBS("");
        const params = {
            page_number: 1,
            is_exchange: true,
            base_asset : coinSymbol,
            order_by_field: 'v',
            order_by_direction: 'desc',
        };

        Api.spotSearch(params, 100).then(async res => {
            res.data = res.data || [];

            res.data.forEach(el => el.ticker= el.ticker || {});
            // Generate readable tickers
            res.data.forEach(el => {
                el.org_ticker = el.ticker;
                el.ticker = DataMappers.spotTickerMapper(el.ticker);
            });

            let pairs = [];

            if(res.pairs){
                res.pairs.forEach(el=>{
                    pairs.push({value:el,label:el});
                })
                setPairOptions(pairs);
                setSelectedPairBS({value:res.data[0].pair,label:res.data[0].pair});
                setSelectedPairSB({value:res.data[0].pair,label:res.data[0].pair});
            }
        });
    },[])


    useEffect(()=>{
        setLoading(true);
        if(pairOptions.length > 0 && selectedPairBS !== ""){
            setBuySellData({...buySellData,loading: true});
            // Generate search params
            const params = {
                page_number: 1,
                is_exchange: true,
                base_asset : selectedPairBS.value.split("/")[0],
                quoted_asset: selectedPairBS.value.split("/")[1]
            };

            Api.spotSearch(params, 100).then(async res => {
                res.data = res.data || [];

                res.data.forEach(el => el.ticker= el.ticker || {});
                // Generate readable tickers
                res.data.forEach(el => {
                    el.org_ticker = el.ticker;
                    el.ticker = DataMappers.spotTickerMapper(el.ticker);
                });
                setBuySellData({data:res.data,loading: false});
                setLoading(false);
            });
        }else{
            setBuySellData({data: [],loading: false});
            setLoading(false);
        }
    },[selectedPairBS,arbitrage])

    useEffect(()=>{
        setLoading(true);
        if(pairOptions.length > 0 && selectedPairSB !== ""){
            // Generate search params
            setSellBuyData({...sellBuyData,loading: true})
            const params = {
                page_number: 1,
                is_exchange: true,
                base_asset : selectedPairSB.value.split("/")[0],
                quoted_asset: selectedPairSB.value.split("/")[1]
            };

            Api.spotSearch(params, 100).then(async res => {
                res.data = res.data || [];

                res.data.forEach(el => el.ticker= el.ticker || {});
                // Generate readable tickers
                res.data.forEach(el => {
                    el.org_ticker = el.ticker;
                    el.ticker = DataMappers.spotTickerMapper(el.ticker);
                });
                setSellBuyData({data:res.data,loading: false});
                setLoading(false);
            });
        }else{
            setSellBuyData({data:[],loading: false});
            setLoading(false);
        }
    },[selectedPairSB, arbitrage])

    const onChangePrefs = (val) => {
        setArbitrage(val);
    }


    return (
        <div className={`gray-container`} style={{borderTopLeftRadius:"0px",borderTopRightRadius:"0px"}}>
            <Loading loading={false}>
                <div className="row">
                    <div className="col-lg-12 col-md-12" style={{marginTop:"15px"}}>

                        <div style={{paddingTop: "10px", marginLeft: "5px", display:"flex"}}>
                            <div style={{color:"white", fontSize:"17px",bottom:"0"}}>{"ARBITRAGE"}</div>
                        </div>
                        <div style={{paddingTop: "10px", marginLeft: "5px", display:"flex"}}>
                            <div style={{color:"white", fontSize:"14px",bottom:"0"}}>
                                BUY / SELL
                            </div>
                            <div style={{marginLeft:"5px",marginRight:"5px"}}>
                                <ToggleButton
                                    inactiveLabel={''}
                                    activeLabel={''}
                                    value={arbitrage}
                                    colors={{
                                        activeThumb: {
                                            base: 'rgb(250,250,250)',
                                        },
                                        inactiveThumb: {
                                            base: 'rgb(250,250,250)',
                                        },
                                        active: {
                                            base: 'rgb(62,130,247)',
                                            hover: 'rgb(62,130,247)',
                                        },
                                        inactive: {
                                            base: 'rgb(62,130,247)',
                                            hover: 'rgb(62,130,247)',
                                        }
                                    }}
                                    onToggle={(value) => {
                                        onChangePrefs(!value);
                                    }} />
                            </div>
                            <div style={{color:"white", fontSize:"14px",bottom:"0"}}>
                                SELL / BUY
                            </div>
                        </div>
                        <React.Fragment>
                            <div style={{display:"flex",marginBottom:"10px"}}>
                                <div style={{paddingTop: "10px", marginLeft: "5px", display:"flex"}}>
                                    {
                                        !arbitrage ?
                                            <React.Fragment>
                                                <div style={{padding: "0px", width: "150px"}}>
                                                    <Select
                                                        value={selectedPairBS}
                                                        onChange={(e) => setSelectedPairBS(e)}
                                                        options={pairOptions}
                                                        placeholder="Pair"
                                                    />
                                                </div>
                                                <div style={{marginLeft:"10px"}}>
                                                    <div style={{display:"flex"}}>
                                                        <div className="onlyLatest">
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={onlyBS}
                                                                            onChange={()=> setOnlyBS(!onlyBS)}
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div className="spa-text" style={{textAlign:"left", marginTop: "12px",marginRight:"0px",color:"white"}}>Only Latest</div>
                                                    </div>
                                                </div>
                                            </React.Fragment>

                                            :
                                            <React.Fragment>
                                                <div style={{padding: "0px", width: "150px"}}>
                                                    <Select
                                                        value={selectedPairSB}
                                                        onChange={(e) => setSelectedPairSB(e)}
                                                        options={pairOptions}
                                                        placeholder="Pair"
                                                    />
                                                </div>
                                                <div style={{marginLeft:"10px"}}>
                                                    <div style={{display:"flex"}}>
                                                        <div className="onlyLatest">
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={onlySB}
                                                                            onChange={()=> setOnlySB(!onlySB)}
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div className="spa-text" style={{textAlign:"left", marginTop: "12px",marginRight:"0px",color:"white"}}>Only Latest</div>
                                                    </div>
                                                </div>
                                            </React.Fragment>

                                    }
                                </div>
                            </div>
                            <Loading loading={loading}>
                                {
                                    !arbitrage ?
                                        <ArbitrageGrid data={buySellData.data} selectedPair={selectedPairBS} type={"BS"} visible={onlyBS}/>
                                        :
                                        <ArbitrageGrid data={sellBuyData.data} selectedPair={selectedPairSB} type={"SB"} visible={onlySB}/>
                                }
                            </Loading>
                        </React.Fragment>
                    </div>
                </div>
            </Loading>
        </div>
    );
};


const mapStateToProps = state => {
    return {
        meta: state.meta,
        exchanges: state.meta.exchanges
    };
};

export default connect(mapStateToProps)(ArbitrageGridMarket);
