import React, {useEffect, useState} from "react";
import Api from "../../../api";
import Loading from "../../../components/Loading";
import LatestNews from "../../../components/News/LatestNews";
import {connect} from "react-redux";
const News = props => {
    const {
        rawData, // Raw sector metadata
        exchange, // Selected exchange rate
        businessMetadata // Business metadata
    } = props;

    // News of the sector
    const [news, setNews] = useState({items: [], loading: true});
    // Main index code
    const [indexCode, setIndexCode] = useState(rawData && rawData.main_index_benchmark_code);

    // Executes when props are changed
    useEffect(() => {
        // Fetch news for the sector
        if (businessMetadata && businessMetadata.length > 0) {
            fetchNews(businessMetadata);
        }
        // Fetch index for the sector
        setIndexCode(rawData && rawData.main_index_benchmark_code);
    }, [rawData, businessMetadata]);


    // Fetches news for the sector
    const fetchNews = (businessMetadata) => {
        setNews({...news, loading: true});
        // Find which tag to search for the sector
        const tags = businessMetadata.find(el => el.numeric_code === rawData.numeric_code).news_tag;
        Api.searchNews({tags: tags})
            .then(res => setNews({items: res, loading: false}));
    };

    return (
        <Loading loading={!rawData}>
            <div className="detail-indices-inner">
                <div className="detail-indices-row">
                    <div className="di-left-column">
                       <LatestNews  news={news.items}/>
                    </div>
                </div>
            </div>
        </Loading>
    );
};

const mapStateToProps = state => {
    return {
        businessMetadata: (state.meta && state.meta.lists && state.meta.lists.business_classification_schema)
    };
};

export default connect(mapStateToProps)(News);
