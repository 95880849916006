import React from 'react';
import Utils from "../../helpers/Utils";

const StateBarSectorRow = props => {
  const {
    displayData, // Contains single sector summary display response
    rawData, // Contains single sector summary raw response
    selectedExchange // Information of selected exchange
  } = props;

  // Extract selected exchange value for viewing purposes
  const exchange = selectedExchange.value;

  // Detect color of the percentage
  let percentageClass = Utils.textColorPolarity(rawData.change_24h_pct);

  return (
      <div className="row row-center">
        <div className="col-lg-11 col-md-11" id="col-mobile">
          <div className="grid-group" id="grid-group-mobile">
            <div className={"row grid-group-item"} style={{"marginLeft" : "1em"}}>
              <div className="primary-text primary-value">
                {displayData.business_classification + '(' + displayData.alphanumeric_code + ')'}
              </div>
            </div>
            <div className={"row grid-group-item"} style={{"marginLeft" : "7em"}}>
              <div className="secondary-text">
                Sector Mkt Cap ({exchange})
              </div>
              <div className="primary-text primary-value">
                {displayData.market_cap}
              </div>
            </div>
            <div className={"row grid-group-item"}>
              <div className="secondary-text">
                Sector 24h VOL
              </div>
              <div className="primary-text primary-value">
                {displayData.volume_24h}
              </div>
            </div>
            <div className={"row grid-group-item"}>
              <div className="secondary-text">
                Sector 24h Mkt Cap Chg ({exchange})
              </div>
              <div className={`primary-text primary-value ${percentageClass}`}>
                {displayData.change_24h_pct}
              </div>
            </div>
            <div className={"row grid-group-item"}>
              <div className="secondary-text">
                Coins
              </div>
              <div className="primary-text primary-value primary-value-coin-mobile">
                {displayData.num_coins}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-1 hide-content">
          <div className="pull-right">
            <a target="_blank" rel="noopener noreferrer" href={rawData.document_url}>
              <img className="actionable-img" src="/img/ic_document.png" alt="" style={{"height": "50px"}}/>
            </a>
          </div>
        </div>
      </div>
  );
};

export default StateBarSectorRow;
