import React from 'react';
import {Route, Switch} from 'react-router-dom';
import TopMenu from '../../components/Menu/TopMenu';
import LeftMenu from '../../components/Menu/LeftMenu/LeftMenu';
import Footer from '../../components/Footer';
import Home from './Home';
import WatchList from '../../pages/WatchList';
import CoinIndicesDetail from '../../pages/CoinIndicesDetail';
import Coins from '../../pages/Coins';
import Trade from '../../pages/Trade';
import News from '../../pages/News';
import Contact from '../../pages/Contact';
import Overview from '../../pages/Contact';
import NotFoundPage from '../../pages/NotFoundPage';
import Sectors from '../../pages/Sectors';
import Indices from '../../pages/Indices';
import IndicesDetail from '../../pages/Indices/IndicesDetail';
import SectorsDetail from '../../pages/Sectors/SectorDetail';
import Profile from '../../pages/Profile';
import Exchanges from "../Exchanges";
import Alerts from "../Alerts"
import {checkFeatureAccess, getAccessibleComponent, getStepUpComponent, useAuth0} from "../../helpers/Auth0";
import Portfolio from "../Portfolio";
import WelcomeModal from "../Welcome/WelcomeModal";
import Utils from "../../helpers/Utils";
import {ChatComponent} from "../../components/ChatComponent/ChatComponent";
import ArbitrageApp from "../ArbitrageApp";

const isMobile = Utils.checkMobile();

const Dashboard = props => {
  // User information and credentials
  const {user} = useAuth0();

  return (
    <React.Fragment>

        <div className="gray-bg" id="body-scroll" style={{display: "flex", flexDirection: "column"}}>
      <TopMenu {...props} />
      <LeftMenu />
      {
        !isMobile && checkFeatureAccess(user,"chat_support") &&
        <ChatComponent/>
      }
      <div style={{flex: "1 1 auto", display: "flex", flexDirection: "column"}}>
        <div style={{flex: "1 1 auto"}}>
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route path="/watch-list" component={WatchList} />
            {getAccessibleComponent(user, "/coins/:coinSymbol/:additional", "coin_taxanomy", CoinIndicesDetail)}
            <Route path="/coins" component={Coins} />
            {getAccessibleComponent(user, "/trade", "trading", Trade)}
            {/*{getStepUpComponent("/trade", "2fa-required", <Trade/>)}*/}
            {getAccessibleComponent(user, "/sectors/:secSymbol/:additional", "sector_overview", SectorsDetail)}
            <Route path="/sectors" component={Sectors} />
            <Route path="/indices/:idxSymbol/:additional" component={IndicesDetail} />
            <Route path="/indices" component={Indices} />
            {getAccessibleComponent(user, "/news", "news_search", News)}
            {getStepUpComponent("/portfolio/:additional", "2fa-required", <Portfolio/>, "exchanges")}
            {/*<Route path="/portfolio" component={Portfolio} />*/}
            <Route path="/overview" component={Overview} />
            <Route path="/profile" component={Profile} />
            <Route path="/alerts" component={Alerts} />
            <Route path="/arbitrageapp" component={ArbitrageApp} />
            {/*<Route path="/alerts" component={WorkInProgress} />*/}
            {getStepUpComponent("/exchanges", "2fa-required", <Exchanges/>, "exchanges")}
            {/*{getStepUpComponent("/exchanges", "2fa-required", <Exchanges/>)}*/}
            <Route component={NotFoundPage} />
          </Switch>
        </div>
        <Footer />
      </div>
      {/*<SearchModal /> Gives Red Warning Not Used !!! */}
    </div>

    </React.Fragment>
  );
};

export default Dashboard;
