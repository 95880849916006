import React, {useState} from 'react';
import Breadcrump from '../../components/Breadcrump';
import WatchListTable from './WatchListTable';
import WatchListCreate from './WatchListCreate';
import Utils from "../../helpers/Utils";
import MobileNavBar from "../../components/MobileNavBar/MobileNavBar";

const isMobile = Utils.checkMobile();

const WatchList = () => {
  const [selectedWatchlist, setSelectedWatchlist] = useState(null);

  return (
      isMobile?
          <div>
            <MobileNavBar/>
            <div className="mobile-Div">
              <div className="flex-row" style={{marginTop: "20px"}}>
                <div className="flex-width-left">
                  <WatchListCreate setSelectedWatchlist={setSelectedWatchlist} />
                </div>
                <div className="flex-width-right">
                  <div className="gray-container watch-list-table" style={{display: "flex", flexDirection: "column"}}>
                    <div className="section-header" style={{marginBottom: "20px"}}>Watch List Detail</div>
                    <WatchListTable watchlist={selectedWatchlist} />
                  </div>
                </div>
              </div>
            </div>
            <div style={{height:"80px"}}>
              <p></p>
            </div>
          </div>
          :
          <section className="dashboard padding-sm-container">
              <Breadcrump />
              <div className="flex-row" style={{marginTop: "20px"}}>
                <div className="flex-width-left" style={{width: '35%'}}>
                  <WatchListCreate setSelectedWatchlist={setSelectedWatchlist} />
                </div>
                <div className="flex-width-right" style={{width: '65%'}}>
                  <div className="gray-container watch-list-table" style={{display: "flex", flexDirection: "column"}}>
                    <div className="section-header" style={{marginBottom: "20px"}}>Watch List Detail</div>
                    <WatchListTable watchlist={selectedWatchlist} />
                  </div>
                </div>
              </div>
          </section>
  );
};

export default WatchList;
