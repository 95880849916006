import React, {useEffect, useState} from 'react';
import Api from '../../../api';
import Loading from '../../../components/Loading';
import moment from 'moment';
import DataMappers from "../../../helpers/DataMappers";
import CoinTable from "../../../components/CoinTable/CoinTable";
import Select from "../../../components/Forms/Select";
import Paginate from "../../../components/Paginate";

// Page size selections
const pages = [
  {value: 10, label: '10'},
  {value: 20, label: '20'},
  {value: 50, label: '50'}
];

const IndexConstituents = props => {
  const {
    indexCode, // Which index are we working on
    exchange // Exchange information
  } = props;

  // Index data to show
  const [indexData, setIndexData] = useState(null);
  // Indicates if index data is fetching
  const [indexLoading, setIndexLoading] = useState(true);
  // Indicates if constitutes are fetching
  const [constituteLoading, setConstituteLoading] = useState(true);
  // Constituents data
  const [constituents, setConstituents] = useState([]);
  // Leaver & Joiner data
  const [leaverJoiners, setLeaversJoiners] = useState([]);

  // Table related information variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  // Fetch/Refresh index data on code or exchange changes
  useEffect(() => {
    if (indexCode && exchange) {
      setIndexLoading(true);
      // Read index data for leavers joiners
      Api.readIndexFull(indexCode, exchange.value).then(res => {
        setIndexData(res);
        setIndexLoading(false)
      });
    }
  }, [indexCode, exchange]);

  // Load constituents when paging action or code change
  useEffect(() => {
    if (indexCode && exchange) {
      // Read constituents
      setConstituteLoading(true);
      Api.getIndexConstituents({index_code: indexCode, ccy_code: exchange.value, page_size: pageSize, page_number: currentPage}, true).then(res => {
        // Map ticker values for visualisation
        const mapped = res.data.map(DataMappers.coinDataMapper);

        setConstituents(mapped);
        setTotalPage(res.totalPage);
        setConstituteLoading(false);
      });
    }
  }, [indexCode, exchange, pageSize, currentPage]);

  // Handles pager clicks
  const handlePageClick = d => {
    if (currentPage !== d.selected + 1) {
      setCurrentPage(d.selected + 1);
    }
  };

  // Prepare data for leaver joiner summery
  useEffect(() => {
    if (indexData && indexData.leaver_joiner_summary) {
      setLeaversJoiners(indexData.leaver_joiner_summary)
    }
  }, [indexData]);

  // Change parent row open state
  const rowClicked = (idx) => {
    leaverJoiners[idx].is_open = !leaverJoiners[idx].is_open;
    setLeaversJoiners([...leaverJoiners]);
  };

  // Generate parent row(action) for leavers joiners table
  const generateParentRow = (row, idx) => {
    return <tr key={row.effective_date} onClick={e => {e.stopPropagation(); rowClicked(idx)}}>
      <td style={{textAlign: "center", width: "57px"}}><img style={{margin: "1em"}} className={"actionable-img"} src={row.is_open ? "/img/arrow-down.svg": "/img/arrow-right.svg"}/></td>
      <td colSpan={1} style={{fontWeight: "bold"}}>{moment.utc(row.effective_date).local().format('DD.MM.YYYY')}</td>
      <td colSpan={1} style={{textAlign: "center", fontWeight: "bold"}} className={"table-green-text"}>{row.num_joiners}</td>
      <td colSpan={1} style={{textAlign: "center", fontWeight: "bold"}} className={"table-red-text"}>{row.num_leavers}</td>
      <td colSpan={1} style={{textAlign: "center", fontWeight: "bold"}}>{row.num_constituents}</td>
    </tr>
  };

  // Generates child rows(coins) for leavers joiners table
  const generateChildRows = (row) => {
    return row.leaver_joiners.map(el => {
      return <tr key={row.effective_date + el.coin_symbol} style={{background : "#1f2327"}}>
        <td colSpan={1}/>
        <td colSpan={3} style={{paddingLeft: "2em", fontWeight: "normal"}}>{`${el.coin_name} (${el.coin_symbol})`}</td>
        <td colSpan={1} style={{fontWeight: "normal"}} className={el.action === 'joiner' ? "table-green-text" : "table-red-text"}>{el.action === "joiner" ? "Joiner" : "Leaver"}</td>
      </tr>
    });
  };

  return (
    <div className="container" style={{width: "100%", marginTop: "1vh", paddingLeft: "0px", paddingRight: "0px"}}>
      <div className={"row"} style={{display: "flex", flexFlow: "row shrink"}}>
        <div className={"col-lg-8"} style={{paddingRight: "1vw"}}>
          <Loading loading={constituteLoading}>
            <section className={"coin-table"} style={{paddingBottom: "0px"}}>
              <div className="gray-container" style={{display: "flex", flexDirection: "column", paddingBottom: "0px"}}>
                <div className="table-head">
                  <div className="clearfix" />
                  <div className="table-left"><div className="section-header">Constituents</div></div>
                  <div className="table-right">
                    <div className="table-select-input">
                      <div className="sm-select-input small-select">
                        <Select selected={pageSize} options={pages} onChange={selected => setPageSize(selected.value)} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-body table-responsive constituents-list" style={{marginTop: "1vh"}} id="beauty-scroll7">
                  <CoinTable coinData={constituents} exchange={exchange} disabledColumns={["name", "set_watchlist"]}/>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: totalPage > 1 ? '0' : '2em'}}>
                    {totalPage > 1 && <Paginate pageCount={totalPage} onPageChange={handlePageClick} active={currentPage - 1} />}
                  </div>
                </div>
              </div>
            </section>
          </Loading>
        </div>
        <div className={"col-lg-4"} style={{paddingLeft: "0px"}}>
          <Loading loading={indexLoading}>
            <div className="gray-container" style={{display: "flex", flexDirection: "column", height: "100%"}}>
              <div className="section-header" style={{flex: "0 0 auto"}}>Leavers & Joiners</div>
              <div className="table-body table-responsive" style={{marginTop: "1vh", flex: "1 1 1px"}}>
                <table className="coins enumarable-table">
                  <thead>
                  <tr>
                    <th style={{width: "57px"}}/>
                    <th colSpan={1} style={{paddingLeft: "6px"}}>Action Date</th>
                    <th colSpan={1} style={{textAlign: "center"}}># Joiners</th>
                    <th colSpan={1} style={{textAlign: "center"}}># Leavers</th>
                    <th colSpan={1} style={{textAlign: "center"}}># Constituents</th>
                  </tr>
                  </thead>
                  <tbody>
                  {leaverJoiners.map((el, idx) => {
                    if (el.is_open) {
                      return [generateParentRow(el, idx), generateChildRows(el)];
                    } else {
                      return generateParentRow(el, idx)
                    }
                  })}
                  </tbody>
                </table>
              </div>
            </div>
          </Loading>
        </div>
      </div>
    </div>
  );
};

export default IndexConstituents;
