import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import TopBannerArea from '../../components/BannerAreas/TopBannerArea';
import Watchlist from '../../components/PageDashboard/Watchlist';
import TopIndices from '../../components/PageDashboard/TopIndices/TopIndices';
import LatestNews from '../../components/News/LatestNews';
import Api from '../../api';
import SmallCoinList from '../../components/SmailCoinList/SmallCoinList';
import TradingView from '../../tradingview/App';
import {useAuth0} from "../../helpers/Auth0";
import Utils from "../../helpers/Utils";
import MobileNavBar from "../../components/MobileNavBar/MobileNavBar";
import PositiveNews from "../../components/News/PositiveNews";
import NegativeNews from "../../components/News/NegativeNews";
import Loading from "../../components/Loading";
import TopDominanceArea from "../../components/TopDominanceArea";

const isMobile = Utils.checkMobile();

const filterParam = {
    page_size: isMobile ? 10 : 20,
    asset_type: '',
    coin_symbols: '',
    business_classification: '',
    ccy: 'USD',
    order_by: 'mkt_cap,desc',
    ts_cutoff: 0,
    page_number: 1,
};

// Today date
const today = new Date();

// Timeframe options 1h => 3600000 mili sec
const options = [
    {time: 2 * 3600000, label: "Last 2h"},
    {time: 6 * 3600000, label: "Last 6h"},
    {time: 12 * 3600000, label: "Last 12h"},
    {time: 24 * 3600000, label: "Last 24h"}
]

const Dashboard = props => {
    const {meta} = props;
    const {user} = useAuth0();
    const [indexes, setIndexes] = useState({items: [], loading: true});
    const [news, setNews] = useState({items: [], loading: true});
    const [result, setResult] = useState({items: [], loading: true});
    const [exchange, setExchange] = useState(user.preferences.currency);
    const [exchange2, setExchange2] = useState(user.preferences.currency);
    const [watchListShow, setWatchListShow] = useState(user.preferences.show_watchlist);
    const [selectedExchange, setSelectedExchange] = useState({value: user.preferences.currency});
    // Coins menu
    const [coinMenu, setCoinMenu] = useState("TOPCOINS");
    // News menu
    const [newMenu, setNewsMenu] = useState("LATEST");
    // Time frame selected option defaul 24h
    const [selectedTimeFrame, setSelectedTimeFrame] = useState(options[3]);
    // Loading flag
    const [loading,setLoading] = useState(true);
    // Loading Coins flag
    const [loadingC,setLoadingC] = useState(true);
    // Selected Market Cap default 3,2,1 mid high huge
    const [marketCap,setMarketCap] = useState([3,2,1]);

    const currencyList =
        (meta.lists &&
            meta.lists.key_currency_list &&
            meta.lists.key_currency_list.map(c => ({
                value: c[0],
                label: c[0],
                type: c[2] === 'FIAT' ? 'USD' : 'BTC'
            }))) ||
        [];

    // News menu or TimeFrame changed re arrange news
    useEffect(() => {
        setLoading(true);
        if(newMenu === "NEWSPOSITIVE"){
            fetchNews("positive",10);
        }else if(newMenu === "NEWSNEGATIVE"){
            fetchNews("negative",10);
        }else if(newMenu === "LATEST"){
            fetchNews();
        }else{
            setLoading(false);
        }
    }, [newMenu,selectedTimeFrame]);

    // Coin menu changed re arrange coins
    useEffect(()=>{
        setLoadingC(true);
        let selectedExchange = currencyList.filter(e => e.value === exchange);
        selectedExchange = (selectedExchange.length && selectedExchange[0]) || {};
        setSelectedExchange(selectedExchange);
        fetchData(exchange);
    },[coinMenu,exchange,marketCap])

    // Widget
    useEffect(() => {
        if(document.getElementById("economicCalendarWidgetImg")){
            const script = document.createElement('script');
            script.src = "https://s3.tradingview.com/external-embedding/embed-widget-events.js";
            script.async = true;
            const textNode = document.createTextNode(` { "colorTheme": "dark", "isTransparent": true, "width": "100%", "height": "100%", "locale": "en", "importanceFilter": "-1,0,1"}`)
            script.appendChild(textNode)

            document.getElementById("economicCalendarWidgetImg").appendChild(script);
        }
    }, [newMenu]);

    useEffect(() => {
        fetchIndexes(exchange2);
    }, [exchange2]);

    const fetchIndexes = ex => {
        setIndexes({items: [], loading: true});
        Api.getPromise(`/search/IndexSearch?order_by=mkt_cap,desc&ccy=${ex}&page_size=12`)
            .then(res => {
                setIndexes({items: (res && res.data) || [], loading: false});
            })
            .catch(e => setIndexes({items: [], loading: false}));
    };

    const fetchNews = (status, size) => {
        let f = {}
        if (status === "positive" || status === "negative") {
            f = {
                min_publication_datetime: today.getTime() - selectedTimeFrame.time,
                page_size: size,
                order_by: status === "positive" ?  'title_sentiment,desc':'title_sentiment,asc'
            }
        }
        if (isMobile) {
            f = {page_size: 5};
        }
        setNews({...news, loading: true});
        Api.getPromise(`/News/NewsSearch?language_code=en`, f)
            .then(res => {
                setNews({items: (res && res.data) || [], loading: false});
                setLoading(false);
            })
            .catch(e => setNews({items: [], loading: false}));
    };

    const fetchData = ex => {
        let f = filterParam;
        setResult({items: [], loading: true});
        if(coinMenu === "TOPCOINS"){
            f.ccy = ex;
            f.page_size = 20;
            f.order_by = 'mkt_cap,desc';
            f.market_cap_category = "";
            f.chgpct_24h_max = "";
            f.chgpct_24h_min = "";

            setTimeout(() => {
                Api.getPromise('/Search/CoinSearch', f)
                    .then(res => {
                        const data = (res && res.data) || [];
                        setResult({items: data, loading: false});
                        setLoadingC(false);
                    })
                    .catch(e => setResult({items: [], loading: false}));
            }, 500);
        }else{
            f.order_by = coinMenu === "LOSERS" ? 'chgpct,asc':'chgpct,desc';
            f.page_size = 20;
            f.ccy = ex;
            if(coinMenu === "LOSERS"){
                f.chgpct_24h_max = 0
                f.chgpct_24h_min = ""
            }else if(coinMenu === "GAINERS"){
                f.chgpct_24h_max = ""
                f.chgpct_24h_min = 0
            }

            f.market_cap_category = marketCap.join().toString() || "";

            setTimeout(() => {
                Api.getPromise('/Search/CoinSearch', f)
                    .then(res => {
                        const data = (res && res.data) || [];
                        setResult({items: data, loading: false});
                        setLoadingC(false);
                    })
                    .catch(e => setResult({items: [], loading: false}));
            }, 500);
        }


    };

    return (
        isMobile ?
            <div>
                <MobileNavBar/>
                <div>
                    {watchListShow ? <Watchlist/> : <></>}
                </div>
                <div className="mobile-Div">
                    <div className="chart-area">
                        <TradingView symbol={"BTC"} exchange={selectedExchange.value}/>
                    </div>
                </div>
                <div className="mobile-Div">
                    <SmallCoinList
                        homeTable
                        columnClass={'top-coins'}
                        subClass={'gray-container'}
                        selectedExchange={selectedExchange}
                        setResult={setResult}
                        exchange={exchange}
                        setExchange={setExchange}
                        result={result}
                        title={`TOP COINS`}
                    />
                </div>
                <div className="mobile-Div">
                    <TopIndices indexes={indexes} exchange={exchange2} setExchange={setExchange2}
                                selectedExchange={selectedExchange} currencyList={currencyList}/>
                </div>
                <div className="mobile-Div">
                    <LatestNews news={news.items}/>
                </div>
                <div style={{height: "80px"}}>

                    <p></p>
                </div>
            </div>
            :
            <section className="dashboard padding-sm-container">
                <TopBannerArea/>
                <TopDominanceArea/>
                {watchListShow ? <Watchlist/> : <></>}
                <div className="flex-row">
                    <div className="flex-width-left" style={{display:"flex", flexDirection:"column"}}>
                        <div className="chart-area">
                            <TradingView symbol={"BTC"} exchange={selectedExchange.value}/>
                        </div>
                        <div className="indices-news-part" style={{flexGrow:"1"}}>
                            <div className="inp-column" style={{width: "100%",display:"flex",flexDirection:"column"}}>
                                <div className={"inline-tab-wrapper"}>
                                    <ul className={"inline-tab"}>
                                        <li key={"LATEST"}><button className={newMenu === 'LATEST' ? "inline-tab-button-active" : "inline-tab-button"} onClick={e => {e.stopPropagation();  setNewsMenu('LATEST')}}>Latest News</button></li>
                                        <li key={"NEWSPOSITIVE"}><button className={newMenu === 'NEWSPOSITIVE' ? "inline-tab-button-active" : "inline-tab-button"} onClick={e => {e.stopPropagation();  setNewsMenu('NEWSPOSITIVE')}}>Top 10 (+) News</button></li>
                                        <li key={"NEWSNEGATIVE"}><button className={newMenu === 'NEWSNEGATIVE' ? "inline-tab-button-active" : "inline-tab-button"} onClick={e => {e.stopPropagation();  setNewsMenu('NEWSNEGATIVE')}}>Top 10 (-) News</button></li>
                                        <li key={"CALENDAR"}><button className={newMenu === 'CALENDAR' ? "inline-tab-button-active" : "inline-tab-button"} onClick={e => {e.stopPropagation();  setNewsMenu('CALENDAR')}}>Economic Calendar</button></li>
                                    </ul>
                                </div>
                                <div className="gray-container gray-container-extra" style={{flexGrow: "1"}}>
                                    <Loading loading={loading}>
                                        {newMenu === "LATEST" &&
                                        <LatestNews news={news.items} v2={true}/>
                                        }
                                        {newMenu === "NEWSPOSITIVE" &&
                                        <PositiveNews news={news.items} selectedOption={selectedTimeFrame} setSelectedOption={setSelectedTimeFrame} allOptions={options}/>
                                        }
                                        {newMenu === "NEWSNEGATIVE" &&
                                        <NegativeNews news={news.items} selectedOption={selectedTimeFrame} setSelectedOption={setSelectedTimeFrame} allOptions={options}/>
                                        }
                                        {newMenu === "CALENDAR" &&
                                            <div>
                                                <div id="economicCalendarWidgetImg"
                                                     style={{height:"400px",padding: "0px"}}
                                                     className="col-md-12">
                                                        {/*<script type="text/javascript" src="https://widgets.myfxbook.com/scripts/fxCalendar.js"></script>*/}
                                                </div>
                                                <div style={{color:"white"}}>
                                                    Economic Calendar by <a href='https://www.tradingview.com/markets/currencies/economic-calendar/' className='myfxbookLink' target='_self' rel='noopener noreferrer'><strong>TradingView</strong></a>
                                                </div>
                                            </div>
                                        }
                                    </Loading>
                                </div>
                            </div>
                            {/*<TopIndices indexes={indexes} exchange={exchange2} setExchange={setExchange2} selectedExchange={selectedExchange} currencyList={currencyList} />*/}
                        </div>
                    </div>
                    <div className="flex-width-right">
                        <div className="inp-column" style={{width: "100%",display:"flex",flexDirection:"column",height:"100%"}}>
                                <div className={"inline-tab-wrapper"} style={{marginTop:"15px"}}>
                                    <ul className={"inline-tab"}>
                                        <li key={"TOPCOINS"}><button className={coinMenu === 'TOPCOINS' ? "inline-tab-button-active" : "inline-tab-button"} onClick={e => {e.stopPropagation();  setCoinMenu('TOPCOINS')}}>Top 20 Pairs</button></li>
                                        <li key={"GAINERS"}><button className={coinMenu === 'GAINERS' ? "inline-tab-button-active" : "inline-tab-button"} onClick={e => {e.stopPropagation();  setCoinMenu('GAINERS')}}>Top 20 Gainers</button></li>
                                        <li key={"LOSERS"}><button className={coinMenu === 'LOSERS' ? "inline-tab-button-active" : "inline-tab-button"} onClick={e => {e.stopPropagation();  setCoinMenu('LOSERS')}}>Top 20 Losers</button></li>
                                    </ul>
                                </div>
                                {coinMenu === "TOPCOINS" &&
                                <SmallCoinList
                                    loadingC={loadingC}
                                    homeTable
                                    columnClass={'top-coins top-coins-extra'}
                                    subClass={'gray-container gray-container-extra'}
                                    selectedExchange={selectedExchange}
                                    setResult={setResult}
                                    exchange={exchange}
                                    setExchange={setExchange}
                                    result={result}
                                    title={`TOP COINS`}
                                />
                                }
                                {coinMenu === "GAINERS" &&
                                <SmallCoinList
                                    loadingC={loadingC}
                                    gainerLoser
                                    marketCap={marketCap}
                                    setMarketCap={setMarketCap}
                                    columnClass={'top-coins top-coins-extra'}
                                    subClass={'gray-container gray-container-extra'}
                                    selectedExchange={selectedExchange}
                                    setResult={setResult}
                                    exchange={exchange}
                                    setExchange={setExchange}
                                    result={result}
                                    title={`TOP COINS`}
                                />
                                }
                                {coinMenu === "LOSERS" &&
                                <SmallCoinList
                                    loadingC={loadingC}
                                    gainerLoser
                                    marketCap={marketCap}
                                    setMarketCap={setMarketCap}
                                    columnClass={'top-coins top-coins-extra'}
                                    subClass={'gray-container gray-container-extra'}
                                    selectedExchange={selectedExchange}
                                    setResult={setResult}
                                    exchange={exchange}
                                    setExchange={setExchange}
                                    result={result}
                                    title={`TOP COINS`}
                                />
                                }
                        </div>

                    </div>
                </div>
            </section>
    );
};

const mapStateToProps = state => ({
    meta: state.meta
});

export default connect(mapStateToProps)(Dashboard);
