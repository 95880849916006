import React, {useState} from 'react';
import UserApi from "../../../api/UserApi";



const DeleteProfileModal = props => {
    const {cancel, show} = props;
    const [confirm,setConfirm] = useState(false);

    const modalCancel = () =>{
        setConfirm(false);
        cancel();
    }


    const deleteAccount = () =>{
        setConfirm(true);
        UserApi.deleteProfile();
    }


    return(
            <div className={`general-modal ${(show && 'active') || ''}`} style={{zIndex: 10000}} id="delete-modal-wlitem"  >
                <div className="backexit" style={{zIndex:"-20px"}} >
                    <div className="vAlignWr" >
                        <div className="vAlign" >
                            <div className="white-modalfornews-inner set-alert" >
                                <div className="modal-blue-header" style={{ display: "flex", flexDirection: "row", paddingBottom: "7px",justifyContent: "flex-end"}}  >

                                    <div onClick={modalCancel} data-target="delete-modal-wlitem" style={{cursor: "pointer"}}>
                                        <img src="/img/close-white.svg" alt="" style={{"padding": "5px"}}/>
                                    </div>
                                </div>
                                <div className="modal-white-body" >
                                    <div style={{display: "flex", flexDirection: "column"}}>
                                        <div className="remove-flex" style={{display: 'flex', flexDirection: 'row'}}>

                                            <div className="modal-content-width" style={{color: '#fff', width: 500, flex: "1 1 500px", marginLeft: "1vw", textAlign: "justify", lineHeight: "1.5", display: "flex", flexDirection: "column"}}>
                                                <p style={{textAlign:"center"}}>{confirm? "Check your mail for confirmation!": "Are you sure want to delete account?"}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!confirm &&
                                <div className="modal-gray-footer">
                                    <div className="form-buttons">
                                        <div className="form-flex">
                                            <div className="form-flex-inner">
                                                <div className="light-gray-btn">
                                                    <button onClick={modalCancel} className="close-modal" data-target="set-news-alert">
                                                        CANCEL
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="form-flex-inner">
                                                <div className="light-blue-btn">
                                                    <button style={{background:"#c11e0f"}} onClick={()=>{deleteAccount()}}>
                                                        DELETE
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="clearfix" />
                                    </div>
                                </div>}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );

};

export default DeleteProfileModal;
