import Api from "../index";
import Links from "../../constants/Links";

// General subscription value
let swRegistration = null;
// Indicates if user subscribed
let isSubscribed = false;
// Public key for receiving notifications
const publicKey = "BK2J1Bm3GsVvAuK-_wxF7Rybk1kTOdattKP1WrzqFHLmo0JfHqDuiD76lVbulXJF_r0dyG1jQEh7nrN-rdJHzI0";

const NotificationApi = {
    getPromise(endpoint, params) {
        return Api.getPromise(endpoint, params, Links.BASE_NOTIFICATION_URL)
    },
    postPromise(endpoint, body) {
        return Api.postPromise(endpoint, body, Links.BASE_NOTIFICATION_URL)
    },
    postMPPromise(endpoint, body){
        return Api.postMPPromise(endpoint, body, Links.BASE_NOTIFICATION_URL)
    },
    putPromise(endpoint, body) {
        return Api.putPromise(endpoint, body, Links.BASE_NOTIFICATION_URL)
    },
    deletePromise(endpoint, params) {
        return Api.deletePromise(endpoint, params, Links.BASE_NOTIFICATION_URL)
    },

    /**
     * Register user device
     *
     * @param metadata
     */
    registerDevice(metadata) {
       return this.postPromise("", {device_type: "webpn", metadata: metadata}).then(resp => {
           return true;
       }).catch(err => {
           console.error("Error on registering device...", err);
           return false;
       })
    },

    /**
     * Get notifications of the user
     *
     * @return
     */
    getNotifications(setSeen) {
        // Set seen of read messages if set seen is true
        const params = setSeen && {
            set_seen: setSeen
        };

        return this.getPromise("", params || {}).then(resp => {
            return resp;
        }).catch(err => {
            console.error("Error while receiving notifications...", err);
            return [];
        })
    },

    /**
     * Utility function for parsing public VAPID key
     *
     * @param base64String
     * @return {Uint8Array}
     */
    urlB64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    },

    /**
     * Subscribe user to web push notifications
     */
    subscribeUser() {
        const applicationServerKey = this.urlB64ToUint8Array(publicKey);
        !isSubscribed && swRegistration && swRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: applicationServerKey
        }).then(async function(subscription) {
            // Get public key and user auth from the subscription object
            const key = subscription.getKey ? subscription.getKey('p256dh') : '';
            const auth = subscription.getKey ? subscription.getKey('auth') : '';

            // Generate payload
            const payload = {
                endpoint: subscription.endpoint,
                // Take byte[] and turn it into a base64 encoded string suitable for
                // POSTing to a server over HTTP
                key: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : '',
                auth: auth ? btoa(String.fromCharCode.apply(null, new Uint8Array(auth))) : ''
            };

            if (await NotificationApi.registerDevice(payload)) {
                console.log("User subscription is stored at repository")
                isSubscribed = true;
            }
        }).catch(function(err) {
            console.log('Failed to subscribe the user: ', err);
        });
    },

    /**
     * Register service worker for user subscriptions, should be called at app initialization
     */
    registerServiceWorker() {
        // Register a service worker for web push notification
        if ('serviceWorker' in navigator && 'PushManager' in window && !swRegistration) {
            console.log('Service Worker and Push is supported');

            navigator.serviceWorker.register('/sw.js')
                .then(function(swReg) {
                    console.log('Service Worker is registered', swReg);

                    swRegistration = swReg;
                })
                .catch(function(error) {
                    console.error('Service Worker Error', error);
                });
        } else {
            console.warn('Push messaging is not supported');
        }
    }
}

export default NotificationApi;
