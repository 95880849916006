import React, {useState, useEffect} from 'react';
import Utils from "../../helpers/Utils";
import TradeHistoryTable from "./TradeHistoryTable";
import TransferHistoryTable from "./TransferHistoryTable";
import PortfolioCreate from "./PortfolioCreate";
import PortfolioAddTrade from "./PortfolioAddTrade";
import PortfolioAddTransfer from "./PortfolioAddTransfer";
import PortfolioRemoveTrade from "./PortfolioRemoveTrade";
import PortfolioRemoveTransfer from "./PortfolioRemoveTransfer";
import ImportTradeDocument from "./ImportTradeDocument";
import ImportTransferDocument from "./ImportTransferDocument";
import ExportTradeDocument from "./ExportTradeDocument";
import ExportTransferDocument from "./ExportTransferDocument";
import DocumentsTable from "./DocumentsTable";
import {setLoading} from "../../actions/generalAction";
import {connect} from "react-redux";
import ExceptionsTable from "./ExceptionsTable";

// Allowed Exchanges
const allowedExchanges = ["BNB"];

const isMobile = Utils.checkMobile();

const PortfolioTransactions = props => {

    const {myPortfolio, portfolioAccounts ,meta, historyAccounts} = props;

    const [active, setActive] = useState("trades");
    const [showP, setShowP] = useState(false);
    const [showA, setShowA] = useState(false);
    const [showPD, setShowPD] = useState(false);
    const [showAD, setShowAD] = useState(false);
    // Control import trade modal
    const [showITR, setShowITR] = useState(false);
    // Control import transfer modal
    const [showITS, setShowITS] = useState(false);
    // Control export trade modal
    const [showETR, setShowETR] = useState(false);
    // Control export transfer modal
    const [showETS, setShowETS] = useState(false);

    const [selectedTrade, setSelectedTrade] = useState({});
    const [selectedTransfer, setSelectedTransfer] = useState({});

    // Mapped connectable exchanges
    const [exchanges,setExchanges] = useState([]);

    useEffect(()=>{
        let mapExc = meta.connectable_exchanges.map(el=>{
            return {id:el.code,name:el.name}
        });
        mapExc.push({id:"CIS",name:"Crypto Index Series"});
        setExchanges(mapExc);
    },[])


    return (
        <React.Fragment>
            <div style={{paddingTop: "10px"}}>
                <div className={"inline-tab-wrapper"}>
                    <ul className={"inline-tab"}>
                        <li key={"trades"}><button className={active === 'trades' ? "inline-tab-button-active" : "inline-tab-button"} onClick={e => {e.stopPropagation();  setActive('trades')}}>Trades</button></li>
                        <li key={"transfers"}><button className={active === 'transfers' ? "inline-tab-button-active" : "inline-tab-button"} onClick={e => {e.stopPropagation();  setActive('transfers')}}>Transfers</button></li>
                        <li key={"documents"}><button className={active === 'documents' ? "inline-tab-button-active" : "inline-tab-button"} onClick={e => {e.stopPropagation();  setActive('documents')}}>Documents</button></li>
                        <li key={"exceptions"}><button className={active === 'exceptions' ? "inline-tab-button-active" : "inline-tab-button"} onClick={e => {e.stopPropagation();  setActive('exceptions')}}>Exceptions</button></li>
                    </ul>
                </div>
            </div>

            <div style={{textAlign: "center", color: "white", marginTop:"0px",borderTopRightRadius:"0px",borderTopLeftRadius:"0px"}} className="gray-container">
                {
                    active === "trades" &&
                    <div>
                        <TradeHistoryTable myPortfolio={myPortfolio} portfolioAccounts={portfolioAccounts}
                                           setSelectedTrade={setSelectedTrade} setShowP={setShowP} showP={showP}
                                           setShowPD={setShowPD} showPD={showPD} setShowA={setShowA} showA={showA}
                                           setShowITR={setShowITR} setShowETR={setShowETR}/>
                    </div>
                }
                {
                    active === "transfers" &&
                    <div>
                        <TransferHistoryTable myPortfolio={myPortfolio} portfolioAccounts={portfolioAccounts}
                                              setShowA={setShowA} showA={showA}
                                              setSelectedTransfer={setSelectedTransfer} setShowAD={setShowAD}
                                              showAD={showAD} setShowP={setShowP} showP={showP} setShowITS={setShowITS}
                                              setShowETS={setShowETS}/>
                    </div>
                }
                {
                    active === "documents" &&
                    <div>
                        <DocumentsTable setShowITS={setShowITS} setShowETS={setShowETS}/>
                    </div>
                }
                {
                    active === "exceptions" &&
                    <div>
                        <ExceptionsTable historyAccounts={historyAccounts}/>
                    </div>
                }
                {/*{*/}
                {/*    active === "orderHistory" &&*/}
                {/*    <div>*/}

                {/*    </div>*/}
                {/*}*/}
            </div>

            <ExportTradeDocument
                portfolioAccounts={portfolioAccounts}
                show={showETR}
                close={() => {
                    setShowETR(false);
                }}
            />

            <ExportTransferDocument
                portfolioAccounts={portfolioAccounts}
                show={showETS}
                close={() => {
                    setShowETS(false);
                }}
            />

            <ImportTradeDocument
                portfolioAccounts={portfolioAccounts}
                allowedExchanges = {allowedExchanges}
                exchanges={exchanges}
                show={showITR}
                close={() => {
                    setShowITR(false);
                }}
            />

            <ImportTransferDocument
                portfolioAccounts={portfolioAccounts}
                allowedExchanges = {allowedExchanges}
                exchanges={exchanges}
                show={showITS}
                close={() => {
                    setShowITS(false);
                }}
            />

            <PortfolioAddTrade
                selectedTrade={selectedTrade}
                setSelectedTrade={setSelectedTrade}
                portfolioAccounts={portfolioAccounts}
                show={showP}
                close={() => {
                    setShowP(false);
                }}
            />
            <PortfolioAddTransfer
                selectedTransfer={selectedTransfer}
                setSelectedTransfer={setSelectedTransfer}
                portfolioAccounts={portfolioAccounts}
                show={showA}
                close={() => {
                    setShowA(false);
                }}
            />
            <PortfolioRemoveTrade
                selectedTrade={selectedTrade}
                setSelectedTrade={setSelectedTrade}
                show={showPD}
                close={() => {
                    setShowPD(false);
                }}
            />
            <PortfolioRemoveTransfer
                selectedTransfer={selectedTransfer}
                setSelectedTransfer={setSelectedTransfer}
                show={showAD}
                close={() => {
                    setShowAD(false);
                }}
            />
        </React.Fragment>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setFullLoading: data => dispatch(setLoading(data)),
    };
};

const mapStateToProps = state => {
    return {
        meta: state.meta,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioTransactions);

