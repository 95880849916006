import AppHelpers from "../../helpers/AppHelpers";
import Api from "../index";

const getOrderBookUrl = (symbol) => "wss://stream.binance.com/ws/" + symbol.toLowerCase() + "@depth20";
class BinanceApi {

    constructor() {}

    subscribeOrderbook(symbol, buyHandler, sellHandler) {
        const conn = new WebSocket(getOrderBookUrl(symbol));
        conn.onclose = function () {};
        this.conn = conn;
        this.buyHandler = buyHandler;
        this.sellHandler = sellHandler;

        this.conn.onmessage = ((event) => {
            // Parse message to JSON
            const json = JSON.parse(event.data);

            // Compute totals on bids
            const bids = json.bids.map(el => {
                el[2] = AppHelpers.priceFormat(el[0] * el[1]);
                return el;
            });

            // Compute totals on asks
            const asks = json.asks.map(el => {
                el[2] = AppHelpers.priceFormat(el[0] * el[1]);
                return el;
            });

            // Update buy and sell data
            this.buyHandler(bids.reverse());
            this.sellHandler(asks);
        });
    }

    getTrades(symbol, limit) {
        return Api.getPromise("api/v3/trades", {symbol: symbol, limit: limit}, "https://api.binance.com/").then(res => {
            return res.map(el => {
                // Map trades response to common type (CCXT-Rest)
                return {
                    price: el.price,
                    amount: el.qty,
                    timestamp: el.time,
                    side: el.isBuyerMaker ? 'buy' : 'sell'
                };
            });
        }).catch(err => {
            console.error("Error while getting Binance trades", err);
            return [];
        });
    }

    destroyConnection() {
        this.conn && this.conn.close();
    }
}

export default BinanceApi;
