import Links from "../../constants/Links";
import Api from "../index";
import CryptoJS from 'crypto-js' ;
import fs from "fs";

// Path definitions
const EXCHANGE_ACCOUNT_PATH = "/account";
const ACCOUNT_WITH_ID_PATH = (id) => EXCHANGE_ACCOUNT_PATH + "/" + id;
const DOCUMENT_PATH = "/document";
const PORTFOLIO = "/portfolio";
const TRADE = "/trade";
const TRANSFER = "/transfer";
const BALANCE = "/balance";

const PortfolioApi = {
    getPromise(endpoint, params) {
        return Api.getPromise(endpoint, params, Links.BASE_PORTFOLIO_URL)
    },
    getMPromise(endpoint, params) {
        return Api.getMPPromise(endpoint, params, Links.BASE_PORTFOLIO_URL)
    },
    postPromise(endpoint, body) {
        return Api.postPromise(endpoint, body, Links.BASE_PORTFOLIO_URL)
    },
    postMPPromise(endpoint, body) {
        return Api.postMPPromise(endpoint, body, Links.BASE_PORTFOLIO_URL)
    },
    putPromise(endpoint, body) {
        return Api.putPromise(endpoint, body, Links.BASE_PORTFOLIO_URL)
    },
    deletePromise(endpoint, params) {
        return Api.deletePromise(endpoint, params, Links.BASE_PORTFOLIO_URL)
    },
    /**
     * Read user portfolio accounts.
     */
    getPortfolios() {
        return this.getPromise(PORTFOLIO);
    },
    /**
     * Create portfolio account.
     * * @param include account name
     */
    createPortfolio(param) {
        return this.postPromise(PORTFOLIO, param);
    },
    /**
     * Edit portfolio account.
     * * @portfolioId portfolio id
     * * @param include account name
     */
    editPortfolio(portfolioId, param) {
        return this.putPromise(PORTFOLIO + "/" + portfolioId, param);
    },
    /**
     * Delete portfolio account.
     * * @portfolioId portfolio id
     */
    deletePortfolio(portfolioId) {
        return this.deletePromise(PORTFOLIO + "/" + portfolioId);
    },
    /**
     * Read user account records. This will not contain actual keys, only key meta information (E.g. key_name, exchange)
     */
    getAccounts() {
        return this.getPromise(EXCHANGE_ACCOUNT_PATH);
    },
    /**
     * Delete account of the user
     *
     * @param k_id Key ID
     */
    deleteAccount(k_id) {
        return this.deletePromise(EXCHANGE_ACCOUNT_PATH + "/" + k_id);
    },
    /**
     * Get accounts linked to portfolio
     *
     * @portfolioId portfolio id
     */
    getAccountsOfPortfolio(portfolioId) {
        return this.getPromise(PORTFOLIO + "/" + portfolioId + EXCHANGE_ACCOUNT_PATH);
    },
    /**
     * Bind account to portfolio
     *
     * @portfolioId portfolio id
     * * @portfolioId account id
     */
    bindAccountToPortfolio(portfolioId, accountId) {
        return this.putPromise(PORTFOLIO + "/" + portfolioId + EXCHANGE_ACCOUNT_PATH + "/" + accountId);
    },

    /**
     * Remove account to portfolio
     *
     * @portfolioId portfolio id
     * * @portfolioId account id
     */
    removeAccountToPortfolio(portfolioId, accountId) {
        return this.deletePromise(PORTFOLIO + "/" + portfolioId + EXCHANGE_ACCOUNT_PATH + "/" + accountId);
    },
    /**
     * Create exchange key at repository
     *
     * @param key_name Name of the key
     * @param exchange Exchange of the key
     * @param exchange_code Code of the exchange
     * @param token Auth0 Token
     * @param key Key itself
     */
    createKey(key_name, exchange_name, type, exchange_code, token, key) {
        // Generate record

        const record = {
            name: key_name,
            type: type,
            exchange_information: {
                exchange_code: exchange_code,
                exchange_name: exchange_name,
                credentials: this.encryptKey(JSON.stringify(key), token)
            },
        };

        return this.postPromise(EXCHANGE_ACCOUNT_PATH, record);
    },
    /**
     * Update exchange account.
     * * @accountName include new account name
     * * @accountId indicate account id
     */
    updateAccount(accountName, accountId) {
        return this.putPromise(EXCHANGE_ACCOUNT_PATH + "/" + accountId, accountName);
    },
    /**
     * Read user trade history
     * @param refer trade history filter
     */
    getTradeHistory(param) {
        return this.getPromise(TRADE, param).then(resp => {
            const trade = {};
            trade.total_count = resp.count;
            if (resp.trades) {
                trade.mapped = resp.trades.map(el => {
                    const ret = {}
                    ret.total_count = resp.count;
                    resp.fields.forEach((f, i) => {
                        ret[f] = el[i];
                    })
                    return ret;
                })
            } else {
                trade.mapped = [];
            }

            return trade || [];
        }).catch(e => {
            console.error(e);
            return [];
        });
    },

    /**
     * Read user transfer history
     * @param refer transfer history filter
     */
    getTransferHistory(param) {
        return this.getPromise(TRANSFER, param).then(resp => {
            const transfer = {};
            transfer.total_count = resp.count;
            if (resp.transfers) {
                transfer.mapped = resp.transfers.map(el => {
                    const ret = {}
                    resp.fields.forEach((f, i) => {
                        ret[f] = el[i];
                    })

                    return ret;
                })
            } else {
                transfer.mapped = [];
            }
            return transfer || [];
        }).catch(e => {
            console.error(e);
            return [];
        });
    },
    /**
     * Add transfer to portfolio accounts.
     * * @accountId account Id
     * * @param include transfer params
     */
    addTransfer(accountId, param) {
        return this.postPromise(EXCHANGE_ACCOUNT_PATH + "/" + accountId + TRANSFER, param);
    },
    /**
     * Update trade to portfolio accounts.
     * * @accountId account Id
     * * @tradeId trade Id
     * * @param include trade params
     */
    updateTransfer(accountId, transferId, param) {
        return this.putPromise(EXCHANGE_ACCOUNT_PATH + "/" + accountId + TRANSFER + "/" + transferId, param);
    },
    /**
     * Delete trade to portfolio accounts.
     * * @accountId account Id
     * * @tradeId trade Id
     */
    deleteTransfer(accountId, transferId) {
        return this.deletePromise(EXCHANGE_ACCOUNT_PATH + "/" + accountId + TRANSFER + "/" + transferId);
    },
    /**
     * Add trade to portfolio accounts.
     * * @accountId account Id
     * * @param include trade params
     */
    addTrade(accountId, param) {
        return this.postPromise(EXCHANGE_ACCOUNT_PATH + "/" + accountId + TRADE, param);
    },
    /**
     * Update trade to portfolio accounts.
     * * @accountId account Id
     * * @tradeId trade Id
     * * @param include trade params
     */
    updateTrade(accountId, tradeId, param) {
        return this.putPromise(EXCHANGE_ACCOUNT_PATH + "/" + accountId + TRADE + "/" + tradeId, param);
    },
    /**
     * Delete trade to portfolio accounts.
     * * @accountId account Id
     * * @tradeId trade Id
     */
    deleteTrade(accountId, tradeId) {
        return this.deletePromise(EXCHANGE_ACCOUNT_PATH + "/" + accountId + TRADE + "/" + tradeId);
    },
    encryptKey(key, token) {
        // Extract token parts
        const signature = token.split('.')[2]
        const iv = CryptoJS.enc.Utf8.parse(signature.slice(0, 16))
        const secret = CryptoJS.enc.Utf8.parse(signature.slice(0, 32))

        // Decrypt response
        return CryptoJS.AES.encrypt(key, secret, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }).toString();
    },

    /**
     * Decrypt key with AES
     *
     * @param key Key to decrypt
     * @param token Auth0 Token
     */
    decryptKey(key, token) {
        // Extract token parts
        const signature = token.split('.')[2]
        const iv = CryptoJS.enc.Utf8.parse(signature.slice(0, 16))
        const secret = CryptoJS.enc.Utf8.parse(signature.slice(0, 32))

        // Decrypt response
        return CryptoJS.AES.decrypt(key, secret, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }).toString(CryptoJS.enc.Utf8);
    },

    /**
     * Reads list of documents (imported or exported) related to given account
     *
     * @param accountId ID of account to read documents for
     */
    getTradeDocuments(accountId) {
        const path = ACCOUNT_WITH_ID_PATH(accountId) + TRADE + DOCUMENT_PATH;

        return this.getPromise(path).then(res => {
            return res;
        }).catch(err => {
            console.error("Error while reading trade documents...", err);
            return [];
        });
    },

    /**
     * Reads list of documents (imported or exported) related to given account
     *
     * @param accountId ID of account to read documents for
     */
    getTransferDocuments(accountId) {
        const path = ACCOUNT_WITH_ID_PATH(accountId) + TRANSFER + DOCUMENT_PATH;

        return this.getPromise(path).then(res => {
            return res;
        }).catch(err => {
            console.error("Error while reading transfer documents...", err);
            return [];
        });
    },

    /**
     * Calls import document for trade endpoint
     *
     * @param accountId ID of account we are importing this document to
     * @param source_code Which exchange symbology in document is adapted to (e.g If document is exported from Binance, this should be BNB)
     * @param file Uploaded file (Check UserApi Image Upload login is same)
     */
    importTradeDocument(accountId, source_code, file) {
        return this.importDocument(accountId, TRADE, source_code, file);
    },

    /**
     * Calls import document for transfer endpoint
     *
     * @param accountId ID of account we are importing this document to
     * @param source_code Which exchange symbology in document is adapted to (e.g If document is exported from Binance, this should be BNB)
     * @param file Uploaded file (Check UserApi Image Upload login is same)
     */
    importTransferDocument(accountId, source_code, file) {
        return this.importDocument(accountId, TRANSFER, source_code, file);
    },

    /**
     * Calls export document for trade endpoint
     *
     * @param accountId ID of account we are importing this document to
     * @param file_type What type of file you want to export (e.g csv, xlm)
     */
    exportTradeDocument(accountId, file_type) {
        return this.exportDocument(accountId, TRADE, file_type);
    },

    /**
     * Calls export document for trade endpoint
     *
     * @param accountId ID of account we are importing this document to
     * @param file_type What type of file you want to export (e.g csv, xlm)
     */
    exportTransferDocument(accountId, file_type) {
        return this.exportDocument(accountId, TRANSFER, file_type);
    },

    /**
     * Helper method to handle document import requests. This method performs required actions for importing
     * a document for either transfer or trades
     *
     * @param accountId ID of account we are importing this document to
     * @param document_type Type of the document (Either TRANSFER or TRADE)
     * @param source_code Which exchange symbology in document is adapted to (e.g If document is exported from Binance, this should be BNB)
     * @param file Uploaded file (Check UserApi Image Upload login is same)
     */
    importDocument(accountId, document_type, source_code, file) {
        // Generate path to import document account/{aID}/document/import/{document_type}
        const path = ACCOUNT_WITH_ID_PATH(accountId) + DOCUMENT_PATH + "/import" + document_type

        // Prepare body of the request
        const formData = new FormData();
        formData.append("file", file);
        formData.append("source_code", source_code);

        return this.postMPPromise(path, formData).then(res => {
            // This returns record for imported document as in getTradeDocuments
            return res;
        }).catch(err => {
            console.error("Error while importing document params:", document_type, source_code, "error:", err);
            return {}; // For safety
        })
    },

    /**
     * Helper method to handle document export requests. This method performs required actions for triggering account data
     * export process.
     *
     * @param accountId ID of account we want to export data from
     * @param document_type Type of the document (Either TRANSFER or TRADE)
     * @param file_type File extension should be one of fileTypes array below
     */
    exportDocument(accountId, document_type, file_type) {
        // Generate path to import document account/{aID}/document/import/{document_type}
        const path = ACCOUNT_WITH_ID_PATH(accountId) + DOCUMENT_PATH + "/export" + document_type

        // Prepare body of the request
        const formData = new FormData();
        formData.append("file_type", file_type);
        formData.append("source_code", 'CIS');

        return this.postPromise(path, formData).then(res => {
            // This returns record for imported document as in getTradeDocuments
            return res;
        }).catch(err => {
            console.error("Error while importing document params:", document_type, file_type, "error:", err);
            return {}; // For safety
        })
    },

    /**
     * Download a document with it's ID. Give this function an account ID and a ID of document that belongs to
     * that account to download the document.
     *
     * @param accountId Account ID
     * @param documentId Document ID
     */
    downloadDocument(accountId, documentId) {
        // Generate path
        const path = ACCOUNT_WITH_ID_PATH(accountId) + DOCUMENT_PATH + "/" + documentId;

        return this.getMPromise(path).then(res => {
            // Extract file name from content disposion header
            const headerLine = res.headers['content-disposition'];
            const startFileNameIndex = headerLine.indexOf('"') + 1
            const endFileNameIndex = headerLine.lastIndexOf('"');
            const filename = headerLine.substring(startFileNameIndex, endFileNameIndex);

            // Unfortunately in order to trigger download with axios we need to do this hacky solution
            const url = window.URL.createObjectURL(new Blob([res.data], {type: "application/octet-stream"}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        }).catch(err => {
            console.error("Error while downloading document", documentId, "error:", err);
            return false;
        });
    },

    // Types of supported files for import and export, give it as hint for file selection and export file path
    fileTypes: ["xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt"].map(el => "." + el).join(","),

    /**
     * Get balance history
     * * @param include account ids and ccy
     */
    getBalanceHistory(param) {
        return this.getPromise(BALANCE + "/history", param).then(resp => {

            resp.history = (resp.history.data && resp.history.data.map(el => {
                return {
                    [resp.history.fields[0]]: el[0],
                    [resp.history.fields[1]]: el[1],
                    [resp.history.fields[2]]: el[2],
                    [resp.history.fields[3]]: el[3],
                    [resp.history.fields[4]]: el[4],
                    [resp.history.fields[5]]: el[5],
                    [resp.history.fields[6]]: el[6],
                }
            })) || [];

            resp.performance = (resp.performance.data && resp.performance.data.map(el => {
                return {
                    [resp.performance.fields[0]]: el[0],
                    [resp.performance.fields[1]]: el[1],
                    [resp.performance.fields[3]]: el[2],
                    [resp.performance.fields[4]]: el[3],
                    [resp.performance.fields[5]]: el[4],
                    [resp.performance.fields[6]]: el[5],
                }
            })) || [];

            if (!param.account_ids || param.account_ids.length === 0) {
                return Promise.resolve(
                    {
                        history: [],
                        performance: [],
                        asset_exceptions: [],
                        instrument_exceptions: []
                    }
                );
            } else {
                return resp;
            }
        });
    },

    /**
     * Get current balance
     * * @param include account ids
     */
    getCurrentBalance(param) {
        return this.getPromise(BALANCE, param);
    },

}

export default PortfolioApi;

