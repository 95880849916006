import React, {useState, useEffect} from 'react';
import Utils from "../../helpers/Utils";
import ReactTooltip from "react-tooltip";
import {Pie} from "react-chartjs-2";
import Loading from "../../components/Loading";
import moment from "moment";
import AppHelpers from "../../helpers/AppHelpers";
import TradeHistoryTable from "./TradeHistoryTable";
import TransferHistoryTable from "./TransferHistoryTable";
import DocumentsTable from "./DocumentsTable";
import {setLoading} from "../../actions/generalAction";
import {connect} from "react-redux";
import TradingView from "../../tradingview";


const isMobile = Utils.checkMobile();


const legend = {
    display: true,
    position: 'right',
    fullWidth: true,
    reverse: false,
    labels: {
        boxWidth: 15,
        fontColor: "white",
        fontSize: 15
    }
}

const colors = [
    '#1f5c94',
    '#68bbe3',
    '#1b4b9d',
    '#1d2e51',
    '#7267c2',
    '#3d3c49',
    '#603f8b',
    '#493979',
    '#385b4f',
    '#1c938b',
    '#224a51',
    '#1c2143',
    '#282854',

]


const PortfolioBalance = props => {

    const {
        historyAccounts, // History balance info
        ccy, // Selected currency
        totalHoldings, // holdings information for asset allocation
        meta, // Meta data
        pABHLoading // Portfolio Account Balance History loading
    } = props;

    const [data, setData] = useState({data: {}, loading: true});

    // Active tab
    const [active, setActive] = useState("performance");

    // Connectable Exchanges
    const [exchanges,setExchanges] = useState({});

    const historyNameMapper = (label) => {
        if (label === "lifetime") {
            return "Lifetime";
        } else if (label === "YTD") {
            return "Year-To-Date";
        } else if (label.includes("M")) {
            return label.split("M")[0] + " Month(s)";
        } else if (label.includes("Y")) {
            return label.split("Y")[0] + " Year(s)";
        } else {
            return label;
        }
    }

    // Get connectable exchanges from meta data
    useEffect(()=>{
        const mapExc = {};
        meta.connectable_exchanges.forEach(el=>{
            mapExc[el.code] = el.name;
        });
        mapExc["CIS"] = "Crypto Index Series";
        setExchanges(mapExc);
    },[]);

    // Arrange pie chart
    useEffect(() => {

        if (totalHoldings.length > 0) {
            if (totalHoldings.length <= 13) {
                const defaultData = {
                    labels: totalHoldings.map(el => {
                        return el.symbol;
                    }),
                    datasets: [{
                        data: totalHoldings.map(el => {
                            return el.perc.toFixed(2);
                        }),
                        backgroundColor: colors,
                        hoverBackgroundColor: colors,
                        borderColor: colors,
                        borderWidth: 1,
                    }]
                };
                setData({data: defaultData, loading: false});
            } else {

                // If asset count more then 5 add others field to pie chart
                const sortedHoldings = totalHoldings;

                // Get the ones that have impact larger than 5%
                const firstSlice = sortedHoldings.filter(el => el.perc >= 9);

                let holdingsLabel = firstSlice.map(el => {
                    return el.symbol;
                });

                holdingsLabel.push("Others");

                // first slice sum of perc
                let firstSliceTotalPerc = 0;
                let holdingsData = firstSlice.map(el => {
                    firstSliceTotalPerc = firstSliceTotalPerc + el.perc;
                    return el.perc.toFixed(2);
                })

                holdingsData.push((100 - firstSliceTotalPerc).toString());


                const defaultData = {
                    labels: holdingsLabel,
                    datasets: [{
                        data: holdingsData,
                        backgroundColor: colors,
                        hoverBackgroundColor: colors,
                        borderColor: colors,
                        borderWidth: 1,
                    }]
                };

                setData({data: defaultData, loading: false});
            }
        }else{
            setData({data: {}, loading: false});
        }
    }, [totalHoldings])

    return (
        <React.Fragment>
            <div style={{marginTop: "10px", color: "white",display:"flex"}} className="row">
                <div className="col-md-4 col-sm-12 col-custom">
                    <div className="gray-container" style={{height:"350px"}}>
                        <Loading loading={pABHLoading}>
                                <div style={{fontSize: "20px"}}>Performance Statistics</div>
                                <div className="table-body table-responsive" id="beauty-scroll7"
                                     style={{height: "233px",marginTop: "15px"}}>
                                    <table className="coins enumarable-table coins-menu-table linked-table">
                                        <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Index(Open)</th>
                                            <th>Change</th>
                                            <th>Change(%)</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            historyAccounts.performance && historyAccounts.performance.length > 0 ?
                                                historyAccounts.performance.map((el, i) => {
                                                    return (
                                                        <tr style={{cursor: "auto"}} key={i}>
                                                            <td>{historyNameMapper(el.label)}</td>
                                                            <td>{el.open_index.toFixed(2)}</td>
                                                            <td style={el.change > 0 ? {color: "#7ac231"} : {color: "red"}}>{el.change.toFixed(2)}</td>
                                                            <td style={el.change_pct > 0 ? {color: "#7ac231"} : {color: "red"}}>{el.change_pct > 0 ? "+" + (el.change_pct * 100).toFixed(2) + " %" : "-" + (el.change_pct * 100).toFixed(2) + " %"}</td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={4}>
                                                        There is no performance information!
                                                    </td>
                                                </tr>
                                        }
                                        </tbody>
                                    </table>
                            </div>
                        </Loading>

                    </div>
                </div>
                <div className="col-md-8 col-sm-12 col-custom">
                    <div className="gray-container" style={{textAlign: "center",height:"100%",marginTop:"0px",padding:"0px"}}>
                        <Loading loading={pABHLoading}>
                            {historyAccounts && historyAccounts.history && historyAccounts.history.length > 0 ?
                                <TradingView pData={historyAccounts.history} />
                                :
                                <p style={{padding:"130px", fontSize:"20px"}}>No Available Data</p>
                            }
                        </Loading>
                    </div>
                </div>
            </div>
            <div style={{marginTop: "20px", color: "white"}}>
                <div className="gray-container" style={{margin:"0px -5px"}}>
                    <Loading loading={pABHLoading}>
                        <div className="row" style={{display:"flex",flexDirection:"row"}}>
                            <div className="col-md-6 col-custom" style={{display:"flex",flexDirection:"column"}}>
                                <div className="table-head">
                                    <div className="table-left">
                                        <div className="table-header">Asset Allocation</div>
                                    </div>
                                    <div className="clearfix"/>
                                </div>
                                <div className="table-body table-responsive" id="beauty-scroll7" style={{marginTop: "15px",flex:"1 1 1px"}}>
                                    <ReactTooltip html={true}/>
                                    <table className="coins enumarable-table coins-menu-table linked-table">
                                        <thead>
                                        <tr>
                                            <th>Asset</th>
                                            <th>Amount Held</th>
                                            <th>Percentage</th>
                                            <th>Total Value({ccy.ccy})</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {totalHoldings.length>0 ? totalHoldings.map((el, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{el.symbol} ({el.name})</td>
                                                        <td>{AppHelpers.priceFormat(el.amount)}</td>
                                                        <td>{el.perc.toFixed(2)} %</td>
                                                        <td>{AppHelpers.priceFormat(el.total)}</td>
                                                    </tr>
                                                )
                                            }):
                                            <tr>
                                                <td colSpan={4}>
                                                    There is no asset!
                                                </td>
                                            </tr>

                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-6 col-custom">
                                <div style={{marginTop: "10px"}}>
                                    <Loading loading={data.loading}>
                                        {totalHoldings.length > 0 && data.data  &&<Pie data={data.data} legend={legend}/>}
                                    </Loading>
                                </div>
                            </div>
                        </div>
                    </Loading>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setFullLoading: data => dispatch(setLoading(data)),
    };
};

const mapStateToProps = state => {
    return {
        meta: state.meta,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioBalance);
