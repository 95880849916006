import React from 'react';
import {connect} from 'react-redux';
import './style.css';
export const FullLoading = ({loading}) => {
  return (
    <div className={`fullLoading ${loading ? '' : 'hidden'}`}>
      <div className="uil-ring-css" style={{transform: 'scale(0.79)'}}>
        <div></div>
      </div>
    </div>
  );
};

export default connect({})(FullLoading);
