import React, { useEffect, useState } from 'react';
import AppHelpers from "../../helpers/AppHelpers";
import ReactTooltip from 'react-tooltip';
import createReport from 'docx-templates';
import Api from '../../api';
import SocialApi from '../../api/SocialApi/social';
import { toastr } from 'react-redux-toastr';

const StateBarRow = props => {
    const {
        chgpct,
        price,
        marketCap,
        totalCoin,
        avalCoin,
        vol24,
        perClass,
        perTd,
        symbol,
        rowImage,
        name,
        exchange,
        rowClass,
        high24,
        low24,
        rowClick,
        whitepaperUrl,
        row,
        handleOpenAlert,
        setShow,
        setAdd,
        selectedExchange,
    } = props;

    const getVolatiliyColor = () => {
        if (row.volatility_category === "Low") {
            return <div className={`sb-number-inner`}
                style={{ color: "rgb(2,244,2)" }}>{row.volatility_category ? row.volatility_category : "-"}</div>;
        } else if (row.volatility_category === "Normal") {
            return <div className={`sb-number-inner`}
                style={{ color: "rgb(14,131,18)" }}>{row.volatility_category ? row.volatility_category : "-"}</div>;
        } else if (row.volatility_category === "High") {
            return <div className={`sb-number-inner`}
                style={{ color: "rgb(244,192,2)" }}>{row.volatility_category ? row.volatility_category : "-"}</div>;
        } else if (row.volatility_category === "Very High") {
            return <div className={`sb-number-inner`}
                style={{ color: "#c11e0f" }}>{row.volatility_category ? row.volatility_category : "-"}</div>;
        }
    }

    const value = getVolatiliyColor();

    return (
        <React.Fragment>
            <div className="state-bar-inner">
                <React.Fragment>
                    <div className="sb-text-img" style={{ marginTop: "7px" }}>
                        <div className="sb-img" style={{ background: '#f2921b' }}>
                            <img src={`https://files.cryptoindexseries.com/cis-files/${rowImage}`} alt="" />
                        </div>
                        <div className="sb-text">
                            <div className="sb-lg-text">{symbol}</div>
                            <div className="sb-sm-text">{name}</div>
                        </div>
                    </div>
                    <div className="sb-numbers">
                        <div className="sb-number">
                            <div className="sb-number-head">Rank</div>
                            <div className={`sb-number-inner`}>{row.rank}</div>
                        </div>
                        <div className="sb-number">
                            <div className="sb-number-head">Price({exchange})</div>
                            <div className={`sb-number-inner ${rowClass}`}>{price}</div>
                        </div>
                        <div className="sb-number">
                            <div className="sb-number-head">MKT CAP</div>
                            <div className={`sb-number-inner`}>{marketCap}</div>
                        </div>
                        <div className="sb-number">
                            <div className="sb-number-head">24h VOL</div>
                            <div className="sb-number-inner">{vol24}</div>
                        </div>
                        <div className="sb-number">
                            <div className="sb-number-head">24h LOW ({exchange})</div>
                            <div className="sb-number-inner">{low24}</div>
                        </div>
                        <div className="sb-number">
                            <div className="sb-number-head">24h HIGH ({exchange})</div>
                            <div className="sb-number-inner">{high24}</div>
                        </div>
                        <div className="sb-number">
                            <div className="sb-number-head">CHG (24h)</div>
                            <div className={`sb-number-inner ${perClass}`}>{perTd}</div>
                        </div>
                        <div className="sb-number">
                            <div className="sb-number-head">Volume flag</div>
                            <div className={`sb-number-inner`}>{row.volume_category ? row.volume_category : "-"}</div>
                        </div>
                        <div className="sb-number pull-right">
                            <div className="sb-number-head">Volatility Flag</div>
                            {value}
                        </div>
                        {
                            row.defi_info && row.defi_info.tvl_usd &&
                            <div className="sb-number">
                                <div className="sb-number-head">DeFi TVL</div>
                                <div
                                    className={`sb-number-inner`}>{AppHelpers.numberFormat(row.defi_info.tvl_usd) + " $"}</div>
                            </div>
                        }

                        {/*yazıyı kırmızı yapmak için green-text yerine red-text eklenmesi ve eğer ok işareti aşağı doğru olacaksa down arrow yapmak yeterli*/}
                        <div className="clearfix" />
                    </div>
                </React.Fragment>

                <ReactTooltip html={true} />
                {
                    whitepaperUrl &&
                    <div className={"pull-right"} style={{ "marginRight": "2.5em" }}>
                        <a target="_blank" rel="noopener noreferrer"
                            href={`https://files.cryptoindexseries.com/cis-files/${whitepaperUrl}`}>
                            <img data-html={true} data-tip={"Whitepaper"} className="actionable-img" src="/img/ic_document.png"
                                alt="" style={{ "height": "50px" }} />
                        </a>
                    </div>
                }
                <div className="clearfix" />
            </div>
            <div className="green-border-btn">
                <a>
                    <img className="actionable-img" src="/img/plus-normal.svg" alt="" />
                </a>
            </div>
            <div style={{ right: 50, top: 36, width: 50, height: 30, position: 'absolute' }}
                className="three-dot-menu three-dot-menu-coin-details">
                <div className="dot-menu-icon">
                    <img className="actionable-img" src="" alt="" />
                </div>
                <div className="table-dropdown-menu">
                    <ul>
                        <li>
                            <a
                                onClick={e => {
                                    e.stopPropagation();
                                    setShow(true);
                                    setAdd({ from: row.symbol, to: selectedExchange.value });
                                }}
                                className=" open-modal-event"
                                data-target="set-watch-list">
                                <img className="actionable-img" src="/img/rounded-add-button.svg" alt="" />
                                Add Watchlist
                            </a>
                        </li>
                        <li>
                            <a
                                onClick={() => handleOpenAlert(price)}
                                className="open-modal-event"
                                data-target="set-alert">
                                <img className="actionable-img" src="/img/alerts.svg" alt="" />
                                Alert
                            </a>
                        </li>
                        {/* <li>
                            <a
                                style={{ display: "table-cell" }}
                                href="#"
                                onClick={generateReport}
                            >
                                <img className="actionable-img" src="/img/pdf.svg" alt="" />
                                Generate Report
                            </a>
                        </li> */}
                    </ul>
                </div>


            </div>
        </React.Fragment>
    );
};

export default StateBarRow;

