import React from 'react';
import Loader from 'react-loader-spinner';

const Loading = props => {
  const {color, loading, height, width, children,isSector} = props;
  return (
    <>
      {(loading && (
          isSector?<tr>
                <td colSpan={15}>
                  <div style={{justifyContent: 'center', display: 'flex'}}>
                    <Loader type="ThreeDots" color={color} height={height} width={width} />
                  </div>
                </td>
              </tr>
              :
              <div style={{justifyContent: 'center', display: 'flex'}}>
                <Loader type="ThreeDots" color={color} height={height} width={width} />
              </div>
      )) ||
        children}
    </>
  );
};
Loading.defaultProps = {
  color: '#fdfd',
  height: 100,
  width: 100
};
export default Loading;
