import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {toastr} from "react-redux-toastr";
import Select from 'react-select';
import Api from '../../../api';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import AlertApi from "../../../api/AlertApi";
import RealtimeUtils from "../../../helpers/RealtimeUtils";
import DataMappers from "../../../helpers/DataMappers";
import ReactTooltip from "react-tooltip";

const fields = [
    {value: "price", label: "Price"},
    {value: "change_percentage", label: "Change Percentage(24h)"}
]

const directions = [
    {value: "above", label: "Above"},
    {value: "below", label: "Below"}
]

const customStyles = {
    menuList: (provided, state) => ({
        ...provided,
        maxHeight: "200px",
    }),
}

const TemplateConditionModal = props => {
    const {
        conditionsFields,
        index,
        setConditionsFields,
        handleDeleteCondition,
        indicatorList,
        indicators
    } = props;

    const handleChangeValue = (event,index,resp) => {
        let newConditionsFields = [...conditionsFields];
        newConditionsFields[index].parameters[resp] = event.target.value;
        setConditionsFields(newConditionsFields);
    };

    const onChange = ( selectedOption) => {
        let newConditionsFields = [...conditionsFields];
        newConditionsFields[index].indicator_code = selectedOption.value;
        newConditionsFields[index].parameters = {...indicators[selectedOption.value].default_parameters};
        setConditionsFields(newConditionsFields);
    };


    return (
        <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
            <div style={{padding: "5px 0px", display: "flex",justifyContent:"space-between"}}>
                <div style={{paddingTop: "10px"}}>
                    <h4 style={{
                        color: "white",
                        fontSize: "16px",
                        padding: "0px 8px"
                    }}>{"Indicator - "+(index+1)+":"}</h4>
                </div>
                <div style={{display:"flex"}}>
                    <div style={{paddingTop: "10px", marginLeft: "30px"}}>
                        <div style={{padding: "0px", width: "150px"}}>
                            <Select
                                styles={customStyles}
                                value={{value:conditionsFields[index].indicator_code, label:conditionsFields[index].indicator_code}}
                                onChange={(e) => onChange(e)}
                                options={indicatorList}
                                placeholder="Indicators"
                            />
                        </div>
                    </div>
                    {conditionsFields.length > 0 && Object.keys(conditionsFields[index].parameters).map((resp,i)=>{
                        return(
                            <div key={i} style={{paddingTop: "10px", marginLeft: "5px"}}>
                                <div style={{padding: "0px", width: "150px"}}>
                                    <TextField
                                        size={"small"}
                                        label={ resp.charAt(0).toUpperCase() + resp.slice(1)}
                                        margin="normal"
                                        variant="outlined"
                                        onChange={(event)=>handleChangeValue(event,index,resp)}
                                        value={conditionsFields[index].parameters[resp]}
                                        fullWidth
                                    />
                                </div>
                            </div>
                        )
                    })
                    }
                    <div style={{paddingTop: "10px"}}>
                        <div style={{padding: "0px", width: "50px", display: "flex"}}>
                            <ReactTooltip html={true}/>
                            {index !== 0 &&
                            <a style={{marginTop: "8px", marginLeft: "5px", cursor: "pointer"}}><img
                                className="actionable-img"
                                alt=""/><i onClick={() => handleDeleteCondition(index)} className="fas fa-minus-circle"
                                           style={{
                                               fontSize: "1.5em",
                                               color: "#c11e0f"
                                           }}  data-tip={'Delete Condition'}/></a>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const prepareInputProps = state => {

    const mappedExchanges = {};

    state.meta.exchanges.forEach(el=>{
        mappedExchanges[el.code] = el;
    })


    return {
        exchanges: state.meta.exchanges,
        mappedExchanges: mappedExchanges
    };
};

export default connect(prepareInputProps)(TemplateConditionModal);
