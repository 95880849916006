import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import {toastr} from 'react-redux-toastr';
import {useAuth0} from "../../helpers/Auth0";
import Select from "../../components/Forms/Select";
import {setLoading} from "../../actions/generalAction";
import Binance from "../Portfolio/Images/Binance.png";
import BTCTurk from "../Portfolio/Images/BTCTurk.png";
import CoinBasePro from "../Portfolio/Images/Coin_Base_Pro.png";
import Huobi from "../Portfolio/Images/Huobi.png";
import Gemini from '../Portfolio/Images/Gemini.jpg';
import FTX from '../Portfolio/Images/ftx.png';
import Kraken from '../Portfolio/Images/kraken.png';
import PortfolioApi from "../../api/PortfolioApi";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const defaultFilter = {
    name: '',
    type: 'manual',
    exchange_information : {
        exchange_code: "",
        exchange_name: "",
        credentials: ""
    },
};

const accountTypes = {
    Exchange: {id:"exchange",name:"Exchange"},
    OfflineManual: {id:"manual",name:"Offline / Manual"}
}

// Exchange open account links
const links = {
    "BNB": "https://accounts.binance.com/tr/register?ref=G6V6Y6N8",
    "CBS": "https://www.coinbase.com/join/philli_4t4y",
    "BTT": "https://sso.btcturk.com/k/account/register",
    "HUO": "https://www.huobi.com/tr-tr/register/",
    "GEM": "https://exchange.gemini.com/register",
    "KrakenHere": "https://r.kraken.com/888CIS",
    "FTX": "https://ftx.com/#a=41254024"
}

const ExchangeCreate = props => {
    const {exchanges, isNew, close, show, fetchKeys, locState, history, setFullLoading, portfolios, fetchDataAccount} = props;
    const [filter, setFilter] = useState(defaultFilter);
    const [buttonDisable,setButtonDisable] = useState(false);
    const [showApi,setShowApi] = useState({show:false,data:{}});
    const [activeEffect,setActiveEffect] = useState("");
    const {token} = useAuth0();

    // My portfolio
    const [myPortfolio, setMyPortfolio] = useState({name: "empty", id: ''});

    // Flag control to portfolio select
    const [flag,setFlag] = useState(false);

    useEffect(()=>{
        if(portfolios.data.length > 0){
            setMyPortfolio({name:portfolios.data[0].name,id:portfolios.data[0].id})
        }
    },[portfolios])

    const onChange = (type, val) => {
        if(type === "type"){
            filter.exchange_information.exchange_name = '';
            filter.exchange_information.exchange_code = '';
            setShowApi({show:false,data:{}});
        }
        let newFilter = {...filter};
        newFilter[type] = val;
        setFilter(newFilter);
    };

    useEffect(() => {
        setShowApi({show:false,data:{}});
        setActiveEffect("");
        if (isNew && isNew.id && show) { // Update is requested
            let newFilterUpdate = {...filter};
            newFilterUpdate.name = isNew.name;
            newFilterUpdate.type = isNew.type;
            if(isNew.key_details){
                newFilterUpdate.exchange_information.exchange_code = isNew.key_details.exchange_code;
                newFilterUpdate.exchange_information.exchange_name = isNew.key_details.exchange_name;
                setActiveEffect(isNew.key_details.exchange_name);
                setShowApi({show:true,data:exchanges[isNew.key_details.exchange_name]});
            }
            setFilter(newFilterUpdate);
        // } else if (!(isNew && isNew.id) && locState && show) { // User is navigated to this page with add exchange (Not used)
        //     setFilter(f => ({...f, exchange: locState.exchange}));
        } else { // Create requested

            setFilter(defaultFilter);
        }
    }, [show, isNew, locState]);

    /**
     * Generate key part in request, returns null if invalid
     *
     * @param filter
     * @return {*}
     */
    const generateKey = (filter) => {
        let key = {};
        let isValid = true;
        // Validate required fields
        const exc = exchanges[showApi.data.ccxt_id];
        exc.exchange_connection_required_fields.forEach(el => {
            if (!filter[el.field_name]) {
                isValid = false;
            } else {
                key[el.field_name] = filter[el.field_name];
            }
        })

        // Check if all required fields are entered
        if (!isValid) {
            toastr.error('', 'You must enter exchange connection details');
            setFullLoading(false);
            return null;
        }

        return key;
    }

    const onSave = () => {
        setFullLoading(true);
        let type = (isNew && isNew.id) ? 'update' : 'create';

        if (!filter.name) {
            toastr.error('', 'Name required.');
            setFullLoading(false);
        } else if (!filter.exchange_information.exchange_name && filter.type !== "manual") {
            toastr.error('', 'You must choose exchange.');
            setFullLoading(false);
        }  else {
            if (type === 'update') {
                setFullLoading(true);
                PortfolioApi.updateAccount({name:filter.name},isNew.id).then(resp=>{
                    if (fetchKeys) fetchKeys();
                    if (!locState) close();
                    toastr.success('', 'Successfully updated');
                    setFilter(defaultFilter);
                    setFullLoading(false);
                }).catch(err => {
                    console.error(err)
                    setFullLoading(false);
                    toastr.error('Something went wrong while updating account name');
                });
            } else {
                // Validate and create key object
                let key = "";
                if( filter.type !== "manual"){
                    key = generateKey(filter)
                    if (!key) return;
                }

                setFullLoading(true);
                PortfolioApi.createKey(filter.name, filter.exchange_information.exchange_name,filter.type, filter.exchange_information.exchange_code, token, key).then(res => {
                    if (fetchKeys) fetchKeys("new");
                    if (!locState) close();
                    if(flag){
                        PortfolioApi.bindAccountToPortfolio(myPortfolio.id, res.id).then(resp => {
                            fetchDataAccount();
                        })
                    }
                    toastr.success('', 'Successfully Saved');
                    setFilter(defaultFilter);
                    setFullLoading(false);
                    if (locState) history.replace('/news');
                }).catch(err => {
                    console.error(err);
                    setFullLoading(false);
                    toastr.error('Something went wrong while creating key');
                });
            }
        }
    };

    const onClickOpenAccount = (exchangeSymbol)  => {
        const win = window.open(links[exchangeSymbol], "_blank");
        win.focus();
    }

    return (
        <div className={`general-modal ${show ? 'active' : ''}`} id="createAccount">
            <div className="vAlignWr">
                <div className="vAlign">
                    <div className="white-modal-inner set-alert">
                        <div className="modal-blue-header">
                            {isNew ? "Update Account":"Create Account"}
                            <div onClick={close} className="close-modal" data-target="set-news-alert">
                                <img src="/img/close-white.svg" alt="" />
                            </div>
                        </div>
                        <div style={{minWidth: '50vw'}} className="modal-white-body">
                            <div className="general-tab-area">
                                <div className="general-tab-menu">
                                    <ul>
                                        <li className="active">
                                            <a href="#" data-target="price-alert">
                                                {isNew ? "Update Account":"Create Account"}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div style={{color:"gray",padding:"15px",backgroundColor:"#292e33"}}>
                                    <i style={{marginRight:"5px"}} className="fas fa-info-circle"/>
                                    You can upload transactions manually from Portfolio > Transactions
                                </div>
                                <hr style={{borderTop:"1px solid #191d21", marginTop:"0px",marginBottom:"0px"}}/>
                                <div className="general-tab-body-all">
                                    <div className="general-tab-body active" id="news-alert">
                                        <div className="set-news-alert">
                                            <div className="input-table">
                                                {isNew &&
                                                <h5 style={{color:"#fff",textAlign:"center",paddingTop:"10px"}}>You can update only account name !</h5>
                                                }
                                                <table>
                                                    <tbody>
                                                    <tr>
                                                        <td style={{width:"25%"}}>
                                                            <div className="spa-text" style={{textAlign:"left"}}>Account Name:</div>
                                                        </td>
                                                        <td style={{width: '50%'}}>
                                                            <div className="bottom-border-input">
                                                                <input type="text" placeholder="Account Name" maxLength={50} value={filter.name} onChange={val => onChange('name', val.target.value)} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: '25%'}}>
                                                            <div className="spa-text" style={{textAlign:"left"}}>Account Type:</div>
                                                        </td>
                                                        <td style={{width: '50%'}}>
                                                            <div className="sm-select-input">
                                                                <div className="sm-select-input">
                                                                    <Select
                                                                        selected={filter.type || 0}
                                                                        optionValue={'id'}
                                                                        optionLabel={'name'}
                                                                        options={Object.values(accountTypes) || []}
                                                                        onChange={selected => onChange('type', selected.id)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {
                                                        !(isNew && isNew.id) && portfolios.data.length > 0 &&
                                                        <tr>
                                                            <td style={{width: '100%',display:"flex"}}>
                                                                <div className="spa-text" style={{textAlign:"left", marginTop: "10px",marginRight:"0px"}}>Link to Portfolio:</div>
                                                                <div className="alertSentiment" style={{marginLeft: "5px"}}>
                                                                    <FormGroup>
                                                                        <FormControlLabel
                                                                              control={
                                                                                  <Checkbox
                                                                                      checked={flag}
                                                                                      onChange={()=>setFlag(!flag)}
                                                                                      color="primary"
                                                                                      value="sentiment"
                                                                                  />
                                                                              }
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </td>
                                                            {
                                                                flag ?
                                                                <td style={{width: '50%'}}>
                                                                    <div className="sm-select-input">
                                                                        <Select
                                                                            selected={myPortfolio.id || null}
                                                                            optionValue={'id'}
                                                                            optionLabel={'name'}
                                                                            options={portfolios.data || []}
                                                                            onChange={selected => setMyPortfolio({name: selected.name, id: selected.id})}/>
                                                                    </div>
                                                                </td>
                                                                :
                                                                <td style={{width: '50%'}}>
                                                                    <div className="sm-select-input">

                                                                    </div>
                                                                </td>
                                                            }

                                                        </tr>
                                                    }
                                                    </tbody>
                                                </table>
                                                <hr/>
                                                {
                                                    filter.type === "exchange" &&
                                                        <React.Fragment>
                                                            <div className="spa-text">
                                                                Which Exchange?
                                                            </div>
                                                            <div style={{display:"flex",marginTop:"10px"}}>
                                                                {
                                                                    Object.values(exchanges).map(exchange=>{
                                                                        return (
                                                                            <div key={exchange.code} className={activeEffect === exchange.ccxt_id ? "activeExchange" : ""} style={isNew ? {padding:"10px",backgroundColor:"#1d2024",borderRadius:"10px",marginLeft:"5px",marginRight:"5px"}:{padding:"10px",backgroundColor:"#1d2024",borderRadius:"10px",marginLeft:"5px",marginRight:"5px",cursor:"pointer"}} onClick={()=>{
                                                                                if(!isNew){
                                                                                    filter.exchange_information.exchange_name = exchange.ccxt_id;
                                                                                    filter.exchange_information.exchange_code = exchange.code;
                                                                                    setActiveEffect(exchange.ccxt_id);
                                                                                    setShowApi({show:true,data:exchange});
                                                                                }
                                                                            }}>
                                                                                {exchange.code === "BNB" && <img width={225} height={112.5} src={Binance}/>}
                                                                                {exchange.code === "BTT" && <img width={225} height={112.5} src={BTCTurk}/>}
                                                                                {exchange.code === "CBS" && <img width={225} height={112.5} src={CoinBasePro}/>}
                                                                                {exchange.code === "HUO" && <img width={225} height={112.5} src={Huobi}/>}
                                                                                {exchange.code === 'GEM' && <img width={225} height={112.5} src={Gemini}/>}
                                                                                {exchange.code === 'FTX' && <img width={225} height={112.5} src={FTX}/>}
                                                                                {exchange.code === 'KrakenHere' && <img width={225} height={112.5} src={Kraken}/>}
                                                                            </div>)
                                                                    })
                                                                }
                                                            </div>
                                                        </React.Fragment>
                                                }

                                                {
                                                    showApi.show &&
                                                    <div style={{marginTop:"10px"}}>
                                                        <div className="spa-text">
                                                            Connect to {showApi.data.name}
                                                        </div>
                                                        <div className="spa-text" style={{fontSize:"14px",color:"#8e8a8a"}}>
                                                            Don't have an account with {showApi.data.name}? No problem, open one now and then connect.
                                                        </div>
                                                        <div className="light-gray-btn" style={{width:"20%",marginTop:"10px"}}>

                                                            <button className="close-modal" onClick={()=>onClickOpenAccount(showApi.data.code)}>
                                                                OPEN ACCOUNT
                                                            </button>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-8 col-sm-8">
                                                                <div className="spa-text" style={{marginTop:"10px"}}>
                                                                    Connect
                                                                </div>
                                                                <table>
                                                                    <tbody>
                                                                    {showApi.show && showApi.data.exchange_connection_required_fields.map((el,i)=>{
                                                                        return(
                                                                            <tr key={i}>
                                                                                <td style={{width:"25%"}}>
                                                                                    <div className="spa-text" style={{textAlign:"left"}}>{el.field_label}:</div>
                                                                                </td>
                                                                                <td style={{width: '50%'}}>
                                                                                    <div className="bottom-border-input">
                                                                                        <input type="text" placeholder={isNew?"****************************":el.field_placeholder} disabled={isNew? true:false} value={filter[el.field_name]} onChange={val => onChange(el.field_name, val.target.value)} />
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="col-lg-4 col-sm-4">
                                                                <div className="spa-text" style={{marginTop:"10px"}}>
                                                                    Help
                                                                </div>
                                                                <div style={{marginTop:"10px"}}>
                                                                    <a href="#">How do I connect to {showApi.data.name}?</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="clearfix" />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-gray-footer">
                            <div className="form-buttons">
                                <div className="form-flex">
                                    <div className="form-flex-inner" style={{width:"200px"}}>
                                        <div className="light-gray-btn">
                                            <button onClick={close} className="close-modal" data-target="set-news-alert">
                                                CANCEL
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-flex-inner" style={{width:"200px"}}>
                                        <div className="light-blue-btn">
                                            <button disabled={buttonDisable} onClick={onSave}>
                                                {isNew? "UPDATE ACCOUNT":"CREATE ACCOUNT"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};
const mapStateToProps = state => {
    // Generate a map from exchanges list
    const exchangeMap = {}
    state.meta.connectable_exchanges.forEach(el => {
        exchangeMap[el.id] = el;
    })

    return {
        exchanges: exchangeMap
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setFullLoading: data => dispatch(setLoading(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeCreate);
