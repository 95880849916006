import React from 'react';
import {Link} from "react-router-dom";
import {WindowOpener} from "../../WindowOpener";

const Footer = () => {



  return (
    <section style={{padding: '30px 30px 30px 120px'}} className="footer-area">
      <div className="footer-flex">
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: "5px"}} className="footer-inner">
          <div>
            <div className="footer-logo">
              <img src="/img/loginlogo.svg" alt="" />
            </div>
            <div className="footer-txt" style={{marginBottom: "0px"}}>
              <div className="footer-bold-text" style={{marginBottom: "0px"}}>CryptoIndexSeries Version 2.0</div>
            </div>
          </div>
          {/* <div className="footer-blue-head">
            <a target='_blank' href={'https://cryptoindexseries.com/'}>Visit Us</a>
          </div> */}
          <div className="footer-blue-head" style={{alignSelf: "center"}}>
            <a target='_blank' href={'https://cryptoindexseries.com/en/about'}>About</a>
          </div>
          <div className="footer-blue-head" style={{alignSelf: "center"}}>
            <a target='_blank' href={'https://cryptoindexseries.com/en/terms-and-conditions'}>Terms &amp; Conditions</a>
          </div>
          <div className="footer-blue-head" style={{alignSelf: "center"}}>
            <a target='_blank' href={'https://cryptoindexseries.com/en/privacy-policy'}>Privacy Policy</a>
          </div>
          <div className="footer-blue-head" style={{alignSelf: "center"}}>
            <WindowOpener
                url="/contact"
            >
              Contact Us
            </WindowOpener>
          </div>
          <div className="footer-social" style={{marginTop: 0, marginBottom: 0, alignSelf: "center"}}>
            <ul>
              <li>
                <a target="_blank" href="https://twitter.com/cryptoindexs">
                  <img className="actionable-img" src="/img/logo-twitter.svg" alt="" />
                </a>
              </li>
              <li>
                <a target="_blank" href="https://www.linkedin.com/company/cryptoindexseries/about/?viewAsMember=true">
                  <img className="actionable-img" src="/img/logo-linkedin.svg" alt="" />
                </a>
              </li>
              <li>
                <a target="_blank" href="https://t.me/joinchat/LXQ7eBaaQ4qN6JRaVoHtbQ">
                  <img className="actionable-img" src="/img/logo-telegram.svg" alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="copyright">© {new Date().getFullYear()} CryptoIndexSeries . All rights reserved.</div>
    </section>
  );
};
export default Footer;
