import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import {setLoading} from "../../actions/generalAction";
import {toastr} from "react-redux-toastr";
import PortfolioApi from "../../api/PortfolioApi";
import Loading from "../../components/Loading";



const PortfolioBindAccount = props => {
    const {close, // Function change show variable then close modal
           show, // Show variable controlling modal show or not
           myPortfolio , // Portfolio information
           myPortfolioAccounts , // Portfolio linked accounts
           setMyPortfolioAccounts, // Set linked accounts to portfolio
           setPLoading, // Control Loading portfolio accounts
    } = props;

    // All accounts without linked portfolio accounts
    const [accounts,setAccounts] = useState({data:[],loading:true});

    // Re arrange accounts when portfolio change
    useEffect(()=>{
        setPLoading(true);
        fetchData();
    },[myPortfolio]);

    // Re arrange filtered accounts
    const fetchData = () =>{
        if(myPortfolio.id){
            PortfolioApi.getAccountsOfPortfolio(myPortfolio.id).then(respL=>{
                const portfolioAccount = respL.map(resp=>{
                    resp["selected"] = true;
                    return resp;
                })
                setMyPortfolioAccounts({data:portfolioAccount});
                PortfolioApi.getAccounts().then(resp=>{
                    if(resp.length > 0){
                        //filter "Is accounts exist in portfolio or not ?"
                        const newAccounts = resp.filter(account => !respL.some(pAccount => pAccount.id === account.id));
                        setAccounts({data:newAccounts,loading:false})
                    }
                })
            })
        }
        else if(myPortfolio.name !== "empty"){
            setMyPortfolioAccounts({data:[]})
        }
    }

    // When finished adding account, re-arrange accounts
    const onSave = () => {
        fetchData();
        close();
    };

    // Add account to portfolio
    const moveAccount = (porfolioId,accountId,index) => {
        PortfolioApi.bindAccountToPortfolio(porfolioId,accountId).then(resp=>{
            toastr.success('', '\n' + 'Account successfully connected');
            let newPortfolioAccount = [...myPortfolioAccounts.data];
            newPortfolioAccount.push(accounts.data[index]);
            let newAccounts = [...accounts.data];
            newAccounts.splice(index,1);
            setAccounts({data:newAccounts,loading:false});
            setMyPortfolioAccounts({data:newPortfolioAccount});
        }).catch(err => {
            console.error(err);
            toastr.error('Something went wrong while connecting account');
        });
    }

    // Remove account from portfolio
    const moveBackAccount = (porfolioId,accountId,index) => {
        PortfolioApi.removeAccountToPortfolio(porfolioId,accountId).then(resp=>{
            toastr.success('', '\n' + 'Account successfully removed');
            let newPortfolioAccount = [...myPortfolioAccounts.data];
            let newAccounts = [...accounts.data];
            newAccounts.push(myPortfolioAccounts.data[index]);
            newPortfolioAccount.splice(index,1);
            setAccounts({data:newAccounts,loading:false});
            setMyPortfolioAccounts({data:newPortfolioAccount});
        }).catch(err => {
            console.error(err);
            toastr.error('Something went wrong while removing account');
        });
    }

    return (
        <div className={`general-modal ${show ? 'active' : ''}`} id="set-news-alert">
            <div className="vAlignWr">
                <div className="vAlign">
                    <div className="white-modal-inner set-alert">
                        <div className="modal-blue-header">
                            Bind Account to Portfolio
                            <div onClick={close} className="close-modal" data-target="set-news-alert">
                                <img src="/img/close-white.svg" alt="" />
                            </div>
                        </div>
                        <div style={{minWidth: '75vw'}} className="modal-white-body">
                            <div className="general-tab-area">
                                <div className="general-tab-menu">
                                    <ul>
                                        <li className="active">
                                            <a href="#" data-target="price-alert">
                                                Bind
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="general-tab-body-all">
                                    <div className="general-tab-body active" id="news-alert">
                                        <div className="set-news-alert">
                                            <div className="row">
                                                <div  className="col-md-6">
                                                    <Loading loading={false}>
                                                        <h4 style={{color: '#fff',paddingLeft:"11px"}}>Portfolio</h4>
                                                        <table style={{marginTop:"10px",marginBottom:"10px"}}>
                                                            <thead>
                                                            <tr>
                                                                <th style={{color: '#fff',padding:"10px",width:"25%"}}>Account Name</th>
                                                                <th style={{color: '#fff',padding:"10px",width:"25%"}}>Type</th>
                                                                <th style={{color: '#fff',padding:"10px",width:"25%"}}>Exchange</th>
                                                                <th style={{color: '#fff',padding:"10px",width:"25%"}}>Action</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {myPortfolioAccounts && myPortfolioAccounts.data.length > 0 ? myPortfolioAccounts.data.map((d, i) =>
                                                            {
                                                                let new_exchange_name = "";
                                                                if(d.key_details){
                                                                    new_exchange_name = d.key_details.exchange_name.charAt(0).toUpperCase() + d.key_details.exchange_name.slice(1,d.key_details.exchange_name.length);
                                                                }
                                                                return(
                                                                    <tr key={i}>
                                                                        <td style={{color: '#fff',padding:"10px"}}>{d.name}</td>
                                                                        <td style={{color: '#fff',padding:"10px"}}>{d.type.toUpperCase()}</td>
                                                                        {d.key_details ? <td style={{color: '#fff',padding:"10px"}}>{new_exchange_name}</td> : <td style={{color: '#fff',padding:"10px"}}></td>}
                                                                        <td className="edit-delete-btn" style={{cursor: "pointer",padding:"10px 12px"}}>
                                                                            <button className=" open-modal-event" onClick={()=>moveBackAccount(myPortfolio.id,d.id,i)}>
                                                                                <i style={{color:"red"}} title={"Remove account from portfolio"} className="fas fa-2x fa-arrow-right"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                    )
                                                            }

                                                            ):
                                                                <tr>
                                                                    <td colSpan={4} style={{color: '#fff',padding:"10px"}}>There is no binded account in your portfolio.</td>
                                                                </tr>
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </Loading>
                                                </div>
                                                <div className="col-md-6">
                                                    <Loading loading={accounts.loading}>
                                                        <h4 style={{color: '#fff',paddingLeft:"11px"}}>Accounts</h4>
                                                        <table style={{marginTop:"10px",marginBottom:"10px"}}>
                                                            <thead>
                                                            <tr>
                                                                <th style={{color: '#fff',padding:"10px",width:"25%"}}>Account Name</th>
                                                                <th style={{color: '#fff',padding:"10px",width:"25%"}}>Type</th>
                                                                <th style={{color: '#fff',padding:"10px",width:"25%"}}>Exchange</th>
                                                                <th style={{color: '#fff',padding:"10px",width:"25%"}}>Action</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {accounts.data.length > 0 ? accounts.data.map((d, i) => {
                                                                    let new_exchange_name = "";
                                                                    if(d && d.key_details){
                                                                        new_exchange_name = d.key_details.exchange_name.charAt(0).toUpperCase() + d.key_details.exchange_name.slice(1,d.key_details.exchange_name.length);
                                                                    }
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td style={{color: '#fff',padding:"10px"}}>{d.name}</td>
                                                                            <td style={{color: '#fff',padding:"10px"}}>{d.type.toUpperCase()}</td>
                                                                            {d.key_details ? <td style={{color: '#fff',padding:"10px"}}>{new_exchange_name}</td> : <td style={{color: '#fff',padding:"10px"}}></td>}
                                                                            <td className="edit-delete-btn" style={{cursor: "pointer",padding:"10px 12px"}} onClick={()=>moveAccount(myPortfolio.id,d.id,i)}>
                                                                                <button className=" open-modal-event">
                                                                                    <i style={{color:"green"}} title={"Add account to portfolio"} className="fas fa-2x fa-arrow-left"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    );

                                                                }
                                                                ):
                                                                <tr>
                                                                    <td colSpan={4} style={{color: '#fff',padding:"10px"}}>There is no created account.</td>
                                                                </tr>
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </Loading>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-gray-footer">
                            <div className="form-buttons">
                                <div className="form-flex">
                                    <div className="form-flex-inner">
                                        <div className="light-gray-btn">
                                            <button onClick={close} className="close-modal" data-target="set-news-alert">
                                                CANCEL
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-flex-inner">
                                        <div className="light-blue-btn">
                                            <button onClick={onSave}>
                                                Finish
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setFullLoading: data => dispatch(setLoading(data)),
    };
};

export default connect(null, mapDispatchToProps)(PortfolioBindAccount);

