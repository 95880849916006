import React, {useEffect} from "react";
import {withRouter} from "react-router-dom";
import RealtimeUtils from "../../helpers/RealtimeUtils";

const IndexRow = props => {
    const {
        indexRow, // Index data to show
        indexSelected, // Called when index selected with the selected index
        addWatchlist, // Called when add watchlist is clicked with the selected index
        setChannel, // Channel for updating ticker
        addAlert, // Called when add alert is clicked with the selected index
        disabledColumns // Indicates disabled columns
    } = props;

    // Handles real time updates on row
    const [tickerData, setTickerData] = RealtimeUtils.useRealTimeRow(indexRow.ticker.symbol, setChannel,indexRow && indexRow.display_ticker);

    // useEffect(() => {
    //     // Initialize ticker data with index row
    //     setTickerData(indexRow.display_ticker);
    // }, [indexRow]);


    // Check if column is enabled
    const isEnabled = col_id => !disabledColumns.includes(col_id);

    return (
        <tr onClick={e => indexSelected(indexRow)}>
            <td colSpan={2}>{indexRow.index_code}</td>
            <td colSpan={4} style={{"textAlign" : "Left"}}>{indexRow.index_name}</td>
            <td colSpan={2} className={tickerData.price_update_class}>{tickerData.price}</td>
            {isEnabled("high") && <td colSpan={2} className={tickerData.high}>{tickerData.high}</td>}
            {isEnabled("low") && <td colSpan={2} className={tickerData.low}>{tickerData.low}</td>}
            {isEnabled("market_cap") && <td colSpan={2} className={tickerData.market_cap}>{tickerData.market_cap}</td>}
            {isEnabled("chgpct") && <td colSpan={2} className={`${tickerData.chngpct_update_class} ${tickerData.chngpct_class}`}>{tickerData.chngpct}</td>}
            {isEnabled("set_watchlist") && <td colSpan={1} onClick={e => e.stopPropagation()}>
                <div className="three-dot-menu">
                    <div className="dot-menu-icon">
                        <img className="actionable-img" src="/img/three-dot.svg" alt="" />
                    </div>
                    <div className="table-dropdown-menu">
                        <ul>
                            <li>
                                <a
                                    onClick={e => {
                                        e.stopPropagation();
                                        addWatchlist(indexRow);
                                    }}
                                    className=" open-modal-event"
                                    data-target="set-watch-list">
                                    <img className="actionable-img" src="/img/rounded-add-button.svg" alt="" />
                                    Add Watchlist
                                </a>
                            </li>
                            <li>
                                <a
                                    onClick={() => addAlert(indexRow)}
                                    className=" open-modal-event"
                                    data-target="set-alert">
                                    <img className="actionable-img" src="/img/set-alarm.svg" alt="" />
                                    Set Alert
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </td>}
        </tr>
    );
};


export default withRouter(IndexRow);
