import React, {useEffect, useRef, useState} from "react";
import ExchangeApi from "../../api/ExchangeApi";
import moment from "moment";
import OpenOrder from "./OpenOrder";
import Loading from "../../components/Loading";

const Balance = props => {
    const {
        userAccount, // Which account we show for open orders
        selectedAccount, // Which account is selected as primary
        selectedSpot, // Selected spot details
        symbolMappings, // Symbol mappings of selected spot
        orderChanged, // Triggered when an order changed
        setOrderChanged, // Used to trigger order changed
        setSelectedBalance, // Sets balance of the selected account
        exchanges // Exchange information
    } = props;

    // State variables
    const [loading, setLoading] = useState(false);
    const [balance, setBalance] = useState({});

    // Realtime callback reference
    const realTimeCallback = useRef(null);

    // Fetches and organizes balances
    const fetchBalance = () => {
        setLoading(true);
        // Requests to fetch balances for all accounts
        ExchangeApi.getBalances(userAccount.key_id, [selectedSpot.base_asset, selectedSpot.quoted_asset]).then( res => {
            const baseBalance = res.find(el => el.currency === selectedSpot.base_asset) || {used: "NA", free: "NA", total: "NA"};
            const quotedBalance = res.find(el => el.currency === selectedSpot.quoted_asset) || {used: "NA", free: "NA", total: "NA"};

            // Base object to fill
            const b = {
                key_name: userAccount.key_name,
                key_id: userAccount.key_id,
                exchange_name: exchanges[userAccount.exchange].name,
                base_balance: baseBalance,
                base_asset: selectedSpot.base_asset,
                quoted_balance: quotedBalance,
                quoted_asset: selectedSpot.quoted_asset,
                is_open: balance.is_open,
                last_updated: moment().format("DD.MM.YYYY HH:mm:ss")
            };

            // Set balance data by checking open ones
            setBalance(b);
            setLoading(false);
        }).catch(err => {
            console.error(err);
            setLoading(false);
        });
    }

    // Initialize periodic checks for balance
    const startPeriodicCheck = () => {
        // Clear timeout if already running
        if (realTimeCallback.current) {
            clearInterval(realTimeCallback.current);
        }

        // Create new periodic job
        realTimeCallback.current = setInterval(() => {
            fetchBalance();
        }, 60 * 1000)
    }

    useEffect(() => {
        // Fetch balances if base and quoted asset changed (A spot with same assets but different exchange can be selected)
        if (balance.base_asset !== selectedSpot.base_asset || balance.quoted_asset !== selectedSpot.quoted_asset) {
            // Fetch balance for the row
            fetchBalance();

            // Start periodic check for balances
            startPeriodicCheck();
        }
    }, [selectedSpot, userAccount]);

    useEffect(() => {
        // If order changed triggered and this is the selected account update balance
        if (selectedAccount && balance && selectedAccount.key_id === balance.key_id) {
            fetchBalance();

            // Reset periodic check for balances
            startPeriodicCheck();
        }
    }, [orderChanged]);

    // Clean async jobs
    useEffect(() => {
        return () => {
            if (realTimeCallback.current) {
                clearInterval(realTimeCallback.current);
            }
        }
    }, []);

    // Set/Change selected balance
    useEffect(() =>  {
        // Set balance information of selected account
        if (selectedAccount && balance && balance.key_id === selectedAccount.key_id) {
            setSelectedBalance(balance);
        }
    }, [balance, selectedAccount]);

    // Called when balance clicked
    const balanceClicked = () => {
        balance.is_open = !balance.is_open;
        setBalance({...balance});
    }

    // Return balance row, takes balance object
    const getBalanceRow = () => {
        return (
            balance && balance.key_id && <tr key={balance.key_name} onClick={e => {e.stopPropagation(); balanceClicked();}}>
                <td style={{textAlign: 'left'}}>
                    <div className="text"><img style={{margin: "0.8em"}} className={"actionable-img"} src={balance.is_open ? "/img/arrow-down.svg": "/img/arrow-right.svg"}/>{balance.key_name}</div>
                </td>
                <td style={{textAlign: 'left'}}>
                    <div className="text">{balance.exchange_name}</div>
                </td>
                <td style={{textAlign: 'center', borderLeft: "thin solid #33393f"}}>
                    <div className="text">{balance.base_balance.total}</div>
                </td>
                <td style={{textAlign: 'center'}}>
                    <div className="text">{balance.base_balance.used}</div>
                </td>
                <td style={{textAlign: 'center'}}>
                    <div className="text">{balance.base_balance.free}</div>
                </td>
                <td style={{textAlign: 'center', borderLeft: "thin solid #33393f"}}>
                    <div className="text">{balance.quoted_balance.total}</div>
                </td>
                <td style={{textAlign: 'center'}}>
                    <div className="text">{balance.quoted_balance.used}</div>
                </td>
                <td style={{textAlign: 'center'}}>
                    <div className="text">{balance.quoted_balance.free}</div>
                </td>
                <td style={{textAlign: 'end', borderLeft: "thin solid #33393f"}}>
                    <div className="text">{balance.last_updated}</div>
                </td>
            </tr>
        )
    }

    // Returns order takes balance object
    const getOrderRow = () => {
        return (
            <tr>
                <td colSpan={9} style={{background: "rgb(31, 35, 39)", padding: 0}}>
                    <OpenOrder symbolMappings={symbolMappings} userAccount={userAccount} selectedBalance={balance} setOrderChanged={setOrderChanged}/>
                </td>
            </tr>
        );
    }

    return (
        <Loading loading={loading}>
            { balance.is_open ? [getBalanceRow(), getOrderRow()] : getBalanceRow() }
        </Loading>
    );

}

export default Balance;
