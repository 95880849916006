import React, {useState, useEffect} from 'react';
import Breadcrumps from '../../constants/Breadcrumps';
import Breadcrump from '../../components/Breadcrump';
import Api from '../../api';
import {connect} from 'react-redux';
import Select from '../../components/Forms/Select';
import CoinRow from '../../components/CoinRow/CoinRow';
import {useAuth0} from "../../helpers/Auth0";
import CISNavigator from "../../helpers/CISNavigator";
import MobileNavBar from "../../components/MobileNavBar/MobileNavBar";
import Utils from "../../helpers/Utils";
import Watchlist from "../../components/PageDashboard/Watchlist/Watchlist";
const isMobile = Utils.checkMobile();

const Indices = props => {
  const {history, meta} = props;
  const {user} = useAuth0();
  const [indexes, setIndexes] = useState({items: [], loading: true});
  const [exchange, setExchange] = useState(user.preferences.currency);
  const [selectedExchange, setSelectedExchange] = useState({});
  const [watchListShow,setWatchListShow] = useState(user.preferences.show_watchlist);

  const currencyList =
    (meta.lists &&
      meta.lists.key_currency_list &&
      meta.lists.key_currency_list.map(c => ({value: c[0], label: c[0], type: c[2] === 'FIAT' ? 'USD' : 'BTC'}))) ||
    [];

  useEffect(() => {
    let selectedExchange = currencyList.filter(e => e.value === exchange);
    selectedExchange = (selectedExchange.length && selectedExchange[0]) || {};
    setSelectedExchange(selectedExchange);
  }, [exchange]);

  useEffect(() => {
    fetchIndexes(exchange);
  }, [exchange]);

  const fetchIndexes = (ex) => {
    setIndexes({items: [], loading: true});
    Api.getPromise(`/search/IndexSearch?&language_code=en&ccy=${ex}`)
      .then(res => {
        setIndexes({items: (res && res.data) || [], loading: false});
      })
      .catch(e => setIndexes({items: [], loading: false}));
  };

  return (
    isMobile ?
        <div>
          <MobileNavBar/>
          <div>
            {watchListShow? <Watchlist />:<></>}
          </div>
          <div className="mobile-Div">
            <div style={{marginTop: 20}} className="gray-container">
              <div className="table-head">
                <div className="table-left">
                  <div className="table-header">ALL INDICES</div>
                </div>
                <div className="table-right">
                  {/*<div className="table-select-input">*/}
                  {/*  <div className="sm-select-input small-select">*/}
                  {/*    <Select selected={exchange} options={currencyList} onChange={selected => setExchange(selected.value)} />*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
                <div className="clearfix" />
              </div>
              <div className="table-body table-responsive" id="beauty-scroll7">
                <table className="coins enumarable-table  linked-table">
                  <thead>
                  <tr>
                    <th>Name</th>
                    <th>CHG (24h)</th>
                  </tr>
                  </thead>
                  <tbody>
                  {indexes.items.map((row, i) => (
                      <CoinRow key={i} type={'indices'} rowClick={() => {
                        CISNavigator.navigateToIndexDetail(history, row, selectedExchange);
                      }} row={row} selectedExchange={selectedExchange} />
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div style={{height:"80px"}}>
            <p></p>
          </div>
        </div>
          :
        <React.Fragment>
          <section className="coin-table padding-container">
            <div className="watchlist-all-page">
              {watchListShow? <Watchlist />:<></>}
            </div>
            <Breadcrump links={Breadcrumps.indices} />
            <div style={{marginTop: 20}} className="gray-container">
              <div className="table-head">
                <div className="table-left">
                  <div className="table-header">ALL INDICES</div>
                </div>
                <div className="table-right">
                  <div className="table-select-input">
                    <div className="sm-select-input small-select">
                      <Select selected={exchange} options={currencyList} onChange={selected => setExchange(selected.value)} />
                    </div>
                  </div>
                </div>
                <div className="clearfix" />
              </div>
              <div className="table-body table-responsive" id="beauty-scroll7" tabIndex={1} style={{overflow: 'hidden', outline: 'none'}}>
                <table className="coins enumarable-table indices-table linked-table">
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Name</th>
                      <th>Value</th>
                      <th>24h Low</th>
                      <th>24h High</th>
                      <th>Mkt. Cap</th>
                      <th>CHG (24h)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {indexes.items.map((row, i) => (
                      <CoinRow key={i} type={'indices'} rowClick={() => {
                        CISNavigator.navigateToIndexDetail(history, row, selectedExchange);
                        }} row={row} selectedExchange={selectedExchange} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/* <div className="gray-container mtop30" style={{height: '400px'}}>
              <div className="">
                <div className="">
                  <div className="chart-area" style={{marginTop:0}}>
                    <TradingView/>
                  </div>
                </div>
                <div className="clearfix" />
              </div>
            </div> */}
          </section>
        </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    meta: state.meta,
  };
};
export default connect(mapStateToProps)(Indices);
