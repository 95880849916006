import Links from "../../constants/Links";
import Api from "../index";

// Path definitions
const INDICATOR = "/Indicator";


const IndicatorsApi = {
    getPromise(endpoint, params) {
        return Api.getPromise(endpoint, params, Links.BASE_URL)
    },
    getMPromise(endpoint, params) {
        return Api.getMPPromise(endpoint, params, Links.BASE_URL)
    },
    postPromise(endpoint, body) {
        return Api.postPromise(endpoint, body, Links.BASE_URL)
    },
    postMPPromise(endpoint, body) {
        return Api.postMPPromise(endpoint, body, Links.BASE_URL)
    },
    putPromise(endpoint, body) {
        return Api.putPromise(endpoint, body, Links.BASE_URL)
    },
    deletePromise(endpoint, params) {
        return Api.deletePromise(endpoint, params, Links.BASE_URL)
    },
    /**
     * Read indicator list.
     */
    getIndicatorList() {
        return this.getPromise(INDICATOR+"/GetIndicatorList");
    },

    /**
     * Read indicators.
     * @params include indicators infos
     */
    getIndicators(params){
        return this.postPromise(INDICATOR+"/GetIndicators",params)
    },

    /**
     * Read indicator templates.
     */
    getIndicatorsTemplates(){
        return this.getPromise(INDICATOR+"/GetIndicatorTemplates")
    },



}

export default IndicatorsApi;

