import React from 'react';
import {Switch, Route} from "react-router-dom";
import PortfolioBalance from "./PortfolioBalance";
import PortfolioTransactions from "./PortfolioTransactions";

const PortfolioMenuContent = props => {

    const {myPortfolio,portfolioAccounts ,historyAccounts, ccy, totalHoldings, pABHLoading} = props;

    return (
        <Switch>
            <Route path={"/portfolio/balance"} render={() => {
                // const pb = <PortfolioBalance/>
                // return getAccessibleComponent(user, null, "coin_taxanomy", cl);
                return (<PortfolioBalance historyAccounts={historyAccounts} ccy={ccy} totalHoldings={totalHoldings} pABHLoading={pABHLoading}/>);
            }}/>
            <Route path={"/portfolio/transactions"} render={() => {
                // const pt = <PortfolioTransactions/>;
                // return getAccessibleComponent(user, null, "coin_news", cn);
                return (<PortfolioTransactions historyAccounts={historyAccounts} myPortfolio={myPortfolio} portfolioAccounts={portfolioAccounts}/>);
            }}/>
            {/*<Route path={"/portfolio/settings"} render={() => {*/}
            {/*    // const ps = <PortfolioSettings/>;*/}
            {/*    // return getAccessibleComponent(user, null, "coin_taxanomy", clm);*/}
            {/*    return (<PortfolioSettings/>);*/}
            {/*}}/>*/}
        </Switch>
    );
};

export default PortfolioMenuContent;
