import React, { Component } from 'react'
import AlertApi from "../../../api/AlertApi";
import {toastr} from "react-redux-toastr";

const ConfirmationAlert = props => {
    const {
        show,
        close,
        item,
        fetchAlerts,
    } = props;

    const handleDeleteItem = () =>{
        if(item.type === "alert"){
            AlertApi.deleteCryptoAlert(item.id).then(resp=>{
                toastr.success('', 'Successfully deleted alert.');
                fetchAlerts();
                close();
            }).catch(err => {
                toastr.info(`Something went wrong while deleting alert`)
                console.error(err);
            });
        }else{
            AlertApi.deleteNewsAlert(item.id).then(resp=>{
                toastr.success('', 'Successfully deleted alert.');
                fetchAlerts();
                close();
            }).catch(err => {
                toastr.info(`Something went wrong while deleting alert`)
                console.error(err);
            });
        }
    }



    return (
        <div className={`general-modal ${show ? 'active' : ''}`} id="delete-modal">
            <div className="vAlignWr">
                <div className="vAlign">
                    <div className="white-modal-inner set-alert">
                        <div className="modal-blue-header">Delete
                            <div className="close-modal" data-target="delete-modal" onClick={close}>
                                <img src="img/close-white.svg" alt="" />
                            </div>
                        </div>
                        <div className="modal-white-body">
                            <div className="section-header">Are you sure to delete this {item.title}.</div>
                        </div>
                        <div className="modal-gray-footer">
                            <div className="form-buttons">
                                <div className="form-flex">
                                    <div className="form-flex-inner">
                                        <div className="light-gray-btn">
                                            <button className="close-modal" data-target="set-price-alert" onClick={close}>NO</button>
                                        </div>
                                    </div>
                                    <div className="form-flex-inner">
                                        <div className="light-blue-btn">
                                            <button onClick={handleDeleteItem}>YES</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationAlert;

