import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Select from "react-select";
import Loading from "../../components/Loading";
import ArbitrageGrid from "../../components/ArbitrageGrid/ArbitrageGrid";
import Api from "../../api";
import DataMappers from "../../helpers/DataMappers";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";


const ArbitrageAppBS = props => {
    const {type, baseAssets, quotedAssets, pairs, onlyVisible ,setOnlyVisible} = props;

    // Selected base and quote
    const [selectedBase,setSelectedBase] = useState(null);
    const [selectedQuote,setSelectedQuote] = useState(null);

    const [selectedPair,setSelectedPair] = useState({value:"",label:""});

    // Possible filters
    const [baseAssetsOptions, setBaseAssetsOptions] = useState([]);
    const [quotedAssetsOptions, setQuotedAssetsOptions] = useState([]);

    // Data
    const [data,setData] = useState({data:[],loading:true});

    useEffect(()=>{
       if(baseAssets && quotedAssets){
           setBaseAssetsOptions(baseAssets);
           setQuotedAssetsOptions(quotedAssets);
       }
    },[baseAssets, quotedAssets])

    const handleChangeBase = selectedOption => {
        setSelectedBase(selectedOption);
    };

    const handleChangeQuote = selectedOption => {
        setSelectedQuote(selectedOption);
    };

    useEffect(()=>{
        setData({...data,loading: true});
        if(selectedBase && selectedQuote){

            // Generate search params
            const params = {
                page_number: 1,
                is_exchange: true,
                base_asset : selectedBase.value,
                quoted_asset: selectedQuote.value
            };

            Api.spotSearch(params, 100).then(async res => {
                res.data = res.data || [];

                res.data.forEach(el => el.ticker= el.ticker || {});
                // Generate readable tickers
                res.data.forEach(el => {
                    el.org_ticker = el.ticker;
                    el.ticker = DataMappers.spotTickerMapper(el.ticker);
                });
                setSelectedPair({value: selectedBase.value+"/"+selectedQuote.value,label: selectedBase.value+"/"+selectedQuote.value});
                setData({data:res.data,loading: false});
            });
        }else{
            if(selectedBase){
                const newQuoteAssets = pairs.bases[selectedBase.value].map(el=>{
                    return {value: el,label: el};
                });
                setQuotedAssetsOptions(newQuoteAssets);
                setData({data:[],loading: false});
                setSelectedPair({value:"",label: ""});
            }else if(selectedQuote){
                const newBaseAssets = pairs.quotes[selectedQuote.value].map(el=>{
                    return {value: el,label: el};
                });
                setBaseAssetsOptions(newBaseAssets);
                setData({data:[],loading: false});
                setSelectedPair({value:"",label: ""});
            }else{
                setBaseAssetsOptions(baseAssets);
                setQuotedAssetsOptions(quotedAssets);
                setData({data:[],loading: false});
                setSelectedPair({value:"",label: ""});
            }
        }

    },[selectedQuote,selectedBase])

    return (
        <React.Fragment>
            <div style={{display:"flex",marginBottom:"10px"}}>
                <div style={{paddingTop: "10px", marginLeft: "5px"}}>
                    <div style={{padding: "0px", width: "150px"}}>
                        <Select
                            value={selectedBase}
                            onChange={handleChangeBase}
                            options={baseAssetsOptions || []}
                            placeholder="Base"
                            isClearable={true}
                        />
                    </div>
                </div>
                <div style={{paddingTop: "10px", marginLeft: "5px"}}>
                    <div style={{padding: "0px", width: "150px"}}>
                        <Select
                            value={selectedQuote}
                            onChange={handleChangeQuote}
                            options={quotedAssetsOptions || []}
                            placeholder="Quote"
                            isClearable={true}
                        />
                    </div>
                </div>
                <div style={{paddingTop: "10px", marginLeft: "5px"}}>
                    <div style={{display:"flex"}}>
                        <div className="onlyLatest">
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={onlyVisible}
                                            onChange={()=> setOnlyVisible(!onlyVisible)}
                                            color="primary"
                                        />
                                    }
                                />
                            </FormGroup>
                        </div>
                        <div className="spa-text" style={{textAlign:"left", marginTop: "12px",marginRight:"0px",color:"white"}}>Only Latest</div>
                    </div>
                </div>
            </div>
            <Loading loading={data.loading}>
                <ArbitrageGrid data={data.data} selectedPair={selectedPair} type={type} visible={onlyVisible}/>
            </Loading>
        </React.Fragment>
    );
};


const prepareInputProps = state => {
    return {
        meta: state.meta,
        exchanges: state.meta.exchanges
    };
};

export default connect(prepareInputProps)(withRouter(ArbitrageAppBS));
