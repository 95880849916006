import Utils from "../../helpers/Utils";
import React from 'react';
import DataMappers from "../../helpers/DataMappers";
import CISNavigator from "../../helpers/CISNavigator";
import {withRouter} from "react-router-dom";

const isMobile = Utils.checkMobile();

const SummaryModal = props => {
  const {cancel, show, item ,userPref, history} = props;
  const sentiment = item.sentiment || {};
  // Sentiment bubble style generator
  const sentimentStyle = (value) => {
    return {
      borderRadius: "50%",
      width: "10vh",
      height: "10vh",
      lineHeight: "10vh",
      textAlign: "center",
      color: "white",
      fontSize: "1.5em",
      fontWeight: "bold",
      background: DataMappers.mapSentimentColor(value),

    }
  };

  const tagTypes = ["COIN", "ASSET_TYPE", "SECTOR", "LOCATION", "EXCHANGE", "OTHER"];
  const tagHeaders = {
    "ASSET_TYPE" : "Asset Types",
    "SECTOR" : "Sectors",
    "LOCATION" : "Nations",
    "COIN" : "Coins",
    "EXCHANGE" : "Exchanges",
    "OTHER" : "Categories"
  };

  const userPrefMatch = () =>{
    let sentimentPrefValue = 0;
    if(userPref === "title_sentiment" && sentiment){
      sentimentPrefValue = sentiment.title;
    }else if(userPref === "summary_sentiment" && sentiment){
      sentimentPrefValue = sentiment.summary;
    }else if(userPref === "content_sentiment" && sentiment){
      sentimentPrefValue = sentiment.content;
    }
    const allRanges = document.querySelectorAll(".range-wrap");
    allRanges.forEach(wrap => {
      const range = wrap.querySelector(".slider");
      const bubble = wrap.querySelector(".bubble");
      setBubble(range, bubble);
      setBubble(range, bubble);
    });

    function setBubble(range, bubble) {
      const val = sentimentPrefValue*100;
      const min = range.min ? range.min : 0;
      const max = range.max ? range.max : 100;
      const newVal = Number(((val - min) * 100) / (max - min));

      bubble.innerHTML = parseInt(val, 10)+"%";

      // Sorta magic numbers based on size of the native UI thumb
      bubble.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
    }

    return(
        <div className="range-wrap" style={{width:"100%"}}>
          <div style={{paddingBottom:"10px"}}>
            <span className="sentimentStyle-span" style={{fontWeight: 'bold', color: "#306fa5"}}>News Sentiment Score:</span>
          </div>
          <input type="range" min="-100" max="100" readOnly value={sentimentPrefValue ? sentimentPrefValue * 100 : 0} className="slider" id="myRange"/>
          <output className="bubble" >{sentimentPrefValue * 100}%</output>
        </div>

    );
  }

  // Parse news tags for visualisation
  const tagParser = (tags, tagType) => {
    if (!tags || tags.length <= 0) {
      return ["-"];
    }

    // Extract tags
    const relatedTags = tags.filter(el => el.startsWith(tagType));

    if (relatedTags.length > 0) {
      return relatedTags.map(el => {
        // Detect tag type
        const parsed = el.split("_");

        // Parse values by tag types
        let tagRes = "-";
        switch (tagType) {
          case "ASSET_TYPE":
            tagRes = parsed[2] + " " + parsed[3];
            break;
          case "SECTOR":
            tagRes = parsed.slice(1, parsed.length -2).join(" ");
            break;
          case "LOCATION":
            tagRes = parsed.slice(1).join(" ");
            break;
          case "COIN":
            tagRes = parsed.slice(1).join(" ");
            break;
          case "EXCHANGE":
            tagRes = parsed.slice(1).join(" ");
            break;
          case "OTHER":
            tagRes = parsed.slice(1).join(" ");
            break;
          default:
            return "-";
        }
        return tagRes;
      });
    } else {
      return ["-"];
    }
  };

  return (
      isMobile?

          <div className={`general-modal ${(show && 'active') || ''}`} id="delete-modal-wlitem"  >
            <div className="backexit" onClick={cancel} style={{zIndex:"-20px"}} >
              <div className="vAlignWr" >
                <div className="vAlign" >
                  <div className="white-modalfornews-inner set-alert" >
                    <div className="modal-blue-header" style={{ display: "flex", flexDirection: "row", paddingBottom: "7px"}}  >

                      <div className="remove-image-class" style={{height: "35px", display: "flex", flexDirection: "column", justifyContent: "top",cursor: 'pointer'}} onClick={() => window.open(item.link.substring(0,item.link.indexOf("/",item.link.indexOf("//")+2)), '_blank')} >
                        <img style={{height: "100%", padding: "0%", flex: "0 0 auto", alignSelf: "center", marginRight: "7px"}} src={item.image_url} />
                      </div>
                      <div>{item.title}</div>
                      <div onClick={cancel} data-target="delete-modal-wlitem" style={{cursor: "pointer"}}>
                        <img src="/img/close-white.svg" alt="" style={{"padding": "5px"}}/>
                      </div>
                    </div>
                    <div className="modal-white-body" >
                      <div style={{display: "flex", flexDirection: "column"}}>
                        <div className="remove-flex" style={{display: 'flex', flexDirection: 'row'}}>

                          <div className="modal-content-width" style={{color: '#fff', width: 500, flex: "1 1 500px", marginLeft: "1vw", textAlign: "justify", lineHeight: "1.5", display: "flex", flexDirection: "column"}}>
                            <p style={{marginBottom: "3vh"}}>{item.summary}

                              <a style={{cursor: 'pointer'}} onClick={() => window.open(item.link, '_blank')}>
                                {'  '}Read More...
                              </a>
                            </p>
                            <div style={{display: "flex", flexWrap: "wrap"}}>
                              {tagTypes.map(el => (tagParser(item.tags, el)!="-" &&
                                      <div key={el} className={"col-xs-6"} style={{paddingLeft: "0px", paddingRight: "1vw", marginBottom: "2vh"}}>
                                        <p style={{display: "flex", flexDirection: "column", textAlign: "left"}}>
                                          <span style={{fontWeight: 'bold', color: "#306fa5"}}>{tagHeaders[el]}: </span>
                                          {tagParser(item.tags, el).join(", ")}
                                        </p>
                                      </div>
                                  )
                              )}
                            </div>
                          </div>
                        </div>
                        <div style={{marginTop: "2vh",paddingBottom: "25px"}}>
                          <div>
                            <div className={"loat-left-mobile"} style={{display: "flex", flexDirection: "column", alignItems: "center"}} id="loat-left-first">
                              {
                                userPrefMatch()
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          :


          <div className={`general-modal ${(show && 'active') || ''}`} id="delete-modal-wlitem"  >
            <div className="backexit"  style={{zIndex:"-20px"}} >
            <div className="vAlignWr" >
              <div className="vAlign" >
                <div className="white-modalfornews-inner set-alert" >
                  <div className="modal-blue-header" style={{ display: "flex", flexDirection: "row", paddingBottom: "7px"}}  >

                  <div className="remove-image-class" style={{height: "35px", display: "flex", flexDirection: "column", justifyContent: "top",cursor: 'pointer'}} onClick={() => window.open(item.link.substring(0,item.link.indexOf("/",item.link.indexOf("//")+2)), '_blank')} >
                          <img style={{height: "100%", padding: "0%", flex: "0 0 auto", alignSelf: "center", marginRight: "7px"}} src={item.image_url} />
                        </div>
                        <div className="horizontal-line"> </div>
                    <div style={{width: "40vw", marginRight: "1vw", marginTop: "auto", marginBottom: "auto", marginLeft: "10px"}}>{item.title}</div>
                    <div onClick={cancel} data-target="delete-modal-wlitem" style={{cursor: "pointer"}}>
                      <img src="/img/close-white.svg" alt="" style={{"padding": "5px"}}/>
                    </div>
                  </div>
                  <div className="modal-white-body" >
                    <div style={{display: "flex", flexDirection: "column"}}>
                      <div className="remove-flex" style={{display: 'flex', flexDirection: 'row'}}>

                        <div className="modal-content-width" style={{color: '#fff', width: 500, flex: "1 1 500px", marginLeft: "1vw", textAlign: "justify", lineHeight: "1.5", display: "flex", flexDirection: "column"}}>
                          <div style={{display: "flex", flexWrap: "wrap"}}>
                            <div className={"col-lg-12"} style={{padding:"0px"}}>
                              <p style={{marginBottom: "3vh"}}>{item.summary}

                                <a style={{cursor: 'pointer'}} onClick={() => window.open(item.link, '_blank')}>
                                  {'  '}Read More...
                                </a>
                              </p>
                            </div>
                            <div className={"col-lg-12"}style={{padding:"40px 0px"}}>
                              <div className={"loat-left-mobile"} style={{display: "flex", flexDirection: "column", alignItems: "center"}} id="loat-left-first">
                                {
                                  userPrefMatch()
                                }
                              </div>
                            </div>
                          </div>

                          <div style={{display: "flex", flexWrap: "wrap"}}>
                              {tagTypes.map(el => {
                                return(
                                    tagParser(item.tags, el)!="-" &&

                                    <div key={el} className={"col-lg-4 col-lg-mobile-0"} style={{paddingLeft: "0px", paddingRight: "2vw", marginBottom: "2vh"}}>
                                      <p style={{display: "flex", flexDirection: "column", textAlign: "left"}}>
                                        <span style={{fontWeight: 'bold', color: "#306fa5"}}>{tagHeaders[el]}: </span>
                                        {
                                          el === "COIN" ?
                                              <div style={{display:"flex", flexWrap:"wrap"}}>
                                                {tagParser(item.tags, el).map(el=>{
                                                  return(
                                                      <a style={{cursor:"pointer",color:"white"}} className={"coinsLink"} onClick={() => CISNavigator.navigateToCoinDetail(history, {symbol:el})}>
                                                        {el} &nbsp;
                                                      </a>
                                                  )
                                                })}
                                              </div>
                                              :
                                              tagParser(item.tags, el).join(", ")
                                        }

                                      </p>
                                    </div>
                                )
                              }
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
    
  );
};

export default withRouter(SummaryModal);
