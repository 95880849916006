const CalculateSocketData = {
  conversationSelection(state, action) {
    let XXX_YYY = null;
    let YYY_ZZZ = null;
    let XXX_ZZZ = null;
    let ZZZ_XXX = null;
    let ZZZ_YYY = null;
    let YYY_XXX = null;
    let direction = null;
    if ((state !== undefined || state !== null) && (action !== undefined || action !== null)) {
      let subVal =
        state.subsNeeded && Object.values(state.subsNeeded).find(i => i.CurrencyFrom === action.FROMSYMBOL && i.CurrencyTo === state.exchange);
      if (subVal) {
        if (subVal.ConversionSymbol !== '') {
          // ARA DÖNÜŞÜM COIN TİPİ
          switch (subVal.Conversion) {
            case 'multiply':
              XXX_YYY = action;
              YYY_ZZZ = state.listenCoin.find(i => i.FROMSYMBOL === subVal.ConversionSymbol && i.TOSYMBOL === state.exchange);
              direction = subVal.Conversion;
              break;

            case 'divide':
              // XXX_YYY and ZZZ_YYY
              XXX_YYY = action;
              ZZZ_YYY = state.listenCoin.find(i => i.FROMSYMBOL === state.exchange && i.TOSYMBOL === subVal.ConversionSymbol);
              direction = subVal.Conversion;
              break;

            case 'invert_multiply':
              // YYY_XXX and ZZZ_YYY
              YYY_XXX = action;
              ZZZ_YYY = state.listenCoin.find(i => i.FROMSYMBOL === state.exchange && i.TOSYMBOL === subVal.ConversionSymbol);
              direction = subVal.Conversion;
              break;

            case 'invert_divide':
              // YYY_ZZZ and YYY_XXX
              // YYY_ZZZ = action;
              YYY_ZZZ = state.listenCoin.find(i => i.FROMSYMBOL === subVal.ConversionSymbol && i.TOSYMBOL === state.exchange);
              YYY_XXX = state.listenCoin.find(i => i.FROMSYMBOL === subVal.ConversionSymbol && i.TOSYMBOL === subVal.CurrencyFrom);
              direction = subVal.Conversion;
              break;

            default:
              break;
          }
        } else {
          switch (subVal.Conversion) {
            case 'direct':
              XXX_ZZZ = action;
              direction = subVal.Conversion;
              break;

            case 'invert':
              ZZZ_XXX = action;
              direction = subVal.Conversion;
              break;
            case 'not_needed':
              direction = subVal.Conversion;
              break;

            default:
              break;
          }
        }
      }
    }

    return this.calculateCoin(XXX_YYY, YYY_ZZZ, XXX_ZZZ, ZZZ_XXX, ZZZ_YYY, YYY_XXX, direction);
  },

  calculateCoin(XXX_YYY, YYY_ZZZ, XXX_ZZZ, ZZZ_XXX, ZZZ_YYY, YYY_XXX, direction) {
    switch (direction) {
      case 'direct':
        return {
          TOSYMBOL: XXX_ZZZ.FROMSYMBOL,
          PRICE: XXX_ZZZ && XXX_ZZZ.PRICE !== undefined ? XXX_ZZZ.PRICE : null,
          VOLUMEHOUR: XXX_ZZZ && XXX_ZZZ.VOLUME24HOUR !== undefined ? XXX_ZZZ.VOLUME24HOUR : null,
          VOLUMEHOURTO: XXX_ZZZ && XXX_ZZZ.VOLUME24HOURTO !== undefined ? XXX_ZZZ.VOLUME24HOURTO : null,
          CHANGE:
            XXX_ZZZ && XXX_ZZZ.PRICE !== undefined && XXX_ZZZ && XXX_ZZZ.OPEN24HOUR
              ? ((XXX_ZZZ.PRICE - XXX_ZZZ.OPEN24HOUR) / XXX_ZZZ.OPEN24HOUR) * 100
              : null,
        };

      case 'invert':
        return {
          // XXX_ZZZ  = 1 / ZZZ_XXX
          TOSYMBOL: ZZZ_XXX.TOSYMBOL,
          PRICE: ZZZ_XXX && ZZZ_XXX.PRICE !== undefined ? 1 / ZZZ_XXX.PRICE : null,
          VOLUMEHOUR: ZZZ_XXX && ZZZ_XXX.VOLUME24HOUR !== undefined ? 1 / ZZZ_XXX.VOLUME24HOUR : null,
          VOLUMEHOURTO: ZZZ_XXX && ZZZ_XXX.VOLUME24HOURTO !== undefined ? 1 / ZZZ_XXX.VOLUME24HOURTO : null,
          OPEN24HOUR: ZZZ_XXX && ZZZ_XXX.OPEN24HOUR !== undefined ? 1 / ZZZ_XXX.OPEN24HOUR : null,
          LOW24HOUR: ZZZ_XXX && ZZZ_XXX.LOW24HOUR !== undefined ? 1 / ZZZ_XXX.LOW24HOUR : null,
          HIGH24HOUR: ZZZ_XXX && ZZZ_XXX.HIGH24HOUR !== undefined ? 1 / ZZZ_XXX.HIGH24HOUR : null,
          CHANGE:
            ZZZ_XXX && ZZZ_XXX.PRICE !== undefined && ZZZ_XXX && ZZZ_XXX.OPEN24HOUR
              ? (1 / ((ZZZ_XXX.PRICE - ZZZ_XXX.OPEN24HOUR) / ZZZ_XXX.OPEN24HOUR)) * 100
              : null,
        };
        // break;

      case 'multiply':
        // XXX_ZZZ  = XXX_YYY * YYY_ZZZ
        return {
          TOSYMBOL: XXX_YYY.FROMSYMBOL,
          PRICE: XXX_YYY && XXX_YYY.PRICE !== undefined && YYY_ZZZ && YYY_ZZZ.PRICE !== undefined ? XXX_YYY.PRICE * YYY_ZZZ.PRICE : null,
          VOLUMEHOUR:
            XXX_YYY && XXX_YYY.VOLUME24HOUR !== undefined && YYY_ZZZ && YYY_ZZZ.VOLUME24HOUR !== undefined
              ? XXX_YYY.VOLUME24HOUR * YYY_ZZZ.VOLUME24HOUR
              : null,
          VOLUMEHOURTO:
            XXX_YYY && XXX_YYY.VOLUME24HOURTO !== undefined && YYY_ZZZ && YYY_ZZZ.VOLUME24HOURTO !== undefined
              ? XXX_YYY.VOLUME24HOURTO * YYY_ZZZ.VOLUME24HOURTO
              : null,
          OPEN24HOUR:
            XXX_YYY && XXX_YYY.OPEN24HOUR !== undefined && YYY_ZZZ && YYY_ZZZ.OPEN24HOUR !== undefined
              ? XXX_YYY.OPEN24HOUR * YYY_ZZZ.OPEN24HOUR
              : null,
          LOW24HOUR:
            XXX_YYY && XXX_YYY.LOW24HOUR !== undefined && YYY_ZZZ && YYY_ZZZ.LOW24HOUR !== undefined ? XXX_YYY.LOW24HOUR * YYY_ZZZ.LOW24HOUR : null,
          HIGH24HOUR:
            XXX_YYY && XXX_YYY.HIGH24HOUR !== undefined && YYY_ZZZ && YYY_ZZZ.HIGH24HOUR !== undefined
              ? XXX_YYY.HIGH24HOUR * YYY_ZZZ.HIGH24HOUR
              : null,
          CHANGE:
            XXX_YYY && XXX_YYY.PRICE && XXX_YYY && XXX_YYY.OPEN24HOUR && YYY_ZZZ && YYY_ZZZ.PRICE && YYY_ZZZ && YYY_ZZZ.OPEN24HOUR
              ? (((XXX_YYY.PRICE - XXX_YYY.OPEN24HOUR) / XXX_YYY.OPEN24HOUR + 1) * ((YYY_ZZZ.PRICE - YYY_ZZZ.OPEN24HOUR) / YYY_ZZZ.OPEN24HOUR + 1) -
                  1) *
                100
              : null,
        };

      case 'divide':
        // XXX_ZZZ  = XXX_YYY / ZZZ_YYY
        return {
          TOSYMBOL: XXX_YYY.FROMSYMBOL,
          PRICE: XXX_YYY && XXX_YYY.PRICE !== undefined && ZZZ_YYY && ZZZ_YYY.PRICE !== undefined ? XXX_YYY.PRICE / ZZZ_YYY.PRICE : null,
          VOLUMEHOUR:
            XXX_YYY && XXX_YYY.VOLUME24HOUR !== undefined && ZZZ_YYY && ZZZ_YYY.VOLUME24HOUR !== undefined
              ? XXX_YYY.VOLUME24HOUR / ZZZ_YYY.VOLUME24HOUR
              : null,
          VOLUMEHOURTO:
            XXX_YYY && XXX_YYY.VOLUME24HOURTO !== undefined && ZZZ_YYY && ZZZ_YYY.VOLUME24HOURTO !== undefined
              ? XXX_YYY.VOLUME24HOURTO / ZZZ_YYY.VOLUME24HOURTO
              : null,
          OPEN24HOUR:
            XXX_YYY && XXX_YYY.OPEN24HOUR !== undefined && ZZZ_YYY && ZZZ_YYY.OPEN24HOUR !== undefined
              ? XXX_YYY.OPEN24HOUR / ZZZ_YYY.OPEN24HOUR
              : null,
          LOW24HOUR:
            XXX_YYY && XXX_YYY.LOW24HOUR !== undefined && ZZZ_YYY && ZZZ_YYY.LOW24HOUR !== undefined ? XXX_YYY.LOW24HOUR / ZZZ_YYY.LOW24HOUR : null,
          HIGH24HOUR:
            XXX_YYY && XXX_YYY.HIGH24HOUR !== undefined && ZZZ_YYY && ZZZ_YYY.HIGH24HOUR !== undefined
              ? XXX_YYY.HIGH24HOUR / ZZZ_YYY.HIGH24HOUR
              : null,
          CHANGE:
            XXX_YYY && XXX_YYY.PRICE !== undefined && ZZZ_YYY && ZZZ_YYY.OPEN24HOUR
              ? (((XXX_YYY.PRICE - XXX_YYY.OPEN24HOUR) / XXX_YYY.OPEN24HOUR) * 100) /
                (((ZZZ_YYY.PRICE - ZZZ_YYY.OPEN24HOUR) / ZZZ_YYY.OPEN24HOUR) * 100)
              : null,
        };

      case 'invert_multiply':
        // XXX_ZZZ = ZZZ_YYY / YYY_XXX
        return {
          TOSYMBOL: YYY_XXX.TOSYMBOL,
          PRICE: ZZZ_YYY && ZZZ_YYY.PRICE !== undefined && YYY_XXX && YYY_XXX.PRICE !== undefined ? ZZZ_YYY.PRICE / YYY_XXX.PRICE : null,
          VOLUMEHOUR:
            ZZZ_YYY && ZZZ_YYY.VOLUME24HOUR !== undefined && YYY_XXX && YYY_XXX.VOLUME24HOUR !== undefined
              ? ZZZ_YYY.VOLUME24HOUR / YYY_XXX.VOLUME24HOUR
              : null,
          VOLUMEHOURTO:
            ZZZ_YYY && ZZZ_YYY.VOLUME24HOURTO !== undefined && YYY_XXX && YYY_XXX.VOLUME24HOURTO !== undefined
              ? ZZZ_YYY.VOLUME24HOURTO / YYY_XXX.VOLUME24HOURTO
              : null,
          OPEN24HOUR:
            ZZZ_YYY && ZZZ_YYY.OPEN24HOUR !== undefined && YYY_XXX && YYY_XXX.OPEN24HOUR !== undefined
              ? ZZZ_YYY.OPEN24HOUR / YYY_XXX.OPEN24HOUR
              : null,
          LOW24HOUR:
            ZZZ_YYY && ZZZ_YYY.LOW24HOUR !== undefined && YYY_XXX && YYY_XXX.LOW24HOUR !== undefined ? ZZZ_YYY.LOW24HOUR / YYY_XXX.LOW24HOUR : null,
          HIGH24HOUR:
            ZZZ_YYY && ZZZ_YYY.HIGH24HOUR !== undefined && YYY_XXX && YYY_XXX.HIGH24HOUR !== undefined
              ? ZZZ_YYY.HIGH24HOUR / YYY_XXX.HIGH24HOUR
              : null,
          CHANGE:
            ZZZ_YYY && ZZZ_YYY.PRICE !== undefined && YYY_XXX && YYY_XXX.OPEN24HOUR
              ? (((ZZZ_YYY.PRICE - ZZZ_YYY.OPEN24HOUR) / ZZZ_YYY.OPEN24HOUR) * 100) /
                (((YYY_XXX.PRICE - YYY_XXX.OPEN24HOUR) / YYY_XXX.OPEN24HOUR) * 100)
              : null,
        };

      case 'invert_divide':
        // XXX_ZZZ = YYY_ZZZ / YYY_XXX
        return {
          TOSYMBOL: YYY_XXX.TOSYMBOL,
          PRICE: YYY_ZZZ && YYY_ZZZ.PRICE !== undefined && YYY_XXX && YYY_XXX.PRICE !== undefined ? YYY_ZZZ.PRICE / YYY_XXX.PRICE : null,
          VOLUMEHOUR:
            YYY_ZZZ && YYY_ZZZ.VOLUME24HOUR !== undefined && YYY_XXX && YYY_XXX.VOLUME24HOUR !== undefined
              ? YYY_ZZZ.VOLUME24HOUR / YYY_XXX.VOLUME24HOUR
              : null,
          VOLUMEHOURTO:
            YYY_ZZZ && YYY_ZZZ.VOLUME24HOURTO !== undefined && YYY_XXX && YYY_XXX.VOLUME24HOURTO !== undefined
              ? YYY_ZZZ.VOLUME24HOURTO / YYY_XXX.VOLUME24HOURTO
              : null,
          OPEN24HOUR:
            YYY_ZZZ && YYY_ZZZ.OPEN24HOUR !== undefined && YYY_XXX && YYY_XXX.OPEN24HOUR !== undefined
              ? YYY_ZZZ.OPEN24HOUR / YYY_XXX.OPEN24HOUR
              : null,
          LOW24HOUR:
            YYY_ZZZ && YYY_ZZZ.LOW24HOUR !== undefined && YYY_XXX && YYY_XXX.LOW24HOUR !== undefined ? YYY_ZZZ.LOW24HOUR / YYY_XXX.LOW24HOUR : null,
          HIGH24HOUR:
            YYY_ZZZ && YYY_ZZZ.HIGH24HOUR !== undefined && YYY_XXX && YYY_XXX.HIGH24HOUR !== undefined
              ? YYY_ZZZ.HIGH24HOUR / YYY_XXX.HIGH24HOUR
              : null,
          CHANGE:
            YYY_ZZZ && YYY_ZZZ.PRICE !== undefined && YYY_XXX && YYY_XXX.OPEN24HOUR
              ? (((YYY_ZZZ.PRICE - YYY_ZZZ.OPEN24HOUR) / YYY_ZZZ.OPEN24HOUR) * 100) /
                (((YYY_XXX.PRICE - YYY_XXX.OPEN24HOUR) / YYY_XXX.OPEN24HOUR) * 100)
              : null,
        };

      case 'not_needed':
        // XXX_ZZZ = YYY_ZZZ / YYY_XXX
        return {
          PRICE: 1,
          CHANGE: "0",
        };

      default:
        break;
    }
  },
};

/*
XXX_YYY
AVG: 0.01955
FLAGS: "2052"
FROMSYMBOL: "HT"
HIGH24HOUR: 0.02005
HIGHHOUR: 0.01994
LASTMARKET: "Bibox"
LASTTRADEID: 15712958440001
LASTUPDATE: 1571295844
LASTVOLUME: 0.6993
LASTVOLUMETO: 0.013677916392
LOW24HOUR: 0.01937
LOWHOUR: 0.01954
MARKET: "CCCAGG"
OPEN24HOUR: 0.01952
OPENHOUR: 0.01979
PRICE: 0.01955
TOSYMBOL: "ETH"
TYPE: "5"
VOLUME24HOUR: 353091.7573075658
VOLUME24HOURTO: 6928.83095971904
VOLUMEHOUR: 101865.91900000007
VOLUMEHOURTO: 2005.292139695367

YYY_ZZZ
AVG: 174.08
FLAGS: "2052"
FROMSYMBOL: "ETH"
HIGH24HOUR: 179.62
HIGHHOUR: 174.79
LASTMARKET: "BTCAlpha"
LASTTRADEID: 42667173
LASTUPDATE: 1571295848
LASTVOLUME: 0.000213
LASTVOLUMETO: 0.037062
LOW24HOUR: 171.98
LOWHOUR: 173
MARKET: "CCCAGG"
OPEN24HOUR: 178.93
OPENHOUR: 174.77
PRICE: 174.26
TOSYMBOL: "USD"
TYPE: "5"
VOLUME24HOUR: 240900.064288939
VOLUME24HOURTO: 42091002.46210549
VOLUMEHOUR: 39602.053804385214
VOLUMEHOURTO: 6881334.943803198
*/
export default CalculateSocketData;
