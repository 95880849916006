import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import {setLoading} from "../../actions/generalAction";
import {toastr} from "react-redux-toastr";
import PortfolioApi from "../../api/PortfolioApi";
import PortfolioManagement from "./PortfolioManagement";
import AccountManagement from "./AccountManagement";


const PortfolioCreate = props => {
    const {
        close, // Function change show variable then close modal
        show, // Show variable controlling modal show or not
        setFullLoading, // All application loading animation control
    } = props;

    // All portfolio accounts
    const [myPortfolioAccounts,  setMyPortfolioAccounts] = useState(null);

    // Selected portfolio account
    const [myPortfolio, setMyPortfolio] = useState({name: "empty", id: ''});

    //Menu active
    const [menu, setMenu] = useState("Portfolio");

    //Portfolios
    const [portfolios, setPortfolios] = useState({data: [], loading: true});

    // Include portfolio information when user want create portfolio
    const [portfolioName, setPortfolioName] = useState("");

    // All accounts without linked portfolio accounts
    const [accounts, setAccounts] = useState({data: [], loading: true});

    // Re arrange accounts when portfolio change
    useEffect(() => {
        fetchData();
    }, [myPortfolio]);

    // Re arrange filtered accounts
    const fetchData = () => {
        if (myPortfolio.id && myPortfolio.id !== "-") {
            PortfolioApi.getAccountsOfPortfolio(myPortfolio.id).then(respL => {
                if (respL.length > 0) {
                    const portfolioAccount = respL.map(resp => {
                        resp["selected"] = true;
                        return resp;
                    })
                    setMyPortfolioAccounts({data: portfolioAccount});
                } else {
                    setMyPortfolioAccounts({data: []});
                }
                PortfolioApi.getAccounts().then(resp => {
                    if (resp.length > 0) {
                        //filter "Is accounts exist in portfolio or not ?"
                        const newAccounts = resp.filter(account => !respL.some(pAccount => pAccount.id === account.id));
                        const newAccountsSelected = newAccounts.map(resp => {
                            resp["selected"] = true;
                            return resp;
                        })
                        setAccounts({data: newAccountsSelected, loading: false})
                    } else {
                        setAccounts({data: [], loading: false})
                    }
                })
            })
        } else if (myPortfolio.name !== "empty") {
            setMyPortfolioAccounts({data: []})
            setAccounts({data: [], loading: false})
        }
    }


    const getPortfolios = (type) => {
        PortfolioApi.getPortfolios().then(resp => {
            if (resp.length > 0) {
                resp = resp.map((el)=>{
                    return ({...el,show:false});
                })
                setPortfolios({data: resp, loading: false});
                if(type !== "create"){
                    setMyPortfolio(resp[0]);
                }

            } else {
                setPortfolios({data: [], loading: false});
                setMyPortfolio({name: "-", id: '-'});
            }
        });
    }


    useEffect(() => {
        getPortfolios("first");
    }, [])


    // Save create or edit portfolio account
    const onSave = () => {
        setFullLoading(true);
        if (!portfolioName) {
            toastr.error('', 'Name required.');
            setFullLoading(false);
            return;
        }

        PortfolioApi.createPortfolio({name: portfolioName}).then(resp => {
            toastr.success('', 'Successfully created portfolio account');
            setMyPortfolio(resp);
            getPortfolios("create");
            setFullLoading(false);
        }).catch(err => {
            console.error(err);
            setFullLoading(false);
            toastr.error('Something went wrong while creating portfolio account');
        });

    };

    // Add account to portfolio
    const moveAccount = (porfolioId, accountId, index) => {
        PortfolioApi.bindAccountToPortfolio(porfolioId, accountId).then(resp => {
            toastr.success('', '\n' + 'Account successfully connected');
            let newPortfolioAccount = [...myPortfolioAccounts.data];
            newPortfolioAccount.push(accounts.data[index]);
            let newAccounts = [...accounts.data];
            newAccounts.splice(index, 1);
            setAccounts({data: newAccounts, loading: false});
            setMyPortfolioAccounts({data: newPortfolioAccount});
        }).catch(err => {
            console.error(err);
            toastr.error('Something went wrong while connecting account');
        });
    }

    // Remove account from portfolio
    const moveBackAccount = (porfolioId, accountId, index) => {
        PortfolioApi.removeAccountToPortfolio(porfolioId, accountId).then(resp => {
            toastr.success('', '\n' + 'Account successfully removed');
            let newPortfolioAccount = [...myPortfolioAccounts.data];
            let newAccounts = [...accounts.data];
            newAccounts.push(myPortfolioAccounts.data[index]);
            newPortfolioAccount.splice(index, 1);
            setAccounts({data: newAccounts, loading: false});
            setMyPortfolioAccounts({data: newPortfolioAccount});
        }).catch(err => {
            console.error(err);
            toastr.error('Something went wrong while removing account');
        });
    }

    return (
        <div className={`general-modal ${show ? 'active' : ''}`} id="set-news-alert">
            <div className="vAlignWr">
                <div className="vAlign">
                    <div className="white-modal-inner set-alert">
                        <div className="modal-blue-header" style={{height: "50px"}}>
                            <div onClick={close} className="close-modal" data-target="set-news-alert">
                                <img src="/img/close-white.svg" alt=""/>
                            </div>
                        </div>
                        <div style={{minWidth: '60vw'}} className="modal-white-body">
                            <div className="general-tab-area">
                                <div className="general-tab-menu">
                                    <ul>
                                        <li className={menu === "Portfolio" ? "active" : ""}>
                                            <a href="#" data-target="price-alert" onClick={() => setMenu("Portfolio")}>
                                                Portfolio Management
                                            </a>
                                        </li>
                                        <li className={menu !== "Portfolio" ? "active" : ""}>
                                            <a href="#" data-target="price-alert" onClick={() => setMenu("Account")}>
                                                Account Management
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                {
                                    menu === "Portfolio" ?
                                    <PortfolioManagement portfolioName={portfolioName}
                                                         setPortfolioName={setPortfolioName} onSave={onSave}
                                                         portfolios={portfolios}
                                                         myPortfolio={myPortfolio}
                                                         setMyPortfolio={setMyPortfolio}
                                                         myPortfolioAccounts={myPortfolioAccounts}
                                                         accounts={accounts}
                                                         moveAccount={moveAccount}
                                                         moveBackAccount={moveBackAccount}
                                                         getPortfolios={getPortfolios}
                                                         setPortfolios={setPortfolios}

                                    />
                                    :
                                    <AccountManagement portfolios={portfolios} fetchDataAccount={fetchData} getPortfolios={getPortfolios}/>
                                }
                            </div>
                        </div>
                        <div className="modal-gray-footer">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setFullLoading: data => dispatch(setLoading(data)),
    };
};

export default connect(null, mapDispatchToProps)(PortfolioCreate);

