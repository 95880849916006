const PortfolioIndex = (PineJS) => { return {
    // Replace the <study name> with your study name
    // The name will be used internally by the Charting Library
    name: "balance",
    metainfo: {
        "_metainfoVersion": 40,
        "id": "index@tv-basicstudies-2",
        "scriptIdPart": "",
        "name": "Balance",

        // This description will be displayed in the Indicators window
        // It is also used as a "name" argument when calling the createStudy method
        "description": "Balance",

        // This description will be displayed on the chart
        "shortDescription": "Balance",

        "is_hidden_study": true,
        "is_price_study": true,
        "isCustomIndicator": true,

        "plots": [{"id": "plot", "type": "line"}],
        "defaults": {
            "styles": {
                "plot": {
                    "linestyle": 0,
                    "visible": true,

                    // Plot line width.
                    "linewidth": 1,

                    // Plot type:
                    //    1 - Histogram
                    //    2 - Line
                    //    3 - Cross
                    //    4 - Area
                    //    5 - Columns
                    //    6 - Circles
                    //    7 - Line With Breaks
                    //    8 - Area With Breaks
                    "plottype": 1,

                    // Show price line?
                    "trackPrice": false,

                    // Plot transparency, in percent.
                    "transparency": 20,

                    // Plot color in #RRGGBB format
                    "color": "#880000"
                }
            },

            // Precision of the study's output values
            // (quantity of digits after the decimal separator).
            "precision": 2,

            "inputs": {
                "symbol" : {id: "source", name: "source", value: "open"},
                "source" :  {id: "symbol", name: "symbol", value: "BTC"}
            }
        },
        "styles": {
            "plot": {
                // Output name will be displayed in the Style window
                "title": "plot",
                // "histogramBase": 0,
            }
        },
        "inputs": [{id: "source", name: "source", value: "open"}, {id: "symbol", name: "symbol", value: "BTC"}]
    },

    constructor: function() {
        this.init = function(context, inputCallback) {
            this._context = context;
            this._input = inputCallback;

            // Define the symbol to be plotted.
            // Symbol should be a string.
            // You can use PineJS.Std.ticker(this._context) to get the selected symbol's ticker.
            // For example,
            //    var symbol = "AAPL";
            //    var symbol = "#EQUITY";
            //    var symbol = PineJS.Std.ticker(this._context) + "#TEST";
            this._context.new_sym(this._input(1), PineJS.Std.period(this._context), PineJS.Std.period(this._context));
        };

        this.main = function(context, inputCallback) {
            this._context = context;
            this._input = inputCallback;

            this._context.select_sym(1);
            // You can use following built-in functions in PineJS.Std object:
            //    open, high, low, close
            //    hl2, hlc3, ohlc4
            let v = PineJS.Std.open(this._context);
            if (this._input(0) === "Pos") {
                v = PineJS.Std.high(this._context);
            } else if (this._input(0) === "Neg") {
                v = PineJS.Std.low(this._context);
            }
            return [v];
        }
    }
}};

export default PortfolioIndex;
