import React, {useEffect, useRef, useState} from "react";
import {withRouter} from "react-router-dom";
import Speedometer from "../Speedometer/Speedometer";
import IndicatorsApi from "../../api/IndicatorsApi";
import Loading from "../Loading";
import OscillatorsTable from "./OscillatorsTable";
import MovingAverageTable from "./MovingAverageTable";
import Select from "../Forms/Select";
import SetTemplateModal from "../Modals/SetTemplateModal";
import UserApi from "../../api/UserApi";

const intervals = [
    {value:"1m",label:"1 minute"},
    {value:"5m",label:"5 minutes"},
    {value:"15m",label:"15 minutes"},
    {value:"30m",label:"30 minutes"},
    {value:"1h",label:"1 hours"},
    {value:"1d",label:"1 day"},
]

const CoinTechnicalTab = props => {

    // Get coin symbol and selected menu (default BTC and detail)
    const coinSymbol = (props.match.params && props.match.params.coinSymbol) || "BTC";
    // Get additional params (ccy)
    const params = new URLSearchParams(props.location.search);
    const exchangeCode = params.get("ccy");

    const {ccy} = props;

    const [oscillators, setOscillators] = useState({data: [], sell: 0, neutral: 0, buy: 0, all: 0, loading:true});

    const [movingAvarages, setMovingAvarages] = useState({data: [], sell: 0, neutral: 0, buy: 0, all: 0, loading:true});

    const [summary, setSummary] = useState({sell: 0, neutral: 0, buy: 0, all: 0, loading:true});

    const [interval, setInterval] = useState({value:"1m",label:"1 minute"});

    const [templates,setTemplates] = useState([]);

    const [selectedTemplate,setSelectedTemplate] = useState({label:"Default",value:""});

    const [indicatorList, setIndicatorList] = useState([]);

    const [indicatorsInfo, setIndicatorsInfo] = useState({});

    const [showModal, setShowModal] = useState(false);

    const [type, setType] = useState("Create");

    useEffect(()=>{

        setOscillators({...oscillators,loading: true});

        setMovingAvarages({...movingAvarages,loading: true});

        if(ccy.label && interval.value){
           IndicatorsApi.getIndicatorsTemplates().then(resp=>{

               let param = resp.DEFAULT;

               let newTemplates = [...templates];

               newTemplates.push({label:"Default", value:resp.DEFAULT});

               UserApi.getUserIndicatorTemplate().then(response=>{
                   response.forEach(el=>{
                       newTemplates.push({label:el.name, value:el});
                   })

                   setTemplates(newTemplates);

                   setSelectedTemplate({label:"Default", value:resp.DEFAULT});
               })
           })
        }
    },[ccy]);

    useEffect(()=>{
        IndicatorsApi.getIndicatorList().then(resp=>{
            const mappedIndicators = Object.keys(resp).map(el=>{
                return {label:el,value:el};
            })
            setIndicatorsInfo(resp);
            setIndicatorList(mappedIndicators);
        })
    },[]);

    useEffect(()=>{
        if(selectedTemplate.value !== ""){

            setOscillators({...oscillators,loading: true});

            setMovingAvarages({...movingAvarages,loading: true});

            let param = selectedTemplate.value;

            param.symbol = coinSymbol + "-" + ccy.value + ".CISCALC";

            param.interval = interval.value;

            IndicatorsApi.getIndicators(param).then(resp=>{

                let oscillator = [];
                let movingAvarage = [];


                resp.indicator_results && resp.indicator_results.forEach(el=>{
                    if(el.indicator_type === "Moving Average"){
                        movingAvarage.push(el);
                    }else{
                        oscillator.push(el);
                    }
                });


                setOscillators({...oscillators, data:oscillator,loading: false});

                setMovingAvarages({...movingAvarages, data:movingAvarage, loading: false});

            })
        }
    },[selectedTemplate,interval])

    const getValueComponent = (obj) => {

        return (
            <div style={{display: "flex", justifyContent: "center"}}>
                <div style={{textAlign: "center"}}>
                    <div style={{color: '#c11e0f'}}>
                        {obj.sell}
                    </div>
                    <div style={{color: "white", fontSize: "16px"}}>
                        Sell
                    </div>
                </div>
                <div style={{textAlign: "center", marginLeft: "20px", marginRight: "20px"}}>
                    <div style={{color: '#d1d4dc'}}>
                        {obj.neutral}
                    </div>
                    <div style={{color: "white", fontSize: "16px"}}>
                        Neutral
                    </div>
                </div>
                <div style={{textAlign: "center"}}>
                    <div style={{color: '#7ac231'}}>
                        {obj.buy}
                    </div>
                    <div style={{color: "white", fontSize: "16px"}}>
                        Buy
                    </div>
                </div>
            </div>
        )
    }

    const calculateScore = (obj) => {
        const value = (obj.buy - obj.sell) / obj.all;

        if (value > 0.5) {
            return ({index: 0, value: 10})
        } else if (value < 0.5 && value > 0.1) {
            return ({index: 1, value: 30})
        } else if (value < -0.1 && value > -0.5) {
            return ({index: 3, value: 70})
        } else if (value < -0.5) {
            return ({index: 4, value: 90})
        } else {
            return ({index: 2, value: 50})
        }
    }

    const handleDeleteTemplate = () =>{
        UserApi.deleteIndicatorTemplate(selectedTemplate.value.id).then(resp=>{
            setOscillators({...oscillators,loading: true});

            setMovingAvarages({...movingAvarages,loading: true});

            if(ccy.label && interval.value){
                IndicatorsApi.getIndicatorsTemplates().then(resp=>{

                    let param = resp.DEFAULT;

                    let newTemplates = [];

                    newTemplates.push({label:"Default", value:resp.DEFAULT});

                    UserApi.getUserIndicatorTemplate().then(response=>{
                        response.forEach(el=>{
                            newTemplates.push({label:el.name, value:el});
                        })

                        setTemplates(newTemplates);

                        setSelectedTemplate({label:"Default", value:resp.DEFAULT});
                    })
                })
            }
        })
    }

    const handleClose = () =>{
        setShowModal(false);
        setType("");
    }

    return (
        <React.Fragment>
            <div className="gray-container" style={{marginTop: "10px"}}>
                <div className="row">
                    <div className="col-lg-12 col-sm-12" style={{marginBottom:"15px"}}>
                        <div style={{display:"flex"}}>
                            {intervals.map((el,index)=>{
                                return(
                                    <div key={index} className={interval.value === el.value ? "interval-osc-active" : "interval-osc"} onClick={()=>setInterval(el)}>
                                        {el.label}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {/*<div className="col-lg-4 col-sm-12">*/}
                    {/*    <div style={{textAlign: "center", fontSize: "16px", color: "white", fontWeight: "bold"}}>*/}
                    {/*        Oscillators*/}
                    {/*    </div>*/}
                    {/*    <Speedometer score={calculateScore(oscillators)}/>*/}
                    {/*    {getValueComponent(oscillators)}*/}
                    {/*</div>*/}
                    {/*<div className="col-lg-4 col-sm-12">*/}
                    {/*    <div style={{textAlign: "center", fontSize: "16px", color: "white", fontWeight: "bold"}}>*/}
                    {/*        Summary*/}
                    {/*    </div>*/}
                    {/*    <Speedometer score={calculateScore(summary)}/>*/}
                    {/*    {getValueComponent(summary)}*/}
                    {/*</div>*/}
                    {/*<div className="col-lg-4 col-sm-12">*/}
                    {/*    <div style={{textAlign: "center", fontSize: "16px", color: "white", fontWeight: "bold"}}>*/}
                    {/*        Moving Averages*/}
                    {/*    </div>*/}
                    {/*    <Speedometer score={calculateScore(movingAvarages)}/>*/}
                    {/*    {getValueComponent(movingAvarages)}*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className="gray-container" style={{marginTop: "10px"}}>
                <div className="border-bottom-input floating-label-wrap" style={{display:"flex",justifyContent:"space-between"}}>
                    <div style={{zIndex:"100"}}>
                        <div className="sm-select-input floating-label-field floating-label-field--s1" style={{paddingRight: "0px", paddingLeft: "0px", paddingTop: "15px",zIndex: 10000, width:"250px"}}>
                            <Select
                                selected={selectedTemplate.value || null}
                                optionValue={'value'}
                                optionLabel={'label'}
                                options={templates || []}
                                onChange={selected => setSelectedTemplate(selected)}/>
                        </div>
                        <label className="floating-label" style={{fontSize:"16px", opacity:"1"}}>
                            Templates
                        </label>
                    </div>
                    <div>
                        {
                            selectedTemplate.label !== "Default" &&
                                <React.Fragment>
                                    <div style={{width: 120}} className="red-border-btn flRight">
                                        <a style={{cursor: "pointer"}} onClick={() => {
                                            handleDeleteTemplate();
                                        }}>Delete</a>
                                    </div>
                                    <div style={{width: 120, marginRight:"10px"}} className="blue-border-btn flRight">
                                        <a style={{cursor: "pointer"}} onClick={() => {
                                            setType("Update");
                                            setShowModal(true);
                                        }}>Update</a>
                                    </div>
                                </React.Fragment>
                        }
                        <div style={{width: 120, marginRight:"10px"}} className="blue-border-btn flRight">
                            <a style={{cursor: "pointer"}} onClick={() => {
                                setType("Create");
                                setShowModal(true);
                            }}>Add</a>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop:"20px"}}>
                    <div className="col-lg-6 col-sm-12">
                        <OscillatorsTable oscillators={oscillators} />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <MovingAverageTable movingAvarages={movingAvarages} />
                    </div>
                </div>
            </div>
            <SetTemplateModal show={showModal} close={()=>handleClose()} indicators={indicatorsInfo} indicatorList={indicatorList} templates={templates} setTemplates={setTemplates} type={type} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate}/>
        </React.Fragment>

    );
};

export default withRouter(CoinTechnicalTab);
