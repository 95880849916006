import React from "react";
import PortfolioApi from "../../api/PortfolioApi";
import {toastr} from "react-redux-toastr";


const PortfolioDelete = props => {
    const {editPortfolio, // Portfolio information which user want edit
           show, // Show variable controlling modal show or not
           close, // Function change show variable then close modal
           getPortfolios
    } = props;

    const onDelete = () => {
        PortfolioApi.deletePortfolio(editPortfolio.id).then(res => {
            toastr.success(`Portfolio successfully deleted...`);
            getPortfolios("update");
            close();
        }).catch(err => {
            toastr.error(`Something went wrong while deleting portfolio...`)
            console.error(err);
        });
    };
    return (
        <div className={`general-modal ${show ? 'active' : ''}`} id="delete-modal">
            <div className="vAlignWr">
                <div className="vAlign">
                    <div className="white-modal-inner set-alert">
                        <div className="modal-blue-header">
                            Delete
                            <div onClick={close} className="close-modal" data-target="delete-modal">
                                <img src="/img/close-white.svg" alt="" />
                            </div>
                        </div>
                        <div className="modal-white-body">
                            <div className="section-header">Are you sure to delete this portfolio.</div>
                        </div>
                        <div className="modal-gray-footer">
                            <div className="form-buttons">
                                <div className="form-flex">
                                    <div className="form-flex-inner">
                                        <div className="light-gray-btn">
                                            <button onClick={close} className="close-modal" data-target="set-price-alert">
                                                NO
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-flex-inner">
                                        <div className="light-blue-btn">
                                            <button onClick={onDelete}>YES</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PortfolioDelete;
