import React, {useState} from 'react';

const TopBannerArea = () => {
  const [isBannerClose, setIsBannerClose] = useState(localStorage.getItem('isBannerClose'));
  return null;
  // if (isBannerClose) return null;
  // return (
  //   <div style={{width: '100%'}} className="top-banner-area">
  //     <div
  //       onClick={() => {
  //         setIsBannerClose(true);
  //         localStorage.setItem('isBannerClose', 'true');
  //       }}
  //       className="close-banner">
  //       <img src="img/close-button.svg" alt="" />
  //     </div>
  //     <div className="vAlignWr">
  //       <div className="vAlign">
  //         <Image style={{width: '100%'}} src={'banner.jpg'} />
  //         {/* <div className="top-banner-text">Banner (728x90)</div> */}
  //       </div>
  //     </div>
  //   </div>
  // );
};
export default TopBannerArea;
