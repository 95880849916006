import AppHelpers from "../../helpers/AppHelpers";
import * as zlib from "zlib";



const getOrderBookUrl = () => "wss://api-aws.huobi.pro/ws";

class HuobiApi {

    constructor() {}

    subscribeOrderbook(symbol, buyHandler, sellHandler) {
        const conn = new WebSocket(getOrderBookUrl());

        conn.binaryType = "arraybuffer";


        let zip = {
            gzip: function gzip(input, options) {
                return new Promise(function (resolve, reject) {
                    zlib.gzip(input, options, function (error, result) {
                        if (!error) resolve(result); else reject(Error(error));
                    });
                });
            },
            ungzip: function ungzip(input, options) {
                return new Promise(function (resolve, reject) {
                    zlib.gunzip(input, options, function (error, result) {
                        if (!error) resolve(result); else reject(Error(error));
                    });
                });
            }
        };

        // Subscribe to symbol on open
        conn.onopen = () => {

            conn.send(JSON.stringify({
                "sub": "market."+symbol+".mbp.refresh.20",
                "id": "id1"
            }));
        };

        this.conn = conn;
        this.buyHandler = buyHandler;
        this.sellHandler = sellHandler;

        this.conn.onmessage = ((event) => {

            var uint8View = new Uint8Array(event.data);

            zip.ungzip(uint8View).then(result=>{
                // Parse message to JSON
                const json = JSON.parse(result.toString());

                if(json.ping){
                    conn.send(JSON.stringify({
                        "pong":json.ping,
                    }));
                }else if(!json.id){
                    if (!json.tick.asks && !json.tick.bids) { // Not interested
                        return;
                    }

                    // // Compute totals on bids
                    const bids = json.tick.bids && json.tick.bids.map(el => {
                        const amount = AppHelpers.priceFormat(el[0] * el[1]);
                        return [el[0], el[1], amount];
                    });
                    //
                    // // Compute totals on asks
                    const asks = json.tick.asks && json.tick.asks.map(el => {
                        const amount = AppHelpers.priceFormat(el[0] * el[1]);
                        return [el[0], el[1], amount];
                    });
                    // // Update buy and sell data
                    this.buyHandler(bids.reverse());
                    this.sellHandler(asks);
                }
            })
        });
    }

    destroyConnection() {
        this.conn && this.conn.close();
    }
}

export default HuobiApi;
