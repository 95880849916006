import React, {useEffect, useState} from 'react';
import SetWatchListModal from '../../components/Modals/SetWatchListModal';
import Confirm from '../../components/Confirm/Confirm';
import {toastr} from 'react-redux-toastr';
import UserApi from "../../api/UserApi";
import {checkQuotaAccess, useAuth0} from "../../helpers/Auth0";

const WatchListCreate = props => {
  const {setSelectedWatchlist} = props;
  const [show, setShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [watchlists, setWatchlists] = useState([]);
  const {user} = useAuth0();

  useEffect(() => {
      fetchWatchlists();
  }, []);

  const changeWatch = watchlist => {
      setSelectedWatchlist(watchlist);
  };

  const changeFavorite = watchlist => {
      if (!watchlist.is_favorite) {
          UserApi.setFavorite(watchlist.watchlist_id).then(res => {
              setWatchlists(res);
          }).catch(ex => setWatchlists([]));
      }
  }

  const fetchWatchlists = () => {
      UserApi.getWatchlists().then(res => {
          const favorite = res.filter(el => el.is_favorite)[0];
          setSelectedWatchlist(favorite);
          setWatchlists(res)
      });
  }

  const list = () => {
    return watchlists.map((w, i) => (
      <tr key={i}>
        <td onClick={() => changeWatch(w)} style={{cursor: 'pointer'}}>
          {w.name}
        </td>
        <td className="edit-delete-btn">
          <button
            onClick={() => { changeFavorite(w); }}
            className="open-modal-event"
            data-target="set-watch-list">
            <i style={{color: '#fff'}} className={w.is_favorite ? 'fas fa-star' : 'far fa-star'} />
          </button>
          <button
            style={{marginRight: 15}}
            onClick={() => {
              setShow(true);
              setIsNew(w);
            }}
            className=" open-modal-event"
            data-target="set-watch-list">
            <img className="actionable-img" src="/img/edit.svg" alt="" />
          </button>
          <button
            onClick={() => {
              if (watchlists.length < 2) toastr.warning('', 'You must have at least one list.');
              else if (w.is_favorite) toastr.warning('', 'Default watch list can not be removed.');
              else setDeleteShow(w.watchlist_id);
            }}>
            <img className="actionable-img" src="/img/delete.svg" alt="" />
          </button>
        </td>
      </tr>
    ));
  };

  const del = () => {
    UserApi.deleteWatchlist(deleteShow).then(res => {
        fetchWatchlists();
        setDeleteShow(false);
    });
  };

  return (
    <div className="gray-container alerts-price-alert" style={{marginTop: 0, height: "100%", display: "flex", flexDirection: "column"}}>
      <div className="section-header">Watch List</div>
      <div className="pa-inner" style={{display: "flex", flexDirection: "column", flex: "1 1 auto"}}>
        <div className="scroll-table" id="beauty-scroll-table" style={{flex: "1 1 255px", height: "auto"}}>
          <table>
            <tbody>{list()}</tbody>
          </table>
        </div>
        <div className="green-border-btn" style={{marginLeft: "auto"}}>
          <a
            onClick={() => {
              const quota = checkQuotaAccess(user, 'watchlist', watchlists.length);
              if (quota) {
                  setShow(true);
                  setIsNew(false);

              } else {
                  toastr.error("", `You cannot create new watchlist. Upgrade your plan (Remaining Quota: ${quota}).`)
              }
            }}
            className="open-modal-event"
            data-target="set-watch-list">
            Create Watchlist
          </a>
        </div>
        <div className="clearfix" />
      </div>
      <SetWatchListModal {...props} modalShow={show} isCreate={true} closeModal={() => setShow(false)} refreshWatchlists={setWatchlists} isNew={isNew} />
      <Confirm message={'Are you sure to delete this watchlist?'} show={deleteShow} ok={del} cancel={() => setDeleteShow(false)} />
    </div>
  );
};

export default WatchListCreate;
