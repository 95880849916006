import React, {Component, useEffect, useState} from 'react';
import {connect} from 'react-redux';


export class Contact extends Component {

    componentDidMount() {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/shell.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    portalId: '7183506',
                    formId: '2c864b5d-8a62-4c45-b9cf-c9e5cd678810',
                    target: '#hubspotForm',
                    region: "na1"
                })
            }
        });
    }

    render() {
        return (
            <section className="dashboard padding-sm-container" style={{paddingLeft: "30px",paddingTop:"20px"}}>
                <div style={{display:"flex",justifyContent:"center"}}>
                    <div style={{display:"flex"}}>
                        <img height={100} src={"/cis_logo.png"}/>
                        <img height={50} src={"/img/cis_logo_text_colour-1.png"}/>
                    </div>
                </div>
                <div className={`gray-container`} style={{marginTop: "10px",background:"#dee2e6"}}>
                    <div id="hubspotForm"></div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        state,
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Contact);
