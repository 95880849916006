import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import CreateAndUpdateConditionModal from "../CreateCryptoAlert/CreateAndUpdateConditionModal";
import {toastr} from "react-redux-toastr";
import AlertApi from "../../../api/AlertApi";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ReactTooltip from "react-tooltip";

const defaultAlert = () => {
    return (
        {
            name: "",
            conditions: [{
                market: null,
                symbol: null,
                currency: null,
                direction: {value: "above", label: "Above"},
                targetField: {value: "price", label: "Price"},
                value: ""
            }],
            id: "",
            webpn:false,
            email:false,
        }
    )
}


const CryptoAlertModal = props => {
    const {
        show,
        close,
        type,
        newType,
        updated, // updated data
        getExchangeName, // provide exchange label name
        fetchData
    } = props;

    // Alert
    const [alert, setAlert] = useState(defaultAlert());
    // Alert Conditions
    const [conditions, setConditions] = useState(defaultAlert().conditions);
    // Const new type
    const [typeNew, setTypeNew] = useState("");


    useEffect(() => {
        if (type === "Update" && updated.id) {
            let alert = defaultAlert();
            alert.id = updated.id;
            alert.name = updated.data.alert_name;
            const mappedConditions = updated.data.alert_data.map((el) => {
                const mappedMarket = {value: el.pair.split(".")[1], label: getExchangeName(el.pair.split(".")[1])};
                const mappedSymbol = {value: el.pair.split("-")[0], label: el.pair.split("-")[0]};
                const mappedCurrency = {
                    value: el.pair.split("-")[1].split(".")[0],
                    label: el.pair.split("-")[1].split(".")[0]
                };
                const mappedDirection = {
                    value: el.direction,
                    label: el.direction.charAt(0).toUpperCase() + el.direction.slice(1)
                };
                const mappedTargetField = {
                    value: el.target_field,
                    label: el.target_field.charAt(0).toUpperCase() + el.target_field.slice(1)
                };

                return ({
                    market: mappedMarket,
                    symbol: mappedSymbol,
                    currency: mappedCurrency,
                    direction: mappedDirection,
                    targetField: mappedTargetField,
                    value: el.target_value
                })
            })
            updated.data.notification_device.forEach(el=>{
                if(el === "email"){
                    alert.email = true;
                }else if( el === "webpn"){
                    alert.webpn = true;
                }
            })
            alert.conditions = mappedConditions;
            setAlert(alert);
            setConditions(mappedConditions);
            setTypeNew("Update");
        }else if (updated.data && type === "Add") {
            let newAlert = defaultAlert();
            newAlert.conditions[0].market = {value:updated.data.exchange,label:getExchangeName(updated.data.exchange)}
            newAlert.conditions[0].symbol = {value:updated.data.symbol,label:updated.data.symbol}
            newAlert.conditions[0].currency = {value:updated.data.ccy,label:updated.data.ccy}
            newAlert.conditions[0].value = updated.data.value
            setAlert(newAlert);
            setConditions(newAlert.conditions);
            setTypeNew("Update")
        }
        else if (!updated.id && type === "Create") {
            setAlert(defaultAlert);
            setConditions(defaultAlert().conditions);
            setTypeNew(type);
        }
    }, [type])


    const handleChangeName = text => {
        let newAlert = {...alert};
        newAlert["name"] = text.target.value;
        setAlert(newAlert);
    };

    const handleAddCondition = () => {
        let newConditions = [...conditions];
        newConditions.push(defaultAlert().conditions[0]);
        setConditions(newConditions);
    }

    const handleCreate = () => {
        let newAlert = {...alert};
        newAlert.conditions = conditions;
        const control = controlConditions();
        if (newAlert.name === "") {
            toastr.error('', 'You must enter alert name.');
            return;

        } else if(!newAlert.webpn && !newAlert.email ){
            toastr.error('', 'You must select at least one notification option.');
            return;
        } else {
            if (control.flag) {
                const param = {
                    "alert_name": newAlert.name,
                    "alert_data": control.conditions,
                    "notification_device": control.notification_device
                }
                AlertApi.createCryptoAlert(param).then(resp=>{
                    if(type !== "Add"){
                        fetchData();
                    }
                    toastr.success('', 'Successfully created alert.');
                    close();
                }).catch(function(error) {
                    toastr.error('', error.response.data.reason);
                })
            }else{
                toastr.error('', 'You must fill all fields for conditions.');
            }
        }
    }

    const handleUpdate = () => {
        let newAlert = {...alert};
        newAlert.conditions = conditions;
        const control = controlConditions();
        if (newAlert.name === "") {
            toastr.error('', 'You must enter alert name.');
            return;

        }else if(!newAlert.webpn && !newAlert.email ){
            toastr.error('', 'You must select at least one notification option.');
            return;
        } else {
            if (control.flag) {
                const param = {
                    "alert_name": newAlert.name,
                    "alert_data": control.conditions,
                    "notification_device": control.notification_device
                }
                AlertApi.updateCryptoAlert(newAlert.id,param).then(resp=>{
                    fetchData();
                    toastr.success('', 'Successfully updated alert.');
                    close();
                })
            }else{
                toastr.error('', 'You must fill all fields for conditions.');
            }
        }
    }

    const handleDeleteCondition = (index) => {
        let newConditions = [...conditions];
        newConditions.splice(index, 1);
        setConditions(newConditions);
    }

    // Control conditions field
    const controlConditions = () => {
        let control = {flag: true};
        let newConditions = [];
        conditions.forEach(el => {
            if (!el.currency || !el.market || !el.symbol || el.value === "") {
                control = {flag: false};
            } else {
                let newCondition = {
                    "pair": el.symbol.value + "-" + el.currency.value + "." + el.market.value,
                    "direction": el.direction.value,
                    "target_field": el.targetField.value,
                    "target_value": parseFloat(el.value)
                }
                newConditions.push(newCondition);
            }
        })
        control.conditions = newConditions;
        control.notification_device = [];
        if(alert.email){
            control.notification_device.push("email");
        }
        if(alert.webpn){
            control.notification_device.push("webpn");
        }
        return control;
    }

    const handleNotification = (type) =>{
        let newAlert = {...alert}
        newAlert[type] = !newAlert[type];
        setAlert(newAlert);
    }

    return (
        <div className={`general-modal ${show ? 'active' : ''}`} id="set-price-alert">
            <div className="vAlignWr">
                <div className="vAlign">
                    <div className="white-modal-inner set-alert">
                        <div className="modal-blue-header" style={{height:"60px"}}>
                            <div onClick={close} className="close-modal" data-target="set-price-alert">
                                <img src="img/close-white.svg" alt=""/>
                            </div>
                        </div>
                        <div className="modal-white-body" >
                            <div className="gray-container alerts-price-alert"
                                 style={{display: "flex", flexDirection: "column", justifyContent: "space-between",marginTop:"0px",width:"1200px"}}>
                                <div className="row" style={{padding: "5px 0px"}}>
                                    <div className="col-lg-12" style={{padding: "0px 15px"}}>
                                        <h4 style={{
                                            color: "white",
                                            textAlign: "center",
                                            fontSize: "20px"
                                        }}>{newType.label + " Market Data Alert"}</h4>
                                        <hr style={{borderTop: "1px solid #3c4145"}}/>
                                    </div>
                                    <div className="col-lg-12" style={{padding: "0px 15px"}}>
                                        <div style={{display:"flex", padding: "0px 10px"}}>
                                            <div className="filter-bar-input1 " style={{padding: "0px"}}>
                                                <TextField
                                                    size={"small"}
                                                    label="Alert Name"
                                                    margin="normal"
                                                    variant="outlined"
                                                    onChange={handleChangeName}
                                                    value={alert.name}
                                                    fullWidth
                                                />
                                            </div>
                                        </div>
                                        <hr style={{borderTop: "1px solid #3c4145"}}></hr>
                                    </div>
                                    <div className="col-lg-12"
                                         style={{padding: "0px 15px", display: "flex"}}>
                                        <h4 style={{
                                                color: "white",
                                                fontSize: "18px",
                                                padding: "0px 10px"
                                            }}>{"Conditions:"}</h4>

                                    </div>
                                    <div className="col-lg-12" style={{padding: "0px 15px", marginTop: "10px"}}>
                                        <hr style={{borderTop: "1px solid #3c4145", marginTop: "0px"}}></hr>
                                        {conditions.length > 0 && conditions.map((el, index) => {
                                            return (
                                                <CreateAndUpdateConditionModal key={index} conditionsFields={conditions}
                                                                               setConditionsFields={setConditions}
                                                                               index={index} type={typeNew}
                                                                               setType={setTypeNew}
                                                                               handleDeleteCondition={handleDeleteCondition}
                                                                               updated={updated}/>
                                            )
                                        })}
                                    </div>
                                    <div className="col-lg-12"
                                         style={{padding: "0px 18px", marginTop: "10px", display: "flex"}}>
                                        <ReactTooltip html={true}/>
                                        <a style={{marginTop: "7px", marginLeft: "5px", cursor: "pointer"}}><img
                                            className="actionable-img"
                                            alt=""/>
                                            {
                                                conditions.length < 3 &&
                                                <i onClick={handleAddCondition} className="fas fa-plus-circle" style={{
                                                    fontSize: "1.5em",
                                                    color: "#7ac231"
                                                }} data-tip={'Add Condition'}/>
                                            }
                                        </a>
                                    </div>

                                </div>
                                <div style={{justifyContent: "flex-end",marginTop:"100px"}}>
                                    <div className="pa-inner" style={{display:"flex",justifyContent:"space-between"}}>
                                        <div style={{display:"flex",justifyContent:"space-between",marginLeft: "10px"}}>
                                            <div style={{color:"white",fontSize:"16px",marginTop: "25px"}}>
                                                Notifications:
                                            </div>
                                            <div style={{display:"flex",marginLeft:"15px"}}>
                                                <div className="spa-text" style={{textAlign:"left", marginTop: "25px",marginRight:"0px",color:"white"}}>Web</div>
                                                <div className="alertSentiment" style={{marginLeft: "10px", marginTop: "15px"}}>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={alert.webpn}
                                                                    onChange={()=>handleNotification("webpn")}
                                                                    color="primary"
                                                                    value="sentiment"
                                                                />
                                                            }
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div style={{display:"flex"}}>
                                                <div className="spa-text" style={{textAlign:"left", marginTop: "25px",marginRight:"0px",color:"white"}}>Email</div>
                                                <div className="alertSentiment" style={{marginLeft: "10px", marginTop: "15px"}}>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={alert.email}
                                                                    onChange={()=>handleNotification("email")}
                                                                    color="primary"
                                                                    value="sentiment"
                                                                />
                                                            }
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </div>
                                        {newType.label === "Create" ?
                                            <div style={{display: "flex", justifyContent: "flex-end"}}>
                                                <div className="green-border-btn">
                                                    <a onClick={close} style={{cursor: "pointer"}}>
                                                        Close
                                                    </a>
                                                </div>
                                                <div style={{marginLeft: "5px"}} className="green-border-btn">
                                                    <a style={{cursor: "pointer"}} onClick={handleCreate}>
                                                        Create Alert
                                                    </a>
                                                </div>
                                            </div>
                                            :
                                            <div style={{display: "flex", justifyContent: "flex-end"}}>
                                                <div className="green-border-btn">
                                                    <a onClick={() => {
                                                        close();
                                                    }} style={{cursor: "pointer"}}>
                                                        Close
                                                    </a>
                                                </div>
                                                <div style={{marginLeft: "5px"}} className="green-border-btn">
                                                    <a onClick={handleUpdate} style={{cursor: "pointer"}}>
                                                        Update Alert
                                                    </a>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};


const prepareInputProps = state => {

    return {
        exchanges: state.meta.exchanges
    };
};

export default connect(prepareInputProps)(CryptoAlertModal);
