import React, {useEffect, useState} from 'react';
import Loading from "../../components/Loading";
import PortfolioApi from "../../api/PortfolioApi";
import Select from "../../components/Forms/Select";
import {setLoading} from "../../actions/generalAction";
import {connect} from "react-redux";
import moment from 'moment';
import {toastr} from "react-redux-toastr";

const defaultFilterParam = {
    portfolio_id: "",
    page_size: 10,
    page_number: 1,
    order_by_field: "trade_ts",
    order_by_direction: "desc",
    account_ids: ""

};

// Selectable exchanges
const types = {
    "BNB":{name:"Binance"},
    "CIS":{name: "Crypto Index Series"}
}

const DocumentsTable = (props) => {
    const {
        setShowITR, // Set show import trade document modal
        setShowETR, // Set show export trade document modal
        meta // Meta data
    } = props;
    // Filter
    const [filter, setFilter] = useState(defaultFilterParam);
    // Get documents for selected account
    const [documentHistory, setDocumentHistory] = useState({data: [], loading: true});
    // Select account for documents
    const [selectedAccounts, setSelectedAccounts] = useState({});
    // User all accounts
    const [dAccounts, setDAccounts] = useState([]);

    // Get all accounts and assign default selected account
    useEffect(() => {
        PortfolioApi.getAccounts().then(resp => {
            setDAccounts(resp);
            if (resp.length > 0) {
                setSelectedAccounts(resp[0]);
            }
        })
    }, []);

    useEffect(() => {
        if (selectedAccounts.id) {
            PortfolioApi.getTradeDocuments(selectedAccounts.id).then(respTR => {
                PortfolioApi.getTransferDocuments(selectedAccounts.id).then(respTS => {
                    const documents = respTR.concat(respTS);
                    setDocumentHistory({data: documents, loading: false});
                })
            })
        }
    }, [selectedAccounts]);

    // Multi select change function
    const onChange = (field, selected) => {
        if (field === "account") {
            setSelectedAccounts(selected);
        }
    };

    // download document
    const downloadDocument = (documentID) =>{
        PortfolioApi.downloadDocument(selectedAccounts.id,documentID).then(resp=>{
            toastr.success('Document successfully downloaded!');
        }).catch(err => {
            toastr.error('Something went wrong while downloading document!');
        });
    }


    return (
        dAccounts && dAccounts.length > 0 ?
            <div>
                <div className="table-head">
                    <div className="table-left">
                        <div className="filter-bar" style={{display: "flex"}}>
                            <div style={{display: "flex"}}>
                                <div style={{fontSize: "14px", color: "#b2b2b2", paddingRight: "5px"}}>
                                    Accounts:
                                </div>
                                <div className="sm-select-input">
                                    <Select
                                        selected={selectedAccounts.id || 0}
                                        optionValue={'id'}
                                        optionLabel={'name'}
                                        options={dAccounts || []}
                                        onChange={selected => onChange("account", selected)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="clearfix"/>
                </div>
                <div className="table-body table-responsive" id="beauty-scroll7" style={{marginTop: "15px"}}>
                    <Loading loading={documentHistory.loading}>
                        <table className="coins enumarable-table coins-menu-table linked-table">
                            <thead>
                            <tr>
                                <th>Request Time</th>
                                <th>Source</th>
                                <th>Document Type</th>
                                <th>Task</th>
                                <th>File Type</th>
                                <th>Status</th>
                                <th style={{textAlign:"center"}}>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                documentHistory.data.length > 0 ?
                                    documentHistory.data.map(el => {
                                        return (
                                            <tr style={{cursor:"auto"}} key={el.id}>
                                                <td>{moment(el.requested_on).format('DD.MM.YYYY HH:mm:ss') || "-"}</td>
                                                <td>{types[el.source_code].name+" ("+el.source_code+")" || "-"}</td>
                                                <td>{el.document_type}</td>
                                                <td style={el.task_type === "EXPORT" ? {color: "#c11e0f"} : {color: "#7ac231"}}>{el.task_type}</td>
                                                <td>{el.file_type}</td>
                                                {el.handled_on ?
                                                    <React.Fragment>
                                                        <td style={{color: "#7ac231"}}>READY</td>
                                                        <td onClick={()=>downloadDocument(el.id)} style={{cursor:"pointer",textAlign:"center"}}><i className="fas fa-download"></i></td>
                                                    </React.Fragment>
                                                    :
                                                    <td style={{color: "#16a5c5"}}>PROCESS</td>
                                                }

                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan={7}>
                                            There is no document!
                                        </td>
                                    </tr>
                            }

                            </tbody>
                        </table>
                    </Loading>
                </div>
            </div>
            :
            <div>
                There is no account!
            </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setFullLoading: data => dispatch(setLoading(data)),
    };
};

const mapStateToProps = state => {
    return {
        meta: state.meta,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsTable);





