import React from 'react';

import './styles.css';

const Launcher = () => (
  <div className="launcher-body">
    <div className="logo-container">
      <div className="logo-text">
        <h4>Loading ...</h4>
      </div>
    </div>
  </div>
);

export default Launcher;
