import React, {useEffect, useState} from 'react';
import Select from '../../Forms/Select';
import {toastr} from 'react-redux-toastr';
import {useAuth0} from "../../../helpers/Auth0";
import UserApi from "../../../api/UserApi";

const SetWatchListModal = props => {
  const {isNew, isCreate, modalShow, closeModal, add, refreshWatchlists} = props;
  const {user} = useAuth0();
  const [watch, setWatch] = useState({});
  const [name, setName] = useState('');
  const [plus, setPlus] = useState(false);
  const [watchlists, setWatchlist] = useState([]);

  const fetchWatchLists = () => {
    UserApi.getWatchlists().then(res => {
      setWatch(res.some(el => el.is_favorite));
      setWatchlist(res)
      refreshWatchlists && refreshWatchlists(res);
    }).catch(ex => {
      setWatch({});
      setWatchlist([]);
    });
  }

  useEffect(() => {
    if (!isCreate) {
      fetchWatchLists();
    }
  }, [isCreate]);

  useEffect(() => {
    if (isNew && !add) setName(isNew.name);
  }, [isNew]);

  const createOrUpdate = () => {
    if (add && !plus) {
      pairCreate()
    } else {
      if (isNew) {
        UserApi.updateWatchlist(isNew.watchlist_id, name).then(res => {
          closeModal()
          toastr.success('', 'Successfully Updated');
          setName('');
          fetchWatchLists();
        }).catch(exp => {
          toastr.error('', 'Name already exists...');
        })
      } else {
        UserApi.createWatchlist(name).then(res => {
          closeModal();
          toastr.success('', 'Successfully Saved');
          setName('');
          fetchWatchLists();
        }).catch(exp => {
          console.log(exp.response.data.error);
          if (exp.response.data.error && exp.response.data.error === "already_exists") {
            toastr.error('', 'Name already exists...');
          } else if (exp.response.data.error && exp.response.data.error === "wt_limit_exceeded") {
            toastr.error('', 'You can have at most 3 watch lists...')
          } else {
            toastr.error('Unknown error...')
          }

        });
      }
    }
  };

  const pairCreate = () => {
    UserApi.createPair(watch.watchlist_id, add.from, add.to).then(res => {
      closeModal();
      toastr.success('', 'Successfully Saved');
      setName('');
    }).catch(err => {
      toastr.error('', 'Already in the list');
    });
  };

  const onSave = () => {
    if (!add || plus) {
      if (!name.length) toastr.error('', 'Name Required');
      else {
        createOrUpdate();
      }
    } else {
      if (!watch.watchlist_id) toastr.error('', 'WatchList required');
      else {
        pairCreate();
      }
    }
  };

  return (
    <div className={`general-modal ${modalShow === true ? 'active' : ''}`} id="set-watch-list">
      <div className="vAlignWr">
        <div className="vAlign">
          <div className="white-modal-inner set-alert">
            <div className="modal-blue-header">
              Watch Lists
              <div
                className="close-modal"
                data-target={'set-watch-list'}
                onClick={() => {
                  closeModal();
                }}>
                <img src="img/close-white.svg" alt="" />
              </div>
            </div>
            <div className="modal-white-body">
              <div className="general-tab-area">
                <div className="general-tab-menu">
                  <ul>
                    <li className="active">
                      <a href="#" data-target="price-alert">
                        {!isNew && !add ? 'Create' : isNew && !add ? 'Edit' : ''} Watch List
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="general-tab-body-all">
                  <div className="general-tab-body active" id="price-alert">
                    <div className="set-price-alert">
                      <div className="calc-price-number">
                        {(!add || plus) && (
                          <div className="input-table" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="spa-text">Watch List Name</div>
                                  </td>
                                  <td>
                                    <div className="bottom-border-input">
                                      <input type="text" value={name} maxLength={50} onChange={val => setName(val.target.value)} />
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            {add && (
                              <span onClick={() => setPlus(false)} className="sublink-area">
                                <a>
                                  <i className="fas fa-minus-circle" />
                                  {/* <Image className="actionable-img" src="minus.svg" alt="" /> */}
                                </a>
                              </span>
                            )}
                          </div>
                        )}
                        {add && !plus && (
                          <div className="input-table" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <table style={{width: '100%'}}>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="sm-select-input" style={{width: '200px'}}>
                                      <Select
                                        selected={ (watch && watch.watchlist_id) || 0}
                                        options={watchlists}
                                        optionValue={'watchlist_id'}
                                        optionLabel={'name'}
                                        onChange={selected => setWatch(selected)}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <span onClick={() => setPlus(true)} className="sublink-area">
                              <a>
                                <i className="fas fa-plus-circle" />

                                {/* <Image className="actionable-img" src="plus.svg" alt="" /> */}
                              </a>
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-gray-footer">
              <div className="form-buttons">
                <div className="form-flex">
                  <div className="form-flex-inner">
                    <div className="light-gray-btn">
                      <button
                        className="close-modal"
                        data-target="set-watch-list"
                        onClick={() => {
                          closeModal();
                        }}>
                        CANCEL
                      </button>
                    </div>
                  </div>
                  <div className="form-flex-inner">
                    <div className="light-blue-btn">
                      <button id="crop-image" onClick={onSave}>
                        SAVE
                      </button>
                    </div>
                  </div>
                </div>
                <div className="clearfix" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetWatchListModal;
