import React from "react";
import RealtimeUtils from "../../helpers/RealtimeUtils";
import CISNavigator from "../../helpers/CISNavigator";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import AppHelpers from "../../helpers/AppHelpers";


// Represents per row in spot search table or row in markets tab
const SymbolRow = (props) => {
    const {
        row, // Row base information
        setChannel, // Subscribe to real time events
        assetPrices,
        type,
        rowClicked,
        pair,
        history,
        ccy,
        currencyList,
        connectableExc,
        setSelectedPair,
        selectedPair
    } = props;


    // Handles real time updates on row
    const [tickerData, setTickerData] = RealtimeUtils.useRealTimeRow(row.cis_symbol, setChannel, row && row.ticker);

    // useEffect(() => {
    //     if(row && assetPrices){
    //         // Initialize ticker data with coin row
    //         setTickerData(row.ticker);
    //     }
    // }, [row,assetPrices]);

    const pairSelected = (row) =>{
        setSelectedPair(row);
    }

    return (
        <React.Fragment>
            {
                type === "all" &&
                <tr key={row.cis_symbol} style={{cursor:"auto"}}>
                    <td style={{textAlign: 'left'}}>
                        <div className="text">{row.base_asset}/{row.quoted_asset}</div>
                    </td>
                    <td style={{textAlign: 'left'}}>
                        <div className="text">{row.source_name}</div>
                    </td>
                    <td className={`text ${tickerData.price_update_class}`} style={{textAlign: 'left'}}>
                        {tickerData.price}
                    </td>
                    <td className={`text ${tickerData.price_update_class}`} style={{textAlign: 'left'}}>
                        {assetPrices[row.quoted_asset] ? AppHelpers.priceFormat(parseFloat(tickerData.price && tickerData.price.replace(",","")) * assetPrices[row.quoted_asset]) : "-"}
                    </td>
                    <td className={`text ${tickerData.chngpct_class}`} style={{textAlign: 'left'}}>
                        {tickerData.chngpct}
                    </td>
                    <td style={{textAlign: 'left'}}>
                        <div className="text">{tickerData.v}</div>
                    </td>
                    <td style={{textAlign: 'left'}}>
                        <div className="text">{tickerData.vol24}</div>
                    </td>
                    {/*<td style={{textAlign: 'left'}}>*/}
                    {/*    -*/}
                    {/*</td>*/}
                    <td style={{textAlign: 'left'}}>
                        <div className="text">{tickerData.vwap ? tickerData.vwap  : "-"}</div>
                    </td>
                    <td style={{textAlign: 'left'}}>
                        <div className="text">{(tickerData.low && tickerData.high) ? tickerData.low+" - "+tickerData.high  : "-"}</div>
                    </td>
                    <td style={{textAlign: 'left'}}>
                        <div className="text">{(tickerData.n) ? tickerData.n  : "-"}</div>
                    </td>
                    <td style={{textAlign: "center"}}>
                        <div className="three-dot-menu">
                            <div className="dot-menu-icon">
                                <img className="actionable-img" src="/img/three-dot.svg" alt="" />
                            </div>
                            <div className="table-dropdown-menu">
                                <ul>
                                    <li>
                                        <a
                                            className=" open-modal-event"
                                            data-target="set-alert"
                                            onClick={() => CISNavigator.navigateToCoinDetail(history, {symbol:row.base_asset},{type:ccy, value:ccy})}
                                        >
                                            View {row.base_asset}

                                        </a>
                                    </li>
                                    {
                                        !currencyList[row.quoted_asset] &&
                                        <li>
                                            <a
                                                className=" open-modal-event"
                                                data-target="set-alert"
                                                onClick={() => CISNavigator.navigateToCoinDetail(history, {symbol:row.quoted_asset},{type:ccy, value:ccy})}
                                            >
                                                View {row.quoted_asset}
                                            </a>
                                        </li>
                                    }
                                    {
                                        connectableExc[row.source_code] &&
                                        <li>
                                            <a
                                                className=" open-modal-event"
                                                data-target="set-alert"
                                                onClick={()=>{
                                                    history.push({
                                                        pathname: '/trade',
                                                        state: {from: row.base_asset, to: row.quoted_asset, exchange:row.source_code}
                                                    })
                                                }}
                                            >
                                                Trade
                                            </a>
                                        </li>
                                    }

                                </ul>
                            </div>
                        </div>
                    </td>
                </tr>
            }
            {
                (type === "byPair") &&
                <tr key={row.cis_symbol}>
                    <td style={{textAlign: 'left'}}>
                        <div className="text">{row.base_asset}/{row.quoted_asset}</div>
                    </td>
                    <td style={{textAlign: 'left'}}>
                        <div className="text">{row.source_name}</div>
                    </td>
                    <td className={`text ${tickerData.price_update_class}`} style={{textAlign: 'left'}}>
                        {tickerData.price}
                    </td>
                    <td className={`text ${tickerData.price_update_class}`} style={{textAlign: 'left'}}>
                        {assetPrices[row.quoted_asset] ? (parseFloat(tickerData.price && tickerData.price.replace(",","")) * assetPrices[row.quoted_asset]).toFixed(2) : "-"}
                    </td>
                    <td className={`text ${tickerData.chngpct_class}`} style={{textAlign: 'left'}}>
                        {tickerData.chngpct}
                    </td>
                    <td style={{textAlign: 'left'}}>
                        <div className="text">{tickerData.v}</div>
                    </td>
                    <td style={{textAlign: 'left'}}>
                        <div className="text">{tickerData.vol24}</div>
                    </td>
                    <td style={{textAlign: 'left'}}>
                        -
                    </td>
                    <td style={{textAlign: 'left'}}>
                        -
                    </td>
                    <td style={{textAlign: 'left'}}>
                        -
                    </td>
                    <td style={{textAlign: 'left'}}>
                        -
                    </td>
                    <td style={{textAlign: "center"}}>
                        <div className="three-dot-menu">
                            <div className="dot-menu-icon">
                                <img className="actionable-img" src="/img/three-dot.svg" alt="" />
                            </div>
                            <div className="table-dropdown-menu">
                                <ul>
                                    <li>
                                        <a
                                            className=" open-modal-event"
                                            data-target="set-watch-list">
                                            View Chart
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className=" open-modal-event"
                                            data-target="set-alert">
                                            View {row.base_asset}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className=" open-modal-event"
                                            data-target="set-alert">
                                            View {row.quoted_asset}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className=" open-modal-event"
                                            data-target="set-alert">
                                            Trade
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </td>
                </tr>
            }
            {
                (type === "byExchange") &&
                <tr key={row.cis_symbol}>
                    <td style={{textAlign: 'left'}}>
                        <div className="text">{row.base_asset}/{row.quoted_asset}</div>
                    </td>
                    <td style={{textAlign: 'left'}}>
                        <div className="text">{row.source_name}</div>
                    </td>
                    <td className={`text ${tickerData.price_update_class}`} style={{textAlign: 'left'}}>
                        {tickerData.price}
                    </td>
                    <td className={`text ${tickerData.price_update_class}`} style={{textAlign: 'left'}}>
                        {assetPrices[row.quoted_asset] ? (parseFloat(tickerData.price && tickerData.price.replace(",","")) * assetPrices[row.quoted_asset]).toFixed(2) : "-"}
                    </td>
                    <td className={`text ${tickerData.chngpct_class}`} style={{textAlign: 'left'}}>
                        {tickerData.chngpct}
                    </td>
                    <td style={{textAlign: 'left'}}>
                        <div className="text">{tickerData.v}</div>
                    </td>
                    <td style={{textAlign: 'left'}}>
                        <div className="text">{tickerData.vol24}</div>
                    </td>
                    <td style={{textAlign: 'left'}}>
                        -
                    </td>
                    <td style={{textAlign: 'left'}}>
                        -
                    </td>
                    <td style={{textAlign: 'left'}}>
                        -
                    </td>
                    <td style={{textAlign: 'left'}}>
                        -
                    </td>
                    <td style={{textAlign: "center"}}>
                        <div className="three-dot-menu">
                            <div className="dot-menu-icon">
                                <img className="actionable-img" src="/img/three-dot.svg" alt="" />
                            </div>
                            <div className="table-dropdown-menu">
                                <ul>
                                    <li>
                                        <a
                                            className=" open-modal-event"
                                            data-target="set-watch-list">
                                            View Chart
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className=" open-modal-event"
                                            data-target="set-alert">
                                            View {row.base_asset}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className=" open-modal-event"
                                            data-target="set-alert">
                                            View {row.quoted_asset}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className=" open-modal-event"
                                            data-target="set-alert">
                                            Trade
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </td>
                </tr>
            }
            {
                (type === "parent") &&

                <tr onClick={e => {e.stopPropagation(); rowClicked(pair)}}>
                    <td style={{textAlign: "center", width: "57px"}}><img style={{margin: "1em"}} className={"actionable-img"} src={row.is_open ? "/img/arrow-down.svg": "/img/arrow-right.svg"}/></td>
                    <td style={{textAlign: 'left'}}>
                        <div className="text">{pair}</div>
                    </td>
                    <td style={{textAlign: 'left'}}>
                        <div className="text">-</div>
                    </td>
                    <td className={`text ${tickerData.price_update_class}`} style={{textAlign: 'left'}}>
                        -
                    </td>
                    <td className={`text ${tickerData.price_update_class}`} style={{textAlign: 'left'}}>
                        -
                    </td>
                    <td className={`text ${tickerData.chngpct_class}`} style={{textAlign: 'left'}}>
                        -
                    </td>
                    <td style={{textAlign: 'left'}}>
                        -
                    </td>
                    <td style={{textAlign: 'left'}}>
                        -
                    </td>
                    <td style={{textAlign: 'left'}}>
                        -
                    </td>
                    <td style={{textAlign: 'left'}}>
                        -
                    </td>
                    <td style={{textAlign: 'left'}}>
                        -
                    </td>
                    <td style={{textAlign: 'left'}}>
                        -
                    </td>
                    <td style={{textAlign: "center"}}>
                        <div className="three-dot-menu">
                            <div className="dot-menu-icon">
                                <img className="actionable-img" src="/img/three-dot.svg" alt="" />
                            </div>
                            <div className="table-dropdown-menu">
                                <ul>
                                    <li>
                                        <a
                                            className=" open-modal-event"
                                            data-target="set-watch-list">
                                            View Chart
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className=" open-modal-event"
                                            data-target="set-alert">
                                            View {row.base_asset}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className=" open-modal-event"
                                            data-target="set-alert">
                                            View {row.quoted_asset}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className=" open-modal-event"
                                            data-target="set-alert">
                                            Trade
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </td>
                </tr>
            }
            {
                (type === "small") &&

                <tr key={row.cis_symbol} onClick={()=>pairSelected(row)} style={(row.cis_symbol === selectedPair.cis_symbol) ? {border:"2px solid green"}:{}}>
                    <td style={{textAlign: 'left'}}>
                        <div className="text">{row.base_asset}/{row.quoted_asset}</div>
                    </td>
                    <td style={{textAlign: 'left'}}>
                        <div className="text">{row.source_name}</div>
                    </td>
                    <td className={`text ${tickerData.price_update_class}`} style={{textAlign: 'left'}}>
                        {tickerData.price}
                    </td>
                    <td className={`text ${tickerData.chngpct_class}`} style={{textAlign: 'left'}}>
                        {tickerData.chngpct}
                    </td>
                </tr>
            }
        </React.Fragment>



    );
}

const mapStateToProps = state => {
    // Lists to be used
    const currencyList = {};
    const connectableExc = {};
    (state.meta.lists &&
        state.meta.lists.key_currency_list &&
        state.meta.lists.key_currency_list.forEach(c => {
                if (c[2] === "FIAT") {
                    currencyList[c[0]] = 1;
                }
            }));
    (state.meta.exchanges &&
        state.meta.exchanges &&
        state.meta.exchanges.forEach(c => {
            if (c.is_connectable) {
                connectableExc[c.code] = 1;
            }
        }));

    return {
        meta: state.meta,
        exchanges: state.meta.exchanges,
        currencyList: currencyList,
        connectableExc : connectableExc,
    };
};

export default connect(mapStateToProps)(withRouter(SymbolRow));
