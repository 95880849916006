export const translations = {
  en: {
    header: 'Title',
    captDesc: 'When calculating Market Capitalization (market cap),<br/>' +
      'we account for all coins in circulation,<br/>' +
      'including those held by team members or the company.<br/>' +
      'This also includes coins in smart contracts or escrow.<br/>' +
      ' If the coins have been issued and have not been burned,<br/>' +
      'they will be accounted for in the market cap',
    totalVDesc: 'This is the total volume of the coin across all markets and currency pairs multiplied by the current price.',
  },
};
