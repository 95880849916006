import React from 'react';

const SmallTrRow = props => {
  const {
    chgpct,
    price,
    marketCap,
    totalCoin,
    avalCoin,
    vol24,
    perClass,
    perTd,
    symbol,
    rowImage,
    name,
    exchange,
    rowClass,
    high24,
    low24,
    rowClick,
    row,
    weight,
  } = props;
  return (
    <tr className="top-indices-hometable" style={{cursor: 'pointer'}} onClick={rowClick}>
      <td style={{textAlign: 'left'}}>
        <div className="custom-icon-img">
          <img width="15" src={rowImage} alt="" />
        </div>
        {name}
      </td>
      <td style={{"textAlign" : "left"}} className={rowClass}>{price}</td>
      <td style={{"textAlign" : "left"}} className={perClass}>{perTd}</td>
      {weight && <td style={{"textAlign" : "left"}}>{weight}</td>}
    </tr>
  );
};

export default SmallTrRow;
