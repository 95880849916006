import React from 'react';
import {useAuth0} from "../../helpers/Auth0";


const NotAuthorizedPage = props => {
    const {logout} = useAuth0();
    return (
        <section style={{textAlign: 'center', minHeight: "100%", backgroundImage: "url('/img/blur_upgrade_your_plan.png')"}} className="contact-area padding-sm-container">
            <div className="general-header"><h2></h2></div>
            <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', color: '#fff'}} className="contact-form-and-banners">
                <h4>Your account is expired.</h4>
                <h3>
                    <a style={{cursor: "pointer", marginRight: "1em"}} onClick={() => logout()}>Logout</a>
                    <a target="_blank" href="https://account.cryptoindexseries.com/Subscriptions?show_upgrades=true&plan_group=CIS%20Trader%20Application%20Plans">Upgrade</a>
                </h3>
            </div>
        </section>
    );
};

export default NotAuthorizedPage;
