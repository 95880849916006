import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Breadcrump from '../../components/Breadcrump';
import {withRouter} from 'react-router-dom';
import {useAuth0, checkQuotaAccess} from "../../helpers/Auth0";
import Breadcrumps from "../../constants/Breadcrumps";
import Watchlist from "../../components/PageDashboard/Watchlist/Watchlist";
import Loading from "../../components/Loading";
import Select from "react-select";
import ArbitrageGrid from "../../components/ArbitrageGrid/ArbitrageGrid";
import Api from "../../api";
import DataMappers from "../../helpers/DataMappers";
import ArbitrageAppBS from "./ArbitrageAppBS";
import ToggleButton from "react-toggle-button";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";


const ArbitrageApp = props => {
    const {meta} = props;
    const {user} = useAuth0();
    const [watchListShow, setWatchListShow] = useState(user.preferences.show_watchlist);

    // Possible filters
    const [baseAssets, setBaseAssets] = useState([]);
    const [quotedAssets, setQuotedAssets] = useState([]);

    // Mapped Pairs
    const [mappedPairs,setMappedPairs] = useState({bases:{},quotes:{}});

    // Set arbitrage type
    const [arbitrage,setArbitrage] = useState(false);

    // BS only latest checkbox
    const [onlyBS,setOnlyBS] = useState(true);
    // SB only latest checkbox
    const [onlySB,setOnlySB] = useState(true);


    useEffect(() => {
        const params = {
            is_exchange: true,
        };

        Api.spotSearch(params, 100).then(async res => {
            res.data = res.data || [];

            let mappedBase = {};
            let mappedQuote = {};

            const mappedBaseAssets = res.base_assets.map(el=>{
                return {value: el,label: el};
            });

            const mappedQuoteAssets = res.quoted_assets.map(el=>{
                return {value: el,label: el};
            });

            setBaseAssets(mappedBaseAssets);

            setQuotedAssets(mappedQuoteAssets);

            res.pairs.forEach(el=>{
                if(mappedBase[el.split("/")[0]]){
                    mappedBase[el.split("/")[0]].push(el.split("/")[1]);
                }else{
                    mappedBase[el.split("/")[0]]=[];
                    mappedBase[el.split("/")[0]].push(el.split("/")[1]);
                }
                if(mappedQuote[el.split("/")[1]]){
                    mappedQuote[el.split("/")[1]].push(el.split("/")[0]);
                }else{
                    mappedQuote[el.split("/")[1]]=[];
                    mappedQuote[el.split("/")[1]].push(el.split("/")[0]);
                }
            });

            setMappedPairs({bases:mappedBase,quotes:mappedQuote})

        });
    }, []);

    const onChangePrefs = (val) => {
        setArbitrage(val);
    }


    return (
        <React.Fragment>
            <section className="dashboard padding-sm-container">
                <div className="watchlist-all-page">
                    {watchListShow ? <Watchlist/> : <></>}
                </div>
                <Breadcrump links={Breadcrumps.arbitrage}/>

                <div className={`gray-container`} style={{borderTopLeftRadius:"0px",borderTopRightRadius:"0px",marginTop:"10px"}}>
                    <Loading loading={false}>
                        <div className="row">
                            <div className="col-lg-12 col-md-12" style={{marginTop:"15px"}}>

                                <div style={{paddingTop: "10px", marginLeft: "5px", display:"flex"}}>
                                    <div style={{color:"white", fontSize:"17px",bottom:"0"}}>{"ARBITRAGE"}</div>
                                </div>
                                <div style={{paddingTop: "10px", marginLeft: "5px", display:"flex"}}>
                                    <div style={{color:"white", fontSize:"14px",bottom:"0"}}>
                                        BUY / SELL
                                    </div>
                                    <div style={{marginLeft:"5px",marginRight:"5px"}}>
                                        <ToggleButton
                                            inactiveLabel={''}
                                            activeLabel={''}
                                            value={arbitrage}
                                            colors={{
                                                activeThumb: {
                                                    base: 'rgb(250,250,250)',
                                                },
                                                inactiveThumb: {
                                                    base: 'rgb(250,250,250)',
                                                },
                                                active: {
                                                    base: 'rgb(62,130,247)',
                                                    hover: 'rgb(62,130,247)',
                                                },
                                                inactive: {
                                                    base: 'rgb(62,130,247)',
                                                    hover: 'rgb(62,130,247)',
                                                }
                                            }}
                                            onToggle={(value) => {
                                                onChangePrefs(!value);
                                            }} />
                                    </div>
                                    <div style={{color:"white", fontSize:"14px",bottom:"0"}}>
                                        SELL / BUY
                                    </div>
                                </div>
                                <React.Fragment>
                                    {
                                        !arbitrage ?
                                            <ArbitrageAppBS baseAssets={baseAssets} quotedAssets={quotedAssets} pairs={mappedPairs} type={"BS"} onlyVisible={onlyBS} setOnlyVisible={setOnlyBS}/>
                                            :
                                            <ArbitrageAppBS baseAssets={baseAssets} quotedAssets={quotedAssets} pairs={mappedPairs} type={"SB"} onlyVisible={onlyBS} setOnlyVisible={setOnlyBS}/>
                                    }
                                </React.Fragment>
                            </div>
                        </div>
                    </Loading>
                </div>

            </section>
        </React.Fragment>
    );
};


const prepareInputProps = state => {
    return {
        meta: state.meta,
        exchanges: state.meta.exchanges
    };
};

export default connect(prepareInputProps)(withRouter(ArbitrageApp));
