import React from 'react';
import Utils from "../../helpers/Utils";

const isMobile = Utils.checkMobile();

const IndicesRow = props => {
  const {
    chgpct,
    price,
    marketCap,
    totalCoin,
    avalCoin,
    vol24,
    perClass,
    perTd,
    symbol,
    rowImage,
    name,
    exchange,
    rowClass,
    high24,
    low24,
    rowClick,
    row,
    weight,
  } = props;

    function parseIndiceName(){

        if(row.index_name){
            return row.index_name.replace("Crypto Index Series","CIS");
        }
    }
  
  return (
      isMobile?
      <tr style={{cursor: 'pointer'}}>
          <td>{parseIndiceName()}</td>
          <td className={perClass}>{perTd}</td>
      </tr>

          :
    <tr style={{cursor: 'pointer'}} onClick={rowClick}>
      <td>{row.index_code}</td>
      <td>{row.index_name}</td>
      <td className={rowClass}>{price}</td>
      <td>{low24}</td>
      <td>{high24}</td>
      <td>{marketCap}</td>
      <td className={perClass}>{perTd}</td>
    </tr>
  );
};

export default IndicesRow;
