import React, {useEffect, useState} from "react";
import RealtimeUtils from "../../helpers/RealtimeUtils";
import DataMappers from "../../helpers/DataMappers";
import Api from "../../api";
import Loading from "../Loading";
import Paginate from "../Paginate";
import Select from "../Forms/Select";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Utils from "../../helpers/Utils";

const isMobile = Utils.checkMobile();

// Represents per row in spot search table or row in markets tab
const SymbolRow = (props) => {
    const {
        row, // Row base information
        setSelectedSpot, // Triggers selection of row
        selected, // Indicates if this is the selected row
        setChannel // Subscribe to real time events
    } = props;

    // Handles real time updates on row
    const [tickerData, setTickerData] = RealtimeUtils.useRealTimeRow(row.cis_symbol, setChannel,row && row.ticker);

    // useEffect(() => {
    //     // Initialize ticker data with coin row
    //     setTickerData(row.ticker);
    // }, [row]);

    return (
        isMobile?

            <tr key={row.cis_symbol} style={{outline: selected ? "thin solid green" : ""}} onClick={() => {
                setSelectedSpot(row)
            }}>
                <td style={{textAlign: 'left'}}>
                    <div className="text">{row.exchange_name}</div>
                </td>

                <td style={{textAlign: 'left'}}>
                    <div className="text">{row.quoted_asset}</div>
                </td>
                <td className={`text ${tickerData.chngpct_class}`} style={{textAlign: 'left'}}>
                    {tickerData.chngpct}
                </td>
                <td style={{textAlign: 'left'}}>
                    <div className="text">{row.base_asset}/{row.quoted_asset}</div>
                </td>
                <td style={{textAlign: 'left'}}>
                    <div className="text">{tickerData.v}</div>
                </td>
                <td style={{textAlign: 'left'}}>
                    <div className="text">{tickerData.vol24}</div>
                </td>
                <td className={`text ${tickerData.price_update_class}`} style={{textAlign: 'left'}}>
                    {tickerData.price}
                </td>
            </tr>
            :
            <tr key={row.cis_symbol} style={{outline: selected ? "thin solid green" : ""}} onClick={() => {
                setSelectedSpot(row)
            }}>
                <td style={{textAlign: 'left'}}>
                    <div className="text">{row.exchange_name}</div>
                </td>
                <td style={{textAlign: 'left'}}>
                    <div className="text">{row.base_asset}/{row.quoted_asset}</div>
                </td>
                <td style={{textAlign: 'left'}}>
                    <div className="text">{row.quoted_asset}</div>
                </td>
                <td className={`text ${tickerData.price_update_class}`} style={{textAlign: 'left'}}>
                    {tickerData.price}
                </td>
                <td className={`text ${tickerData.chngpct_class}`} style={{textAlign: 'left'}}>
                    {tickerData.chngpct}
                </td>
                <td style={{textAlign: 'left'}}>
                    <div className="text">{tickerData.vol24}</div>
                </td>
                <td style={{textAlign: 'left'}}>
                    <div className="text">{tickerData.v}</div>
                </td>
            </tr>


    );
}

const MarketsTable = (props) => {
    const {
        coinRow,
        exchanges, // Exchange information metadata
        is_exchange, // Is exchange parameter
        selectedTicker, // Ticker selection manipulation
        setSelectedTicker,
    } = props;

    // Search fields
    const [quotedAsset, setQuotedAsset] = useState(null);
    const [exchange, setExchange] = useState( null);
    const [pair, setPair] = useState(null);

    // Possible filters
    const [quotedAssets, setQuotedAssets] = useState([]);
    const [exchangeSearches, setExchangeSearches] = useState([]);
    const [pairs, setPairs] = useState([]);

    // State variables
    const [loading, setLoading] = useState(false);
    const [spots, setSpots] = useState([]);

    // Table related information variables
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [sort, setSort] = useState({i: 'cis_symbol', s: 'asc'});


    // Real time listeners
    const disabledColumns = ["market_cap", "high", "low"];
    const [tickerList, setChannel, initSocketConnection] = RealtimeUtils.useRealTimeTicker(disabledColumns, is_exchange ? DataMappers.spotTickerMapper : DataMappers.coinTickerMapper);

    // Arrange spots so selected spot at top and exchange info is initiated
    const arrangeSpots = (inSpots, returnSpots) => {
        // Fill missing spot search values
        inSpots = inSpots.map(el => {
            // Add exchange name and ID
            const exc = exchanges[el.source_code];
            if (exc) {
                el.exchange_name = exc.name;
                el.exchange_id = exc.id;

            } else { // In case exchange not defined (Not likely when we get it from API)
                el.exchange_name = el.source_code;
            }
            return el;
        });

        // Return spots rather than updating (This one is used by updates to current spot list)
        if (returnSpots)
            return inSpots;

        // Initiate real time updates
        initSocketConnection(inSpots.map(el => el.cis_symbol));

        setSpots(inSpots);
    }

    // Called when one of the search parameters are changed
    const searchSpots = () => {
        // Generate search params
        const params = {
            page_number: currentPage,
            order_by_field: sort.i,
            base_asset: coinRow.symbol,
            order_by_direction: sort.s,
            is_exchange: is_exchange
        };

        // Only Calculated Aggregates
        if(!is_exchange){
            params.source_code = "CISCALC"
        }


        // Arrange parameters
        if (quotedAsset && quotedAsset.value)
            params.quoted_asset = quotedAsset.value;
        if (exchange && exchange.code)
            params.source_code = exchange.code;
        if (pair && pair.value) { // Overrides other params
            params.base_asset = pair.value.split("/")[0];
            params.quoted_asset = pair.value.split("/")[1];
        }

        // Perform search
        setLoading(true);
        Api.spotSearch(params, 50).then(async res => {
            res.data = res.data || [];
            res.data.forEach(el => el.ticker= el.ticker || {});
            // Generate readable tickers
            res.data.forEach(el => {
                el.org_ticker = el.ticker;
                el.ticker = is_exchange ? DataMappers.spotTickerMapper(el.ticker) : DataMappers.coinTickerMapper(el.ticker);
            });

            // Puts selected spot at top if any
            arrangeSpots(res.data);

            // Check if previous spots contain selected ticker
            const isPrev = selectedTicker && spots.some(el => el.cis_symbol === selectedTicker.cis_symbol);
            // Is new spots does not contain selected ticker
            const isNotNext = selectedTicker && !res.data.some(el => el.cis_symbol === selectedTicker.cis_symbol);

            // If prev contains selected ticker but new list does not set selected ticker null
            if (isPrev && isNotNext) {
                setSelectedTicker(null);
            }

            // Set possible filter fields
            const qa = res.quoted_assets || [];
            setQuotedAssets(qa.map(el => { return {symbol: el, value: el}}));
            const p = res.pairs || [];
            setPairs(p.map(el => { return {symbol: el, value: el}}));
            const exc = res.exchanges || [];
            setExchangeSearches(exc.map(el => {
                return exchanges[el] || {id: el, code: el, name: el};
            }));

            setLoading(false);
            setTotalPage(res.totalPage);
        });
    }

    // Checks if spot is the selected spot
    const isSelected = (element) => {
        return selectedTicker && selectedTicker.cis_symbol === element.cis_symbol;
    }


    useEffect(() => {
        // Clear page selection before search
        if(quotedAsset || exchange || pair){
            if(currentPage !== 1){
                setCurrentPage(1);
            }else{
                searchSpots();
            }
        }
    }, [quotedAsset, exchange, pair])

    useEffect(() => {
        if(coinRow.symbol){
            // Search on parameter changes
            searchSpots();
        }
    }, [currentPage, sort,coinRow.symbol]);

    // Put selected spot at top
    useEffect(() => {
        setSpots(currentSpots => arrangeSpots(currentSpots, true));
    }, [coinRow.symbol]);

    // Handles pager clicks
    const handlePageClick = d => {
        if (currentPage !== d.selected + 1) {
            setCurrentPage(d.selected + 1);
        }
    };

    // Get table row for given data
    const getTableRow = (el) =>
        <SymbolRow row={el} setSelectedSpot={setSelectedTicker} selected={isSelected(el)} setChannel={setChannel} disabledColumns={disabledColumns} />

    // Get spots data for table
    const getSpotsData = () => {
        if (spots.length === 0)
            return  <tr><td colSpan={7}><div style={{color: "white", margin: "1em", fontSize: "14px"}}>No pairs listed in CIS markets</div></td></tr>

        return loading ? <tr><td colSpan={7}><Loading loading={loading}/></td></tr> : spots.map(getTableRow);
    }

    // Sort related variables
    const sortChange = index => sort && (sort.s === 'desc' ? {i: index, s: 'asc'} : {i: index, s: 'desc'});
    const sortArrow = index => sort && sort.i === index && <i style={{fontSize: 12}} className={`fa fa-angle-${sort.s === 'desc' ? 'up' : 'down'}`} />;

    return (
        isMobile?
            <div className="gray-container" style={{display: "flex", flexDirection: "column", marginTop: !is_exchange ? "10px" : "0px", height: "100%", paddingBottom: "0px"}}>
                <div className="section-header"style={{marginBottom: "1em"}}>{is_exchange ? 'Exchanges' : 'Calculated / Aggregates'}</div>
                <div className="table-body table-responsive" id="beauty-scroll7">
                    <table className="coins enumarable-table news-table-responsive linked-table">
                        <thead>
                        <tr>
                            {/*1*/}
                            <th style={{padding: "7px", cursor: 'pointer'}} onClick={() => setSort(sortChange('source_code'))}>Exchange {sortArrow('source_code')}</th>
                            <th style={{padding: "7px", cursor: 'pointer'}} onClick={() => setSort(sortChange('chgpct'))}>24h Change% {sortArrow('chgpct')}</th>
                            <th style={{padding: "7px", cursor: 'pointer'}} onClick={() => setSort(sortChange('quoted_asset'))}>Quote {sortArrow('quoted_asset')}</th>
                            {/*2*/}
                            <th style={{padding: "7px", cursor: 'pointer'}} onClick={() => setSort(sortChange('cis_symbol'))}>Pair {sortArrow('cis_symbol')}</th>
                            <th style={{padding: "7px", cursor: 'pointer'}} onClick={() => setSort(sortChange('vto'))}>24h Vol To {sortArrow('vto')}</th>
                            {/*3*/}
                            <th style={{padding: "7px", cursor: 'pointer'}} onClick={() => setSort(sortChange('v'))}>24h Vol {sortArrow('v')}</th>
                            {/*4*/}
                            <th style={{padding: "7px", cursor: 'pointer'}} onClick={() => setSort(sortChange('lst'))}>Price {sortArrow('lst')}</th>
                        </tr>

                        </thead>
                        <tbody>
                        {selectedTicker && spots.some(el => isSelected(el)) ? [getTableRow(selectedTicker), getSpotsData()]: getSpotsData()}
                        </tbody>
                    </table>
                </div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: totalPage > 1 ? '0' : '2em'}}>
                    {totalPage > 1 && <Paginate pageCount={totalPage} onPageChange={handlePageClick} active={currentPage - 1} />}
                </div>
            </div>
            :
            <div className="gray-container" style={{display: "flex", flexDirection: "column", marginTop: !is_exchange ? "10px" : "0px", height: "100%", paddingBottom: "0px"}}>
                <div className="section-header"style={{marginBottom: "1em"}}>{is_exchange ? 'Exchanges' : 'Calculated / Aggregates'}</div>
                <div className="table-body table-responsive" id="beauty-scroll7" tabIndex={1} style={{height: "100%", outline: 'none', paddingLeft: "1px", paddingRight: "1px"}}>
                    <table className="coins enumarable-table news-table-responsive linked-table" style={{marginLeft: "0.5px"}}>
                        <thead>
                        <tr>
                            <th style={{cursor: 'pointer'}} onClick={() => setSort(sortChange('source_code'))}>Exchange {sortArrow('source_code')}</th>
                            <th style={{cursor: 'pointer'}} onClick={() => setSort(sortChange('cis_symbol'))}>Pair {sortArrow('cis_symbol')}</th>
                            <th style={{cursor: 'pointer'}} onClick={() => setSort(sortChange('quoted_asset'))}>Quote {sortArrow('quoted_asset')}</th>
                            <th style={{cursor: 'pointer'}} onClick={() => setSort(sortChange('lst'))}>Price {sortArrow('lst')}</th>
                            <th style={{cursor: 'pointer'}} onClick={() => setSort(sortChange('chgpct'))}>24h Change% {sortArrow('chgpct')}</th>
                            <th style={{cursor: 'pointer'}} onClick={() => setSort(sortChange('vto'))}>24h Vol To {sortArrow('vto')}</th>
                            <th style={{cursor: 'pointer'}} onClick={() => setSort(sortChange('v'))}>24h Vol {sortArrow('v')}</th>
                        </tr>
                        <tr>
                            <th>
                                <div className="filter-bar-input create-flex" style={{width: "90%"}}>
                                    <div className="sm-select-input">
                                        <Select
                                            addAll
                                            selected={(exchange && exchange.id) || 0}
                                            optionValue={"id"}
                                            optionLabel={"name"}
                                            options={exchangeSearches || []}
                                            onChange={selected => setExchange(selected)}/>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="filter-bar-input create-flex" style={{width: "90%"}}>
                                    <div className="sm-select-input">
                                        <Select
                                            addAll
                                            selected={(pair && pair.value) || 0}
                                            optionValue={"symbol"}
                                            optionLabel={"value"}
                                            options={pairs || []}
                                            onChange={selected => setPair(selected)}/>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="filter-bar-input create-flex" style={{width: "90%"}}>
                                    <div className="sm-select-input">
                                        <Select
                                            addAll
                                            selected={(quotedAsset && quotedAsset.value) || 0}
                                            optionValue={"symbol"}
                                            optionLabel={"value"}
                                            options={quotedAssets || []}
                                            onChange={selected => setQuotedAsset(selected)}/>
                                    </div>
                                </div>
                            </th>
                            <th/>
                            <th/>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {getSpotsData()}
                        </tbody>
                    </table>
                </div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: totalPage > 1 ? '0' : '2em'}}>
                    {totalPage > 1 && <Paginate pageCount={totalPage} onPageChange={handlePageClick} active={currentPage - 1} />}
                </div>
            </div>
    );
}

// Prepares input props from state and local storage
const prepareInputProps = state => {

    // Convert exchanges to map of symbol to exchange data
    const exchangeMap = {};
    state.meta.exchanges.forEach(el => exchangeMap[el.code] = el);

    return {
        exchanges: exchangeMap
    };
};
export default connect(prepareInputProps)(withRouter(MarketsTable));
