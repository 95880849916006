import React, {useState} from 'react';
import moment from 'moment';
import SummaryModal from "../../../pages/News/SummaryModal";
import {useAuth0} from "../../../helpers/Auth0";
import DataMappers from "../../../helpers/DataMappers";

// Sentiment bubble generator
const sentimentStyle = (value) => {
    return {
        borderRadius: "50%",
        width: "3em",
        height: "3em",
        fontSize: "12px",
        lineHeight: "3em",
        textAlign: "center",
        margin: "0.5vh 0 0.5vh 0",
        color: "white",
        fontWeight: "bold",
        background: DataMappers.mapSentimentColor(value)
    }
};

const LatestNews = (props) => {
  const {news, v2} = props;
  const [show, setShow] = useState(false);
  const [item, setItem] = useState({});
  const {user} = useAuth0();

  return (
      v2 ?

              <div className="latest-news">
                  <div className="news-list" id="beauty-scroll3">
                      <div className="table-body table-responsive" id="beauty-scroll7" tabIndex={1} style={{overflow: 'hidden', outline: 'none'}}>
                          {news && news.length && <table className="coins enumarable-table news-table-responsive linked-table">
                              <thead>
                              <tr>
                                  <th style={{width: '130px',textAlign: 'left'}}>Date</th>
                                  <th style={{width: '100px',textAlign: 'left'}}>Source</th>
                                  <th style={{textAlign: 'left'}}>Title</th>
                                  <th style={{textAlign: 'left'}}>Sentiment</th>
                              </tr>
                              </thead>
                              <tbody>
                              {news.map((d, i) => {
                                  const date = moment.utc(d.publication_datetime).local();
                                  const sentimentPref = user.preferences.sentiment_field;
                                  const source = d.source.split("_").map(word =>  word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
                                  let sentimentPrefValue = 0;

                                  if(sentimentPref === "title_sentiment" && d.sentiment){
                                      sentimentPrefValue = d.sentiment.title;
                                  }else if(sentimentPref === "summary_sentiment" && d.sentiment){
                                      sentimentPrefValue = d.sentiment.summary;
                                  }else if(sentimentPref === "content_sentiment" && d.sentiment){
                                      sentimentPrefValue = d.sentiment.content;
                                  }
                                  const perc = (d.sentiment && parseInt(sentimentPrefValue * 100, 10)) || 0;
                                  return (
                                      <tr onClick={() => {setShow(true); setItem(d);}} key={i}>
                                          <td style={{textAlign: 'left'}}>
                                              <div className="date">{date.format('DD MMM YYYY')}</div>
                                              <div className="hour">{date.format('HH:mm:ss')}</div>
                                          </td>
                                          <td style={{textAlign: 'left'}}>
                                              <a>{source}</a>
                                          </td>
                                          <td style={{textAlign: 'left'}}>
                                              <div className="text">{d.title}</div>
                                          </td>
                                          <td style={{textAlign: 'left', display: "flex", flexDirection: "row", justifyContent: "center"}}>
                                              <div style={sentimentStyle(sentimentPrefValue)}>
                                                  {perc}%
                                              </div>
                                          </td>
                                      </tr>
                                  );
                              })}
                              </tbody>
                          </table>}
                          {news && !news.length && <p style={{color: "white"}}>No news found for the given filters...</p>}
                      </div>
                  </div>
                  <SummaryModal show={show} item={item} userPref={user.preferences.sentiment_field} cancel={() => setShow(false)}/>
              </div>
          :

            <div className="inp-column">
              <div className="gray-container latest-news">
                <div className="section-header">Latest News</div>
                <div className="news-list" id="beauty-scroll3">
                  <ul style={{margin: '1em'}}>
                    {news && news.length &&
                      news.map((d, i) => {
                        const source = d.source.split("_").map(word =>  word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
                        const date = moment.utc(d.publication_datetime).local();
                        return (
                          <li key={i} onClick={() => {setShow(true); setItem(d);}}>
                            <div className="ln-date">
                              <span>{date.format('DD.MM.YYYY')}/</span> {date.format('HH:mm')}
                            </div>
                            <div className="ln-text">{d.title}</div>
                            <div className="ln-link">
                              {' '}
                              <a target="_blank" className="blue-link" href={d.link}>
                                {source}
                              </a>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              <SummaryModal show={show} item={item} userPref={user.preferences.sentiment_field} cancel={() => setShow(false)}/>
            </div>
  );
};

export default LatestNews;
