import React, {useEffect} from 'react';
import './App.css';
import { TVChartContainer } from './components/TVChartContainer/index';
import {useAuth0} from "../helpers/Auth0";
import Utils from "../helpers/Utils";

const isMobile = Utils.checkMobile();

const TradingView = props => {
	const {
		// Use this If you are providing a ticker symbol value directly like BTC-USDT.CISCALC or BTC-USDT.CISIDX
		ticker,
		// Use the below if you are providing a ticker symbol part by part BTC-USDT.CISCALC => symbol: BTC, exchange: USDT, type: CISCALC
		symbol,
		exchange,
		type,
		customFilter, // Additional parameters applied to calls made for getting timescale data (check news filter part, the filters like tags given from outside)
		pricescale, // Indicates how many precision points should we used, this is optional value If not given component fetches a sample value and works from there
		latest_price, // Used for detecting pricescale from price directly
		showVolume, // Indicates If the chart should show the volume (Only for trading part currently)
		isNews, // Indicates if news chart that we are drawing
		// For portfolio chart we will feed data from outside use this below, this when given automatically converts chart to portfolio chart
		pData,
		containerId, // Give a different ID in case you are drawing multiple TV in one component (both visible at same time)
		// Screenshot related parameters
		takeScreenshot,
		screenshotReady
	} = props;

	const {user} = useAuth0();
	return (
		isMobile?
			<div style={{width: "100%", height: "370px"}}>
				<TVChartContainer ticker={ticker} symbol={symbol} exchange={exchange} type={type} containerId={containerId} takeScreenshot={takeScreenshot} screenshotReady={screenshotReady}
								  customFilter={customFilter} pricescale={pricescale} latest_price={latest_price} showVolume={showVolume} isNews={isNews} pData={pData}
								  chart_type={user.preferences && user.preferences.chart_type} sentiment_type={user.preferences && user.preferences.sentiment_field}/>
			</div> :
			<div style={{width: "100%", height: "100%"}}>
				<TVChartContainer ticker={ticker} symbol={symbol} exchange={exchange} type={type}  containerId={containerId} takeScreenshot={takeScreenshot} screenshotReady={screenshotReady}
								  customFilter={customFilter} pricescale={pricescale} latest_price={latest_price} showVolume={showVolume} isNews={isNews} pData={pData}
								  chart_type={user.preferences && user.preferences.chart_type} sentiment_type={user.preferences && user.preferences.sentiment_field}/>
			</div>
	);
}

export default TradingView;
