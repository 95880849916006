import React, {useEffect, useRef, useState} from "react";
import {withRouter} from "react-router-dom";
import HorizontalBar from "../HorizontalBar/HorizontalBar";
import Api from "../../api";
import TradingView from "../../tradingview";
import UpgradeYourPlan from "../../pages/UpgradeYourPlan/UpgradeYourPlan";

// Today date
const today = new Date();

const NewsAnalysis = props => {

    const {user} = props;
    // News data
    const [dataNews,setDataNews] = useState({positive:[],negative:[],load:false});
    // Coins Data
    const [dataCoins,setDataCoins] = useState({coins:[],load:false});
    // Coins Data (Excluding Top 10)
    const [otherCoinsData,setOtherCoinsData] = useState({coins:{},load:false});
    // Graph data for Top 10
    const [graphData,setGraphData] = useState({
        coins:[],
        positive:[],
        negative:[]
    });
    // Graph data for Excluding Top 10
    const [graphDataOther,setGraphDataOther] = useState({
        coinsPositive:[],
        coinsNegative:[],
        positive:[],
        negative:[]
    });

    //Arrange 1000 news 24h postive negative
    useEffect(()=>{
        const f = {
            min_publication_datetime: today.getTime() - 86400000,
            page_size: 1000,
        }
        Api.getPromise(`/News/NewsSearch?language_code=en`, f)
            .then(res => {
                const preference = user.preferences.sentiment_field.split("_")[0];
                const positiveNews = res.data.filter(el=>el.sentiment[preference] > 0.2);
                const negativeNews = res.data.filter(el=>el.sentiment[preference] < -0.2);

                let otherCoins = {};

                positiveNews.forEach(el=>{
                    el.tags.forEach(tag=>{
                        if(!otherCoins[tag.split("_")[1]] && tag.includes("COIN")){
                            otherCoins[tag.split("_")[1]] = {positive:positiveNews.filter(el=>el.tags.includes(tag)).length,negative:negativeNews.filter(el=>el.tags.includes(tag)).length};
                        }
                    })
                })
                negativeNews.forEach(el=>{
                    el.tags.forEach(tag=>{
                        if(!otherCoins[tag.split("_")[1]] && tag.includes("COIN")){
                            otherCoins[tag.split("_")[1]] = {positive:positiveNews.filter(el=>el.tags.includes(tag)).length,negative:negativeNews.filter(el=>el.tags.includes(tag)).length};
                        }
                    })
                })

                setOtherCoinsData({coins:otherCoins,load: true});
                setDataNews({positive: positiveNews,negative: negativeNews ,load: true});
            })
    },[])

    // Get top 10 coins
    useEffect(()=>{
        let f = {}
        f.ccy = user.preferences.currency;
        f.page_size = 10;
        f.order_by = 'mkt_cap,desc';

        Api.getPromise('/Search/CoinSearch', f)
            .then(res => {
                setDataCoins({coins:res && res.data,load: true})
            })
    },[])

    //Find Max number
    const maxNumberNews = (positive, negative) =>{

        const p = Math.max(...positive);
        const n = Math.max(...negative);

        return Math.max(p,n);
    }

    // Arrange graph data and other graph data
    useEffect(()=>{
        if(dataNews.load && dataCoins.load && otherCoinsData.load){
            let coins = [];
            let positiveNewsCount = [];
            let negativeNewsCount = [];

            dataCoins.coins.forEach(el=>{
                const symbol = el.symbol.includes("-") ? el.symbol.split("-")[0] : el.symbol
                coins.push(symbol);
                positiveNewsCount.push(dataNews.positive.filter(el=>el.tags.includes("COIN_"+symbol)).length);
                negativeNewsCount.push(dataNews.negative.filter(el=>el.tags.includes("COIN_"+symbol)).length);
            })

            setGraphData({
                coins:coins,
                positive:positiveNewsCount,
                negative:negativeNewsCount
            })

            let mappedOtherCoins = otherCoinsData.coins;

            coins.slice(0,10).forEach(el=>{
                delete mappedOtherCoins[el];
            })

            const otherCoins = Object.keys(mappedOtherCoins);

            let otherPositive = [];
            let otherNegative = [];
            let coinsPositive = [];
            let coinsNegative = [];

            otherCoins.forEach(el=>{

                if(mappedOtherCoins[el].positive > 0){
                    otherPositive.push(mappedOtherCoins[el].positive);
                    coinsPositive.push(el);
                }
                if(mappedOtherCoins[el].negative > 0){
                    otherNegative.push(mappedOtherCoins[el].negative);
                    coinsNegative.push(el);
                }
            })

            setGraphDataOther({
                coinsPositive: coinsPositive,
                coinsNegative: coinsNegative,
                positive: otherPositive,
                negative: otherNegative
            })

        }
    },[dataCoins, dataNews, otherCoinsData])


    return (
        <div className="row">
            {
                user && user.authorization.rule.features.news_graphical_analysis ?
                    <React.Fragment>
                        <div className="col-lg-6" style={{marginTop:"20px"}}>
                            <div className="gray-container" style={{padding:"0px"}}>
                                <div style={{textAlign:"center",paddingTop:"10px",paddingBottom:"10px",fontSize:"14px",color:"white",fontWeight:"600"}}>
                                    Number of Daily News vs Moving Average
                                </div>
                                <div className="chart-area" style={{height: "500px",marginTop:"0px"}}>
                                    <TradingView ticker="num_mv"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6" style={{marginTop:"20px"}}>
                            <div className="gray-container" style={{padding:"0px"}}>
                                <div style={{textAlign:"center",paddingTop:"10px",paddingBottom:"10px",fontSize:"14px",color:"white",fontWeight:"600"}}>
                                    Number of Positive News vs Number of Negative News
                                </div>
                                <div className="chart-area" style={{height: "500px",marginTop:"0px"}}>
                                    <TradingView ticker="pos_neg" containerId={"other-tv"}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6" style={{marginTop:"20px"}}>
                            <div className="gray-container">
                                <HorizontalBar title={{text:"Positive News for Top 10 Coins (Last 24h)",display:true}} datas={graphData.coins.slice(0,10)} values={graphData.positive.slice(0,10)} color={'#159725'} hoverColor={'#7ac231'} max={maxNumberNews(graphData.positive, graphData.negative)}/>
                            </div>
                        </div>
                        <div className="col-lg-6" style={{marginTop:"20px"}}>
                            <div className="gray-container">
                                <HorizontalBar title={{text:"Negative News for Top 10 Coins (Last 24h)",display:true}} datas={graphData.coins.slice(0,10)} values={graphData.negative.slice(0,10)} color={'#8e1e25'} hoverColor={'#c11e0f'} max={maxNumberNews(graphData.positive, graphData.negative)}/>
                            </div>
                        </div>
                        <div className="col-lg-6" style={{marginTop:"20px"}}>
                            <div className="gray-container">
                                <HorizontalBar title={{text:"Positive News for Coins exc Top 10 (Last 24h)",display:true}} datas={graphDataOther.coinsPositive} values={graphDataOther.positive} color={'#159725'} hoverColor={'#7ac231'} max={maxNumberNews(graphDataOther.positive, graphDataOther.negative)}/>
                            </div>
                        </div>
                        <div className="col-lg-6" style={{marginTop:"20px"}}>
                            <div className="gray-container">
                                <HorizontalBar title={{text:"Negative News for Coins exc Top 10 (Last 24h)",display:true}} datas={graphDataOther.coinsNegative} values={graphDataOther.negative} color={'#8e1e25'} hoverColor={'#c11e0f'} max={maxNumberNews(graphDataOther.positive, graphDataOther.negative)}/>
                            </div>
                        </div>
                    </React.Fragment>
                :
                    <UpgradeYourPlan/>
            }
        </div>
    );
};

export default withRouter(NewsAnalysis);
