import React, {useEffect, useState} from 'react';
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";


const FilterForm = props => {

    const {setFilter} = props;

    const [volatility,setVolatility] =useState({
        "Low":true,
        "Normal":true,
        "High":true,
        "Very High":false
    });

    const [volume,setVolume] =useState({
        "Extremely Low":false,
        "Very Low":false,
        "Low":true,
        "Medium-Low":true,
        "Medium":true,
        "Medium-High":true,
        "High":true,
        "Very High":true,
        "Extremely High":true,
    });

    const [marketCap,setMarketCap] =useState({
        "Micro":false,
        "Small":true,
        "Mid":true,
        "Large":true,
        "Huge":true
    });
    useEffect(()=>{

        const f = {
            volatilities: Object.keys(volatility).filter(el=>volatility[el] === true),
            volumes: Object.keys(volume).filter(el=>volume[el] === true),
            marketCaps: Object.keys(marketCap).filter(el=>marketCap[el] === true)
        }

        setFilter(f);

    },[marketCap,volume,volatility]);

    const handleChange = (event) =>{
        if(event.target.value === "volume"){
            setVolume({...volume,[event.target.name]:event.target.checked});
        }else if(event.target.value === "volatility"){
            setVolatility({...volatility,[event.target.name]:event.target.checked});
        }else if(event.target.value === "marketCap"){
            setMarketCap({...marketCap,[event.target.name]:event.target.checked});
        }
    }


    return(
        <div className="row">
            <div className="col-lg-5">
                <FormLabel>Volume/Liquidity Category</FormLabel>
                <FormGroup >
                    {
                        Object.keys(volume).map((checks,index)  => {
                            return(
                                <FormControlLabel key={index}
                                    control={
                                        <Checkbox
                                            key={index}
                                            checked={volume[checks]}
                                            onChange={handleChange}
                                            name={checks}
                                            color="primary"
                                            value="volume"
                                        />
                                    }
                                    label={checks}
                                />
                            );
                        })
                    }
                </FormGroup>
            </div>
            <div className="col-lg-3">
                <FormLabel>Volatility</FormLabel>
                <FormGroup >
                    {
                        Object.keys(volatility).map(checks  => {
                            return(
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={volatility[checks]}
                                            onChange={handleChange}
                                            name={checks}
                                            color="primary"
                                            value="volatility"
                                        />
                                    }
                                    label={checks}
                                />
                            );
                        })
                    }
                </FormGroup>
            </div>
            <div className="col-lg-4">
                <FormLabel>Market Cap</FormLabel>
                <FormGroup >
                    {
                        Object.keys(marketCap).map(checks  => {
                            return(
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={marketCap[checks]}
                                            onChange={handleChange}
                                            name={checks}
                                            color="primary"
                                            value="marketCap"
                                        />
                                    }
                                    label={checks}
                                />
                            );
                        })
                    }
                </FormGroup>
            </div>
        </div>

    );


};


export default FilterForm;
