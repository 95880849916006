import React from 'react';

const FullTableRow = props => {
  const {
    chgpct,
    price,
    marketCap,
    totalCoin,
    avalCoin,
    circCoin,
    vol24,
    perClass,
    perTd,
    symbol,
    rowImage,
    name,
    exchange,
    rowClass,
    high24,
    low24,
    rowClick,
    row,
    weight,
  } = props;
  return (
    <tr onClick={rowClick}>
      <td style={{display: 'flex', flexDrection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
        <div className="icon-img">
          <img src={rowImage} style={{width: 20}} alt="" />
        </div>
        {symbol}
      </td>
      <td className={rowClass}>{price}</td>
      <td>{marketCap}</td> {/*circulating_supply sabit kalacak ve soketten gelen PRICE alanı ile çarpılacak*/}
      <td>{circCoin}</td>
      <td>{avalCoin}</td>
      <td>{vol24}</td>
      <td className={perClass}>{perTd}</td>
      {weight && <td>{weight}</td>}
    </tr>
  );
};

export default FullTableRow;
