import Loading from "../../components/Loading";
import React, {useEffect, useRef, useState} from "react";
import ExchangeApi from "../../api/ExchangeApi";
import Utils from "../../helpers/Utils";
import AppHelpers from "../../helpers/AppHelpers";
import Api from "../../api";


const Orderbook = props => {
    const {
        selectedSpot, // Selected spot to show for orderbook
        setSelectedPrice // Send selected price to order creation screen
    } = props;

    // Orderbook connection and visual references
    const connection = useRef(null);
    const isScrolled = useRef(false);
    const tableEndRef = useRef(null);
    const parentRef = useRef(null);

    // Buy and sell orders
    const [loading, setLoading] = useState(false);
    const [buyOrders, setBuyOrders] = useState([]);
    const [sellOrders, setSellOrders] = useState([]);

    // Selected coin USD price
    const [usdPrice, setUsdPrice] = useState(null);

    const[sellOrderVisual,setSellOrderVisual] = useState([]);
    const[buyOrderVisual,setBuyOrderVisual] = useState([]);

    // Stop orderbook connection
    const closeConnection = () => {
        // Stop connection
        connection.current && connection.current.destroyConnection();
    };

    useEffect(() => {

        //Get selected spot usd price
        if(selectedSpot.base_asset){
            const assetParams={
                assets: selectedSpot.base_asset,
                ccy: "USD"
            }

            Api.getAssetPrices(assetParams).then(resp=>{
                if(resp.data[0].length > 0){
                    setUsdPrice(15000 / resp.data[0][2] );
                }
            })
        }



        // Stop connection if exists
        closeConnection()
        isScrolled.current = false;
        setLoading(true);

        // Get connection api for the selected spot
        if (selectedSpot) {
            connection.current = ExchangeApi.getApi(selectedSpot.source_code)
            connection.current && connection.current.subscribeOrderbook(selectedSpot.native_symbol, setBuyOrders, setSellOrders);
        }
    }, [selectedSpot]);

    // Clean real time connections
    useEffect(() => {
        return () => {
          closeConnection()
        }
    }, []);

    useEffect(() => {
        setLoading(buyOrders.length === 0 && sellOrders.length === 0);

        // For handling orderbook data some exchange use SortedSet implementation which is not iterable
        // Thus cant do [...aList] on them, to list is just a protection
        const so = sellOrders.toList ? sellOrders.toList() : sellOrders;
        const bo = buyOrders.toList ? buyOrders.toList() : buyOrders;
        setSellOrderVisual([...so].reverse());
        setBuyOrderVisual([...bo].reverse());
    }, [buyOrders, sellOrders]);

    // Scroll to bottom at buy orders once spot changed
    useEffect(() => {
        if (!isScrolled.current && tableEndRef.current && buyOrders.length > 0) {
            Utils.scrollToChild(tableEndRef, parentRef);
            isScrolled.current = true
        }
    }, [tableEndRef.current, isScrolled.current, buyOrders]);

    const calculatePerc = (row) =>{
        let perc = {};

        const calculated = parseInt(((parseFloat(row[1]) * 100) / usdPrice));

        if(parseFloat(row[1]) > usdPrice){
            perc.red = "100%";
            perc.black = "0%";
        }else{
            perc.red = calculated.toString() + "%";
            perc.black = (100 - calculated).toString() + "%";
        }

        return perc;
    }

    return (
        <div className="gray-container" style={{display: "flex", flexDirection: "column", height: "100%", borderRadius:"0px"}}>
            <div style={{color:"white",fontSize:"17px"}}>
                Sell Orders
            </div>
            <div className="table-body table-responsive" id="beauty-scroll7" tabIndex={1} style={{flex: "1 1 1px", outline: 'none'}} ref={parentRef}>
                <table className="coins enumarable-table news-table-responsive linked-table" style={{tableLayout:"fixed"}}>
                    <thead>
                        <tr>
                            <th className="fixed-gray-header" style={{textAlign: 'left',paddingRight:"1px"}}>Price({selectedSpot.quoted_asset})</th>
                            <th className="fixed-gray-header" style={{textAlign: 'left',paddingLeft:"0px"}}>Amount({selectedSpot.base_asset}}</th>
                            <th className="fixed-gray-header" style={{textAlign: 'left',paddingLeft:"10px"}}>Total({selectedSpot.quoted_asset})</th>
                        </tr>
                    </thead>
                    {loading && <tr>
                        <td colSpan={3}><Loading loading={loading}/></td>
                    </tr>}
                    {!loading && <tbody>
                        {sellOrderVisual.map((row, i) =>{
                            //Calculate background color amount
                            const perc = calculatePerc(row);
                            return(
                                <tr onClick={() => setSelectedPrice(parseFloat(row[0]))} style={{background:"linear-gradient(90deg, #292e33 "+ perc.black+", rgba(193, 30, 15, 0.5) "+perc.red+")"}}>
                                    <td style={{textAlign: 'left',paddingRight:"2px"}}>
                                        <div className="text" style={{color:'#c11e0f'}}>{AppHelpers.priceFormat(row[0])}</div>
                                    </td>
                                    <td style={{textAlign: 'left',paddingLeft:"0px",paddingRight:"0px"}}>
                                        <div className="text">{AppHelpers.priceFormat(row[1])}</div>
                                    </td>
                                    <td style={{textAlign: 'left',paddingLeft:"10px"}}>
                                        <div className="text">{row[2]}</div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>}
                </table>
            </div>
            <div style={{color:"white",fontSize:"17px",marginTop:"20px"}}>
                Buy Orders
            </div>
            <div className="table-body table-responsive" id="beauty-scroll7" tabIndex={1} style={{flex: '1 1 1px', outline: 'none'}}>
                <table className="coins enumarable-table news-table-responsive linked-table" style={{tableLayout:"fixed"}}>
                    <thead>
                        <tr>
                            <th className="fixed-gray-header" style={{textAlign: 'left',paddingRight:"1px"}}>Price({selectedSpot.quoted_asset})</th>
                            <th className="fixed-gray-header" style={{textAlign: 'left',paddingLeft:"0px"}}>Amount({selectedSpot.base_asset}}</th>
                            <th className="fixed-gray-header" style={{textAlign: 'left'}}>Total({selectedSpot.quoted_asset})</th>
                        </tr>
                    </thead>
                    <tbody>
                        <Loading loading={loading}>
                            {buyOrderVisual.map((row, i) =>{
                                //Calculate background color amount
                                const perc = calculatePerc(row);
                                return(
                                    <tr onClick={() => setSelectedPrice(parseFloat(row[0]))} ref={i === 19 ? tableEndRef : null} style={{background:"linear-gradient(90deg, #292e33 "+ perc.black+", rgba(122, 194, 49, 0.5) "+perc.red+")"}}>
                                        <td style={{textAlign: 'left',paddingRight:"2px"}}>
                                            <div className="text" style={{color:'#7ac231'}}>{AppHelpers.priceFormat(row[0])}</div>
                                        </td>
                                        <td style={{textAlign: 'left',paddingLeft:"0px",paddingRight:"0px"}}>
                                            <div className="text">{AppHelpers.priceFormat(row[1])}</div>
                                        </td>
                                        <td style={{textAlign: 'left',paddingLeft:"10px"}}>
                                            <div className="text">{row[2]}</div>
                                        </td>
                                    </tr>
                                )
                            } )}
                        </Loading>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Orderbook;
