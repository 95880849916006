import React, {useEffect, useState} from 'react';
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import AccountApi from "../../api/AccountApi/AccountApi";
import {getReferral} from "../../helpers/ReferralHandler";
import InfoApi from "../../api/InfoApi/InfoApi";
import Select from "../../components/Forms/Select";
import Utils from "../../helpers/Utils";
import {toastr} from "react-redux-toastr";

const WelcomeModal = props => {
    const {show, setUser, userInitialized} = props;

    const [check,setCheck] = useState(false);
    const [country, setCountry] = useState([]);
    const [selectedCountry,setSelectedCountry] = useState(null);
    const [showModal,setShowModal] = useState(show);
    const [packs,setPacks] = useState([]);
    const [error,setError] = useState(true);


    useEffect(()=>{

        const plans = Utils.isMarketMonitor().title === "Trader" ? "cis_trader_app_plans" : "cis_market_monitor_app_plans";

        InfoApi.getTrialPlansAndPrices(plans).then(resp=>{
            let packs = [];

            if(resp.result)
            {
                resp.result[0].product_list.forEach((el,i)=>{
                    if(i > 0 && el.price_list["USD"]){
                        packs.push({data:el,select:false})
                    }else if(el.price_list["USD"]){
                        packs.push({data:el,select:true})
                    }
                })
            }
            setPacks(packs);
        })

        InfoApi.getCountryList().then(resp=>{
            const mappedCountry = resp.result.map(el=>{
                return {value:el.cis_country_id, label:el.country_name}
            })
            setCountry(mappedCountry);
        })
    },[])


    const handleSavePreferences = () =>{

        if(!error){
            const ref = getReferral();

            let selected_id = "";

            packs.forEach(el=>{
                if(el.select){
                    selected_id = el.data.price_list["USD"][0].id;
                }
            })

            const param = {
                cis_country_id:selectedCountry.value,
                cis_product_price_id:selected_id,
                referrer_user_id:ref || "",
                subscribe_to_newsletter:check
            }


            AccountApi.initializeUserAccount(param).then(resp=>{
                if(resp.response === "Success"){
                    setUser(resp.result);
                }

                userInitialized();
            })
        }
    }

    useEffect(()=>{
        if(selectedCountry){
            setError(false);
        }else{
            setError(true);
        }
    },[selectedCountry])


    const handleSet = (val) => {
        let newPacks = [...packs];

        newPacks.forEach((el,i)=>{
            newPacks[i].select = false;
        })

        newPacks[val].select = true;

        setPacks(newPacks);
    }

    return (
        <div className={`general-modal ${showModal ? 'active' : ''}`} id="delete-modal">
            <div className="vAlignWr">
                <div className="vAlign">
                    <div className="white-modal-inner set-alert">
                        <div className="modal-blue-header" style={{textAlign:"center"}}>
                            Welcome to {Utils.isMarketMonitor().welcomeTitle}
                        </div>
                        <div className="modal-white-body">
                            <div style={{marginLeft:"10px"}}>
                                <div className="section-header" style={{fontSize:"16px",textAlign:"center"}}>
                                    We hope you enjoy the Platform.
                                </div>
                                <div  style={{fontSize:"16px",marginTop:"10px",color:"white",textAlign:"center"}}>
                                    We always welcome feedback and suggestions.
                                </div>
                                <div style={{fontSize:"16px",marginTop:"10px",color:"white",textAlign:"center"}}>
                                    You can reach us at
                                    <a style={{marginLeft:"5px"}} href = "mailto: contact@cryptoindexseries.com">
                                        contact@cryptoindexseries.com
                                    </a>
                                </div>
                            </div>
                            <hr style={{marginLeft:"-18px",marginRight:"-18px"}}/>
                            <div className="border-bottom-input floating-label-wrap">
                                <div className="sm-select-input floating-label-field floating-label-field--s1" style={{paddingRight: "0px", paddingLeft: "0px", paddingTop: "15px",zIndex: 10000}}>
                                    <Select
                                        selected={selectedCountry ? selectedCountry.value : null}
                                        optionValue={'value'}
                                        optionLabel={'label'}
                                        options={country || []}
                                        onChange={selected => setSelectedCountry(selected)}/>
                                </div>
                                <label className="floating-label" style={{fontSize:"16px", opacity:"1"}}>
                                    Country of Residence
                                </label>
                            </div>
                            {
                                error &&
                                <div style={{display:"flex", flexDirection:"column",paddingLeft:"5px",paddingRight:"5px"}}>
                                    <div style={{fontSize:"13px", opacity:"1", color:"red", marginTop:"15px"}}>
                                        You must select country!
                                    </div>
                                </div>
                            }
                            <div style={{display:"flex", flexDirection:"column",paddingLeft:"5px",paddingRight:"5px"}}>
                                <div style={{fontSize:"13px", opacity:"1", color:"white", marginTop:"15px"}}>
                                    Select package for 14-day free trial:
                                </div>
                                {
                                    packs.length > 0 && packs.map((el,i)=>{
                                        return(
                                            <div key={i} style={el.select ? {display:"flex",padding:"5px",paddingBottom:"12px",paddingRight:"15px",border:"1px solid #337ab7",marginTop:"10px",borderRadius:"4px", cursor:"pointer",width:"100%"} : {display:"flex",padding:"5px",paddingBottom:"12px",paddingRight:"15px",border:"1px solid gray",marginTop:"10px",borderRadius:"4px",cursor:"pointer",width:"100%"}} onClick={()=>handleSet(i)}>
                                                <div className="alertSentiment" style={{marginLeft: "10px", marginTop: "20px"}}>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={el.select || false}
                                                                    color="primary"
                                                                />
                                                            }
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div style={{display:"flex", flexDirection:"column",width:"100%"}}>
                                                    <div className="spa-text" style={{display:"flex",justifyContent:"space-between", marginTop: "20px",marginRight:"0px",color:"white",fontWeight:"500"}}>
                                                        <div>
                                                            {el.data.name}
                                                        </div>
                                                        {/*<div>*/}
                                                        {/*    {el.data.price_list["USD"][0].price} USD / Month*/}
                                                        {/*</div>*/}
                                                    </div>
                                                    <div className="spa-text" style={{textAlign:"left",marginRight:"0px",color:"white",fontWeight:"400",fontSize:"14px"}}>
                                                        {el.data.description}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <hr style={{marginLeft:"-18px",marginRight:"-18px"}}/>
                            <div style={{display:"flex",justifyContent:"space-between",paddingLeft:"10px",paddingRight:"10px"}}>
                                <div className="alertSentiment" style={{marginLeft: "10px", marginTop: "12.5px"}}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={check || false}
                                                    onChange={()=>setCheck(!check)}
                                                    color="primary"
                                                />
                                            }
                                        />
                                    </FormGroup>
                                </div>
                                <div className="spa-text" style={{textAlign:"left", marginTop: "25px",marginRight:"10px",color:"white"}}>
                                    I would like to receive newsletters and promotional material from CryptoIndexSeries
                                </div>
                            </div>
                            <hr style={{marginLeft:"-18px",marginRight:"-18px"}}/>
                            {
                                packs.length > 0 &&
                                <div className="form-buttons" style={{borderTop:"none"}}>
                                    <div className="form-flex">
                                        <div className="form-flex-inner">
                                            <div className="light-blue-btn">
                                                <button onClick={()=>handleSavePreferences()}>Continue</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clearfix" />
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WelcomeModal;
