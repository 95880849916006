import React, {useEffect, useState} from "react";
import Api from "../../../api";
import Loading from "../../../components/Loading";
import LatestNews from "../../../components/News/LatestNews";
import {connect} from "react-redux";
import TradingView from "../../../tradingview";
import StateBarIndex from "../../../components/Indices/StateBarIndex";
import GainersLosersTable from "../../../components/Sector/GainersLosersTable";
import SectorIndexTable from "../../../components/Sector/SectorIndexTable";

const SectorOverview = props => {
    const {
        rawData, // Raw sector metadata
        exchange, // Selected exchange rate
        businessMetadata // Business metadata
    } = props;

    // News of the sector
    const [news, setNews] = useState({items: [], loading: true});
    // Main index code
    const [indexCode, setIndexCode] = useState(rawData && rawData.main_index_benchmark_code);

    // Executes when props are changed
    useEffect(() => {
        // Fetch news for the sector
        if (businessMetadata && businessMetadata.length > 0) {
            fetchNews(businessMetadata);
        }
        // Fetch index for the sector
        setIndexCode(rawData && rawData.main_index_benchmark_code);
    }, [rawData, businessMetadata]);


    // Fetches news for the sector
    const fetchNews = (businessMetadata) => {
        setNews({...news, loading: true});
        // Find which tag to search for the sector
        const tags = businessMetadata.find(el => el.numeric_code === rawData.numeric_code).news_tag;
        Api.searchNews({tags: tags})
            .then(res => setNews({items: res, loading: false}));
    };

    return (
        <Loading loading={!rawData}>
            <div className="detail-indices-inner">
                <div className="detail-indices-row">
                    <div className="di-left-column">
                        <div className="gray-container sector-summary" style={{'height' : '98%'}}>
                            <div className="section-header">Sector Description</div>
                            <div className="summary-text-all" id="beauty-scroll6" tabIndex={1} style={{overflowY: 'scroll', minHeight: 250, outline: 'none'}}>
                                <div className="sector-summary-text" dangerouslySetInnerHTML={{__html: rawData && rawData.business_classification_explanation && rawData.business_classification_explanation}}/>
                                <div className="sector-summary-text"><p>Full report can be accessed from <a target="_blank" rel="noopener noreferrer" href={rawData.document_url}>here</a></p></div>
                            </div>
                            <div className="clearfix" />
                        </div>
                    </div>
                    {indexCode &&
                    <div className="di-right-column">
                        <div className="state-bar" style={{"paddingBottom" : "5px", "paddingTop" : "15px"}}>
                            <StateBarIndex clickable={true} isInPage={true} indexCode={indexCode} selectedExchange={exchange}/>
                        </div>
                        <div className="chart-area"  style={{"marginTop": "0px"}}>
                            <TradingView symbol={indexCode} exchange={exchange.value} type={'CISIDX'}/>
                        </div>
                    </div>}
                    {!indexCode &&
                    <div className="di-right-column" style={{"marginTop" : "10px"}}>
                        <div className="col-md-6" style={{"paddingLeft" : "0px", "height": "100%"}}>
                            <GainersLosersTable exchange={exchange} sectorCode={rawData.id} isGainer={true}/>
                        </div>
                        <div className="col-md-6" style={{"paddingRight" : "0px", "height": "100%"}}>
                            <GainersLosersTable exchange={exchange} sectorCode={rawData.id}/>
                        </div>
                    </div>}
                </div>
                <div className="detail-indices-row">
                    <div className="di-left-column">
                       <LatestNews news={news.items}/>
                    </div>
                    {indexCode &&
                    <div className="di-right-column" style={{"marginTop" : "20px"}}>
                        <div className="col-md-6" style={{"paddingLeft" : "0px", "height": "100%"}}>
                            <GainersLosersTable exchange={exchange} sectorCode={rawData.id} isGainer={true}/>
                        </div>
                        <div className="col-md-6" style={{"paddingRight" : "0px", "height": "100%"}}>
                            <GainersLosersTable exchange={exchange} sectorCode={rawData.id} />
                        </div>
                    </div>}
                    {!indexCode &&
                    <div className="di-right-column" style={{"marginTop" : "20px"}}>
                            <section className={"coin-table"}>
                                <div className="gray-container" style={{"paddingBottom" : "0px", "height": "100%"}}>
                                    <SectorIndexTable exchange={exchange} sectorCode={rawData.id}/>
                                </div>
                            </section>
                        </div>}
                    </div>
                    {indexCode &&
                    <div className="detail-indices-row" style={{"marginTop" : "20px"}}>
                        <div className="di-right-column" style={{"width" : "100%"}}>
                            <section className={"coin-table"}>
                                <div className="gray-container" style={{"paddingBottom" : "0px"}}>
                                    <SectorIndexTable exchange={exchange} sectorCode={rawData.id}/>
                                </div>
                            </section>
                        </div>
                    </div>}
            </div>
        </Loading>
    );
};

const mapStateToProps = state => {
    return {
        businessMetadata: (state.meta && state.meta.lists && state.meta.lists.business_classification_schema)
    };
};

export default connect(mapStateToProps)(SectorOverview);
