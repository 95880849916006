import React, {useEffect, useState} from "react";
import Api from "../../../api";
import Loading from "../../../components/Loading";
import {connect} from "react-redux";
import TradingView from "../../../tradingview";


const OverviewMobile = props => {
    const {
        rawData, // Raw sector metadata
        exchange, // Selected exchange rate
        businessMetadata // Business metadata
    } = props;

    // News of the sector
    const [news, setNews] = useState({items: [], loading: true});
    // Main index code
    const [indexCode, setIndexCode] = useState(rawData && rawData.main_index_benchmark_code);

    // Executes when props are changed
    useEffect(() => {
        // Fetch news for the sector
        if (businessMetadata && businessMetadata.length > 0) {
            fetchNews(businessMetadata);
        }
        // Fetch index for the sector
        setIndexCode(rawData && rawData.main_index_benchmark_code);
    }, [rawData, businessMetadata]);


    // Fetches news for the sector
    const fetchNews = (businessMetadata) => {
        setNews({...news, loading: true});
        // Find which tag to search for the sector
        const tags = businessMetadata.find(el => el.numeric_code === rawData.numeric_code).news_tag;
        Api.searchNews({tags: tags}).then(res => setNews({items: res, loading: false}));
    };

    return (
        <Loading loading={!rawData}>
            <div className="detail-indices-inner">
                <div className="detail-indices-row" style={{display:'unset'}}>
                    <div className="di-left-column" style={{width:'100%'}}>
                        <div className="gray-container sector-summary sector-summary-mobile" style={{'height' : '100%'}}>
                            <div className="section-header">Sector Description</div>
                            <div className="summary-text-all" id="beauty-scroll6" tabIndex={1} style={{overflowY: 'scroll', minHeight: 250, outline: 'none'}}>
                                <div className="sector-summary-text" dangerouslySetInnerHTML={{__html: rawData && rawData.business_classification_explanation && rawData.business_classification_explanation}}/>
                                <div className="sector-summary-text"><p>Full report can be accessed from <a target="_blank" rel="noopener noreferrer" href={rawData.document_url}>here</a></p></div>
                            </div>
                            <div className="clearfix" />
                        </div>
                    </div>
                    {indexCode && <div className="di-right-column" style={{width:'100%', marginTop:'20px'}}>
                        <div className="chart-area"  style={{"marginTop": "0px"}}>
                            <TradingView symbol={indexCode} exchange={exchange.value} type={'CISIDX'}/>
                        </div>
                    </div>}
                </div>
            </div>
        </Loading>
    );
};

const mapStateToProps = state => {
    return {
        businessMetadata: (state.meta && state.meta.lists && state.meta.lists.business_classification_schema)
    };
};

export default connect(mapStateToProps)(OverviewMobile);
