import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import TradingView from "../../tradingview";
import Api from "../../api";
import DataMappers from "../../helpers/DataMappers";
import Select from "react-select";
import Loading from "../Loading";
import SmallPairList from "./SmallPairList";
import OscillatorsTable from "../CoinTechnicalTab/OscillatorsTable";
import MovingAverageTable from "../CoinTechnicalTab/MovingAverageTable";
import IndicatorsApi from "../../api/IndicatorsApi";

const intervals = [
    {value:"1m",label:"1 minute"},
    {value:"5m",label:"5 minutes"},
    {value:"15m",label:"15 minutes"},
    {value:"30m",label:"30 minutes"},
    {value:"1h",label:"1 hours"},
    {value:"1d",label:"1 day"},
]

const ChartViewMarket = (props) => {
    // Props selected coin and ccy , and get exchanges from metadata
    const {coinRow, ccy, exchanges, selectedTicker} = props;

    // All markets
    const [marketOptions, setMarketOptions] = useState([]);
    // All pairs related to selected market
    const [pairOptions, setPairOptions] = useState([]);
    // Selected Market
    const [selectedMarket, setSelectedMarket] = useState("");
    // Selected Pair
    const [selectedPair, setSelectedPair] = useState("");
    // Oscillators
    const [oscillators, setOscillators] = useState({data: [], sell: 0, neutral: 0, buy: 0, all: 0, loading:true});
    // Moving Averages
    const [movingAvarages, setMovingAvarages] = useState({data: [], sell: 0, neutral: 0, buy: 0, all: 0, loading:true});
    // Interval Value
    const [interval, setInterval] = useState({value:"1m",label:"1 minute"});

    // Loading Control
    const [loading,setLoading] = useState(true);

    const getMarkets = () => {
        const params = {};
        Api.marketSearch(params).then(res => {
            let market;
            let options = [];

            res.forEach(el => {
                exchanges.forEach(exel => {
                    if (el === exel.code && exel.is_connectable) {
                        market = {value: el, label: exel.name}
                        options.push(market);
                    }
                });
            });
            setMarketOptions(options);
            setSelectedMarket(options[0]);
            setLoading(false);
        });
    }

    // Called when one of the search parameters are changed
    const searchSpots = () => {
        // Generate search params

        setSelectedPair("");

        const params = {
            page_number: 1,
            is_exchange: true,
            base_asset : coinRow.symbol,
            source_code : selectedMarket.value
        };

        Api.spotSearch(params, 100).then(async res => {
            res.data = res.data || [];

            res.data.forEach(el => el.ticker= el.ticker || {});
            // Generate readable tickers
            res.data.forEach(el => {
                el.org_ticker = el.ticker;
                el.ticker = DataMappers.spotTickerMapper(el.ticker);
            });

            let pairs = [];

            res.data.forEach(el=>{
                pairs.push({value:el.pair,label:el.pair,data:el});
            })
            setPairOptions(pairs);
        });
    }

    useEffect(()=>{
        getMarkets();
    },[])

    useEffect(()=>{
       if(selectedMarket !== ""){
           searchSpots();
       }
    },[selectedMarket])

    useEffect(()=>{
        setOscillators({...oscillators,loading: true});

        setMovingAvarages({...movingAvarages,loading: true});

        if(selectedPair && selectedPair !== ""){
            IndicatorsApi.getIndicatorsTemplates().then(resp=>{
                let param = resp.DEFAULT;

                param.symbol = selectedPair.cis_symbol;

                param.interval = interval.value;

                IndicatorsApi.getIndicators(param).then(resp=>{

                    let oscillator = [];
                    let movingAvarage = [];


                    resp.indicator_results && resp.indicator_results.forEach(el=>{
                        if(el.indicator_type === "Moving Average"){
                            movingAvarage.push(el);
                        }else{
                            oscillator.push(el);
                        }
                    });


                    setOscillators({...oscillators, data:oscillator,loading: false});

                    setMovingAvarages({...movingAvarages, data:movingAvarage, loading: false});

                })
            })
        }
    },[selectedPair,interval])



    return (
        <div className={`gray-container`} style={{borderTopLeftRadius:"0px",borderTopRightRadius:"0px"}}>
            <Loading loading={loading}>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <SmallPairList coinBaseAsset={coinRow.symbol} ccy={ccy} type={"small"} setSelectedPair={setSelectedPair} selectedPair={selectedPair}/>
                    </div>
                    <div className="col-lg-8 col-md-12">
                        {selectedPair && selectedPair !== "" ?
                            <div style={{height:"500px"}}>
                                <TradingView ticker={selectedPair.cis_symbol} latest_price={selectedPair.org_ticker && selectedPair.org_ticker.lst} showVolume={true}/>
                            </div>
                            :
                            <div style={{color: "white", paddingTop: "0.5em", paddingBottom: "0.5em", margin: "auto", fontSize: "20px"}}>Select pair for price graph.</div>}
                    </div>
                    <div className="col-12">

                    </div>
                </div>
                <hr/>
                {
                    selectedPair && selectedPair !== "" &&
                    <div className="row">
                        <div className="col-lg-12 col-sm-12" style={{marginBottom:"15px"}}>
                            <div style={{display:"flex"}}>
                                {intervals.map((el,index)=>{
                                    return(
                                        <div key={index} className={interval.value === el.value ? "interval-osc-active" : "interval-osc"} onClick={()=>setInterval(el)}>
                                            {el.label}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                }

                {
                    selectedPair && selectedPair !== "" &&
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <OscillatorsTable oscillators={oscillators} />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <MovingAverageTable movingAvarages={movingAvarages} />
                        </div>
                    </div>
                }
            </Loading>
        </div>
    );
};


const mapStateToProps = state => {
    return {
        meta: state.meta,
        exchanges: state.meta.exchanges
    };
};

export default connect(mapStateToProps)(ChartViewMarket);
