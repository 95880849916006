import React, {useEffect, useState} from "react";
import Api from "../../api";
import Loading from "../Loading";
import DataMappers from "../../helpers/DataMappers";
import Select from "../Forms/Select";
import Paginate from "../Paginate";
import IndexTable from "../IndexTable/IndexTable";

// Page size selections
const pages = [
    {value: 10, label: '10'},
    {value: 20, label: '20'},
    {value: 50, label: '50'},
];

/**
 * Table implementation for index list in Sector Overview
 *
 * @param props
 * @constructor
 */
const SectorIndexTable = props => {
    const {
        sectorCode, // Which sector indices will be shown
        exchange, // Which exchange is selected for the view
    } = props;

    // Indexes to be shown
    const [indexes, setIndexes] = useState([]);
    // Indicates if the indexes are loading
    const [loading, setLoading] = useState(true);

    // Table related information variables
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [sort, setSort] = useState({i: 'mkt_cap', s: 'desc'});

    useEffect(() => {
        // Fetch coins from api
        setLoading(true);
        Api.searchIndex({business_classification: sectorCode, ccy: exchange.value, page_size: pageSize, page_number: currentPage}, true)
            .then(res => {
                // Map ticker values for visualisation
                const data = res.data.map(el => {
                    el.display_ticker = DataMappers.baseTickerMapperRaw(el.ticker, true);
                    return el;
                });

                // Set state variables
                setIndexes(data);
                setTotalPage(res.totalPage);
                setLoading(false);
            });
    }, [sectorCode, exchange, pageSize, currentPage]);

    // Handles pager clicks
    const handlePageClick = d => {
        if (currentPage !== d.selected + 1) {
            setCurrentPage(d.selected + 1);
        }
    };

    return (
        (indexes && indexes.length > 0 ? <div style={{display: "flex", flexDirection: "column"}}>
            <div className="table-head">
                <div className="clearfix" />
                <div className={"table-left"}>
                    <div className="section-header">Sector Related Indices</div>
                </div>
                <div className="table-right">
                    <div className="table-select-input" style={{"margin": "6px"}}>
                        <div className="sm-select-input small-select">
                            <Select selected={pageSize} options={pages} onChange={selected => setPageSize(selected.value)}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-body table-responsive" style={{"marginTop": totalPage > 1 ? null : "1em"}} >
                <Loading loading={loading}>
                    <IndexTable indexData={indexes} exchange={exchange} sort={sort} setSort={setSort} disabledColumns={[]}/>
                </Loading>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: totalPage > 1 ? '0' : '2em'}}>
                    {totalPage > 1 && <Paginate pageCount={totalPage} onPageChange={handlePageClick} active={currentPage - 1} />}
                </div>
            </div>
        </div> : <div className={"summary-par"} style={{"color" : "white", "paddingTop" : "2em", "paddingBottom" : "2em"}}>
            There is no index created for this sector yet...
        </div>)
    );
};

export default SectorIndexTable;
