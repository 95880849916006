import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import TextComplete from '../../../components/TextComplete';
import TopUserMenu from '../TopUserMenu';
import {Link, NavLink, withRouter} from 'react-router-dom';
import Api from "../../../api";
import CISNavigator from "../../../helpers/CISNavigator";
import ReactTooltip from 'react-tooltip';
import {useAuth0} from "../../../helpers/Auth0";
import moment from 'moment';
import Loading from '../../../components/Loading';
import NotificationApi from "../../../api/NotificationApi/NotificationApi";
import Utils from "../../../helpers/Utils";

const TopMenu = props => {
  const {history} = props;
  const [notifications, setNotifications] = useState({items: [], loading: false});
  const {user} = useAuth0();
  const unReadCount = notifications.items.filter(n => !n.seen).length;
  const userId = user && user.sub;

  const handleOnClick = useCallback((path) => history.push(path), [history]);

  // Options for the search box
  const [searchOptions, setSearchOptions] = useState([]);
  // Indicates if the options are loading
  const [loading, setLoading] = useState(false);
  // Search text
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
      fetchNotifications();
        if (window.navigator.serviceWorker) { // Gives error in production build
          window.navigator.serviceWorker.addEventListener('message', message => fetchNotifications());
        }
  }, []);

  const fetchNotifications = () => {
      setNotifications(n => ({...n, loading:true}));
      NotificationApi.getNotifications()
          .then(result => setNotifications({items: result, loading: false}));
  };

  // fetch notifications again to change unread count
  const fetchOnMouseLeave = () => {
      NotificationApi.getNotifications(true) // Set seen true since user opened the screen
          .then(result => setNotifications({items: result, loading: false}));
  };

    // Search indexes and coins with given string
    const search = (str) => {
        const searchString = str && str.target && str.target.value;
        if (searchString) {
            setLoading(true);
            Api.quickSearch(searchString).then(quickSearch => { // Search coins, indices and sectors
                // Generate coin group for options
                const groupedCoins = quickSearch.coins.map(el => {
                    el.group = "Coins";
                    el.label = el.name + '(' + el.symbol + ')';
                    el.value = el.symbol;
                    return el;
                });

                // Generate index group for options
                const groupedIndex = quickSearch.indices.map(el => {
                    el.group = "Indices";
                    el.label = el.index_code;
                    el.value = el.index_code;
                    return el;
                });

                // Generate sector group for options
                const groupedSector = quickSearch.sectors.map(el => {
                    el.group = "Sectors";
                    el.label = el.sector_name;
                    el.value = el.sector_code;
                    return el;
                });

                // Set options
                setSearchOptions(groupedCoins.concat(groupedIndex).concat(groupedSector));
                setSearchText(searchString);
                setLoading(false);
            });
        } else {
            setLoading(false);
            setSearchOptions([]);
            setSearchText('');
        }
    };

    // Called when search closed, cleans searched items
    const searchClosed = () => {
        if (searchOptions.length > 0) {
            setLoading(false);
            setSearchOptions([]);
            setSearchText('');
        }
    };

    // Navigate user to respective page on selection from search
    const select = selected => {
        if (selected) {
            searchClosed();
            if (selected.group === "Indices") { // If an index is selected navigate to index detail page
                CISNavigator.navigateToIndexDetail(history, selected, {value: user.preferences.currency})
            } else if (selected.group === "Coins") { // If a coin is selected navigate to coin detail page
                CISNavigator.navigateToCoinDetail(history, selected, {value: user.preferences.currency})
            } else { // If a sector is selected navigate to sector detail page
                CISNavigator.navigateToSectorDetail(history, selected, {value: user.preferences.currency})
            }
        }
    };

    const timeDiff = (el) => {
        const currentDate = new Date();
        const difference = Date.parse( el.expired_at) - currentDate;
        const daysDifference = Math.floor(difference/1000/60/60/24);
        return daysDifference;
    }

    const onClickWatchList = () =>{
        history.push("/watch-list")
    }


    return (
        <section className="top-menu">
          <ReactTooltip html={true} />
            <div className="tm-inner">
                <div className="logo" style={{display:"flex"}}>
                    <img src="/img/logo-text.svg" alt=""/>
                    <div style={{color:"white",fontWeight:"bold",marginLeft:"10px",marginTop:"1px", letterSpacing:"1px", fontFamily:"sans-serif"}}>
                        {Utils.isMarketMonitor().title}
                    </div>
                </div>
                <div className="tm-inner-right-area">
                    {
                        user && user.authorization && user.authorization.role.find(el=>el.is_trial) &&
                        <div style={{padding:"2px 10px" ,border:"1px solid #16adcf",marginRight:"10px",borderRadius:"5px"}}>
                            <div style={{textAlign:"center",color:"white"}}>
                                Trial Version - {timeDiff(user.authorization.role.find(el=>el.is_trial))} Days Left
                            </div>
                            <div style={{textAlign:"center",color:"white"}}>
                                <a target="_blank" href={"https://account.cryptoindexseries.com/plans-prices/" + Utils.checkRedirectUrlPlanAndPrices(user.authorization.role[0].name) }>UPGRADE</a>
                            </div>
                        </div>
                    }

                    <div style={{marginTop:"7px"}}>
                        <TextComplete
                            data={searchOptions}
                            className={"test-class"}
                            onSearch={search}
                            onClose={searchClosed}
                            searchText={searchText}
                            loading={loading}
                            onSelect={select}
                        />
                    </div>
                    <div className="notifications">
                        <a style={{cursor:"pointer"}} onClick={()=>onClickWatchList()} data-tip={'WATCHLISTS'} data-hover="WATCHLISTS">
                            <img className="actionable-img" src="/img/watchlist.svg" alt=""/>
                        </a>
                    </div>

                    <div onMouseLeave={fetchOnMouseLeave} className="notifications">
                        <a className={(unReadCount && 'active') || ''} href="#" data-number={unReadCount}
                           data-hover="NOTIFICATIONS">
                            <img className="actionable-img" src="/img/ic-notifications.svg" alt=""/>
                        </a>
                        <div className="notf-menu-dropdown">
                            <div className="nmd-header">
                                Notifications
                            </div>
                            <Loading loading={notifications.loading}>
                                <ul className="active-scroll" id="beauty-scrollnotf">
                                    {notifications.items.map((n, i) => (
                                        <li key={i}>
                                            <a>
                                                <div className="notf-inner">
                                                    <div className={n.seen ? "text-muted" : "text-dark"}>{n.header}</div>
                                                </div>
                                                <div className="notf-date">{moment(n.date).format('DD.MM.YYYY HH:mm:ss')}</div>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </Loading>
                        </div>
                    </div>

                    <div className="notifications noContent">
                        <a className="active" data-hover="NOTIFICATIONS">
                            <img className="actionable-img" src="/img/menu.svg" alt=""/>
                        </a>
                        <div className="notf-menu-dropdown">
                            <div className="nmd-header" style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                                <div style={{textAlign:"center"}}>
                                    APPS
                                </div>
                            </div>
                            <div  style={{display:"flex",flexDirection:"column",padding:"0px"}}>
                                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                                    <div className={"app-menu-grid-item"} style={{borderRight:"1px solid gray"}} onClick={()=>handleOnClick("/arbitrageapp")}>
                                        <a>
                                            <div style={{textAlign:"center"}}>
                                                <img className="actionable-img" src="/img/grid.svg" alt=""/>
                                                <div style={{marginTop:"5px"}}>
                                                    Arbitrage
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className={"app-menu-grid-item"} style={{cursor:"auto"}}>
                                        <a>
                                            <div style={{textAlign:"center"}}>
                                                <img className="actionable-img" src="/img/menu.svg" alt=""/>
                                                <div style={{marginTop:"5px"}}>
                                                    Coming Soon
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                {/*<div style={{display:"flex",flexDirection:"row",padding:"0px",justifyContent:"space-between"}}>*/}
                                {/*    <div className={"app-menu-grid-item"} style={{borderRight:"1px solid gray", cursor:"auto"}}>*/}
                                {/*        <a>*/}
                                {/*            <div style={{textAlign:"center"}}>*/}
                                {/*                <img className="actionable-img" src="/img/menu.svg" alt=""/>*/}
                                {/*                <div style={{marginTop:"5px"}}>*/}
                                {/*                    Coming Soon*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </a>*/}
                                {/*    </div>*/}
                                {/*    <div className={"app-menu-grid-item"} style={{cursor:"auto"}}>*/}
                                {/*        <a>*/}
                                {/*            <div style={{textAlign:"center"}}>*/}
                                {/*                <img className="actionable-img" src="/img/menu.svg" alt=""/>*/}
                                {/*                <div style={{marginTop:"5px"}}>*/}
                                {/*                    Coming Soon*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </a>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div >
                        </div>
                    </div>

                    <TopUserMenu/>
                </div>
                <div className="clearfix"/>
            </div>
        </section>
    );
};

const mapStateToProps = state => {
  return {
    state,
    user: state.user
  };
};

export default connect(mapStateToProps)(withRouter(TopMenu));
