import AppHelpers from "../../helpers/AppHelpers";

const getOrderBookUrl = () => "wss://ws-feed-pro.btcturk.com/";

class BTCTurkApi {

    constructor() {}

    subscribeOrderbook(symbol, buyHandler, sellHandler) {
        const conn = new WebSocket(getOrderBookUrl());

        // Subscribe to symbol on open
        conn.onopen = () => {
            const m = [151,
                {
                    type: 151,
                    channel: 'orderbook',
                    event: symbol,
                    join: true
                }
            ];
            conn.send(JSON.stringify(m));
        };

        this.conn = conn;
        this.buyHandler = buyHandler;
        this.sellHandler = sellHandler;

        this.conn.onmessage = ((event) => {
            // Parse message to JSON
            const json = JSON.parse(event.data)[1];

            if (!json.BO && !json.AO) { // Not interested
                return;
            }

            // Compute totals on bids
            const bids = json.BO && json.BO.slice(0, 20).map(el => {
                const amount = AppHelpers.priceFormat(el.A * el.P);
                return [el.P, el.A, amount];
            });

            // Compute totals on asks
            const asks = json.AO && json.AO.slice(0, 20).map(el => {
                const amount = AppHelpers.priceFormat(el.A * el.P);
                return [el.P, el.A, amount];
            });

            // Update buy and sell data
            this.buyHandler(bids.reverse());
            this.sellHandler(asks);
        });
    }

    destroyConnection() {
        this.conn && this.conn.close();
    }
}

export default BTCTurkApi;
