import React from 'react';
import Select from '../../Forms/Select';
import {withRouter} from 'react-router-dom';
import CoinRow from '../../CoinRow';
import CISNavigator from "../../../helpers/CISNavigator";
import Utils from "../../../helpers/Utils";

const isMobile = Utils.checkMobile();

const TopIndices = props => {
    const {indexes, setExchange, exchange, currencyList, history, selectedExchange} = props;

    return (
        isMobile ?
            <div className="inp-column">
                <div className="gray-container top-indices">
                    <div className="section-header"
                         style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <div>Top Indices</div>
                    </div>
                    <div className="table-body table-responsive" id="beauty-scroll7">
                        <table className="coins top-indices-table enumarable-table linked-table">
                            <thead>
                            <tr>
                                <th style={{paddingLeft: "5px"}}>Index Name</th>
                                <th style={{paddingLeft: "5px"}}>24h Change%</th>
                            </tr>
                            </thead>
                            <tbody>
                            {indexes.items.map((row, i) => (
                                <CoinRow
                                    key={i}
                                    type={'indicesSmall'}
                                    rowClick={() => {
                                        CISNavigator.navigateToIndexDetail(history, row, selectedExchange);
                                    }}
                                    row={row}
                                    selectedExchange={selectedExchange}
                                />
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            :
            <div className="inp-column">
                <div className="gray-container top-indices">
                    <div className="section-header" style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: '1em'
                    }}>
                        <div>Top Indices</div>
                        <div className={'table-select-input'}>
                            <div style={{fontSize: 13}} className={'sm-select-input small-select'}>
                                <Select selected={exchange} options={currencyList}
                                        onChange={selected => setExchange(selected.value)}/>
                            </div>
                        </div>
                    </div>
                    <div className="table-body table-responsive" id="beauty-scroll7" tabIndex={1}
                         style={{overflow: 'auto', height: 410, outline: 'none'}}>
                        <table className="coins top-indices-table enumarable-table linked-table">
                            <thead>
                            <tr>
                                <th>Index Name</th>
                                <th>24h Change%</th>
                            </tr>
                            </thead>
                            <tbody>
                            {indexes.items.map((row, i) => (
                                <CoinRow
                                    key={i}
                                    type={'indicesSmall'}
                                    rowClick={() => {
                                        CISNavigator.navigateToIndexDetail(history, row, selectedExchange);
                                    }}
                                    row={row}
                                    selectedExchange={selectedExchange}
                                />
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
    );
};

export default withRouter(TopIndices);
