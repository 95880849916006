import React from 'react';


const NotFoundPage = props => {
  return (
    <section style={{textAlign: 'center', minHeight: 400}} className="contact-area padding-sm-container">
      <div className="general-header">404</div>
      <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', color: '#fff'}} className="contact-form-and-banners">
        <h2>PAGE NOT FOUND</h2>
        <p>
          <a onClick={() => props.history.push('/')}>Go Home</a>
        </p>
      </div>
    </section>
  );
};

export default NotFoundPage;
