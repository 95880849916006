import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {toastr} from "react-redux-toastr";
import Select from 'react-select';
import Api from '../../../api';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import AlertApi from "../../../api/AlertApi";
import RealtimeUtils from "../../../helpers/RealtimeUtils";
import DataMappers from "../../../helpers/DataMappers";
import ReactTooltip from "react-tooltip";

const fields = [
    {value: "price", label: "Price"},
    {value: "change_percentage", label: "Change Percentage(24h)"}
]

const directions = [
    {value: "above", label: "Above"},
    {value: "below", label: "Below"}
]

const customStyles = {
    menuList: (provided, state) => ({
        ...provided,
        maxHeight: "230px",
    }),
}

const CreateAndUpdateConditionModal = props => {
    const {
        conditionsFields,
        setConditionsFields,
        type,
        setType,
        exchanges,
        index,
        handleDeleteCondition,
        updated,
        mappedExchanges
    } = props;


    // All markets
    const [marketOptions, setMarketOptions] = useState([]);
    // All symbols
    const [coinSymbolOptions, setCoinSymbolOptions] = useState({data: [], isDisable: true});
    // All currency related with symbol
    const [coinCurrencyOptions, setCoinCurrencyOptions] = useState({data: [], isDisable: true});
    // All fields
    const [targetFields, setTargetFields] = useState({data: fields, isDisable: true});
    // Selected coin data
    const [selectedCoinData, setSelectedCoinData] = useState({});

    const onChange = (type, selectedOption) => {
        let newConditionsFields = [...conditionsFields];
        newConditionsFields[index][type] = selectedOption;
        if (type === "market") {
            newConditionsFields[index]["symbol"] = null;
            newConditionsFields[index]["currency"] = null;
            setTargetFields({...targetFields, isDisable: true});
            setCoinCurrencyOptions({...coinCurrencyOptions, isDisable: true});
            setCoinSymbolOptions({...coinSymbolOptions, isDisable: true});
        }
        setConditionsFields(newConditionsFields);
    };


    const reset = () => {
        let newConditionsFields = [...conditionsFields];
        newConditionsFields[index]["market"] = null;
        newConditionsFields[index]["symbol"] = null;
        newConditionsFields[index]["currency"] = null;
        setTargetFields({...targetFields, isDisable: true});
        setCoinCurrencyOptions({...coinCurrencyOptions, isDisable: true});
        setCoinSymbolOptions({...coinSymbolOptions, isDisable: true});
        setConditionsFields(newConditionsFields);
    }


    const handleChangeTargetValue = (text) => {
        let newConditionsFields = [...conditionsFields];
        if (selectedCoinData && selectedCoinData.price) {
            if (selectedCoinData.price < parseFloat(text)) {
                newConditionsFields[index]["direction"] = {value: "above", label: "Above"};
            } else {
                newConditionsFields[index]["direction"] = {value: "below", label: "Below"};
            }
        }
        newConditionsFields[index]["value"] = text;
        setConditionsFields(newConditionsFields);
    };

    const handleChangeTargetPercValue = (text) => {
        let newConditionsFields = [...conditionsFields];
        if (selectedCoinData && selectedCoinData.ochngpct) {
            if (selectedCoinData.ochngpct < parseFloat(text)) {
                newConditionsFields[index]["direction"] = {value: "above", label: "Above"};
            } else {
                newConditionsFields[index]["direction"] = {value: "below", label: "Below"};
            }
        }
        newConditionsFields[index]["value"] = text;
        setConditionsFields(newConditionsFields);
    };

    useEffect(() => {
        if (type === "Update") {
            searchSpots("market");
            setTargetFields({...targetFields, isDisable: false});
            setType("Create");
        }else if(type === ""){
            setTargetFields({...targetFields, isDisable: true});
            setCoinCurrencyOptions({...coinCurrencyOptions, isDisable: true});
            setCoinSymbolOptions({...coinSymbolOptions, isDisable: true});
        }
    }, [type])

    useEffect(() => {
        getMarkets();
    }, []);

    useEffect(() => {
        if (conditionsFields[index].market && type === "Create") {
            searchSpots("market");
        }
    }, [conditionsFields[index].market]);

    // Realtime related states and functions
    const [tickerList, setChannel, initSocketConnection] = RealtimeUtils.useRealTimeTicker([], DataMappers.coinTickerMapper);

    const [symbolCcy,setSymbolCcy] = useState({symbol:"",ccy:"",exchanges:"",flag:false})

    // Realtime receivers
    const [ticker, setTicker] = RealtimeUtils.useRealTimeRow(symbolCcy.symbol+"-"+symbolCcy.ccy+"."+symbolCcy.exchanges, setChannel);

    useEffect(()=>{
        if(symbolCcy.symbol !== "" && symbolCcy.ccy !== "" && symbolCcy.exchanges !== "" && type === "Create"){
            // Start real time updates
            initSocketConnection([symbolCcy.symbol+"-"+symbolCcy.ccy+"."+symbolCcy.exchanges]);
            setSymbolCcy({...symbolCcy,flag: false});
        }
    },[symbolCcy.symbol,symbolCcy.ccy,symbolCcy.exchanges])

    useEffect(()=>{
        if(!symbolCcy.flag && ticker.price && type === "Create"){
            let newConditionsFields = [...conditionsFields];
            if( newConditionsFields[index]["targetField"].value === "price"){
                let newPrice = ticker.price.includes(",") ? ticker.price.replace(",","") : ticker.price;
                newConditionsFields[index]["value"] = newPrice || "";
            }else{
                newConditionsFields[index]["value"] = (ticker.ochngpct) || "";
            }
            let newTicker = {...ticker}
            newTicker.price = ticker.price.includes(",") ? ticker.price.replace(",","") : ticker.price;
            setConditionsFields(newConditionsFields);
            setSelectedCoinData(newTicker);
            setSymbolCcy({...symbolCcy,flag: true});
        }
    },[ticker])

    useEffect(() => {
        if (conditionsFields[index].currency && type === "Create") {
            searchSpots("currency");
            if (conditionsFields[index].symbol) {
                setTargetFields({...targetFields, isDisable: false});
                const param = {
                    base_asset: conditionsFields[index].symbol.value,
                    quoted_asset: conditionsFields[index].currency.value,
                    source_code: conditionsFields[index].market.value
                }
                setSymbolCcy({...symbolCcy,symbol:param.base_asset,ccy:param.quoted_asset,exchanges:param.source_code});

            }
        }
    }, [conditionsFields[index].currency]);

    useEffect(()=>{
        if((selectedCoinData.price || selectedCoinData.ochngpct) && type === "Create"){
            let newConditionsFields = [...conditionsFields];
            if( newConditionsFields[index]["targetField"].value === "price"){
                newConditionsFields[index]["value"] = selectedCoinData.price || "";
            }else{
                newConditionsFields[index]["value"] = (selectedCoinData.ochngpct) || "";
            }
            setConditionsFields(newConditionsFields);
        }
    },[conditionsFields[index]["targetField"]])

    useEffect(() => {
        if (conditionsFields[index].symbol && type === "Create") {
            searchSpots("symbol");
        }
    }, [conditionsFields[index].symbol]);

    const getMarkets = () => {
        const params = {};
        Api.marketSearch(params).then(res => {
            let market;
            let options = [];

            res.forEach(el => {
                if (el === "CISCALC") {
                    market = {value: el, label: "CISCALC"}
                    options.push(market);
                }else if (el === "CISAGG") {
                    market = {value: el, label: "CISAGG"}
                    options.push(market);
                } else if(mappedExchanges[el]){
                    market = {value: el, label: mappedExchanges[el].name}
                    options.push(market);
                }
            });
            setMarketOptions(options);
        });
    }

    // Called when one of the search parameters are changed
    const searchSpots = (type) => {
        // Generate search params
        let params = {};
        if (type === "symbol")
            params.base_asset = conditionsFields[index].symbol.value;
        if (type === "currency")
            params.quoted_asset = conditionsFields[index].currency.value;
        if (type === "symbol") {
            let newConditionsFields = [...conditionsFields];
            newConditionsFields[index]["currency"] = null;
            setConditionsFields(newConditionsFields);
            setTargetFields({...targetFields, isDisable: true});
        }
        if (type === "market" || conditionsFields[index].market.value) {
            params.source_code = conditionsFields[index].market.value;
        }

        // Perform search
        Api.spotSearch(params).then(async res => {
            res.data = res.data || [];

            // Set selected fields
            const ba = res.base_assets || [];
            const newBa = ba.map(el => {
                return {value: el, label: el}
            });
            if (type !== "symbol" && type !== "currency") {
                setCoinSymbolOptions({data: newBa, isDisable: false});
            }
            const qa = res.quoted_assets || [];
            const newQa = qa.map(el => {
                return {value: el, label: el}
            });
            if (type !== "currency") {
                setCoinCurrencyOptions({data: newQa, isDisable: false});
            }
        });
    }


    return (
        <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
            <div style={{padding: "5px 0px", display: "flex"}}>,
                <div style={{paddingTop: "10px"}}>
                    <h4 style={{
                        color: "white",
                        fontSize: "16px",
                        padding: "0px 8px"
                    }}>{"Conditions - "+(index+1)+":"}</h4>
                </div>
                <div style={{paddingTop: "10px", marginLeft: "30px"}}>
                    <div style={{padding: "0px", width: "150px"}}>
                        <Select
                            styles={customStyles}
                            value={conditionsFields[index].market}
                            onChange={(e) => onChange("market", e)}
                            options={marketOptions}
                            placeholder="Markets"
                        />
                    </div>
                </div>
                <div style={{paddingTop: "10px", marginLeft: "5px"}}>
                    <div style={{padding: "0px", width: "150px"}}>
                        <Select
                            styles={customStyles}
                            value={conditionsFields[index].symbol}
                            onChange={(e) => onChange("symbol", e)}
                            options={coinSymbolOptions.data}
                            isDisabled={coinSymbolOptions.isDisable}
                            placeholder="Symbol"
                        />
                    </div>
                </div>
                <div style={{paddingTop: "10px", marginLeft: "5px"}}>
                    <div style={{padding: "0px", width: "150px"}}>
                        <Select
                            styles={customStyles}
                            value={conditionsFields[index].currency}
                            onChange={(e) => onChange("currency", e)}
                            options={coinCurrencyOptions.data}
                            isDisabled={coinCurrencyOptions.isDisable}
                            placeholder="Currency"
                        />
                    </div>
                </div>
                <div style={{paddingTop: "10px", marginLeft: "5px"}}>
                    <div style={{padding: "0px", width: "150px"}}>
                        <Select
                            styles={customStyles}
                            value={conditionsFields[index].targetField}
                            onChange={(e) => onChange("targetField", e)}
                            options={targetFields.data}
                            isDisabled={targetFields.isDisable}
                            placeholder="Target Field"
                        />
                    </div>
                </div>
                <div style={{paddingTop: "10px", marginLeft: "5px"}}>
                    <div style={{padding: "0px", width: "150px"}}>
                        <Select
                            styles={customStyles}
                            value={conditionsFields[index].direction}
                            onChange={(e) => onChange("direction", e)}
                            options={directions}
                            isDisabled={targetFields.isDisable}
                            placeholder="Direction"
                        />
                    </div>
                </div>
                {
                    !targetFields.isDisable && conditionsFields[index].targetField.value && conditionsFields[index].targetField.value === "price" &&
                    <div style={{paddingTop: "10px", marginLeft: "5px"}}>
                        <div style={{padding: "0px", width: "150px"}}>
                            <TextField
                                label="Value"
                                margin="normal"
                                variant="outlined"
                                type="number"
                                step="any"
                                onChange={(e) => {
                                    let value = 0;
                                    if (e.target.value > 0) {
                                        value = e.target.value;
                                    }
                                    handleChangeTargetValue(value);
                                }}
                                value={parseFloat(conditionsFields[index].value)}
                                fullWidth
                                disabled={targetFields.isDisable}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {
                                                conditionsFields[index].direction.value === "above" &&
                                                <img height={20} width={20}
                                                     className="actionable-img"
                                                     src="/img/up-arrow.svg" alt=""/>
                                            }
                                            {
                                                conditionsFields[index].direction.value === "below" &&
                                                <img height={20} width={20}
                                                     className="actionable-img"
                                                     src="/img/down-arrow.svg" alt=""/>
                                            }
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                    </div>
                }
                {
                    !targetFields.isDisable && conditionsFields[index].targetField.value && conditionsFields[index].targetField.value === "change_percentage" &&
                    <div style={{paddingTop: "10px", marginLeft: "5px"}}>
                        <div style={{padding: "0px", width: "150px"}}>
                            <TextField
                                label="Value(%)"
                                margin="normal"
                                variant="outlined"
                                type="number"
                                step="any"
                                onChange={(e) => {
                                    let value = 0;
                                    if (e.target.value > 100) {
                                        value = 100;
                                    }else if(e.target.value < -100){
                                        value = -100;
                                    }else{
                                        value = e.target.value;
                                    }
                                    handleChangeTargetPercValue(value);
                                }}
                                value={conditionsFields[index].value}
                                fullWidth
                                disabled={targetFields.isDisable}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {
                                                conditionsFields[index].direction.value === "above" &&
                                                <img height={20} width={20}
                                                     className="actionable-img"
                                                     src="/img/up-arrow.svg" alt=""/>
                                            }
                                            {
                                                conditionsFields[index].direction.value === "below" &&
                                                <img height={20} width={20}
                                                     className="actionable-img"
                                                     src="/img/down-arrow.svg" alt=""/>
                                            }
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                    </div>
                }
                <div style={{paddingTop: "10px"}}>
                    <div style={{padding: "0px", width: "50px", display: "flex"}}>
                        <ReactTooltip html={true}/>
                        <a style={{marginTop: "8px", cursor: "pointer", marginLeft: "5px"}} ><img
                            className="actionable-img"
                            alt=""/><i
                            data-tip={'Reset Condition'}
                            onClick={()=>reset()}
                            className="fas fa-redo-alt" style={{
                            fontSize: "1.5em",
                            color: "#16adcf"
                        }}/></a>
                        {index !== 0 &&
                        <a style={{marginTop: "8px", marginLeft: "5px", cursor: "pointer"}}><img
                            className="actionable-img"
                            alt=""/><i onClick={() => handleDeleteCondition(index)} className="fas fa-minus-circle"
                                       style={{
                                           fontSize: "1.5em",
                                           color: "#c11e0f"
                                       }}  data-tip={'Delete Condition'}/></a>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

const prepareInputProps = state => {

    const mappedExchanges = {};

    state.meta.exchanges.forEach(el=>{
        mappedExchanges[el.code] = el;
    })


    return {
        exchanges: state.meta.exchanges,
        mappedExchanges: mappedExchanges
    };
};

export default connect(prepareInputProps)(CreateAndUpdateConditionModal);
