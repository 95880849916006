import React, {useEffect, useState} from 'react';
import {useAuth0} from "../../helpers/Auth0";
import PortfolioApi from "../../api/PortfolioApi";
import {toastr} from "react-redux-toastr";

const StepUpAuthentication = props => {
    const { checkScope, component, type} = props;
    const { stepUpAuthorize, scopes, configured2fa } = useAuth0();

    const [stateStatus, setStateStatus] = useState(true);

    const [twoFactorType,setTwoFactorType] = useState(true);

    useEffect(()=>{
        if(checkScope){
            PortfolioApi.getPortfolios().then(resp=>{
                setStateStatus(true);
            }).catch(e=>{
                if(e.response && e.response.data && e.response.data.error){
                    setStateStatus(false);
                    if(configured2fa){
                        setTwoFactorType(true);
                    }else{
                        setTwoFactorType(false);
                    }
                }else{
                    toastr.error('', 'Something went wrong.');
                }
            })
        }
    },[checkScope])

    return (
        (stateStatus) ? component :
            twoFactorType ?
                <div style={{height: "80vh", width: "100vw", display: "flex"}}>
                    <section style={{textAlign: 'center', minHeight: "100%", width: "100%", display: "flex", backgroundImage: "url('/img/blur_upgrade_your_plan.png')"}} className="contact-area padding-sm-container">
                        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column' ,margin: "auto", color: '#fff'}} className="contact-form-and-banners">
                            <h4 style={{lineHeight:"1.4"}}>To access this page you need to authenticate your account with second factor. Please click 2FA Authentication to continue...</h4>
                            <h3>
                                <a style={{cursor: "pointer", marginRight: "1em"}} onClick={() => stepUpAuthorize()}>2FA Authentication</a>
                                <a style={{cursor: "pointer", marginLeft: "1em"}} target="_blank" rel="noopener noreferrer" href="https://cryptoindexseries.com">Contact Us</a>
                            </h3>
                        </div>
                    </section>
                </div>
                :
                <div style={{height: "80vh", width: "100vw", display: "flex"}}>
                    <section style={{textAlign: 'center', minHeight: "100%", width: "100%", display: "flex", backgroundImage: "url('/img/blur_upgrade_your_plan.png')"}} className="contact-area padding-sm-container">
                        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column' ,margin: "auto", color: '#fff'}} className="contact-form-and-banners">
                            <h4 style={{width:"850px",textAlign:"center",lineHeight:"1.4"}}>You need to authenticate your current session with second factor. But it seems you did not configure a second factor for your account. To configure a second factor for your account please click Configure 2FA to continue...</h4>
                            <h3>
                                <a style={{cursor: "pointer", marginRight: "1em"}} onClick={() => stepUpAuthorize()}>Configure 2FA</a>
                                <a style={{cursor: "pointer", marginLeft: "1em"}} target="_blank" rel="noopener noreferrer" href="https://cryptoindexseries.com">Contact Us</a>
                            </h3>
                        </div>
                    </section>
                </div>

    );
};

export default StepUpAuthentication;
