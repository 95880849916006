import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import {Auth0Provider} from './helpers/Auth0';
import history from "./helpers/HistoryAccess";
import App from './App';
import {AuthenticationProvider, InMemoryWebStorage, oidcLog, OidcSecure} from '@axa-fr/react-oidc-context';
import Loading from "./components/Loading";
import NotAuthorizedPage from "./pages/NotAuthorizedPage";
import NotificationApi from "./api/NotificationApi/NotificationApi";
import {setReferral} from "./helpers/ReferralHandler";
import Utils from "./helpers/Utils";

// Register a service worker for web push notifications
NotificationApi.registerServiceWorker();

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
    history.push(
        appState && appState.url
            ? appState.url
            : window.location.pathname
    );
};

const Callback = () => {
    return (
      <Loading/>
    );
}
const NotAuthenticated = () => {
    setTimeout(() => {
        window.location.replace(window.location.origin);
    }, 0)

    return (
        <Loading/>
    )
}



// Initialize Google Analytics
const trackingId = "UA-175992732-1";
ReactGA.initialize(trackingId);


// Set referral to local storage
setReferral();

const oidcConfiguration = {
    client_id: Utils.isMarketMonitor().client_id,
    redirect_uri: window.location.origin + '/authentication/callback',
    response_type: 'code',
    scope: 'openid profile email',
    authority: 'https://sso.cryptoindexseries.com/auth/realms/CIS',
    silent_redirect_uri: window.location.origin + '/authentication/silent_callback',
    automaticSilentRenew: true,
    monitorSession: false,
    accessTokenExpiringNotificationTime: 10,
    post_logout_redirect_uri: window.location.origin,
    loadUserInfo: false,
};

ReactDOM.render(
    <AuthenticationProvider
        configuration={oidcConfiguration}
        loggerLevel={oidcLog.ERROR}
        UserStore={InMemoryWebStorage}
        callbackComponentOverride={Callback}
        notAuthorized={NotAuthorizedPage}
        notAuthenticated={NotAuthenticated}
        authenticating={Callback}>
        <OidcSecure>
            <Auth0Provider onRedirectCallback={onRedirectCallback}>
                <App />
            </Auth0Provider>
        </OidcSecure>
    </AuthenticationProvider>
 , document.getElementById('root'));

