import React, {useState, useEffect} from 'react';
import CoinRow from '../../../components/CoinRow';
import SetWatchListModal from '../../Modals/SetWatchListModal';
import Loading from '../../Loading';
import {withRouter} from 'react-router-dom';

const StateBar = props => {
    const {row, selectedExchange, history, setShowCU, setUpdated, setType} = props;
    const [show, setShow] = useState(false);
    const [add, setAdd] = useState(false);
    const [rowChangeLoad, setRowChangeLoad] = useState(true);

    useEffect(() => {
        setRowChangeLoad(true); // socket yüzünden öldürmek zorundayım ortalık karısıyor :)
        setTimeout(() => {
            if (row.symbol) setRowChangeLoad(false);
        }, 400);
    }, [row]);

    return (
        <div className="state-bar">
            <Loading Loading={rowChangeLoad}>
                {!rowChangeLoad && (
                    <CoinRow type={'stateBar'} rowClick={() => null} setAdd={setAdd} setShow={setShow} row={row}
                             selectedExchange={selectedExchange} setShowCU={setShowCU} setShow={setShow} setAdd={setAdd}
                             setUpdated={setUpdated}
                             setType={setType}/>
                )}
            </Loading>
            <SetWatchListModal modalShow={show} closeModal={() => setShow(false)} add={add}/>
        </div>
    );
};

StateBar.defaultProps = {
    exchangeList: [],
};

export default withRouter(StateBar);
