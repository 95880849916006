import React, {useState} from 'react';
import moment from 'moment';
import SummaryModal from "../../../pages/News/SummaryModal";
import {useAuth0} from "../../../helpers/Auth0";
import Select from "../../Forms/Select";
import Loading from "../../Loading";
import DataMappers from "../../../helpers/DataMappers";

// Sentiment bubble generator
const sentimentStyle = (value) => {
    return {
        borderRadius: "50%",
        width: "3em",
        height: "3em",
        fontSize: "12px",
        lineHeight: "3em",
        textAlign: "center",
        margin: "0.5vh 0 0.5vh 0",
        color: "white",
        fontWeight: "bold",
        background: DataMappers.mapSentimentColor(value)
    }
};

const PositiveNews = (props) => {
    const {news, selectedOption , setSelectedOption, allOptions} = props;
    const [show, setShow] = useState(false);
    const [item, setItem] = useState({});
    const {user} = useAuth0();

    return (
        <div className="latest-news">
            <div style={{display:"flex",justifyContent:"flex-end"}}>
                <div style={{display:"flex"}}>
                    <div className="input-title news" style={{marginTop:"0.3vh"}}>Timeframe:</div>
                    <div className="sm-select-input">
                        <Select
                            optionValue={'time'}
                            optionLabel={'label'}
                            selected={selectedOption.time || 0}
                            options={allOptions || []}
                            onChange={selected => setSelectedOption(selected)}
                        />
                    </div>
                </div>
            </div>
            <div className="news-list" id="beauty-scroll3">
                    <div className="table-body table-responsive" id="beauty-scroll7" tabIndex={1} style={{overflow: 'hidden', outline: 'none'}}>
                        {news && news.length && <table className="coins enumarable-table news-table-responsive linked-table">
                            <thead>
                            <tr>
                                <th style={{width: '130px',textAlign: 'left'}}>Date</th>
                                <th style={{width: '100px',textAlign: 'left'}}>Source</th>
                                <th style={{textAlign: 'left'}}>Title</th>
                                <th style={{textAlign: 'left'}}>Sentiment</th>
                            </tr>
                            </thead>
                            <tbody>
                            {news.map((d, i) => {
                                const date = moment.utc(d.publication_datetime).local();
                                const sentimentPref = user.preferences.sentiment_field;
                                const source = d.source.split("_").map(word =>  word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
                                let sentimentPrefValue = 0;

                                if(sentimentPref === "title_sentiment" && d.sentiment){
                                    sentimentPrefValue = d.sentiment.title;
                                }else if(sentimentPref === "summary_sentiment" && d.sentiment){
                                    sentimentPrefValue = d.sentiment.summary;
                                }else if(sentimentPref === "content_sentiment" && d.sentiment){
                                    sentimentPrefValue = d.sentiment.content;
                                }
                                const perc = (d.sentiment && parseInt(sentimentPrefValue * 100, 10)) || 0;
                                return (
                                    <tr onClick={() => {setShow(true); setItem(d);}} key={i}>
                                        <td style={{textAlign: 'left'}}>
                                            <div className="date">{date.format('DD MMM YYYY')}</div>
                                            <div className="hour">{date.format('HH:mm:ss')}</div>
                                        </td>
                                        <td style={{textAlign: 'left'}}>
                                            <a>{source}</a>
                                        </td>
                                        <td style={{textAlign: 'left'}}>
                                            <div className="text">{d.title}</div>
                                        </td>
                                        <td style={{textAlign: 'left', display: "flex", flexDirection: "row", justifyContent: "center"}}>
                                            <div style={sentimentStyle(sentimentPrefValue)}>
                                                {perc}%
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>}
                        {news && !news.length && <p style={{color: "white"}}>No news found for the given filters...</p>}
                    </div>
            </div>
            <SummaryModal show={show} item={item} userPref={user.preferences.sentiment_field} cancel={() => setShow(false)}/>
        </div>
    );
};

export default PositiveNews;
