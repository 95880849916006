import ReactGA from "react-ga";
import {useRef} from "react";
import {useAuth0} from "../../helpers/Auth0";

// Component for tracking router changes
const GoogleAnalytics = (props) => {
    const {isAuthenticated, user} = useAuth0();

    // Keep record of last page click
    const lastPath = useRef("");
    // Keep record of active user ID
    const lastUserId = useRef("");

    // If path is empty and user is authenticated, set path to dashboard
    const path = window.location.pathname === "/" && isAuthenticated ? "/dashboard" : window.location.pathname;

    // Update state if not equal and sent, detail pages are sent by themselves
    if (lastPath.current !== path && path !== "/" && !path.endsWith("detail")) {
        lastPath.current = path;
        ReactGA.pageview(lastPath.current);
    }

    // New user id
    const userId = user && user.sub;

    // Send new user id if changed
    if (userId !== lastUserId.current) {
        lastUserId.current = userId;
        // Set userID for the session (probably will not be used)
        ReactGA.set({userId: lastUserId.current});
    }

    return null;
};

export default (GoogleAnalytics);


