import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import Api from "../../api";
import Loading from "../Loading";
import ReactTooltip from "react-tooltip";
import AppHelpers from "../../helpers/AppHelpers";
import {Pie} from "react-chartjs-2";

const legend = {
    display: true,
    position: 'right',
    fullWidth: true,
    reverse: false,
    labels: {
        boxWidth: 15,
        fontColor: "white",
        fontSize: 15
    }
}

const colors = [
    '#1f5c94',
    '#68bbe3',
    '#1b4b9d',
    '#1d2e51',
    '#7267c2',
    '#3d3c49',
    '#603f8b',
    '#493979',
    '#385b4f',
    '#1c938b',
    '#224a51',
    '#1c2143',
    '#282854',

]

const VolumeAnalysis = (props) => {
    // Props selected coin and ccy , and get exchanges from metadata
    const {coinRow, ccy, exchanges} = props;

    // Pair volume
    const [volumeByPair,setVolumeByPair] = useState({data:[],loading:true});

    // Exchange volume
    const [volumeByExchange,setVolumeByExchange] = useState({data:[],loading:true});

    //Asset Prices
    const [assetPrices,setAssetPrices] = useState({});

    //Pie Chart Data
    const [pieData,setPieData] = useState({pairPie:{},exchangePie:{}})


    useEffect(()=>{
        setVolumeByExchange({data:[],loading:true});
        setVolumeByPair({data:[],loading:true});

        // Generate search params
        const params = {
            page_number: 1,
            base_asset: coinRow.symbol,
            is_exchange: true,
        };

        Api.spotSearch(params, 100).then(async res => {
            res.data = res.data || [];
            let pairs = {};
            let exchanges = {};
            let assets = {};

            if(res.pairs){
                // Map pairs with volume
                res.pairs.forEach(el=>{
                    pairs[el] = 0;
                });
                // Map exchanges with volume
                res.exchanges.forEach(el=>{
                    exchanges[el] = 0;
                })
            }

            let param = {};

            if(res.quoted_assets){
                param.assets = res.quoted_assets.join();
                param.ccy = ccy;
                Api.getAssetPrices(param).then(resp=>{
                    let assetPrice = {};

                    resp.data.forEach(el=>{
                        assetPrice[el[0]] = el[2];
                    })

                    res.data.forEach(el=>{
                        if(el.ticker && el.ticker.vto){
                            pairs[el.pair] = pairs[el.pair] + (el.ticker.vto * assetPrice[el.quoted_asset]);
                            exchanges[el.source_code] = exchanges[el.source_code] + (el.ticker.vto * assetPrice[el.quoted_asset]);
                        }
                    })

                    let pairsArray = [];
                    let exchangeArray = [];

                    Object.keys(pairs).map(function(key, index) {
                        pairsArray.push({pair:key,volume:pairs[key]});
                    })

                    Object.keys(exchanges).map(function(key, index) {
                        exchangeArray.push({exchange:key,volume:exchanges[key]});
                    })

                    pairsArray = pairsArray.filter(el=>el.volume && el.volume !== 0).sort((a, b) => (a.volume < b.volume) ? 1 : -1);

                    exchangeArray = exchangeArray.filter(el=>el.volume && el.volume !== 0).sort((a, b) => (a.volume < b.volume) ? 1 : -1);

                    let totalPairsVolume = 0;
                    let totalExchangeVolume = 0;

                    pairsArray.forEach(el=>{
                        totalPairsVolume += el.volume;
                    })

                    exchangeArray.forEach(el=>{
                        totalExchangeVolume += el.volume;
                    })

                    pairsArray.forEach(el=>{
                        el.perc = ((el.volume / totalPairsVolume) * 100).toFixed(2);
                    })

                    exchangeArray.forEach(el=>{
                        el.perc = ((el.volume / totalExchangeVolume) * 100).toFixed(2);
                    })

                    setVolumeByPair({data:pairsArray,loading: false});

                    setVolumeByExchange({data:exchangeArray,loading: false});

                    arrangePieChart(pairsArray,exchangeArray);

                });
            }else{
                setVolumeByPair({data:[],loading: false});

                setVolumeByExchange({data:[],loading: false});
            }

        });
    },[ccy]);

    const arrangePieChart = (pair,exchange) =>{

        let exchangePie = {};
        let pairPie = {};

        if(exchange.length <= 5){
            exchangePie = {
                labels: exchange.map(el => {
                    return el.exchange;
                }),
                datasets: [{
                    data: exchange.map(el => {
                        return el.perc;
                    }),
                    backgroundColor: colors,
                    hoverBackgroundColor: colors,
                    borderColor: colors,
                    borderWidth: 1,
                }]
            };
        }else{

            // Get the ones that have impact larger than 20%
            const firstSlice = exchange.filter(el => el.perc >= 20);

            let holdingsLabel = firstSlice.map(el => {
                return el.exchange;
            });

            holdingsLabel.push("Others");

            // first slice sum of perc
            let firstSliceTotalPerc = 0;
            let holdingsData = firstSlice.map(el => {
                firstSliceTotalPerc += parseFloat(el.perc);
                return el.perc;
            })

            holdingsData.push((100 - firstSliceTotalPerc).toFixed(2).toString());

            exchangePie = {
                labels: holdingsLabel,
                datasets: [{
                    data: holdingsData,
                    backgroundColor: colors,
                    hoverBackgroundColor: colors,
                    borderColor: colors,
                    borderWidth: 1,
                }]
            };
        }

        if (pair.length <= 5) {
            pairPie = {
                labels: pair.map(el => {
                    return el.pair;
                }),
                datasets: [{
                    data: pair.map(el => {
                        return el.volume.toFixed(2);
                    }),
                    backgroundColor: colors,
                    hoverBackgroundColor: colors,
                    borderColor: colors,
                    borderWidth: 1,
                }]
            };

        }else{


            // Get the ones that have impact larger than 5%
            const firstSlice =  pair.filter(el => el.perc >= 9);

            let holdingsLabel = firstSlice.map(el => {
                return el.pair;
            });

            holdingsLabel.push("Others");

            // first slice sum of perc
            let firstSliceTotalPerc = 0;
            let holdingsData = firstSlice.map(el => {
                firstSliceTotalPerc += parseFloat(el.perc);
                return el.perc;
            })

            holdingsData.push((100 - firstSliceTotalPerc).toFixed(2).toString());
            pairPie = {
                labels: holdingsLabel,
                datasets: [{
                    data: holdingsData,
                    backgroundColor: colors,
                    hoverBackgroundColor: colors,
                    borderColor: colors,
                    borderWidth: 1,
                }]
            };
        }

        setPieData({pairPie: pairPie, exchangePie: exchangePie});
    }

    const getExchangeName = (code) => {
        let marketLabel = "";
        if(code === "CISCALC"){
            marketLabel = "Crypto Index Series"
        }else{
            exchanges.forEach(exel => {
                if (code === exel.code) {
                    marketLabel = exel.name;
                    return;
                }
            });
        }
        return marketLabel;
    }

    return (
        <div className={`gray-container`} style={{borderTopLeftRadius:"0px",borderTopRightRadius:"0px"}}>
            <div className="row">
                <div className="col-lg-4 col-sm-4">
                    <div style={{color:"white", fontSize:"17px",marginBottom:"10px"}}>Volume By Pair</div>
                    {volumeByPair.data.length > 0  && <Pie data={pieData.pairPie} legend={legend}/>}
                    <div className="table-body table-responsive" id="beauty-scroll7">
                        <Loading loading={volumeByPair.loading}>
                            <React.Fragment>
                                <ReactTooltip html={true}/>
                                <table className="coins enumarable-table coins-menu-table linked-table">
                                    <thead>
                                    <tr>
                                        <th>Pair</th>
                                        <th>Volume ({ccy})</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {volumeByPair.data.length > 0 ?
                                        volumeByPair.data.map((key, index)=> {
                                            if(key.volume && key.volume !== 0){
                                                return(
                                                    <tr key={index}>
                                                        <td style={{textAlign: 'left'}}>
                                                            <div className="text">{key.pair}</div>
                                                        </td>
                                                        <td style={{textAlign: 'left'}}>
                                                            <div className="text">{AppHelpers.numberFormat(key.volume)}</div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        }):
                                        <tr>
                                            <td colSpan={2}
                                                style={{textAlign: "left", color: "fff", fontSize: "14px", padding: "7px 5px",fontWeight:"normal",cursor:"auto"}}>
                                                There is no data.
                                            </td>
                                        </tr>

                                    }
                                    </tbody>
                                </table>
                            </React.Fragment>
                        </Loading>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-4">
                    <div style={{color:"white", fontSize:"17px",marginBottom:"10px"}}>Volume By Exchange</div>
                    {volumeByExchange.data.length > 0  && <Pie data={pieData.exchangePie} legend={legend}/>}
                    <div className="table-body table-responsive" id="beauty-scroll7">
                        <Loading loading={volumeByExchange.loading}>
                            <React.Fragment>
                                <ReactTooltip html={true}/>
                                <table className="coins enumarable-table coins-menu-table linked-table">
                                    <thead>
                                    <tr>
                                        <th>Exchange</th>
                                        <th>Volume ({ccy})</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {volumeByExchange.data.length > 0 ?
                                        volumeByExchange.data.map((key, index)=> {
                                            if(key.volume && key.volume !== 0){
                                                return(
                                                    <tr key={index}>
                                                        <td style={{textAlign: 'left'}}>
                                                            <div className="text">{getExchangeName(key.exchange)}</div>
                                                        </td>
                                                        <td style={{textAlign: 'left'}}>
                                                            <div className="text">{AppHelpers.numberFormat(key.volume)}</div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        })
                                        :
                                        <tr>
                                            <td colSpan={2}
                                                style={{textAlign: "left", color: "fff", fontSize: "14px", padding: "7px 5px",fontWeight:"normal",cursor:"auto"}}>
                                                There is no data.
                                            </td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                            </React.Fragment>
                        </Loading>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-4">
                    <div style={{color:"white", fontSize:"17px",marginBottom:"10px"}}>Volume By Asset / Currency</div>
                    {volumeByPair.data.length > 0  && <Pie data={pieData.pairPie} legend={legend}/>}
                    <div className="table-body table-responsive" id="beauty-scroll7">
                        <Loading loading={volumeByPair.loading}>
                            <React.Fragment>
                                <ReactTooltip html={true}/>
                                <table className="coins enumarable-table coins-menu-table linked-table">
                                    <thead>
                                    <tr>
                                        <th>Asset</th>
                                        <th>Volume ({ccy})</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {volumeByPair.data.length > 0 ?
                                        volumeByPair.data.map((key, index)=> {
                                            if(key.volume && key.volume !== 0){
                                                return(
                                                    <tr key={index}>
                                                        <td style={{textAlign: 'left'}}>
                                                            <div className="text">{key.pair.split("/")[1]}</div>
                                                        </td>
                                                        <td style={{textAlign: 'left'}}>
                                                            <div className="text">{AppHelpers.numberFormat(key.volume)}</div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        })
                                        :
                                        <tr>
                                            <td colSpan={2}
                                                style={{textAlign: "left", color: "fff", fontSize: "14px", padding: "7px 5px",fontWeight:"normal",cursor:"auto"}}>
                                                There is no data.
                                            </td>
                                        </tr>

                                    }
                                    </tbody>
                                </table>
                            </React.Fragment>
                        </Loading>
                    </div>
                </div>
            </div>

        </div>
    );
};


const mapStateToProps = state => {
    return {
        meta: state.meta,
        exchanges: state.meta.exchanges
    };
};

export default connect(mapStateToProps)(VolumeAnalysis);
