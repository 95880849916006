import React from 'react';
import {Link} from "react-router-dom";
import {useAuth0} from "../../helpers/Auth0";



const MobileNavBar = () => {
    const {logout} = useAuth0();



    return (
        <div>
            <div>
                <nav className="navbar navbar-default navbar-fixed-top">
                    <div className="container">
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                                    data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            <div className="mobile-logo-text">
                                <img height="50px" width="50px" src="/img/logo.svg" alt="logo"/>
                                <img height="50px" width="175px" src="/img/logo-text.svg" alt="logo-text"/>
                            </div>
                        </div>
                        <div id="navbar" className="navbar-collapse collapse">
                            <ul className="nav navbar-nav">
                                <li><Link to={'/watch-list'}>Watchlists</Link></li>
                                <li><Link to={'/profile'}>Settings</Link></li>
                                {/*<li><a href="#contact">Account</a></li>*/}
                                <li>
                                    <a
                                    onClick={() => {
                                        logout();
                                    }}>
                                    Logout
                                    </a>
                                </li>
                            </ul>
                        </div>

                    </div>
                </nav>
            </div>
            <div className="info-mobile-app-div">
                <p>We provide more data, analytics and features in desktop</p>
            </div>
        </div>


    );

};

export default MobileNavBar;
