import {
    META_DATA,
    GET_ALL_COINS,
    CLEAR_META_DATA,
    NEWS_TAGS,
    GET_EXCHANGES,
    GET_CONNECTABLE_EXCHANGES
} from "../actions/types"

let INITIAL_STATE = {
    lists: {},
    allCoins: [],
    newsTags: {},
    exchanges: [],
}


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case META_DATA:
            return {
                ...state,
                lists: action.value
            }
        case NEWS_TAGS:
            return {
                ...state,
                newsTags: action.value
            }
        case GET_ALL_COINS:
            return {
                ...state,
                allCoins: action.value
            }
        case GET_EXCHANGES:
            return {
                ...state,
                exchanges: action.value
            }
        case GET_CONNECTABLE_EXCHANGES:
            return {
                ...state,
                connectable_exchanges: action.value
            }
        case CLEAR_META_DATA:
            return {
                ...state,
                lists: null,
                allCoins: null
            }
        default:
            return {
                ...state
            }
    }
}
