import {connect} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import RealtimeUtils from "../../helpers/RealtimeUtils";
import Select from "../../components/Forms/Select";
import {withRouter} from "react-router-dom";
import DataMappers from "../../helpers/DataMappers";
import {useAuth0} from "../../helpers/Auth0";


const InstrumentOverview = props => {
    const {
        selectedSpot, // Selected spot item to show
        selectedDropDown,
        history, // Used for navigation
        userAccounts, // Exchange accounts
        selectedAccount, // Selected exchange account
        setSelectedAccount, // Called when selected account is changed,
        setSelectedPrice, // Used to send latest price to buy/sell input
        setSelectedDropDown,
    } = props

    // State variables
    const [accountOptions, setAccountOptions] = useState([]);

    // Realtime related states and functions
    const [tickerList, setChannel, initSocketConnection] = RealtimeUtils.useRealTimeTicker([], DataMappers.spotTickerMapper);

    // Ticker symbol to subscribe
    const tickerSymbol = selectedSpot.cis_symbol;
    // Realtime receivers
    const [ticker, setTicker] = RealtimeUtils.useRealTimeRow(tickerSymbol, setChannel);

    // Indicates if  realtime price sent once
    let isRTSend = useRef(false);

    useEffect(() => {
        if(selectedSpot) {
            // Send last price again  on spot change
            isRTSend.current = false;
            // Set ticker data from spot data
            setTicker(selectedSpot.ticker);
            // Start real time updates
            initSocketConnection([tickerSymbol]);
        }
    }, [selectedSpot])


    // Need to carry latest price to buy/sell input for initial price
    useEffect(() => {
        if (!isRTSend.current && ticker.is_rt && ticker.price) {
            setTimeout(() => {
                setSelectedPrice(parseFloat(ticker.price.replace(",", "")));
                isRTSend.current = true;
            }, 200)
        }
    }, [ticker]);

    useEffect(() => {
        // Find possible accounts
        const possibleAccounts = userAccounts.filter(el => el.exchange === selectedSpot.exchange_id);
        setAccountOptions(possibleAccounts);

        // Set selected account as first item and set options
        if (!selectedAccount || !possibleAccounts.some(el => el.key_id === selectedAccount.key_id)) {
            setSelectedAccount(possibleAccounts[0]);
        }
    }, [userAccounts, selectedSpot]);

    const openDropDown = () => {
        if(selectedDropDown){
            setSelectedDropDown(false);
        }else{
            setSelectedDropDown(true);
        }

    }

    // 2FA related parameters
    const {configured2fa, sessionHas2FA, stepUpAuthorize} = useAuth0();

    // Return what should we write If there is no accounts/credentials
    const getInformationButton = () => {
        if (sessionHas2FA) {
            return  <a style={{color: "white", cursor: "pointer", textDecoration: "underline"}} onClick={() =>
                history.push({
                    pathname: '/exchanges',
                    state: {exchange: selectedSpot.exchange_id}
                })
            }>Connect Now</a>
        } else if (configured2fa) {
            return <a style={{color: "white", cursor: "pointer", textDecoration: "underline"}} onClick={() => stepUpAuthorize()}>Login with 2FA</a>
        } else {
            return <a style={{color: "white", cursor: "pointer", textDecoration: "underline"}} onClick={() => stepUpAuthorize()}>Configure 2FA</a>
        }
    }

    return (
        <div className={"state-bar"} style={{paddingTop:"0px",paddingBottom:"0px"}}>
            <div className="row row-center">
                <div className={"col-lg-2"} style={{paddingRight:"0px",width:"15%",background:"black"}}>
                    <div className="grid-group mobile-state-bar" id="grid-group-statebar-2" style={{width: "100%"}}>
                        <div onClick={openDropDown} className={"row grid-group-item"} id="grid-group-first-item"  style={{"marginLeft" : "0.5em"}}>
                            <div className={"col-lg-10"}>
                                <div className="secondary-text">
                                    INSTRUMENT
                                </div>
                                <div className={`primary-text primary-value`}>
                                    {selectedSpot.base_asset} / {selectedSpot.quoted_asset}
                                </div>
                            </div>
                            <div style={{color:"white",paddingTop:"10px"}} className={"col-lg-2"}>
                                { !selectedDropDown ? <i className="fas fa-caret-down fa-2x"></i>:<i className="fas fa-caret-up fa-2x"></i>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"col-lg-10"} style={{paddingLeft:"0px",width:"85%",paddingRight:"0px"}}>
                    <div className="grid-group mobile-state-bar" id="grid-group-statebar-2" style={{width: "100%"}}>
                        <div className={"row grid-group-item"}>
                            <div className="secondary-text">
                                EXCHANGE
                            </div>
                            <div className={`primary-text primary-value`}>
                                {selectedSpot.exchange_name}
                            </div>
                        </div>
                        <div className={"row grid-group-item"}>
                            <div className="secondary-text">
                                PRICE ({selectedSpot.quoted_asset})
                            </div>
                            <div className={`primary-text primary-value ${ticker.price_update_class}`}>
                                {ticker.price}
                            </div>
                        </div>
                        <div className={"row grid-group-item"}>
                            <div className="secondary-text">
                                24h LOW ({selectedSpot.quoted_asset})
                            </div>
                            <div className={`primary-text primary-value ${ticker.low_update_class}`}>
                                {ticker.low}
                            </div>
                        </div>
                        <div className={"row grid-group-item"}>
                            <div className="secondary-text">
                                24h HIGH ({selectedSpot.quoted_asset})
                            </div>
                            <div className={`primary-text primary-value ${ticker.high_update_class}`}>
                                {ticker.high}
                            </div>
                        </div>
                        <div className={"row grid-group-item"}>
                            <div className="secondary-text">
                                24h VOL TO
                            </div>
                            <div className={`primary-text primary-value ${ticker.vol24_update_class}`}>
                                {ticker.vol24}
                            </div>
                        </div>
                        <div className={"row grid-group-item"}>
                            <div className="secondary-text">
                                24h VOL
                            </div>
                            <div className={`primary-text primary-value ${ticker.v_update_class}`}>
                                {ticker.v}
                            </div>
                        </div>
                        <div className={"row grid-group-item"} id="mobile-change-indices">
                            <div className="secondary-text">
                                CHG (24H)
                            </div>
                            <div className={`primary-text primary-value ${ticker.chng_update_class} ${ticker.chng_class}`}>
                                {ticker.chng}
                            </div>
                        </div>
                        <div className={"row grid-group-item"} id="mobile-change-indices">
                            <div className="secondary-text">
                                CHG PCT(24H)
                            </div>
                            <div className={`primary-text primary-value ${ticker.chngpct_update_class} ${ticker.chngpct_class}`}>
                                {ticker.chngpct}
                            </div>
                        </div>
                        <div className={"row grid-group-item"} style={{marginLeft: "auto", marginRight: "5em"}} id="mobile-change-indices">
                            <div className="secondary-text" style={{marginLeft: "5px"}}>
                                EXCHANGE ACCOUNT
                            </div>
                            <div className={`primary-text primary-value ${ticker.chngpct_update_class} ${ticker.chngpct_class}`} style={{fontSize: accountOptions.length > 0 ? "13px" : ""}}>
                                <div className="sm-select-input">
                                    {accountOptions.length > 0 ?
                                        <Select
                                            selected={(selectedAccount && selectedAccount.key_id) || 0}
                                            optionValue={"key_id"}
                                            optionLabel={"key_name"}
                                            options={accountOptions || []}
                                            onChange={selected => setSelectedAccount(selected)}/> :
                                        getInformationButton()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}

const prepareInputProps = state => {
    return {
        exchanges: state.meta.connectable_exchanges
    };
}

export default connect(prepareInputProps)(withRouter(InstrumentOverview));
