
// Path definitions
import Links from "../../constants/Links";
import Api from "../index";

const USER_PATH = "/user"

const AccountApi = {
    getPromise(endpoint, params) {
        return Api.getPromise(endpoint, params, Links.ACCOUNT_API_URL)
    },
    postPromise(endpoint, body) {
        return Api.postPromise(endpoint, body, Links.ACCOUNT_API_URL)
    },
    postMPPromise(endpoint, body){
        return Api.postMPPromise(endpoint, body, Links.ACCOUNT_API_URL)
    },
    putPromise(endpoint, body) {
        return Api.putPromise(endpoint, body, Links.ACCOUNT_API_URL)
    },
    deletePromise(endpoint, params) {
        return Api.deletePromise(endpoint, params, Links.ACCOUNT_API_URL)
    },

    /**
     * Reads user profile
     */
    getUserProfile() {
        return this.getPromise(USER_PATH+"/GetUserProfile");
    },

    /**
     * Reads user organization invites
     */
    getUserOrgInvites() {
        return this.getPromise(USER_PATH+"/GetUserOrgInvites");
    },

    /**
     * Reads user subscriptions
     */
    getUserSubscriptions() {
        return this.getPromise(USER_PATH+"/GetUserSubscriptions");
    },

    /**
     * Reads user organisation products
     */
    getUserOrganisationProducts() {
        return this.getPromise(USER_PATH+"/GetUserOrganisationProducts");
    },

    /**
     * Reads user organisation info
     */
    getUserOrganisationInfo() {
        return this.getPromise(USER_PATH+"/GetUserOrganisationInfo");
    },

    /**
     * Updates user profile
     *
     * @param newProfile Updates
     */
    updateUserProfile(newProfile) {
        return this.putPromise(USER_PATH+"/UpdateUserProfile", newProfile);
    },

    /**
     * Updates user socials
     *
     * @param newSocial Updates
     */
    updateUserSocial(newSocial) {
        return this.putPromise(USER_PATH+"/UpdateUserSocial", newSocial);
    },

    /**
     * Updates user preferences
     *
     * @param newSocial Updates
     */
    updateUserPreferences(newPreferences) {
        return this.putPromise(USER_PATH+"/UpdateUserPreferences", newPreferences);
    },

    /**
     * Accept organization invite
     *
     * @param organizationInviteId includes organization id
     */
    acceptUserOrgInvite(organizationInviteId) {
        return this.putPromise(USER_PATH+"/AcceptUserOrgInvite", organizationInviteId);
    },

    /**
     * Decline organization invite
     *
     * @param organizationInviteId includes organization id
     */
    declineUserOrgInvite(organizationInviteId) {
        return this.putPromise(USER_PATH+"/DeclineUserOrgInvite", organizationInviteId);
    },

    /**
     * Leave organization
     *
     * @param organizationInviteId includes organization id
     */
    leaveOrg(organizationInviteId) {
        return this.putPromise(USER_PATH+"/LeaveOrg", organizationInviteId);
    },

    /**
     * Update user account info
     *
     * @param newUserAccountInfo includes user account info
     */
    updateUserAccountInfo(newUserAccountInfo) {
        return this.putPromise(USER_PATH+"/UpdateUserAccountInfo", newUserAccountInfo);
    },

    /**
     * Initialize user account
     *
     * @param params includes user account info
     */
    initializeUserAccount(params) {
        return this.postPromise(USER_PATH+"/InitializeUserAccount", params);
    },





}

export default AccountApi;

