import React from 'react';

const Confirm = props => {
  const {title, message, ok, cancel, show} = props;

  return (
    <div className={`general-modal ${(show && 'active') || ''}`} id="delete-modal-wlitem">
      <div className="vAlignWr">
        <div className="vAlign">
          <div className="white-modal-inner set-alert">
            <div className="modal-blue-header">
              {title}
              <div onClick={cancel} className="close-modal" data-target="delete-modal-wlitem">
                <img src="/img/close-white.svg" alt="" />
              </div>
            </div>
            <div className="modal-white-body">
              <div className="section-header">{message}</div>
            </div>
            <div className="modal-gray-footer">
              <div className="form-buttons">
                <div className="form-flex">
                  <div className="form-flex-inner">
                    <div className="light-gray-btn">
                      <button
                        onClick={() => {
                          cancel();
                        }}
                        className="close-modal"
                        data-target="delete-modal-wlitem">
                        NO
                      </button>
                    </div>
                  </div>
                  <div className="form-flex-inner">
                    <div className="light-blue-btn">
                      <button
                        onClick={() => {
                          ok();
                        }}>
                        YES
                      </button>
                    </div>
                  </div>
                </div>
                <div className="clearfix" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Confirm.defaultProps = {
  ok: () => null,
  cancel: () => null,
  title: 'Delete',
  message: 'Are you sure to delete this watchlist item?',
  show: false,
};

export default Confirm;
