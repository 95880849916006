import Api from "../index";
import Links from "../../constants/Links";

const SocialApi = {
    getPromise(endpoint, params) {
        return Api.getPromise(endpoint, params, Links.BASE_SOCIAL_URL)
    },
    postPromise(endpoint, body) {
        return Api.postPromise(endpoint, body, Links.BASE_SOCIAL_URL)
    },
    deletePromise(endpoint, params) {
        return Api.deletePromise(endpoint, params, Links.BASE_SOCIAL_URL)
    },

    /**
     * Get social media information for given links
     *
     * @param links Links to be checked
     */
    getSocialInformation(link) {
        // Create a url search params from links
        const params = new URLSearchParams();
        params.append("link", link);

        return this.getPromise("", params).then(res => {
            return res;
        }).catch(err => {
            console.error("Error while getting links...", err);
            return [];
        });
    }
};

export default SocialApi;
