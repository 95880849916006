import React, {useEffect, useRef, useState} from "react";
import {withRouter} from "react-router-dom";
import Loading from "../Loading";



const MovingAverageTable = props => {

    const {movingAvarages} = props;

    return (
        <React.Fragment>
            <div style={{color: "white", fontSize: "18px"}}>
                <div className="table-head">
                    <div className="table-left">
                        <div className="table-header">MOVING AVERAGES</div>
                    </div>
                    <div className="clearfix"/>
                </div>
                <Loading loading = {movingAvarages.loading}>
                    <div className="table-body table-responsive" id="beauty-scroll7" tabIndex={1}
                         style={{overflow: 'hidden', outline: 'none'}}>
                        <table className="coins enumarable-table indices-table linked-table">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Value(s)</th>
                                {/*<th>Action</th>*/}
                            </tr>
                            </thead>
                            <tbody>
                            {
                                movingAvarages.data.length > 0 ? movingAvarages.data.map((el,i)=>{
                                        const parameters = Object.values(el.parameters);


                                        return(
                                            <tr key={i} style={{cursor:"auto"}}>
                                                <td style={{color: '#fff',padding:"8px 5px"}}>{el.indicator_name+"("+parameters.join()+")"}</td>
                                                <td style={{color: '#fff',padding:"8px 5px"}}>
                                                    <div style={{display:"flex",flexDirection:"column"}}>
                                                        {
                                                            el.fields.map((fld,i)=>{
                                                                if(el.fields && el.fields.length === 1){
                                                                    return(
                                                                        <div key={i}>
                                                                            {
                                                                                el.data ? el.data[i] : "-"
                                                                            }
                                                                        </div>
                                                                    )
                                                                }else{
                                                                    return(
                                                                        <div key={i}>
                                                                            {
                                                                                fld + " : " + (el.data ? el.data[i] : "-")
                                                                            }
                                                                        </div>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                </td>
                                                {/*<td style={{color: '#fff',padding:"8px 5px"}}></td>*/}
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan={2}>
                                            There is no indicator for this profile
                                            {/*This coin does not have the price values. The technical indicators will be available as soon as it is priced in a supported exchange.*/}
                                        </td>
                                    </tr>

                            }
                            </tbody>
                        </table>
                    </div>
                </Loading>
            </div>
        </React.Fragment>

    );
};

export default withRouter(MovingAverageTable);
