/**
 *  Contains hooks and helpers for easily integrating RealTime updates on components.
 */

import {useEffect, useRef, useState} from "react";
import DataMappers from "./DataMappers";
import SocketHelpersNew from "./SocketHelpersNew";
import Set from "sorted-set";

const RealtimeUtils = {
    /**
     * Provides variables and methods for integrating real time ticker updates. Use this on parent
     * component e.g Table holder component; returns three state related variables;
     *      - setChannel => Channel for setting subscribing to ticker data
     *      - tickerList => List of active ticker symbols
     *      - initSocketConnection => Call this with list of tickers symbols (without type e.g. ~TICKER) to use
     *
     *  See also useRealTimeRow in this class
     *
     *  @param disabledColumns These columns (ticker fields) are disabled on comparison
     *  @param tickerMapper Ticker mapper used for this real time ticker
     *  @param type Type of the ticker (e.g TICKER, TRADE...)
     */
    useRealTimeTicker(disabledColumns, tickerMapper, type='TICKER') {
        // Ticker map that holds messages for each ticker
        const tickerChannels = useRef({});
        // Track current ticker list
        const [tickerList, setTickerList] = useState([]);
        // Accessible ticker list
        const tickerRef = useRef([]);
        // Track realtime callback for destroying later
        const realtimeCallback = useRef();
        // Track disabled field on update check
        const disabledFields = useRef(disabledColumns || []);

        // On ticker list change update internals
        useEffect(() => {
            // Put ticker list to accessible variable
            tickerRef.current = tickerList;
        }, [tickerList]);

        // Clean async jobs
        useEffect(() => {
            return () => {
                cleanAsyncTasks();
            }
        }, []);

        /**
         * Sets update channel for the ticker message
         *
         * @param symbol
         * @param channel
         */
        function setChannel(symbol, channel) {
            tickerChannels.current[symbol] = channel;
        }

        /**
         * Updates ticker message on the map. Updates only the tickers that already exists
         * on the map.
         *
         * @param ticker Which ticker to update
         * @param message New ticker message
         */
        function setTickerMessage(ticker, message) {
            // Not do anything if we don't care about the ticker key
            if (tickerRef.current.includes(ticker)) {
                // Parse ticker message
                const tickerMessage = tickerMapper(message);
                // Add sequence number to ticker message (if exists)
                tickerMessage.seqnum = message.seqnum;
                tickerMessage.mt = message.mt;

                // Get reference to row object
                const updateChannel = tickerChannels.current && tickerChannels.current[ticker];

                if (updateChannel) {
                    // Set with callback to not override pending updates
                    updateChannel(oldTickerData => {
                        // Check if new type of message and check if sequence numbers are valid
                        if (message.seqnum && message.mt === 'update' && oldTickerData.seqnum + 1 !== message.seqnum) {
                            SocketHelpersNew.subscribe([ticker + "~" + type]);
                            return oldTickerData;
                        }
                        // Compare tickers
                        const [animTicker, shouldUpdate] = DataMappers.shouldUpdate(tickerMessage, oldTickerData, disabledFields.current);

                        // Set is_rt flag to indicate ticker came from real time
                        animTicker.is_rt = true;

                        if (message.mt === 'update') { // Don't check should update on updates segnum needs to be updated
                            // Write updates on old ticker data if we are in compare and update scenario
                            return Object.assign({}, oldTickerData, animTicker);
                        } else if (shouldUpdate) { // Update ticker map if update needed
                            return animTicker;
                        } else {
                            return oldTickerData;
                        }
                    });
                }
            }
        }

        /**
         * Handles real time messages
         *
         * @param message Received message
         */
        function handleRealTime(message) {
            try {
                if (!message.startsWith("OK|")) {
                    // Parse to json and update
                    const jsonResp = JSON.parse(message);

                    if (!jsonResp.hasOwnProperty("p") || jsonResp.d === 'TICKER') {
                        // Extract symbol
                        const symbol = jsonResp.hasOwnProperty("p") ? jsonResp.s : jsonResp.symbol;
                        // Extract ticker message
                        let ticker = jsonResp
                        if (jsonResp.hasOwnProperty("p")) { // Send only ticker updates with sequence number
                            ticker = jsonResp.p;
                            ticker.mt = jsonResp.mt;
                            ticker.seqnum = jsonResp.seqnum;
                        }
                        // Set ticker message
                        setTickerMessage(symbol, ticker);
                    }
                }
            } catch (e) {
                console.error(e);
            }
        }

        /**
         * Initialize real time socket connection by setting ticker list with this
         *
         * @param tickerList List of tickers to listen
         * @param disableRealTime Disable real time updates
         */
        function initSocketConn(tickerList, disableRealTime) {
            // Clean previous async tasks
            cleanAsyncTasks();

            // Set ticker list
            setTickerList(tickerList);

            // Subscribe to tickers
            if (!disableRealTime) {
                realtimeCallback.current = handleRealTime;
                SocketHelpersNew.coinsRaw(tickerList.map(el => el + '~' + type), realtimeCallback.current);
            }
        }

        /**
         * Cleans connections and callbacks
         */
        function cleanAsyncTasks() {
            if (tickerRef.current && tickerRef.current.length > 0 && realtimeCallback.current) { // Remove ticker subscription
                SocketHelpersNew.close(tickerRef.current.map(el => el + '~' + type),  realtimeCallback.current);
            }
        }

        // Return ticker map and initialization function
        return [tickerList, setChannel, initSocketConn];
    },

    /**
     * Handles updates on realtime row returns tickerData state, use this to show
     * row data (e.g price, market_cap see DataMappers for fields).
     *
     * @param symbol Which symbol we are subscribing
     * @param setChannel Function used for setting the channel
     * @param initTicker Initial ticker value coming from API (optional)
     */
    useRealTimeRow(symbol, setChannel, initTicker = {}) {
        // Keep ticker data to disable animations
        const [tickerData, setTickerData] = useState(initTicker);
        // Message received from parent
        const [newTicker, setNewTicker] = useState({});

        useEffect(() => {
            // Send update information through channel
            if (symbol) {
                setChannel(symbol, setNewTicker);
            }
        }, [setNewTicker, symbol]);

        // Clear timeout if this row destroyed
        useEffect(() => {
            // Set ticker data
            let timeout;

            // Set ticker if new ticker is defined
            if (newTicker && Object.keys(newTicker).length !== 0) {
                setTickerData(newTicker);

                // Remove animation updates
                timeout = setTimeout(() => {
                    setTickerData( oldTicker => {
                        return DataMappers.removeUpdates(oldTicker)
                    })
                }, 3000);
            }

            // Clear timeout on destroy
            return () => {
                clearTimeout(timeout);
            }
        }, [newTicker]);

        return [tickerData, setTickerData];
    },

    /**
     * Handles realtime updates on tradebook. Returns three accessors/states
     *  initTradeBook => Takes native symbol, snapshot and symbol information and initiates real time
     *  tradeBook => Active tradebook state
     *
     * @param snapshot Snapshot of the orderbook
     * @param nativeSymbol Symbol to subscribe
     * @param symbolInformation Information regarding symbols
     */
    useRealTimeTrade() {
        // Track realtime callback for destroying later
        const realtimeCallback = useRef();
        // Keep track of the symbol
        const [symbol, setSymbol] = useState();
        // Keep track of the tradebook (Internal sorted set)
        const [iTradebook, setITradebook] = useState(new Set());
        // Keep track of external tradebook
        const [eTradebook, setETradebook] = useState([]);
        // Keep track of symbol information
        const [info, setInfo] = useState()

        // Initializes tradebook as sorted set with given snapshot
        function initTradeBook(nativeSymbol, symbolInformation) {
            // Create a set is hashed and sorted on timestamp
            const sortedSet = new Set({
                hash: function(entry) {
                    return entry.id;
                },
                compare: function(a, b) {
                    // descending numeric sort
                    return b.timestamp - a.timestamp;
                }
            });

            // Clean async tasks before changing symbol
            cleanAsyncTasks()

            // Initialize internal tradebook
            setITradebook(sortedSet);

            // Set symbol of the tradebook
            setSymbol(nativeSymbol)

            // Set symbol information
            setInfo(symbolInformation)

            return sortedSet;
        }

        // Subscribe to real time updates
        useEffect(() => {
            if (symbol) {
                // Activate updates on given symbol
                realtimeCallback.current = handleRealTime;
                SocketHelpersNew.coinsRaw([symbol + '~TRADE'], realtimeCallback.current);
            }

            return () => {
                cleanAsyncTasks();
            }
        }, [symbol])

        // When internal book changes update external book
        useEffect(() => {
            setETradebook(iTradebook.values());
        }, [iTradebook]);

        // Cleans async tasks
        function cleanAsyncTasks() {
            if (realtimeCallback.current && symbol) { // Remove ticker subscription
                SocketHelpersNew.close([symbol + '~TRADE'],  realtimeCallback.current);
            }
        }

        // Handle realtime updates
        function handleRealTime(message) {
            try {
                if (!message.startsWith("OK|")) {
                    // Parse to json and update
                    const jsonResp = JSON.parse(message);

                    // Check if we are interested with this message
                    if (jsonResp.ns === symbol && jsonResp.d === "TRADE") {
                        // Get updates and add to internal set
                        const rtUpdates = jsonResp.p;

                        // Update internal tradebook
                        setITradebook(oldTradeBook => {
                            // Convert messages to common trade book format
                            rtUpdates.forEach(el => {
                                oldTradeBook.add({
                                    id: el[1],
                                    timestamp: el[0],
                                    side: el[2],
                                    price: el[3],
                                    amount: el[4]
                                })
                            });

                            // Pop values until length is 50
                            while(oldTradeBook.length > 50) oldTradeBook.pop();

                            // Copy so react understands it is updated
                            return Object.assign(new Set(), oldTradeBook);
                        });
                    }
                }
            } catch (e) {
                console.error(e);
            }
        }

        return [initTradeBook, eTradebook];
    },

    /**
     * Generate ticker value for symbol and exchange
     *
     * @param symbol Symbol
     * @param exchange Exchange
     * @param isIndex indicates if this is index
     */
    generateTicker(symbol, exchange, isIndex) {
        return symbol + "-" + exchange + "." + (isIndex ? "CISIDX" : "CISCALC");
    },

    /**
     * Used to decide if we should render the component, use with React.memo
     *
     * @param oldProps Old props
     * @param newProps New props
     */
    shouldUpdateRealTime(oldProps, newProps) {
        // Draw row if ticker is null
        if (!oldProps.ticker) {
            return false;
        }

        // Do not draw row if new prop ticker is empty
        if (!newProps.ticker) {
            return true;
        }

        // Check if we should update
        return newProps.ticker.state_tracker === oldProps.ticker.state_tracker;
    }
};


export default RealtimeUtils;
