import { toastr } from 'react-redux-toastr'
import MetaApi from "../api/MetaApi"
import {META_DATA, GET_ALL_COINS, NEWS_TAGS, GET_EXCHANGES, GET_CONNECTABLE_EXCHANGES} from "./types"
import Language from '../constants/Language'

export const metaData = () => dispatch => {
    MetaApi.data((resp, err) => {
        if (resp !== false) {
            dispatch({
                type: META_DATA,
                value: resp
            })
        } else {
            toastr.error("", Language.en.messages.FAIL_LOAD_METADATA)
        }
    })
};

/**
 * Reads news tags and dispatch
 *
 * @returns {function(...[*]=)}
 */
export const newsTags = () => dispatch => {
    MetaApi.newsTags((resp, err) => {
        if (resp !== false) {
            dispatch({
                type: NEWS_TAGS,
                value: resp
            })
        } else {
            toastr.error("", Language.en.messages.FAIL_LOAD_METADATA)
        }
    })
};

export const getAllCoins = () => dispatch => {
    MetaApi.getAllCoins((resp, err) => {
        dispatch({
            type: GET_ALL_COINS,
            value: resp
        })
    })
};

export const getExchanges = () => dispatch => {
    MetaApi.getExchanges( (resp, err)  => {
        dispatch({
            type: GET_EXCHANGES,
            value: resp
        })
    })
};

export const getConnectableExchanges = () => dispatch => {
    MetaApi.getConnectableExchanges( (resp, err)  => {
        dispatch({
            type: GET_CONNECTABLE_EXCHANGES,
            value: resp
        })
    })
};
