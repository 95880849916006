import React from 'react';
import Utils from "../../helpers/Utils";

const isMobile = Utils.checkMobile();

const IndicesRow = props => {
  const {
    chgpct,
    price,
    marketCap,
    totalCoin,
    avalCoin,
    vol24,
    perClass,
    perTd,
    symbol,
    rowImage,
    name,
    exchange,
    rowClass,
    high24,
    low24,
    rowClick,
    row,
    weight,
  } = props;

  /**
   *Indice name parse (forex = > Crypto Index Series Big 10 Index  to  CIS Big 10 Index )
   *
   * @param incideName represent long indice name
   */
  function parseIndiceName(){

    if(row.index_name){
      return row.index_name.replace("Crypto Index Series","CIS");
    }
  }
  return (
      isMobile?
          <tr style={{cursor: 'pointer'}}>
            <td>{parseIndiceName()}</td>
            <td className={perClass}>{perTd}</td>
          </tr>
          :
          <tr style={{cursor: 'pointer'}} onClick={rowClick}>
            <td>{parseIndiceName()}</td>
            <td style={{marginLeft:"0px"}} className={perClass}>{perTd}</td>
          </tr>
  );

  // Old Top Index Series

  // return (
  //     <tr style={{cursor: 'pointer'}} onClick={rowClick}>
  //       <td>{row.index_code}</td>
  //       <td className={rowClass}>{price}</td>
  //       <td>{marketCap}</td>
  //       <td>{vol24}</td>
  //     </tr>
  // );
};

export default IndicesRow;
