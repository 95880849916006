import React, {useEffect, useRef, useState} from "react";
import {withRouter} from "react-router-dom";
import ReactSpeedometer from "react-d3-speedometer";

const Speedometer = props => {

    const {score} = props;

    const colors = ['#8e1e25', '#c11e0f', '#d1d4dc', '#7ac231', '#159725'];

    const text = [
        {
            text: 'Strong Sell',
            position: 'OUTSIDE',
            color: '#8e1e25',
        },
        {
            text: 'Sell',
            position: 'OUTSIDE',
            color: '#c11e0f',
        },
        {
            text: 'Neutral',
            position: 'OUTSIDE',
            color: '#d1d4dc',
        },
        {
            text: 'Buy',
            position: 'OUTSIDE',
            color: '#7ac231',
        },
        {
            text: 'Strong Buy',
            position: 'OUTSIDE',
            color: '#159725',
        },
    ];

    return (
        <div style={{display:"flex", justifyContent:"center",marginTop:"10px"}}>
            <ReactSpeedometer
                width={400}
                height={250}
                needleHeightRatio={0.7}
                value={score.value}
                maxValue={100}
                segmentColors={colors}
                currentValueText={text[score.index].text}
                customSegmentLabels={text}
                ringWidth={25}
                needleTransitionDuration={3333}
                needleTransition="easeElastic"
                needleColor={'rgb(126 134 134)'}
                textColor={colors[score.index]}
            />
        </div>
    );
};

export default withRouter(Speedometer);
