import {combineReducers} from 'redux';
import meta from './metaReducer'
import coin from './coinReducer'
import watchList from './watchListReducer'
import general from './generalReducer'
import {i18nState} from "redux-i18n"
import {reducer as toastr} from 'react-redux-toastr'

export default combineReducers({
    toastr,
    meta,
    coin,
    watchList,
    general,
    i18nState
})
