import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import Loading from "../Loading";
import ReactTooltip from "react-tooltip";
import Paginate from "../Paginate";
import Api from "../../api";
import RealtimeUtils from "../../helpers/RealtimeUtils";
import DataMappers from "../../helpers/DataMappers";
import Select from "../Forms/Select";
import TextField from "@material-ui/core/TextField";
import SymbolRow from "./SymbolRow";
import moment from "moment";

const pageSizes =   [
        {
            value: 20,
            label: "20",
        },
        {
            value: 50,
            label: "50",
        },
        {
            value: 100,
            label: "100",
        }
    ];


const AllMarkets = (props) => {
    const {ccy,coinBaseAsset ,sourceCode ,exchanges} = props;

    //Search Text
    const [text,setText] = useState("");

    const [spots, setSpots] = useState([]);

    //All markets
    const [markets,setMarkets] = useState({data:[],loading:true});

    //Pagination infos
    const [pageInfo, setPageInfo] = useState({page: 1, totalPage: 0});

    //Page size
    const [pageSize, setPageSize] = useState(20);

    //Sort
    const [sort, setSort] = useState({i: 'chgpct', s: 'desc'});

    //Asset Prices
    const [assetPrices,setAssetPrices] = useState({});

    // Real time listeners
    const disabledColumns = ["market_cap", "high", "low"];
    const [tickerList, setChannel, initSocketConnection] = RealtimeUtils.useRealTimeTicker(disabledColumns,  DataMappers.spotTickerMapper);

    // Arrange spots so selected spot at top and exchange info is initiated
    const arrangeSpots = (inSpots, returnSpots) => {
        // Fill missing spot search values
        inSpots = inSpots.map(el => {
            // Add exchange name and ID
            const exc = exchanges[el.source_code];
            if (exc) {
                el.exchange_name = exc.name;
                el.exchange_id = exc.id;

            } else { // In case exchange not defined (Not likely when we get it from API)
                el.exchange_name = el.source_code;
            }
            return el;
        });

        // Return spots rather than updating (This one is used by updates to current spot list)
        if (returnSpots)
            return inSpots;

        // Initiate real time updates
        initSocketConnection(inSpots.map(el => el.cis_symbol));

        setSpots(inSpots);
    }

    // Called when one of the search parameters are changed
    const searchSpots = (page) => {
        // Generate search params

        const params = {
            page_number: page,
            order_by_field: sort.i,
            order_by_direction: sort.s,
            is_exchange: true,
            search_string: text
        };
        if(coinBaseAsset){
            params.base_asset = coinBaseAsset;
        }
        if(sourceCode){
            params.source_code = sourceCode;
        }

        // Perform search
        setMarkets({data: [], loading: true});

        Api.spotSearch(params, pageSize).then(async res => {

            res.data = res.data || [];

            res.data.forEach(el => el.ticker= el.ticker || {});
            // Generate readable tickers
            res.data.forEach(el => {
                el.org_ticker = el.ticker;
                el.ticker = DataMappers.spotTickerMapper(el.ticker);
            });

            let param = {};
            if(res.quoted_assets){
                param.assets = res.quoted_assets.join();
                param.ccy = ccy;
                Api.getAssetPrices(param).then(resp=>{
                    let assetPrice = {};

                    resp.data.forEach(el=>{
                        assetPrice[el[0]] = el[2];
                    })

                    setAssetPrices(assetPrice);
                })
            }
            // Puts selected spot at top if any
            arrangeSpots(res.data);

            setMarkets({data: res.data, loading: false});
            if(res.totalPage >= page){
                setPageInfo({page: page, totalPage: res.totalPage});
            }else{
                setPageInfo({page: 1, totalPage: res.totalPage});
            }

        });
    }


    //Change page
    const handlePageClick = d => {
        if (pageInfo.page !== d.selected + 1) {
            searchSpots(d.selected + 1);
        }
    };

    useEffect(()=>{
        if(coinBaseAsset || sourceCode){
            searchSpots(pageInfo.page);
        }
    },[ccy,sort,pageSize,coinBaseAsset,sourceCode,text])

    return (
            <div className={`gray-container`} style={{borderTopLeftRadius:"0px",borderTopRightRadius:"0px"}}>
                <div
                    className="section-header"
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: '1em'
                    }}>
                    <div className="filter-bar">
                        <div className="filter-bar-input search-menu-all create-flex">
                            <div className="gray-sm-input gray-sm-inputS" style={{padding: "0px 5px", width: "250px"}}>
                                <TextField id="outlined-basic" label="Search" variant="outlined"
                                           value={text || ""}
                                           onChange={val =>{ setText(val.target.value);
                                           }}
                                />
                            </div>
                        </div>
                    </div>
                    {pageSize &&
                        <div className={'table-select-input'}>
                            <div style={{fontSize: 13}} className={'sm-select-input small-select'}>
                                <Select selected={pageSize} options={pageSizes}
                                        onChange={selected => setPageSize(selected.value)}/>
                            </div>
                        </div>
                    }
                </div>
                <div className="table-body table-responsive" id="beauty-scroll7">
                    <Loading loading={markets.loading}>
                        <React.Fragment>
                            <ReactTooltip html={true}/>
                            <table className="coins enumarable-table coins-menu-table linked-table">
                                <thead>
                                <tr>
                                    <th>Pair</th>
                                    <th>Exchange</th>
                                    <th>Price</th>
                                    <th>Price({ccy})</th>
                                    <th>Change</th>
                                    <th>Volume</th>
                                    <th>Volume To</th>
                                    {/*<th>Volume ({ccy})</th>*/}
                                    <th>VWAP</th>
                                    <th>Range</th>
                                    <th>#Trades</th>
                                    <th style={{textAlign:"center"}}>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {markets.data.length > 0 ?
                                    markets.data.map((el,i)=>{
                                    return (<SymbolRow key={i} row={el} setChannel={setChannel} assetPrices={assetPrices} type={"all"} ccy={ccy}/>)
                                })
                                :
                                <tr>
                                    <td colSpan={12}
                                        style={{textAlign: "left", color: "fff", fontSize: "14px", padding: "7px 5px",fontWeight:"normal",cursor:"auto"}}>
                                        There is no pair.
                                    </td>
                                </tr>
                                }
                                </tbody>
                            </table>
                        </React.Fragment>
                    </Loading>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Paginate pageCount={pageInfo.totalPage} onPageChange={handlePageClick}
                                  active={pageInfo.page - 1}/>
                    </div>
                </div>
            </div>
    );
};


const mapStateToProps = state => {
    return {
        meta: state.meta,
        exchanges: state.meta.exchanges
    };
};

export default connect(mapStateToProps)(AllMarkets);
