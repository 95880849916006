import AppHelpers from "../../helpers/AppHelpers";
import Set from "sorted-set";

const getOrderBookUrl = () => "wss://api.gemini.com/v2/marketdata";
class Gemini {

    constructor() {}

    generateSet(snapshot, bids) {
        const set = new Set({
            // what the primary index should be (defaults to identity function)
            hash: function(entry) {
                return entry[0];
            },
            // how to order the set (defaults to string-friendly comparator)
            compare: function(a, b) {
                // descending numeric sort
                return a[0] - b[0];
            }
        });

        // Feed a map function to set for to be used in front-end
        if (bids) {
            set.map = function map(callback) {
                return this.values().slice(0, 20).map(callback);
            };
        } else {
            set.map = function map(callback) {
                return this.values().slice(0, 20).map(callback);
            };
        }

        // Set a reverse function
        set.toList = function () {
            return this.values().slice(0, 20);
        }

        snapshot.forEach(el => {
            // Generate total for data
            el[2] = AppHelpers.priceFormat(el[0] * el[1]);
            set.add(el);
        });

        return set;
    }

    updateBook(updates, handler, type) {
        handler(oldOrderbook => {
            let isChanged = false;
            // Apply updates
            updates.forEach( el => {
                if (el[1] == 0) { // Left as == because it gives "0.00000" == 0 true
                    isChanged = isChanged || oldOrderbook.del(el);
                } else if ((type === 'buy' && el[0] > oldOrderbook.head()[0]) || (type === 'sell' && el[0] < oldOrderbook.tail()[0]) || oldOrderbook.length < 20) { // Generate total data and apply
                    el[2] = AppHelpers.priceFormat(el[0] * el[1]);
                    isChanged = true;
                    oldOrderbook.add(el);
                }
            });

            // Return updated handler
            return isChanged ? Object.assign(new Set(), oldOrderbook) : oldOrderbook;
        });
    }

    subscribeOrderbook(symbol, buyHandler, sellHandler) {
        const conn = new WebSocket(getOrderBookUrl());
        conn.onclose = function () {};
        conn.onopen = function () {
            const message = {
                "type": "subscribe",
                "subscriptions": [
                    {
                        "name":"l2",
                        "symbols":[symbol]
                    }
                ]
            }

            // Get snapshot every second instead of runtime updating
            setInterval(() => {
                if (conn.readyState === WebSocket.OPEN) { // Check status before sending
                    conn.send(JSON.stringify({...message, type: "unsubscribe"}));
                    conn.send(JSON.stringify(message));
                }
            }, 500);
        }
        this.conn = conn;
        this.buyHandler = buyHandler;
        this.sellHandler = sellHandler;

        this.conn.onmessage = ((event) => {
            // Parse message to JSON
            const json = JSON.parse(event.data);
            // Subscription ack message
            if (json.type !== 'l2_updates') return;

            // No indicator of snapshot check message length
            if (json.changes.length > 60) {
                const bids = this.generateSet(json.changes.filter(el => el[0] === 'buy').slice(0, 30).map(el => [parseFloat(el[1]), parseFloat(el[2])]), true);
                const asks = this.generateSet(json.changes.filter(el => el[0] === 'sell').slice(0, 30).map(el => [parseFloat(el[1]), parseFloat(el[2])]));
                this.buyHandler(bids);
                this.sellHandler(asks);
            } else {
                // // If updates came execute updates
                // const buyUpdates = json.changes.filter(el => el[0] === 'buy').map(el => [parseFloat(el[1]), parseFloat(el[2])]);
                // const sellUpdates = json.changes.filter(el => el[0] === 'sell').map(el => [parseFloat(el[1]), parseFloat(el[2])]);
                //
                // // Update bids orderbook
                // if (buyUpdates.length > 0) {
                //     this.updateBook(buyUpdates, this.buyHandler, 'buy');
                // }
                //
                // // Update sell orderbook
                // if (sellUpdates.length > 0) {
                //     this.updateBook(sellUpdates, this.sellHandler, 'sell');
                // }
            }
        });
    }

    destroyConnection() {
        this.conn && this.conn.close();
    }
}

export default Gemini;
